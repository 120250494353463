.recommendations {
  text-align: center;
  color: color(black);
  margin: 1em 0;

  @include from('md') {
    margin-bottom: 4em;
  }

  &__title {
    margin-left: auto;
    margin-right: auto;
  }

  &__container {
    margin-top: 3em;

    @include from('md') {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
    }
  }
}

.recommendation {
  margin-bottom: 2em;
  display: inline-block;
  width: 100%;

  @include from('md') {
    width: auto;
    margin: 0 1em;
  }

  @include from('lg') {
    margin: 0 3em;
  }

  &__icon {
    font-size: 42px;
    margin-bottom: 6px;
  }

  &__title {
    margin-bottom: 6px;
  }
}
