.navigation-card {
  &__container {
    background-color: color(white);
    border-radius: 8px;
    box-shadow: 0 16px 16px 0 rgba(color(grey100), .1);
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 144px;
    justify-content: space-between;
    padding: 16px;

    @include from('xs') {
      height: 296px;
      padding: 24px;
    }
  }

  &__image {
    align-items: left;
    height: 48px;
    width: 48px;

    @include from('xs') {
      height: 64px;
      width: 64px;
    }
  }

  &__title {
    color: color(corePurple);
    font-size: 12px;
    font-weight: bold;
    line-height: 20px;
    
    @include from('xs') {
      font-size: 18px;
      line-height: 26px;
      margin-bottom: 8px;
    }
  }

  &__text {
    color: color(black);
    display: none;
    font-size: 12px;
    line-height: 20px;

    @include from('xs') {
      display: block;
    }
  }
}
