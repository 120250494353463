.cms-tv-guide {
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */

  &__title {
    text-align: center;
    max-width: none;
    margin-bottom: 16px;
  }

  &__loader {
    text-align: center;
    margin-top: 20px;
  }

  &__channel-package-toggle-button {
    color: color(grey100);
    position: relative;
    border-radius: 4px;
    background-color: color(white);
    padding: 8px 11px 8px 16px;
    height: 38px;
    line-height: 23px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;

    @include from('md') {
      background-color: transparent;
    }

    &-text {
      margin-right: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-icon {
      font-size: 11px;
      color: color(corePurple);

      .icon {
        transition: transform 0.5s;
      }
    }

    &--active {
      .icon {
        transform: rotate(180deg);
      }
    }
  }

  &__package-panel {
    border-radius: 8px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
    background-color: color(white);
    color: color(grey200);
    font-size: 12px;
    font-weight: 500;
    line-height: 26px;
    max-height: 0;
    overflow: hidden;
    transition: 0.3s ease-in-out;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    // TODO: The order of items should be top-down-left-right. This makes it left-right-top-down.
    // grid-auto-flow: column does not work as expected ¯\_(ツ)_/¯
    // grid-auto-flow: column;

    @include from('md') {
      order: 2;
      width: 100%;
    }

    /* This will match the IE browser */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: flex;
      flex-wrap: wrap;
    }

    &--item {
      /* This will match the IE browser */
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        min-width: 280px;
      }

      label {
        display: flex;
        align-items: center;
        padding: 4px 8px;
        line-height: 16px;
        cursor: pointer;

        & .icon {
          height: 5px;
          width: 5px;
          min-width: 5px;
          min-height: 5px;
          color: color(corePurple);
          margin-left: 12px;
          margin-right: 8px;
        }
      }
    }

    input {
      display: none;
    }

    input:checked + label {
      background-color: color(corePurple);
      color: color(white);

      & .icon {
        color: color(white);
      }
    }

    &--visible {
      max-height: 1000px;
      margin: 16px 0;
      padding: 16px 0;
    }
  }

  &__filter-container {
    display: flex;
    flex-direction: column;
    margin-top: 12px;
    margin-bottom: 8px;

    @include from('md') {
      margin-bottom: 24px;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
    }

    .cms-tv-guide__dropdown + .select2-container {
      -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
      color: color(grey100);
      border-radius: 4px;
      background-color: color(white);
      margin: 0 !important;

      @include from('md') {
        background-color: transparent;
        width: auto !important;
      }

      .ppr-select__container {
        border: none;
        padding: 8px 16px;

        .select2-selection__rendered {
          height: 20px;
          line-height: unset;
          margin-right: 20px;
        }

        .select2-selection__arrow {
          top: 8px;
          width: 22px;
          color: color(corePurple);
        }
      }
    }
  }

  &__filter {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;

    @include from('md') {
      margin: 8px;
    }

    &--hidden {
      display: none;
    }

    label {
      color: color(grey70);
      min-height: 1em;
      font-size: 12px;
      line-height: 20px;

      @include from('md') {
        min-height: 20px;
        padding-left: 16px;
      }
    }
  }

  &__carousel-container {
    display: none;
    position: relative;
    overflow: hidden;
  }

  &__content-carousel {
    position: relative;
    max-height: 160px;

    &--md {
      max-height: 300px;
    }

    &--lg {
      max-height: 500px;
    }

    &--xlg {
      max-height: 650px;
    }

    &--xxlg {
      max-height: none;
    }

    @include to('md') {
      max-height: 300px;
    }
  }

  &__carousel {
    margin: 0;
    padding-bottom: 8px;

    @include from('md') {
      margin: 0 24px 0 24px;
    }

    .cms-tv-guide__content-carousel {
      display: flex;
      flex-direction: row;
    }

    &::before,
    &::after {
      content: '';
      z-index: z(forward);
      position: absolute;
      top: 0;
      height: 100%;
      width: 24px;
      pointer-events: none;

      @include from('md') {
        width: 80px;
      }
    }

    &::before {
      background: linear-gradient(to left, rgba(color(grey20), 0), rgba(color(grey20), 1));
      left: 0;
    }

    &::after {
      background: linear-gradient(to right, rgba(color(grey20), 0), rgba(color(grey20), 1));
      right: 0;
    }
  }

  &__carousel-arrow {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 50%;
    cursor: pointer;
    background-color: color(white);
    border-radius: 999px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
    z-index: z(forward);
    transform: translateY(-50%);

    @include to('md') {
      display: none !important;
    }

    .icon {
      font-size: 24px;
      position: absolute;
      top: 50%;
      left: 50%;
    }

    &:hover {
      color: hover(corePurple);
    }

    &.swiper-button-disabled {
      display: none !important;
    }
  }

  &__carousel-arrow--left {
    left: 0;

    .icon {
      transform: translate(-60%, -50%);
    }
  }

  &__carousel-arrow--right {
    right: 0;

    .icon {
      transform: translate(-40%, -50%);
    }
  }

  &__channel {
    display: flex;
    flex-direction: column;
    margin: 0 8px;
    min-width: 192px;
    max-width: 192px;
    height: 100%;
  }

  &__channel-heading {
    display: flex;
    flex-direction: column;
    min-width: 192px;
    max-width: 192px;
    align-items: center;
  }

  &__channel-logo {
    height: 60px;
    margin-bottom: 8px;
  }

  &__channel-title {
    display: flex;
    align-items: center;
    min-height: 60px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  &__channel-content {
    height: 100%;
    border-radius: 0 0 8px 8px;
    background-color: color(grey10);
    box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.12);
    overflow: hidden;

    &--rounded {
      border-radius: 8px;
      padding-top: 0;
      margin-top: 0;
    }
  }

  &__program {
    &--not-found:first-of-type {
      text-align: center;
      background-color: color(grey10);
      padding-top: 16px;
      margin-bottom: 8px;
    }

    &--active {
      width: 100%;
      background-color: color(white);
      z-index: z(forward);
      border-radius: 8px 8px 0 0;
    }
  }

  &__time-slot {
    @include from('md') {
      border-bottom: solid 1px color(grey30);
      min-height: 40px;

      &:last-of-type,
      &--hidden {
        border-bottom: none;
      }
    }
  }

  &__program-content {
    padding: 4px 8px;
    display: flex;
    align-items: baseline;

    &--no-content {
      justify-content: center;
      margin-top: 4px;

      .cms-tv-guide__program-time {
        display: none;
      }

      .cms-tv-guide__program-name {
        color: color(grey100);
      }
    }
  }

  &__program-time {
    font-size: 11px;
    color: color(grey50);
    margin-right: 12px;
  }

  &__program-name,
  &__program--not-found {
    font-size: 13px;
    color: color(grey100);
    line-height: 16px;
    min-height: 32px;
    word-break: break-word;
  }

  &__program-progress {
    margin: 4px 8px;
    position: relative;
    height: 4px;

    progress {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;

      position: absolute;
      top: 0;
      left: 0;
      height: 4px;
      width: 100%;
      border-radius: 999px;
      background-color: color(grey30);
      color: color(corePurple);
    }

    progress::-webkit-progress-bar {
      background-color: color(grey30);
      border-radius: 16px;
    }

    progress::-webkit-progress-value {
      background-color: color(corePurple);
      border-radius: 16px;
    }

    progress::-moz-progress-bar {
      background-color: color(corePurple);
      border-radius: 16px;
    }
  }

  /* This is the content that is injeted into the program details modal */
  &__program-details {
    display: none;
  }

  &__program-details-subtitle {
    color: color(grey100);
    font-size: 16px;
    font-weight: bold;
    line-height: 19px;
  }

  &__program-details-description {
    margin-top: 24px;
    color: color(grey100);
    font-size: 16px;
    line-height: 24px;
  }

  &__program-details-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    text-align: right;

    img {
      height: 60px;
    }
  }

  &__show-more {
    margin-top: 16px;
    text-align: center;
  }
}
