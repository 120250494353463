.job-details {

  &__image {
    max-width: 100%;
  }

  &__description {
    p, span {
      font-size: inherit !important;
      font-family: inherit !important;
    }
  }

  &__info-title {
    font-weight: bold;
  }

  &__info {
    line-height: 20px;
    padding-bottom: 1em;
  }

  &__button {
    &--left {
      display: none;
      @include from('md') {
        display: block;
      }
    }
  }

  &__error {
    margin-top: 20px;
  }

}