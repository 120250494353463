.price-component {
  &--color-inherit {
    color: inherit;
  }

  &--color-black {
    color: color(black);
  }

  &--color-white {
    color: color(white);
  }

  &__prefix-text {
    font-size: 16px;
    line-height: 16px;
    margin-right: 2px;

    .price-component--bigger & {
      font-size: 14px;
      font-weight: bold;
    }
  }

  &__old-price {
    @include teliaSansHeading;
    text-decoration: line-through;
    font-size: 20px;
    line-height: 20px;
    margin-right: 2px;
    color: color(grey50);

    .price-component--bigger & {
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__single-line-unit {
    font-size: 16px;
    line-height: 16px;
    margin-left: -3px;

    .price-component--bigger & {
      @include teliaSansHeading;
      margin-left: -7px;
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__value {
    @include teliaSansHeading; 
    font-size: 34px;

    .price-component--bigger & {
      font-size: 48px;
      line-height: 48px;
    }
  }

  &__unit {
    font-size: 14px;
    margin-left: -4px;

    .price-component--bigger & {
      @include teliaSansHeading;
      margin-left: -8px;
      font-size: 16px;
      line-height: 16px;
    }
  }

  &__stacked-unit {
    display: inline-block;
    line-height: 14px;
    font-size: 12px;
    width: 12px;
    margin-left: -3px;

    .price-component--bigger & {
      @include teliaSansHeading;
      margin-left: -6px;
      font-size: 16px;
      line-height: 16px;
    }
  }
}
