.helppi-service-information {
  display: none;
  height: 100%;
  overflow: auto;
  width: 100%;

  &__description {
    height: 65%;
    overflow: hidden;
    padding: 10px;
    text-overflow: ellipsis;
  }

  &__buttons-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
  }

  &__button-primary ,
  &__button-secondary {
    height: 44px;
    padding: 14px 20px;
    width: 165px;

    span {
      font-size: 14px;
      font-weight: bold;
      margin: 0 auto;
    }
  }
}
