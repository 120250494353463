.file-download {
  background-color: white;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;

  @include from('sm') {
    padding: 30px;
    width: 80%;
  }

  &__title {
    text-align: center;
    max-width: 100%;
  }

  &__description {
    text-align: center;
    font-weight: bold;
    font-size: 14px;

    @include from('sm') {
      font-size: 16px;
    }
  }

  &__choose-file-container {
    display: flex;
    flex-direction: column;
    margin: 30px;

    @include from('sm') {
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  // ppr-select overrides
  &__dropdown + .select2-container {
    border: solid 2px color(corePurple);
    border-radius: 8px;
    margin: 0 !important;

    @include from('sm') {
      width: 50% !important;
    }

    .ppr-select__container {
      border: none;
      font-weight: bold;
      padding: 10px;

      .select2-selection__rendered {
        color: color(corePurple);
        padding: 0;
        margin-right: 20px;
      }
    }
  }

  &__download-button {
    .button {
      width: 100%;
      margin: 0;
      margin-top: 12px;
      text-align: center;
      justify-content: center;

      @include from('sm') {
        width: auto;
        margin: 0;
        margin-left: 16px;
      }
    }
  }
}

// styling with id because this is dynamically appended to the end of the DOM with an id generated from the original dropdown
#select2-file-download-select-results {
  max-width: none;
}



