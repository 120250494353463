.cms-new-magnolia-card--horizontal.cms-new-magnolia-card--corner {
  @include from('sm') {
    min-height: 380px;
  }

  .cms-new-magnolia-card__footer-container {
    min-width: unset;
    width: unset;
  }
}

.cms-new-magnolia-card {
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.12);
  padding: 10px 10px 10px 10px;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: transform 0.2s ease 0s;
  -webkit-text-size-adjust: 100%;

  @include from('xs') {
    padding: 16px 16px 16px 16px;
  }

  @include from('sm') {
    padding: 16px 32px 16px 32px;
  }

  &--no-subcomponent-margin > * {
    margin: 0 !important;
  }

  &--no-subcomponent-padding > * {
    padding: 0 !important;
  }

  &--no-subcomponent-border > * {
    border: none !important
  }

  &--no-subcomponent-shadow > * {
    box-shadow: none !important;
  }

  &--overflow-hidden {
    overflow: hidden;
  }

  &__link-overlay {
    left: 0;
    top: 0;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: z(cardLinkOverlay);
    cursor: pointer;
  }

  &--text-black {
    color: color(grey100);
  }

  &--text-white {
    color: color(white);
  }

  &--text-deepPurple {
    color: color(deepPurple);
  }

  &--text-lightPurple {
    color: color(lightPurple);
  }

  &--text-deepBeige {
    color: color(deepBeige);
  }

  &--text-lightBeige {
    color: color(lightBeige);
  }

  &--text-beige {
    color: color(beige);
  }

  &--with-link {
    &:hover {
      @include from('sm') {
        transform: scale(1.03);
        box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.12);
        z-index: z(productCardHover);
      }
    }
  }

  &--background-white {
    background-color: color(white);
  }

  &--background-grey {
    background-color: color(grey20);
    box-shadow: 0px 12px 24px 10px rgba(0, 0, 0, 0.12);
  }

  &--background-purple-gradient {
    background: linear-gradient(180deg, color(corePurple) 0%, #7625a7 100%);
  }

  &--background-black-gradient {
    background: linear-gradient(180deg, #505256 0%, #1B1D20 100%);
  }

  &--background-deepPurple {
    background-color: color(deepPurple);
  }

  &--background-lightPurple {
    background-color: color(lightPurple);
  }

  &--background-deepBeige {
    background-color: color(deepBeige);
  }

  &--background-lightBeige {
    background-color: color(lightBeige);
  }

  &--background-beige {
    background-color: color(beige);
  }

  @include from('sm') {
    border-radius: 16px;
  }

  &__tag-container {
    height: 22px;
    display: flex;
    justify-content: flex-end;
    margin-right: 0;
    margin-bottom: 5px;

    @include from('xs') {
      margin-right: -5px;
      margin-bottom: 6px;
    }

    @include from('sm') {
      margin-right: -16px;
      margin-bottom: 7px;
    }
  }

  &__tag {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 22px;
    padding: 0 6px;
    border-radius: 4px;
    color: color(white);
    display: flex;
    justify-content: center;

    @include from('sm') {
      font-size: 12px;
      line-height: 23px;
      padding: 0 8px;
    }

    &--red {
      background-color: color(red);
    }

    &--orange {
      background-color: color(orange);
    }

    &--blue {
      background-color: color(blue);
    }

    &--transparent {
      background-color: rgba(color(grey70), 0.5);
    }

    &--purple {
      background-color: color(corePurple);
    }
  }

  &__content-container {
    display: flex;
    flex: 1 0 auto;
    min-height: 1px; // IE stretch fix

    @include from('sm') {
      flex-direction: column;

      .cms-new-magnolia-card--horizontal & {
        flex-direction: row;
      }
    }

    .cms-new-magnolia-card--large & {
      flex-direction: column;
    }

    // The image in horizontal layout (the default layout in mobile)
    > .cms-new-magnolia-card__image-container {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      width: 100%;
      min-height: 1px; // IE stretch fix

      @include from('sm') {
        display: none;

        .cms-new-magnolia-card--horizontal & {
          display: flex;
          flex-grow: 1;
          justify-content: center;
        }
      }

      @include to('sm') {
        .cms-new-magnolia-card--corner & {
          margin-top: auto;
        }
      }

      .cms-new-magnolia-card--horizontal.cms-new-magnolia-card--corner & {
        margin-top: auto;
      }

      .cms-new-magnolia-card--large & {
        display: none;
      }
    }
  }

  .cms-new-magnolia-card__image--huippudiili-mobile {
    width: 140px;
    height: 140px;
  }

  &__content-special {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 1px; // IE stretch fix

    @include from('sm') {
      flex: 1 0 auto;

      .cms-new-magnolia-card--horizontal & {
        flex: 0 0 50%;
      }
    }

    .cms-new-magnolia-card--large & {
      flex: 1 0 auto;
    }

    .cms-new-magnolia-card__image {
      width: 200px;
      height: 200px;
    }

    .cms-new-magnolia-card__image-container {
      margin-bottom: 24px;
    }

    // The image in vertical layout
    > .cms-new-magnolia-card__image-container {
      display: none;

      @include from('sm') {
        display: block;
        .cms-new-magnolia-card--horizontal & {
          display: none;
        }
      }

      .cms-new-magnolia-card--large & {
        display: flex;
        align-self: center;
        margin-top: auto;
      }
    }

    // The image in vertical layout
    > .cms-new-magnolia-card__huippudiili-component {
      display: none;
      @include from('sm') {
        display: block;
        .cms-new-magnolia-card--horizontal & {
          display: none;
        }
      }
    }
  }

  &__content {
    flex: 0 0 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 1px; // IE stretch fix

    @include from('sm') {
      flex: 1 0 auto;

      .cms-new-magnolia-card--horizontal & {
        flex: 0 0 50%;
      }
    }

    .cms-new-magnolia-card--large & {
      flex: 1 0 auto;
    }

    // The image in vertical layout
    > .cms-new-magnolia-card__image-container {
      display: none;

      @include from('sm') {
        display: flex;
        align-self: center;
        margin-top: auto;
        width: 100%;
        min-height: 1px; // IE stretch fix

        .cms-new-magnolia-card--horizontal & {
          display: none;
        }
      }

      .cms-new-magnolia-card--large & {
        display: flex;
        align-self: center;
        margin-top: auto;
      }
    }

  }

  &__text-container {
    z-index: z(forward);
  }


  &__description {
    font-size: 11px;
    line-height: 14px;
    font-weight: bold;
    margin-bottom: 5px;
    color: rgba(color(grey50), 0.85);

    @include from('xs') {
      font-size: 14px;
    }

    @include from('xlg') {
      font-size: 16px;
    }

    .cms-new-magnolia-card--large & {
      font-size: 14px;

      @include from('sm') {
        font-size: 16px;
      }

      @include from('xlg') {
        font-size: 18px;
      }
    }
  }

  &__title {
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    margin-bottom: 12px;
    margin-top: 0;
    color:inherit;

    @include from('xs') {
      font-size: 16px;
      line-height: 20px;
    }

    @include from('sm') {
      font-size: 20px;
      line-height: 28px;
    }

    @include from('xlg') {
      font-size: 24px;
      line-height: 32px;
    }

    .cms-new-magnolia-card--large & {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 14px;

      @include from('sm') {
        font-size: 27px;
        line-height: 33px;
      }

      @include from('md') {
        font-size: 29px;
        line-height: 35px;
      }

      @include from('lg') {
        font-size: 37px;
        line-height: 42px;
      }
    }
  }

  &__text {
    font-size: 13px;
    line-height: 19px;
    margin-bottom: 12px;

    @include from('sm') {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 24px;
    }

    @include from('md') {
      font-size: 15px;
      line-height: 24px;

      .cms-new-magnolia-card--large & {
        font-weight: 500;
      }
    }
  }

  &__image-container {
    max-width: 290px;

    @include from('sm') {
      max-width: 420px;
      margin: 0 auto;
    }

    .cms-new-magnolia-card--corner & {
      height: 180px;
    }

    .cms-new-magnolia-card--horizontal.cms-new-magnolia-card--corner & {
      @include from('md') {
        height: 260px;
      }
    }
  }

  &__image {
    margin-bottom: 0;
    max-width: 100%;
    align-self: center;
    flex-shrink: 0; // IE stretch fix
    margin: 0 auto;

    @include from('sm') {
      margin-bottom: 12px;

      .cms-new-magnolia-card--horizontal & {
        margin-bottom: 0;
      }

      .cms-new-magnolia-card--corner & {
        margin-bottom: 0;
      }
    }

    .cms-new-magnolia-card--corner & {
      transform: translateX(33%) translateY(20%) scale(1.2);

      @include from('sm') {
        transform: translateX(33%) translateY(40%) scale(1.2);
      }
    }

    .cms-new-magnolia-card--corner.cms-new-magnolia-card--large & {
      transform: translateX(33%) translateY(33%) scale(1.2);

      @include from('sm') {
        transform: translateX(33%) translateY(40%) scale(1.2);
      }
    }

    .cms-new-magnolia-card--horizontal.cms-new-magnolia-card--corner & {
      transform: translateX(33%) translateY(25%) scale(1.2);

      @include from('sm') {
        transform: translateX(35%) scale(1.2);
      }

      @include from('md') {
        transform: translateX(35%) translateY(30%) scale(1.2);
      }
    }

    .cms-new-magnolia-card--horizontal.cms-new-magnolia-card--corner.cms-new-magnolia-card--large & {
      transform: translateX(33%) translateY(33%) scale(1.2);

      @include from('sm') {
        transform: translateX(33%) translateY(40%) scale(1.2);
      }
    }

    .cms-new-magnolia-card--large & {
      margin-bottom: 12px;
    }
  }

  &__footer-container {
    z-index: z(forward);

    .cms-new-magnolia-card--corner & {
      width: 50%;
      min-width: 80px;

      @include from('sm') {
        width: 40%;
        min-width: 122px;
      }
    }
  }

  &__price {
    @include teliaSansHeading;
    font-size: 20px;
    line-height: 20px;

    @include from('sm') {
      font-size: 32px;
      line-height: 23px;
    }
  }

  &__price-unit {
    @include teliaSans;
    font-size: 12px;
    line-height: 18px;
    margin-left: -4px;
    margin-right: 6px;

    @include from('sm') {
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__price-crossed {
    @include teliaSansHeading;
    font-size: 12px;
    line-height: 18px;
    text-decoration: line-through;
    color: color(grey40);

    @include from('sm') {
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__first-footer-description {
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;

    @include from('sm') {
      font-size: 14px;
      line-height: 26px;
    }
  }

  &__second-footer-description {
    color: color(grey40);
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;

    @include from('sm') {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .cms-huippudiili-number-offer__form-container-dark {
    & .text-input__field {
      border-color: color(white);
      color: color(white);
      &.has-value {
        &:focus, &.focus {
          & ~ .text-input__label {
            color: color(white);
          }
        }
      }
      &:focus, &.focus {
       & ~ .text-input__label {
         color: color(white);
       }
      }
      & ~ .text-input__label {
        color: rgba(255, 255, 255, 0.66);
      }
    }

    & .cms-text-input__error-text {
      color: color(white);
    }
    & .icon--error-filled {
      color: color(red);
    }
  }

  .cms-huippudiili-number-offer__input-container {
      margin-bottom: 15px;
      & .text-input {
          margin-bottom: 15px;
      }
  }

  .cms-huippudiili-number-offer__form-container {
    padding-top: 0px;
    padding-bottom: 0px;
    @include from('sm') {
      margin-bottom: 10px;
    }
  }

  .cms-huippudiili-number-offer {
    margin-bottom: 0px;
  }

}


