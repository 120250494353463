// This component relies somewhat on external .form-container styles (form container required to style dark theme inputs)
.cms-telephone-number-offers {
   & > .form-container {
    margin: 0;

    @include from('md') {
      padding-top: 1.75em;
      padding-bottom: 1.75em;
    }
  }

  margin-bottom: 20px;

  @include from('md') {
    margin-bottom: 0;
  }

  &--dark {
    color: color(white);
  }

  &--background-transparent {
    background-color: transparent;
  }

  &--background-white {
    background-color: color(white);
  }

  &--background-lightGrey {
    background-color: color(grey20);
  }

  &--background-darkGrey {
    background-color: color(grey100);
  }

  // Adjust anchor based on the height of the header
  &__anchor {
    position: relative;
    display: block;
    visibility: hidden;
    top: -64px;

    @include from('md') {
      top: -78px;
    }
  }

  // The header is higher in desktop sizes when the page is scrolled to the top
  .main-container > &__anchor:first-child {
    @include from('md') {
      top: -110px;
    }
  }

  &__title {
    // Override global h2 stylings.
    max-width: unset;
    margin-bottom: 20px;

    @include from('md') {
      margin-bottom: 29px;
    }

    .cms-telephone-number-offers--dark & {
      color: color(white);
    }
  }

  &__description {
    // Override global p style
    p {
      max-width: unset;
    }
  }

  &__form-container {
    margin-bottom: 10px;

    @include from('md') {
      display: flex;
      align-items: center;
      flex-direction: row;
    }
  }

  .form-container--display-none {
    display: none;
  }

  .form-container--display-block {
    display: block;
  }

  &__image-container {
    &--mobile {
      display: flex;
      justify-content: center;
      align-items: center;

      @include from('md') {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @include from('md') {
        display: block;
      }

      @include from('lg') {
        display: block;
      }
    }

    &--left-side {
      padding-right: 30px;

      @include from('lg') {
        padding-right: 40px;
      }
    }

    &--right-side {
      padding-left: 30px;

      @include from('lg') {
        padding-left: 40px;
      }
    }
  }

  &__image {
    max-width: 220px;

    @include from('xs') {
      max-width: 300px;
    }

    @include from('lg') {
      max-width: 380px;
    }
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    
    @include from('sm') {
      flex-direction: row;
      margin-bottom: 40px;
    }

    @include from('md') {
      max-width: 600px;
    }

    // Override external text-input stylings
    & .text-input {
      margin: 0;
      margin-bottom: 15px;
      width: 100%;
      
      @include from('sm') {
        margin-bottom: 0;
        padding-right: 20px;
      }

      & .text-input__error-text {
        @include from('sm') {
          position: absolute;
        }
      }
    }

    // Override external button stylings
    & > .button {
      align-self: center;
      margin: 0;
      margin-top: auto;
    }
  }

  &__captcha > .g-recaptcha {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    @include from('sm') {
      justify-content: flex-start;
    }
  }

  &__loader {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }

  &__loader-top {
    display: flex;
    justify-content: center;
    padding: 50px;
  }

  // Override notification grid styles
  &__notification-container .notification .grid {
    margin: 0;
    width: 100%;

    & > .grid__col-12 {
      padding: 0 10px;
    }
  }

  &__result-area {
    overflow: hidden;
  }

  &__display-none {
    display: none;
  }

  &__display-block {
    display: block;
  }
}

// Move google captcha badge above chat button
@include to('md') {
  .grecaptcha-badge {
    bottom: 60px !important;
  }
}