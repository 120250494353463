.icon-text-list {
  &__item {
    display: flex;
    margin-bottom: 16px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__item-icon {
    .icon {
      height: 24px;
      width: 24px;
    }
  }

  &__item-text {
    text-align: left;
    margin-left: 16px;
    font-size: 16px;
    font-weight: 500;
  }
}
