.footer {
  &__links {
    background-color: color(deepPurple);
    color: color(white);

    @include from('sm') {
      padding: 20px 0;
    }
  }

  &__link-grid {
    .main-container &,
    .footer & {
      @include to('xs') {
        padding: 0;
      }
    }

    @include from('lg') {
      text-align: center;
      justify-content: center;
      text-align-last: center;
    }
  }

  &__copyright {
    background-color: color(deepPurple);
    color: rgba(color(white), 0.7);
    font-size: 14px;
    padding: 30px;
    text-align: center;
  }

  &__copyright-text {
    display: inline-block;
    margin: 10px;
  }

  &__additional-text {
    display: block;
    margin: 10px;

    @include from('md') {
      display: inline-block;
    }
  }

  &__copyright-link {
    margin: 10px;
    display: inline-block;
    transition: color 0.2s;

    &:link {
      color: rgba(color(white), 0.7);
    }

    &:active,
    &:visited {
      color: color(white);
    }

    &:hover {
      color: color(white);
    }
  }

  &__social {
    text-align: center;
    overflow: auto;
  }

  /* Following styles from footer-links.ftl */

  &__link-section {
    text-align: left;
    text-align-last: initial;
    letter-spacing: normal;
    word-spacing: normal;
    white-space: normal;
    padding: 0;

    .main-container &,
    .footer & {
      @include to('xs') {
        padding: 0;
      }
    }

    @include from('sm') {
      padding: 20px 0;
    }

    @include between('md', 'lg') {
      width: 20%;
    }
  }
  &__navi-button {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    color: inherit;
    text-align: left;
    cursor: pointer;

    &:hover {
      @include to('xs') {
        background-color: rgba(color(white), 0.1);
      }
    }

    @include from('sm') {
      pointer-events: none; // This will make the button non-clickable
    }
  }
  &__links-heading {
    color: color(white);
    text-transform: none;
    font-weight: bold;
    font-size: 16px;
    margin: 0;
    padding: 0 16px;
    height: 48px;
    line-height: 48px;
    vertical-align: middle;
    position: relative;
    border-bottom: 1px solid rgba(color(white), 0.4);

    @include from('sm') {
      border: none;
      height: auto;
      line-height: 1.3;
      font-size: 20px;
    }
  }

  &__links-toggle {
    opacity: 0.7;
    position: absolute;
    right: 16px;

    @include from('sm') {
      display: none;
    }
  }

  &__link-list {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s;
    margin: 0;
    padding-left: 0px;

    > li {
      list-style-type: none;
    }

    @include from('sm') {
      max-height: none;
      margin-top: 24px;
    }
  }

  &__link {
    display: block;
    font-size: 16px;
    height: 48px;
    line-height: 48px;
    padding: 0 32px;
    border-bottom: 1px solid rgba(color(white), 0.25);
    margin: 1px;

    &:link,
    &:visited {
      color: rgba(color(white), 0.7);
    }
    &:hover,
    &:active {
      color: color(white);
    }

    &:hover {
      @include to('xs') {
        background-color: rgba(color(white), 0.1);
      }
    }

    @include from('sm') {
      margin: 3px 3px 8px 3px;
      font-size: 14px;
      line-height: 23px;
      height: auto;
      border: none;
      padding: 0 14px;
    }
  }
}

