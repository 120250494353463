.search-form {
  background-color: color(grey10);
  padding: 3em 0 1.5em;

  &__wrapper {
    align-items: center;
  }

  &__field {
    .text-input__icon {
      top: 1.5em;

      @include from('md') {
        left: 10px;
      }

      &--search {
         top: 2em;
      }
    }
    
  }

  &__input {
    font-size: 16px;

    &--ac-active {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &__results {

    &-container {
      &-wrapper {
        align-items: center;
      }
    }

    &-addsearch {
      display: none;
      position: relative !important;
    }
  }

  &__filters-container {
    background-color: color(grey10);
  }

  &__filters {
    position: relative;
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }

  &__filters-list {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    margin-top: 21px;
    color: color(corePurple);

    @include from('md') {
      overflow: hidden;
    }
  }

  &__filters-action {
    height: 100%;
    position: absolute;
    transition: left .2s ease-in, right .2s ease-in;
    display: none;
    padding-top: 1px;
    top: 25%;
    @include to('xs') {
      top: 35%;
    }
    &--right {
      right: -30px;
      @at-root {
        .search-form__filters-list--scrollRight .search-form__filters-action--right {
          right: 0;
          display: inline-block;
        }
      }
    }
    &--left {
      left: -30px;
      @at-root {
        .search-form__filters-list--scrollLeft .search-form__filters-action--left {
          left: 0;
          display: inline-block;
        }
      }
    }
  }

  &__filters-icon {
    position: absolute;
    color: color(corePurple);
    width: 20px;
    height: 30px;
    background-color: color(grey10);
    text-align: center;
    z-index: 1;
    cursor: pointer;
    &--left {
      left: 0;
      box-shadow: 10px 0 10px color(grey10);
    }
    &--right {
      right: 0;
      box-shadow: -10px 0 10px color(grey10);
    }
    .icon {
      vertical-align: middle;
      @include to('xs') {
        font-size: 12px;
      }
    }
  }

}

.search--with-image {
  .search-form {
    background-color: transparent;
    padding-bottom: 0.1px;
    transition: margin-top .3s ease-in-out, padding-bottom .3s ease-in-out;

    &__field {
      position: relative;
    }

    &__results {

      &-container {
        background: color(white);
        margin-top: 72px;
        padding-bottom: 30px;
        opacity: 0;
        transition: opacity .3s ease-in-out .3s;
  
        &-wrapper {
          align-items: center;
        }
      }
  
      &-addsearch {
        margin-top: 180px !important;
      }
    }

    .text-input__icon {
      left: -2px;
    }
  }

  .search__container--small .search-form {
    margin-top: 48px;
  }
}

.search--with-image.search--searched {
  .search-form__results {
    &-container {
      margin-top: 40px;
      padding-bottom: 40px;
      opacity: 1;
    }

    &-addsearch {
      margin-top: 90px !important;
    }
  }

}