// Component has reloader
[data-component-href] {
  position: relative;
}

.component-loader__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: z(back);
  opacity: 0;
  background-color: rgba(color(white), 0.8);
  transition: opacity 0.2s ease-in;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 !important;

  &--active {
    opacity: 1;
    z-index: z(componentLoader);
  }

  &--dots {
    color: color(white);
  }
}
