.cms-tablist {
  &__anchor {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
  }

  margin-top: 2em;

  &__navbar-container {
    position: relative;
    margin-bottom: em(40px);

    &.scrollLeft {
      padding-left: 20px;
    }

    &.scrollRight {
      padding-right: 20px;
    }
  }

  &__navbar {
    width: 100%;
    position: relative;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    &--align-center {
      text-align: center;
    }

    .grid & .grid {
      margin: 0;
      width: 100%;

      .grid__col-12 {
        padding: 0;
      }
    }
  }

  &__navbar-content {
    white-space: nowrap;
    padding:3px;
  }

  &__heading {
    display: none;

    &--center {
      text-align: center;
    }

    &--left {
      text-align: left;
    }
  }

  &__radio {
    display: none;
  }

  &__tab {
    display: none;
  }

  &__tab-text {
    pointer-events: none;
    display: inline-block; // IE11 ignores pointer-events property when display is inline
  }

  &__radio:checked + &__tab {
    display: block;
  }

  &__label {
    @include tablink;
    pointer-events: all;
    display: inline-block;
    border-width: 0;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;

    &--active {
      border-bottom: 3px solid color(corePurple);

      &:hover {
        color: inherit;
      }
    }
  }

  &__container {
    overflow: hidden;
    margin-bottom: 3em;
    position: relative;

    .cms-tablist--no-footer-margin & {
      margin-bottom: 0;
    }
  }

  &__tab > &__content .columnrow {
    margin-top: 0;
  }

  &__scroll-button {
    speak: none;
    position: absolute;
    top: 13px;
    transition: opacity 0.5s;
    cursor: pointer;
    display:none;
    color: color(corePurple);
  }

  &__scroll-left-button {
    left: 0px;

    .scrollLeft & {
      display:block;
    }
  }

  &__scroll-right-button {
    right: 0px;

    .scrollRight & {
      display:block;
    }
  }

  &--inline {
    .cms-tablist__heading {
      display: block;
      font-size: 28px;
      font-weight: 700;
      padding: 0.25em 0;
      margin: 1em 0;
      color: color(corePurple);
    }

    .cms-tablist__navbar {
      display: none;
    }

    .cms-tablist__tab {
      display: block;
    }
  }

  &--rocker-tabs {
    .cms-tablist__navbar-content {
      font-size: 0; //Fighting the Space Between Inline Block Elements
    }

    .cms-tablist__label {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 12px;
      padding: 8px 20px;
      margin: 0;
      box-sizing: border-box;
      border: solid 2px color(corePurple);
      border-right: none;
      color: color(corePurple);

      &--active {
        background-color: color(corePurple);
        color: color(white);
      }

      @include from('md') {
        font-size: 14px;
        padding: 12px 44px;
      }
    }

    .cms-tablist__label:first-child {
      border-radius: 8px 0 0 8px;
    }

    .cms-tablist__label:last-child {
      border-radius: 0 8px 8px 0;
      border-right: solid 2px color(corePurple);
    }
  }

  &--squircle {
    $squircleMobileDefaultSize: 60px;
    $squircleMobileActiveSize: 70px;

    $squircleDefaultSize: 80px;
    $squircleActiveSize: 100px;

    .cms-tablist__squircle-container {
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      width: $squircleMobileDefaultSize + 14px;
      height: $squircleMobileDefaultSize + 14px;

      @include from('sm') {
        width: $squircleDefaultSize + 32px;
        height: $squircleDefaultSize + 32px;
      }
    }

    .cms-tablist__navbar-container {
      margin-bottom: 16px;
    }

    .cms-tablist__navbar {
      white-space: normal;
    }

    .cms-tablist__navbar-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      white-space: normal;
    }

    .cms-tablist__navbar--align-left .cms-tablist__navbar-content {
      justify-content: flex-start;
    }

    .cms-tablist__label {
      margin: 0;
      pointer-events: all;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      flex-direction: column;
      border: solid color(grey30) 2px;
      border-radius: 18px;
      width: $squircleMobileDefaultSize;
      height: $squircleMobileDefaultSize;
      padding: 0;
      box-sizing: border-box;
      color: color(grey200);
      font-size: 9px;
      line-height: 9px;
      font-weight: 500;
      transition: all 100ms linear;

      &--active {
        width: $squircleMobileActiveSize;
        height: $squircleMobileActiveSize;
        border-color: rgb(255, 0, 205); // This color has occured only once in designs thus not added to color.scss yet
        box-shadow: 0 8px 8px -10px color(grey100);
      }

      @include from('sm') {
        font-size: 12px;
        line-height: 12px;
        width: $squircleDefaultSize;
        height: $squircleDefaultSize;
        border-radius: 12px;

        &--active {
          width: $squircleActiveSize;
          height: $squircleActiveSize;
          border-color: color(corePurple);
          box-shadow: 0 8px 8px -10px color(grey100);
        }
      }
    }

    .cms-tablist__tab-icon {
      pointer-events: none;
      font-size: 22px;
      height: 22px;

      @include from('sm') {
        font-size: 26px;
        height: 26px;
      }
    }

    .cms-tablist__tab-number {
      @include teliaSansHeading;
      font-size: 22px;
      line-height: 20px;
      color: color(grey200);
      pointer-events: none;

      @include from('sm') {
        font-size: 24px;
        line-height: 24px;
      }
    }

    .cms-tablist__tab-text {
      margin-top: 4px;
      width: 100%;
    }
  }
}
