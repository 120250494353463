.carousel {
  // Overflow hidden needed here to make the initial loading of the carousel calculate slick component widths correctly. Not exacly sure why.
  overflow: hidden;
  padding: 32px 0 32px;

  @include from('md') {
    padding: 48px 0 40px;
  }

  @include from('lg') {
    padding: 48px 0 48px;
  }

  @include from('xlg') {
    padding: 64px 0 52px;
  }

  &--padding {
    &-top-none {
      padding-top: 0;
    }

    &-top-small {
      padding-top: 16px;
    }
  
    &-top-medium {
      padding-top: 32px;
    }
  
    &-top-large {
      padding-top: 48px;
    }

    &-bottom-none {
      padding-bottom: 0;
    }
  
    &-bottom-small {
      padding-bottom: 16px;
    }
  
    &-bottom-medium {
      padding-bottom: 32px;
    }
  
    &-bottom-large {
      padding-bottom: 48px;
    }
  }

  .slick-track {
    display: flex;
  }

  p {
    max-width: none !important;
  }

  &__heading {
    @include from('lg') {
      justify-content: center;
    }
  }

  &--track-white {
    background-color: color(white);

    .slick-track {
      background-color: color(white);
    }
  }

  &--track-lightGrey {
    background-color: color(grey20);

    .slick-track {
      background-color: color(grey20);
    }
  }

  &--track-darkGrey {
    background-color: color(grey100);

    .slick-track {
      background-color: color(grey100);
    }
  }

  &--track-black {
    background-color: color(black);

    .slick-track {
      background-color: color(black);
    }
  }

  &--track-deepPurple {
    background-color: color(deepPurple);

    .slick-track {
      background-color: color(deepPurple);
    }
  }

  &--track-lightPurple {
    background-color: color(lightPurple);

    .slick-track {
      background-color: color(lightPurple);
    }
  }

  &--track-deepBeige {
    background-color: color(deepBeige);

    .slick-track {
      background-color: color(deepBeige);
    }
  }

  &--track-lightBeige {
    background-color: color(lightBeige);

    .slick-track {
      background-color: color(lightBeige);
    }
  }

  &--track-transparent {
    background-color: transparent;

    .slick-track {
      background-color: transparent;
    }
  }

  &__heading {
    h2 {
      margin-bottom: 1em;
    }
  }

  &__slick {
    @include to('xxs') {
      // Make the cards have some room around them on small screens when gutters are enabled
      [class*="grid__col-"] {
        padding-left: 6px;
        padding-right: 6px;
      }

      &.grid--bleed {
        [class*="grid__col-"] {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    @include to('xs') {
      .carousel--partial-slides & {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  &__arrow-container {
    position: absolute;
    top: 50%;
    width: 100%;

    .carousel--has-dots & {
      $halfDotsNavigationHeight: 16px;
      top: calc(50% - #{$halfDotsNavigationHeight});

      @include from('xlg') {
        $halfDotsNavigationHeight: 21px;
        top: calc(50% - #{$halfDotsNavigationHeight});
      }
    }
  }

  &__arrow-content {
    position: relative;
    color: color(corePurple);
    background-color: color(white);
    transition: opacity 0.3s, background-color 0.3s, color 0.3s;
    font-size: 20px;
    opacity: 0.6;
    width: 48px;
    height: 48px;

    &:hover {
      background-color: color(corePurple);
      color: color(white);
    }

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: z(scrollArrow);
    border-radius: 48px;
    box-shadow: 3px 3px 6px 0px rgba(color(black), 0.3);
    overflow: hidden;
    cursor: pointer;

    &:focus {
      outline: solid 3px #40b3ff;
      outline-offset: 2px;
  
      &:not(:focus-visible) {
        outline: none;
      }
    }

    // This class added to the arrow when at the end/beginning of the carousel
    &.slick-disabled {
      pointer-events: none;
      display: none !important;
    }

    &--right {
      right: -12px;

      @include from('sm') {
        right: -16px;
      }

      @include from('md') {
        right: -24px;
      }
    }

    &--left {
      left: -12px;

      @include from('sm') {
        left: -16px;
      }

      @include from('md') {
        left: -24px;
      }
    }
  }

  &__content-wrapper {
    position: relative;
  }

  &__card-wrapper {
    // Padding to make room for the card shadow
    padding: 9px;
    padding-top: 5px;
    height: 100%;

    .carousel--no-cards & {
      padding: 0;
    }
  }

  &__card {
    background-color: color(white);
    box-shadow: 0px 2px 8px rgba(color(black), 0.3);
    border-radius: 8px;
    padding: 1em;
    letter-spacing: normal;
    word-spacing: normal;
    height: 100%;
    overflow: hidden;

    &--white {
      background-color: color(white);
    }
  
    &--lightGrey {
      background-color: color(grey20);
    }
  
    &--darkGrey {
      background-color: color(grey100);
    }
  
    &--black {
      background-color: color(black);
    }
  
    &--deepPurple {
      background-color: color(deepPurple);
    }
  
    &--lightPurple {
      background-color: color(lightPurple);
    }
  
    &--deepBeige {
      background-color: color(deepBeige);
    }
  
    &--lightBeige {
      background-color: color(lightBeige);
    }
  
    &--transparent {
      background-color: transparent;
    }

    .carousel--no-cards & {
      background-color: transparent;
      box-shadow: none;
      border-radius: 0;
      padding: 0;
    }

    .carousel--no-card-padding & {
      padding: 0;
    }

    & > * {
      box-shadow: none !important;

      &:hover {
        transform: none !important;
      }
    }

    & > .cms-plan-card {
      height: 100% !important;
    }
  }

  .slick-slide {
    height: auto;

    > div {
      display: flex;
      height: 100%;
    }
  }

  &--partial-slides .slick-slide {
    @include to('xs') {
      width: 72vw !important;
    }
  }

  .slick-dots {
    @include gutter-padding();
    display: flex;
    max-width: none;
    justify-content: center;
    margin: 20px 0 0;

    @include from('sm') {
      justify-content: flex-start;
    }

    @include from('md') {
      margin: 24px 0 0;
    }

    @include from('xlg') {
      margin: 32px 0 0;
    }

    li {
      list-style-type: none;
      line-height: 1px;
      margin: 0;
      position: relative;

      button {
        color: transparent;
        background-color: rgba(color(grey200), 0.1);
        border-radius: 50%;
        width: 8px;
        height: 8px;
        padding: 0;
        margin: 0 3px;
        border: 0;
        outline: 0;
        cursor: pointer;

        &:focus {
          outline: solid 3px #40b3ff;
          outline-offset: 2px;
      
          &:not(:focus-visible) {
            outline: none;
          }
        }

        @include from('xlg') {
          width: 10px;
          height: 10px;
          margin: 0 4px;
        }
      }

      &.slick-active button {
        background-color: color(corePurple);
      }
    }
  }
}
