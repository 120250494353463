.bulletin-list {

  &__item {
    border-top: 1px solid color(grey40);

    &__link::after {
      clear: both;
      content: "";
      display: table;
    }
    
    &__link {
      display: block;
      padding: 16px 0;
      font-weight: bold;
    }
     
    &__date {
      float: left;
      width: 100px;
      color: color(black);
      font-weight: normal;
    }
    
    &__title {
      float: left;
      font-weight: bold;
      width: calc(100% - 100px);
      color: color(black);

      &:hover {
        color: color(corePurple);
      }
    }
  }

  &__aside {
    @include from ('md') {
      margin-bottom: 50px;
      margin-top: -32px;
    }
  }  
}