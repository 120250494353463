.big-x {
  z-index: z(forward);
  position: relative;
  background-color: color(white);

  &__list {
    padding-top: 5px;

    @include from('md') {
      justify-content: space-around;
      text-align: center;
      padding-top: 0;
    }
  }
}
