.helppi-multi-service-selection {
  display: none;
  height: 100%;

  &__list-container {
    height: 80%;
    overflow: auto;
    width: 100%;
  }

  &__list-single {
    background-color: color(white);
    border-radius: 8px;
    color: color(grey200);
    font-size: 20px;
    height: 96px;
    margin-top: 4px;
    padding-bottom: 24px;
    padding-left: 22px;
    padding-top: 24px;
    width: 100%;

    &:hover,
    &:active,
    &--selected {
      border: 3px solid color(blue);
      cursor: pointer;
    }

    &-logo {
      float: left;
      font-size: 48px;
      margin-right: 16px;
    }

    &-title {
      font-weight: normal;
    }

    &-subtitle {
      font-weight: bold;
    }
  }

  &__buttons-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    text-align: center;
    width: 100%;
  }

  &__button-primary ,
  &__button-secondary {
    font-size: 14px;
    font-weight: bold;
    width: 233px;

    span {
      margin: 0 auto;
    }
  }
}
