/*! Reflex v1.5.0 - https://github.com/leejordan/reflex */
/*
 *
 * Reflex is a flexbox grid which provides a way to take advantage of emerging
 * flexbox support while providing a fall back to inline-block on older browsers
 *
 * To disable legacy support and build a flexbox only grid, set the variable legacy-support to false
 *
 * Built by Lee Jordan G.C.S.E.
 * email: ldjordan@gmail.com
 * github: https://github.com/leejordan
 *
 * Structure and calculations are inspired by twitter bootstrap
 *
 */
.grid {
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  *display: inline;
  zoom: 1;
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
  max-width: 100%;
  letter-spacing: -0.31em !important;
  *letter-spacing: normal !important;
  word-spacing: -0.43em !important;
  list-style-type: none;
}
.grid:before, .grid:after {
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
  max-width: 100%;
}
.grid *:before,
.grid *:after {
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
}
.grid .grid {
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}
.grid * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.grid *:before,
.grid *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

[class*=grid__col-] {
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  *display: inline;
  zoom: 1;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
  position: relative;
  width: 100%;
  vertical-align: top;
  padding: 1em 1em;
}

.grid__cell {
  position: relative;
  display: block;
  -ms-flex: 1 1 auto;
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
}

:-ms-fullscreen .grid__cell, :root .grid__cell {
  width: 100%;
}

.grid__col-12 {
  width: 100%;
  *width: 99.9%;
}

.grid__col-11 {
  width: 91.6666666667%;
  *width: 91.5666666667%;
}

.grid__col-10 {
  width: 83.3333333333%;
  *width: 83.2333333333%;
}

.grid__col-9 {
  width: 75%;
  *width: 74.9%;
}

.grid__col-8 {
  width: 66.6666666667%;
  *width: 66.5666666667%;
}

.grid__col-7 {
  width: 58.3333333333%;
  *width: 58.2333333333%;
}

.grid__col-6 {
  width: 50%;
  *width: 49.9%;
}

.grid__col-5 {
  width: 41.6666666667%;
  *width: 41.5666666667%;
}

.grid__col-4 {
  width: 33.3333333333%;
  *width: 33.2333333333%;
}

.grid__col-3 {
  width: 25%;
  *width: 24.9%;
}

.grid__col-2 {
  width: 16.6666666667%;
  *width: 16.5666666667%;
}

.grid__col-1 {
  width: 8.3333333333%;
  *width: 8.2333333333%;
}

@media (min-width: 480px) {
  .grid__col-xs-12 {
    width: 100%;
    *width: 99.9%;
  }
  .grid__col-xs-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }
  .grid__col-xs-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }
  .grid__col-xs-9 {
    width: 75%;
    *width: 74.9%;
  }
  .grid__col-xs-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }
  .grid__col-xs-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }
  .grid__col-xs-6 {
    width: 50%;
    *width: 49.9%;
  }
  .grid__col-xs-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }
  .grid__col-xs-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }
  .grid__col-xs-3 {
    width: 25%;
    *width: 24.9%;
  }
  .grid__col-xs-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }
  .grid__col-xs-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}
@media (min-width: 768px) {
  .grid__col-sm-12 {
    width: 100%;
    *width: 99.9%;
  }
  .grid__col-sm-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }
  .grid__col-sm-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }
  .grid__col-sm-9 {
    width: 75%;
    *width: 74.9%;
  }
  .grid__col-sm-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }
  .grid__col-sm-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }
  .grid__col-sm-6 {
    width: 50%;
    *width: 49.9%;
  }
  .grid__col-sm-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }
  .grid__col-sm-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }
  .grid__col-sm-3 {
    width: 25%;
    *width: 24.9%;
  }
  .grid__col-sm-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }
  .grid__col-sm-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}
@media (min-width: 992px) {
  .grid__col-md-12 {
    width: 100%;
    *width: 99.9%;
  }
  .grid__col-md-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }
  .grid__col-md-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }
  .grid__col-md-9 {
    width: 75%;
    *width: 74.9%;
  }
  .grid__col-md-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }
  .grid__col-md-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }
  .grid__col-md-6 {
    width: 50%;
    *width: 49.9%;
  }
  .grid__col-md-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }
  .grid__col-md-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }
  .grid__col-md-3 {
    width: 25%;
    *width: 24.9%;
  }
  .grid__col-md-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }
  .grid__col-md-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}
@media (min-width: 1200px) {
  .grid__col-lg-12 {
    width: 100%;
    *width: 99.9%;
  }
  .grid__col-lg-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }
  .grid__col-lg-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }
  .grid__col-lg-9 {
    width: 75%;
    *width: 74.9%;
  }
  .grid__col-lg-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }
  .grid__col-lg-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }
  .grid__col-lg-6 {
    width: 50%;
    *width: 49.9%;
  }
  .grid__col-lg-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }
  .grid__col-lg-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }
  .grid__col-lg-3 {
    width: 25%;
    *width: 24.9%;
  }
  .grid__col-lg-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }
  .grid__col-lg-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}
@media (min-width: 1600px) {
  .grid__col-xlg-12 {
    width: 100%;
    *width: 99.9%;
  }
  .grid__col-xlg-11 {
    width: 91.6666666667%;
    *width: 91.5666666667%;
  }
  .grid__col-xlg-10 {
    width: 83.3333333333%;
    *width: 83.2333333333%;
  }
  .grid__col-xlg-9 {
    width: 75%;
    *width: 74.9%;
  }
  .grid__col-xlg-8 {
    width: 66.6666666667%;
    *width: 66.5666666667%;
  }
  .grid__col-xlg-7 {
    width: 58.3333333333%;
    *width: 58.2333333333%;
  }
  .grid__col-xlg-6 {
    width: 50%;
    *width: 49.9%;
  }
  .grid__col-xlg-5 {
    width: 41.6666666667%;
    *width: 41.5666666667%;
  }
  .grid__col-xlg-4 {
    width: 33.3333333333%;
    *width: 33.2333333333%;
  }
  .grid__col-xlg-3 {
    width: 25%;
    *width: 24.9%;
  }
  .grid__col-xlg-2 {
    width: 16.6666666667%;
    *width: 16.5666666667%;
  }
  .grid__col-xlg-1 {
    width: 8.3333333333%;
    *width: 8.2333333333%;
  }
}
.grid__col-auto {
  -ms-flex: 1 0 auto;
  -webkit-flex: 1 0 0px;
  flex: 1 0 0px;
  width: auto !important;
  max-width: 100%;
}

@media (min-width: 480px) {
  .grid__col-xs-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto !important;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .grid__col-sm-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto !important;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .grid__col-md-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto !important;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .grid__col-lg-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto !important;
    max-width: 100%;
  }
}
@media (min-width: 1600px) {
  .grid__col-xlg-auto {
    -ms-flex: 1 0 auto;
    -webkit-flex: 1 0 0px;
    flex: 1 0 0px;
    width: auto !important;
    max-width: 100%;
  }
}
.grid--order-12 {
  -ms-flex-order: 12;
  -webkit-order: 12;
  order: 12;
}

.grid--order-11 {
  -ms-flex-order: 11;
  -webkit-order: 11;
  order: 11;
}

.grid--order-10 {
  -ms-flex-order: 10;
  -webkit-order: 10;
  order: 10;
}

.grid--order-9 {
  -ms-flex-order: 9;
  -webkit-order: 9;
  order: 9;
}

.grid--order-8 {
  -ms-flex-order: 8;
  -webkit-order: 8;
  order: 8;
}

.grid--order-7 {
  -ms-flex-order: 7;
  -webkit-order: 7;
  order: 7;
}

.grid--order-6 {
  -ms-flex-order: 6;
  -webkit-order: 6;
  order: 6;
}

.grid--order-5 {
  -ms-flex-order: 5;
  -webkit-order: 5;
  order: 5;
}

.grid--order-4 {
  -ms-flex-order: 4;
  -webkit-order: 4;
  order: 4;
}

.grid--order-3 {
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
}

.grid--order-2 {
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
}

.grid--order-1 {
  -ms-flex-order: 1;
  -webkit-order: 1;
  order: 1;
}

.grid--order-0 {
  -ms-flex-order: 0;
  -webkit-order: 0;
  order: 0;
}

@media only screen and (min-width: 480px) {
  .grid--order-12-xs {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }
  .grid--order-11-xs {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }
  .grid--order-10-xs {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }
  .grid--order-9-xs {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }
  .grid--order-8-xs {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }
  .grid--order-7-xs {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }
  .grid--order-6-xs {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }
  .grid--order-5-xs {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }
  .grid--order-4-xs {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }
  .grid--order-3-xs {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .grid--order-2-xs {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .grid--order-1-xs {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .grid--order-0-xs {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
@media only screen and (min-width: 768px) {
  .grid--order-12-sm {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }
  .grid--order-11-sm {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }
  .grid--order-10-sm {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }
  .grid--order-9-sm {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }
  .grid--order-8-sm {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }
  .grid--order-7-sm {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }
  .grid--order-6-sm {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }
  .grid--order-5-sm {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }
  .grid--order-4-sm {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }
  .grid--order-3-sm {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .grid--order-2-sm {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .grid--order-1-sm {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .grid--order-0-sm {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
@media only screen and (min-width: 992px) {
  .grid--order-12-md {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }
  .grid--order-11-md {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }
  .grid--order-10-md {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }
  .grid--order-9-md {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }
  .grid--order-8-md {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }
  .grid--order-7-md {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }
  .grid--order-6-md {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }
  .grid--order-5-md {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }
  .grid--order-4-md {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }
  .grid--order-3-md {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .grid--order-2-md {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .grid--order-1-md {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .grid--order-0-md {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
@media only screen and (min-width: 1200px) {
  .grid--order-12-lg {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }
  .grid--order-11-lg {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }
  .grid--order-10-lg {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }
  .grid--order-9-lg {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }
  .grid--order-8-lg {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }
  .grid--order-7-lg {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }
  .grid--order-6-lg {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }
  .grid--order-5-lg {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }
  .grid--order-4-lg {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }
  .grid--order-3-lg {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .grid--order-2-lg {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .grid--order-1-lg {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .grid--order-0-lg {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
@media only screen and (min-width: 1600px) {
  .grid--order-12-xlg {
    -ms-flex-order: 12;
    -webkit-order: 12;
    order: 12;
  }
  .grid--order-11-xlg {
    -ms-flex-order: 11;
    -webkit-order: 11;
    order: 11;
  }
  .grid--order-10-xlg {
    -ms-flex-order: 10;
    -webkit-order: 10;
    order: 10;
  }
  .grid--order-9-xlg {
    -ms-flex-order: 9;
    -webkit-order: 9;
    order: 9;
  }
  .grid--order-8-xlg {
    -ms-flex-order: 8;
    -webkit-order: 8;
    order: 8;
  }
  .grid--order-7-xlg {
    -ms-flex-order: 7;
    -webkit-order: 7;
    order: 7;
  }
  .grid--order-6-xlg {
    -ms-flex-order: 6;
    -webkit-order: 6;
    order: 6;
  }
  .grid--order-5-xlg {
    -ms-flex-order: 5;
    -webkit-order: 5;
    order: 5;
  }
  .grid--order-4-xlg {
    -ms-flex-order: 4;
    -webkit-order: 4;
    order: 4;
  }
  .grid--order-3-xlg {
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
  }
  .grid--order-2-xlg {
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .grid--order-1-xlg {
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .grid--order-0-xlg {
    -ms-flex-order: 0;
    -webkit-order: 0;
    order: 0;
  }
}
.grid--bleed [class*=grid__col-] {
  padding: 0;
}

.grid--wrap {
  -ms-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.grid--no-wrap {
  -ms-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.grid--wrap-reverse {
  -ms-flex-wrap: wrap-reverse;
  -webkit-flex-wrap: wrap-reverse;
  flex-wrap: wrap-reverse;
}

.grid--direction-row {
  -ms-flex-direction: row;
  -webkit-flex-direction: row;
  flex-direction: row;
}

.grid--direction-row-reverse {
  -ms-flex-direction: row-reverse;
  -webkit-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.grid--direction-column {
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.grid--direction-column-reverse {
  -ms-flex-direction: column-reverse;
  -webkit-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.grid--align-start {
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.grid--align-end {
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
.grid--align-end [class*=grid__col-] {
  vertical-align: bottom;
}

.grid--align-center {
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.grid--align-center [class*=grid__col-] {
  vertical-align: middle;
}

.grid--align-baseline {
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}
.grid--align-baseline [class*=grid__col-] {
  vertical-align: baseline;
}

.grid--align-content-start {
  -ms-flex-line-pack: start;
  -webkit-align-content: flex-start;
  align-content: flex-start;
}

.grid--align-content-end {
  -ms-flex-line-pack: end;
  -webkit-align-content: flex-end;
  align-content: flex-end;
}
.grid--align-content-end [class*=grid__col-] {
  vertical-align: bottom;
}

.grid--align-content-center {
  -ms-flex-line-pack: center;
  -webkit-align-content: center;
  align-content: center;
}

.grid--align-content-space-between {
  -ms-flex-line-pack: space-between;
  -webkit-align-content: space-between;
  align-content: space-between;
}

.grid--align-content-space-around {
  -ms-flex-line-pack: space-around;
  -webkit-align-content: space-around;
  align-content: space-around;
}

.grid--align-self-stretch {
  -ms-flex-item-align: stretch;
  -webkit-align-self: stretch;
  align-self: stretch;
}

.grid--align-self-start {
  -ms-flex-item-align: start;
  -webkit-align-self: flex-start;
  align-self: flex-start;
}

.grid--align-self-end {
  -ms-flex-item-align: end;
  -webkit-align-self: flex-end;
  align-self: flex-end;
  vertical-align: bottom;
}

.grid--align-self-center {
  -ms-flex-item-align: center;
  -webkit-align-self: center;
  align-self: center;
  vertical-align: middle;
}

.grid--align-self-baseline {
  -ms-flex-item-align: baseline;
  -webkit-align-self: baseline;
  align-self: baseline;
  vertical-align: baseline;
}

.grid--justify-start {
  text-align: left;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}

.grid--justify-end {
  text-align: right;
  -moz-text-align-last: right;
  text-align-last: right;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
.grid--justify-end .grid__cell,
.grid--justify-end [class*=grid__col-] {
  text-align: left;
  text-align: start;
  text-align: initial;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  -moz-text-align-last: initial;
  text-align-last: left;
  text-align-last: start;
  text-align-last: initial;
}

.grid--justify-center {
  text-align: center;
  -moz-text-align-last: center;
  text-align-last: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}
.grid--justify-center .grid__cell,
.grid--justify-center [class*=grid__col-] {
  text-align: left;
  text-align: start;
  text-align: initial;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  -moz-text-align-last: initial;
  text-align-last: left;
  text-align-last: start;
  text-align-last: initial;
}

.grid--justify-space-between {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.grid--justify-space-between .grid__cell,
.grid--justify-space-between [class*=grid__col-] {
  text-align: left;
  text-align: start;
  text-align: initial;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  -moz-text-align-last: initial;
  text-align-last: left;
  text-align-last: start;
  text-align-last: initial;
}

.grid--justify-space-around {
  text-align: justify;
  -moz-text-align-last: justify;
  text-align-last: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}
.grid--justify-space-around .grid__cell,
.grid--justify-space-around [class*=grid__col-] {
  text-align: left;
  text-align: start;
  text-align: initial;
  -moz-text-align-last: left;
  -moz-text-align-last: start;
  -moz-text-align-last: initial;
  text-align-last: left;
  text-align-last: start;
  text-align-last: initial;
}

.grid__col--bleed {
  padding: 0;
}

.grid__col--bleed-x {
  padding: 1em 0;
}

.grid__col--bleed-y {
  padding: 0 1em;
}

.grid__col--bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  padding-bottom: 50%;
}

.grid__cell--padding-sm {
  padding: 0.5em;
}

.grid__cell--padding-md {
  padding: 1em;
}

.grid__cell--padding-lg {
  padding: 2em;
}

.grid__cell-img {
  display: block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex: 0 0 auto;
  -webkit-flex: 0 0 auto;
  flex: 0 0 auto;
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  width: 100%;
  height: auto;
}

.grid__cell-footer {
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  *display: inline;
  zoom: 1;
  width: 100%;
  margin-top: auto;
}

@media (max-width: 479px) {
  .hidden-xxs {
    display: none !important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

/**
 * Strips the unit from a given number-unit-combination and returns the number.
 * @link: http://stackoverflow.com/a/12335841/1779999
 * @usage: parse-int(10px) => 10
 */
/**
 * Simply adds the em unit to a given number. This is used twice in the
 * function underneath, thus the need for a separate function.
 */
/**
 * Calculates em values for a given list of (px or em) values and a given em
 * base. Multiply base values for nested em structures (last @usage example).
 * @usage: em(10px, 1.125) => .55556em
 *         em(24px, .875em) => 1.71429em
 *         em(12px 0 7em rgba(0, 0, 0, .7), 1.75) => .42857em 0 4em rgba(0, 0, 0, 0.7)
 *         em(12px) + em(3em) => 3.75em
 *         em(23px, em(53px)) => .43396em
 *         em(24px, 1.5 * 1.125) => .88889em
 */
/* Values from: https://react.styleguide.telia.fi/?path=/story/atoms-shadows--overview */
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong,
var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, article, aside,
canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section,
summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;
  font-weight: normal;
  letter-spacing: normal;
  background: #ffffff;
}
body.body--grey {
  background-color: #F2F2F2;
}
body.body--deepPurple {
  background-color: #29003e;
}
body.body--lightPurple {
  background-color: #F4E0FF;
}
body.body--deepBeige {
  background-color: #5C524E;
}
body.body--lightBeige {
  background-color: #F8EEE8;
}

body, html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.no-scroll, html.no-scroll {
  overflow: hidden;
  position: fixed;
  height: 100%;
}

ul {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 20px;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 20px;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

ol {
  display: block;
  list-style-type: decimal;
  -webkit-margin-before: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 20px;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 20px;
}

ul ul, ol ul {
  list-style-type: circle;
}

ol ul, ul ol, ul ul, ol ol {
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
}

* {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  background: transparent;
}

p {
  margin: 1em 0;
}

input:-webkit-autofill,
.input:-webkit-autofill,
#identifier:-webkit-autofill,
#password:-webkit-autofill {
  background-color: #FFF9EC !important;
}

table {
  width: 100%;
  table-layout: fixed;
  color: #3A3A3A;
  border-width: 0;
  border-spacing: 0;
}
table tr:nth-child(odd) {
  background-color: #F9F9F9;
}
table td {
  border-width: 0;
  padding: 0.75em 1.125em;
}
table thead {
  border-bottom: 1px solid #E0E0E0;
}
table thead tr {
  background-color: #ffffff !important;
}
table thead th {
  border-width: 0;
  padding: 0.75em 1.125em;
  text-align: left;
}
table.inverse tr:nth-child(odd) {
  background-color: #ffffff;
}
table.inverse tr:nth-child(even) {
  background-color: #F9F9F9;
}
table.inverse thead tr {
  background-color: #F9F9F9 !important;
}

.grid {
  max-width: 1920px;
  margin: 0 auto;
  padding: 0 5%;
}
@media (min-width: 481px) {
  .grid {
    padding: 0 34px;
  }
}
@media (min-width: 601px) {
  .grid {
    padding: 0 36px;
  }
}
@media (min-width: 1081px) {
  .grid {
    padding: 0 96px;
  }
}
@media (min-width: 1441px) {
  .grid {
    padding: 0 108px;
  }
}
.grid .grid:not(.grid--with-padding) {
  padding: 0;
}
@media (min-width: 481px) {
  .grid .grid:not(.grid--with-padding) {
    margin: 0 -6px;
    width: calc(100% + 12px);
  }
}
@media (min-width: 601px) {
  .grid .grid:not(.grid--with-padding) {
    margin: 0 -8px;
    width: calc(100% + 16px);
  }
}
@media (min-width: 1081px) {
  .grid .grid:not(.grid--with-padding) {
    margin: 0 -12px;
    width: calc(100% + 24px);
  }
}
.grid--half {
  max-width: 960px;
}

[class*=grid__col-] {
  padding-left: 0;
  padding-right: 0;
}
@media (min-width: 481px) {
  [class*=grid__col-] {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (min-width: 601px) {
  [class*=grid__col-] {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 1081px) {
  [class*=grid__col-] {
    padding-left: 12px;
    padding-right: 12px;
  }
}

.grid__col--bleed {
  padding-left: 0;
  padding-right: 0;
}

.grid__col-xxs-none {
  width: auto;
}

@media (min-width: 481px) {
  .grid__col-xs-none {
    width: auto;
  }
}

@media (min-width: 601px) {
  .grid__col-sm-none {
    width: auto;
  }
}

@media (min-width: 841px) {
  .grid__col-md-none {
    width: auto;
  }
}

@media (min-width: 1081px) {
  .grid__col-lg-none {
    width: auto;
  }
}

@media (min-width: 1441px) {
  .grid__col-xlg-none {
    width: auto;
  }
}

.hidden {
  display: none !important;
}

.box {
  background-color: #ffffff;
  margin-bottom: 1em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}
.box--rounded {
  border-radius: 5px;
}

.main-container--full-height {
  height: 100%;
}

.cms-gutter {
  height: 8px;
}
.cms-gutter--sm {
  height: 8px;
}
.cms-gutter--md {
  height: 16px;
}
.cms-gutter--lg {
  height: 24px;
}

/* Try to remove browser native button styles */
.cms-clear-button-styles {
  padding: 0;
  border: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  color: inherit;
  line-height: 0;
  text-align: left;
}

/**
 * Strips the unit from a given number-unit-combination and returns the number.
 * @link: http://stackoverflow.com/a/12335841/1779999
 * @usage: parse-int(10px) => 10
 */
/**
 * Simply adds the em unit to a given number. This is used twice in the
 * function underneath, thus the need for a separate function.
 */
/**
 * Calculates em values for a given list of (px or em) values and a given em
 * base. Multiply base values for nested em structures (last @usage example).
 * @usage: em(10px, 1.125) => .55556em
 *         em(24px, .875em) => 1.71429em
 *         em(12px 0 7em rgba(0, 0, 0, .7), 1.75) => .42857em 0 4em rgba(0, 0, 0, 0.7)
 *         em(12px) + em(3em) => 3.75em
 *         em(23px, em(53px)) => .43396em
 *         em(24px, 1.5 * 1.125) => .88889em
 */
/* Values from: https://react.styleguide.telia.fi/?path=/story/atoms-shadows--overview */
.button {
  font-family: inherit;
  font-size: 12px;
  letter-spacing: 0.8px;
  line-height: 18px;
  font-weight: bold;
  white-space: nowrap;
  padding: 0 2em;
  height: 40px;
  margin: 6px;
  border: 2px solid transparent;
  color: #ffffff;
  background-color: #990AE3;
  border-radius: 999px;
  text-transform: uppercase;
  transition: all 0.2s;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;
}
.button:focus {
  outline: solid 3px #40b3ff;
  outline-offset: 2px;
}
.button:focus:not(:focus-visible) {
  outline: none;
}
[class*=grid__col-] > .button {
  align-self: flex-start;
}
.grid--justify-center > [class*=grid__col-] > .button {
  align-self: center;
}
.grid--justify-end > [class*=grid__col-] > .button {
  align-self: flex-end;
}
button.button {
  display: inline-block;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-moz-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .button {
    box-shadow: none;
  }
}
.button__wrapper {
  display: flex;
  align-items: center;
}
@media (min-width: 841px) {
  .button {
    height: 44px;
    font-size: 14px;
    padding: 0 2em;
  }
}
.button:link, .button:visited {
  color: #ffffff;
}
.button:hover, .button--hover {
  color: #ffffff;
  background-color: #CC00FF;
}
.button--purchase {
  background-color: #00CC66;
}
.button--purchase:hover, .button--purchase.button--hover {
  background-color: #32FF00;
}
.button:disabled, .button--disabled {
  background-color: #C5C5C5;
  cursor: default;
  pointer-events: none;
}
.button--purchase-secondary {
  background-color: rgba(0, 204, 102, 0);
  color: #00CC66;
  border-color: #00CC66;
}
.button--purchase-secondary:link, .button--purchase-secondary:visited {
  color: #00CC66;
}
.button--purchase-secondary:hover, .button--purchase-secondary.button--hover {
  background-color: #00CC66;
  color: #ffffff;
  border-color: #00CC66;
}
.button--purchase-secondary:disabled, .button--purchase-secondary.button--disabled {
  color: #C5C5C5;
  border-color: #C5C5C5 !important;
  background-color: transparent;
}
.button--secondary {
  background-color: rgba(153, 10, 227, 0);
  color: #990AE3;
  border-color: #990AE3;
}
.button--secondary:link, .button--secondary:visited {
  color: #990AE3;
}
.button--secondary:hover, .button--secondary.button--hover {
  background-color: #990AE3;
  color: #ffffff;
  border-color: #990AE3;
}
.button--secondary:disabled, .button--secondary.button--disabled {
  color: #C5C5C5;
  border-color: #C5C5C5 !important;
  background-color: transparent;
}
.button--text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.button--link {
  background-color: transparent;
  color: #990AE3;
  border: 0 transparent;
  text-transform: none;
  letter-spacing: 0;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  font-size: 14px;
}
@media (min-width: 841px) {
  .button--link {
    font-size: 16px;
  }
  .button--link .icon {
    margin-top: 1px;
  }
}
.button--link:link, .button--link:visited {
  color: #990AE3;
}
.button--link:hover, .button--link.button--hover {
  background-color: transparent;
  color: #CC00FF;
  border-color: transparent;
}
.button--link.button--disabled {
  color: #C5C5C5;
  border-color: transparent;
  background-color: transparent;
}
.button--app-store, .button--google-play, .button--windows-store {
  background-repeat: no-repeat;
  background-position: center;
  min-width: 158px;
  background-color: #000000;
  border-color: #000000;
  overflow: hidden;
  background-size: 100px auto;
  height: 40px;
}
@media (min-width: 841px) {
  .button--app-store, .button--google-play, .button--windows-store {
    height: 44px;
  }
}
.button--app-store:hover, .button--google-play:hover, .button--windows-store:hover {
  background-color: #000000;
  border-color: #000000;
}
.button--app-store .button--text,
.button--app-store .icon, .button--google-play .button--text,
.button--google-play .icon, .button--windows-store .button--text,
.button--windows-store .icon {
  display: none;
}
.button--app-store {
  background-image: url("/.resources/pride-ui-module/webresources/assets/images/appstore/desktop_AppStore@2x.png");
}
.button--google-play {
  background-image: url("/.resources/pride-ui-module/webresources/assets/images/appstore/desktop_googlePlay@2x.png");
}
.button--windows-store {
  background-image: url("/.resources/pride-ui-module/webresources/assets/images/appstore/desktop_WindowsStore@2x.png");
}
@media (min-width: 601px) {
  .button--dark {
    background-color: #ffffff;
    color: #990AE3;
  }
  .button--dark:link, .button--dark:visited {
    color: #990AE3;
  }
  .button--dark:hover, .button--dark.button--hover {
    background-color: #CC00FF;
    color: #ffffff;
  }
  .button--dark:disabled, .button--dark.button--disabled {
    color: #A0A0A0;
    background-color: #F2F2F2;
  }
  .button--dark.button--purchase {
    color: #ffffff;
    background-color: #00CC66;
  }
  .button--dark.button--purchase:hover, .button--dark.button--purchase.button--hover {
    background-color: #32FF00;
  }
  .button--dark.button--purchase:disabled, .button--dark.button--purchase.button--disabled {
    color: #A0A0A0;
    background-color: #F2F2F2;
  }
  .button--dark.button--secondary {
    background-color: transparent;
    color: #ffffff;
    border-color: #ffffff;
  }
  .button--dark.button--secondary:hover, .button--dark.button--secondary.button--hover {
    border-color: #ffffff;
    background-color: #ffffff;
    color: #990AE3;
  }
  .button--dark.button--secondary:disabled, .button--dark.button--secondary.button--disabled {
    color: #C5C5C5;
    background-color: transparent;
  }
}
.button--dark:not(.button--not-dark-in-mobile) {
  background-color: #ffffff;
  color: #990AE3;
}
.button--dark:not(.button--not-dark-in-mobile):link, .button--dark:not(.button--not-dark-in-mobile):visited {
  color: #990AE3;
}
.button--dark:not(.button--not-dark-in-mobile):hover, .button--dark:not(.button--not-dark-in-mobile).button--hover {
  background-color: #CC00FF;
  color: #ffffff;
}
.button--dark:not(.button--not-dark-in-mobile):disabled, .button--dark:not(.button--not-dark-in-mobile).button--disabled {
  color: #A0A0A0;
  background-color: #F2F2F2;
}
.button--dark:not(.button--not-dark-in-mobile).button--purchase {
  color: #ffffff;
  background-color: #00CC66;
}
.button--dark:not(.button--not-dark-in-mobile).button--purchase:hover, .button--dark:not(.button--not-dark-in-mobile).button--purchase.button--hover {
  background-color: #32FF00;
}
.button--dark:not(.button--not-dark-in-mobile).button--purchase:disabled, .button--dark:not(.button--not-dark-in-mobile).button--purchase.button--disabled {
  color: #A0A0A0;
  background-color: #F2F2F2;
}
.button--dark:not(.button--not-dark-in-mobile).button--secondary {
  background-color: transparent;
  color: #ffffff;
  border-color: #ffffff;
}
.button--dark:not(.button--not-dark-in-mobile).button--secondary:hover, .button--dark:not(.button--not-dark-in-mobile).button--secondary.button--hover {
  border-color: #ffffff;
  background-color: #ffffff;
  color: #990AE3;
}
.button--dark:not(.button--not-dark-in-mobile).button--secondary:disabled, .button--dark:not(.button--not-dark-in-mobile).button--secondary.button--disabled {
  color: #C5C5C5;
  background-color: transparent;
}
.button .icon {
  font-size: 10px;
  margin-left: 5px;
}
.button--icon-before .icon {
  font-size: 1.143em;
  margin-left: 0;
  margin-right: 9px;
  order: -1;
}
.button--small {
  height: 30px;
  padding: 6px 1em;
  font-size: 12px;
}
.button--pebble {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-weight: normal;
}
.button--no-margin {
  margin: 0;
}
.button--large {
  font-size: 12px;
  padding: 0 24px;
  height: 40px;
}
@media (min-width: 601px) {
  .button--large {
    font-size: 14px;
    padding: 0 24px;
    height: 48px;
  }
}
.button--xlarge {
  font-size: 12px;
  padding: 0 24px;
  height: 40px;
}
@media (min-width: 601px) {
  .button--xlarge {
    font-size: 16px;
    padding: 0 40px;
    height: 56px;
  }
}
.button--primary.button--hover-gradient:not(:disabled):not(.button--disabled) {
  background-color: #990AE3;
}
.button--primary.button--hover-gradient:not(:disabled):not(.button--disabled):hover {
  background-image: linear-gradient(135deg, #CC00FF 0%, #CC00FF 66%, #CC00FF 69%, #990AE3 86%, #990AE3 100%);
  background-size: 600% 600%;
  animation: primary-button-keyframe ease-out 1.7s;
  animation-fill-mode: forwards;
}
@keyframes primary-button-keyframe {
  0% {
    background-position: 100% 96%;
  }
  100% {
    background-position: 0% 5%;
  }
}

h1,
.h1 {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: 0;
  color: #222222;
  margin-top: 25px;
  margin-bottom: 11px;
}
@media (min-width: 481px) {
  h1,
  .h1 {
    font-size: 1.75rem;
    margin-bottom: 16px;
  }
}
@media (min-width: 841px) {
  h1,
  .h1 {
    font-size: 2.5rem;
    margin-bottom: 18px;
  }
}
@media (min-width: 1081px) {
  h1,
  .h1 {
    font-size: 3rem;
    margin-bottom: 21px;
  }
}
@media (min-width: 1441px) {
  h1,
  .h1 {
    font-size: 4rem;
    margin-bottom: 18px;
  }
}

h2,
.h2 {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1;
  letter-spacing: 0;
  color: #222222;
  margin-top: 43px;
  margin-bottom: 29px;
}
@media (min-width: 481px) {
  h2,
  .h2 {
    margin-top: 59px;
  }
}
@media (min-width: 601px) {
  h2,
  .h2 {
    margin-top: 59px;
  }
}
@media (min-width: 841px) {
  h2,
  .h2 {
    font-size: 1.75rem;
    margin-top: 58px;
  }
}
@media (min-width: 1081px) {
  h2,
  .h2 {
    font-size: 2.125rem;
  }
}
@media (min-width: 1441px) {
  h2,
  .h2 {
    font-size: 2.625rem;
  }
}
@media (min-width: 1921px) {
  h2,
  .h2 {
    font-size: 2.75rem;
  }
}

h3,
.h3 {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.375rem;
  line-height: 1;
  color: #222222;
  margin-top: 36px;
}
@media (min-width: 481px) {
  h3,
  .h3 {
    margin-bottom: 26px;
  }
}
@media (min-width: 841px) {
  h3,
  .h3 {
    font-size: 1.5rem;
  }
}
@media (min-width: 1081px) {
  h3,
  .h3 {
    font-size: 1.75rem;
  }
}
@media (min-width: 1441px) {
  h3,
  .h3 {
    font-size: 2.125rem;
  }
}

h4,
.h4 {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1;
  letter-spacing: -0.08px;
  color: #222222;
  margin: 1em 0;
}
@media (min-width: 841px) {
  h4,
  .h4 {
    font-size: 1.375rem;
  }
}
@media (min-width: 1081px) {
  h4,
  .h4 {
    font-size: 1.5rem;
  }
}

h5,
.h5 {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: normal;
  margin: 1em 0;
}
@media (min-width: 841px) {
  h5,
  .h5 {
    font-size: 1.25rem;
    line-height: 1.3;
  }
}

@media (min-width: 1081px) {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5 {
    max-width: 60vw;
  }
  .grid--justify-center h1,
  .grid--justify-center .h1,
  .grid--justify-center h2,
  .grid--justify-center .h2,
  .grid--justify-center h3,
  .grid--justify-center .h3,
  .grid--justify-center h4,
  .grid--justify-center .h4,
  .grid--justify-center h5,
  .grid--justify-center .h5 {
    margin-left: auto;
    margin-right: auto;
  }
  .grid--justify-end h1,
  .grid--justify-end .h1,
  .grid--justify-end h2,
  .grid--justify-end .h2,
  .grid--justify-end h3,
  .grid--justify-end .h3,
  .grid--justify-end h4,
  .grid--justify-end .h4,
  .grid--justify-end h5,
  .grid--justify-end .h5 {
    margin-left: auto;
  }
}
@media (min-width: 1441px) {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5 {
    max-width: 62.5vw;
  }
}
@media (min-width: 1921px) {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5 {
    max-width: 1260px;
  }
}
h1 a:link,
h1 a:visited,
.h1 a:link,
.h1 a:visited,
h2 a:link,
h2 a:visited,
.h2 a:link,
.h2 a:visited,
h3 a:link,
h3 a:visited,
.h3 a:link,
.h3 a:visited,
h4 a:link,
h4 a:visited,
.h4 a:link,
.h4 a:visited,
h5 a:link,
h5 a:visited,
.h5 a:link,
.h5 a:visited {
  color: inherit;
}
h1 a:hover,
h1 a:active,
.h1 a:hover,
.h1 a:active,
h2 a:hover,
h2 a:active,
.h2 a:hover,
.h2 a:active,
h3 a:hover,
h3 a:active,
.h3 a:hover,
.h3 a:active,
h4 a:hover,
h4 a:active,
.h4 a:hover,
.h4 a:active,
h5 a:hover,
h5 a:active,
.h5 a:hover,
.h5 a:active {
  color: #A0A0A0;
}

.heading-xl {
  font-size: 28px;
}
@media (min-width: 601px) {
  .heading-xl {
    font-size: 2.25rem;
  }
}
@media (min-width: 841px) {
  .heading-xl {
    font-size: 3.5rem;
  }
}
@media (min-width: 1081px) {
  .heading-xl {
    font-size: 4rem;
  }
}
@media (min-width: 1441px) {
  .heading-xl {
    font-size: 5.5rem;
  }
}
@media (min-width: 1921px) {
  .heading-xl {
    font-size: 8.125rem;
  }
}

.heading-small {
  font-size: 12px;
  letter-spacing: 1.55px;
  line-height: 16px;
}

p,
ol,
ul {
  font-size: 14px;
  line-height: 1.5;
  max-width: 600px;
}
@media (min-width: 841px) {
  p,
  ol,
  ul {
    font-size: 16px;
  }
}

.callout {
  font-size: 1.125rem;
  line-height: 1.22222;
}
@media (min-width: 601px) {
  .callout {
    font-size: 1.25rem;
  }
}
@media (min-width: 841px) {
  .callout {
    font-size: 1.5rem;
  }
}
@media (min-width: 1441px) {
  .callout {
    font-size: 1.875rem;
  }
}
.callout--purple {
  color: #990AE3;
}

.purplecircle ol {
  list-style-type: none;
  position: relative;
  /*This will match the IE browser */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .purplecircle ol {
    left: -20px;
  }
}
.purplecircle ol > li {
  counter-increment: step-counter;
  margin-bottom: 22px;
  margin-left: 16px;
  /*This will match the IE browser */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .purplecircle ol > li {
    margin-left: 35px;
  }
}
@supports (-moz-appearance: none) {
  .purplecircle ol > li {
    padding-left: 16px;
  }
}
@supports (-ms-ime-align: auto) {
  .purplecircle ol > li {
    padding-left: 16px;
  }
}
.purplecircle ol > li:before {
  content: counter(step-counter);
  position: absolute;
  left: 0;
  width: 1.4em;
  height: 1.4em;
  color: #ffffff;
  background-color: #990AE3;
  border-radius: 50%;
  text-align: center;
  margin-right: 10px;
  line-height: 22px;
}
@media (min-width: 841px) {
  .purplecircle ol > li:before {
    line-height: 24px;
  }
}

.paragraph-large {
  font-size: 1.125rem;
  line-height: 1.5;
  max-width: 600px;
}
@media (min-width: 841px) {
  .paragraph-large {
    font-size: 1.25rem;
  }
}

.paragraph-medium {
  font-size: 16px;
  line-height: 1.5;
  max-width: 600px;
}
@media (min-width: 841px) {
  .paragraph-medium {
    font-size: 1.125rem;
  }
}

.summary {
  font-size: 12px;
  line-height: 18px;
}

.caption {
  font-size: 14px;
  line-height: 20px;
  color: #990AE3;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-weight: bold;
}

.step-label {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #222222;
  background-color: #F2F2F2;
  display: inline-flex;
  width: 39px;
  height: 39px;
  font-size: 24px;
  line-height: 1;
  align-items: center;
  justify-content: center;
  border-radius: 39px;
  overflow: hidden;
}

a {
  color: #990AE3;
}
a:hover, a:active {
  color: #CC00FF;
}

.link--with-arrow, .white-link--with-arrow,
a.arrow {
  font-weight: bold;
}
.link--with-arrow:after, .white-link--with-arrow:after,
a.arrow:after {
  content: "";
  width: 10px;
  height: 16px;
  background-image: url("/.resources/pride-ui-module/webresources/assets//images/links/arrow-right.png");
  background-size: 12px;
  background-repeat: no-repeat;
  top: 6px;
  position: relative;
  display: inline-block;
  margin-left: 2px;
}
.link--with-arrow:hover:after, .white-link--with-arrow:hover:after,
a.arrow:hover:after {
  background-image: url("/.resources/pride-ui-module/webresources/assets//images/links/arrow-right-hover.png");
}

.white-link--with-arrow:link, .white-link--with-arrow:hover, .white-link--with-arrow:active, .white-link--with-arrow:visited {
  color: #ffffff;
}
.white-link--with-arrow:after {
  background-image: url("/.resources/pride-ui-module/webresources/assets//images/links/white-arrow-right.png");
}
.white-link--with-arrow:hover:after {
  background-image: url("/.resources/pride-ui-module/webresources/assets//images/links/white-arrow-right.png");
}

.modal-buttonlink:after, .modal-buttonlink--purple:after {
  content: "";
  width: 10px;
  height: 17px;
  background-image: url("/.resources/pride-ui-module/webresources/assets//images/links/white-arrow-right.png");
  background-size: 12px;
  background-repeat: no-repeat;
  top: 3px;
  position: relative;
  display: inline-block;
  margin-left: 5px;
}
.modal-buttonlink:hover:after, .modal-buttonlink--purple:hover:after {
  background-image: url("/.resources/pride-ui-module/webresources/assets//images/links/white-arrow-right.png");
}

.modal-buttonlink--purple:after {
  background-image: url("/.resources/pride-ui-module/webresources/assets//images/links/arrow-right.png");
}

.cms-blockquote {
  border-left: solid 2px #990AE3;
  padding-left: 12px;
  padding-right: 20%;
  margin: 1.4em 0;
  margin-left: 17px;
  font-size: 16px;
}
@media (min-width: 841px) {
  .cms-blockquote {
    padding-left: 15px;
    margin: 1.4em 0;
    margin-left: 24px;
    font-size: 18px;
    line-height: 1.7em;
  }
}

@font-face {
  font-family: Pebble;
  src: url("/.resources/pride-ui-module/webresources/assets//fonts/pebble/Pebble_W_Rg.eot");
  src: url("/.resources/pride-ui-module/webresources/assets//fonts/pebble/Pebble_W_Rg.eot?#iefix") format("embedded-opentype"), url("/.resources/pride-ui-module/webresources/assets//fonts/pebble/Pebble_W_Rg.woff2") format("woff2"), url("/.resources/pride-ui-module/webresources/assets//fonts/pebble/Pebble_W_Rg.woff") format("woff"), url("/.resources/pride-ui-module/webresources/assets//fonts/pebble/Pebble_W_Rg.ttf") format("truetype");
}
@font-face {
  font-family: TeliaSansHeading;
  src: url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSansHeading-Heading.otf") format("opentype"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSansHeading-Heading.ttf") format("truetype"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSansHeading-Heading.woff") format("woff"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSansHeading-Heading.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: TeliaSans;
  src: url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-Regular.otf") format("opentype"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-Regular.ttf") format("truetype"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-Regular.woff") format("woff"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: TeliaSans;
  src: url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-Medium.otf") format("opentype"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-Medium.ttf") format("truetype"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-Medium.woff") format("woff"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-Medium.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: TeliaSans;
  src: url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-Bold.otf") format("opentype"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-Bold.ttf") format("truetype"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-Bold.woff") format("woff"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: TeliaSans;
  src: url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-RegularItalic.otf") format("opentype"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-RegularItalic.ttf") format("truetype"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-RegularItalic.woff") format("woff"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-RegularItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: TeliaSans;
  src: url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-MediumItalic.otf") format("opentype"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-MediumItalic.ttf") format("truetype"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-MediumItalic.woff") format("woff"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-MediumItalic.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: TeliaSans;
  src: url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-BoldItalic.otf") format("opentype"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-BoldItalic.ttf") format("truetype"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-BoldItalic.woff") format("woff"), url("/.resources/pride-ui-module/webresources/assets//fonts/teliasans/TeliaSans-BoldItalic.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}
.telia-react-typography__helvetica {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.notification {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-weight: bold;
  font-size: 16px;
  color: #ffffff;
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  z-index: 1005;
  max-height: 180px;
  transition: max-height 0.6s ease-in-out 0.3s;
  background-color: #0099ff;
}
.notification--deleted {
  max-height: 0;
}
.notification__container {
  display: flex;
  flex-direction: row;
  min-height: 40px;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
}
.columnrow .notification__container {
  padding: 0 2em;
}
.notification--textbox-border .notification__container {
  padding: 0 1em;
}
@media (min-width: 841px) {
  .notification--textbox-border .notification__container {
    padding: 0 2em;
  }
}
.notification__text-content a {
  z-index: 1005;
}
.notification p {
  font-size: 14px;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  max-width: none;
  flex-grow: 1;
  justify-content: space-between;
}
.notification p a {
  margin-right: -12px;
}
.notification__text {
  display: inline-block;
  display: flex;
  margin: 8px 0 8px 16px;
  font-size: 14px;
  line-height: 20px;
  align-self: center;
  align-items: baseline;
  justify-content: space-between;
  flex-grow: 1;
  flex-wrap: nowrap;
}
.no-flexbox .notification__text {
  width: 90%;
  text-align: left;
}
.notification__text--textbox-style {
  max-width: none;
  line-height: 24px;
}
.notification__text--textbox-style .notification__text-content {
  padding-right: 0;
}
.notification__text a {
  display: inline-block;
  color: inherit;
  margin-top: 0;
  padding-top: 0;
  flex: 0 0 auto;
}
.notification__text a:hover {
  text-decoration: underline;
}
.notification__text-content {
  display: inline-block;
  margin-right: 16px;
  max-width: none;
  flex-basis: 100%;
}
.notification__text-content--padding-none {
  padding-right: 0;
}
.notification__text-content--padding-none p {
  max-width: none;
}
.notification__icon {
  display: inline-block;
  display: flex;
  align-items: flex-start;
  align-self: flex-start;
  padding: 10px 0;
  font-size: 20px;
}
.no-flexbox .notification__icon {
  max-width: 2.5%;
}
.notification .icon {
  display: inline-block;
  vertical-align: text-bottom;
}
.notification .icon--chevron-right {
  font-size: 10px;
  vertical-align: baseline;
}
.notification .icon--icon-checkmark {
  fill: white;
}
.notification .icon--close {
  font-size: 14px;
}
.notification__link {
  display: inline-block;
}
.notification__link:link, .notification__link:visited, .notification__link:active, .notification__link:hover {
  color: inherit;
}
.notification__link--grey {
  color: #707070 !important;
}
.notification__link--grey:link, .notification__link--grey:visited {
  color: #707070;
}
.notification__link--grey:active, .notification__link--grey:hover {
  color: #A0A0A0;
}
.notification__link--white {
  color: #ffffff !important;
}
.notification__link--white:link, .notification__link--white:visited {
  color: #ffffff;
}
.notification__link--white:active, .notification__link--white:hover {
  color: #CCCCCC;
}
.notification__link--hidden {
  visibility: hidden;
}
.notification__delete {
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
  align-self: flex-start;
  margin-left: auto;
  padding: 8px 0;
  line-height: 20px;
  float: right;
}
.notification__delete > button {
  background: none;
  border: none;
  padding: 2px 3px;
  font: inherit;
  cursor: pointer;
  outline: none;
  border-radius: 3px;
  margin-bottom: 3px;
}
.notification__delete > button:hover, .notification__delete > button:focus {
  box-shadow: 0 0 0 2px #ffffff;
}
.notification__delete > button svg {
  fill: white;
}
@media (min-width: 841px) {
  .notification__delete {
    margin-left: 24px;
  }
}
@media (min-width: 1441px) {
  .notification__delete {
    margin-left: 64px;
  }
}
.no-flexbox .notification__delete {
  max-width: 2.5%;
  text-align: right;
  vertical-align: middle;
}
.notification__delete--textbox-margin {
  align-self: flex-start;
  line-height: 1;
}
.notification--grey {
  color: #707070;
}
.notification--blue {
  background-color: #0099ff;
}
.notification--blue:hover {
  background-color: #00BEFF;
}
.notification--green {
  background-color: #00CC66;
}
.notification--green:hover {
  background-color: #32FF00;
}
.notification--red {
  background-color: #E4175C;
}
.notification--red:hover {
  background-color: #E4175C;
}
.notification--purple {
  background-color: #990AE3;
}
.notification--purple:hover {
  background-color: #CC00FF;
}
.notification--grey {
  background-color: #E0E0E0;
}
.notification--grey:hover {
  background-color: #F2F2F2;
}
.notification--textbox, .notification--textbox-border {
  color: #222222;
  margin: 20px 0;
  background-color: transparent;
}
.notification--textbox:hover, .notification--textbox-border:hover {
  background-color: transparent;
}
.notification--textbox .icon, .notification--textbox-border .icon {
  fill: #0099ff;
}
.notification--textbox .icon--chevron-right, .notification--textbox-border .icon--chevron-right {
  fill: #990AE3;
}
.notification--textbox .icon--close, .notification--textbox-border .icon--close {
  fill: #222222;
}
.notification--textbox .notification__icon, .notification--textbox-border .notification__icon {
  display: inline-block;
  align-self: flex-start;
  line-height: 1;
  padding: 12px 0;
  font-size: 24px;
}
.notification--textbox .notification__text, .notification--textbox-border .notification__text {
  margin-top: 11px;
  margin-bottom: 11px;
}
.notification--textbox .notification__link:link, .notification--textbox .notification__link:visited, .notification--textbox-border .notification__link:link, .notification--textbox-border .notification__link:visited {
  color: #990AE3;
}
.notification--textbox .notification__link:active, .notification--textbox .notification__link:hover, .notification--textbox-border .notification__link:active, .notification--textbox-border .notification__link:hover {
  color: #CC00FF;
}
.notification--textbox-border {
  border: solid 4px;
  border-color: #0099ff;
  transition: max-height 0.3s;
}
.notification--textbox-border.notification--deleted {
  border-bottom-width: 0;
  border-top-width: 0;
  margin: 0;
}
.notification--textbox-border.notification--blue {
  border-color: #0099ff;
}
.notification--textbox-border.notification--green {
  border-color: #00CC66;
}
.notification--textbox-border.notification--red {
  border-color: #E4175C;
}
.notification--textbox-border.notification--purple {
  border-color: #990AE3;
}
.notification--textbox-border.notification--grey {
  border-color: #E0E0E0;
}
.notification--textbox.notification--red .notification__icon .icon {
  fill: #E4175C;
}
.notification--textbox.notification--green .notification__icon .icon {
  fill: #00CC66;
}
.notification--textbox.notification--purple .notification__icon .icon {
  fill: #990AE3;
}
.notification--textbox.notification--grey .notification__icon .icon {
  fill: #E0E0E0;
}
.notification--hide-close .notification__delete {
  display: none;
}

.icon {
  width: 1em;
  height: 1em;
  fill: currentColor;
  pointer-events: none;
  overflow: hidden;
}

.animation--dots {
  display: inline-block;
  font-size: 10px;
  line-height: 18px;
  vertical-align: middle;
}
.animation--dots svg {
  line-height: 1px;
  animation-name: blink;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  /**
   * This makes sure that the starting style (opacity: .2)
   * of the animation is applied before the animation starts.
   * Otherwise we would see a short flash or would have
   * to set the default styling of the dots to the same
   * as the animation. Same applies for the ending styles.
   */
  animation-fill-mode: both;
}
.animation--dots svg:nth-child(2) {
  animation-delay: 0.2s;
}
.animation--dots svg:nth-child(3) {
  animation-delay: 0.4s;
}

.animation--loader {
  display: inline-block;
  font-size: 48px;
  position: relative;
  width: 1em;
  height: 1em;
  color: #990AE3;
}
.animation--loader span {
  font-size: 0.2em;
  transform-origin: 50% 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: calc(50% - 0.5em);
  line-height: 1px;
}
.animation--loader span:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1000ms;
}
.animation--loader span:nth-child(2) {
  transform: rotate(45deg);
  animation-delay: -875ms;
}
.animation--loader span:nth-child(3) {
  transform: rotate(90deg);
  animation-delay: -750ms;
}
.animation--loader span:nth-child(4) {
  transform: rotate(135deg);
  animation-delay: -625ms;
}
.animation--loader span:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -500ms;
}
.animation--loader span:nth-child(6) {
  transform: rotate(225deg);
  animation-delay: -375ms;
}
.animation--loader span:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -250ms;
}
.animation--loader span:nth-child(8) {
  transform: rotate(315deg);
  animation-delay: -125ms;
}
.animation--loader span .icon {
  animation-delay: inherit;
  animation-name: pebbleSpinner;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.animation--white {
  color: #ffffff;
}

@keyframes pebbleSpinner {
  0% {
    opacity: 0.2;
    transform: scale(0.4);
  }
  10% {
    opacity: 1;
    transform: scale(1.1);
  }
  20% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0.2;
    transform: scale(0.4);
  }
}
@keyframes formFieldFlash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink {
  /**
   * At the start of the animation the dot
   * has an opacity of .2
   */
  0% {
    opacity: 0.2;
  }
  /**
   * At 20% the dot is fully visible and
   * then fades out slowly
   */
  20% {
    opacity: 1;
  }
  /**
   * Until it reaches an opacity of .2 and
   * the animation can start again
   */
  100% {
    opacity: 0.2;
  }
}
.input {
  border: none;
  box-shadow: none;
  transition: background-color 0.1s ease-in, border-color 0.1s ease-in;
  border-radius: 0;
  padding: 20px;
  background-color: transparent;
}
.input::-webkit-input-placeholder {
  color: #fff;
}
.input:-moz-placeholder {
  color: #fff;
}
.input::-moz-placeholder {
  color: #fff;
}
.input:-ms-input-placeholder {
  color: #fff;
}

.error-message {
  color: #E4175C;
  display: block;
  text-align: left;
  font-size: 12px;
  line-height: 12px;
}
.error-message .icon {
  margin-right: 6px;
}

.text-input {
  position: relative;
  margin-bottom: -5px;
  margin-top: 20px;
}
form .text-input {
  padding-top: 0;
  padding-bottom: 0;
}
.text-input__list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.text-input--no-width-adjust .text-input__field {
  max-width: 100%;
  min-width: 100%;
}
.text-input__label {
  color: #707070;
  font-weight: normal;
  position: absolute;
  top: 26px;
  z-index: 1;
  transition: all 0.2s;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  animation-delay: 2s;
  animation-name: formFieldFlash;
  animation-duration: 0.7s;
  animation-timing-function: ease-in-out;
}
.text-input--has-icon .text-input__label {
  padding-left: 34px;
}
.text-input__field {
  font-family: inherit;
  font-size: 1em;
  border: 0;
  border-bottom: 1px solid #C5C5C5;
  position: relative;
  display: block;
  width: 100%;
  padding: 1.75em 0 0.3125em 0;
  line-height: 1.25em;
  appearance: none;
  border-radius: 0;
  transition: color 0.2s, border-color 0.2s;
  background-color: transparent;
}
.text-input__field--centered {
  text-align: center;
}
.text-input--has-icon .text-input__field {
  padding-left: 34px;
}
.form-container--dark .text-input__field {
  color: #ffffff;
}
.text-input__field.has-value {
  border-color: #C5C5C5;
}
.text-input__field.has-value ~ .text-input__label {
  top: 6px;
  font-size: 0.75em;
  color: #A0A0A0;
  animation-name: none;
}
.text-input__field:focus, .text-input__field.focus {
  border-color: #990AE3;
  margin-bottom: 0;
  border-bottom-width: 2px;
  padding-bottom: 0.25em;
  outline: none;
}
.text-input__field:focus ~ .text-input__label, .text-input__field.focus ~ .text-input__label {
  top: 6px;
  font-size: 0.75em;
  color: #990AE3;
}
.form-container--dark .text-input__field:focus ~ .text-input__label, .form-container--dark .text-input__field.focus ~ .text-input__label {
  color: #A0A0A0;
}
.text-input__field:disabled {
  border-color: #C5C5C5;
  border-bottom-style: dotted;
  color: #A0A0A0;
  -webkit-text-fill-color: #A0A0A0;
}
.text-input__field:disabled + .text-input__label {
  animation-name: none;
  color: #C5C5C5;
}
.text-input__field:read-only {
  border-bottom-color: transparent;
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
}
.text-input__field.has-error {
  border-color: #E4175C;
}
.text-input__field.has-error + .text-input__label {
  color: #E4175C;
}
.text-input--independent .text-input__field {
  padding: 0 0 0.3125em;
}
.text-input--login {
  margin-top: 0;
}
.text-input--login .text-input__field {
  padding: 1em 0;
  border: 0;
}
.text-input--search {
  width: 100%;
  color: #000000;
  background-color: #ffffff;
  font-size: 16px;
  padding-left: 60px;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}
@media (min-width: 841px) {
  .text-input--search {
    font-size: 16px;
  }
}
.text-input--search:focus {
  outline: 0;
}
.text-input--search::-webkit-input-placeholder {
  color: #707070;
}
.text-input--search:-moz-placeholder {
  color: #707070;
}
.text-input--search::-moz-placeholder {
  color: #707070;
}
.text-input--search:-ms-input-placeholder {
  color: #707070;
}
.text-input__icon {
  color: #000000;
  position: absolute;
  top: 50%;
  left: 0;
  font-size: 20px;
  line-height: normal;
  transform: translateY(-50%);
  padding: 15px 20px;
  margin-top: 2px;
}
.text-input__icon--search {
  font-size: 24px;
  cursor: pointer;
}
.text-input__icon--clear {
  font-size: 24px;
  display: none;
  cursor: pointer;
}
.text-input__icon--error {
  font-size: 24px;
  display: none;
  color: #E4175C;
}
.text-input__icon--loader {
  color: #990AE3;
  padding-top: 14px;
  display: none;
  margin-top: 0;
}
.text-input__icon--loader .animation--loader {
  margin: 3px 0 0;
  display: inline-block;
  line-height: 1px;
  font-size: 26px;
}
.text-input--has-icon .text-input__icon {
  font-size: 21px;
  padding: 0;
  bottom: 1px;
  top: auto;
  transform: none;
}
.text-input__error-text {
  color: #E4175C;
  display: block;
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
}
.text-input__info-text {
  color: #A0A0A0;
  display: block;
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
}
.text-input__info-text .icon {
  fill: #0099ff;
}
.text-input .ppr-autocomplete__list {
  top: -2px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.15);
  border-top: 2px solid #990AE3;
}

.file-input__input {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
.file-input__field {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in, padding 0.5s ease-in;
  position: relative;
}
.file-input__field--visible {
  max-height: 150px;
  padding: 18px 0 0;
}
.file-input__button {
  margin: 24px 1em 6px;
}
.file-input__label {
  margin: 0.5em 0;
}
.file-input__info-text {
  color: #A0A0A0;
  display: block;
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
}
.file-input__info-text .icon {
  fill: #0099ff;
}

.radio {
  display: none;
}
.radio + label {
  margin: 0 10px 10px 0;
  padding: 0 0 0 35px;
  line-height: 28px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  user-select: none;
}
.radio + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 28px;
  height: 28px;
  border: 2px solid #C5C5C5;
  background-color: #ffffff;
  border-radius: 999px;
}
.radio + label::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 6px;
  width: 16px;
  height: 16px;
  border-radius: 999px;
  background-color: #990AE3;
  display: none;
}
.radio:checked + label::after {
  display: block;
}
.radio:disabled + label {
  pointer-events: none;
  color: #C5C5C5;
}
.radio:disabled + label::before {
  border-color: #C5C5C5;
  background-color: #F2F2F2;
}
.radio:disabled + label::after {
  background-color: #C5C5C5;
}
.radio.invalid + label {
  color: #E4175C;
}
.radio.invalid + label::before {
  border-color: #E4175C;
}
.radio.invalid + label::after {
  background-color: #E4175C;
}

.checkbox {
  display: none;
}
.checkbox + label {
  margin: 0 10px 10px 0;
  padding: 0 0 0 35px;
  line-height: 28px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  user-select: none;
}
.checkbox + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 28px;
  height: 28px;
  border: 2px solid #C5C5C5;
  background-color: #ffffff;
  border-radius: 2px;
}
.checkbox + label::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 14px;
  width: 3px;
  height: 3px;
  border-radius: 2px;
  border-bottom: 3px solid #990AE3;
  border-left: 3px solid #990AE3;
  transform: rotate(-45deg);
  transform-origin: top left;
  opacity: 0;
  transition: height 0s, width 0s;
}
.checkbox:checked + label::after {
  opacity: 1;
  width: 16px;
  height: 8px;
  transition: height 0.2s, width 0.2s 0.2s;
}
.checkbox:disabled + label {
  pointer-events: none;
  color: #C5C5C5;
}
.checkbox:disabled + label::before {
  border-color: #C5C5C5;
  background-color: #F2F2F2;
}
.checkbox:disabled + label::after {
  border-color: #C5C5C5;
}
.checkbox.invalid + label {
  color: #E4175C;
}
.checkbox.invalid + label::before {
  border-color: #E4175C;
}
.checkbox.invalid + label::after {
  border-color: #E4175C;
}

/* Focus styles for the custom checkbox - Accessibility changes */
label.custom-checkbox:focus {
  outline: 2px solid #990AE3;
  background-color: #DDE;
}

label.custom-checkbox:hover {
  background-color: #DDE;
}

label.accessible-radio:focus {
  outline: 2px solid #990AE3;
  background-color: #DDE;
}

label.accessible-radio:hover {
  background-color: #DDE;
}

.toggle {
  display: none;
}
.toggle + label {
  margin: 0 10px 10px 0;
  padding: 0 0 0 68px;
  line-height: 32px;
  cursor: pointer;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  user-select: none;
}
.toggle + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 60px;
  height: 32px;
  border: 2px solid #C5C5C5;
  background-color: #C5C5C5;
  border-radius: 999px;
  transition: border-color 0.3s, background-color 0.3s;
}
.toggle + label::after {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  width: 28px;
  height: 28px;
  border-radius: 999px;
  background-color: #ffffff;
  transition: left 0.3s;
}
.toggle:checked + label::before {
  background-color: #990AE3;
  border-color: #990AE3;
}
.toggle:checked + label::after {
  left: 30px;
}
.toggle:disabled + label {
  pointer-events: none;
  color: #C5C5C5;
}
.toggle:disabled + label::before {
  border-color: #C5C5C5;
  background-color: #C5C5C5;
}
.toggle.invalid + label {
  color: #E4175C;
}
.toggle.invalid + label::before {
  background-color: #E4175C;
  border-color: #E4175C;
}

textarea {
  min-height: 75px;
}

.select {
  border-radius: 0;
  -webkit-appearance: button;
  -moz-appearance: button;
  border: none;
}

.form-group {
  margin: 1.5em 0;
}
.form-group__legend {
  font-weight: bold;
  margin: 1em 0;
  color: #3A3A3A;
}

[data-component-href] {
  position: relative;
}

.component-loader__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  background-color: rgba(255, 255, 255, 0.8);
  transition: opacity 0.2s ease-in;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}
.component-loader__wrapper--active {
  opacity: 1;
  z-index: 500;
}
.component-loader__wrapper--dots {
  color: #ffffff;
}

.carousel {
  overflow: hidden;
  padding: 32px 0 32px;
}
@media (min-width: 841px) {
  .carousel {
    padding: 48px 0 40px;
  }
}
@media (min-width: 1081px) {
  .carousel {
    padding: 48px 0 48px;
  }
}
@media (min-width: 1441px) {
  .carousel {
    padding: 64px 0 52px;
  }
}
.carousel--padding-top-none {
  padding-top: 0;
}
.carousel--padding-top-small {
  padding-top: 16px;
}
.carousel--padding-top-medium {
  padding-top: 32px;
}
.carousel--padding-top-large {
  padding-top: 48px;
}
.carousel--padding-bottom-none {
  padding-bottom: 0;
}
.carousel--padding-bottom-small {
  padding-bottom: 16px;
}
.carousel--padding-bottom-medium {
  padding-bottom: 32px;
}
.carousel--padding-bottom-large {
  padding-bottom: 48px;
}
.carousel .slick-track {
  display: flex;
}
.carousel p {
  max-width: none !important;
}
@media (min-width: 1081px) {
  .carousel__heading {
    justify-content: center;
  }
}
.carousel--track-white {
  background-color: #ffffff;
}
.carousel--track-white .slick-track {
  background-color: #ffffff;
}
.carousel--track-lightGrey {
  background-color: #F2F2F2;
}
.carousel--track-lightGrey .slick-track {
  background-color: #F2F2F2;
}
.carousel--track-darkGrey {
  background-color: #3A3A3A;
}
.carousel--track-darkGrey .slick-track {
  background-color: #3A3A3A;
}
.carousel--track-black {
  background-color: #000000;
}
.carousel--track-black .slick-track {
  background-color: #000000;
}
.carousel--track-deepPurple {
  background-color: #29003e;
}
.carousel--track-deepPurple .slick-track {
  background-color: #29003e;
}
.carousel--track-lightPurple {
  background-color: #F4E0FF;
}
.carousel--track-lightPurple .slick-track {
  background-color: #F4E0FF;
}
.carousel--track-deepBeige {
  background-color: #5C524E;
}
.carousel--track-deepBeige .slick-track {
  background-color: #5C524E;
}
.carousel--track-lightBeige {
  background-color: #F8EEE8;
}
.carousel--track-lightBeige .slick-track {
  background-color: #F8EEE8;
}
.carousel--track-transparent {
  background-color: transparent;
}
.carousel--track-transparent .slick-track {
  background-color: transparent;
}
.carousel__heading h2 {
  margin-bottom: 1em;
}
@media (max-width: 480px) {
  .carousel__slick [class*=grid__col-] {
    padding-left: 6px;
    padding-right: 6px;
  }
  .carousel__slick.grid--bleed [class*=grid__col-] {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 600px) {
  .carousel--partial-slides .carousel__slick {
    padding-left: 0;
    padding-right: 0;
  }
}
.carousel__arrow-container {
  position: absolute;
  top: 50%;
  width: 100%;
}
.carousel--has-dots .carousel__arrow-container {
  top: calc(50% - 16px);
}
@media (min-width: 1441px) {
  .carousel--has-dots .carousel__arrow-container {
    top: calc(50% - 21px);
  }
}
.carousel__arrow-content {
  position: relative;
  color: #990AE3;
  background-color: #ffffff;
  transition: opacity 0.3s, background-color 0.3s, color 0.3s;
  font-size: 20px;
  opacity: 0.6;
  width: 48px;
  height: 48px;
}
.carousel__arrow-content:hover {
  background-color: #990AE3;
  color: #ffffff;
}
.carousel__arrow-content .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.carousel__arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  border-radius: 48px;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  cursor: pointer;
}
.carousel__arrow:focus {
  outline: solid 3px #40b3ff;
  outline-offset: 2px;
}
.carousel__arrow:focus:not(:focus-visible) {
  outline: none;
}
.carousel__arrow.slick-disabled {
  pointer-events: none;
  display: none !important;
}
.carousel__arrow--right {
  right: -12px;
}
@media (min-width: 601px) {
  .carousel__arrow--right {
    right: -16px;
  }
}
@media (min-width: 841px) {
  .carousel__arrow--right {
    right: -24px;
  }
}
.carousel__arrow--left {
  left: -12px;
}
@media (min-width: 601px) {
  .carousel__arrow--left {
    left: -16px;
  }
}
@media (min-width: 841px) {
  .carousel__arrow--left {
    left: -24px;
  }
}
.carousel__content-wrapper {
  position: relative;
}
.carousel__card-wrapper {
  padding: 9px;
  padding-top: 5px;
  height: 100%;
}
.carousel--no-cards .carousel__card-wrapper {
  padding: 0;
}
.carousel__card {
  background-color: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  padding: 1em;
  letter-spacing: normal;
  word-spacing: normal;
  height: 100%;
  overflow: hidden;
}
.carousel__card--white {
  background-color: #ffffff;
}
.carousel__card--lightGrey {
  background-color: #F2F2F2;
}
.carousel__card--darkGrey {
  background-color: #3A3A3A;
}
.carousel__card--black {
  background-color: #000000;
}
.carousel__card--deepPurple {
  background-color: #29003e;
}
.carousel__card--lightPurple {
  background-color: #F4E0FF;
}
.carousel__card--deepBeige {
  background-color: #5C524E;
}
.carousel__card--lightBeige {
  background-color: #F8EEE8;
}
.carousel__card--transparent {
  background-color: transparent;
}
.carousel--no-cards .carousel__card {
  background-color: transparent;
  box-shadow: none;
  border-radius: 0;
  padding: 0;
}
.carousel--no-card-padding .carousel__card {
  padding: 0;
}
.carousel__card > * {
  box-shadow: none !important;
}
.carousel__card > *:hover {
  transform: none !important;
}
.carousel__card > .cms-plan-card {
  height: 100% !important;
}
.carousel .slick-slide {
  height: auto;
}
.carousel .slick-slide > div {
  display: flex;
  height: 100%;
}
@media (max-width: 600px) {
  .carousel--partial-slides .slick-slide {
    width: 72vw !important;
  }
}
.carousel .slick-dots {
  padding-left: 0;
  padding-right: 0;
  display: flex;
  max-width: none;
  justify-content: center;
  margin: 20px 0 0;
}
@media (min-width: 481px) {
  .carousel .slick-dots {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (min-width: 601px) {
  .carousel .slick-dots {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 1081px) {
  .carousel .slick-dots {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media (min-width: 601px) {
  .carousel .slick-dots {
    justify-content: flex-start;
  }
}
@media (min-width: 841px) {
  .carousel .slick-dots {
    margin: 24px 0 0;
  }
}
@media (min-width: 1441px) {
  .carousel .slick-dots {
    margin: 32px 0 0;
  }
}
.carousel .slick-dots li {
  list-style-type: none;
  line-height: 1px;
  margin: 0;
  position: relative;
}
.carousel .slick-dots li button {
  color: transparent;
  background-color: rgba(34, 34, 34, 0.1);
  border-radius: 50%;
  width: 8px;
  height: 8px;
  padding: 0;
  margin: 0 3px;
  border: 0;
  outline: 0;
  cursor: pointer;
}
.carousel .slick-dots li button:focus {
  outline: solid 3px #40b3ff;
  outline-offset: 2px;
}
.carousel .slick-dots li button:focus:not(:focus-visible) {
  outline: none;
}
@media (min-width: 1441px) {
  .carousel .slick-dots li button {
    width: 10px;
    height: 10px;
    margin: 0 4px;
  }
}
.carousel .slick-dots li.slick-active button {
  background-color: #990AE3;
}

.ppr-autocomplete__list {
  width: 100%;
  background-color: #ffffff;
  padding: 0;
  transition: max-height 0.2s;
  overflow: hidden;
  position: relative;
  color: #000000;
  max-height: 0;
  z-index: 200;
  display: none;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.15);
  border-radius: 0 0 8px 8px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
}
.ppr-autocomplete__list li:hover {
  color: #990AE3;
}
.ppr-autocomplete__list--open {
  display: block;
  max-height: 100%;
  overflow: auto;
}
.ppr-autocomplete__item {
  padding: 0.4em 1em;
  cursor: pointer;
}
.ppr-autocomplete__item:nth-child(1) {
  padding-top: 1em;
}
.ppr-autocomplete__item:nth-last-child(1) {
  padding-bottom: 0.8em;
}
.ppr-autocomplete__item .match {
  font-weight: bold;
}
.ppr-autocomplete__item--active, .ppr-autocomplete__item:hover {
  color: #990AE3;
}

.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}
.select2-container .select2-selection--single .select2-selection__clear {
  position: relative;
}
.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline-block;
  overflow: hidden;
  padding-left: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select2-container .select2-search--inline {
  float: left;
}
.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  padding: 0;
}

.select2-results {
  display: block;
}

.select2-container--open {
  z-index: 1;
}
.select2-container--open .select2-dropdown {
  left: 0;
}
.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 203;
  background-color: #ffffff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}
.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}
.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--single {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
.select2-container--default .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}
.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}
.select2-container--default .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--default .select2-results__option[aria-disabled=true] {
  color: #999;
}
.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}
.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
}
.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}
.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__placeholder {
  float: right;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}
.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}
.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}
.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-right: 10px;
}
.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}
.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}
.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}
.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: linear-gradient(to bottom, white 0, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}
.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}
.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}
.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}
.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
}
.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}
.select2-container--classic .select2-selection--multiple .select2-selection__rendered {
  list-style: none;
  margin: 0;
  padding: 0 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  color: #888;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
}
.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}
.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}
.select2-container--classic .select2-dropdown--below {
  border-top: none;
}
.select2-container--classic .select2-results__option[role=group] {
  padding: 0;
}
.select2-container--classic .select2-results__option[aria-disabled=true] {
  color: grey;
}
.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}
.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}
.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}
.select2-container--classic .select2-results > .select2-results__options {
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
  max-height: 200px;
  overflow-y: auto;
  border: 2px solid #990AE3;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}
.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  float: right;
  margin-left: 5px;
  margin-right: auto;
}
.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.ppr-select {
  width: 100%;
  padding: 10px;
  height: 46px;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #A0A0A0;
}
.ppr-select__label {
  font-size: 12px;
  line-height: 1;
  color: #A0A0A0;
  margin: -22px 0 20px;
}
.select2 .ppr-select__label {
  margin: 0;
  position: absolute;
  top: -5px;
}
.ppr-select ~ .select2-container {
  margin-top: 15px;
}
.ppr-select ~ .select2-container .icon {
  transition: transform 0.5s, color 0.5s;
}
.ppr-select ~ .select2-container--open .icon {
  transform: rotate(180deg);
  color: #990AE3;
}
.ppr-select.error ~ .select2-container .ppr-select__label {
  color: #E4175C;
}
.ppr-select.error ~ .select2-container .ppr-select__container {
  border-bottom: 2px solid #E4175C;
}
.ppr-select.error ~ .select2-container .ppr-select__container .icon {
  color: #E4175C;
}
.ppr-select ~ .select2-container--disabled {
  pointer-events: none;
}
.ppr-select ~ .select2-container--disabled .ppr-select__container {
  border-bottom: 1px dotted #C5C5C5;
}
.ppr-select ~ .select2-container--disabled .select2-selection {
  background-color: transparent;
}
.ppr-select ~ .select2-container--disabled .select2-selection .select2-selection__rendered {
  color: #C5C5C5;
}
.ppr-select__footer {
  font-size: 12px;
  line-height: 1;
  margin: 7px 0;
}
.ppr-select__footer--error {
  color: #E4175C;
}
.ppr-select__container {
  cursor: pointer;
  display: block;
  user-select: none;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #C5C5C5;
  padding: 10px 0 4px;
}
.ppr-select__container.select2-selection--single .select2-selection__rendered {
  height: 24px;
  line-height: 24px;
  display: block;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #444;
}
.ppr-select__container.select2-selection--single .select2-selection__arrow {
  height: 20px;
  width: 20px;
  position: absolute;
  top: 12px;
  color: #C5C5C5;
  font-size: 11px;
  right: 0;
}
.ppr-select__container.select2-selection--single .select2-selection__arrow b {
  display: none;
}
.ppr-select__dropdown {
  overflow: hidden;
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: -2px;
  width: 100%;
  z-index: 200;
  background-color: #ffffff;
  transition: max-height 2s;
}
.select2-container--visible .ppr-select__dropdown {
  max-height: 200px;
}
.select2-container--visible .ppr-select__dropdown .select2-results {
  margin-top: 0;
  transition: margin 0.2s ease-out;
}
.ppr-select__dropdown .select2-search {
  display: block;
  padding: 4px;
}
.ppr-select__dropdown .select2-search--hide {
  display: none;
}
.ppr-select__dropdown .select2-search__field {
  font-size: 16px;
}
.ppr-select__dropdown .select2-search__field::-webkit-contacts-auto-fill-button {
  display: none !important;
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}
.ppr-select__dropdown .select2-results {
  display: block;
  position: relative;
  margin-top: 0;
  transition: margin 0.2s ease-out;
}
.ppr-select__dropdown .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  max-width: 100%;
}
.ppr-select__dropdown .select2-results__options {
  border: 2px solid #990AE3;
  border-radius: 2px;
  list-style: none;
  margin: 0;
  padding: 0;
}
.ppr-select__dropdown .select2-results__option {
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
  padding: 10px 6px;
  user-select: none;
  cursor: pointer;
  color: #990AE3;
}
.ppr-select__dropdown .select2-results__option[aria-selected=true] {
  background-color: #990AE3;
  color: #ffffff;
}
.ppr-select__dropdown .select2-results__option--highlighted {
  background-color: #990AE3;
  color: #ffffff;
}
.ppr-select__dropdown .select2-results__option--highlighted[aria-selected=true] {
  background-color: #990AE3;
  color: #ffffff;
}

.accordionlist {
  margin-bottom: 1em;
}
.accordionlist__content {
  max-height: 0;
  padding: 0 40px;
  transition: max-height 0.4s, padding-top 0.4s, padding-bottom 0.4s, opacity 0.4s ease;
  overflow: hidden;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
.accordionlist__content.edit-mode {
  max-height: none;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}
.accordionlist__content-wrapper > * {
  overflow: auto;
}
.accordionlist__content > .accordionlist__content-wrapper > .columnrow {
  margin-top: 0px;
  overflow: visible;
}
.accordionlist__item {
  float: none;
  border-radius: 16px;
  box-shadow: 0 6px 24px 0px rgba(0, 0, 0, 0.12);
  margin: 16px 0;
  min-height: 88px;
}
.accordionlist__item--lightGrey {
  background-color: #F2F2F2;
}
.accordionlist__item--white {
  background-color: #ffffff;
}
.accordionlist__heading {
  position: relative;
  font-size: 18px;
  line-height: 28px;
  padding: 30px 64px 24px 40px;
  cursor: pointer;
  font-weight: 500;
  width: 100%;
  overflow-wrap: break-word;
  background-color: transparent;
  border: none;
  text-align: left;
}
.accordionlist__heading--center {
  text-align: center;
}
.accordionlist__heading--purple {
  color: #990AE3;
}
.accordionlist__heading--grey {
  color: #3A3A3A;
}
.accordionlist__heading--black {
  color: #000000;
}
.accordionlist__icon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  bottom: 0;
  right: 48px;
  margin: auto 0;
  color: #990AE3;
  transition: transform 0.2s;
}
.accordionlist__item--open .accordionlist__icon {
  transform: rotate(180deg);
}
.accordionlist-inline .accordionlist__content {
  max-height: initial;
}

.cms-aku-availability-search {
  margin-bottom: 3em;
  background-color: transparent;
  position: relative;
}
.cms-aku-availability-search--small {
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 0;
  padding: 16px;
  box-shadow: 0 8px 32px 0 rgba(58, 58, 58, 0.18);
}
@media (min-width: 601px) {
  .cms-aku-availability-search--small {
    font-size: 0.95em;
    padding: 8px;
  }
}
@media (min-width: 841px) {
  .cms-aku-availability-search--small {
    padding: 16px;
    font-size: 1em;
    max-width: 600px;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__title {
  margin: 16px 16px 0 16px;
}
.cms-aku-availability-search__additional-text {
  padding-left: 1em;
  padding-right: 1em;
  max-width: 100%;
}
@media (min-width: 841px) {
  .cms-aku-availability-search__additional-text {
    padding-left: 24px;
    padding-right: 12px;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__additional-text {
  padding-left: 1em;
  padding-right: 1em;
}
.cms-aku-availability-search__anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}
.cms-aku-availability-search__form {
  visibility: hidden;
}
.cms-aku-availability-search__container {
  background-color: #ffffff;
  box-shadow: 2px 3px 20px 7px #E0E0E0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-top: 36px;
  flex-direction: column;
}
@media (max-width: 840px) {
  .cms-aku-availability-search__container {
    padding-bottom: 10px;
  }
}
@media (min-width: 1081px) {
  .cms-aku-availability-search__container {
    flex-direction: row;
    height: 88px;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__container {
  margin-top: 0;
  padding-bottom: 0;
  flex-direction: column;
  height: auto;
  box-shadow: none;
}
.cms-aku-availability-search__container .text-input {
  margin-top: 1em;
}
@media (min-width: 1081px) {
  .cms-aku-availability-search__container .text-input {
    margin-top: 0;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__container .text-input {
  margin-top: 1.5em;
}
.cms-aku-availability-search__container .text-input__error-text {
  position: absolute;
  bottom: -10px;
  margin-left: 3.75em;
}
@media (min-width: 1081px) {
  .cms-aku-availability-search__container .text-input__error-text {
    width: 100%;
    bottom: -25px;
    margin-left: 2.75em;
  }
}
@media (min-width: 1441px) {
  .cms-aku-availability-search__container .text-input__error-text {
    margin-left: 3.75em;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__container .text-input__error-text {
  position: static;
  margin-left: 3.75em;
  line-height: 16px;
}
.cms-aku-availability-search__container .text-input__field {
  margin-left: 2em;
  padding-right: 22px;
  box-sizing: border-box;
  width: calc(100% - 22px);
  flex-grow: 1;
  padding-top: 0.5em;
}
@media (min-width: 1081px) {
  .cms-aku-availability-search__container .text-input__field {
    margin-left: 1.25em;
  }
}
@media (min-width: 1441px) {
  .cms-aku-availability-search__container .text-input__field {
    margin-left: 2em;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__container .text-input__field {
  margin-left: 2em;
}
.cms-aku-availability-search__container .text-input__field:disabled {
  border-bottom: 1px solid #C5C5C5;
}
.cms-aku-availability-search__container .text-input__field:focus ~ .text-input__label, .cms-aku-availability-search__container .text-input__field.focus ~ .text-input__label, .cms-aku-availability-search__container .text-input__field.has-value ~ .text-input__label {
  top: 14px;
  margin-left: 5em;
}
@media (min-width: 1081px) {
  .cms-aku-availability-search__container .text-input__field:focus ~ .text-input__label, .cms-aku-availability-search__container .text-input__field.focus ~ .text-input__label, .cms-aku-availability-search__container .text-input__field.has-value ~ .text-input__label {
    top: 0;
    margin-left: 4.5em;
  }
}
@media (min-width: 1441px) {
  .cms-aku-availability-search__container .text-input__field:focus ~ .text-input__label, .cms-aku-availability-search__container .text-input__field.focus ~ .text-input__label, .cms-aku-availability-search__container .text-input__field.has-value ~ .text-input__label {
    margin-left: 6.25em;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__container .text-input__field:focus ~ .text-input__label, .cms-aku-availability-search--small .cms-aku-availability-search__container .text-input__field.focus ~ .text-input__label, .cms-aku-availability-search--small .cms-aku-availability-search__container .text-input__field.has-value ~ .text-input__label {
  top: 14px;
  margin-left: 5em;
}
.cms-aku-availability-search__container .text-input__field::-ms-clear {
  display: none;
}
.cms-aku-availability-search__container .text-input__label {
  left: 0;
  margin-left: 3.75em;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 1081px) {
  .cms-aku-availability-search__container .text-input__label {
    margin-left: 3.25em;
  }
}
@media (min-width: 1441px) {
  .cms-aku-availability-search__container .text-input__label {
    margin-left: 4.75em;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__container .text-input__label {
  margin-left: 3.75em;
}
.cms-aku-availability-search__input-container {
  flex-grow: 1;
  margin-left: 0.75em;
  width: 100%;
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
}
@media (min-width: 1081px) {
  .cms-aku-availability-search__input-container {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__input-container {
  padding-top: 1em;
  padding-bottom: 0;
}
.cms-aku-availability-search__input-container .ppr-autocomplete__list {
  position: absolute;
  left: 3.75em;
  top: 3em;
  width: auto;
  right: 1em;
}
@media (min-width: 1081px) {
  .cms-aku-availability-search__input-container .ppr-autocomplete__list {
    top: 2em;
    right: 0;
    left: 3.5em;
  }
}
@media (min-width: 1441px) {
  .cms-aku-availability-search__input-container .ppr-autocomplete__list {
    left: 4.75em;
    right: 1em;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__input-container .ppr-autocomplete__list {
  left: 3.75em;
  top: 3em;
  right: 1em;
}
.cms-aku-availability-search__street-input-icon-ok, .cms-aku-availability-search__street-input-icon-loading, .cms-aku-availability-search__postalcode-input-icon-ok {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 1081px) {
  .cms-aku-availability-search__street-input-icon-ok, .cms-aku-availability-search__street-input-icon-loading, .cms-aku-availability-search__postalcode-input-icon-ok {
    right: 0;
  }
}
@media (min-width: 1441px) {
  .cms-aku-availability-search__street-input-icon-ok, .cms-aku-availability-search__street-input-icon-loading, .cms-aku-availability-search__postalcode-input-icon-ok {
    right: 16px;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__street-input-icon-ok, .cms-aku-availability-search--small .cms-aku-availability-search__street-input-icon-loading, .cms-aku-availability-search--small .cms-aku-availability-search__postalcode-input-icon-ok {
  right: 16px;
}
.cms-aku-availability-search__street-input-icon-ok .icon--checkmark, .cms-aku-availability-search__street-input-icon-loading .icon--checkmark, .cms-aku-availability-search__postalcode-input-icon-ok .icon--checkmark {
  fill: #00CC66;
  float: right;
  font-size: 18px;
  justify-content: flex-end;
}
.cms-aku-availability-search__street-input-icon-ok .animation--loader, .cms-aku-availability-search__street-input-icon-loading .animation--loader, .cms-aku-availability-search__postalcode-input-icon-ok .animation--loader {
  font-size: 20px;
}
.cms-aku-availability-search__item {
  flex-grow: 1;
  display: flex;
  margin-top: 1em;
  width: 100%;
  flex-wrap: wrap;
}
@media (max-width: 1080px) {
  .cms-aku-availability-search__item {
    padding: 0 1em;
  }
}
@media (min-width: 1081px) {
  .cms-aku-availability-search__item {
    padding-left: 22px;
    width: auto;
  }
}
@media (min-width: 1441px) {
  .cms-aku-availability-search__item {
    padding-left: 32px;
    padding-right: 12px;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__item {
  padding: 0 1em;
  width: 100%;
}
@media (min-width: 1081px) {
  .cms-aku-availability-search__postalcode {
    width: 20%;
  }
}
@media (min-width: 1441px) {
  .cms-aku-availability-search__postalcode {
    width: auto;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__postalcode {
  width: 100%;
}
.cms-aku-availability-search__postalcode-input {
  -moz-appearance: textfield;
}
.cms-aku-availability-search__postalcode-input::-webkit-outer-spin-button, .cms-aku-availability-search__postalcode-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media (min-width: 1081px) {
  .cms-aku-availability-search__apartment {
    width: 20%;
  }
}
@media (min-width: 1441px) {
  .cms-aku-availability-search__apartment {
    width: auto;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__apartment {
  width: 100%;
}
.cms-aku-availability-search__icon {
  position: absolute;
  top: 50%;
  font-size: 1.5em;
  transform: translateY(-50%);
}
.cms-aku-availability-search__submit {
  background-color: #990AE3;
}
.cms-aku-availability-search__submit:disabled {
  background-color: #A0A0A0;
  border-color: transparent;
}
.cms-aku-availability-search__submit:hover {
  background-color: #CC00FF;
}
.cms-aku-availability-search__validate {
  flex-grow: 0;
  align-self: center;
}
.cms-aku-availability-search__validate-button {
  padding: 28px 0 16px;
}
@media (min-width: 1081px) {
  .cms-aku-availability-search__validate-button {
    align-self: flex-end;
    padding: 2px 18px 0 0;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__validate-button {
  padding: 16px 0 0 0;
}
.cms-aku-availability-search__error {
  color: #E4175C;
  max-width: 400px;
  margin-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
}
@media (min-width: 841px) {
  .cms-aku-availability-search__error {
    padding-left: 24px;
    padding-right: 12px;
    max-width: 600px;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__error {
  padding-left: 1em;
  padding-right: 1em;
  max-width: 400px;
}
.cms-aku-availability-search__load-animation {
  display: block;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
.cms-aku-availability-search__load-animation :before, .cms-aku-availability-search__load-animation :after {
  background-color: #F9F9F9;
}
@media (min-width: 841px) {
  .cms-aku-availability-search__load-animation {
    position: relative;
    top: 0.4em;
  }
}
.cms-aku-availability-search--small .cms-aku-availability-search__load-animation {
  position: static;
  top: 0;
}
.cms-aku-availability-search .component-loader__wrapper {
  border-radius: 8px;
}
.cms-aku-availability-search__akuresults {
  font-size: 16px;
  line-height: 24px;
  margin: 10px 20px 10px 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.cms-aku-availability-search--small .cms-aku-availability-search__akuresults {
  margin-left: 16px;
}
.cms-aku-availability-search__result {
  display: none;
}
.cms-aku-availability-search__result-operator-details {
  display: none;
}
.cms-aku-availability-search .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/**
 * Strips the unit from a given number-unit-combination and returns the number.
 * @link: http://stackoverflow.com/a/12335841/1779999
 * @usage: parse-int(10px) => 10
 */
/**
 * Simply adds the em unit to a given number. This is used twice in the
 * function underneath, thus the need for a separate function.
 */
/**
 * Calculates em values for a given list of (px or em) values and a given em
 * base. Multiply base values for nested em structures (last @usage example).
 * @usage: em(10px, 1.125) => .55556em
 *         em(24px, .875em) => 1.71429em
 *         em(12px 0 7em rgba(0, 0, 0, .7), 1.75) => .42857em 0 4em rgba(0, 0, 0, 0.7)
 *         em(12px) + em(3em) => 3.75em
 *         em(23px, em(53px)) => .43396em
 *         em(24px, 1.5 * 1.125) => .88889em
 */
/* Values from: https://react.styleguide.telia.fi/?path=/story/atoms-shadows--overview */
.appstore {
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  position: absolute;
}
@media (min-width: 841px) {
  .appstore__container {
    height: 72px;
    display: inline-block;
    background-color: #000000;
  }
}
.appstore__container a {
  height: 60px;
  background-color: #000000;
  width: 150px;
  position: relative;
  display: block;
  margin-bottom: 2px;
  float: left;
}
.appstore__container a:last-child {
  margin-bottom: 0;
}
.appstore__container a:hover {
  background-color: #333333;
}
@media (min-width: 601px) {
  .appstore__container a {
    width: 175px;
  }
}
@media (min-width: 841px) {
  .appstore__container a {
    text-align: center;
    width: 250px;
    float: left;
    height: 100%;
  }
}
.appstore__container a img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  max-width: 128px;
}

/**
 * Strips the unit from a given number-unit-combination and returns the number.
 * @link: http://stackoverflow.com/a/12335841/1779999
 * @usage: parse-int(10px) => 10
 */
/**
 * Simply adds the em unit to a given number. This is used twice in the
 * function underneath, thus the need for a separate function.
 */
/**
 * Calculates em values for a given list of (px or em) values and a given em
 * base. Multiply base values for nested em structures (last @usage example).
 * @usage: em(10px, 1.125) => .55556em
 *         em(24px, .875em) => 1.71429em
 *         em(12px 0 7em rgba(0, 0, 0, .7), 1.75) => .42857em 0 4em rgba(0, 0, 0, 0.7)
 *         em(12px) + em(3em) => 3.75em
 *         em(23px, em(53px)) => .43396em
 *         em(24px, 1.5 * 1.125) => .88889em
 */
/* Values from: https://react.styleguide.telia.fi/?path=/story/atoms-shadows--overview */
@media (min-width: 841px) {
  .cms-aureg-password {
    max-width: 600px;
  }
}
.cms-aureg-password__title {
  max-width: none;
  margin-bottom: 20px;
}
@media (min-width: 841px) {
  .cms-aureg-password__title {
    margin-bottom: 29px;
  }
}
.cms-aureg-password__description p {
  max-width: none;
}
.cms-aureg-password__input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
@media (min-width: 601px) {
  .cms-aureg-password__input-container {
    flex-direction: row;
    margin-bottom: 30px;
  }
}
.cms-aureg-password__input-container .text-input {
  margin: 0;
  margin-bottom: 15px;
  width: 100%;
}
@media (min-width: 601px) {
  .cms-aureg-password__input-container .text-input {
    margin-bottom: 0;
    padding-right: 20px;
  }
}
@media (min-width: 601px) {
  .cms-aureg-password__input-container .text-input .text-input__error-text {
    position: absolute;
  }
}
.cms-aureg-password__input-container > .button {
  align-self: center;
  margin: 0;
  margin-top: auto;
}
.cms-aureg-password__loader {
  display: flex;
  justify-content: center;
}

/**
 * Strips the unit from a given number-unit-combination and returns the number.
 * @link: http://stackoverflow.com/a/12335841/1779999
 * @usage: parse-int(10px) => 10
 */
/**
 * Simply adds the em unit to a given number. This is used twice in the
 * function underneath, thus the need for a separate function.
 */
/**
 * Calculates em values for a given list of (px or em) values and a given em
 * base. Multiply base values for nested em structures (last @usage example).
 * @usage: em(10px, 1.125) => .55556em
 *         em(24px, .875em) => 1.71429em
 *         em(12px 0 7em rgba(0, 0, 0, .7), 1.75) => .42857em 0 4em rgba(0, 0, 0, 0.7)
 *         em(12px) + em(3em) => 3.75em
 *         em(23px, em(53px)) => .43396em
 *         em(24px, 1.5 * 1.125) => .88889em
 */
/* Values from: https://react.styleguide.telia.fi/?path=/story/atoms-shadows--overview */
.availabilitysearch {
  margin-bottom: 3em;
  background-color: transparent;
  position: relative;
}
.availabilitysearch--small {
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 0;
  padding: 16px;
  box-shadow: 0 8px 32px 0 rgba(58, 58, 58, 0.18);
}
@media (min-width: 601px) {
  .availabilitysearch--small {
    font-size: 0.95em;
    padding: 8px;
  }
}
@media (min-width: 841px) {
  .availabilitysearch--small {
    padding: 16px;
    font-size: 1em;
    max-width: 600px;
  }
}
.availabilitysearch--small .availabilitysearch__title {
  margin: 16px 16px 0 16px;
}
.availabilitysearch__additional-text {
  padding-left: 1em;
  padding-right: 1em;
  max-width: 100%;
}
@media (min-width: 841px) {
  .availabilitysearch__additional-text {
    padding-left: 24px;
    padding-right: 12px;
  }
}
.availabilitysearch--small .availabilitysearch__additional-text {
  padding-left: 1em;
  padding-right: 1em;
}
.availabilitysearch__anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}
.availabilitysearch__form {
  visibility: hidden;
}
.availabilitysearch__container {
  background-color: #ffffff;
  box-shadow: 2px 3px 20px 7px #E0E0E0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-top: 36px;
  flex-direction: column;
}
@media (max-width: 840px) {
  .availabilitysearch__container {
    padding-bottom: 10px;
  }
}
@media (min-width: 1081px) {
  .availabilitysearch__container {
    flex-direction: row;
    height: 88px;
  }
}
.availabilitysearch--small .availabilitysearch__container {
  margin-top: 0;
  padding-bottom: 0;
  flex-direction: column;
  height: auto;
  box-shadow: none;
}
.availabilitysearch__container .text-input {
  margin-top: 1em;
}
@media (min-width: 1081px) {
  .availabilitysearch__container .text-input {
    margin-top: 0;
  }
}
.availabilitysearch--small .availabilitysearch__container .text-input {
  margin-top: 1.5em;
}
.availabilitysearch__container .text-input__error-text {
  position: absolute;
  bottom: -10px;
  margin-left: 3.75em;
}
@media (min-width: 1081px) {
  .availabilitysearch__container .text-input__error-text {
    width: 100%;
    bottom: -25px;
    margin-left: 2.75em;
  }
}
@media (min-width: 1441px) {
  .availabilitysearch__container .text-input__error-text {
    margin-left: 3.75em;
  }
}
.availabilitysearch--small .availabilitysearch__container .text-input__error-text {
  position: static;
  margin-left: 3.75em;
  line-height: 16px;
}
.availabilitysearch__container .text-input__field {
  margin-left: 2em;
  padding-right: 22px;
  box-sizing: border-box;
  width: calc(100% - 22px);
  flex-grow: 1;
  padding-top: 0.5em;
}
@media (min-width: 1081px) {
  .availabilitysearch__container .text-input__field {
    margin-left: 1.25em;
  }
}
@media (min-width: 1441px) {
  .availabilitysearch__container .text-input__field {
    margin-left: 2em;
  }
}
.availabilitysearch--small .availabilitysearch__container .text-input__field {
  margin-left: 2em;
}
.availabilitysearch__container .text-input__field:disabled {
  border-bottom: 1px solid #C5C5C5;
}
.availabilitysearch__container .text-input__field:focus ~ .text-input__label, .availabilitysearch__container .text-input__field.focus ~ .text-input__label, .availabilitysearch__container .text-input__field.has-value ~ .text-input__label {
  top: 14px;
  margin-left: 5em;
}
@media (min-width: 1081px) {
  .availabilitysearch__container .text-input__field:focus ~ .text-input__label, .availabilitysearch__container .text-input__field.focus ~ .text-input__label, .availabilitysearch__container .text-input__field.has-value ~ .text-input__label {
    top: 0;
    margin-left: 4.5em;
  }
}
@media (min-width: 1441px) {
  .availabilitysearch__container .text-input__field:focus ~ .text-input__label, .availabilitysearch__container .text-input__field.focus ~ .text-input__label, .availabilitysearch__container .text-input__field.has-value ~ .text-input__label {
    margin-left: 6.25em;
  }
}
.availabilitysearch--small .availabilitysearch__container .text-input__field:focus ~ .text-input__label, .availabilitysearch--small .availabilitysearch__container .text-input__field.focus ~ .text-input__label, .availabilitysearch--small .availabilitysearch__container .text-input__field.has-value ~ .text-input__label {
  top: 14px;
  margin-left: 5em;
}
.availabilitysearch__container .text-input__field::-ms-clear {
  display: none;
}
.availabilitysearch__container .text-input__label {
  left: 0;
  margin-left: 3.75em;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 1081px) {
  .availabilitysearch__container .text-input__label {
    margin-left: 3.25em;
  }
}
@media (min-width: 1441px) {
  .availabilitysearch__container .text-input__label {
    margin-left: 4.75em;
  }
}
.availabilitysearch--small .availabilitysearch__container .text-input__label {
  margin-left: 3.75em;
}
.availabilitysearch__input-container {
  flex-grow: 1;
  margin-left: 0.75em;
  width: 100%;
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
}
@media (min-width: 1081px) {
  .availabilitysearch__input-container {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.availabilitysearch--small .availabilitysearch__input-container {
  padding-top: 1em;
  padding-bottom: 0;
}
.availabilitysearch__input-container .ppr-autocomplete__list {
  position: absolute;
  left: 3.75em;
  top: 3em;
  width: auto;
  right: 1em;
}
@media (min-width: 1081px) {
  .availabilitysearch__input-container .ppr-autocomplete__list {
    top: 2em;
    right: 0;
    left: 3.5em;
  }
}
@media (min-width: 1441px) {
  .availabilitysearch__input-container .ppr-autocomplete__list {
    left: 4.75em;
    right: 1em;
  }
}
.availabilitysearch--small .availabilitysearch__input-container .ppr-autocomplete__list {
  left: 3.75em;
  top: 3em;
  right: 1em;
}
.availabilitysearch__input--icon-ok, .availabilitysearch__input--icon-loading {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 1081px) {
  .availabilitysearch__input--icon-ok, .availabilitysearch__input--icon-loading {
    right: 0;
  }
}
@media (min-width: 1441px) {
  .availabilitysearch__input--icon-ok, .availabilitysearch__input--icon-loading {
    right: 16px;
  }
}
.availabilitysearch--small .availabilitysearch__input--icon-ok, .availabilitysearch--small .availabilitysearch__input--icon-loading {
  right: 16px;
}
.availabilitysearch__input--icon-ok .icon--checkmark, .availabilitysearch__input--icon-loading .icon--checkmark {
  fill: #00CC66;
  float: right;
  font-size: 18px;
  justify-content: flex-end;
}
.availabilitysearch__input--icon-ok .animation--loader, .availabilitysearch__input--icon-loading .animation--loader {
  font-size: 20px;
}
.availabilitysearch__item {
  flex-grow: 1;
  display: flex;
  margin-top: 1em;
  width: 100%;
  flex-wrap: wrap;
}
@media (max-width: 1080px) {
  .availabilitysearch__item {
    padding: 0 1em;
  }
}
@media (min-width: 1081px) {
  .availabilitysearch__item {
    padding-left: 22px;
    width: auto;
  }
}
@media (min-width: 1441px) {
  .availabilitysearch__item {
    padding-left: 32px;
    padding-right: 12px;
  }
}
.availabilitysearch--small .availabilitysearch__item {
  padding: 0 1em;
  width: 100%;
}
@media (min-width: 1081px) {
  .availabilitysearch__postalcode {
    width: 20%;
  }
}
@media (min-width: 1441px) {
  .availabilitysearch__postalcode {
    width: auto;
  }
}
.availabilitysearch--small .availabilitysearch__postalcode {
  width: 100%;
}
.availabilitysearch__postalcode-input {
  -moz-appearance: textfield;
}
.availabilitysearch__postalcode-input::-webkit-outer-spin-button, .availabilitysearch__postalcode-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
@media (min-width: 1081px) {
  .availabilitysearch__apartment {
    width: 20%;
  }
}
@media (min-width: 1441px) {
  .availabilitysearch__apartment {
    width: auto;
  }
}
.availabilitysearch--small .availabilitysearch__apartment {
  width: 100%;
}
.availabilitysearch__icon {
  position: absolute;
  top: 50%;
  font-size: 1.5em;
  transform: translateY(-50%);
}
.availabilitysearch__submit {
  background-color: #990AE3;
}
.availabilitysearch__submit:disabled {
  background-color: #A0A0A0;
  border-color: transparent;
}
.availabilitysearch__submit:hover {
  background-color: #CC00FF;
}
.availabilitysearch__validate {
  flex-grow: 0;
  align-self: center;
}
.availabilitysearch__validate-button {
  padding: 28px 0 16px;
}
@media (min-width: 1081px) {
  .availabilitysearch__validate-button {
    align-self: flex-end;
    padding: 2px 18px 0 0;
  }
}
.availabilitysearch--small .availabilitysearch__validate-button {
  padding: 16px 0 0 0;
}
.availabilitysearch__error {
  color: #E4175C;
  max-width: 400px;
  margin-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
}
@media (min-width: 841px) {
  .availabilitysearch__error {
    padding-left: 24px;
    padding-right: 12px;
    max-width: 600px;
  }
}
.availabilitysearch--small .availabilitysearch__error {
  padding-left: 1em;
  padding-right: 1em;
  max-width: 400px;
}
.availabilitysearch__load-animation {
  display: block;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
.availabilitysearch__load-animation :before, .availabilitysearch__load-animation :after {
  background-color: #F9F9F9;
}
@media (min-width: 841px) {
  .availabilitysearch__load-animation {
    position: relative;
    top: 0.4em;
  }
}
.availabilitysearch--small .availabilitysearch__load-animation {
  position: static;
  top: 0;
}
.availabilitysearch .component-loader__wrapper {
  border-radius: 8px;
}
.availabilitysearch .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.big-hero {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  color: #3A3A3A;
}
@media (min-width: 601px) {
  .big-hero {
    padding: 105px 0 75px;
  }
}
.big-hero__background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (min-width: 601px) {
  .big-hero__background--mobile {
    display: none;
    background: none !important;
  }
}
@media (max-width: 600px) {
  .big-hero__background--desktop {
    display: none;
    background: none !important;
  }
}
.big-hero__grid--image-on-left {
  flex-direction: row-reverse;
}
.big-hero__text-container {
  display: flex;
  z-index: 1;
  align-items: normal;
  text-align: left;
}
@media (max-width: 600px) {
  .big-hero--align-mobile-center .big-hero__text-container {
    align-items: center;
    text-align: center;
  }
}
@media (min-width: 601px) {
  .big-hero__text-container {
    align-items: flex-start;
    text-align: left;
  }
}
.big-hero__text-container > * {
  /* IE11 Fix, a block level element should occupy 100%, IE needs to be told this */
  width: 100%;
}
.big-hero__component-area-component {
  margin-bottom: 20px;
}
@media (min-width: 481px) {
  .big-hero__component-area-component {
    margin-bottom: 30px;
  }
}
.big-hero__component-area-component > * {
  margin: 0;
}
.big-hero__component-area--bottom .big-hero__component-area-component:last-child {
  margin-bottom: 0;
}
.big-hero__back-link-container {
  display: flex;
  align-items: center;
}
.big-hero__back-link {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #707070;
  font-weight: bold;
}
.big-hero__back-link--grey50 {
  color: #A0A0A0;
}
.big-hero__back-link .icon {
  margin-right: 6px;
  font-size: 0.8em;
}
@media (min-width: 481px) {
  .big-hero__back-link {
    font-size: 18px;
    margin-left: -22px;
  }
  .big-hero__back-link .icon {
    margin-right: 10px;
  }
}
.big-hero__breadcrumbs-container {
  margin: 25px 0 -25px;
  z-index: 1;
}
.big-hero__breadcrumb-link {
  font-size: 14px;
  font-weight: bold;
  margin-right: 3px;
}
@media (min-width: 481px) {
  .big-hero__breadcrumb-link {
    font-size: 18px;
  }
}
.big-hero__breadcrumb-link--grey {
  color: #A0A0A0;
}
.big-hero__breadcrumb-link--white {
  color: #ffffff;
  opacity: 0.64;
}
.big-hero__title {
  margin: 25px 0;
}
@media (min-width: 481px) {
  .big-hero__title {
    margin: 40px 0;
  }
}
@media (min-width: 601px) {
  .big-hero__title--wide {
    width: 150%;
  }
}
.big-hero__title--purple {
  color: #29003e;
}
.big-hero__title--white {
  color: #ffffff;
}
.big-hero__title--black {
  color: #121214;
}
.big-hero__title--deepPurple {
  color: #29003e;
}
.big-hero__title--lightPurple {
  color: #F4E0FF;
}
.big-hero__title--lightBeige {
  color: #F8EEE8;
}
.big-hero__title--deepBeige {
  color: #5C524E;
}
.big-hero__description {
  margin-top: 0;
  /* IE11 Fix, a block level element should occupy 100%, IE needs to be told this */
  width: 100%;
}
.big-hero__description--black {
  color: #121214;
}
.big-hero__description--white {
  color: #ffffff;
}
.big-hero__description--deepPurple {
  color: #29003e;
}
.big-hero__description--lightPurple {
  color: #F4E0FF;
}
.big-hero__description--lightBeige {
  color: #F8EEE8;
}
.big-hero__description--deepBeige {
  color: #5C524E;
}
.big-hero__feature-image {
  display: none;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media (min-width: 601px) {
  .big-hero__feature-image {
    display: block;
  }
}
.big-hero__feature-image--mobile {
  display: block;
  height: auto;
  width: 70%;
  margin-bottom: 20px;
  align-self: center;
  /* IE 11 image in flex fix. Without this, the image would stretch vertically */
  min-height: 1px;
}
@media (min-width: 601px) {
  .big-hero__feature-image--mobile {
    display: none;
  }
}
.big-hero__feature-components {
  display: flex;
  align-items: center;
}
.big-hero__feature-components > * {
  /* IE11 Fix, a block level element should occupy 100%, IE needs to be told this */
  width: 100%;
}

.big-x-item:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #E0E0E0;
}
@media (min-width: 601px) {
  .big-x-item:before {
    width: 90%;
  }
}
@media (min-width: 841px) {
  .big-x-item:before {
    display: none;
  }
}
.big-x-item--horizontal-mobile:before {
  width: 90%;
}
@media (min-width: 601px) {
  .big-x-item:nth-last-child(-n+2):before {
    display: none;
  }
}
.big-x-item:last-child:before {
  display: none;
}
.big-x-item--horizontal-mobile:nth-last-child(-n+2):before {
  display: none;
}
@media (max-width: 600px) {
  .big-x-item--horizontal-mobile {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (max-width: 840px) {
  .big-x-item--horizontal-mobile:after {
    content: "";
    position: absolute;
    right: 4.5%;
    top: 50%;
    height: 80%;
    width: 1px;
    border-right: 1px solid #E0E0E0;
    transform: translateY(-50%);
  }
  .big-x-item--horizontal-mobile:nth-child(even):after {
    display: none;
  }
}
.big-x-item:nth-child(even):before {
  width: 100%;
}
@media (min-width: 601px) and (max-width: 840px) {
  .big-x-item:after {
    content: "";
    position: absolute;
    right: 4.5%;
    top: 50%;
    height: 80%;
    width: 1px;
    border-right: 1px solid #E0E0E0;
    transform: translateY(-50%);
  }
  .big-x-item:nth-child(even):after {
    display: none;
  }
}
.big-x-item__link {
  font-size: 18px;
  line-height: 26px;
  padding: 1em 0;
}
.big-x-item--horizontal-mobile .big-x-item__link {
  font-size: 16px;
  line-height: 24px;
}
@media (min-width: 841px) {
  .big-x-item__link {
    padding: 2.5em 0;
  }
}
.big-x-item__link:link, .big-x-item__link:visited {
  color: #000000;
}
.big-x-item__link:hover, .big-x-item__link:active {
  color: #990AE3;
}
@media (min-width: 841px) and (max-width: 1080px) {
  .big-x-item__link {
    font-size: 16px;
  }
}
.big-x-item__icon {
  font-size: 30px;
  margin-right: 5px;
}
.big-x-item--horizontal-mobile .big-x-item__icon {
  font-size: 28px;
}
.big-x-item__text {
  vertical-align: super;
}
@media (min-width: 841px) {
  .big-x__list--has-many .big-x-item__link {
    padding: 1em 0;
  }
  .big-x__list--has-many .big-x-item__icon, .big-x__list--has-many .big-x-item__text {
    display: block;
    text-align: center;
  }
  .big-x__list--has-many .big-x-item__icon {
    padding-bottom: 10px;
  }
  .big-x__list--has-many .big-x-item__text {
    margin-left: -100%;
    margin-right: -100%;
    text-align: center;
    display: block;
  }
}
@media (min-width: 841px) and (max-width: 1440px) {
  .big-x__list--has-few .big-x-item__link {
    padding: 1em 0;
  }
  .big-x__list--has-few .big-x-item__icon, .big-x__list--has-few .big-x-item__text {
    display: block;
    text-align: center;
  }
  .big-x__list--has-few .big-x-item__icon {
    padding-bottom: 10px;
  }
}

.big-x {
  z-index: 1;
  position: relative;
  background-color: #ffffff;
}
.big-x__list {
  padding-top: 5px;
}
@media (min-width: 841px) {
  .big-x__list {
    justify-content: space-around;
    text-align: center;
    padding-top: 0;
  }
}

.bulletin-item {
  overflow: hidden;
}
@media (min-width: 841px) {
  .bulletin-item {
    padding: 10px 0 0 0;
  }
}
@media (min-width: 841px) {
  .bulletin-item__image-container {
    display: block;
    width: 100%;
    position: relative;
    height: 0;
    padding: 56.25% 0 0 0;
    overflow: hidden;
    margin-bottom: 0.5em;
  }
}
.bulletin-item__image {
  width: 100%;
}
@media (min-width: 841px) {
  .bulletin-item__image {
    position: absolute;
    display: block;
    max-width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}
.bulletin-item__type {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: normal;
  color: #A0A0A0;
}
.bulletin-item__link-text {
  font-weight: bold;
  color: #990AE3;
  font-size: 19px;
  line-height: 24px;
  margin-top: 5px;
  margin-bottom: 1.8em;
  display: block;
}
.bulletin-item__link-text:hover {
  color: #CC00FF;
}
.bulletin-item__link-text:hover svg {
  fill: #CC00FF;
}
.bulletin-item__chevron svg {
  fill: #990AE3;
  opacity: 0.7;
  width: 18px;
  height: 19px;
  vertical-align: text-bottom;
  margin-left: -7px;
}

.bulletin-list__item {
  border-top: 1px solid #C5C5C5;
}
.bulletin-list__item__link::after {
  clear: both;
  content: "";
  display: table;
}
.bulletin-list__item__link {
  display: block;
  padding: 16px 0;
  font-weight: bold;
}
.bulletin-list__item__date {
  float: left;
  width: 100px;
  color: #000000;
  font-weight: normal;
}
.bulletin-list__item__title {
  float: left;
  font-weight: bold;
  width: calc(100% - 100px);
  color: #000000;
}
.bulletin-list__item__title:hover {
  color: #990AE3;
}
@media (min-width: 841px) {
  .bulletin-list__aside {
    margin-bottom: 50px;
    margin-top: -32px;
  }
}

.bulletin__header {
  width: 70%;
}
.bulletin__content p {
  font-size: 1.125em;
  line-height: 1.5em;
  color: #3A3A3A;
}
.bulletin__content p:first-child {
  margin-top: 0;
}
.bulletin__image {
  width: 100%;
}

.callback-calendar {
  margin-bottom: 16px;
}
.callback-calendar__issue-types-title {
  font-weight: bold;
  margin-top: 12px;
  font-size: 20px;
}
.callback-calendar__issue-types-options {
  display: flex;
  flex-direction: column;
}
.callback-calendar .callback-calendar__issue-types-options .callback-calendar__issue-types-option {
  margin-top: 12px;
}
.callback-calendar__info-text {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  background-color: #E0E0E0;
  padding: 20px;
  border-radius: 10px;
  word-wrap: break-word;
}
.callback-calendar__info-text--helppi {
  background-color: #990AE3;
  border-radius: 60px;
  color: #ffffff;
  padding: 24px;
  overflow: hidden;
}
.callback-calendar__info-banner {
  width: 100%;
  border-radius: 10px;
  margin-top: 10px;
}
.callback-calendar__date-selector-week {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #F2F2F2;
  color: #A0A0A0;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
}
.callback-calendar__date-selector {
  margin-top: 16px;
}
.callback-calendar__date-selector-week-changer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  color: #3A3A3A;
  cursor: pointer;
}
.callback-calendar__date-selector-dates {
  margin: 20px -6px;
  display: flex;
  justify-content: space-between;
}
.callback-calendar__date-selector-date-container {
  display: flex;
  flex-direction: column;
}
.callback-calendar__date-selector-date-number {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  cursor: pointer;
  margin: 0 6px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 22px;
  border: solid 1px #E0E0E0;
  width: 44px;
  height: 44px;
}
.callback-calendar__date-selector-date-name {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 14px;
  color: #A0A0A0;
  font-weight: bold;
}
.callback-calendar__date-selector-date--selected .callback-calendar__date-selector-date-number {
  color: #ffffff;
  background-color: #990AE3;
  border-color: #990AE3;
  font-weight: bold;
}
.callback-calendar__date-selector-date--selected .callback-calendar__date-selector-date-name {
  color: #990AE3;
}
.callback-calendar__reservation-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #0099ff;
  border-radius: 4px;
  padding: 16px;
}
.callback-calendar__reservation-details {
  color: #ffffff;
}
.callback-calendar__reservation-date {
  font-weight: bold;
}
.callback-calendar__reservation-edit-link {
  display: flex;
  align-items: baseline;
  font-weight: bold;
  font-size: 12px;
  color: #ffffff;
}
.callback-calendar__reservation-edit-link:hover {
  color: #ffffff !important;
}
.callback-calendar__reservation-edit-link-text {
  font-size: 12px;
  margin-left: 6px;
}

.cms-campaign {
  background-color: #F2F2F2;
  padding: 0 108px !important;
}
.cms-campaign__container {
  padding-left: 12px !important;
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.cms-campaign__wrapper {
  padding: 12px;
}
.cms-campaign__mobileapp {
  height: 184px;
  background-color: #FF9B00;
  color: #ffffff;
  display: inline-block;
  padding: 0 20px;
  position: relative;
}
.cms-campaign__mobileapp__width-320 {
  width: 320px;
}
.cms-campaign__mobileapp__width-375 {
  width: 375px;
}
.cms-campaign__mobileapp__width-414 {
  width: 414px;
}
.cms-campaign__mobileapp__width-360 {
  width: 360px;
}
.cms-campaign__mobileapp__width-400 {
  width: 400px;
}
.cms-campaign__mobileapp__width-480 {
  width: 480px;
}
.cms-campaign__mobileapp__background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 5px;
  background-size: 100% !important;
  background-repeat: no-repeat;
}
.cms-campaign__mobileapp__block {
  height: 88px;
}
.cms-campaign__mobileapp__content {
  height: 96px;
  position: relative;
}
.cms-campaign__mobileapp__content__body {
  position: absolute;
  bottom: 12px;
}
.cms-campaign__mobileapp__caption {
  height: 18px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
}
.cms-campaign__mobileapp__title {
  min-height: 22px;
  max-height: 44px;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.4px;
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  text-overflow: ellipsis !important;
  overflow: hidden;
}
.cms-campaign__os {
  height: 210px;
  background-color: #FF9B00;
  color: #ffffff;
  display: inline-block;
  border-radius: 6px;
  padding: 0 20px;
  position: relative;
}
.cms-campaign__os__small {
  width: 392px;
}
.cms-campaign__os__big {
  width: 807px;
}
.cms-campaign__os__background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border-radius: 5px;
  background-position: 100%;
}
.cms-campaign__os__header {
  margin-bottom: 15px;
  color: #ffffff;
  position: relative;
}
.cms-campaign__os__description {
  position: relative;
}
.cms-campaign__os__button-container {
  position: absolute;
  bottom: 10px;
}
.cms-campaign__os__telia* {
  margin: 0;
  padding: 0;
}
.cms-campaign__os__button {
  font-size: 14px;
  letter-spacing: 0.8px;
  line-height: 18px;
  font-weight: bold;
  white-space: nowrap;
  padding: 0 2em;
  height: 40px;
  margin: 6px;
  border: 2px solid transparent;
  color: #ffffff;
  background-color: #990AE3;
  border-color: #990AE3;
  border-radius: 999px;
  text-transform: uppercase;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  cursor: pointer;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 100%;
  text-decoration: none;
}
.cms-campaign__os__button.cms-campaign__os__button--dark-background {
  color: #990AE3;
  border-color: #ffffff;
  background-color: #ffffff;
}
.cms-campaign__os__button__text {
  overflow: hidden;
  text-overflow: ellipsis;
}
.cms-campaign__os__button__icon {
  fill: currentColor;
  margin-left: 5px;
  font-size: 9px;
  margin-top: 1px;
}
.cms-campaign__os__button__icon--large {
  font-size: 14px;
}
.cms-campaign__os__button__icon svg {
  width: 1em;
  height: 1em;
}
.cms-campaign__os__button:hover {
  background-color: #CC00FF;
  border-color: #CC00FF;
  color: #ffffff;
}
.cms-campaign__os__button:hover .cms-campaign__os__button--dark-background {
  background-color: #CC00FF;
  border-color: #CC00FF;
  color: #ffffff;
}
.cms-campaign__os__button:link, .cms-campaign__os__button:visited {
  color: #ffffff;
}
.cms-campaign__os__button--secondary {
  background-color: rgba(153, 10, 227, 0);
  color: #990AE3;
  border-color: #990AE3;
}
.cms-campaign__os__button--secondary.cms-campaign__os__button--dark-background {
  color: #ffffff;
  border-color: #ffffff;
  background-color: transparent;
}
.cms-campaign__os__button--secondary:hover {
  background-color: #990AE3;
  border-color: #990AE3;
  color: #ffffff;
}
.cms-campaign__os__button--secondary:hover.cms-campaign__os__button--dark-background {
  background-color: #CC00FF;
  border-color: #CC00FF;
  color: #ffffff;
}
.cms-campaign__os__button--secondary:link, .cms-campaign__os__button--secondary:visited {
  color: #990AE3;
}
.cms-campaign__os__button--purchase {
  background-color: #00CC66;
  border-color: #00CC66;
}
.cms-campaign__os__button--purchase.cms-campaign__os__button--dark-background {
  color: #ffffff;
  border-color: #00CC66;
  background-color: #00CC66;
}
.cms-campaign__os__button--purchase:hover {
  background-color: #32FF00;
  border-color: #32FF00;
  color: #ffffff;
}
.cms-campaign__os__button--purchase:hover.cms-campaign__os__button--dark-background {
  background-color: #32FF00;
  border-color: #32FF00;
  color: #ffffff;
}
.cms-campaign__os__button--link {
  background-color: transparent;
  color: #990AE3;
  border: 0 transparent;
  text-transform: none;
  letter-spacing: 0;
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  font-size: 14px;
  -webkit-transition: 0ms;
  transition: 0ms;
}
.cms-campaign__os__button--link.cms-campaign__os__button--dark-background {
  background-color: transparent;
  color: #ffffff;
}
.cms-campaign__os__button--link:hover {
  background-color: transparent;
  color: #CC00FF;
  border-color: transparent;
}
.cms-campaign__os__button--link:hover.cms-campaign__os__button--dark-background {
  background-color: transparent;
  color: #990AE3;
}
.cms-campaign__os__button--link:link, .cms-campaign__os__button--link:visited {
  color: #990AE3;
}
.cms-campaign__os__button--link .cms-campaign__os__button__icon {
  margin-top: 3px;
}

.cms-channel-package-card {
  text-align: left;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  height: 100%;
  padding-bottom: 4px;
}
.cms-channel-package-card__wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
.cms-channel-package-card__image {
  width: 100%;
  min-height: 1px;
}
.cms-channel-package-card__logo {
  height: 60px;
}
.cms-channel-package-card__logo:not(:last-of-type) {
  margin-right: 12px;
}
.cms-channel-package-card__tag-container {
  height: 22px;
  display: flex;
  justify-content: flex-start;
  margin: auto 16px 0;
}
.cms-channel-package-card__tag {
  padding: 0px 8px;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 10px;
  letter-spacing: 1px;
  line-height: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 600;
  color: #ffffff;
  text-transform: uppercase;
}
.cms-channel-package-card__tag--blue {
  background-color: #0099ff;
}
.cms-channel-package-card__tag--red {
  background-color: #E4175C;
}
.cms-channel-package-card__tag--orange {
  background-color: #FF9B00;
}
.cms-channel-package-card__tag--purple {
  background-color: #990AE3;
}
.cms-channel-package-card__tag--transparent {
  background-color: rgba(112, 112, 112, 0.5);
}
.cms-channel-package-card__lead-container {
  display: flex;
  align-items: center;
  margin: 2px 16px 8px;
}
@media (min-width: 601px) {
  .cms-channel-package-card__lead-container {
    font-size: 16px;
    margin-top: 2px;
  }
}
.cms-channel-package-card__lead-icon {
  align-self: flex-start;
  margin-right: 6px;
  position: relative;
}
.cms-channel-package-card__lead-icon > svg {
  display: block;
  height: 22px;
  width: 22px;
  pointer-events: none;
}
.cms-channel-package-card__lead-icon--blue {
  color: #0099ff;
}
.cms-channel-package-card__lead-icon--red {
  color: #E4175C;
}
.cms-channel-package-card__lead-icon--orange {
  color: #FF9B00;
}
.cms-channel-package-card__lead-icon--purple {
  color: #990AE3;
}
.cms-channel-package-card__lead-icon--transparent {
  color: rgba(112, 112, 112, 0.5);
}
.cms-channel-package-card__lead-icon--has-tooltip {
  cursor: pointer;
}
.cms-channel-package-card__lead-text {
  line-height: 22px;
  font-weight: 400;
  color: #707070;
  font-size: 14px;
}
@media (min-width: 601px) {
  .cms-channel-package-card__lead-text {
    font-size: 16px;
  }
}
.cms-channel-package-card__description {
  line-height: 22px;
  min-height: 40px;
  font-weight: 400;
  color: #707070;
  font-size: 14px;
  margin: 8px 16px 18px;
}
@media (min-width: 601px) {
  .cms-channel-package-card__description {
    font-size: 16px;
  }
}
.cms-channel-package-card__name {
  line-height: 32px;
  color: #3A3A3A;
  font-weight: 600;
  font-size: 18px;
  display: block;
  margin: 16px 16px 0;
}
@media (min-width: 601px) {
  .cms-channel-package-card__name {
    font-size: 24px;
  }
}
.cms-channel-package-card__footer {
  padding: 12px 16px 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  flex-direction: column;
}
.cms-channel-package-card__price-container {
  display: flex;
  flex-wrap: wrap;
}
.cms-channel-package-card__price-container .price {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #3A3A3A;
  font-size: 28px;
  margin-right: 4px;
}
@media (min-width: 601px) {
  .cms-channel-package-card__price-container .price {
    font-size: 32px;
  }
}
.cms-channel-package-card__price-container .price-unit {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #3A3A3A;
  font-size: 20px;
  display: flex;
  align-items: center;
  flex-grow: 1;
  text-transform: none;
}
@media (min-width: 601px) {
  .cms-channel-package-card__price-container .price-unit {
    font-size: 24px;
  }
}
.cms-channel-package-card__price-container .price-description {
  line-height: 14px;
  font-size: 12px;
  color: #707070;
  margin: 4px 0 0;
  flex: 1 0 100%;
}
@media (min-width: 601px) {
  .cms-channel-package-card__price-container .price-description {
    font-size: 14px;
    line-height: 18px;
  }
}
.cms-channel-package-card__price-container .price-prefix {
  font-size: 12px;
  color: #3A3A3A;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
  padding-right: 3px;
}
.cms-channel-package-card .price-from {
  line-height: 14px;
  font-size: 12px;
  color: #3A3A3A;
  font-weight: bold;
  display: flex;
  align-items: flex-end;
}
.cms-channel-package-card__button-container {
  padding-top: 14px;
}
.cms-channel-package-card__button-container .button {
  margin: 0;
  padding: 0 14px;
  margin-bottom: 18px;
}
.cms-channel-package-card__tooltip {
  width: 175px;
  position: absolute;
  visibility: hidden;
  background-color: #642d96;
  font-weight: normal;
  padding: 4px 18px;
  color: #ffffff;
  border-radius: 8px;
  z-index: 1;
  cursor: default;
  z-index: 2001;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.3);
  transform: translate(40px, 50%);
  bottom: 60%;
  left: -50%;
  -webkit-text-size-adjust: 100%;
}
.cms-channel-package-card__tooltip p {
  font-size: 12px;
}
.cms-channel-package-card__tooltip:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -3px;
  border: 5px solid transparent;
  border-right-color: #642d96;
}
.cms-channel-package-card__tooltip--open {
  visibility: visible;
}

.columnrow__anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}
.columnrow > .media-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.columnrow--justify-center [class*=grid__col-] {
  text-align: center;
}
.columnrow--justify-center p, .columnrow--justify-center ol, .columnrow--justify-center ul {
  margin-left: auto;
  margin-right: auto;
}
.columnrow:last-child:not(.columnrow--no-footer-margin) {
  padding-bottom: 60px;
}
.grid .columnrow:last-child:not(.columnrow--no-footer-margin) {
  padding-bottom: 0;
}
.columnrow--justify-end {
  text-align: right;
}
.columnrow--rounded > .grid > [class*=grid__col-] {
  border-radius: 16px;
}
.columnrow--clip-column-background > .grid > [class*=grid__col-] {
  background-clip: content-box;
}
.columnrow--separate-vertically .grid > [class*=grid__col-] {
  margin-bottom: 12px;
}
@media (min-width: 601px) {
  .columnrow--separate-vertically .grid > [class*=grid__col-] {
    margin-bottom: 16px;
  }
}
@media (min-width: 1081px) {
  .columnrow--separate-vertically .grid > [class*=grid__col-] {
    margin-bottom: 24px;
  }
}
.columnrow--white {
  background-color: #ffffff;
}
.columnrow--lightGrey {
  background-color: #F2F2F2;
}
.columnrow--darkGrey {
  background-color: #3A3A3A;
}
.columnrow--black {
  background-color: #000000;
}
.columnrow--deepPurple {
  background-color: #29003e;
}
.columnrow--lightPurple {
  background-color: #F4E0FF;
}
.columnrow--deepBeige {
  background-color: #5C524E;
}
.columnrow--lightBeige {
  background-color: #F8EEE8;
}
.columnrow--morePadding {
  padding-top: 4em;
  padding-bottom: 4em;
}
.columnrow--media-container-as-bg {
  position: relative;
  overflow: hidden;
}
.columnrow__column--white > .grid > [class*=grid__col-] {
  background-color: #ffffff;
}
.columnrow__column--lightGrey > .grid > [class*=grid__col-] {
  background-color: #F2F2F2;
}
.columnrow__column--deepPurple > .grid > [class*=grid__col-] {
  background-color: #29003e;
}
.columnrow__column--lightPurple > .grid > [class*=grid__col-] {
  background-color: #F4E0FF;
}
.columnrow__column--deepBeige > .grid > [class*=grid__col-] {
  background-color: #5C524E;
}
.columnrow__column--lightBeige > .grid > [class*=grid__col-] {
  background-color: #F8EEE8;
}
.columnrow__column--whiteBorder > .grid > [class*=grid__col-] {
  border: 10px solid #ffffff;
}
.columnrow__column--lightGreyBorder > .grid > [class*=grid__col-] {
  border: 10px solid #F2F2F2;
}
.columnrow__column--deepPurpleBorder > .grid > [class*=grid__col-] {
  border: 10px solid #29003e;
}
.columnrow__column--lightPurpleBorder > .grid > [class*=grid__col-] {
  border: 10px solid #F4E0FF;
}
.columnrow__column--deepBeigeBorder > .grid > [class*=grid__col-] {
  border: 10px solid #5C524E;
}
.columnrow__column--lightBeigeBorder > .grid > [class*=grid__col-] {
  border: 10px solid #F8EEE8;
}
.grid .columnrow {
  margin: 60px 0;
}
.columnrow__no-margins > :first-child {
  margin-top: 0;
}
.columnrow__no-margins > :last-child {
  margin-bottom: 0;
}
.columnrow-padding--top-small, .columnrow__column-padding--top-small {
  padding-top: 16px;
}
.columnrow-padding--top-medium, .columnrow__column-padding--top-medium {
  padding-top: 32px;
}
.columnrow-padding--top-large, .columnrow__column-padding--top-large {
  padding-top: 48px;
}
.columnrow-padding--bottom-small, .columnrow__column-padding--bottom-small {
  padding-bottom: 16px;
}
.columnrow-padding--bottom-medium, .columnrow__column-padding--bottom-medium {
  padding-bottom: 32px;
}
.columnrow-padding--bottom-large, .columnrow__column-padding--bottom-large {
  padding-bottom: 48px;
}
.columnrow-padding--horizontal-small, .columnrow__column-padding--horizontal-small {
  padding-left: 16px;
  padding-right: 16px;
}
.columnrow-padding--horizontal-medium, .columnrow__column-padding--horizontal-medium {
  padding-left: 32px;
  padding-right: 32px;
}
.columnrow-padding--horizontal-large, .columnrow__column-padding--horizontal-large {
  padding-left: 48px;
  padding-right: 48px;
}

.cms-contact-cards__gutter {
  height: 12px;
}
.cms-contact-cards__card {
  margin-bottom: 23px;
  padding: 43px 32px;
  border-radius: 8px;
  box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.12);
  min-height: 306px;
  height: 100%;
}
.cms-contact-cards__card--white {
  background-color: #ffffff;
}
.cms-contact-cards__card--lightGrey {
  background-color: #F2F2F2;
}
.cms-contact-cards__card--transparent {
  background-color: transparent;
}
.cms-contact-cards__card .content-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.cms-contact-cards__card-title {
  color: #3A3A3A;
  margin-top: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25em;
}
@media (min-width: 841px) {
  .cms-contact-cards__card-title {
    font-size: 20px;
  }
}
@media (min-width: 1081px) {
  .cms-contact-cards__card-title {
    font-size: 24px;
  }
}
.cms-contact-cards__card-content p {
  margin: 0;
  line-height: 24px;
}
.cms-contact-cards__card-content a {
  font-weight: bold;
  line-height: 28px;
}
.cms-contact-cards__card-content--bottom {
  border-top: 1px solid #d0d0d0;
  margin-top: auto;
  padding-top: 6px;
}

.contact-info {
  text-align: center;
}
.contact-info__wrapper {
  padding: 10px !important;
}
.contact-info__content {
  background-color: #F2F2F2;
  padding: 36px 8px 0 8px;
  border-radius: 4px;
}
.contact-info__bottom-content {
  margin-top: auto;
}
.contact-info__icon {
  font-size: 36px;
}
.contact-info__heading {
  font-size: 20px;
  margin: 16px auto 22px;
}
.contact-info__description {
  color: #707070;
  margin-bottom: 20px;
}
.contact-info__phone {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #29003e;
  font-size: 24px;
  line-height: 24px;
}
.contact-info__link {
  font-weight: bold;
  line-height: 22px;
}
.contact-info__link-icon {
  color: #990AE3;
  font-size: 10px;
}
.contact-info__bottom-text {
  margin-top: 8px;
  color: #A0A0A0;
  min-height: 54px;
}

.contact-info-list {
  margin: 48px auto 16px;
}

.component-container__anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}

.content-central-article-teaser__container {
  color: #ffffff;
  overflow: hidden;
  position: relative;
  transition: transform 0.2s ease 0s;
}
.content-central-article-teaser__container::before {
  content: "";
  display: block;
  padding-top: 100%;
}
.content-central-article-teaser__container a,
.content-central-article-teaser__container a:hover {
  color: #ffffff !important;
}
.content-central-article-teaser__container:hover {
  transform: scale(1.03);
}
.content-central-article-teaser__article {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: #3A3A3A;
  display: flex;
  flex-direction: column;
  padding: 20px;
  transition: transform ease 0.2s;
  border-radius: 6px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #3A3A3A;
  text-align: left !important;
}
.content-central-article-teaser__article::after {
  display: block;
  position: absolute;
  content: " ";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 1;
  transition: opacity 0.5s;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 6px;
}
.content-central-article-teaser__article--has-image {
  color: #ffffff;
}
.content-central-article-teaser__overlay {
  opacity: 0;
  pointer-events: none;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0px;
  bottom: 0;
  transition: opacity 0.2s;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
.content-central-article-teaser__article:hover .content-central-article-teaser__overlay {
  opacity: 1;
}
.content-central-article-teaser__categories {
  font-size: 14px;
}
.content-central-article-teaser__description {
  margin-top: auto;
}
.content-central-article-teaser__title {
  font-size: 16px;
  line-height: 16px;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 48px;
  font-weight: 500;
  overflow: hidden;
  margin-top: 0;
  margin-bottom: 0.4em;
}
@media (min-width: 1441px) {
  .content-central-article-teaser__title {
    font-size: 30px;
    line-height: 30px;
    max-height: 90px;
  }
}
.content-central-article-teaser__ingress, .content-central-article-teaser__author, .content-central-article-teaser__title, .content-central-article-teaser__description, .content-central-article-teaser__categories, .content-central-article-teaser__article-reading-time {
  z-index: 1;
}
.content-central-article-teaser__ingress {
  font-weight: 500;
  max-height: 4.3125em;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  display: -webkit-box;
  overflow: hidden;
}
.content-central-article-teaser__author {
  font-size: 14px;
}
.content-central-article-teaser__article-reading-time {
  margin-top: 20px;
  font-size: 14px;
}
.content-central-article-teaser__header {
  display: flex;
  justify-content: space-between;
}
.content-central-article-teaser__categories, .content-central-article-teaser__article-reading-time {
  font-weight: 600;
}

.content-article {
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
  background-color: #F2F2F2;
}
.content-article--customerSupport2020 {
  background-color: #f2f2f2;
}
.content-article--customerSupport2020 .cms-personalized-card-list {
  padding-bottom: 24px;
}
@media (min-width: 841px) {
  .content-article--customerSupport2020 .content-article__header-wrapper {
    padding-left: 22px;
  }
}
.content-article--customerSupport2020 .content-article__helppi-banner {
  border-radius: 16px;
  box-shadow: 0 6px 24px 0 rgba(58, 58, 58, 0.1019607843);
  background-color: #ffffff;
  padding: 32px;
  margin-bottom: 32px;
}
.content-article--customerSupport2020 .content-article__helppi-banner-title {
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: bold;
}
.content-article--customerSupport2020 .content-article__helppi-banner-text {
  font-size: 16px;
  margin-bottom: 20px;
}
.content-article--customerSupport2020 .content-article__helppi-banner-phone-number {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 32px;
  margin-bottom: 6px;
  line-height: 32px;
}
.content-article--customerSupport2020 .content-article__helppi-banner-pricing {
  font-size: 16px;
}
.content-article--customerSupport2020 .content-article__helppi-banner-link {
  font-size: 16px;
  margin-top: 20px;
}
.content-article--customerSupport2020 .content-article__helppi-banner-link .icon {
  height: 10px;
}
.content-article--customerSupport2020 .content-article__heading h1.h2 {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 24px;
}
@media (min-width: 841px) {
  .content-article--customerSupport2020 .content-article__heading h1.h2 {
    font-size: 32px;
  }
}
@media (min-width: 1081px) {
  .content-article--customerSupport2020 .content-article__heading h1.h2 {
    font-size: 40px;
  }
}
.content-article--customerSupport2020 .content-article__ingress {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 14px;
}
@media (min-width: 841px) {
  .content-article--customerSupport2020 .content-article__ingress {
    font-size: 16px;
  }
}
.content-article--customerSupport2020 .cms-customer-support-page__overlay--active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.2);
  z-index: 1;
}
.content-article--customerSupport2020 .content-article__article-content,
.content-article--customerSupport2020 .content-article__notification-content {
  transition: box-shadow 0.1s border-radius 0.1s;
  box-shadow: 0 6px 24px 0 rgba(58, 58, 58, 0.1019607843);
  border-radius: 16px;
  box-sizing: border-box;
  background-color: #ffffff;
  overflow: hidden;
  padding: 32px;
  margin-bottom: 16px;
}
.content-article--customerSupport2020 .content-article__article-content h1,
.content-article--customerSupport2020 .content-article__article-content h2,
.content-article--customerSupport2020 .content-article__article-content h3,
.content-article--customerSupport2020 .content-article__article-content h4,
.content-article--customerSupport2020 .content-article__article-content h5,
.content-article--customerSupport2020 .content-article__notification-content h1,
.content-article--customerSupport2020 .content-article__notification-content h2,
.content-article--customerSupport2020 .content-article__notification-content h3,
.content-article--customerSupport2020 .content-article__notification-content h4,
.content-article--customerSupport2020 .content-article__notification-content h5 {
  font-size: 20px;
  margin-top: 32px;
  margin-bottom: 24px;
}
.content-article--customerSupport2020 .content-article__article-content h5,
.content-article--customerSupport2020 .content-article__notification-content h5 {
  font-weight: bold;
}
.content-article--customerSupport2020 .content-article__article-content .hasTable,
.content-article--customerSupport2020 .content-article__notification-content .hasTable {
  overflow: auto;
}
@media (max-width: 1440px) {
  .content-article--customerSupport2020 .content-article__backlink-container {
    margin-top: 12px;
  }
}
.content-article--customerSupport2020 .content-article__back-link {
  margin-top: 36px;
  display: flex;
  align-items: center;
  line-height: 0;
}
@media (max-width: 1080px) {
  .content-article--customerSupport2020 .content-article__back-link {
    margin-top: 16px;
  }
}
.content-article--customerSupport2020 .content-article__back-icon {
  display: block;
  margin-right: 6px;
}
.content-article--customerSupport2020 .content-article__ingress {
  font-size: 22px;
  line-height: 30px;
}
@media (min-width: 841px) {
  .content-article--customerSupport2020 .content-article__related-articles {
    padding: 0 0 0 19px;
  }
}
.content-article--customerSupport2020 .content-article__related-articles svg {
  color: #990AE3;
  font-size: 10px;
}
.content-article--customerSupport2020 .content-article__related-articles-title {
  color: #3A3A3A;
  line-height: 1.25em;
  font-weight: 600;
  font-size: 14px;
}
@media (min-width: 1081px) {
  .content-article--customerSupport2020 .content-article__related-articles-title {
    font-size: 16px;
  }
}
.content-article--customerSupport2020 .content-article__related-articles-item {
  display: flex;
  align-items: baseline;
  font-size: 14px;
  line-height: 1.5em;
  font-size: 14px;
}
@media (min-width: 841px) {
  .content-article--customerSupport2020 .content-article__related-articles-item {
    font-size: 16px;
  }
}
.content-article--customerSupport2020 .content-article__related-articles-item p {
  margin: 0 0 0 8px;
  color: #000000;
}
.content-article--customerSupport2020 .content-article__gutter {
  height: 8px;
  margin-top: 8px;
}
.content-article--customerSupport2020 .content-article__article {
  background-color: #f2f2f2;
  padding: 0;
}
.content-article--customerSupport2020 .content-article__similar-container {
  display: flex;
  margin: 32px 0 24px 0;
  font-size: 14px;
  line-height: 1.5em;
  font-size: 14px;
}
.content-article--customerSupport2020 .content-article__similar-container a {
  font-weight: 500;
}
@media (max-width: 840px) {
  .content-article--customerSupport2020 .content-article__similar-container {
    flex-direction: column;
  }
  .content-article--customerSupport2020 .content-article__similar-container a {
    margin-top: 8px;
  }
}
@media (min-width: 841px) {
  .content-article--customerSupport2020 .content-article__similar-container {
    font-size: 16px;
  }
  .content-article--customerSupport2020 .content-article__similar-container a {
    margin-left: 8px;
  }
}
@media (min-width: 841px) {
  .content-article--customerSupport2020 .content-article__article-wrapper {
    padding-right: 16px;
  }
}
.content-article--customerSupport2020 .content-article__right-side-wrapper {
  padding-left: 8px;
  flex-direction: column;
}
.content-article--customerSupport2020 .content-article__accordion {
  position: relative;
  padding: 1px 32px 0;
  margin-bottom: 16px;
  transition: box-shadow 0.1s, border-radius 0.1s, background-color 0.1s, padding 0.4s;
}
.content-article--customerSupport2020 .content-article__accordion-heading {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 8px 0;
  border-bottom: 1px solid #cdcdcd;
  color: #222222;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-weight: 600;
  line-height: 1.25em;
  font-size: 14px;
  text-align: left;
}
@media (min-width: 841px) {
  .content-article--customerSupport2020 .content-article__accordion-heading {
    font-size: 16px;
  }
}
@media (min-width: 1081px) {
  .content-article--customerSupport2020 .content-article__accordion-heading {
    font-size: 20px;
  }
}
.content-article--customerSupport2020 .content-article__accordion-heading svg {
  color: #990AE3;
}
.content-article--customerSupport2020 .content-article__accordion-heading .icon {
  transition: transform 0.4s;
  margin-right: 3px;
}
.content-article--customerSupport2020 .content-article__accordion-heading .icon.rotated {
  transform: rotate(180deg);
}
.content-article--customerSupport2020 .content-article__accordion-description {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  color: #3A3A3A;
  height: 0;
  overflow: hidden;
  transition: height 0.4s;
  font-size: 14px;
  line-height: 1.5em;
  font-size: 14px;
}
@media (min-width: 841px) {
  .content-article--customerSupport2020 .content-article__accordion-description {
    font-size: 16px;
  }
}
.content-article--customerSupport2020 .content-article__accordion-description h1,
.content-article--customerSupport2020 .content-article__accordion-description h2,
.content-article--customerSupport2020 .content-article__accordion-description h3,
.content-article--customerSupport2020 .content-article__accordion-description h4,
.content-article--customerSupport2020 .content-article__accordion-description h5 {
  font-size: 20px;
  margin-top: 32px;
  margin-bottom: 24px;
}
.content-article--customerSupport2020 .content-article__accordion-description h5 {
  font-weight: bold;
}
.content-article--customerSupport2020 .content-article__accordion--opened {
  box-shadow: 0 3px 8px 0 rgba(58, 58, 58, 0.1019607843);
  border-radius: 16px;
  box-sizing: border-box;
  background-color: #ffffff;
  overflow: hidden;
  padding: 16px 32px;
}
.content-article--customerSupport2020 .content-article__accordion--opened .content-article__accordion-description {
  height: auto;
}
.content-article--customerSupport2020 .content-article__accordion--opened .content-article__accordion-description ol li {
  list-style-position: inside;
}
.content-article--customerSupport2020 .content-article__accordion--opened .content-article__accordion-description .hasTable {
  overflow: auto;
}
.content-article--customerSupport2020 .content-article__accordion-anchor {
  position: absolute;
}
.content-article--customerSupport2020 .content-article__error {
  margin: 20px 0;
}
.content-article--customerSupport2020 .content-article__extra-component-wrapper {
  margin-top: 16px;
}
@media (max-width: 481px) {
  .content-article--default {
    padding-left: 3%;
    padding-right: 3%;
  }
}
.content-article__heading h1.h2 {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin-top: 35px;
}
.content-article__main {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
}
.content-article__article, .content-article__right-side-author {
  background-color: #ffffff;
  border-radius: 6px;
  padding: 30px;
}
.content-article__right-side-author {
  margin-bottom: 30px;
  margin-top: 12px;
}
@media (min-width: 841px) {
  .content-article__right-side-author {
    margin-top: 0;
  }
}
.content-article__right-side-wrapper {
  display: flex;
  flex-direction: column-reverse;
}
@media (min-width: 841px) {
  .content-article__right-side-wrapper {
    position: sticky;
    margin-bottom: auto;
  }
}
.content-article__back-link {
  margin-top: 20px;
}
.content-article__back-icon {
  vertical-align: middle;
}
.content-article__hero-image {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 6px;
}
.content-article__hero-img {
  width: 100%;
  position: relative;
  border-radius: 6px;
}
@media (min-width: 601px) {
  .content-article__hero-picture--mobile {
    display: none;
  }
}
.content-article__hero-picture--desktop {
  display: none;
}
@media (min-width: 601px) {
  .content-article__hero-picture--desktop {
    display: block;
  }
}
.content-article__article img {
  height: auto !important;
  max-width: 100%;
}
.content-article__article .youtube-embed-wrapper {
  display: flex;
}
.content-article__article iframe {
  max-width: 100%;
}
.content-article__discussiontopics-table-container {
  padding: 14px;
}
.content-article__discussiontopics-data-list {
  border-bottom: 1px solid #E0E0E0;
  margin: 10px;
}
.content-article__discussiontopics-data-list:first-child {
  border-bottom: 0px;
}
.content-article__discussiontopics-heading {
  font-weight: bold;
  margin-bottom: 23px;
  margin-top: 11px;
  font-size: 18px;
  margin-left: 47px;
}
@media (min-width: 841px) {
  .content-article__discussiontopics-heading {
    margin-left: 0;
  }
}
@media (min-width: 1441px) {
  .content-article__discussiontopics-heading {
    right: 25px;
  }
}
.content-article__discussiontopics-post-time {
  color: #707070;
}
@media (min-width: 841px) {
  .content-article__discussiontopics-post-time {
    align-self: end;
  }
}
.content-article__discussiontopics-data-column {
  color: #000000;
  margin-bottom: 13px;
  font-size: 15px;
}
.content-article__discussiontopics-link:hover .content-article__discussiontopics-data-column {
  color: #990AE3;
}
.content-article__discussiontopics-icon {
  font-size: 34px;
  top: 34px;
  color: #0099ff;
}
@media (min-width: 841px) {
  .content-article__discussiontopics-icon {
    top: 0;
  }
}
.content-article figcaption {
  text-transform: none;
  font-size: 16px;
  color: #A0A0A0;
  letter-spacing: 0;
  line-height: 24px;
  font-weight: normal;
  margin: 8px 2px 10px;
}
.content-article figure:after {
  content: "";
  height: 1px;
  display: block;
  width: 100%;
  box-shadow: 0 1px 0 0 #F2F2F2;
  margin-bottom: 1px;
}
.content-article__author {
  max-width: 300px;
}
.content-article__author-img {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  margin: auto;
  object-fit: cover;
}
.content-article__author-picture {
  width: 5em;
  height: 5em;
  border-radius: 50%;
  margin: 1em 0 1.2em;
  overflow: hidden;
  display: block;
  position: relative;
  background: linear-gradient(to bottom, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 12%, rgb(246, 246, 246) 50%, rgb(222, 231, 230) 100%);
}
.content-article__author-name {
  font-weight: bold;
}
.content-article__author-description {
  color: #A0A0A0;
  line-height: 1.3;
}
.content-article__categories {
  max-width: 350px;
  margin-top: 26px;
}
.content-article__categories-heading {
  text-transform: uppercase;
}
.content-article__categories .content-article__categories-link {
  font-size: 14px;
  background-color: #F2F2F2;
  border-radius: 100px;
  color: #000000;
  padding: 8px 18px;
  margin: 3px 0;
  display: inline-block;
}
.content-article__categories .content-article__categories-link:hover {
  background-color: #E0E0E0;
}
.content-article__date {
  color: #A0A0A0;
  padding: 3em 0 0;
}
.content-article__call-to-action {
  margin: 1em 0;
}
.content-article__call-to-action-container {
  margin: 0 !important;
}
.content-article__call-to-action-content {
  padding: 2em;
}
.content-article__call-to-action h3,
.content-article__call-to-action h4 {
  margin: 0 0 8px 0;
}
.content-article__call-to-action a {
  margin-top: 16px;
  margin-left: 0;
}
.content-article__call-to-action img {
  object-fit: cover;
  max-height: 312px;
}
.content-article__related-articles-heading {
  color: #A0A0A0;
  font-size: 20px;
  margin-bottom: 12px;
}
.content-article__related-articles-list {
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
}
.content-article__related-articles-list .content-central-article-teaser__container {
  margin-bottom: 20px;
}
@media (min-width: 841px) {
  .content-article__related-articles-list .content-central-article-teaser__container {
    margin-bottom: 10px;
  }
}
.content-article__related-article {
  margin: 0 0 12px;
  line-height: 24px;
}
.content-article__next-prev-bg-container {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
}
@media (min-width: 841px) {
  .content-article__next-prev-bg-container {
    flex-direction: row;
    justify-content: space-between;
  }
}
.content-article__next-prev-bg {
  width: 100%;
  height: 100%;
  background-color: #F9F9F9;
  border: 2px solid #ffffff;
  border-radius: 4px;
}
.content-article__next-prev-bg--hover {
  background-color: #F2F2F2;
}
.content-article__next-prev-bg--right {
  left: 50%;
}
.content-article__next-prev-bg--full-width {
  width: 100%;
}
.content-article__next-prev-container {
  justify-content: space-between;
}
.content-article__next-prev {
  margin-top: 2em;
  display: flex;
  flex-direction: column;
  position: relative;
}
@media (min-width: 841px) {
  .content-article__next-prev {
    flex-direction: row;
  }
}
.content-article__next-prev-item {
  padding-top: 50px;
  cursor: pointer;
}
.content-article__next-prev-item:first-child .content-article__next-prev-icon {
  margin-right: 8px;
}
.content-article__next-prev-item:last-child .content-article__next-prev-icon {
  margin-left: 8px;
}
.content-article__next-prev .content-article__next-prev-content {
  padding: 0;
}
.content-article__next-prev-link {
  display: flex;
  font-size: 12px;
  color: #000000;
}
.content-article__next-prev-icon {
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #990AE3;
  color: #ffffff;
  width: 22px;
  height: 22px;
  border-radius: 99px;
}
.content-article__next-prev-title {
  color: #000000;
  margin: 14px 0;
}
.content-article__next-prev-ingress {
  color: #A0A0A0;
  padding-bottom: 50px;
}
.content-article__error {
  margin-top: 20px;
}
.content-article__bottom-link .content-article__back {
  margin-bottom: 30px;
}
.content-article__ingress {
  color: #000000;
}
.content-article--support {
  padding-bottom: 58px;
}
.content-article--support .content-article__main.grid {
  justify-content: flex-start;
}
.content-article__highlight-box {
  margin-bottom: 2em;
  font-size: 18px;
}
.content-article__highlight-box__content {
  background-color: #F2F2F2;
  padding: 1em 2em;
}
.content-article .call-to-action {
  padding: 20px 0;
}
.content-article .call-to-action--website .call-to-action__icon, .content-article .call-to-action--email .call-to-action__icon {
  background-image: url("/.resources/pride-ui-module/webresources/assets/images/call-to-action/link.png");
}
.content-article .call-to-action--call .call-to-action__icon, .content-article .call-to-action--sms .call-to-action__icon {
  background-image: url("/.resources/pride-ui-module/webresources/assets/images/call-to-action/phone.png");
}
.content-article .call-to-action__icon {
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 42px;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #000000;
  background-size: 20px 20px;
  border-radius: 50%;
  text-align: center;
  font-size: 22px;
}
.content-article .call-to-action__content {
  display: inline-block;
  vertical-align: middle;
  padding-left: 12px;
}
.content-article .call-to-action__label {
  color: #A0A0A0;
  margin-bottom: 4px;
}
.content-article .call-to-action__link {
  display: inline-block;
  font-weight: bold;
  color: #990AE3;
}
.content-article .call-to-action__price {
  font-size: 12px;
  margin-top: -3px;
  position: absolute;
}
.content-article__products-container {
  margin-top: 40px;
}
.content-article__thumbs {
  margin: 0 !important;
}
.content-article__thumbs-container {
  display: none;
  color: #707070;
  border-radius: 4px;
  border: 1px solid #ffffff;
  width: 184px;
  background: #F9F9F9;
}
@media (max-width: 840px) {
  .content-article__thumbs-container {
    width: 155px;
  }
}
.content-article__thumbs--enabled .content-article__thumbs-container:hover {
  cursor: pointer;
  color: #CC00FF;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
}
.content-article__thumbs-down-container {
  margin-left: 20px;
}
@media (max-width: 840px) {
  .content-article__thumbs-down-container {
    margin: 20px 0;
  }
}
.content-article__thumbs-icon {
  display: inline-block;
  font-size: 44px;
  width: 45px;
  height: 41px;
  margin: 25px 70px 60px;
}
@media (max-width: 840px) {
  .content-article__thumbs-icon {
    margin: 25px 55px 60px;
  }
}
.content-article__thumbs-label {
  width: 39px;
  height: 27px;
  line-height: 26px;
  font-size: 18px;
}
.content-article__thumbs-down-label {
  margin-left: 10px;
}
.content-article__feedback-comments {
  display: none;
  margin-bottom: 20px;
}
.content-article__feedback-comments-submit {
  margin-top: 20px;
  align-self: center;
}
.content-article__feedback-title {
  display: none;
  align-self: center;
}
.content-article__feedback-title h3 {
  margin-bottom: 45px;
}
.content-article__feedback-consent {
  display: none;
}
.content-article__reading-time-container {
  padding: 5px;
  margin: -26px 0 10px -5px;
}
.content-article__reading-time-icon {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: middle;
}
.content-article__reading-time-icon .icon {
  font-size: 15px;
}
.content-article__reading-time-text {
  display: inline-block;
  vertical-align: middle;
  padding-top: 4px;
}
.content-article .content-central-article-teaser__title {
  font-size: 18px;
  margin-bottom: 6px;
  font-weight: 600;
}
.content-article .content-central-article-teaser__ingress {
  font-size: 16px;
  font-weight: normal;
  margin-bottom: 6px;
}
.content-article .content-central-article-teaser__author {
  font-size: 12px;
}

.content-central-article-grid {
  margin-bottom: 2em;
}
.content-central-article-grid__anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}
.content-central-article-grid__teaser-container {
  margin-bottom: 20px;
}
@media (min-width: 601px) {
  .content-central-article-grid__teaser-container {
    margin-bottom: 16px;
  }
}
@media (min-width: 1081px) {
  .content-central-article-grid__teaser-container {
    margin-bottom: 20px;
  }
}
.content-central-article-grid__available-articles-info {
  position: absolute;
  top: 15px;
  font-size: 14px;
  color: #C5C5C5;
}
.content-central-article-grid__load-more-button-container {
  clear: left;
  text-align: center;
  padding-top: 3em;
}
.content-central-article-grid__load-more-spinner {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 24px;
}
.content-central-article-grid__load-more-spinner--hidden {
  display: none;
}

.content-central-article-lift {
  margin-top: 1em;
}

.content-central-breadcrumb {
  padding: 7px 0;
}
.content-central-breadcrumb__items {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.3px;
  max-width: none;
  padding-top: 0px;
  padding-bottom: 0px;
}
.content-central-breadcrumb--light {
  color: #3A3A3A;
  background-color: #ffffff;
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
}
.content-central-breadcrumb--dark {
  color: #F4E0FF;
  background-color: #29003e;
}
.content-central-breadcrumb--beige {
  color: #5C524E;
  background-color: #F8EEE8;
}
.content-central-breadcrumb--transparent-light {
  color: #3A3A3A;
  background-color: transparent;
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
}
.content-central-breadcrumb--transparent-dark {
  color: #F4E0FF;
  background-color: transparent;
}
.content-central-breadcrumb__item {
  display: flex;
  align-items: center;
  height: 44px;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
  flex: 0 0 auto;
}
.content-central-breadcrumb__item--current {
  flex: 0 1 auto;
  font-weight: 500;
  overflow: hidden;
}
.content-central-breadcrumb__item--desktop {
  display: none;
}
@media (min-width: 601px) {
  .content-central-breadcrumb__item--desktop {
    display: flex;
  }
}
.content-central-breadcrumb__item > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.content-central-breadcrumb__link {
  display: flex;
  position: relative;
  height: 100%;
  align-items: center;
}
.content-central-breadcrumb__link::after {
  position: absolute;
  bottom: 12px;
  width: 100%;
  content: "";
}
.content-central-breadcrumb--light .content-central-breadcrumb__link, .content-central-breadcrumb--transparent-light .content-central-breadcrumb__link {
  color: #3A3A3A;
}
.content-central-breadcrumb--light .content-central-breadcrumb__link::after, .content-central-breadcrumb--transparent-light .content-central-breadcrumb__link::after {
  border-bottom: 1px solid #3A3A3A;
}
.content-central-breadcrumb--light .content-central-breadcrumb__link:hover, .content-central-breadcrumb--transparent-light .content-central-breadcrumb__link:hover {
  color: #990AE3;
}
.content-central-breadcrumb--light .content-central-breadcrumb__link:hover::after, .content-central-breadcrumb--transparent-light .content-central-breadcrumb__link:hover::after {
  border-bottom: 2px solid #990AE3;
}
.content-central-breadcrumb--dark .content-central-breadcrumb__link, .content-central-breadcrumb--transparent-dark .content-central-breadcrumb__link {
  color: #F4E0FF;
}
.content-central-breadcrumb--dark .content-central-breadcrumb__link::after, .content-central-breadcrumb--transparent-dark .content-central-breadcrumb__link::after {
  border-bottom: 1px solid #F4E0FF;
}
.content-central-breadcrumb--dark .content-central-breadcrumb__link:hover, .content-central-breadcrumb--transparent-dark .content-central-breadcrumb__link:hover {
  color: #E4B6FB;
}
.content-central-breadcrumb--dark .content-central-breadcrumb__link:hover::after, .content-central-breadcrumb--transparent-dark .content-central-breadcrumb__link:hover::after {
  border-bottom: 2px solid #E4B6FB;
}
.content-central-breadcrumb--beige .content-central-breadcrumb__link {
  color: #5C524E;
}
.content-central-breadcrumb--beige .content-central-breadcrumb__link::after {
  border-bottom: 1px solid #5C524E;
}
.content-central-breadcrumb--beige .content-central-breadcrumb__link:hover {
  color: #5C524E;
}
.content-central-breadcrumb--beige .content-central-breadcrumb__link:hover::after {
  border-bottom: 2px solid #5C524E;
}
.content-central-breadcrumb__link:focus {
  outline: solid 3px #0099ff;
  outline-offset: -1px;
}
.content-central-breadcrumb__link:focus:not(:focus-visible) {
  outline: none;
}
.content-central-breadcrumb__item-delimiter {
  margin-left: 6px;
  margin-right: 6px;
}

.content-central-category-filters__description {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 16px;
  margin-bottom: 10px;
  text-transform: uppercase;
  margin-top: 2em;
}
.content-central-category-filters__container {
  background: #F2F2F2;
  margin-top: 16px;
}
.content-central-category-filters__categories {
  display: flex;
  flex-flow: row wrap;
  padding: 40px 0;
}
@media (max-width: 840px) {
  .content-central-category-filters__categories {
    padding: 16px 0;
  }
}
.content-central-category-filters__category {
  margin: 4px 6px 4px 0;
  padding: 6px 16px 6px 11px;
  text-align: center;
  border-radius: 22px;
  background-color: #ffffff;
  font-size: 14px;
}
@media (max-width: 840px) {
  .content-central-category-filters__category {
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
  }
}
.content-central-category-filters__category:link, .content-central-category-filters__category:visited {
  color: #000000;
}
.content-central-category-filters__category:hover, .content-central-category-filters__category--selected {
  background-color: #990AE3;
}
.content-central-category-filters__category:hover:link, .content-central-category-filters__category:hover:visited, .content-central-category-filters__category:hover .content-central-category-filters__article-count, .content-central-category-filters__category--selected:link, .content-central-category-filters__category--selected:visited, .content-central-category-filters__category--selected .content-central-category-filters__article-count {
  color: #ffffff;
}
.content-central-category-filters__article-count {
  color: #A0A0A0;
}
.content-central-category-filters__content {
  margin-top: 55px;
}
.content-central-category-filters__empty-padding {
  padding: 0px;
}
.content-central-category-filters .image-hero__texts--button,
.content-central-category-filters .image-hero__texts--anchor-button {
  margin: 12px 0 0;
}
@media (min-width: 841px) {
  .content-central-category-filters .image-hero__texts--button,
  .content-central-category-filters .image-hero__texts--anchor-button {
    margin-top: 16px;
  }
}
@media (min-width: 1921px) {
  .content-central-category-filters .image-hero__texts--button,
  .content-central-category-filters .image-hero__texts--anchor-button {
    margin-top: 26px;
  }
}
.content-central-category-filters .image-hero__texts--button .button,
.content-central-category-filters .image-hero__texts--anchor-button .button {
  margin-left: 0;
}

.content-central-quicklinks {
  margin: 0 0 40px 0;
}
@media (min-width: 841px) {
  .content-central-quicklinks {
    margin: 80px 0 110px 0;
  }
}
.content-central-quicklinks__header {
  text-align: center;
  padding-bottom: 40px;
}
@media (min-width: 841px) {
  .content-central-quicklinks__header {
    display: none;
  }
}
.content-central-quicklinks__heading {
  color: #000000;
  margin-left: auto;
  margin-right: auto;
}
.content-central-quicklinks__list {
  font-size: 0;
  display: flex;
  flex-wrap: wrap;
}
.content-central-quicklinks__item {
  display: flex;
  margin-bottom: 12px;
  width: 100%;
  font-size: 16px;
  text-align: left;
}
@media (min-width: 841px) {
  .content-central-quicklinks__item {
    width: 50%;
    margin-bottom: 20px;
  }
  .content-central-quicklinks__item:nth-child(odd) {
    padding-right: 6px;
  }
  .content-central-quicklinks__item:nth-child(even) {
    padding-left: 6px;
  }
}
.content-central-quicklinks__item a {
  width: 100%;
}
.content-central-quicklinks__item a:link, .content-central-quicklinks__item a:visited {
  color: #707070;
}
.content-central-quicklinks__item a:hover {
  background-color: #F9F9F9;
}
.content-central-quicklinks__item a:hover:link, .content-central-quicklinks__item a:hover:visited {
  color: #990AE3;
}
.content-central-quicklinks__link {
  display: block;
  padding: 12px 16px 12px 24px;
  border: 1px solid #F2F2F2;
  position: relative;
  font-weight: bold;
}
.content-central-quicklinks__link:hover {
  color: #990AE3;
  background-color: #F9F9F9;
}
.content-central-quicklinks__link-label {
  font-weight: bold;
  padding-right: 1.5em;
}
.content-central-quicklinks__link-icon {
  position: absolute;
  font-size: 12px;
  width: 16px;
  height: 16px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 16px;
}
@media (min-width: 841px) {
  .content-central-quicklinks__link-icon {
    right: 16px;
  }
}

.counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.counter--align-mobile-center {
  align-items: center;
}
@media (min-width: 601px) {
  .counter {
    align-items: flex-start;
  }
  .counter--align-desktop-center {
    align-items: center;
  }
}
.counter__heading {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  width: 100%;
}
.counter__title--center {
  text-align: center;
}
.counter__title--left {
  text-align: left;
}
.counter__title--purple {
  color: #990AE3;
}
.counter__title--white {
  color: #ffffff;
}
.counter__title--black {
  color: #000000;
}
.counter__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.counter__flappers {
  display: flex;
  margin: -6px;
}
@media (min-width: 841px) {
  .counter__flappers {
    margin: -10px;
  }
}
.counter__flappers--hidden {
  display: none;
}
.counter--smaller-size .counter__flappers {
  margin: -6px;
}
.counter__flapper {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #F2F2F2;
  display: block;
  position: relative;
  perspective: 250px;
  width: 54px;
  height: 54px;
  font-size: 32px;
  margin: 6px;
  min-width: 54px;
  min-height: 54px;
  transform: translateZ(0);
}
@media (min-width: 841px) {
  .counter__flapper {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    font-size: 48px;
    margin: 10px;
  }
}
.counter--smaller-size .counter__flapper {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  font-size: 30px;
  margin: 8px;
}
.counter__flapper-unit {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  position: absolute;
  color: #F2F2F2;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 10px;
  line-height: 18px;
}
@media (min-width: 841px) {
  .counter__flapper-unit {
    font-size: 13px;
    line-height: 23px;
  }
}
.counter--smaller-size .counter__flapper-unit {
  font-size: 10px;
  line-height: 18px;
}
.counter__flapper-rod {
  z-index: -1;
  display: block;
  width: 110%;
  height: 21%;
  border-radius: 4px;
  background-color: #8802A3;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.counter__flapper-clipper--dynamic {
  position: absolute;
}
.counter__flapper-clipper--top {
  display: block;
  height: 50%;
  width: 100%;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
}
.counter__flapper-clipper--top.counter__flapper-clipper--dynamic {
  top: 0;
  transition: none;
  transform-origin: bottom;
}
.counter__flapper--flap .counter__flapper-clipper--top.counter__flapper-clipper--dynamic {
  transition: transform 350ms ease-in;
  transform: rotateX(-90deg);
}
.counter__flapper-clipper--bottom {
  display: block;
  height: 50%;
  width: 100%;
  overflow: hidden;
  border-radius: 0 0 8px 8px;
}
.counter__flapper-clipper--bottom.counter__flapper-clipper--dynamic {
  top: 50%;
  transition: none;
  transform-origin: top;
  transform: rotateX(89.9999deg);
}
.counter__flapper--flap .counter__flapper-clipper--bottom.counter__flapper-clipper--dynamic {
  transition: all 150ms linear;
  transition-delay: 350ms;
  transform: rotateX(0deg);
}
.counter__flapper-number--top {
  display: block;
  background-color: #AF5AFF;
  height: 200%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.counter__flapper-number--bottom {
  display: block;
  background-color: #AB36E8;
  height: 200%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
}
.counter__flapper-number-value {
  transform: translateY(-3px);
}
.counter__flapper-number-value--without-unit {
  transform: translateY(0);
}
.counter__ended-text {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin-top: 4px;
  font-size: 18px;
  text-align: center;
}
@media (min-width: 841px) {
  .counter__ended-text {
    margin-top: 10px;
    font-size: 22px;
  }
}
.counter--smaller-size .counter__ended-text {
  margin-top: 4px;
  font-size: 18px;
}
.counter__ended-text--purple {
  color: #990AE3;
}
.counter__ended-text--white {
  color: #ffffff;
}
.counter__ended-text--black {
  color: #000000;
}
.counter__ended-text--hidden {
  display: none;
}

.country-search__content {
  height: 424px;
  overflow: hidden;
  padding: 1em;
  background-size: cover;
}
.country-search__container {
  padding-bottom: 3em;
  vertical-align: middle;
}
.country-search__specific {
  position: absolute;
  width: 100%;
  height: 424px;
  margin: -1em;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.8s;
}
.country-search__specific--visible {
  opacity: 1;
}
.country-search__welcome {
  color: #ffffff;
  text-align: center;
}
.country-search__heading {
  position: relative;
  z-index: 5;
  text-align: center;
  margin: 118px auto 0;
}
.country-search__heading--white {
  color: #ffffff;
}
.country-search__heading--black {
  color: #000000;
}
.country-search__subheading {
  position: relative;
  margin: 0.9em 0 1.5em;
  z-index: 5;
  font-size: 18px;
  text-align: center;
  max-width: 100%;
}
.country-search__subheading--white {
  color: #ffffff;
}
.country-search__subheading--black {
  color: #000000;
}
.country-search__selector {
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  left: 0;
  right: 0;
}
.country-search__selector .ppr-autocomplete__list {
  margin-top: -3px;
  font-size: 18px;
}
.country-search__selector .ppr-autocomplete__item {
  padding-left: 3em;
}
.country-search__input {
  width: 100%;
  padding: 1.1em 1em 1.1em 3em;
  outline: 0;
  border: 1px solid #E0E0E0;
  font-size: 18px;
  -moz-box-sizing: border-box;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 #E0E0E0;
}
.country-search__input::-webkit-input-placeholder {
  color: #707070;
}
.country-search__input:-moz-placeholder {
  color: #707070;
}
.country-search__input::-moz-placeholder {
  color: #707070;
}
.country-search__input:-ms-input-placeholder {
  color: #707070;
}
.country-search__icon {
  position: absolute;
  font-size: 1.5em;
  color: #000000;
  left: 0.7em;
  top: 1.5em;
}
.country-search__clear {
  position: absolute;
  right: 0.7em;
  top: 1.5em;
  font-size: 1.5em;
  color: #a0a0a0;
  background-color: #ffffff;
  cursor: pointer;
  display: none;
}

.country-specific-price-list {
  display: none;
  opacity: 0;
  transition: opacity 1s;
}
.country-specific-price-list--visible {
  display: block;
  opacity: 1;
}
.country-specific-price-list .price-list__content {
  max-height: auto !important;
}

.cms-custom-category-list.grid .columnrow {
  margin: 0;
}

.cms-custom-code pre {
  border: 1px solid black;
  white-space: normal;
}

.cms-customer-support-card {
  min-height: 100px;
}
.cms-customer-support-card__accessible-list {
  max-width: none;
  padding-left: 0;
  margin-left: 0px;
}
.cms-customer-support-card__accessible-list-item {
  list-style-type: none;
}

.cms-customer-support-card__description {
  /** To reset user agent styles for html that may come from the data */
  /** TODO: the p margin should probably be removed so paragraphs from the rich text editor are visible */
}
.cms-customer-support-card__description p {
  margin: 0;
  max-width: 600px;
}
.cms-customer-support-card__description .button {
  margin-left: 0;
  margin-top: 16px;
}

.cms-customer-support-page.grid .columnrow {
  margin: 0;
}
.cms-customer-support-page__main-cards {
  position: relative;
}
@media (min-width: 841px) {
  .cms-customer-support-page__main-cards {
    margin-top: -180px;
  }
}
.cms-customer-support-page__personalized-cards, .cms-customer-support-page__disturbance-cards, .cms-customer-support-page__category-cards {
  padding-bottom: 24px;
}
.cms-customer-support-page__personalized-cards {
  margin-top: -140px;
}
@media (min-width: 841px) {
  .cms-customer-support-page__personalized-cards {
    margin-top: 0px;
    min-height: 204px;
  }
}
.cms-customer-support-page__personalized-cards--category {
  margin-top: 0;
}
@media (min-width: 841px) {
  .cms-customer-support-page__personalized-cards--category {
    min-height: 0;
  }
}
.cms-customer-support-page__personalized-cards--category .cms-logged-out-card,
.cms-customer-support-page__personalized-cards--category .cms-error-card {
  margin-top: 24px;
}
@media (min-width: 841px) {
  .cms-customer-support-page__personalized-cards--category .cms-logged-out-card,
  .cms-customer-support-page__personalized-cards--category .cms-error-card {
    margin-top: 36px;
  }
}
.cms-customer-support-page__disturbance-cards-title, .cms-customer-support-page__category-cards-title {
  color: #000000;
}
@media (min-width: 841px) {
  .cms-customer-support-page__disturbance-cards-title--overlaps-hero, .cms-customer-support-page__category-cards-title--overlaps-hero {
    color: #ffffff;
  }
}
.cms-customer-support-page__disturbance-cards-title, .cms-customer-support-page__category-cards-title {
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0;
}
@media (min-width: 841px) {
  .cms-customer-support-page__disturbance-cards-title, .cms-customer-support-page__category-cards-title {
    font-size: 24px;
    margin: 24px 0;
  }
}
@media (min-width: 1081px) {
  .cms-customer-support-page__disturbance-cards-title, .cms-customer-support-page__category-cards-title {
    font-size: 32px;
  }
}
.cms-customer-support-page__category-description {
  padding-top: 0;
  padding-bottom: 24px;
}
.cms-customer-support-page__category-description > *:first-child {
  margin-top: 3px;
}
@media (max-width: 840px) {
  .cms-customer-support-page__category-description * {
    font-size: 16px;
  }
}
.cms-customer-support-page__contact-cards-title h2 {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0;
  text-transform: none;
}
@media (min-width: 841px) {
  .cms-customer-support-page__contact-cards-title h2 {
    font-size: 24px;
    margin: 24px 0;
  }
}
@media (min-width: 1081px) {
  .cms-customer-support-page__contact-cards-title h2 {
    font-size: 32px;
  }
}

.cms-customer-support-search-hero__container {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.cms-customer-support-search-hero__container--transparent {
  background-color: transparent;
}
.cms-customer-support-search-hero__container--purple {
  background: radial-gradient(circle, #9848e1 0%, #7218c4 100%);
}
.cms-customer-support-search-hero__container--white {
  background-color: #ffffff;
}
.cms-customer-support-search-hero__container--black {
  background-color: #121214;
}
.cms-customer-support-search-hero__container--grey {
  background-color: #F4F4F9;
}
.cms-customer-support-search-hero__container--deepPurple {
  background-color: #29003e;
}
.cms-customer-support-search-hero__container--deepBeige {
  background-color: #5C524E;
}
.cms-customer-support-search-hero__container--beige {
  background-color: #ECDED4;
}
.cms-customer-support-search-hero__container--lightPurple {
  background-color: #F4E0FF;
}
.cms-customer-support-search-hero__container--lightBeige {
  background-color: #F8EEE8;
}

.cms-customer-support-search-hero {
  width: 100%;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 75px;
}
.cms-customer-support-search-hero--has-personalized-cards {
  padding-bottom: 180px;
}
@media (min-width: 841px) {
  .cms-customer-support-search-hero {
    padding-top: 80px;
    padding-bottom: 240px;
  }
  .cms-customer-support-search-hero--is-low-hero {
    padding-bottom: 75px;
  }
}
.cms-customer-support-search-hero__overlay--active {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 34, 34, 0.2);
  z-index: 1;
}
.cms-customer-support-search-hero__container {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.cms-customer-support-search-hero__title {
  display: block;
  text-align: center;
  margin: 0;
  margin-bottom: 16px;
  font-size: 28px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.cms-customer-support-search-hero__title--purple {
  color: #990AE3;
}
.cms-customer-support-search-hero__title--white {
  color: #ffffff;
}
.cms-customer-support-search-hero__title--black {
  color: #121214;
}
.cms-customer-support-search-hero__title--grey {
  color: #F4F4F9;
}
.cms-customer-support-search-hero__title--deepPurple {
  color: #29003e;
}
.cms-customer-support-search-hero__title--deepBeige {
  color: #5C524E;
}
.cms-customer-support-search-hero__title--beige {
  color: #ECDED4;
}
.cms-customer-support-search-hero__title--lightPurple {
  color: #F4E0FF;
}
.cms-customer-support-search-hero__title--lightBeige {
  color: #F8EEE8;
}
@media (min-width: 601px) {
  .cms-customer-support-search-hero__title {
    font-size: 32px;
  }
}
@media (min-width: 841px) {
  .cms-customer-support-search-hero__title {
    margin-bottom: 28px;
    font-size: 40px;
  }
}
@media (min-width: 1081px) {
  .cms-customer-support-search-hero__title {
    font-size: 48px;
  }
}
.cms-customer-support-search-hero__search-input-container {
  z-index: 1;
  position: relative;
}
.cms-customer-support-search-hero__results-container {
  z-index: 1;
  position: absolute;
  top: 56px;
  width: 100%;
}
.cms-customer-support-search-hero__results-container #autocomplete-container {
  display: none;
}
.cms-customer-support-search-hero__results-container #autocomplete-container .suggestions {
  padding: 0;
}
.cms-customer-support-search-hero__results-container #autocomplete-container .suggestions li.addsearch-suggestion {
  list-style-type: none;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.62);
  cursor: pointer;
  font-weight: 600;
}
.cms-customer-support-search-hero__results-container #autocomplete-container .suggestions .icon {
  color: rgba(0, 0, 0, 0.62);
  font-size: 21px;
  margin-right: 22px;
}
.cms-customer-support-search-hero__results-container #results-container {
  display: none;
}
.cms-customer-support-search-hero__results-container #results-container .addsearch-searchresults--no-results:focus {
  border: 1px solid #5897fb;
}
.cms-customer-support-search-hero__results-container #results-container .addsearch-searchresults--no-results p {
  margin: 0;
}
.cms-customer-support-search-hero__results-container #results-container .addsearch-searchresults .category-title {
  color: #222222;
  font-size: 18px;
  font-weight: bold;
  margin-top: 25px;
  margin-bottom: 14px;
}
.cms-customer-support-search-hero__results-container #results-container .addsearch-searchresults .hit {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1080px) {
  .cms-customer-support-search-hero__results-container #results-container .addsearch-searchresults .hit {
    margin-bottom: 8px;
  }
}
.cms-customer-support-search-hero__results-container #results-container .addsearch-searchresults .hit .category {
  max-width: 40%;
  text-align: end;
}
@media (max-width: 1080px) {
  .cms-customer-support-search-hero__results-container #results-container .addsearch-searchresults .hit .category {
    display: none;
  }
}
.cms-customer-support-search-hero__results-container #results-container .addsearch-searchresults__result-title {
  color: #222222;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.5;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
@media (max-width: 1440px) {
  .cms-customer-support-search-hero__results-container #results-container .addsearch-searchresults__result-title {
    display: flex;
  }
}
@media (min-width: 1081px) {
  .cms-customer-support-search-hero__results-container #results-container .addsearch-searchresults__result-title {
    max-width: 50%;
  }
}
.cms-customer-support-search-hero__results-container #results-container .addsearch-searchresults__result-title .icon {
  color: #990AE3;
  font-size: 16px;
  margin-right: 16px;
}
.cms-customer-support-search-hero__results-container #results-container .addsearch-searchresults__result-title span {
  line-height: 1.1;
  margin-bottom: 8px;
}
.cms-customer-support-search-hero__results-container--visible {
  border-radius: 16px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  padding: 12px 24px;
}
.cms-customer-support-search-hero__results-container--visible #autocomplete-container,
.cms-customer-support-search-hero__results-container--visible #results-container {
  display: block;
}
.cms-customer-support-search-hero #searchfield-container {
  z-index: 1;
}
.cms-customer-support-search-hero #searchfield-container .cms-customer-support-search-hero__search-container {
  width: 100%;
  border-radius: 32px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  margin-bottom: 10px;
  display: flex;
  padding: 8px 12px;
}
@media (min-width: 841px) {
  .cms-customer-support-search-hero #searchfield-container .cms-customer-support-search-hero__search-container {
    padding: 10px 25px;
  }
}
.cms-customer-support-search-hero #searchfield-container .cms-customer-support-search-hero__search-container form,
.cms-customer-support-search-hero #searchfield-container .cms-customer-support-search-hero__search-container input {
  flex: 1;
}
.cms-customer-support-search-hero #searchfield-container .cms-customer-support-search-hero__search-container input {
  width: 100%;
  height: 100%;
  border: none;
  font-size: 16px;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  caret-color: #990AE3;
  -webkit-appearance: none;
}
.cms-customer-support-search-hero #searchfield-container .cms-customer-support-search-hero__search-container .cms-customer-support-search-hero__search-icon {
  margin-right: 16px;
  font-size: 25px;
}
@media (min-width: 841px) {
  .cms-customer-support-search-hero #searchfield-container .cms-customer-support-search-hero__search-container .cms-customer-support-search-hero__search-icon {
    margin-right: 21px;
  }
}
.cms-customer-support-search-hero__suggestions {
  display: block;
  color: #ffffff;
  text-align: center;
}
.cms-customer-support-search-hero__suggestions--purple {
  color: #990AE3;
}
.cms-customer-support-search-hero__suggestions--purple a {
  color: #990AE3;
}
.cms-customer-support-search-hero__suggestions--white {
  color: #ffffff;
}
.cms-customer-support-search-hero__suggestions--white a {
  color: #ffffff;
}
.cms-customer-support-search-hero__suggestions--black {
  color: #121214;
}
.cms-customer-support-search-hero__suggestions--black a {
  color: #121214;
}
.cms-customer-support-search-hero__suggestions--grey {
  color: #F4F4F9;
}
.cms-customer-support-search-hero__suggestions--grey a {
  color: #F4F4F9;
}
.cms-customer-support-search-hero__suggestions--deepPurple {
  color: #29003e;
}
.cms-customer-support-search-hero__suggestions--deepPurple a {
  color: #29003e;
}
.cms-customer-support-search-hero__suggestions--deepBeige {
  color: #5C524E;
}
.cms-customer-support-search-hero__suggestions--deepBeige a {
  color: #5C524E;
}
.cms-customer-support-search-hero__suggestions--beige {
  color: #ECDED4;
}
.cms-customer-support-search-hero__suggestions--beige a {
  color: #ECDED4;
}
.cms-customer-support-search-hero__suggestions--lightPurple {
  color: #F4E0FF;
}
.cms-customer-support-search-hero__suggestions--lightPurple a {
  color: #F4E0FF;
}
.cms-customer-support-search-hero__suggestions--lightBeige {
  color: #F8EEE8;
}
.cms-customer-support-search-hero__suggestions--lightBeige a {
  color: #F8EEE8;
}
.cms-customer-support-search-hero__suggestions span {
  text-transform: uppercase;
  margin-right: 8px;
  font-weight: bold;
}
.cms-customer-support-search-hero__suggestions a {
  text-decoration: underline;
}

.device-rebate__anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}
.device-rebate__form-content {
  padding: 30px 0;
  max-width: 600px;
}
.device-rebate__heading-text {
  color: #990AE3;
  margin: 0.5em 0;
  max-width: none;
}
.device-rebate__heading-text--white {
  color: #ffffff;
}
.device-rebate__heading-text--centered {
  text-align: center;
}
.device-rebate__additionalText {
  margin: 0.5em 0 1em;
  max-width: none;
  margin: 0 auto;
}
.device-rebate__additionalText p {
  color: white;
}
.device-rebate__results {
  position: relative;
}
.device-rebate__background {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: none;
  height: 100%;
}
.device-rebate__results--carousel .device-rebate__background, .device-rebate__results--single .device-rebate__background {
  display: block;
}
.device-rebate__results--list .device-rebate__background {
  display: block;
  max-height: 467px;
}
.device-rebate__background-image {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
}
.device-rebate__background-image--desktop {
  display: none;
}
@media (min-width: 841px) {
  .device-rebate__background-image--desktop {
    display: block;
  }
}
@media (min-width: 841px) {
  .device-rebate__background-image--mobile {
    display: none;
  }
}
.device-rebate__result-background {
  position: absolute;
  top: 267px;
  left: 0;
  right: 0;
  z-index: 1;
  height: 200px;
  background: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgb(255, 255, 255));
  display: none;
}
.device-rebate__results--list .device-rebate__result-background {
  display: block;
}
.device-rebate__result-view {
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  max-height: 0;
  transition: max-height 0.4s;
  transition-timing-function: cubic-bezier(0, 1, 0, 1);
  overflow: hidden;
}
.device-rebate__result-view--visible {
  max-height: 10000px;
  transition-timing-function: cubic-bezier(1, 0, 1, 0);
}
.device-rebate__result-container {
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
}
.device-rebate__results--list .device-rebate__result-container {
  background-size: initial;
}
.device-rebate__result-view-content {
  padding-top: 20px;
  padding-bottom: 20px;
}
.device-rebate__promotion-callout {
  margin: 0 0 5px;
  color: #ffffff;
}
.device-rebate__promotion-price {
  font-size: 40px;
  color: #ffffff;
  margin: 0px;
}
.device-rebate__result-list {
  margin: 1.5em 0 0;
  padding-top: 0;
  padding-bottom: 16px;
  background-color: transparent;
  z-index: 2;
}
.device-rebate__result-list .product-card {
  padding: 0;
  width: 100%;
}
.device-rebate__result-list--carousel .grid {
  opacity: 0;
  transition: opacity 0.3s;
}
.device-rebate__result-list--carousel .grid.slick-initialized {
  opacity: 1;
}
.device-rebate__result-list--list .device-rebate__result-item {
  border-radius: 0;
}
.device-rebate__result-list--list > div:first-child .device-rebate__result-item {
  border-radius: 4px 4px 0 0;
}
.device-rebate__result-list--list > div:last-child .device-rebate__result-item {
  border-radius: 0 0 4px 4px;
}
.device-rebate__result-list--list .grid {
  background-color: #ffffff;
  border-radius: 2px;
}
.device-rebate__result-list--list .grid > div .product-card:before, .device-rebate__result-list--list .grid > div .product-card:after {
  display: block;
}
.device-rebate__result-list--list .grid > div .product-card__container {
  border-radius: 2px;
}
.device-rebate__result-list--list .grid > div:first-child .product-card__container {
  border-radius: 4px 4px 0 0;
}
.device-rebate__result-list--list .grid > div:last-child .product-card__container {
  border-radius: 0 0 4px 4px;
}
.device-rebate__result-item {
  background-color: #ffffff;
  height: 244px;
  width: 188px;
  border-radius: 4px;
  margin: 0 auto;
}
.device-rebate__back {
  background-color: #F2F2F2;
  text-align: center;
  padding: 20px 0;
}
.device-rebate__back-icon {
  font-size: 12px;
}
.device-rebate__back-link {
  font-weight: bold;
}
.device-rebate__button {
  text-align: center;
  margin-bottom: 1em;
}
.device-rebate__status {
  text-align: center;
  font-size: 14px;
  margin-bottom: 8px;
  color: #ffffff;
}
.device-rebate__show-all:link, .device-rebate__show-all:visited {
  color: #ffffff;
  font-weight: bold;
}
.device-rebate .notification {
  margin-top: 0;
}
.device-rebate .slick-track {
  padding: 0;
  border-radius: 4px;
  display: flex;
  align-items: stretch;
  background-color: #ffffff;
}
@media (max-width: 480px) {
  .device-rebate .slick-dots {
    justify-content: center;
  }
}
.device-rebate .product-card__container {
  width: 100%;
}
.device-rebate .slick-slide {
  height: auto;
  width: auto;
  padding: 0;
}
.device-rebate .carousel {
  overflow-x: visible;
}
.device-rebate .carousel__arrow {
  top: calc(50% - 21px);
}
@media (min-width: 1441px) {
  .device-rebate .carousel__arrow--left {
    left: calc((100% - 100vw) / 4);
  }
}
@media (min-width: 1441px) {
  .device-rebate .carousel__arrow--right {
    right: calc((100% - 100vw) / 4);
  }
}
.device-rebate__select-container {
  padding-bottom: 20px;
}
.device-rebate__input-field {
  margin-bottom: 5px !important;
}
.device-rebate__imei {
  position: relative;
  bottom: 18px;
}
.device-rebate__navbar-container .cms-tablist__navbar {
  overflow: visible;
}
.device-rebate__captcha > .g-recaptcha {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
@media (min-width: 601px) {
  .device-rebate__captcha > .g-recaptcha {
    justify-content: flex-start;
  }
}

@media (max-width: 1080px) {
  .grecaptcha-badge {
    bottom: 60px !important;
  }
}
.disturbance-map {
  margin: 2em 1em;
}
.disturbance-map__container {
  display: flex;
  flex-direction: column;
}
@media (min-width: 841px) {
  .disturbance-map__container {
    flex-direction: row;
  }
}
.disturbance-map__map {
  order: 2;
  height: 540px;
  width: 100%;
}
@media (min-width: 841px) {
  .disturbance-map__map {
    order: 1;
    height: 690px;
    width: 100%;
    max-width: 1024px;
  }
}
.disturbance-map__toolbar {
  order: 1;
  padding: 2em 0 0 0;
}
@media (min-width: 841px) {
  .disturbance-map__toolbar {
    order: 2;
    padding: 2em;
  }
}
.disturbance-map__toolbar-title {
  font-size: 16px;
  font-weight: bold;
}
.disturbance-map__color-and-description {
  margin-bottom: 12px;
}
.disturbance-map__color-and-description span {
  display: inline-block;
  vertical-align: middle;
}
.disturbance-map__color {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
}
.disturbance-map__color--active {
  background-color: #E4175C;
}
.disturbance-map__color--change {
  background-color: #FF9B00;
}
.disturbance-map__color--fixed {
  background-color: #00CC66;
}
.disturbance-map__area {
  margin-top: 2em;
}
.disturbance-map__area-name {
  margin-bottom: 10px;
}
.disturbance-map .area-selection {
  margin: 1em 0;
}
.disturbance-map .area-selection__change {
  position: relative;
  cursor: pointer;
}
@media (min-width: 841px) {
  .disturbance-map .area-selection__change {
    cursor: auto;
  }
}
.disturbance-map .area-selection__change-icon {
  position: absolute;
  right: 8px;
  top: 4px;
  font-size: 11px;
  height: 20px;
  width: 11px;
  transition: transform 0.2s;
}
@media (min-width: 841px) {
  .disturbance-map .area-selection__change-icon {
    display: none;
  }
}
.disturbance-map .area-selection__container {
  width: 100%;
  background-color: #ffffff;
  position: absolute;
  display: none;
  z-index: 100;
}
@media (min-width: 841px) {
  .disturbance-map .area-selection__container {
    position: static;
    display: block;
  }
}
@media (max-width: 840px) {
  .disturbance-map .area-selection__container:after {
    content: "";
    border: 1px solid transparent;
    width: 100vw;
    position: relative;
    display: block;
    left: calc(-50vw + 50%);
    box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.25);
  }
}
.disturbance-map .area-selection select {
  margin-bottom: 1em;
}
.disturbance-map .area-selection--open .area-selection__container {
  display: block;
}
.disturbance-map .area-selection--open .area-selection__change-icon {
  transform: rotate(180deg);
}
.disturbance-map__street-input-container {
  position: relative;
  width: 100%;
  min-width: 280px;
}
.disturbance-map__reset-button {
  margin: 2em 0;
}
.disturbance-map__reset-button .icon {
  display: none;
}
.disturbance-map__faults-container {
  margin-top: 1em;
}
.disturbance-map__faults {
  margin-top: 1em;
  display: none;
}
@media (min-width: 841px) {
  .disturbance-map__faults {
    display: block;
  }
}
.disturbance-map .faults-mobile {
  margin-top: 1em;
  display: block;
}
@media (min-width: 841px) {
  .disturbance-map .faults-mobile {
    display: none;
  }
}
.disturbance-map .faults-mobile__fault {
  padding: 8px 12px 12px 12px;
}
.disturbance-map .faults-mobile__fault:nth-child(odd) {
  background-color: #F9F9F9;
}
.disturbance-map .faults-mobile__title {
  font-weight: bold;
  margin: 4px 0 4px 0;
}
.disturbance-map__zoom {
  cursor: pointer;
}
.disturbance-map__zoom:hover {
  color: #990AE3;
}
.disturbance-map__zoom svg {
  position: relative;
  top: 2px;
  display: inline-block;
  margin-right: 4px;
}
.disturbance-map__popup {
  display: none;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  width: 220px;
  background-color: #ffffff;
  border-radius: 6px;
  position: relative;
}
@media (min-width: 481px) {
  .disturbance-map__popup {
    width: 280px;
  }
}
@media (min-width: 601px) {
  .disturbance-map__popup {
    width: 320px;
  }
}
@media (min-width: 1081px) {
  .disturbance-map__popup {
    width: 450px;
  }
}
.disturbance-map__popup-container {
  padding: 1em;
  font-size: 11px;
}
.disturbance-map__popup-title {
  display: block;
  font-weight: bold;
  width: 130px;
}
@media (min-width: 841px) {
  .disturbance-map__popup-title {
    display: inline-block;
  }
}
.disturbance-map__popup-close {
  cursor: pointer;
  font-size: 16px;
  position: absolute;
  top: 0;
  right: 2px;
  padding: 4px;
}
.disturbance-map__popup:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: 0px;
  bottom: -15px;
  left: 47%;
  box-sizing: border-box;
  border: 8px solid #000000;
  border-color: transparent transparent #ffffff #ffffff;
  transform-origin: 0 0;
  transform: rotate(-45deg);
  box-shadow: -2px 2px 5px 0 rgba(0, 0, 0, 0.12);
}
.disturbance-map .ol-control button {
  background-color: #000000;
}
.disturbance-map .ol-control button:hover {
  background-color: #990AE3;
}

.disturbance-notifications__description {
  margin: 44px 0 44px 0;
  font-size: 20px;
}
.disturbance-notifications .disturbance-notification {
  padding: 1em 32px 1em 16px;
  display: block;
  margin: 8px 0;
  border: 1px solid #F2F2F2;
  color: #707070;
  font-weight: bold;
  position: relative;
  text-align: left;
}
.disturbance-notifications .disturbance-notification:hover {
  color: #990AE3;
}
.disturbance-notifications .disturbance-notification__icon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 16px;
  font-size: 12px;
}

.disturbance-notification {
  margin-top: 40px;
}
.disturbance-notification h2 {
  margin-top: 0.2em;
}
.disturbance-notification__last-modified {
  color: #A0A0A0;
  font-size: 12px;
}
.disturbance-notification__container {
  padding-top: 40px;
}
.disturbance-notification__back-link {
  margin-top: 20px;
}
.disturbance-notification__back-icon {
  vertical-align: middle;
}

.editor-note {
  background-color: #FFFF00;
  word-spacing: normal;
  letter-spacing: normal;
  border-bottom: solid 2px #aaaa00;
}
.editor-note__content {
  padding: 10px 2em;
}
@media (min-width: 1081px) {
  .editor-note__content {
    padding: 10px 16px;
  }
}

.epressi-article-list__item {
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
  display: flex;
  padding: 10px;
}
.epressi-article-list__item:hover {
  background-color: #F9F9F9;
}
.epressi-article-list__loader {
  padding: 20px;
  text-align: center;
}
.epressi-article-list__thumbnail-container {
  min-width: 80px;
}
.epressi-article-list__thumbnail {
  max-width: 80px;
}
.epressi-article-list__item-text-container {
  margin-left: 15px;
}
.epressi-article-list__item-title-container {
  margin: 0;
}
.epressi-article-list__item-title {
  color: #3A3A3A;
  font-weight: bold;
  font-size: 0.9em;
}
.epressi-article-list__item-date {
  color: #707070;
  font-size: 12px;
}
.epressi-article-list__pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.epressi-article-list__pagination--hidden {
  display: none;
}
.epressi-article-list__pagination-items {
  list-style-type: none;
}
.epressi-article-list__pagination-link {
  padding: 6px 12px;
  display: block;
}
.epressi-article-list__pagination-item {
  margin-left: 8px;
  background-color: #F2F2F2;
  text-align: center;
}
.epressi-article-list__pagination-item--active {
  pointer-events: none;
  background-color: #990AE3;
}
.epressi-article-list__pagination-item--active .epressi-article-list__pagination-link {
  color: #ffffff;
}
.epressi-article-list__pagination-item--disabled {
  background-color: transparent;
  color: #990AE3;
  padding: 6px;
}

.epressi-article__title {
  color: #990AE3;
  max-width: 100%;
}
.epressi-article__introduction {
  font-weight: bold;
}
.epressi-article__images {
  margin: -8px;
}
.epressi-article__image-container {
  display: inline-block;
  max-width: 1000px;
  margin: 8px;
}
.epressi-article__image {
  max-width: 100%;
}
.epressi-article p {
  max-width: 100%;
}

.error-and-search {
  padding-bottom: 5em;
  background-color: #F9F9F9;
  border-top: 1px solid #E0E0E0;
  border-bottom: 1px solid #E0E0E0;
  text-align: center;
}
.error-and-search__title {
  color: #000000;
  margin-bottom: 0;
}
.error-and-search__body {
  max-width: 800px;
  margin: 1em auto;
  color: #707070;
}
.error-and-search__form {
  position: relative;
  margin: auto;
  width: 90%;
}
@media (min-width: 601px) {
  .error-and-search__form {
    width: 80%;
  }
}
@media (min-width: 1081px) {
  .error-and-search__form {
    width: 700px;
  }
}
.error-and-search__search-field {
  padding: 1em 1em 1em 3em;
  width: 100%;
}
.error-and-search__search-icon {
  position: absolute;
  left: 1em;
  top: 1em;
}

.cms-facebook-feed .fb-page {
  visibility: hidden;
}
.cms-facebook-feed__loader {
  padding: 20px;
  text-align: center;
}

.cms-fifty-fifty-hero {
  display: flex;
  flex-direction: column;
  height: calc(2 * 344px);
}
.cms-fifty-fifty-hero--limit-width {
  max-width: 1920px;
  margin: 0 auto;
}
@media (min-width: 1441px) {
  .cms-fifty-fifty-hero--limit-width {
    padding: 0 108px;
  }
}
@media (min-width: 841px) {
  .cms-fifty-fifty-hero {
    flex-direction: row;
    height: 344px;
  }
}
@media (min-width: 1081px) {
  .cms-fifty-fifty-hero {
    height: 360px;
  }
}
@media (min-width: 1441px) {
  .cms-fifty-fifty-hero {
    height: 392px;
  }
}
@media (min-width: 1921px) {
  .cms-fifty-fifty-hero {
    height: 440px;
  }
}
.cms-fifty-fifty-hero__left, .cms-fifty-fifty-hero__right {
  flex: 0 1 auto;
  height: 344px;
  overflow: hidden;
}
@media (min-width: 841px) {
  .cms-fifty-fifty-hero__left, .cms-fifty-fifty-hero__right {
    flex: 1 0 50%;
  }
}
@media (min-width: 1081px) {
  .cms-fifty-fifty-hero__left, .cms-fifty-fifty-hero__right {
    height: 360px;
  }
}
@media (min-width: 1441px) {
  .cms-fifty-fifty-hero__left, .cms-fifty-fifty-hero__right {
    height: 392px;
  }
}
@media (min-width: 1921px) {
  .cms-fifty-fifty-hero__left, .cms-fifty-fifty-hero__right {
    height: 440px;
  }
}
.cms-fifty-fifty-hero .cms-video-hero {
  height: 100%;
}
.cms-fifty-fifty-hero .cms-video-hero--color-white {
  color: #ffffff;
}
.cms-fifty-fifty-hero .cms-video-hero--color-black {
  color: #121214;
}
.cms-fifty-fifty-hero .cms-video-hero--color-purple {
  color: #29003e;
}
.cms-fifty-fifty-hero .cms-video-hero--color-deepPurple {
  color: #29003e;
}
.cms-fifty-fifty-hero .cms-video-hero--color-deepBeige {
  color: #5C524E;
}
.cms-fifty-fifty-hero .cms-video-hero--color-lightPurple {
  color: #F4E0FF;
}
.cms-fifty-fifty-hero .cms-video-hero--color-lightBeige {
  color: #F8EEE8;
}
.cms-fifty-fifty-hero .cms-video-hero__heading--color-white, .cms-fifty-fifty-hero .cms-video-hero__description--color-white {
  color: #ffffff;
}
.cms-fifty-fifty-hero .cms-video-hero__heading--color-black, .cms-fifty-fifty-hero .cms-video-hero__description--color-black {
  color: #121214;
}
.cms-fifty-fifty-hero .cms-video-hero__heading--color-purple, .cms-fifty-fifty-hero .cms-video-hero__description--color-purple {
  color: #29003e;
}
.cms-fifty-fifty-hero .cms-video-hero__heading--color-deepPurple, .cms-fifty-fifty-hero .cms-video-hero__description--color-deepPurple {
  color: #29003e;
}
.cms-fifty-fifty-hero .cms-video-hero__heading--color-deepBeige, .cms-fifty-fifty-hero .cms-video-hero__description--color-deepBeige {
  color: #5C524E;
}
.cms-fifty-fifty-hero .cms-video-hero__heading--color-lightPurple, .cms-fifty-fifty-hero .cms-video-hero__description--color-lightPurple {
  color: #F4E0FF;
}
.cms-fifty-fifty-hero .cms-video-hero__heading--color-lightBeige, .cms-fifty-fifty-hero .cms-video-hero__description--color-lightBeige {
  color: #F8EEE8;
}
.cms-fifty-fifty-hero .cms-video-hero__video-container {
  min-height: 100%;
  height: 100%;
  max-height: 100%;
}
.cms-fifty-fifty-hero .cms-video-hero__container {
  position: absolute;
  background-color: transparent;
}
.cms-fifty-fifty-hero .cms-video-hero__content {
  text-align: left;
}
.cms-fifty-fifty-hero .cms-video-hero__grid .grid__col-md-6 {
  width: 100%;
}
@media (min-width: 601px) {
  .cms-fifty-fifty-hero .cms-video-hero__grid {
    padding: 0 36px;
  }
}
@media (min-width: 841px) {
  .cms-fifty-fifty-hero .cms-video-hero__grid {
    padding: 0 18px;
  }
}
@media (min-width: 1081px) {
  .cms-fifty-fifty-hero .cms-video-hero__grid {
    padding: 0 36px;
  }
}
.cms-fifty-fifty-hero .image-hero {
  height: 100%;
}
.cms-fifty-fifty-hero .image-hero__fade-overlay--black {
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0) 70%);
}
.cms-fifty-fifty-hero .image-hero__fade-overlay--white {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.4) 30%, rgba(0, 0, 0, 0) 70%);
}
.cms-fifty-fifty-hero .image-hero--image-breakout {
  margin-bottom: 0;
  padding-bottom: 0;
  height: 100%;
}
.cms-fifty-fifty-hero .image-hero--image-breakout .image-hero__overlay-image .image-element {
  margin-bottom: 0;
  height: auto;
}
.cms-fifty-fifty-hero .image-hero__background-wrapper {
  height: 100%;
}
.cms-fifty-fifty-hero .image-hero__background-wrapper .image-hero__background {
  border-bottom: none;
  height: 100%;
}
.cms-fifty-fifty-hero .image-hero .image-element__picture, .cms-fifty-fifty-hero .image-hero .image-element__image {
  display: block;
  margin-bottom: 0;
}
.cms-fifty-fifty-hero .image-hero__grid {
  flex-direction: row;
  align-items: center;
  text-align: left;
}
@media (min-width: 601px) {
  .cms-fifty-fifty-hero .image-hero__grid {
    padding: 0 36px;
  }
}
@media (min-width: 841px) {
  .cms-fifty-fifty-hero .image-hero__grid {
    padding: 0 18px;
  }
}
@media (min-width: 1081px) {
  .cms-fifty-fifty-hero .image-hero__grid {
    padding: 0 36px;
  }
}
.cms-fifty-fifty-hero .image-hero__texts, .cms-fifty-fifty-hero .image-hero__overlay-image {
  flex: 1 1 0px;
  height: auto;
  width: 50%;
}
.cms-fifty-fifty-hero .image-hero__container {
  padding-bottom: 0;
}
.cms-fifty-fifty-hero .image-hero__container--background-lightGrey {
  background-color: #F2F2F2;
}
.cms-fifty-fifty-hero .image-hero__container--background-purple {
  background-color: #990AE3;
}
.cms-fifty-fifty-hero .image-hero__container--background-black {
  background-color: #222222;
}
.cms-fifty-fifty-hero .image-hero__container--background-white {
  background-color: #ffffff;
}
.cms-fifty-fifty-hero .image-hero__container--background-transparent {
  background-color: transparent;
}
.cms-fifty-fifty-hero .image-hero__container--background-deepBeige {
  background-color: #5C524E;
}
.cms-fifty-fifty-hero .image-hero__container--background-deepPurple {
  background-color: #29003e;
}
.cms-fifty-fifty-hero .image-hero__container--background-lightPurple {
  background-color: #F4E0FF;
}
.cms-fifty-fifty-hero .image-hero__container--background-lightBeige {
  background-color: #F8EEE8;
}
.cms-fifty-fifty-hero .image-hero__heading {
  margin-top: 0 !important;
}
.cms-fifty-fifty-hero .image-hero--color-white {
  color: #ffffff;
}
.cms-fifty-fifty-hero .image-hero--color-black {
  color: #121214;
}
.cms-fifty-fifty-hero .image-hero--color-purple {
  color: #29003e;
}
.cms-fifty-fifty-hero .image-hero--color-deepPurple {
  color: #29003e;
}
.cms-fifty-fifty-hero .image-hero--color-deepBeige {
  color: #5C524E;
}
.cms-fifty-fifty-hero .image-hero--color-lightPurple {
  color: #F4E0FF;
}
.cms-fifty-fifty-hero .image-hero--color-lightBeige {
  color: #F8EEE8;
}
.cms-fifty-fifty-hero .image-hero__heading, .cms-fifty-fifty-hero .image-hero__subheading, .cms-fifty-fifty-hero .image-hero__description {
  color: inherit;
}
.cms-fifty-fifty-hero .image-hero__heading--color-white, .cms-fifty-fifty-hero .image-hero__subheading--color-white, .cms-fifty-fifty-hero .image-hero__description--color-white {
  color: #ffffff;
}
.cms-fifty-fifty-hero .image-hero__heading--color-black, .cms-fifty-fifty-hero .image-hero__subheading--color-black, .cms-fifty-fifty-hero .image-hero__description--color-black {
  color: #121214;
}
.cms-fifty-fifty-hero .image-hero__heading--color-purple, .cms-fifty-fifty-hero .image-hero__subheading--color-purple, .cms-fifty-fifty-hero .image-hero__description--color-purple {
  color: #29003e;
}
.cms-fifty-fifty-hero .image-hero__heading--color-deepPurple, .cms-fifty-fifty-hero .image-hero__subheading--color-deepPurple, .cms-fifty-fifty-hero .image-hero__description--color-deepPurple {
  color: #29003e;
}
.cms-fifty-fifty-hero .image-hero__heading--color-deepBeige, .cms-fifty-fifty-hero .image-hero__subheading--color-deepBeige, .cms-fifty-fifty-hero .image-hero__description--color-deepBeige {
  color: #5C524E;
}
.cms-fifty-fifty-hero .image-hero__heading--color-lightPurple, .cms-fifty-fifty-hero .image-hero__subheading--color-lightPurple, .cms-fifty-fifty-hero .image-hero__description--color-lightPurple {
  color: #F4E0FF;
}
.cms-fifty-fifty-hero .image-hero__heading--color-lightBeige, .cms-fifty-fifty-hero .image-hero__subheading--color-lightBeige, .cms-fifty-fifty-hero .image-hero__description--color-lightBeige {
  color: #F8EEE8;
}
.cms-fifty-fifty-hero .image-hero__heading, .cms-fifty-fifty-hero .cms-video-hero__heading {
  font-size: 1.5rem;
}
@media (min-width: 841px) {
  .cms-fifty-fifty-hero .image-hero__heading, .cms-fifty-fifty-hero .cms-video-hero__heading {
    font-size: 1.5rem;
  }
}
@media (min-width: 1081px) {
  .cms-fifty-fifty-hero .image-hero__heading, .cms-fifty-fifty-hero .cms-video-hero__heading {
    font-size: 1.5rem;
  }
}
@media (min-width: 1441px) {
  .cms-fifty-fifty-hero .image-hero__heading, .cms-fifty-fifty-hero .cms-video-hero__heading {
    font-size: 1.75rem;
  }
}
@media (min-width: 1921px) {
  .cms-fifty-fifty-hero .image-hero__heading, .cms-fifty-fifty-hero .cms-video-hero__heading {
    font-size: 2.125rem;
  }
}
.cms-fifty-fifty-hero .image-hero__subheading {
  font-size: 1.25rem;
}
@media (min-width: 841px) {
  .cms-fifty-fifty-hero .image-hero__subheading {
    font-size: 1.25rem;
  }
}
@media (min-width: 1081px) {
  .cms-fifty-fifty-hero .image-hero__subheading {
    font-size: 1.25rem;
  }
}
@media (min-width: 1441px) {
  .cms-fifty-fifty-hero .image-hero__subheading {
    font-size: 1.375rem;
  }
}
.cms-fifty-fifty-hero .image-hero__description, .cms-fifty-fifty-hero .cms-video-hero__description {
  font-size: 1rem;
}
@media (min-width: 481px) {
  .cms-fifty-fifty-hero .image-hero__description, .cms-fifty-fifty-hero .cms-video-hero__description {
    font-size: 1rem;
  }
}
@media (min-width: 601px) {
  .cms-fifty-fifty-hero .image-hero__description, .cms-fifty-fifty-hero .cms-video-hero__description {
    font-size: 1rem;
  }
}
@media (min-width: 841px) {
  .cms-fifty-fifty-hero .image-hero__description, .cms-fifty-fifty-hero .cms-video-hero__description {
    font-size: 1rem;
  }
}
@media (min-width: 1081px) {
  .cms-fifty-fifty-hero .image-hero__description, .cms-fifty-fifty-hero .cms-video-hero__description {
    font-size: 1rem;
  }
}
@media (min-width: 1441px) {
  .cms-fifty-fifty-hero .image-hero__description, .cms-fifty-fifty-hero .cms-video-hero__description {
    font-size: 1.25rem;
  }
}
.cms-fifty-fifty-hero .cms-huippudiili-number-offer__form-container-dark .text-input__field {
  border-color: #ffffff;
  color: #ffffff;
}
.cms-fifty-fifty-hero .cms-huippudiili-number-offer__form-container-dark .text-input__field.has-value:focus ~ .text-input__label, .cms-fifty-fifty-hero .cms-huippudiili-number-offer__form-container-dark .text-input__field.has-value.focus ~ .text-input__label {
  color: #ffffff;
}
.cms-fifty-fifty-hero .cms-huippudiili-number-offer__form-container-dark .text-input__field:focus ~ .text-input__label, .cms-fifty-fifty-hero .cms-huippudiili-number-offer__form-container-dark .text-input__field.focus ~ .text-input__label {
  color: #ffffff;
}
.cms-fifty-fifty-hero .cms-huippudiili-number-offer__form-container-dark .text-input__field ~ .text-input__label {
  color: rgba(255, 255, 255, 0.66);
}
.cms-fifty-fifty-hero .cms-huippudiili-number-offer__form-container-dark .cms-text-input__error-text {
  color: #ffffff;
}
.cms-fifty-fifty-hero .cms-huippudiili-number-offer__form-container-dark .icon--error-filled {
  color: #E4175C;
}

.file-download {
  background-color: white;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
}
@media (min-width: 601px) {
  .file-download {
    padding: 30px;
    width: 80%;
  }
}
.file-download__title {
  text-align: center;
  max-width: 100%;
}
.file-download__description {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}
@media (min-width: 601px) {
  .file-download__description {
    font-size: 16px;
  }
}
.file-download__choose-file-container {
  display: flex;
  flex-direction: column;
  margin: 30px;
}
@media (min-width: 601px) {
  .file-download__choose-file-container {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
.file-download__dropdown + .select2-container {
  border: solid 2px #990AE3;
  border-radius: 8px;
  margin: 0 !important;
}
@media (min-width: 601px) {
  .file-download__dropdown + .select2-container {
    width: 50% !important;
  }
}
.file-download__dropdown + .select2-container .ppr-select__container {
  border: none;
  font-weight: bold;
  padding: 10px;
}
.file-download__dropdown + .select2-container .ppr-select__container .select2-selection__rendered {
  color: #990AE3;
  padding: 0;
  margin-right: 20px;
}
.file-download__download-button .button {
  width: 100%;
  margin: 0;
  margin-top: 12px;
  text-align: center;
  justify-content: center;
}
@media (min-width: 601px) {
  .file-download__download-button .button {
    width: auto;
    margin: 0;
    margin-left: 16px;
  }
}

#select2-file-download-select-results {
  max-width: none;
}

.cms-flat-product-card-container {
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
}
.cms-flat-product-card-container__title {
  color: #222222;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: 500;
}
@media (min-width: 841px) {
  .cms-flat-product-card-container__title {
    font-size: 24px;
    margin-bottom: 24px;
  }
}
.cms-flat-product-card-container__description {
  color: #3A3A3A;
  font-size: 14px;
  margin-bottom: 20px;
}
@media (min-width: 841px) {
  .cms-flat-product-card-container__description {
    font-size: 16px;
    margin-bottom: 24px;
  }
}

.cms-flat-product-card {
  position: relative;
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px 0 rgba(160, 160, 160, 0.5);
  padding: 14px 20px;
  margin-bottom: 16px;
}
.cms-flat-product-card--mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 118px;
}
@media (min-width: 841px) {
  .cms-flat-product-card--mobile {
    display: none;
  }
}
.cms-flat-product-card--mobile:nth-last-child(2) {
  margin-bottom: 0;
}
.cms-flat-product-card--desktop {
  display: none;
}
@media (min-width: 841px) {
  .cms-flat-product-card--desktop {
    min-height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.cms-flat-product-card--desktop:last-child {
  margin-bottom: 0;
}
.cms-flat-product-card__mobile-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
}
.cms-flat-product-card__mobile-image-and-arrow-container {
  display: flex;
  align-items: center;
}
.cms-flat-product-card__desktop-content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.cms-flat-product-card__title-and-tag-container {
  display: flex;
  align-items: flex-start;
}
.cms-flat-product-card__title {
  font-size: 14px;
  color: #222222;
  font-weight: 500;
  line-height: 20px;
}
@media (min-width: 841px) {
  .cms-flat-product-card__title {
    font-size: 20px;
  }
}
.cms-flat-product-card__tag {
  padding: 3px 4px;
  border-radius: 4px;
  font-size: 9px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 10px;
  color: #ffffff;
  text-transform: uppercase;
  white-space: nowrap;
}
.cms-flat-product-card__tag--red {
  background-color: #E4175C;
}
.cms-flat-product-card__tag--orange {
  background-color: #FF9B00;
}
.cms-flat-product-card__tag--blue {
  background-color: #0099ff;
}
.cms-flat-product-card__tag--transparent {
  background-color: rgba(112, 112, 112, 0.5);
}
.cms-flat-product-card__tag--purple {
  background-color: #990AE3;
}
@media (min-width: 841px) {
  .cms-flat-product-card__tag {
    padding: 4px 5px;
    margin-left: 16px;
    margin-top: 1px;
    font-size: 10px;
  }
}
.cms-flat-product-card--mobile .cms-flat-product-card__tag {
  position: absolute;
  top: 10px;
  right: 10px;
}
.cms-flat-product-card__description {
  font-size: 12px;
  color: #A0A0A0;
  font-weight: 500;
  line-height: 16px;
  margin-top: 2px;
}
@media (min-width: 841px) {
  .cms-flat-product-card__description {
    line-height: 16px;
    margin-top: 4px;
  }
}
.cms-flat-product-card__description--hidden-in-mobile {
  display: none;
}
@media (min-width: 601px) {
  .cms-flat-product-card__description--hidden-in-mobile {
    display: block;
  }
}
.cms-flat-product-card__price-and-description-container {
  margin-top: 18px;
}
@media (min-width: 841px) {
  .cms-flat-product-card__price-and-description-container {
    padding-left: 20px;
    margin-top: 0;
    width: 1%;
    min-width: 200px;
  }
}
.cms-flat-product-card__price {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #222222;
}
.cms-flat-product-card__price-prefix {
  font-size: 12px;
}
@media (min-width: 841px) {
  .cms-flat-product-card__price-prefix {
    font-size: 16px;
  }
}
.cms-flat-product-card__price-value {
  font-size: 20px;
}
@media (min-width: 841px) {
  .cms-flat-product-card__price-value {
    font-size: 24px;
  }
}
.cms-flat-product-card__price-unit {
  font-size: 12px;
}
@media (min-width: 841px) {
  .cms-flat-product-card__price-unit {
    font-size: 16px;
  }
}
.cms-flat-product-card__price-description {
  color: #A0A0A0;
  font-size: 11px;
  line-height: 12px;
  font-weight: 500;
  margin-top: 2px;
}
@media (min-width: 841px) {
  .cms-flat-product-card__price-description {
    font-size: 12px;
    line-height: 14px;
  }
}
.cms-flat-product-card__image-and-icon-container {
  display: flex;
  align-items: center;
  padding-left: 10px;
  line-height: 0;
}
@media (min-width: 841px) {
  .cms-flat-product-card__image-and-icon-container {
    padding-left: 0;
    margin-right: 22px;
  }
}
.cms-flat-product-card__image img {
  width: 48px;
}
.cms-flat-product-card__icon .icon {
  color: #000000;
  width: 48px;
  height: 48px;
}
.cms-flat-product-card__arrow {
  margin-left: 16px;
  line-height: 0;
}
.cms-flat-product-card__arrow .icon {
  color: #990AE3;
  height: 26px;
}

.floater {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-content: center;
  z-index: 1005;
  background-color: #990AE3;
  border-radius: 15px;
  width: 291px;
  min-height: 141px;
  padding: 15px;
  right: 24px;
  text-align: center;
  color: #ffffff;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
}
@media (min-width: 601px) {
  .floater {
    left: auto;
    bottom: 37px;
    transform: none;
  }
}
.floater--edit-mode {
  position: static;
}
.floater--shifted {
  bottom: 75px;
}
.floater__title {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 22px;
  margin-bottom: 10px;
}
.floater__close-button-container {
  position: relative;
}
.floater__close-button {
  z-index: 2002;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 19px;
  height: 19px;
  cursor: pointer;
  font-size: 15px;
  border-radius: 20px;
  background-color: #ffffff;
  color: #990AE3;
  right: -10px;
  top: -10px;
}
.floater__link {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin-top: 4px;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
}
.floater__link:link, .floater__link:hover, .floater__link:active, .floater__link:visited {
  color: #ffffff;
}
.floater__link .icon {
  font-size: 0.5em;
}
.floater__link--purple {
  color: #990AE3;
}
.floater__link--white {
  color: #ffffff;
}
.floater__link--black {
  color: #000000;
}

.footer {
  /* Following styles from footer-links.ftl */
}
.footer__links {
  background-color: #29003e;
  color: #ffffff;
}
@media (min-width: 601px) {
  .footer__links {
    padding: 20px 0;
  }
}
@media (max-width: 600px) {
  .main-container .footer__link-grid, .footer .footer__link-grid {
    padding: 0;
  }
}
@media (min-width: 1081px) {
  .footer__link-grid {
    text-align: center;
    justify-content: center;
    text-align-last: center;
  }
}
.footer__copyright {
  background-color: #29003e;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  padding: 30px;
  text-align: center;
}
.footer__copyright-text {
  display: inline-block;
  margin: 10px;
}
.footer__additional-text {
  display: block;
  margin: 10px;
}
@media (min-width: 841px) {
  .footer__additional-text {
    display: inline-block;
  }
}
.footer__copyright-link {
  margin: 10px;
  display: inline-block;
  transition: color 0.2s;
}
.footer__copyright-link:link {
  color: rgba(255, 255, 255, 0.7);
}
.footer__copyright-link:active, .footer__copyright-link:visited {
  color: #ffffff;
}
.footer__copyright-link:hover {
  color: #ffffff;
}
.footer__social {
  text-align: center;
  overflow: auto;
}
.footer__link-section {
  text-align: left;
  text-align-last: initial;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: normal;
  padding: 0;
}
@media (max-width: 600px) {
  .main-container .footer__link-section, .footer .footer__link-section {
    padding: 0;
  }
}
@media (min-width: 601px) {
  .footer__link-section {
    padding: 20px 0;
  }
}
@media (min-width: 841px) and (max-width: 1440px) {
  .footer__link-section {
    width: 20%;
  }
}
.footer__navi-button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font: inherit;
  color: inherit;
  text-align: left;
  cursor: pointer;
}
@media (max-width: 600px) {
  .footer__navi-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
@media (min-width: 601px) {
  .footer__navi-button {
    pointer-events: none;
  }
}
.footer__links-heading {
  color: #ffffff;
  text-transform: none;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  padding: 0 16px;
  height: 48px;
  line-height: 48px;
  vertical-align: middle;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}
@media (min-width: 601px) {
  .footer__links-heading {
    border: none;
    height: auto;
    line-height: 1.3;
    font-size: 20px;
  }
}
.footer__links-toggle {
  opacity: 0.7;
  position: absolute;
  right: 16px;
}
@media (min-width: 601px) {
  .footer__links-toggle {
    display: none;
  }
}
.footer__link-list {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;
  margin: 0;
  padding-left: 0px;
}
.footer__link-list > li {
  list-style-type: none;
}
@media (min-width: 601px) {
  .footer__link-list {
    max-height: none;
    margin-top: 24px;
  }
}
.footer__link {
  display: block;
  font-size: 16px;
  height: 48px;
  line-height: 48px;
  padding: 0 32px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  margin: 1px;
}
.footer__link:link, .footer__link:visited {
  color: rgba(255, 255, 255, 0.7);
}
.footer__link:hover, .footer__link:active {
  color: #ffffff;
}
@media (max-width: 600px) {
  .footer__link:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}
@media (min-width: 601px) {
  .footer__link {
    margin: 3px 3px 8px 3px;
    font-size: 14px;
    line-height: 23px;
    height: auto;
    border: none;
    padding: 0 14px;
  }
}

.form-languages {
  padding-top: 1.75em;
}
.form-languages-current {
  font-weight: bold;
}
.form-languages-item {
  float: left;
}
.form-buttons {
  padding-top: 1.75em;
  padding-bottom: 1.75em;
}
.form-container {
  margin-bottom: 1.75em;
}
.form__layer {
  padding-top: 0.75em;
}
.form__layer .notification {
  padding-top: 0.75em;
  z-index: 0;
}
.form-element-text {
  padding-top: 1.75em;
}
.form-element-help {
  display: none;
  background-color: #ffffff;
  z-index: 9999;
  border: 4px solid #0099FF;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  top: 50%;
  right: 5%;
  left: 25%;
}
.form-element-img img {
  width: 100%;
}
.form-element-tiplist--numbered > div {
  color: #990AE3;
  font-weight: bold;
}
.form-element-tiplist--numbered ul {
  list-style-type: none;
}
.form-element-tiplist--numbered ul li {
  counter-increment: item;
}
.form-element-tiplist--numbered ul li span {
  display: inline-table;
  max-width: 92%;
}
.form-element-tiplist--numbered ul li:before {
  font-size: 24px;
  margin-right: 10px;
  content: counter(item);
  color: #000;
  text-align: center;
  display: inline-block;
}
.form-element-tiplist-help {
  display: none;
  background-color: #ffffff;
  z-index: 9999;
  border: 4px solid #0099FF;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 8px;
  position: absolute;
  right: 5%;
  left: 25%;
}
.form-element-tiplist-icon {
  color: #0099FF;
  pointer-events: auto;
}
.form-element-tiplist-icon:hover + .form-element-tiplist-help {
  display: block;
}
.form-element-icon {
  color: #0099FF;
  top: 50%;
  right: 2%;
  position: absolute;
  pointer-events: auto;
}
.form-element-icon:hover + .form-element-help {
  display: block;
}

.form-loader .error-message {
  margin-top: 20px;
  margin-bottom: 5px;
}
.form-loader .error {
  border-bottom-color: #E4175C;
}
.form-loader .error + label {
  color: #E4175C;
}
.form-loader__captcha > .g-recaptcha {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
@media (min-width: 601px) {
  .form-loader__captcha > .g-recaptcha {
    justify-content: flex-start;
  }
}
.form-loader__loader {
  display: block;
  width: 100%;
  margin-top: 20px;
}
@media (min-width: 841px) {
  .form-loader__loader {
    display: inline;
    margin-left: 10px;
    position: relative;
    top: 0.4em;
  }
}

.giftcard {
  margin-bottom: 3em;
  background-color: transparent;
  position: relative;
}
.giftcard--small {
  background-color: #ffffff;
  border-radius: 8px;
  margin-bottom: 0;
  padding: 16px;
  box-shadow: 0 8px 32px 0 rgba(58, 58, 58, 0.18);
}
@media (min-width: 601px) {
  .giftcard--small {
    font-size: 0.95em;
    padding: 8px;
  }
}
@media (min-width: 841px) {
  .giftcard--small {
    padding: 16px;
    font-size: 1em;
    max-width: 600px;
  }
}
.giftcard--small .giftcard__title {
  margin: 16px 16px 0 16px;
}
.giftcard__additional-text {
  margin: 0 16px;
  max-width: 100%;
}
.giftcard__anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}
.giftcard__form {
  visibility: hidden;
}
.giftcard__first-section {
  display: block;
}
.giftcard__second-section {
  display: none;
}
.giftcard__heading-alignment {
  text-align: center;
  max-width: 100%;
}
.giftcard__container {
  background-color: #ffffff;
  box-shadow: 2px 3px 20px 7px #E0E0E0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  margin-top: 36px;
  flex-direction: column;
}
@media (max-width: 840px) {
  .giftcard__container {
    padding-bottom: 10px;
  }
}
@media (min-width: 1081px) {
  .giftcard__container {
    flex-direction: row;
    height: 88px;
  }
}
.giftcard--small .giftcard__container {
  margin-top: 0;
  padding-bottom: 0;
  flex-direction: column;
  height: auto;
  box-shadow: none;
}
.giftcard__container .text-input {
  margin-top: 1em;
}
@media (min-width: 1081px) {
  .giftcard__container .text-input {
    margin-top: 0;
  }
}
.giftcard--small .giftcard__container .text-input {
  margin-top: 1em;
}
.giftcard__container .text-input__error-text {
  position: absolute;
  bottom: -10px;
  margin-left: 3.75em;
}
@media (min-width: 1081px) {
  .giftcard__container .text-input__error-text {
    width: 100%;
    bottom: -25px;
    margin-left: 2.75em;
  }
}
@media (min-width: 1441px) {
  .giftcard__container .text-input__error-text {
    margin-left: 3.75em;
  }
}
.giftcard--small .giftcard__container .text-input__error-text {
  position: absolute;
  bottom: -10px;
  margin-left: 3.75em;
}
.giftcard__container .text-input__field {
  margin-left: 2em;
  padding-right: 22px;
  box-sizing: border-box;
  width: calc(100% - 22px);
  flex-grow: 1;
  padding-top: 0.5em;
}
@media (min-width: 1081px) {
  .giftcard__container .text-input__field {
    margin-left: 1.25em;
  }
}
@media (min-width: 1441px) {
  .giftcard__container .text-input__field {
    margin-left: 2em;
  }
}
.giftcard--small .giftcard__container .text-input__field {
  margin-left: 2em;
}
.giftcard__container .text-input__field:disabled {
  border-bottom: 1px solid #C5C5C5;
}
.giftcard__container .text-input__field:focus ~ .text-input__label, .giftcard__container .text-input__field.focus ~ .text-input__label, .giftcard__container .text-input__field.has-value ~ .text-input__label {
  top: 14px;
  margin-left: 5em;
}
@media (min-width: 1081px) {
  .giftcard__container .text-input__field:focus ~ .text-input__label, .giftcard__container .text-input__field.focus ~ .text-input__label, .giftcard__container .text-input__field.has-value ~ .text-input__label {
    top: 0;
    margin-left: 4.5em;
  }
}
@media (min-width: 1441px) {
  .giftcard__container .text-input__field:focus ~ .text-input__label, .giftcard__container .text-input__field.focus ~ .text-input__label, .giftcard__container .text-input__field.has-value ~ .text-input__label {
    margin-left: 6.25em;
  }
}
.giftcard--small .giftcard__container .text-input__field:focus ~ .text-input__label, .giftcard--small .giftcard__container .text-input__field.focus ~ .text-input__label, .giftcard--small .giftcard__container .text-input__field.has-value ~ .text-input__label {
  top: 14px;
  margin-left: 5em;
}
.giftcard__container .text-input__field::-ms-clear {
  display: none;
}
.giftcard__container .text-input__label {
  left: 0;
  margin-left: 3.75em;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 1081px) {
  .giftcard__container .text-input__label {
    margin-left: 3.25em;
  }
}
@media (min-width: 1441px) {
  .giftcard__container .text-input__label {
    margin-left: 4.75em;
  }
}
.giftcard--small .giftcard__container .text-input__label {
  margin-left: 3.75em;
}
.giftcard__input-container {
  flex-grow: 1;
  margin-left: 0.75em;
  width: 100%;
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
}
@media (min-width: 1081px) {
  .giftcard__input-container {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.giftcard--small .giftcard__input-container {
  padding-top: 1em;
  padding-bottom: 1em;
}
.giftcard__input-container .ppr-autocomplete__list {
  position: absolute;
  left: 3.75em;
  top: 3em;
  width: auto;
  right: 1em;
}
@media (min-width: 1081px) {
  .giftcard__input-container .ppr-autocomplete__list {
    top: 2em;
    right: 0;
    left: 3.5em;
  }
}
@media (min-width: 1441px) {
  .giftcard__input-container .ppr-autocomplete__list {
    left: 4.75em;
    right: 1em;
  }
}
.giftcard--small .giftcard__input-container .ppr-autocomplete__list {
  left: 3.75em;
  top: 3em;
  right: 1em;
}
.giftcard__input--icon-ok {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}
@media (min-width: 1081px) {
  .giftcard__input--icon-ok {
    right: 0;
  }
}
@media (min-width: 1441px) {
  .giftcard__input--icon-ok {
    right: 16px;
  }
}
.giftcard--small .giftcard__input--icon-ok {
  right: 16px;
}
.giftcard__input--icon-ok .icon--checkmark {
  fill: #00CC66;
  float: right;
  font-size: 18px;
  justify-content: flex-end;
}
.giftcard__item {
  flex-grow: 1;
  display: flex;
  margin-top: 1em;
  width: 100%;
  flex-wrap: wrap;
}
@media (max-width: 1080px) {
  .giftcard__item {
    padding: 0 1em;
  }
}
@media (min-width: 1081px) {
  .giftcard__item {
    padding-left: 22px;
    width: auto;
  }
}
@media (min-width: 1441px) {
  .giftcard__item {
    padding-left: 32px;
    padding-right: 12px;
  }
}
.giftcard--small .giftcard__item {
  padding: 0 1em;
  width: 100%;
}
@media (min-width: 1081px) {
  .giftcard__giftcardnumber {
    width: 20%;
  }
}
@media (min-width: 1441px) {
  .giftcard__giftcardnumber {
    width: auto;
  }
}
.giftcard--small .giftcard__giftcardnumber {
  width: 100%;
}
@media (min-width: 1081px) {
  .giftcard__businessid {
    width: 20%;
  }
}
@media (min-width: 1441px) {
  .giftcard__businessid {
    width: auto;
  }
}
.giftcard--small .giftcard__businessid {
  width: 100%;
}
@media (min-width: 1081px) {
  .giftcard__receiptnumber {
    width: 20%;
  }
}
@media (min-width: 1441px) {
  .giftcard__receiptnumber {
    width: auto;
  }
}
.giftcard--small .giftcard__receiptnumber {
  width: 100%;
}
.giftcard__icon {
  position: absolute;
  top: 50%;
  font-size: 1.5em;
  transform: translateY(-50%);
}
.giftcard__return {
  background-color: rgba(0, 204, 102, 0);
  color: #00CC66;
  border-color: #00CC66;
}
.giftcard__return:link, .giftcard__return:visited {
  color: #00CC66;
}
.giftcard__return:hover, .giftcard__return.button--hover {
  background-color: #00CC66;
  color: #ffffff;
  border-color: #00CC66;
}
.giftcard__return:disabled, .giftcard__return.button--disabled {
  color: #C5C5C5;
  border-color: #C5C5C5 !important;
  background-color: transparent;
}
.giftcard__validate {
  flex-grow: 0;
  align-self: center;
  text-align: center;
}
.giftcard__validate-button {
  padding: 28px 0 16px;
}
@media (min-width: 1081px) {
  .giftcard__validate-button {
    align-self: flex-end;
    padding: 2px 18px 0 0;
  }
}
.giftcard--small .giftcard__validate-button {
  padding: 16px 0 0 0;
}
.giftcard__error {
  color: #E4175C;
  max-width: 400px;
  margin-top: 1em;
  padding-left: 1em;
  padding-right: 1em;
}
@media (min-width: 841px) {
  .giftcard__error {
    padding-left: 24px;
    padding-right: 12px;
    max-width: 600px;
  }
}
.giftcard--small .giftcard__error {
  padding-left: 1em;
  padding-right: 1em;
  max-width: 400px;
}
.giftcard__load-animation {
  display: block;
  width: 100%;
  margin-top: 20px;
  text-align: center;
}
.giftcard__load-animation :before, .giftcard__load-animation :after {
  background-color: #F9F9F9;
}
@media (min-width: 841px) {
  .giftcard__load-animation {
    position: relative;
    top: 0.4em;
  }
}
.giftcard--small .giftcard__load-animation {
  position: static;
  top: 0;
}
.giftcard .component-loader__wrapper {
  border-radius: 8px;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
@supports (display: grid) {
  .grid-container {
    display: grid;
    grid-gap: 13px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .grid-container--mobile-column-small {
    grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
  }
  .grid-container--mobile-column-medium {
    grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
  }
  .grid-container--mobile-column-large {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
  @media (min-width: 481px) {
    .grid-container--column-small {
      grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    }
    .grid-container--column-medium {
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
    .grid-container--column-large {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }
  }
}

/* IE 11 fallback */
_:-ms-fullscreen, :root .grid-container {
  margin: -6.5px;
}

_:-ms-fullscreen, :root .grid-container > * {
  margin: 6.5px;
}

_:-ms-fullscreen, :root .grid-container--mobile-column-small > * {
  width: 130px;
}

_:-ms-fullscreen, :root .grid-container--mobile-column-medium > * {
  width: 165px;
}

_:-ms-fullscreen, :root .grid-container--mobile-column-large > * {
  width: 300px;
}

@media (min-width: 481px) {
  _:-ms-fullscreen, :root .grid-container--column-small > * {
    width: 130px;
  }
  _:-ms-fullscreen, :root .grid-container--column-medium > * {
    width: 200px;
  }
  _:-ms-fullscreen, :root .grid-container--column-large > * {
    width: 300px;
  }
}
.heading-component__anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}
.heading-component__heading {
  padding-top: 0;
  padding-bottom: 0;
}
.heading-component__heading--align-left {
  text-align: left;
}
.heading-component__heading--align-center {
  text-align: center;
}
@media (min-width: 1081px) {
  .heading-component__heading--align-center {
    margin-left: auto;
    margin-right: auto;
  }
}
.heading-component__heading--align-right {
  text-align: right;
}
@media (min-width: 1081px) {
  .heading-component__heading--align-right {
    margin-left: auto;
  }
}
.heading-component__heading--color-black {
  color: #222222;
}
.heading-component__heading--color-white {
  color: #ffffff;
}
.heading-component__heading--color-purple {
  color: #990AE3;
}
.heading-component__heading--color-deepPurple {
  color: #29003e;
}
.heading-component__heading--color-deepBeige {
  color: #5C524E;
}
.heading-component__heading--color-lightPurple {
  color: #F4E0FF;
}
.heading-component__heading--color-lightBeige {
  color: #F8EEE8;
}
.heading-component__heading--font-weight-normal {
  font-weight: normal;
}
.heading-component + .textbox {
  margin-top: 0;
}
.heading-component + .textbox > p:first-child {
  margin-top: 0;
}
.heading-component--top-spacing-none .heading-component__heading {
  margin-top: 0;
}
.heading-component--bottom-spacing-none .heading-component__heading {
  margin-bottom: 0;
}

h2.heading-component__heading {
  margin-top: 1.786em;
  margin-bottom: 1em;
}

h3.heading-component__heading {
  margin-top: 1.786em;
  margin-bottom: 1em;
}

.helppi-confirmation-form {
  display: none;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.helppi-confirmation-form__title {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 24px;
  margin-left: 19px;
  margin-top: 17px;
}
.helppi-confirmation-form__text {
  font-size: 16px;
  margin-bottom: 44px;
  margin-left: 19px;
  margin-top: 44px;
}
@media (min-width: 481px) {
  .helppi-confirmation-form__text {
    font-size: 20px;
  }
}
.helppi-confirmation-form__success {
  display: none;
  height: 100%;
}
.helppi-confirmation-form__success.active {
  display: block;
}
.helppi-confirmation-form__buttons-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  padding-top: 30px;
  text-align: center;
  width: 100%;
}
.helppi-confirmation-form__button-primary {
  font-size: 14px;
  font-weight: bold;
  width: 189px;
}
.helppi-confirmation-form__button-primary span {
  margin: 0 auto;
}

.helppi-date-selection {
  display: none;
  height: 100%;
  width: 100%;
}
.helppi-date-selection__date-container {
  margin-top: 58px;
}
.helppi-date-selection__date-container-title {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 48px;
  text-align: center;
}
.helppi-date-selection__date-container-week-info {
  background-color: #F2F2F2;
  height: 50px;
  margin-bottom: 22px;
  padding: 14px 10px;
}
@media (min-width: 1081px) {
  .helppi-date-selection__date-container-week-info {
    padding-bottom: 14px;
    padding-left: 37px;
    padding-right: 39px;
    padding-top: 14px;
  }
}
.helppi-date-selection__date-container--previous {
  color: #3A3A3A;
  cursor: pointer;
  float: left;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  width: 10%;
}
.helppi-date-selection__date-container--previous span {
  display: none;
}
@media (min-width: 1081px) {
  .helppi-date-selection__date-container--previous {
    width: 25%;
  }
  .helppi-date-selection__date-container--previous span {
    display: block;
    float: right;
    margin-right: 30px;
  }
}
.helppi-date-selection__date-container--month-name {
  color: #A0A0A0;
  float: left;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  width: 80%;
}
@media (min-width: 1081px) {
  .helppi-date-selection__date-container--month-name {
    width: 50%;
  }
}
.helppi-date-selection__date-container--next {
  color: #3A3A3A;
  cursor: pointer;
  float: left;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  text-transform: uppercase;
  width: 10%;
}
.helppi-date-selection__date-container--next span {
  display: none;
  float: left;
  margin-left: 30px;
}
@media (min-width: 1081px) {
  .helppi-date-selection__date-container--next {
    width: 25%;
  }
  .helppi-date-selection__date-container--next span {
    display: block;
  }
}
.helppi-date-selection__date-chosen {
  background: #0099ff;
  border-radius: 4px;
  color: #ffffff;
  display: none;
  font-size: 12px;
  height: 82px;
  margin-left: 5%;
  max-width: 392px;
  padding-bottom: 19px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 19px;
  text-transform: capitalize;
  width: 90%;
}
@media (min-width: 481px) {
  .helppi-date-selection__date-chosen {
    font-size: 16px;
    margin: 40px auto 0;
    padding-bottom: 19px;
    padding-left: 50px;
    padding-right: 39px;
    padding-top: 19px;
  }
}
.helppi-date-selection__date-chosen.active {
  display: flex;
}
.helppi-date-selection__date-chosen-old-reservation {
  margin-right: 10px;
  width: 45%;
}
@media (min-width: 481px) {
  .helppi-date-selection__date-chosen-old-reservation {
    float: left;
    margin-right: 29px;
  }
}
.helppi-date-selection__date-chosen-change-icon {
  margin-top: 14px;
  width: 10%;
}
@media (min-width: 481px) {
  .helppi-date-selection__date-chosen-change-icon {
    float: left;
    margin-top: 14px;
  }
}
.helppi-date-selection__date-chosen-new-reservation {
  width: 45%;
}
@media (min-width: 481px) {
  .helppi-date-selection__date-chosen-new-reservation {
    float: right;
  }
}
.helppi-date-selection .callback-calendar__date-container {
  justify-content: center;
}
.helppi-date-selection .callback-calendar__date-container .helppi-date-holder-single {
  padding-right: 5px;
}
@media (min-width: 481px) {
  .helppi-date-selection .callback-calendar__date-container .helppi-date-holder-single {
    padding-right: 22px;
  }
}
.helppi-date-selection .callback-calendar__date-container .helppi-date-holder-single .callback-calendar__date-num {
  height: 33px;
  width: 33px;
}
.helppi-date-selection__buttons-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  text-align: center;
  width: 100%;
}
.helppi-date-selection__button-primary, .helppi-date-selection__button-secondary {
  font-size: 14px;
  font-weight: bold;
  padding: 14px 20px;
  width: 125px;
}
.helppi-date-selection__button-primary span, .helppi-date-selection__button-secondary span {
  margin: 0 auto;
}

.helppi-multi-service-selection {
  display: none;
  height: 100%;
}
.helppi-multi-service-selection__list-container {
  height: 80%;
  overflow: auto;
  width: 100%;
}
.helppi-multi-service-selection__list-single {
  background-color: #ffffff;
  border-radius: 8px;
  color: #222222;
  font-size: 20px;
  height: 96px;
  margin-top: 4px;
  padding-bottom: 24px;
  padding-left: 22px;
  padding-top: 24px;
  width: 100%;
}
.helppi-multi-service-selection__list-single:hover, .helppi-multi-service-selection__list-single:active, .helppi-multi-service-selection__list-single--selected {
  border: 3px solid #0099ff;
  cursor: pointer;
}
.helppi-multi-service-selection__list-single-logo {
  float: left;
  font-size: 48px;
  margin-right: 16px;
}
.helppi-multi-service-selection__list-single-title {
  font-weight: normal;
}
.helppi-multi-service-selection__list-single-subtitle {
  font-weight: bold;
}
.helppi-multi-service-selection__buttons-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  text-align: center;
  width: 100%;
}
.helppi-multi-service-selection__button-primary, .helppi-multi-service-selection__button-secondary {
  font-size: 14px;
  font-weight: bold;
  width: 233px;
}
.helppi-multi-service-selection__button-primary span, .helppi-multi-service-selection__button-secondary span {
  margin: 0 auto;
}

.helppi-service-date-header {
  background: #0099ff;
  border-radius: 4px;
  height: 96px;
  letter-spacing: normal;
  margin-bottom: 34px;
  padding: 10px;
  width: 100%;
  word-spacing: normal;
}
@media (min-width: 481px) {
  .helppi-service-date-header {
    padding: 24px;
  }
}
.helppi-service-date-header__info {
  color: #ffffff;
  float: left;
  padding: 10px 5px;
  width: 70%;
}
@media (min-width: 481px) {
  .helppi-service-date-header__info {
    padding: 0;
    width: 80%;
  }
}
.helppi-service-date-header__info-logo {
  display: none;
  float: left;
  font-size: 48px;
  height: 48px;
  margin-right: 24px;
  width: 48px;
}
@media (min-width: 481px) {
  .helppi-service-date-header__info-logo {
    display: block;
  }
}
.helppi-service-date-header__info-timeslot {
  font-size: 14px;
  font-weight: bold;
  padding: 10px 5px;
  width: 70%;
}
@media (min-width: 481px) {
  .helppi-service-date-header__info-timeslot {
    font-size: 20px;
    padding: 0;
  }
}
.helppi-service-date-header__change {
  color: #ffffff;
  cursor: pointer;
  float: right;
  font-size: 12px;
  height: 100%;
  padding-left: 0;
  padding-top: 25px;
}
@media (min-width: 481px) {
  .helppi-service-date-header__change {
    padding-left: 10px;
    padding-top: 10px;
  }
}

.helppi-service-form {
  display: none;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}
.helppi-service-form__title {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 24px;
  text-align: center;
}
.helppi-service-form__load-animation {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s;
  z-index: 201;
}
.helppi-service-form__load-animation--active {
  opacity: 1;
  pointer-events: auto;
}
.helppi-service-form__fail {
  display: none;
}
.helppi-service-form__fail.active {
  display: block;
  padding: 10px;
  width: 100%;
}
.helppi-service-form__buttons-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}
.helppi-service-form__confirm-button-primary, .helppi-service-form__cancel-reserve-button-secondary, .helppi-service-form__cancel-button-secondary {
  display: none;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
  width: 230px;
  word-spacing: normal;
}
.helppi-service-form__confirm-button-primary.active, .helppi-service-form__cancel-reserve-button-secondary.active, .helppi-service-form__cancel-button-secondary.active {
  display: block;
}

.helppi-service-information {
  display: none;
  height: 100%;
  overflow: auto;
  width: 100%;
}
.helppi-service-information__description {
  height: 65%;
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis;
}
.helppi-service-information__buttons-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}
.helppi-service-information__button-primary, .helppi-service-information__button-secondary {
  height: 44px;
  padding: 14px 20px;
  width: 165px;
}
.helppi-service-information__button-primary span, .helppi-service-information__button-secondary span {
  font-size: 14px;
  font-weight: bold;
  margin: 0 auto;
}

.helppi-service-type-header {
  background: #0099ff;
  border-radius: 8px;
  color: #ffffff;
  height: 96px;
  letter-spacing: normal;
  margin-bottom: 6px;
  padding: 10px;
  width: 100%;
  word-spacing: normal;
}
@media (min-width: 481px) {
  .helppi-service-type-header {
    padding: 24px;
  }
}
.helppi-service-type-header__info {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  float: left;
  font-size: 14px;
  padding: 12px 5px;
  width: 75%;
}
@media (min-width: 481px) {
  .helppi-service-type-header__info {
    font-size: 20px;
    padding: 0;
    width: 80%;
  }
}
.helppi-service-type-header__info-subtitle {
  font-weight: bold;
}
.helppi-service-type-header__info-logo {
  display: none;
  float: left;
  font-size: 48px;
  height: 48px;
  margin-right: 24px;
  width: 48px;
}
@media (min-width: 481px) {
  .helppi-service-type-header__info-logo {
    display: block;
  }
}
.helppi-service-type-header__change {
  cursor: pointer;
  float: right;
  font-size: 12px;
  padding-left: 0;
  padding-top: 25px;
}
@media (min-width: 481px) {
  .helppi-service-type-header__change {
    padding-left: 10px;
    padding-top: 10px;
  }
}

.helppi-area-title {
  color: #990AE3;
}

@media (max-width: 840px) {
  .helppi-category-link {
    margin: 0 auto;
  }
}

.helppi-calendar-component {
  background-color: #F2F2F2;
  cursor: pointer;
  height: 288px;
  margin-bottom: 24px;
  margin-right: 24px;
  padding-left: 16px;
  padding-right: 15px;
  width: 288px;
}
.helppi-calendar-component-image {
  width: 100%;
}
.helppi-calendar-component-image svg {
  fill: #000000;
  height: 48px;
  margin-bottom: 28px;
  margin-left: 108px;
  margin-top: 35px;
  width: 48px;
}
.helppi-calendar-component-title {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #000000;
  font-size: 20px;
  font-weight: normal;
  margin-bottom: 10px;
  text-align: center;
}
.helppi-calendar-component-subtitle {
  color: #707070;
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 29px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
}

.helppi-overlay-container {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  background: rgba(0, 0, 0, 0.8);
  background-size: cover;
  bottom: 0;
  display: none;
  left: 0;
  letter-spacing: normal;
  opacity: 0;
  overflow: auto;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 400ms ease-in;
  word-spacing: normal;
  z-index: 200;
}
.helppi-overlay-container:target {
  opacity: 1;
  pointer-events: auto;
}
.helppi-overlay-container .helppi-modal {
  background: #F9F9F9;
  border-radius: 8px;
  height: 789px;
  margin: 10% auto;
  max-width: 840px;
  padding: 17px 15px 20px;
  position: relative;
  width: 100%;
}
@media (min-width: 601px) and (max-width: 840px) {
  .helppi-overlay-container .helppi-modal {
    width: 90%;
  }
}
@media (min-width: 841px) and (max-width: 1080px) {
  .helppi-overlay-container .helppi-modal {
    width: 80%;
  }
}
@media (min-width: 1081px) {
  .helppi-overlay-container .helppi-modal {
    width: 840px;
  }
}
.helppi-overlay-container .helppi-modal-close {
  background-color: #000000;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  display: block;
  font-size: 40px;
  height: 40px;
  margin-left: 83.75%;
  margin-top: -40px;
  width: 40px;
}
@media (min-width: 841px) {
  .helppi-overlay-container .helppi-modal-close {
    display: none;
  }
}
.helppi-overlay-container .helppi-modal .callback-calendar__time-selector {
  margin-bottom: 50px;
  margin-left: 10%;
  width: 80%;
  z-index: inherit;
}
@media (min-width: 481px) {
  .helppi-overlay-container .helppi-modal .callback-calendar__time-selector {
    margin-left: 25%;
    width: 50%;
  }
}

.helppi-service-type-header {
  background: #0099ff;
  border-radius: 8px;
  color: #ffffff;
  height: 96px;
  letter-spacing: normal;
  margin-bottom: 6px;
  padding: 10px;
  width: 100%;
  word-spacing: normal;
}
@media (min-width: 481px) {
  .helppi-service-type-header {
    padding: 24px;
  }
}
.helppi-service-type-header__info {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  float: left;
  font-size: 14px;
  padding: 12px 5px;
  width: 75%;
}
@media (min-width: 481px) {
  .helppi-service-type-header__info {
    font-size: 20px;
    padding: 0;
    width: 80%;
  }
}
.helppi-service-type-header__info-subtitle {
  font-weight: bold;
}
.helppi-service-type-header__info-logo {
  display: none;
  float: left;
  font-size: 48px;
  height: 48px;
  margin-right: 24px;
  width: 48px;
}
@media (min-width: 481px) {
  .helppi-service-type-header__info-logo {
    display: block;
  }
}
.helppi-service-type-header__change {
  cursor: pointer;
  float: right;
  font-size: 12px;
  padding-left: 0;
  padding-top: 25px;
}
@media (min-width: 481px) {
  .helppi-service-type-header__change {
    padding-left: 10px;
    padding-top: 10px;
  }
}

.helppi-service-date-header {
  background: #0099ff;
  border-radius: 4px;
  height: 96px;
  letter-spacing: normal;
  margin-bottom: 34px;
  padding: 10px;
  width: 100%;
  word-spacing: normal;
}
@media (min-width: 481px) {
  .helppi-service-date-header {
    padding: 24px;
  }
}
.helppi-service-date-header__info {
  color: #ffffff;
  float: left;
  padding: 10px 5px;
  width: 70%;
}
@media (min-width: 481px) {
  .helppi-service-date-header__info {
    padding: 0;
    width: 80%;
  }
}
.helppi-service-date-header__info-logo {
  display: none;
  float: left;
  font-size: 48px;
  height: 48px;
  margin-right: 24px;
  width: 48px;
}
@media (min-width: 481px) {
  .helppi-service-date-header__info-logo {
    display: block;
  }
}
.helppi-service-date-header__info-timeslot {
  font-size: 14px;
  font-weight: bold;
  padding: 10px 5px;
  width: 70%;
}
@media (min-width: 481px) {
  .helppi-service-date-header__info-timeslot {
    font-size: 20px;
    padding: 0;
  }
}
.helppi-service-date-header__change {
  color: #ffffff;
  cursor: pointer;
  float: right;
  font-size: 12px;
  height: 100%;
  padding-left: 0;
  padding-top: 25px;
}
@media (min-width: 481px) {
  .helppi-service-date-header__change {
    padding-left: 10px;
    padding-top: 10px;
  }
}

.helppi-service-information {
  display: none;
  height: 100%;
  overflow: auto;
  width: 100%;
}
.helppi-service-information__description {
  height: 65%;
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis;
}
.helppi-service-information__buttons-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}
.helppi-service-information__button-primary, .helppi-service-information__button-secondary {
  height: 44px;
  padding: 14px 20px;
  width: 165px;
}
.helppi-service-information__button-primary span, .helppi-service-information__button-secondary span {
  font-size: 14px;
  font-weight: bold;
  margin: 0 auto;
}

.helppi-date-selection {
  display: none;
  height: 100%;
  width: 100%;
}
.helppi-date-selection__date-container {
  margin-top: 58px;
}
.helppi-date-selection__date-container-title {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 24px;
  font-weight: normal;
  margin-bottom: 48px;
  text-align: center;
}
.helppi-date-selection__date-container-week-info {
  background-color: #F2F2F2;
  height: 50px;
  margin-bottom: 22px;
  padding: 14px 10px;
}
@media (min-width: 1081px) {
  .helppi-date-selection__date-container-week-info {
    padding-bottom: 14px;
    padding-left: 37px;
    padding-right: 39px;
    padding-top: 14px;
  }
}
.helppi-date-selection__date-container--previous {
  color: #3A3A3A;
  cursor: pointer;
  float: left;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  width: 10%;
}
.helppi-date-selection__date-container--previous span {
  display: none;
}
@media (min-width: 1081px) {
  .helppi-date-selection__date-container--previous {
    width: 25%;
  }
  .helppi-date-selection__date-container--previous span {
    display: block;
    float: right;
    margin-right: 30px;
  }
}
.helppi-date-selection__date-container--month-name {
  color: #A0A0A0;
  float: left;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  width: 80%;
}
@media (min-width: 1081px) {
  .helppi-date-selection__date-container--month-name {
    width: 50%;
  }
}
.helppi-date-selection__date-container--next {
  color: #3A3A3A;
  cursor: pointer;
  float: left;
  font-size: 14px;
  font-weight: bold;
  text-align: right;
  text-transform: uppercase;
  width: 10%;
}
.helppi-date-selection__date-container--next span {
  display: none;
  float: left;
  margin-left: 30px;
}
@media (min-width: 1081px) {
  .helppi-date-selection__date-container--next {
    width: 25%;
  }
  .helppi-date-selection__date-container--next span {
    display: block;
  }
}
.helppi-date-selection__date-chosen {
  background: #0099ff;
  border-radius: 4px;
  color: #ffffff;
  display: none;
  font-size: 12px;
  height: 82px;
  margin-left: 5%;
  max-width: 392px;
  padding-bottom: 19px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 19px;
  text-transform: capitalize;
  width: 90%;
}
@media (min-width: 481px) {
  .helppi-date-selection__date-chosen {
    font-size: 16px;
    margin: 40px auto 0;
    padding-bottom: 19px;
    padding-left: 50px;
    padding-right: 39px;
    padding-top: 19px;
  }
}
.helppi-date-selection__date-chosen.active {
  display: flex;
}
.helppi-date-selection__date-chosen-old-reservation {
  margin-right: 10px;
  width: 45%;
}
@media (min-width: 481px) {
  .helppi-date-selection__date-chosen-old-reservation {
    float: left;
    margin-right: 29px;
  }
}
.helppi-date-selection__date-chosen-change-icon {
  margin-top: 14px;
  width: 10%;
}
@media (min-width: 481px) {
  .helppi-date-selection__date-chosen-change-icon {
    float: left;
    margin-top: 14px;
  }
}
.helppi-date-selection__date-chosen-new-reservation {
  width: 45%;
}
@media (min-width: 481px) {
  .helppi-date-selection__date-chosen-new-reservation {
    float: right;
  }
}
.helppi-date-selection .callback-calendar__date-container {
  justify-content: center;
}
.helppi-date-selection .callback-calendar__date-container .helppi-date-holder-single {
  padding-right: 5px;
}
@media (min-width: 481px) {
  .helppi-date-selection .callback-calendar__date-container .helppi-date-holder-single {
    padding-right: 22px;
  }
}
.helppi-date-selection .callback-calendar__date-container .helppi-date-holder-single .callback-calendar__date-num {
  height: 33px;
  width: 33px;
}
.helppi-date-selection__buttons-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  text-align: center;
  width: 100%;
}
.helppi-date-selection__button-primary, .helppi-date-selection__button-secondary {
  font-size: 14px;
  font-weight: bold;
  padding: 14px 20px;
  width: 125px;
}
.helppi-date-selection__button-primary span, .helppi-date-selection__button-secondary span {
  margin: 0 auto;
}

.helppi-multi-service-selection {
  display: none;
  height: 100%;
}
.helppi-multi-service-selection__list-container {
  height: 80%;
  overflow: auto;
  width: 100%;
}
.helppi-multi-service-selection__list-single {
  background-color: #ffffff;
  border-radius: 8px;
  color: #222222;
  font-size: 20px;
  height: 96px;
  margin-top: 4px;
  padding-bottom: 24px;
  padding-left: 22px;
  padding-top: 24px;
  width: 100%;
}
.helppi-multi-service-selection__list-single:hover, .helppi-multi-service-selection__list-single:active, .helppi-multi-service-selection__list-single--selected {
  border: 3px solid #0099ff;
  cursor: pointer;
}
.helppi-multi-service-selection__list-single-logo {
  float: left;
  font-size: 48px;
  margin-right: 16px;
}
.helppi-multi-service-selection__list-single-title {
  font-weight: normal;
}
.helppi-multi-service-selection__list-single-subtitle {
  font-weight: bold;
}
.helppi-multi-service-selection__buttons-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  text-align: center;
  width: 100%;
}
.helppi-multi-service-selection__button-primary, .helppi-multi-service-selection__button-secondary {
  font-size: 14px;
  font-weight: bold;
  width: 233px;
}
.helppi-multi-service-selection__button-primary span, .helppi-multi-service-selection__button-secondary span {
  margin: 0 auto;
}

.helppi-service-form {
  display: none;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
}
.helppi-service-form__title {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 24px;
  text-align: center;
}
.helppi-service-form__load-animation {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s;
  z-index: 201;
}
.helppi-service-form__load-animation--active {
  opacity: 1;
  pointer-events: auto;
}
.helppi-service-form__fail {
  display: none;
}
.helppi-service-form__fail.active {
  display: block;
  padding: 10px;
  width: 100%;
}
.helppi-service-form__buttons-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
}
.helppi-service-form__confirm-button-primary, .helppi-service-form__cancel-reserve-button-secondary, .helppi-service-form__cancel-button-secondary {
  display: none;
  font-size: 14px;
  font-weight: bold;
  padding: 10px 20px;
  width: 230px;
  word-spacing: normal;
}
.helppi-service-form__confirm-button-primary.active, .helppi-service-form__cancel-reserve-button-secondary.active, .helppi-service-form__cancel-button-secondary.active {
  display: block;
}

.helppi-confirmation-form {
  display: none;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.helppi-confirmation-form__title {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 24px;
  margin-left: 19px;
  margin-top: 17px;
}
.helppi-confirmation-form__text {
  font-size: 16px;
  margin-bottom: 44px;
  margin-left: 19px;
  margin-top: 44px;
}
@media (min-width: 481px) {
  .helppi-confirmation-form__text {
    font-size: 20px;
  }
}
.helppi-confirmation-form__success {
  display: none;
  height: 100%;
}
.helppi-confirmation-form__success.active {
  display: block;
}
.helppi-confirmation-form__buttons-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 10%;
  padding-top: 30px;
  text-align: center;
  width: 100%;
}
.helppi-confirmation-form__button-primary {
  font-size: 14px;
  font-weight: bold;
  width: 189px;
}
.helppi-confirmation-form__button-primary span {
  margin: 0 auto;
}

.horizontalline {
  border: 0;
  border-top: 1px solid #f0f0f0;
  margin: 2em 0;
  flex-basis: 100%;
}

.cms-huippudiili-number-offer {
  margin-bottom: 20px;
}
.cms-huippudiili-number-offer > .form-container {
  margin: 0;
}
@media (min-width: 841px) {
  .cms-huippudiili-number-offer > .form-container {
    padding-top: 1.75em;
    padding-bottom: 1.75em;
  }
}
@media (min-width: 841px) {
  .cms-huippudiili-number-offer {
    margin-bottom: 0;
  }
}
.cms-huippudiili-number-offer__form-content {
  width: 100%;
}
.cms-huippudiili-number-offer__form-container {
  margin-bottom: 10px;
  padding-top: 0;
  padding-bottom: 0;
}
@media (min-width: 841px) {
  .cms-huippudiili-number-offer__form-container {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}
.cms-huippudiili-number-offer__input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
@media (min-width: 601px) {
  .cms-huippudiili-number-offer__input-container {
    flex-direction: row;
  }
}
@media (min-width: 841px) {
  .cms-huippudiili-number-offer__input-container {
    max-width: 600px;
  }
}
.cms-huippudiili-number-offer__input-container .cms-text-input__error-text {
  color: #E4175C;
  display: block;
  text-align: left;
  font-size: 12px;
  margin-top: 5px;
}
.cms-huippudiili-number-offer__input-container .text-input {
  margin: 0;
  margin-bottom: 15px;
  width: 100%;
  text-align: left;
}
@media (min-width: 601px) {
  .cms-huippudiili-number-offer__input-container .text-input {
    margin-bottom: 0;
    padding-right: 20px;
  }
}
@media (min-width: 601px) {
  .cms-huippudiili-number-offer__input-container .text-input .cms-text-input__error-text {
    position: absolute;
  }
}
.cms-huippudiili-number-offer__input-container > .button {
  align-self: center;
  margin: 0;
  margin-top: auto;
}
.cms-huippudiili-number-offer__captcha > .g-recaptcha {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
@media (min-width: 601px) {
  .cms-huippudiili-number-offer__captcha > .g-recaptcha {
    justify-content: flex-start;
  }
}
.cms-huippudiili-number-offer__loader {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.cms-huippudiili-number-offer__display-none {
  display: none !important;
}
.cms-huippudiili-number-offer__visibility-hidden {
  visibility: hidden !important;
}

@media (max-width: 1080px) {
  .grecaptcha-badge {
    bottom: 60px !important;
  }
}
.icon-panel {
  background-color: #F2F2F2;
  padding-top: 40px;
  padding-bottom: 52px;
}
@media (min-width: 481px) {
  .icon-panel {
    padding-left: 6px;
    padding-right: 6px;
  }
}
@media (min-width: 601px) {
  .icon-panel {
    padding-left: 8px;
    padding-right: 8px;
  }
}
@media (min-width: 1081px) {
  .icon-panel {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.icon-panel__card {
  outline: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  padding: 1em;
  margin: 2px;
}
.icon-panel__card:link, .icon-panel__card:visited, .icon-panel__card:hover, .icon-panel__card:active {
  color: #222222;
}
.icon-panel__icon {
  font-size: 24px;
  margin-right: 0.5em;
}

.icon-text-list__item {
  display: flex;
  margin-bottom: 16px;
}
.icon-text-list__item:last-of-type {
  margin-bottom: 0;
}
.icon-text-list__item-icon .icon {
  height: 24px;
  width: 24px;
}
.icon-text-list__item-text {
  text-align: left;
  margin-left: 16px;
  font-size: 16px;
  font-weight: 500;
}

.cms-iframe-coverage-map {
  width: 100%;
}

.iframe {
  width: 100%;
}

.image-and-column-split {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.image-and-column-split__anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}
.image-and-column-split--lightGrey {
  background-color: #F2F2F2;
}
.image-and-column-split--white {
  background-color: #ffffff;
}
.image-and-column-split--corePurple {
  background-color: #990AE3;
}
.image-and-column-split--darkPurple {
  background-color: #1f002e;
}
.image-and-column-split--deepPurple {
  background-color: #29003e;
}
.image-and-column-split--lightPurple {
  background-color: #F4E0FF;
}
.image-and-column-split--deepBeige {
  background-color: #5C524E;
}
.image-and-column-split--lightBeige {
  background-color: #F8EEE8;
}
.image-and-column-split--darkGrey {
  background-color: #222222;
}
.image-and-column-split--black {
  background-color: #000000;
}
.image-and-column-split--height-limit .image-and-column-split__picture {
  max-height: 400px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.image-and-column-split__image {
  width: 100%;
  display: block;
}
.image-and-column-split__picture {
  width: 100%;
}
@media (min-width: 841px) {
  .image-and-column-split__picture {
    width: 50%;
  }
}
@media (min-width: 841px) {
  .image-and-column-split__picture--image-left {
    order: 1;
  }
}
@media (min-width: 841px) {
  .image-and-column-split__picture--image-right {
    order: 2;
  }
}
.image-and-column-split__content {
  width: 100%;
}
@media (min-width: 841px) {
  .image-and-column-split__content {
    width: 50%;
  }
}
@media (min-width: 841px) {
  .image-and-column-split__content--image-left {
    order: 2;
  }
}
.image-and-column-split__content--image-left .grid--half {
  margin-left: 0;
}
@media (min-width: 841px) {
  .image-and-column-split__content--image-right {
    order: 1;
  }
}
.image-and-column-split__content--image-right .grid--half {
  margin-right: 0;
}
.image-and-column-split__content .icon-text-list {
  margin: 20px 0;
}
.image-and-column-split__column {
  text-align: center;
}
.image-and-column-split__column p {
  margin: 0.6em 0;
}
@media (max-width: 840px) {
  .image-and-column-split__column p {
    margin: 1em auto;
  }
}
@media (min-width: 841px) {
  .image-and-column-split__column {
    text-align: left;
  }
}
.image-and-column-split__column h3 {
  margin: 1.5em 0 0;
}
@media (min-width: 841px) {
  .image-and-column-split__column h3 {
    margin: 0;
  }
}
@media (max-width: 600px) {
  .image-and-column-split__column .button {
    margin: 2px 0 0;
  }
}
.image-and-column-split .grid__cell {
  min-height: 100%;
}

.cms-image-and-text {
  position: relative;
}
.cms-image-and-text--align-center {
  text-align: center;
}
.cms-image-and-text--align-right {
  text-align: right;
}
.cms-image-and-text--align-left {
  text-align: left;
  min-height: 1px;
}
.cms-image-and-text--show-on-scroll .cms-image-and-text__content {
  position: relative;
  transition: opacity 1.4s, top 1.4s;
  opacity: 0;
  top: 25px;
}
.cms-image-and-text--show-on-scroll .cms-image-and-text__content--visible {
  opacity: 1;
  top: 0;
}
@media (min-width: 841px) {
  .cms-image-and-text--image-left.cms-image-and-text--show-on-scroll .cms-image-and-text__content {
    transition: opacity 1.4s, left 1.4s;
    left: 25px;
  }
  .cms-image-and-text--image-left.cms-image-and-text--show-on-scroll .cms-image-and-text__content--visible {
    opacity: 1;
    left: 0;
  }
}
@media (min-width: 841px) {
  .cms-image-and-text--image-right.cms-image-and-text--show-on-scroll .cms-image-and-text__content {
    transition: opacity 1.4s, left 1.4s;
    left: -25px;
  }
  .cms-image-and-text--image-right.cms-image-and-text--show-on-scroll .cms-image-and-text__content--visible {
    opacity: 1;
    left: 0;
  }
}
.cms-image-and-text--show-on-scroll .cms-image-and-text__image, .cms-image-and-text--show-on-scroll .cms-image-and-text__icon {
  transition: opacity 1.2s;
  opacity: 0;
}
.cms-image-and-text--show-on-scroll .cms-image-and-text__image--visible, .cms-image-and-text--show-on-scroll .cms-image-and-text__icon--visible {
  opacity: 1;
}
@media (min-width: 841px) {
  .cms-image-and-text--horizontal .cms-image-and-text__outer-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
  }
}
@media (min-width: 841px) {
  .cms-image-and-text--horizontal .cms-image-and-text__content {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 16px;
    text-align: left;
  }
}
@media (min-width: 841px) {
  .cms-image-and-text--horizontal .cms-image-and-text__content .cms-image-and-text__text {
    margin: 0 auto 0 0;
  }
  .cms-image-and-text--horizontal .cms-image-and-text__content .cms-image-and-text__text p {
    margin-left: 0;
    margin-right: auto;
  }
}
@media (min-width: 841px) {
  .cms-image-and-text--horizontal .cms-image-and-text__image {
    flex: 0 0 50%;
    max-width: 50%;
    padding-right: 16px;
    text-align: center;
  }
}
@media (min-width: 841px) {
  .cms-image-and-text--image-right .cms-image-and-text__image {
    order: 1;
    padding-right: 0;
    padding-left: 16px;
    text-align: center;
  }
}
@media (min-width: 841px) {
  .cms-image-and-text--image-right .cms-image-and-text__content {
    text-align: left;
    padding-left: 0;
    padding-right: 16px;
  }
}
@media (min-width: 841px) {
  .cms-image-and-text--image-right .cms-image-and-text__content .cms-image-and-text__text {
    margin: 0 auto 0 0;
  }
  .cms-image-and-text--image-right .cms-image-and-text__content .cms-image-and-text__text p {
    margin-right: auto;
    margin-left: 0;
  }
}
.cms-image-and-text__inner-image {
  width: 100%;
  max-width: 100%;
}
.cms-image-and-text--reverse {
  background-color: #F9F9F9;
  border-radius: 4px;
  overflow: hidden;
}
.body--grey .cms-image-and-text--reverse, .columnrow--lightGrey .cms-image-and-text--reverse {
  background-color: #ffffff;
}
.cms-image-and-text--reverse .cms-image-and-text__content {
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.cms-image-and-text--no-margins {
  margin-bottom: 1.5em;
}
.cms-image-and-text--no-margins .cms-image-and-text__title {
  margin-top: 1.6875em;
}
.cms-image-and-text--no-margins .cms-image-and-text__text {
  padding-bottom: 0;
}
.cms-image-and-text--small-image-title .cms-image-and-text__image {
  width: 25%;
  height: 25%;
  margin: auto;
}
.cms-image-and-text--small-image-title .cms-image-and-text__title {
  font-size: 16px;
}
.cms-image-and-text__container {
  position: relative;
}
.cms-image-and-text__link-overlay {
  left: 0;
  top: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
}
.cms-image-and-text__image {
  width: 100%;
  display: block;
  border-radius: 4px;
}
.cms-image-and-text--reverse .cms-image-and-text__image {
  border-radius: 0;
}
.cms-image-and-text__icon {
  display: block;
  font-size: 56px;
  margin-top: 30px;
}
@media (min-width: 601px) {
  .cms-image-and-text__icon {
    margin-top: 60px;
  }
}
.cms-image-and-text__title {
  margin-top: 1.5em;
  margin-bottom: 0;
}
.cms-image-and-text--align-center .cms-image-and-text__title {
  margin-left: auto;
  margin-right: auto;
}
.cms-image-and-text__title--bold {
  font-weight: bold;
}
.cms-image-and-text__title--white {
  color: #ffffff;
}
.cms-image-and-text__title--purple {
  color: #990AE3;
}
.cms-image-and-text__title--deepPurple {
  color: #29003e;
}
.cms-image-and-text__title--lightPurple {
  color: #F4E0FF;
}
.cms-image-and-text__title--deepBeige {
  color: #5C524E;
}
.cms-image-and-text__title--lightBeige {
  color: #F8EEE8;
}
.cms-image-and-text__icon + .cms-image-and-text__content {
  margin-top: 15px;
}
.cms-image-and-text__icon + .cms-image-and-text__content .cms-image-and-text__title {
  margin-top: 0;
}
@media (max-width: 600px) {
  .cms-image-and-text__icon + .cms-image-and-text__content .cms-image-and-text__text {
    padding-bottom: 1em;
  }
}
.cms-image-and-text__text {
  padding-bottom: 2.5em;
  max-width: 85%;
}
.cms-image-and-text__text a {
  position: relative;
}
@media (max-width: 480px) {
  .cms-image-and-text__text {
    max-width: 100%;
  }
}
.cms-image-and-text__text .button {
  margin: 8px 0;
  position: relative;
  z-index: 6;
}
.cms-image-and-text--align-center .cms-image-and-text__text {
  margin: 0 auto;
}
.cms-image-and-text--align-center .cms-image-and-text__text p {
  margin-left: auto;
  margin-right: auto;
}
.cms-image-and-text--align-right .cms-image-and-text__text {
  float: right;
}
.cms-image-and-text__text--white {
  color: #ffffff;
}
.cms-image-and-text__text--lightPurple {
  color: #F4E0FF;
}
.columnrow__column--borders .cms-image-and-text__content {
  padding: 0 2em;
}
.columnrow__column--borders .cms-image-and-text__text {
  padding-bottom: 1.2em;
}
.cms-image-and-text__labels-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.cms-image-and-text__label-container {
  min-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 5px;
  border-radius: 3px;
  margin-top: 4px;
}
.cms-image-and-text__label-container--no-icon .cms-image-and-text__label-container-text {
  margin: 0px !important;
}
.cms-image-and-text__label-container .cms-image-and-text__label-container-text {
  margin-left: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
}
.cms-image-and-text__label-container--background-black {
  background-color: #000000;
}
.cms-image-and-text__label-container--background-white {
  background-color: #ffffff;
}
.cms-image-and-text__label-container--background-purple {
  background-color: #990AE3;
}
.cms-image-and-text__label-container--background-deepPurple {
  background-color: #29003e;
}
.cms-image-and-text__label-container--background-lightPurple {
  background-color: #F4E0FF;
}
.cms-image-and-text__label-container--background-deepBeige {
  background-color: #5C524E;
}
.cms-image-and-text__label-container--background-lightBeige {
  background-color: #F8EEE8;
}
.cms-image-and-text__label-container--background-beige {
  background-color: #ECDED4;
}
.cms-image-and-text__label-container--text-color-black {
  color: #000000;
}
.cms-image-and-text__label-container--text-color-white {
  color: #ffffff;
}
.cms-image-and-text__label-container--text-color-purple {
  color: #990AE3;
}
.cms-image-and-text__label-container--text-color-deepPurple {
  color: #29003e;
}
.cms-image-and-text__label-container--text-color-lightPurple {
  color: #F4E0FF;
}
.cms-image-and-text__label-container--text-color-deepBeige {
  color: #5C524E;
}
.cms-image-and-text__label-container--text-color-lightBeige {
  color: #F8EEE8;
}
.cms-image-and-text__label-container--text-color-beige {
  color: #ECDED4;
}

.image-hero {
  position: relative;
  /* base hero text color */
  /* desktop text colors */
  /* text part colors when in desktop size */
  /* text part colors when in mobile view */
}
@media (min-width: 601px) {
  .image-hero {
    height: 344px;
  }
}
@media (min-width: 841px) {
  .image-hero {
    height: 360px;
  }
}
@media (min-width: 1081px) {
  .image-hero {
    height: 392px;
  }
}
@media (min-width: 1441px) {
  .image-hero {
    height: 480px;
  }
}
@media (min-width: 601px) {
  .image-hero--image-breakout {
    padding-bottom: 0px;
    height: 344px;
  }
}
@media (min-width: 841px) {
  .image-hero--image-breakout {
    margin-bottom: -48px;
    padding-bottom: 48px;
    height: 408px;
  }
}
@media (min-width: 1081px) {
  .image-hero--image-breakout {
    padding-bottom: 48px;
    height: 440px;
  }
}
@media (min-width: 1441px) {
  .image-hero--image-breakout {
    padding-bottom: 48px;
    height: 528px;
  }
}
@media (min-width: 841px) {
  .image-hero--large {
    min-height: 360px;
    height: 80vh;
    max-height: 468px;
  }
}
@media (min-width: 1081px) {
  .image-hero--large {
    min-height: 392px;
    height: 80vh;
    max-height: 509.6px;
  }
}
@media (min-width: 1441px) {
  .image-hero--large {
    min-height: 480px;
    height: 80vh;
    max-height: 624px;
  }
}
@media (min-width: 601px) {
  .image-hero--color-white {
    color: #ffffff;
  }
}
@media (min-width: 601px) {
  .image-hero--color-black {
    color: #121214;
  }
}
@media (min-width: 601px) {
  .image-hero--color-purple {
    color: #29003e;
  }
}
@media (min-width: 601px) {
  .image-hero--color-deepPurple {
    color: #29003e;
  }
}
@media (min-width: 601px) {
  .image-hero--color-deepBeige {
    color: #5C524E;
  }
}
@media (min-width: 601px) {
  .image-hero--color-beige {
    color: #ECDED4;
  }
}
@media (min-width: 601px) {
  .image-hero--color-lightPurple {
    color: #F4E0FF;
  }
}
@media (min-width: 601px) {
  .image-hero--color-lightBeige {
    color: #F8EEE8;
  }
}
@media (max-width: 600px) {
  .image-hero-mobile--color-white {
    color: #ffffff;
  }
  .image-hero-mobile--color-black {
    color: #121214;
  }
  .image-hero-mobile--color-purple {
    color: #29003e;
  }
  .image-hero-mobile--color-deepPurple {
    color: #29003e;
  }
  .image-hero-mobile--color-deepBeige {
    color: #5C524E;
  }
  .image-hero-mobile--color-beige {
    color: #ECDED4;
  }
  .image-hero-mobile--color-lightPurple {
    color: #F4E0FF;
  }
  .image-hero-mobile--color-lightBeige {
    color: #F8EEE8;
  }
}
.image-hero__fade-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 601px) {
  .image-hero__fade-overlay--black {
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 30%, rgba(0, 0, 0, 0) 70%);
  }
  .image-hero__fade-overlay--white {
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.4) 30%, rgba(0, 0, 0, 0) 70%);
  }
}
.image-hero__heading, .image-hero__subheading, .image-hero__description {
  /* normally inherit base hero text color */
  /* custom colors spesified */
}
@media (min-width: 601px) {
  .image-hero__heading, .image-hero__subheading, .image-hero__description {
    color: inherit;
  }
}
@media (min-width: 601px) {
  .image-hero__heading--color-white, .image-hero__subheading--color-white, .image-hero__description--color-white {
    color: #ffffff;
  }
}
@media (min-width: 601px) {
  .image-hero__heading--color-black, .image-hero__subheading--color-black, .image-hero__description--color-black {
    color: #121214;
  }
}
@media (min-width: 601px) {
  .image-hero__heading--color-purple, .image-hero__subheading--color-purple, .image-hero__description--color-purple {
    color: #29003e;
  }
}
@media (min-width: 601px) {
  .image-hero__heading--color-deepPurple, .image-hero__subheading--color-deepPurple, .image-hero__description--color-deepPurple {
    color: #29003e;
  }
}
@media (min-width: 601px) {
  .image-hero__heading--color-lightPurple, .image-hero__subheading--color-lightPurple, .image-hero__description--color-lightPurple {
    color: #F4E0FF;
  }
}
@media (min-width: 601px) {
  .image-hero__heading--color-deepBeige, .image-hero__subheading--color-deepBeige, .image-hero__description--color-deepBeige {
    color: #5C524E;
  }
}
@media (min-width: 601px) {
  .image-hero__heading--color-beige, .image-hero__subheading--color-beige, .image-hero__description--color-beige {
    color: #ECDED4;
  }
}
@media (min-width: 601px) {
  .image-hero__heading--color-lightBeige, .image-hero__subheading--color-lightBeige, .image-hero__description--color-lightBeige {
    color: #F8EEE8;
  }
}
.image-hero__mobile-heading, .image-hero__mobile-subheading, .image-hero__mobile-description {
  /* normally inherit base hero text color */
  /* custom colors spesified */
}
@media (max-width: 600px) {
  .image-hero__mobile-heading, .image-hero__mobile-subheading, .image-hero__mobile-description {
    color: inherit;
  }
}
@media (max-width: 600px) {
  .image-hero__mobile-heading--color-white, .image-hero__mobile-subheading--color-white, .image-hero__mobile-description--color-white {
    color: #ffffff;
  }
}
@media (max-width: 600px) {
  .image-hero__mobile-heading--color-black, .image-hero__mobile-subheading--color-black, .image-hero__mobile-description--color-black {
    color: #121214;
  }
}
@media (max-width: 600px) {
  .image-hero__mobile-heading--color-purple, .image-hero__mobile-subheading--color-purple, .image-hero__mobile-description--color-purple {
    color: #29003e;
  }
}
@media (max-width: 600px) {
  .image-hero__mobile-heading--color-deepPurple, .image-hero__mobile-subheading--color-deepPurple, .image-hero__mobile-description--color-deepPurple {
    color: #29003e;
  }
}
@media (max-width: 600px) {
  .image-hero__mobile-heading--color-lightPurple, .image-hero__mobile-subheading--color-lightPurple, .image-hero__mobile-description--color-lightPurple {
    color: #F4E0FF;
  }
}
@media (max-width: 600px) {
  .image-hero__mobile-heading--color-deepBeige, .image-hero__mobile-subheading--color-deepBeige, .image-hero__mobile-description--color-deepBeige {
    color: #5C524E;
  }
}
@media (max-width: 600px) {
  .image-hero__mobile-heading--color-beige, .image-hero__mobile-subheading--color-beige, .image-hero__mobile-description--color-beige {
    color: #ECDED4;
  }
}
@media (max-width: 600px) {
  .image-hero__mobile-heading--color-lightBeige, .image-hero__mobile-subheading--color-lightBeige, .image-hero__mobile-description--color-lightBeige {
    color: #F8EEE8;
  }
}
.image-hero__heading {
  margin: 25px 0 18px;
}
@media (max-width: 600px) {
  .image-hero__heading {
    margin-bottom: 10px;
  }
}
@media (min-width: 601px) {
  .image-hero__heading {
    margin-top: 0;
  }
}
@media (min-width: 1921px) {
  .image-hero__heading {
    margin-bottom: 33px;
  }
}
.image-hero__subheading {
  margin: 0 0 8px;
}
.image-hero__description {
  max-width: none;
  margin: 0;
}
.image-hero__basic-components {
  margin: 12px 0 0;
}
@media (min-width: 841px) {
  .image-hero__basic-components {
    margin-top: 16px;
  }
}
@media (min-width: 1921px) {
  .image-hero__basic-components {
    margin-top: 26px;
  }
}
.image-hero__basic-components .button {
  margin-left: 0;
}
.image-hero__basic-components .price-component {
  margin-top: 10px;
  margin-bottom: 20px;
}
.image-hero__basic-components--no-margin {
  margin-top: 0px;
}
.image-hero__container {
  height: 100%;
  padding: 0 0 32px;
  position: relative;
}
@media (min-width: 601px) {
  .image-hero__container {
    padding: 0;
  }
}
.image-hero__container--background-lightGrey {
  background-color: #F2F2F2;
}
.image-hero__container--background-purple {
  background-color: #990AE3;
}
.image-hero__container--background-black {
  background-color: #222222;
}
.image-hero__container--background-white {
  background-color: #ffffff;
}
.image-hero__container--background-transparent {
  background-color: transparent;
}
.image-hero__container--background-deepBeige {
  background-color: #5C524E;
}
.image-hero__container--background-beige {
  background-color: #ECDED4;
}
.image-hero__container--background-deepPurple {
  background-color: #29003e;
}
.image-hero__container--background-lightPurple {
  background-color: #F4E0FF;
}
.image-hero__container--background-lightBeige {
  background-color: #F8EEE8;
}
@media (max-width: 600px) {
  .image-hero__container {
    background-color: #F2F2F2;
  }
  .image-hero__container--mobile-background-lightGrey {
    background-color: #F2F2F2;
  }
  .image-hero__container--mobile-background-purple {
    background-color: #990AE3;
  }
  .image-hero__container--mobile-background-black {
    background-color: #222222;
  }
  .image-hero__container--mobile-background-white {
    background-color: #ffffff;
  }
  .image-hero__container--mobile-background-transparent {
    background-color: transparent;
  }
  .image-hero__container--mobile-background-deepBeige {
    background-color: #5C524E;
  }
  .image-hero__container--mobile-background-beige {
    background-color: #ECDED4;
  }
  .image-hero__container--mobile-background-deepPurple {
    background-color: #29003e;
  }
  .image-hero__container--mobile-background-lightPurple {
    background-color: #F4E0FF;
  }
  .image-hero__container--mobile-background-lightBeige {
    background-color: #F8EEE8;
  }
}
.image-hero__background-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
@media (max-width: 600px) {
  .image-hero__background-wrapper {
    height: 224px;
  }
}
.image-hero__background {
  height: 100%;
  background-size: cover;
}
.image-hero__background--clipping-top-left {
  background-position: 100% 100%;
  transform-origin: 100% 100%;
}
.image-hero__background--clipping-top-center {
  background-position: 50% 100%;
  transform-origin: 50% 100%;
}
.image-hero__background--clipping-top-right {
  background-position: 0 100%;
  transform-origin: 0 100%;
}
.image-hero__background--clipping-middle-right {
  background-position: 0 50%;
  transform-origin: 0 50%;
}
.image-hero__background--clipping-bottom-right {
  background-position: 0 0;
  transform-origin: 0 0;
}
.image-hero__background--clipping-bottom-center {
  background-position: 50% 0;
  transform-origin: 50% 0;
}
.image-hero__background--clipping-bottom-left {
  background-position: 100% 0;
  transform-origin: 100% 0;
}
.image-hero__background--clipping-middle-left {
  background-position: 100% 50%;
  transform-origin: 100% 50%;
}
.image-hero__background--clipping-center {
  background-position: 50% 50%;
  transform-origin: 50% 50%;
}
.image-hero__background--clipping-stretch {
  background-size: 100% 100%;
  transform-origin: 50% 50%;
}
@media (max-width: 600px) {
  .image-hero__background {
    height: 224px;
    border-bottom: none;
  }
}
.image-hero__background--mobile {
  background-position: 50% 50%;
}
@media (min-width: 601px) {
  .image-hero__background--mobile {
    display: none;
    background: none !important;
  }
}
@media (max-width: 600px) {
  .image-hero__background--desktop {
    display: none;
    background: none !important;
  }
}
.image-hero--has-motion .image-hero__background {
  transition: opacity 0.5s, transform 2.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  opacity: 1;
}
.image-hero--motion .image-hero__background {
  transform: scale(1.03);
  opacity: 1;
}
.image-hero__overlay-image {
  height: 224px;
}
@media (min-width: 601px) {
  .image-hero__overlay-image {
    height: auto;
    max-height: 100%;
  }
}
.image-hero__overlay-image .splash {
  position: absolute;
  margin: 0;
  right: 8%;
  top: 20px;
  z-index: 1;
}
@media (min-width: 601px) {
  .image-hero__overlay-image .splash {
    right: 0;
    top: 48px;
  }
}
.image-hero__grid {
  height: 100%;
}
@media (max-width: 600px) {
  .image-hero__grid {
    flex-direction: column-reverse;
    align-items: center;
  }
}
.image-hero--has-motion .image-hero__grid {
  transition: opacity 2s;
  opacity: 1;
}
.image-hero--motion .image-hero__grid {
  opacity: 1;
}
.image-hero__texts {
  align-self: center;
}
@media (min-width: 601px) {
  .image-hero--image-breakout .image-element {
    height: calc(100% + 0px);
    margin-bottom: 0px;
  }
}
@media (min-width: 841px) {
  .image-hero--image-breakout .image-element {
    height: calc(100% + 48px);
    margin-bottom: -48px;
  }
}

.image-hero .image-element__picture {
  width: auto;
  z-index: 1;
  pointer-events: none;
}
@media (min-width: 601px) and (max-width: 840px) {
  .image-hero .image-element__picture {
    margin-bottom: 40px;
  }
}
@media (min-width: 601px) {
  .image-hero .image-element__picture {
    position: relative;
    display: inline-block;
  }
}
.image-hero .image-element__image {
  max-height: 200px;
  max-width: 100%;
  width: auto;
  height: auto;
}
@media (min-width: 601px) {
  .image-hero .image-element__image {
    max-height: 312px;
  }
}
@media (min-width: 841px) {
  .image-hero .image-element__image {
    max-height: 312px;
  }
}
@media (min-width: 1081px) {
  .image-hero .image-element__image {
    max-height: 344px;
  }
}
@media (min-width: 1441px) {
  .image-hero .image-element__image {
    max-height: 432px;
  }
}
@media (min-width: 841px) {
  .image-hero--image-breakout .image-element__image {
    max-height: 408px;
  }
}
@media (min-width: 1081px) {
  .image-hero--image-breakout .image-element__image {
    max-height: 440px;
  }
}
@media (min-width: 1441px) {
  .image-hero--image-breakout .image-element__image {
    max-height: 528px;
  }
}

@media (min-width: 601px) {
  .image-hero--image-breakout .media-container {
    height: calc(100% + 0px);
    margin-bottom: 0px;
  }
}
@media (min-width: 841px) {
  .image-hero--image-breakout .media-container {
    height: calc(100% + 48px);
    margin-bottom: -48px;
  }
}

.image-hero .media-container__image {
  max-height: 200px;
  max-width: 100% !important;
  width: auto;
  height: auto;
}
@media (min-width: 601px) {
  .image-hero .media-container__image {
    max-height: 312px;
  }
}
@media (min-width: 841px) {
  .image-hero .media-container__image {
    max-height: 312px;
  }
}
@media (min-width: 1081px) {
  .image-hero .media-container__image {
    max-height: 344px;
  }
}
@media (min-width: 1441px) {
  .image-hero .media-container__image {
    max-height: 432px;
  }
}
@media (min-width: 841px) {
  .image-hero--image-breakout .media-container__image {
    max-height: 408px;
  }
}
@media (min-width: 1081px) {
  .image-hero--image-breakout .media-container__image {
    max-height: 440px;
  }
}
@media (min-width: 1441px) {
  .image-hero--image-breakout .media-container__image {
    max-height: 528px;
  }
}

@media (min-width: 601px) {
  .image-hero .cms-huippudiili-number-offer__form-container-dark .text-input__field {
    border-color: #ffffff;
    color: #ffffff;
  }
  .image-hero .cms-huippudiili-number-offer__form-container-dark .text-input__field.has-value:focus ~ .text-input__label, .image-hero .cms-huippudiili-number-offer__form-container-dark .text-input__field.has-value.focus ~ .text-input__label {
    color: #ffffff;
  }
  .image-hero .cms-huippudiili-number-offer__form-container-dark .text-input__field:focus ~ .text-input__label, .image-hero .cms-huippudiili-number-offer__form-container-dark .text-input__field.focus ~ .text-input__label {
    color: #ffffff;
  }
  .image-hero .cms-huippudiili-number-offer__form-container-dark .text-input__field ~ .text-input__label {
    color: rgba(255, 255, 255, 0.66);
  }
  .image-hero .cms-huippudiili-number-offer__form-container-dark .cms-text-input__error-text {
    color: #ffffff;
  }
  .image-hero .cms-huippudiili-number-offer__form-container-dark .icon--error-filled {
    color: #E4175C;
  }
}

.image-element:only-child {
  min-height: 100%;
}
.image-element--left {
  text-align: left;
}
.image-element--center {
  text-align: center;
}
.image-element--center img {
  margin: 0 auto;
}
.image-element--right {
  text-align: right;
}
.image-element__picture {
  max-width: 100%;
}
.image-element__picture--fit {
  width: 100%;
}
.image-element__image {
  max-width: 100%;
  display: inline-block;
}
.image-element__image--fit {
  width: 100%;
}

.cms-instagram-feed {
  /* instagram embed script creates some styles that we want to override here */
}
.cms-instagram-feed iframe.instagram-media {
  margin: 0 !important;
  min-width: 100% !important;
  /* iPhone would not shrink the iframe to fit the container without this */
  width: 1px;
}
.cms-instagram-feed__loader {
  padding: 20px;
  text-align: center;
}

.job-details__image {
  max-width: 100%;
}
.job-details__description p, .job-details__description span {
  font-size: inherit !important;
  font-family: inherit !important;
}
.job-details__info-title {
  font-weight: bold;
}
.job-details__info {
  line-height: 20px;
  padding-bottom: 1em;
}
.job-details__button--left {
  display: none;
}
@media (min-width: 841px) {
  .job-details__button--left {
    display: block;
  }
}
.job-details__error {
  margin-top: 20px;
}

.job-listing-yaw {
  max-width: 1440px;
}
.job-listing-yaw__anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}
.job-listing-yaw__heading--black {
  color: #000000;
}
.job-listing-yaw__select-container {
  display: none;
}
.job-listing-yaw__pagination-container {
  text-align: center;
  padding-top: 20px;
  display: none;
}
.job-listing-yaw__pagination-list {
  display: inline-block;
}
.job-listing-yaw__pagination-list li {
  cursor: pointer;
}
.job-listing-yaw__pagination-numbered.current {
  font-weight: bolder;
  color: #9706e2;
}
.job-listing-yaw__pagination-numbered-container, .job-listing-yaw__pagination-numbered-beginning, .job-listing-yaw__pagination-numbered, .job-listing-yaw__pagination-numbered-end {
  padding: 0;
}
.job-listing-yaw__pagination-later, .job-listing-yaw__pagination-earlier {
  height: 20px;
}
.job-listing-yaw__pagination-last:after, .job-listing-yaw__pagination-first:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0' y='0' viewBox='0 0 16 14'><path d='M1.6,14c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1L5.2,7L0.5,2.5c-0.6-0.6-0.6-1.5,0-2.1s1.6-0.6,2.2,0l5.9,5.5c0.6,0.6,0.6,1.5,0,2.1l-5.9,5.5C2.3,13.9,2,14,1.6,14z'/><path d='M8.6,14c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1L12.2,7L7.4,2.5C6.8,1.9,6.8,1,7.4,0.4s1.6-0.6,2.2,0l5.9,5.5c0.6,0.6,0.6,1.5,0,2.1l-5.9,5.5C9.3,13.9,8.9,14,8.6,14z'/></svg>");
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 15px;
  width: 16px;
}
.job-listing-yaw__pagination-next:after, .job-listing-yaw__pagination-previous:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0' y='0' viewBox='0 0 9 14'><path d='M1.6,14c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1L5.2,7L0.5,2.5c-0.6-0.6-0.6-1.5,0-2.1s1.6-0.6,2.2,0l5.9,5.5c0.6,0.6,0.6,1.5,0,2.1l-5.9,5.5C2.4,13.9,2,14,1.6,14z'/></svg>");
  background-repeat: no-repeat;
  content: "";
  display: block;
  height: 15px;
  width: 10px;
}
.job-listing-yaw__pagination-previous:after, .job-listing-yaw__pagination-first:after {
  transform: rotate(180deg);
}
.job-listing-yaw__pagination-list {
  list-style-type: none;
  overflow: hidden;
}
.job-listing-yaw__pagination-list li {
  width: 30px;
  float: left;
}
.job-listing-yaw__search-label {
  display: block;
  margin: 0 0 8px;
  font-size: 14px;
  font-weight: bolder;
}
.job-listing-yaw__search-input {
  border: none;
  width: calc(100% - 40px);
  height: 24px;
  vertical-align: top;
}
.job-listing-yaw__search-box {
  border-bottom: 1px solid #B0B0B5;
}
.job-listing-yaw__search-icon-box {
  display: inline-block;
  padding: 0 12px 4px 0;
}
.job-listing-yaw__search-icon {
  width: 24px;
  height: 24px;
}
.job-listing-yaw__select-box {
  padding-top: 20px;
  padding-bottom: 20px;
}
.job-listing-yaw__select-label {
  color: #A0A0A0;
}
.job-listing-yaw__vacancy {
  padding: 16px 5%;
  width: 100vw;
  margin-left: -5.5%;
}
@media (min-width: 841px) {
  .job-listing-yaw__vacancy {
    padding: 0;
    margin: 0;
    width: auto;
  }
}
.job-listing-yaw__table {
  display: none;
}
@media (max-width: 1080px) {
  .job-listing-yaw__table-container {
    padding: 0;
  }
}
.job-listing-yaw__column-table-headings {
  display: none;
}
@media (min-width: 841px) {
  .job-listing-yaw__column-table-headings {
    display: block;
  }
}
.job-listing-yaw__column-heading {
  font-weight: bold;
  cursor: pointer;
}
.job-listing-yaw__vacancy-data {
  display: block;
  padding: 8px 0;
}
@media (min-width: 841px) {
  .job-listing-yaw__vacancy-data {
    font-size: 13px;
  }
}
.job-listing-yaw__columns {
  display: flex;
}
.job-listing-yaw__title-column {
  padding: 8px 0;
  max-width: 50%;
  color: #3A3A3A;
}
@media (min-width: 841px) {
  .job-listing-yaw__title-column {
    display: none;
  }
}
.job-listing-yaw__data-column {
  max-width: 50%;
  color: #000000;
}
.job-listing-yaw__vacancy-link:hover .job-listing-yaw__data-column {
  color: #A0A0A0;
}
.job-listing-yaw__vacancy:nth-child(odd) {
  background-color: #F9F9F9;
}
.job-listing-yaw__icon {
  position: absolute;
  top: 10px;
  right: 20px;
}
@media (max-width: 840px) {
  .job-listing-yaw__icon {
    display: none;
  }
}
.job-listing-yaw__button-container {
  display: none;
  padding-top: 30px;
}
.job-listing-yaw__button-container .button {
  margin-left: 0;
}
@media (max-width: 1080px) {
  .job-listing-yaw__loader {
    margin-top: 26px;
  }
}

.job-listing__anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}
.job-listing__heading--black {
  color: #000000;
}
.job-listing__select-container {
  display: none;
}
.job-listing__select-box {
  padding-top: 20px;
  padding-bottom: 20px;
}
.job-listing__select-label {
  color: #A0A0A0;
}
.job-listing__vacancy {
  padding: 16px 5%;
  width: 100vw;
  margin-left: -5.5%;
}
@media (min-width: 841px) {
  .job-listing__vacancy {
    padding: 0;
    margin: 0;
    width: auto;
  }
}
.job-listing__table {
  display: none;
}
@media (max-width: 1080px) {
  .job-listing__table-container {
    padding: 0;
  }
}
.job-listing__column-table-headings {
  display: none;
}
@media (min-width: 841px) {
  .job-listing__column-table-headings {
    display: block;
  }
}
.job-listing__column-heading {
  font-weight: bold;
}
.job-listing__vacancy-data {
  display: block;
  padding: 8px 0;
}
@media (min-width: 841px) {
  .job-listing__vacancy-data {
    font-size: 13px;
  }
}
.job-listing__columns {
  display: flex;
}
.job-listing__title-column {
  padding: 8px 0;
  max-width: 50%;
  color: #3A3A3A;
}
@media (min-width: 841px) {
  .job-listing__title-column {
    display: none;
  }
}
.job-listing__data-column {
  max-width: 50%;
  color: #000000;
}
.job-listing__vacancy-link:hover .job-listing__data-column {
  color: #A0A0A0;
}
.job-listing__vacancy:nth-child(odd) {
  background-color: #F9F9F9;
}
.job-listing__icon {
  position: absolute;
  top: 10px;
  right: 20px;
}
@media (max-width: 840px) {
  .job-listing__icon {
    display: none;
  }
}
.job-listing__button-container {
  display: none;
  padding-top: 30px;
}
.job-listing__button-container .button {
  margin-left: 0;
}
@media (max-width: 1080px) {
  .job-listing__loader {
    margin-top: 26px;
  }
}

.b2b-landline-disconnection-form__submit {
  margin-top: 32px;
}
.b2b-landline-disconnection-form__privacy-notice {
  margin-top: 32px;
}
.b2b-landline-disconnection-form__addMoreLandlineData {
  font-size: 23px;
  cursor: pointer;
  position: relative;
  top: 24px;
}
@media (min-width: 601px) {
  .b2b-landline-disconnection-form__addMoreLandlineData {
    left: 5px;
  }
}
.b2b-landline-disconnection-form__addMoreLandlineData:hover {
  color: #CC00FF;
}
.b2b-landline-disconnection-form__landlineNoTitle {
  position: relative;
  top: 20px;
}
.b2b-landline-disconnection-form__deleteRow {
  font-size: 23px;
  cursor: pointer;
}
@media (min-width: 601px) {
  .b2b-landline-disconnection-form__deleteRow {
    margin-top: 52px;
  }
}
.b2b-landline-disconnection-form__deleteRow:hover {
  color: #CC00FF;
}
.b2b-landline-disconnection-form__seperator {
  display: block;
  border-bottom: 2px solid #990AE3;
  margin-top: 48px;
}
@media (min-width: 601px) {
  .b2b-landline-disconnection-form__seperator {
    display: none;
  }
}
.b2b-landline-disconnection-form__element-group {
  background: #ffffff;
  border-radius: 4px;
  padding: 0px 18px 47px 13px;
  box-shadow: 1px 2px 2px #E0E0E0;
}
.b2b-landline-disconnection-form__disconnectiondate-desc {
  margin-top: -24px;
}
@media (min-width: 601px) {
  .b2b-landline-disconnection-form .landlineDataGroup {
    padding-left: 24px;
  }
}
@media (min-width: 601px) {
  .b2b-landline-disconnection-form .inputTextWrapper {
    padding-left: 12px;
  }
}
.b2b-landline-disconnection-form .error {
  border-bottom-color: #E4175C;
}
.b2b-landline-disconnection-form .error + label {
  color: #E4175C;
}

.b2b-landline-disconnection__error-message {
  color: #E4175C;
}

.liiga-game-feed__mobile .slick-dots {
  visibility: hidden;
}
.liiga-game-feed__title {
  justify-content: flex-start;
}
.liiga-game-feed__game-card {
  position: relative;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.liiga-game-feed__live-flag {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #E4175C;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
}
.liiga-game-feed__price {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #000000;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
}
.liiga-game-feed__game-image {
  width: 100%;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 191px;
}
@media (min-width: 841px) {
  .liiga-game-feed__game-image {
    height: 128px;
  }
}
@media (min-width: 1441px) {
  .liiga-game-feed__game-image {
    height: 223px;
  }
}
.liiga-game-feed__game-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  margin-top: 1em;
}
.liiga-game-feed__team-logo {
  max-height: 2em;
}
.liiga-game-feed__card-title {
  font-size: 1.2em;
  margin: 0;
}
.liiga-game-feed__card-text {
  margin-bottom: 0;
}

.liiga-hero {
  position: relative;
  padding-top: 25px;
  padding-bottom: 40px;
  color: #3A3A3A;
}
@media (min-width: 601px) {
  .liiga-hero {
    padding: 75px 0;
  }
}
.liiga-hero--dark {
  color: #ffffff;
}
.liiga-hero__background {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (min-width: 601px) {
  .liiga-hero__background--mobile {
    display: none;
    background: none !important;
  }
}
@media (max-width: 600px) {
  .liiga-hero__background--desktop {
    display: none;
    background: none !important;
  }
}
.liiga-hero__pre-title {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;
}
@media (min-width: 601px) {
  .liiga-hero__pre-title {
    margin-bottom: 8px;
  }
}
.liiga-hero__title-container {
  font-size: 1.1em;
}
@media (min-width: 601px) {
  .liiga-hero__title-container {
    margin-bottom: 21px;
  }
}
.liiga-hero--dark .liiga-hero__title {
  color: #ffffff;
}
.liiga-hero__price {
  line-height: 48px;
  margin-bottom: 16px;
  font-size: 24px;
}
@media (min-width: 601px) {
  .liiga-hero__price {
    margin-bottom: 28px;
    font-size: 32px;
  }
}
.liiga-hero__price-prefix {
  font-weight: bold;
  font-size: 1em;
}
.liiga-hero__price-value {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1.5em;
}
.liiga-hero__price-unit {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 1em;
}
.liiga-hero__button {
  margin-bottom: 10px;
  align-self: center;
}
@media (min-width: 601px) {
  .liiga-hero__button {
    align-self: flex-start;
  }
}
.liiga-hero__button .button {
  margin-left: 0;
}
.liiga-hero__description {
  line-height: 28px;
  max-width: 294px;
}
.liiga-hero__feature-image {
  display: none;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
@media (min-width: 601px) {
  .liiga-hero__feature-image {
    display: block;
  }
}
.liiga-hero__feature-image--mobile {
  display: block;
  height: auto;
  width: 70%;
  margin-bottom: 16px;
  align-self: center;
  min-height: 1px; /* IE 11 image in flex fix. Without this, the image would stretch vertically */
}
@media (min-width: 601px) {
  .liiga-hero__feature-image--mobile {
    display: none;
  }
}

.liiga-product-card {
  color: #3A3A3A;
  background-color: #F2F2F2;
  border-radius: 8px;
  height: 100%;
  overflow: hidden;
  max-width: 500px;
  margin-bottom: 24px;
  /* Fix a bug where liiga product cards are not visible on Safari 10.x. */
  /* Safari 10.1 */
  /* Safari 10.0 (not 10.1) */
}
@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
    .liiga-product-card {
      height: auto !important;
    }
  }
}
.liiga-product-card _::-webkit-:host:not(:root:root), .liiga-product-card {
  height: auto !important;
}
.liiga-product-card__background-image {
  position: relative;
  width: 100%;
  height: 347px;
  background-position: center;
  background-size: cover;
}
.liiga-product-card__foreground-image {
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  bottom: -36px;
}
.liiga-product-card__foreground-image img {
  max-width: 185px;
}
.liiga-product-card__content {
  position: relative;
  padding: 15px 36px 10px;
}
.liiga-product-card__change-team-link {
  display: inline-block;
  position: absolute;
  top: 15px;
  color: #707070;
}
.liiga-product-card__change-team-link .icon {
  pointer-events: all;
  vertical-align: middle;
  height: 24px;
  width: 24px;
}
.liiga-product-card__change-team-link:hover {
  color: #CC00FF;
  cursor: pointer;
}
.liiga-product-card__change-team-link span {
  vertical-align: middle;
}
.liiga-product-card__title {
  color: #3A3A3A;
  margin-bottom: 16px;
  font-size: 30px;
}
.liiga-product-card__description {
  line-height: 24px;
  margin-bottom: 24px;
  font-size: 14px;
}
@media (min-width: 481px) {
  .liiga-product-card__description {
    font-size: 16px;
  }
}
.liiga-product-card__price-container {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin-bottom: 8px;
  font-size: 20px;
}
@media (min-width: 481px) {
  .liiga-product-card__price-container {
    font-size: 24px;
  }
}
.liiga-product-card__price-value {
  font-size: 2em;
}
.liiga-product-card__price-unit {
  font-size: 1.6em;
}
.liiga-product-card__price-info {
  font-size: 0.7em;
  line-height: 1;
  margin-bottom: 14px;
}
.liiga-product-card__disclaimer {
  font-size: 0.7em;
  line-height: 1;
  margin: 14px 0 20px;
}
.liiga-product-card__button {
  margin-bottom: 6px;
}
.liiga-product-card__button .button {
  margin-left: 0;
}
.liiga-product-card__secondary-button-container {
  font-size: 18px;
  color: #990AE3;
  margin-bottom: 6px;
}
.liiga-product-card__secondary-button {
  font-weight: normal;
}
.liiga-product-card__secondary-button:after {
  margin-left: -2px;
}
.liiga-product-card__secondary-button:hover {
  cursor: pointer;
  color: #CC00FF;
}

.link-grid {
  margin-bottom: 4em;
}
.link-grid__header {
  text-align: center;
  margin-bottom: 40px;
}
.link-grid__heading {
  margin: 0 auto;
  color: #222222;
}
.link-grid__link {
  margin-bottom: 12px;
  padding: 20px 0;
  text-align: center;
}
@media (min-width: 841px) {
  .link-grid__link {
    padding: 40px;
  }
}
.link-grid__link a:link, .link-grid__link a:visited {
  color: #222222;
}
.link-grid__link .link-grid__figure {
  color: #222222;
}
.link-grid__link:hover {
  background-color: #F9F9F9;
}
.link-grid__link:hover a {
  color: #990AE3;
}
.link-grid__link:hover .link-grid__figure {
  color: #990AE3;
}
.link-grid__icon {
  font-size: 36px;
  width: 48px;
  height: 48px;
}
@media (min-width: 841px) {
  .link-grid__icon {
    width: 60px;
    height: 60px;
    font-size: 52px;
  }
}
.link-grid__item-title {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  text-transform: none;
  margin: 1em auto 0;
  font-size: 16px;
  color: #222222;
  display: inline-block;
}
@media (min-width: 841px) {
  .link-grid__item-title {
    font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: normal;
    font-size: 24px;
    display: block;
  }
}
.link-grid__link:hover .link-grid__item-title {
  color: #990AE3;
}
.revealable-component .link-grid {
  margin-top: 40px;
  margin-bottom: 1em;
  padding: 16px 0;
  background-color: #F2F2F2;
}
.revealable-component .link-grid__header {
  display: none;
}
@media (max-width: 840px) {
  .revealable-component .link-grid__item {
    width: 100%;
  }
}
.revealable-component .link-grid__item-title {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  text-transform: none;
  font-size: 16px;
}
.revealable-component .link-grid__figure {
  display: inline-block;
  vertical-align: middle;
}
@media (max-width: 840px) {
  .revealable-component .link-grid__figure {
    margin-right: 24px;
  }
}
@media (max-width: 840px) {
  .revealable-component .link-grid__link {
    text-align: left;
    display: block;
    padding: 0;
  }
}
@media (min-width: 841px) {
  .revealable-component .link-grid__link {
    display: inline-block;
    padding: 2em 1em;
  }
}
.revealable-component .link-grid__link a {
  color: #222222;
}
.revealable-component .link-grid__link .icon {
  fill: #222222;
  font-size: 36px;
}
.revealable-component .link-grid__link:hover {
  background-color: #F2F2F2;
}
.revealable-component .link-grid__link:hover a {
  color: #990AE3;
}
.revealable-component .link-grid__link:hover .icon {
  fill: #990AE3;
}
.revealable-component .link-grid__icon {
  width: 48px;
  height: 48px;
}

.linklist {
  color: #000000;
  padding: 1em 0;
}
.linklist--purple {
  color: #990AE3;
}
.linklist__list {
  border-top: 1px solid #E0E0E0;
  width: 100%;
}
.linklist__label {
  border-top: 1px solid #E0E0E0;
}
@media (max-width: 1080px) {
  .linklist__label {
    padding-bottom: 1em;
  }
}
.linklist__item {
  display: block;
  padding: 20px 32px 20px 0;
  border-bottom: 1px solid #E0E0E0;
  transition: color 0.2s;
}
.linklist__item:link, .linklist__item:visited {
  color: #000000;
}
.linklist__item:hover {
  color: #990AE3;
}
.linklist--purple .linklist__item:link, .linklist--purple .linklist__item:visited {
  color: #990AE3;
}
.linklist--purple .linklist__item:hover {
  color: #CC00FF;
}
.linklist__icon {
  color: #C5C5C5;
  font-size: 14px;
  position: absolute;
  right: 20px;
  margin-top: 3px;
}

.cms-list-sub-pages a {
  width: 100%;
}
.cms-list-sub-pages__item {
  list-style-type: none;
  margin-top: 1.1rem;
}
.cms-list-sub-pages__edit {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}
.cms-list-sub-pages__edit .icon {
  color: black;
  font-size: 1rem;
}

.livesearch {
  padding: 0 5%;
}
@media (min-width: 481px) {
  .livesearch {
    padding: 0 34px;
  }
}
@media (min-width: 601px) {
  .livesearch {
    padding: 0 36px;
  }
}
@media (min-width: 841px) {
  .livesearch {
    padding: 0;
    width: 100%;
  }
}
.livesearch__container--closed {
  display: none;
}
.livesearch__container--open {
  display: block;
}
@media (min-width: 841px) {
  .livesearch__form {
    padding-top: 23px;
    padding-bottom: 22px;
  }
}
.livesearch__input-group {
  position: relative;
}
.livesearch__input {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  background-color: transparent;
  padding: 15px 20px 10px 53px;
}
@media (min-width: 841px) {
  .livesearch__input {
    padding: 21px 20px 20px 43px;
  }
}
@media (min-width: 1081px) {
  .livesearch__input {
    padding: 21px 20px 20px 53px;
  }
}
.livesearch__input::-webkit-input-placeholder {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #707070;
}
@media (min-width: 841px) {
  .livesearch__input::-webkit-input-placeholder {
    font-size: 18px;
  }
}
.livesearch__input:-moz-placeholder {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #707070;
}
@media (min-width: 841px) {
  .livesearch__input:-moz-placeholder {
    font-size: 18px;
  }
}
.livesearch__input::-moz-placeholder {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #707070;
}
@media (min-width: 841px) {
  .livesearch__input::-moz-placeholder {
    font-size: 18px;
  }
}
.livesearch__input:-ms-input-placeholder {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #707070;
}
@media (min-width: 841px) {
  .livesearch__input:-ms-input-placeholder {
    font-size: 18px;
  }
}
@media (min-width: 841px) {
  .livesearch__input--selected {
    border-bottom: 2px solid #990AE3;
  }
}
.livesearch__input-icon {
  color: #990AE3;
  top: 50%;
  padding: 0;
  margin-top: 4px;
}
@media (min-width: 841px) {
  .livesearch__input-icon {
    margin: 0;
    top: 45%;
  }
}
.livesearch__close {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 30px;
  color: #C5C5C5;
  transition: color 0.2s;
}
@media (min-width: 841px) {
  .livesearch__close {
    display: block;
    right: 0;
  }
}
.livesearch__close:hover {
  cursor: pointer;
  color: #000000;
}
.livesearch__clearinput {
  display: none;
  position: absolute;
  right: 37px;
  top: 39px;
  font-size: 13px;
  color: #990AE3;
  transition: color 0.2s;
}
@media (min-width: 841px) {
  .livesearch__clearinput {
    display: block;
  }
}
.livesearch__clearinput:hover {
  cursor: pointer;
}

.logged-in-box__background {
  background-size: cover;
  padding: 62px 0;
  color: #ffffff;
}
.logged-in-box__background--color-purple {
  color: #990AE3;
}
.logged-in-box__background--color-black {
  color: #000000;
}
.logged-in-box__greeting {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  display: inline-block;
  align-self: flex-start;
  color: #29003e;
  font-size: 24px;
  background-color: #ffffff;
  padding: 1em;
  margin: 1em 0;
  border-radius: 8px;
  border-bottom-left-radius: 0;
}
.logged-in-box__description {
  margin: 1em 0;
}

.link-card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  transition: transform 0.2s;
  cursor: pointer;
}
@media (max-width: 1080px) {
  .link-card {
    margin-bottom: 16px;
  }
}
.link-card:hover {
  transform: scale(1.03);
}
.link-card__top-bar {
  padding: 0 40px;
  font-size: 40px;
  display: flex;
  align-items: center;
  height: 90px;
  background-color: #ffffff;
  color: #000000;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}
.link-card__icon-container {
  position: relative;
}
.link-card__number {
  position: absolute;
  top: -2px;
  right: -10px;
  background-color: #0099ff;
  color: #ffffff;
  border-radius: 50%;
  text-align: center;
  width: 16px;
  height: 16px;
  font-size: 10px;
  line-height: 16px;
  font-weight: bold;
}
@media (min-width: 841px) {
  .link-card__number {
    width: 22px;
    height: 22px;
    font-size: 12px;
    line-height: 22px;
    top: -8px;
    right: -16px;
  }
}
.link-card__title {
  margin-left: 20px;
  font-size: 20px;
}
.link-card__description {
  flex-grow: 1;
  padding: 16px;
  font-size: 14px;
  background-color: #F2F2F2;
  color: #707070;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.cms-login {
  width: 100%;
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
}
.cms-login--logged-out {
  background-color: #ffffff;
}
.cms-login--logged-out .cms-login__bottom-area {
  background-color: #F9F9F9;
}
.cms-login__logged-in-container {
  display: flex;
  background-color: transparent;
  max-width: 1920px;
  margin-top: -1px;
}
@media (min-width: 841px) {
  .cms-login__logged-in-container {
    padding: 0 36px;
    margin: -1px auto 0;
  }
}
@media (min-width: 1081px) {
  .cms-login__logged-in-container {
    padding: 0 96px;
  }
}
@media (min-width: 1441px) {
  .cms-login__logged-in-container {
    padding: 0 108px;
  }
}
.cms-login__left-overlay-container {
  flex-grow: 1;
  background-color: transparent;
}
.cms-login__services-list {
  position: relative;
  background-color: #ffffff;
  margin-left: auto;
  min-width: 260px;
  max-width: 260px;
  display: flex;
  flex-direction: column;
}
.cms-login__services-list .cms-login__screen-username {
  font-weight: bold;
  letter-spacing: normal;
  color: #2b2b2b;
  font-size: 16px;
  padding: 18px 24px 0;
  margin-bottom: 18px;
}
.cms-login__services-list .cms-login__loggedInUrls--itemLinks {
  display: flex;
  flex-direction: column;
  position: relative;
  pointer-events: all;
}
.cms-login__services-list .cms-login__loggedInUrls--itemText {
  color: #303030;
  position: relative;
  pointer-events: all;
  line-height: 1.5;
  padding: 15px 24px;
}
.cms-login__services-list .cms-login__loggedInUrls--itemText::before {
  content: "";
  width: 100%;
  border-top: solid 1px #E0E0E0;
  position: absolute;
  left: 0;
  top: 0;
}
.cms-login__services-list .cms-login__loggedInUrls--itemText:hover {
  color: #990AE3;
}
.cms-login .cms-login__services-list h3 {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  padding: 6px 0;
  text-transform: none;
  margin: 0;
  font-size: 12px;
  font-weight: normal;
  color: #990AE3;
  padding: 6px 24px 15px;
  display: flex;
}
.cms-login .cms-login__services-list h3 a {
  display: flex;
}
.cms-login .cms-login__services-list h3 .icon {
  margin-left: 4px;
  font-size: 11px;
}
.cms-login__logout-button {
  display: flex;
  padding: 15px 24px;
  color: #303030;
  position: relative;
  pointer-events: all;
  line-height: 1;
}
.cms-login__logout-button span {
  pointer-events: none;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.cms-login__logout-button svg {
  font-size: 24px;
  margin-right: 8px;
}
.cms-login__logout-button:hover {
  color: #303030;
  background-color: #F2F2F2;
}
.cms-login__logout-button::before {
  content: "";
  width: 100%;
  border-top: solid 1px #E0E0E0;
  position: absolute;
  left: 0;
  top: 0;
}
.cms-login__heading {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  line-height: 1;
  font-size: 24px;
  line-height: 26px;
  padding: 24px 24px 32px;
  font-weight: 700;
}
@media (min-width: 841px) {
  .cms-login__heading {
    max-width: 532px;
    padding: 41px 0 36px;
    margin: 0 auto;
    font-size: 32px;
    line-height: 36px;
  }
}
@media (max-width: 1080px) {
  .cms-login__tabs {
    justify-content: space-between;
  }
}
@media (max-width: 1080px) {
  .cms-login__tabs-container {
    padding: 0 24px;
  }
}
@media (min-width: 841px) {
  .cms-login__tabs-container {
    max-width: 532px;
    margin: 0 auto;
  }
}
.cms-login__tab-radio {
  display: none;
}
.cms-login__tab {
  display: inline-block;
  color: #707070;
  letter-spacing: 1px !important;
  font-size: 16px !important;
  font-weight: 700;
  margin-right: 16px;
  padding-bottom: 9px;
  line-height: normal;
  border-bottom: 3px solid transparent;
}
@media (max-width: 1080px) {
  .cms-login__tab {
    max-width: 45%;
    vertical-align: baseline;
  }
}
@media (min-width: 841px) {
  .cms-login__tab {
    border-bottom: 3px solid transparent;
  }
  .cms-login__tab--active {
    border-bottom: 3px solid #990AE3;
  }
  .cms-login__tab:hover {
    color: #222222;
  }
}
.cms-login__tab--b2b-portal {
  order: 0;
}
.cms-login__tab--credentials {
  order: 1;
}
.cms-login__tab--tupas {
  order: 2;
}
@media (max-width: 1080px) {
  .cms-login__form {
    padding: 0 24px 20px;
  }
}
@media (min-width: 841px) {
  .cms-login__form {
    max-width: 532px;
    margin: 0 auto;
  }
}
@media (min-width: 841px) {
  .cms-login__form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.cms-login .text-input {
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
  height: 48px;
  border: 1px solid #d0d0d0;
  border-radius: 4px;
  margin: 0;
}
.cms-login .text-input > input {
  border: none;
  padding: 12px 16px;
  font-size: 16px;
  height: 100%;
}
.cms-login .text-input:focus-within {
  border-bottom: 1.5px solid #990AE3;
}
.cms-login__tab-radio--credentials:checked ~ .cms-login__tabs-container .cms-login__tab--credentials, .cms-login__tab-radio--tupas:checked ~ .cms-login__tabs-container .cms-login__tab--tupas, .cms-login__tab-radio--b2b-portal:checked ~ .cms-login__tabs-container .cms-login__tab--b2b-portal {
  color: #000000;
  border-color: #990AE3;
  cursor: auto;
}
.cms-login__panel {
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
  position: relative;
  padding-top: 0;
  max-height: 0;
  overflow: hidden;
  order: 4;
}
@media (max-width: 840px) {
  .cms-login__panel {
    width: 100%;
    transition: padding 0.2s, max-height 0.3s;
  }
}
@media (max-width: 1080px) {
  .cms-login__panel__info {
    padding: 0 24px;
  }
}
@media (min-width: 841px) {
  .cms-login__panel__info {
    padding-top: 20px;
    line-height: 24px;
    max-width: 532px;
    margin: 0 auto;
  }
  .cms-login__panel__info--empty {
    padding-bottom: 4px;
  }
}
.cms-login__tab-radio--credentials:checked ~ .cms-login__panel--credentials, .cms-login__tab-radio--tupas:checked ~ .cms-login__panel--tupas, .cms-login__tab-radio--b2b-portal:checked ~ .cms-login__panel--b2b-portal {
  border-top: 1px solid #E0E0E0;
  max-height: 800px;
}
.cms-login .cms-login__error {
  overflow: hidden;
  max-height: 0;
  margin: 0;
  transition: max-height 0.2s ease-in, margin 0.2s ease-in;
  padding-left: 12px;
}
@media (min-width: 841px) {
  .cms-login .cms-login__error--credentials {
    padding-left: 4px;
  }
}
.cms-login .cms-login__error--visible {
  max-height: 150px;
  margin: 2px 0 20px;
}
@media (min-width: 841px) {
  .cms-login .cms-login__error .notification {
    max-width: 532px;
    margin: 0 auto;
  }
  .cms-login .cms-login__error .notification .grid {
    margin: 0 !important;
    padding: 0 !important;
  }
  .cms-login .cms-login__error .notification .grid > .grid__col-12 {
    margin: 0 !important;
    padding: 0 !important;
  }
}

.cms-login__error-details {
  font-weight: normal;
  display: block;
}
.cms-login__form-button {
  padding: 32px 0;
}
@media (max-width: 1080px) {
  .cms-login__form-button--padding {
    padding: 32px 24px;
  }
}
@media (min-width: 841px) {
  .cms-login__form-button {
    padding: 40px 0;
  }
}
.cms-login__form-button .button {
  margin: 0;
  display: inline;
}
.cms-login__link-title {
  font-size: 14px;
}
@media (max-width: 1080px) {
  .cms-login__link-container-spacer {
    margin-bottom: 0px;
  }
}
@media (min-width: 841px) {
  .cms-login__link-container-spacer {
    margin-bottom: 40px;
  }
}
.cms-login__link-container__link {
  display: flex;
  font-size: 14px;
  margin-bottom: 16px;
  font-weight: 500;
  color: #F9F9F9;
}
.cms-login__link-container__link span {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  line-height: 1;
  color: #990AE3;
}
.cms-login__link-container__link svg {
  font-size: 24px;
  margin-right: 8px;
  color: #222222 !important;
}
.cms-login__notification-tupas-container {
  margin: 0 24px 20px;
}
@media (min-width: 841px) {
  .cms-login__notification-tupas-container {
    max-width: 532px;
    margin: 0 auto;
  }
}
.cms-login__notification-container {
  display: flex;
  flex-direction: row;
  min-height: 40px;
  max-width: 532px;
  background: #F4FAFF;
  box-shadow: 0px 2px 8px rgba(58, 58, 58, 0.12);
  border-radius: 4px;
  margin-bottom: 32px;
  padding: 12px;
  border-left: 4px solid #007ACC;
}
@media (min-width: 841px) {
  .cms-login__notification-container {
    margin-bottom: 40px;
  }
}
.cms-login__notification-container .notification__text p {
  margin: 0;
}
.cms-login__notification-container .notification__text a {
  color: #990AE3;
}
.cms-login__notification-container .notification__icon {
  color: #007ACC;
  width: 14px;
  height: 14px;
}
.cms-login__notification-container .notification__delete {
  padding: 14px 0 8px 16px;
}
.cms-login .cms-login__form-label-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 23px 0 5px;
}
.cms-login .cms-login__form-label-container:first-of-type {
  padding: 11px 0 5px;
}
.cms-login .cms-login__form-label-container > label {
  position: relative;
  top: 0;
  font-size: 16px;
  line-height: 1.67;
  letter-spacing: normal;
  color: #222222;
  font-weight: 400;
}
.cms-login__bottom-link {
  max-width: 532px;
  flex-direction: row;
  flex-wrap: nowrap;
  margin: 0 auto;
  display: flex;
  color: #23231f;
  padding: 17px 0 20px;
}
.cms-login__bottom-link span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  line-height: 1;
}
.cms-login__bottom-link svg {
  font-size: 16px;
  margin-right: 8px;
}
.cms-login__tooltip {
  position: relative;
  display: inline-block;
  width: 20px;
}
.cms-login__tooltip-info-icon {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 18px;
  color: #642d96;
  cursor: pointer;
}
.cms-login__tooltip-info-icon > svg {
  pointer-events: auto;
}
@supports (-ms-ime-align: auto) {
  .cms-login__tooltip-info-icon > svg {
    pointer-events: none;
  }
}
.cms-login__tooltip-content {
  position: absolute;
  visibility: hidden;
  background-color: #642d96;
  font-weight: normal;
  padding: 4px 18px;
  color: #ffffff;
  border-radius: 8px;
  z-index: 1;
  cursor: default;
  z-index: 2001;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.3);
  -webkit-text-size-adjust: 100%;
}
.cms-login__tooltip-content p {
  font-size: 12px;
}
@media (max-width: 1080px) {
  .cms-login__tooltip-content {
    transform: translate(-100%, 50%);
    bottom: 60%;
    left: -50%;
  }
  .cms-login__tooltip-content:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 100%;
    margin-top: -5px;
    border: 5px solid transparent;
    border-left-color: #642d96;
  }
}
@media (min-width: 841px) {
  .cms-login__tooltip-content {
    transform: translate(-50%, 0);
    bottom: 150%;
    left: 50%;
  }
  .cms-login__tooltip-content:after {
    content: "";
    position: absolute;
    top: 105%;
    left: 50%;
    margin-left: -5px;
    border: 5px solid transparent;
    border-top-color: #642d96;
  }
}
@media (min-width: 1081px) {
  .cms-login__tooltip-content:after {
    top: 100%;
  }
}
.cms-login__tooltip--open .cms-login__tooltip-content {
  visibility: visible;
}
.cms-login__repeated-tooltip p {
  margin: 0;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 23px;
}

.cms-login-2022 {
  margin: auto;
  color: #990AE3;
  background-color: #F4E0FF;
}
.cms-login-2022__mfa-sms-number {
  line-height: 1;
  font-size: 18px;
}
.cms-login-2022__phone-number-options {
  display: flex;
  flex-direction: column;
  border: 1px solid #C5C5C5;
  border-radius: 4px;
  overflow: hidden;
}
.cms-login-2022__phone-number-option {
  background-color: #F9F9F9;
  border-bottom: 1px solid #C5C5C5;
  padding: 30px 20px;
}
.cms-login-2022__phone-number-option:last-child {
  border-bottom: none;
}
.cms-login-2022__phone-number-option label {
  margin: 0 !important;
  font-weight: bold !important;
}
.cms-login-2022__phone-number-option label::before {
  box-sizing: border-box;
}
.cms-login-2022__resend-sms p {
  line-height: 1;
  color: #707070;
}
.cms-login-2022__mfa-error {
  display: none;
  color: #E4175C;
  width: 100%;
  text-align: right;
  margin: 0;
}
.cms-login-2022__mfa-error--visible {
  display: block;
}
.cms-login-2022__mfa-sms-error {
  display: none;
  color: #E4175C;
  width: 100%;
  margin: 0;
}
.cms-login-2022__mfa-sms-error--visible {
  display: block;
}
.cms-login-2022__otp-input-fields {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
@media (min-width: 601px) {
  .cms-login-2022__otp-input-fields {
    gap: 8px;
  }
}
.cms-login-2022__otp-help-text {
  margin-bottom: 16px;
}
.cms-login-2022__otp-input {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  padding: 3px;
  text-align: center;
  height: 50px;
  width: 36px;
}
@media (min-width: 601px) {
  .cms-login-2022__otp-input {
    padding: 4px;
    height: 56px;
    width: 40px;
  }
}
.cms-login-2022__otp-input:focus {
  outline: solid 3px #40b3ff;
  outline-offset: 2px;
}
.cms-login-2022__otp-input:focus:not(:focus-visible) {
  outline: none;
}
.cms-login-2022__otp-resent-info {
  font-weight: bold;
  display: none;
  font-size: 14px;
}
.cms-login-2022__otp-resent-info--visible {
  display: block;
}
@media (min-width: 841px) {
  .cms-login-2022__otp-resent-info {
    font-size: 16px;
  }
}
.cms-login-2022__resend-otp {
  display: none;
  font-size: 14px;
}
.cms-login-2022__resend-otp--visible {
  display: inline;
}
@media (min-width: 841px) {
  .cms-login-2022__resend-otp {
    font-size: 16px;
  }
}
.cms-login-2022__mfa-otp-loader {
  display: none;
}
.cms-login-2022__mfa-otp-loader .animation--loader {
  font-size: 36px;
}
.cms-login-2022__mfa-otp-loader--visible {
  display: block;
}
.cms-login-2022__page-loader {
  width: 100%;
  min-height: 100vh;
  margin: 30vh auto;
  text-align: center;
}
.cms-login-2022__page-loader img {
  height: 20vh;
}
.cms-login-2022 .flex {
  display: flex;
  gap: 0 0.5rem;
}
.cms-login-2022__form-link {
  display: flex;
  margin-bottom: 12px;
}
.cms-login-2022__form-link > span {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 12px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: #990ae3;
  pointer-events: none;
}
.cms-login-2022__form-link > svg {
  font-size: 24px;
  margin-right: 8px;
  color: #222222;
}
.cms-login-2022__display-none {
  display: none;
}
.cms-login-2022__display-block {
  display: block;
}
.cms-login-2022__page {
  padding: 2rem 1rem;
}
.cms-login-2022__top-form-link {
  margin-top: 2rem;
}
.cms-login-2022__form-button {
  margin-top: 1.75rem;
}
.cms-login-2022__form-button .button {
  margin: 0;
  display: inline;
}
.cms-login-2022__error {
  overflow: hidden;
  max-height: 0;
  margin: 0;
  transition: max-height 0.2s ease-in, margin 0.2s ease-in;
  padding-left: 12px;
}
@media (min-width: 841px) {
  .cms-login-2022__error--credentials, .cms-login-2022__error--b2btupas {
    padding-left: 4px;
  }
}
.cms-login-2022__error--visible {
  max-height: unset;
  margin: 2rem 0 1rem;
}
@media (min-width: 841px) {
  .cms-login-2022__error .notification {
    max-width: 100%;
    margin: 0 auto;
  }
  .cms-login-2022__error .notification .grid {
    margin: 0 !important;
    padding: 0 !important;
  }
  .cms-login-2022__error .notification .grid > .grid__col-12 {
    margin: 0 !important;
    padding: 0 !important;
  }
}
.cms-login-2022__error .notification p {
  display: inline-block;
}
.cms-login-2022__error .notification p a {
  margin: 0;
  color: #990AE3;
}
.cms-login-2022 .notification__text a {
  color: #990AE3;
}
.cms-login-2022__page-title, .cms-login-2022__box-title, .cms-login-2022__form-title {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.cms-login-2022__back-button {
  height: 3.5rem;
}
.cms-login-2022__page-content {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1081px) {
  .cms-login-2022__page-content {
    flex-direction: row;
    justify-content: center;
  }
}
@media (min-width: 841px) {
  .cms-login-2022__back-button-box {
    padding: 0 24px;
  }
}
@media (min-width: 1081px) {
  .cms-login-2022__back-button-box {
    max-width: 1870px;
    margin: 0 auto;
    padding: 0 84px;
  }
}
.cms-login-2022__back-button {
  margin: 0;
}
@media (min-width: 841px) {
  .cms-login-2022__page-box-title, .cms-login-2022__checkbox-box {
    justify-content: center;
  }
}
.cms-login-2022__checkbox-label-text {
  margin-left: 0.5rem;
}
.cms-login-2022__options {
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 841px) {
  .cms-login-2022__options {
    flex-direction: row;
    align-items: normal;
  }
}
.cms-login-2022__box:first-child {
  border-radius: 4px 4px 0 0;
}
@media (min-width: 841px) {
  .cms-login-2022__box:first-child {
    border-radius: 4px;
  }
}
.cms-login-2022__box:last-child {
  border-radius: 0 0 4px 4px;
}
@media (min-width: 841px) {
  .cms-login-2022__box:last-child {
    border-radius: 4px;
  }
}
.cms-login-2022__box {
  border: 1px solid #b0b0b5;
  background-color: #ffffff;
  margin-bottom: -1px;
  color: #222222;
  flex-direction: row;
}
@media (min-width: 841px) {
  .cms-login-2022__box {
    border-radius: 4px;
    flex-direction: column;
    flex-basis: 33.33%;
    max-width: 345px;
  }
}
@media (min-width: 1081px) {
  .cms-login-2022__box {
    padding: 1.5rem;
  }
}
.cms-login-2022__text-box {
  padding: 1.5rem;
  width: 100%;
}
@media (min-width: 841px) {
  .cms-login-2022__text-box {
    text-align: center;
  }
}
.cms-login-2022__box-title {
  margin: 0.5rem 0 1rem;
  font-size: 18px;
  line-height: 18px;
  font-weight: 700;
}
@media (min-width: 841px) {
  .cms-login-2022__box-title {
    margin: 1.5rem 0;
    font-size: 20px;
  }
}
.cms-login-2022__button-box {
  text-align: center;
  width: 3.6rem;
  background-color: #990ae3;
}
@media (min-width: 841px) {
  .cms-login-2022__button-box {
    background-color: inherit;
    width: 100%;
    padding-bottom: 2rem;
  }
}
.cms-login-2022__button-box > .button {
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin: 0;
  padding: 0;
}
@media (min-width: 841px) {
  .cms-login-2022__button-box > .button {
    width: auto;
    height: 40px;
    border-radius: 999px;
    margin: 6px;
    padding: 0 2rem;
  }
}
.cms-login-2022__button-box > .button .button-text {
  display: none;
}
@media (min-width: 841px) {
  .cms-login-2022__button-box > .button .button-text {
    display: inline;
    pointer-events: none;
  }
}
.cms-login-2022__button-box > .button::after {
  content: url("data:image/svg+xml;utf8,<svg width='14' height='22' viewBox='0 0 14 22' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.5834 11C13.5836 11.3071 13.4532 11.5998 13.2248 11.8051L2.39143 21.5551C1.94679 21.9553 1.26188 21.9193 0.861641 21.4747C0.461406 21.03 0.497404 20.3451 0.942046 19.9449L10.8809 11L0.942046 2.05508C0.654414 1.79617 0.526755 1.40348 0.607159 1.02493C0.687562 0.64638 0.963813 0.339479 1.33185 0.219835C1.69989 0.100191 2.10379 0.185981 2.39143 0.444888L13.2248 10.1949C13.4532 10.4002 13.5836 10.6929 13.5834 11Z' fill='white'/></svg>");
}
@media (min-width: 841px) {
  .cms-login-2022__button-box > .button::after {
    content: "";
  }
}
.cms-login-2022__short-text {
  margin: 0.5rem 0;
}
.cms-login-2022__link {
  color: #222222;
  text-decoration: underline;
}
.cms-login-2022__page-title {
  margin: 1.75rem 0;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
}
@media (min-width: 841px) {
  .cms-login-2022__page-title {
    margin: 3rem auto;
    font-size: 30px;
    font-weight: 700;
  }
}
.cms-login-2022__page-title::after {
  content: ":";
}
@media (min-width: 841px) {
  .cms-login-2022__page-title::after {
    content: "";
  }
}
.cms-login-2022__checkbox-box {
  margin: 20px auto;
  padding: 1rem;
  background-color: #F4E0FF;
  color: #222222;
  border-radius: 4px;
}
@media (min-width: 1081px) {
  .cms-login-2022__checkbox-box {
    max-width: 1050px;
  }
}

#cms-login-2022__menu-page {
  background-color: #f4f4f9;
  background-image: url("/.resources/pride-ui-module/webresources/assets/images/onelogin/bg-sm.png");
  background-repeat: no-repeat;
  background-position: right top;
}
#cms-login-2022__menu-page .cms-login-2022__back-button {
  background-color: #ffffff;
  color: #990ae3;
}
@media (min-width: 841px) {
  #cms-login-2022__menu-page {
    background-image: none;
    background-image: url("/.resources/pride-ui-module/webresources/assets/images/onelogin/bg-lg.png");
  }
}

#cms-login-2022__b2c-owner-page,
#cms-login-2022__b2c-user-page,
#cms-login-2022__b2b-admin-page {
  background-color: #ffffff;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__form-box,
#cms-login-2022__b2c-user-page .cms-login-2022__form-box,
#cms-login-2022__b2b-admin-page .cms-login-2022__form-box {
  color: #222222;
}
@media (min-width: 841px) {
  #cms-login-2022__b2c-owner-page .cms-login-2022__form-box,
  #cms-login-2022__b2c-user-page .cms-login-2022__form-box,
  #cms-login-2022__b2b-admin-page .cms-login-2022__form-box {
    width: 580px;
    margin: 1rem auto;
    padding: 3rem;
    background-color: #ffffff;
    border: 1px solid #b0b0b5;
    border-radius: 4px;
  }
}
#cms-login-2022__b2c-owner-page .cms-login-2022__form-title,
#cms-login-2022__b2c-user-page .cms-login-2022__form-title,
#cms-login-2022__b2b-admin-page .cms-login-2022__form-title {
  margin-top: 3rem;
  margin-bottom: 2.75rem;
  font-size: 1.5rem;
  font-weight: 700;
}
@media (min-width: 1081px) {
  #cms-login-2022__b2c-owner-page .cms-login-2022__form-title,
  #cms-login-2022__b2c-user-page .cms-login-2022__form-title,
  #cms-login-2022__b2b-admin-page .cms-login-2022__form-title {
    font-size: 2rem;
    margin-top: 3.5rem;
    margin-bottom: 2rem;
  }
}
#cms-login-2022__b2c-owner-page .cms-login-2022__form-text,
#cms-login-2022__b2c-user-page .cms-login-2022__form-text,
#cms-login-2022__b2b-admin-page .cms-login-2022__form-text {
  color: #222222;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__form-label,
#cms-login-2022__b2c-user-page .cms-login-2022__form-label,
#cms-login-2022__b2b-admin-page .cms-login-2022__form-label {
  display: inline-block;
  margin: 16px 0 8px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.67;
  letter-spacing: normal;
  color: #222222;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__input,
#cms-login-2022__b2c-user-page .cms-login-2022__input,
#cms-login-2022__b2b-admin-page .cms-login-2022__input {
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  padding: 1rem 10px;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__login-button,
#cms-login-2022__b2c-user-page .cms-login-2022__login-button,
#cms-login-2022__b2b-admin-page .cms-login-2022__login-button {
  margin: 1.75rem 0 0;
  width: 100%;
}
@media (min-width: 841px) {
  #cms-login-2022__b2c-owner-page .cms-login-2022__login-button,
  #cms-login-2022__b2c-user-page .cms-login-2022__login-button,
  #cms-login-2022__b2b-admin-page .cms-login-2022__login-button {
    width: auto;
  }
}
#cms-login-2022__b2c-owner-page .cms-login-2022__notification-box,
#cms-login-2022__b2c-user-page .cms-login-2022__notification-box,
#cms-login-2022__b2b-admin-page .cms-login-2022__notification-box {
  border-radius: 4px;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__notification-box .notification__icon,
#cms-login-2022__b2c-user-page .cms-login-2022__notification-box .notification__icon,
#cms-login-2022__b2b-admin-page .cms-login-2022__notification-box .notification__icon {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  padding: 10px 0;
  font-size: 20px;
  margin: 0.8rem 0 0 1rem;
}
@media (min-width: 841px) {
  #cms-login-2022__b2c-owner-page .cms-login-2022__notification-box .notification__icon,
  #cms-login-2022__b2c-user-page .cms-login-2022__notification-box .notification__icon,
  #cms-login-2022__b2b-admin-page .cms-login-2022__notification-box .notification__icon {
    margin-top: 1rem;
  }
}
#cms-login-2022__b2c-owner-page .cms-login-2022__notification-box .notification__text,
#cms-login-2022__b2c-user-page .cms-login-2022__notification-box .notification__text,
#cms-login-2022__b2b-admin-page .cms-login-2022__notification-box .notification__text {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  -ms-flex-item-align: center;
  align-self: center;
  -ms-flex-align: baseline;
  align-items: baseline;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  color: #222222;
  margin: 0.5rem;
  padding-right: 1rem;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__notification-box,
#cms-login-2022__b2c-user-page .cms-login-2022__notification-box,
#cms-login-2022__b2b-admin-page .cms-login-2022__notification-box {
  background-color: #ccebff;
  border-left: 4px solid #33adff;
  margin-top: 2.5rem;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__notification-box .notification__icon,
#cms-login-2022__b2c-user-page .cms-login-2022__notification-box .notification__icon,
#cms-login-2022__b2b-admin-page .cms-login-2022__notification-box .notification__icon {
  color: #33adff;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__tupas-notification-box,
#cms-login-2022__b2c-user-page .cms-login-2022__tupas-notification-box,
#cms-login-2022__b2b-admin-page .cms-login-2022__tupas-notification-box {
  border-radius: 4px;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__tupas-notification-box .tupas-notification__icon,
#cms-login-2022__b2c-user-page .cms-login-2022__tupas-notification-box .tupas-notification__icon,
#cms-login-2022__b2b-admin-page .cms-login-2022__tupas-notification-box .tupas-notification__icon {
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  padding: 10px 0;
  font-size: 20px;
  margin: 0 0 0 1rem;
}
@media (min-width: 841px) {
  #cms-login-2022__b2c-owner-page .cms-login-2022__tupas-notification-box .tupas-notification__icon,
  #cms-login-2022__b2c-user-page .cms-login-2022__tupas-notification-box .tupas-notification__icon,
  #cms-login-2022__b2b-admin-page .cms-login-2022__tupas-notification-box .tupas-notification__icon {
    margin-top: 0;
  }
}
#cms-login-2022__b2c-owner-page .cms-login-2022__tupas-notification-box .tupas-notification__text,
#cms-login-2022__b2c-user-page .cms-login-2022__tupas-notification-box .tupas-notification__text,
#cms-login-2022__b2b-admin-page .cms-login-2022__tupas-notification-box .tupas-notification__text {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  -ms-flex-item-align: center;
  align-self: center;
  -ms-flex-align: baseline;
  align-items: baseline;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  color: #222222;
  margin: 0.5rem;
  padding-right: 1rem;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__tupas-notification-box,
#cms-login-2022__b2c-user-page .cms-login-2022__tupas-notification-box,
#cms-login-2022__b2b-admin-page .cms-login-2022__tupas-notification-box {
  background-color: #ccebff;
  border-left: 4px solid #33adff;
  margin: 0 1.4rem;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__tupas-notification-box .tupas-notification__icon,
#cms-login-2022__b2c-user-page .cms-login-2022__tupas-notification-box .tupas-notification__icon,
#cms-login-2022__b2b-admin-page .cms-login-2022__tupas-notification-box .tupas-notification__icon {
  color: #33adff;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__error--credentials,
#cms-login-2022__b2c-owner-page .cms-login-2022__error--b2btupas,
#cms-login-2022__b2c-user-page .cms-login-2022__error--credentials,
#cms-login-2022__b2c-user-page .cms-login-2022__error--b2btupas,
#cms-login-2022__b2b-admin-page .cms-login-2022__error--credentials,
#cms-login-2022__b2b-admin-page .cms-login-2022__error--b2btupas {
  background-color: #fad1de;
  border-left: 4px solid #af1d54;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__error--credentials .notification__icon,
#cms-login-2022__b2c-owner-page .cms-login-2022__error--b2btupas .notification__icon,
#cms-login-2022__b2c-user-page .cms-login-2022__error--credentials .notification__icon,
#cms-login-2022__b2c-user-page .cms-login-2022__error--b2btupas .notification__icon,
#cms-login-2022__b2b-admin-page .cms-login-2022__error--credentials .notification__icon,
#cms-login-2022__b2b-admin-page .cms-login-2022__error--b2btupas .notification__icon {
  color: #be0040;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__back-button,
#cms-login-2022__b2c-user-page .cms-login-2022__back-button,
#cms-login-2022__b2b-admin-page .cms-login-2022__back-button {
  background-color: #f4f4f9;
  color: #990ae3;
}
@media (min-width: 841px) {
  #cms-login-2022__b2c-owner-page .cms-login-2022__back-button,
  #cms-login-2022__b2c-user-page .cms-login-2022__back-button,
  #cms-login-2022__b2b-admin-page .cms-login-2022__back-button {
    background-color: #ffffff;
    color: #990ae3;
  }
}
@media (min-width: 841px) {
  #cms-login-2022__b2c-owner-page,
  #cms-login-2022__b2c-user-page,
  #cms-login-2022__b2b-admin-page {
    background-color: #f4f4f9;
  }
}

#cms-login-2022__b2c-user-page {
  background-repeat: no-repeat;
  background-position: right top;
}
@media (min-width: 1081px) {
  #cms-login-2022__b2c-user-page .cms-login-2022__form-box {
    position: relative;
    margin: 1rem auto;
    padding: 3rem 4rem 4rem;
    width: 650px;
  }
}
@media (min-width: 841px) {
  #cms-login-2022__b2c-user-page {
    background-image: url("/.resources/pride-ui-module/webresources/assets/images/onelogin/bg-lg.png");
  }
}

@media (min-width: 841px) {
  #cms-login-2022__b2c-owner-page .cms-login-2022__form-title,
  #cms-login-2022__b2b-admin-page .cms-login-2022__form-title {
    margin-top: -0.75rem;
    margin-bottom: 2.2rem;
    font-size: 2rem;
  }
}
@media (min-width: 1081px) {
  #cms-login-2022__b2c-owner-page .cms-login-2022__form-title,
  #cms-login-2022__b2b-admin-page .cms-login-2022__form-title {
    margin-top: 3rem;
  }
}
#cms-login-2022__b2c-owner-page .cms-login-2022__login-button,
#cms-login-2022__b2b-admin-page .cms-login-2022__login-button {
  width: 100%;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__back-button,
#cms-login-2022__b2b-admin-page .cms-login-2022__back-button {
  background-color: #f4f4f9;
  color: #990ae3;
}
@media (min-width: 841px) {
  #cms-login-2022__b2c-owner-page,
  #cms-login-2022__b2b-admin-page {
    background-color: #ffffff;
  }
}
@media (min-width: 841px) {
  #cms-login-2022__b2c-owner-page .cms-login-2022__form-box,
  #cms-login-2022__b2b-admin-page .cms-login-2022__form-box {
    border: none;
  }
}
@media (min-width: 1081px) {
  #cms-login-2022__b2c-owner-page .cms-login-2022__form-box,
  #cms-login-2022__b2b-admin-page .cms-login-2022__form-box {
    margin: 0 0 1rem 1rem;
    padding: 0rem 5rem 3rem 0rem;
    width: 500px;
  }
}
#cms-login-2022__b2c-owner-page .cms-login-2022__cards,
#cms-login-2022__b2b-admin-page .cms-login-2022__cards {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 550px;
  margin: 1rem auto 0;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__cards-wrapper,
#cms-login-2022__b2b-admin-page .cms-login-2022__cards-wrapper {
  margin: 2.5rem -1rem -3.5rem;
  padding: 0 0 3.5rem;
  background-color: #f4f4f9;
}
@media (min-width: 1081px) {
  #cms-login-2022__b2c-owner-page .cms-login-2022__cards-wrapper,
  #cms-login-2022__b2b-admin-page .cms-login-2022__cards-wrapper {
    margin: 5rem -1rem 2.5rem;
    height: min-content;
    border-radius: 4px;
  }
}
#cms-login-2022__b2c-owner-page .cms-login-2022__cards-box,
#cms-login-2022__b2b-admin-page .cms-login-2022__cards-box {
  max-width: 580px;
  margin: auto;
  color: #222222;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__cards-text,
#cms-login-2022__b2b-admin-page .cms-login-2022__cards-text {
  margin-top: 1rem;
  padding: 1rem 1rem 0 1rem;
}
@media (min-width: 841px) {
  #cms-login-2022__b2c-owner-page .cms-login-2022__cards-text,
  #cms-login-2022__b2b-admin-page .cms-login-2022__cards-text {
    padding: 1rem 1rem 0 2.2rem;
  }
}
@media (min-width: 1081px) {
  #cms-login-2022__b2c-owner-page .cms-login-2022__cards-text,
  #cms-login-2022__b2b-admin-page .cms-login-2022__cards-text {
    margin-top: 0.5rem;
  }
}
#cms-login-2022__b2c-owner-page .cms-login-2022__cards-link,
#cms-login-2022__b2b-admin-page .cms-login-2022__cards-link {
  color: #222222;
  text-decoration: underline;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__card,
#cms-login-2022__b2b-admin-page .cms-login-2022__card {
  display: flex;
  flex-direction: column;
  width: 170px;
  height: 110px;
  border: 1px solid #8c8c90;
  justify-content: flex-end;
  align-items: center;
  margin: -1px 0 0 -1px;
  background-color: #fff;
  cursor: pointer;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__card img,
#cms-login-2022__b2b-admin-page .cms-login-2022__card img {
  max-width: 135px;
  max-height: 40px;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__card-text,
#cms-login-2022__b2b-admin-page .cms-login-2022__card-text {
  height: 40px;
  margin-top: 0.5rem;
  color: #222222;
  font-size: 14px;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__card:last-child,
#cms-login-2022__b2b-admin-page .cms-login-2022__card:last-child {
  border: none;
  background-color: transparent;
  height: 0;
}

#cms-login-2022__b2c-owner-page {
  background-color: #f4f4f9;
  background-repeat: no-repeat;
  background-position: right top;
}
@media (min-width: 841px) {
  #cms-login-2022__b2c-owner-page {
    background-image: url("/.resources/pride-ui-module/webresources/assets/images/onelogin/bg-lg.png");
  }
}
#cms-login-2022__b2c-owner-page .cms-login-2022__back-button {
  background-color: #fff;
  color: #990ae3;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__cards-wrapper {
  border: 1px solid #b0b0b5;
  background-color: #fff;
  margin: 20px auto;
  border-radius: 4px;
}
#cms-login-2022__b2c-owner-page .cms-login-2022__cards-text {
  margin-top: 1rem;
  padding: 0.5rem 1rem 0 1rem;
}
@media (min-width: 841px) {
  #cms-login-2022__b2c-owner-page .cms-login-2022__cards-text {
    padding: 0.5rem 1rem 0 1.4rem;
  }
}

/* Try to remove browser native button styles */
.cms-clear-button-styles {
  padding: 0;
  border: 0;
  margin: 0;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* stylelint-enable */
  background-color: transparent;
  color: inherit;
  line-height: 0;
  text-align: left;
}

.cms-modal {
  display: none;
  color: #222222;
}
.cms-modal--open {
  display: block;
}
.cms-modal__wrapper {
  position: fixed;
  z-index: 9999;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background: rgba(0, 0, 0, 0.23);
  cursor: pointer;
  inset: 0;
}
.cms-modal__window {
  background-color: #ffffff;
  border-radius: 16px;
  position: relative;
  display: flex;
  width: 100%;
  max-width: 848px;
  max-height: 100%;
  flex-direction: column;
  padding: 32px;
  padding-bottom: 24px;
  box-shadow: 0 8px 24px rgba(88, 88, 88, 0.18);
  cursor: default;
}
@media (min-width: 841px) {
  .cms-modal__window {
    max-width: 848px;
  }
}
.cms-modal__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}
.cms-modal__header h3 {
  margin: 0;
}
.cms-modal__close {
  cursor: pointer;
  width: 20px;
  height: 20px;
}
.cms-modal__close > .icon {
  width: 20px;
  height: 20px;
}
.cms-modal__close:hover {
  color: #222222;
}
.cms-modal__close:focus {
  outline: solid 3px #40b3ff;
  outline-offset: 2px;
}
.cms-modal__close:focus:not(:focus-visible) {
  outline: none;
}
.cms-modal__content {
  margin-bottom: 32px;
}
.cms-modal__content > p {
  max-width: none;
}
.cms-modal__content > ul {
  margin: 0;
}
.cms-modal__content > ul > li {
  padding-top: 16px;
}
.cms-modal__content:last-of-type {
  margin-bottom: 0;
}
.cms-modal__footer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
}
.cms-modal__cancel {
  padding: 0 2em;
}

.login-suggestion {
  background-color: #F2F2F2;
}
.image-hero + .login-suggestion {
  border-top: 1px solid #E0E0E0;
}
.login-suggestion__small-text {
  display: inline-block;
}
.login-suggestion__content {
  padding: 8px 16px;
}
@media (max-width: 840px) {
  .login-suggestion__content {
    text-align: center;
  }
}
.login-suggestion__content--white {
  background-color: #ffffff;
}
.login-suggestion__content--lightGrey {
  background-color: #F2F2F2;
}
.login-suggestion__content--transparent {
  background-color: transparent;
}
.login-suggestion__button a {
  margin-left: 10px;
  margin-right: 10px;
}
@media (min-width: 841px) {
  .login-suggestion__button a {
    margin-left: 22px;
  }
}

.cms-logo-row {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
}
@media (min-width: 841px) {
  .cms-logo-row {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}
.cms-logo-row--align-left {
  justify-content: flex-start;
}
.cms-logo-row--no-top-margin {
  margin-top: 0;
}
.cms-logo-row--no-bottom-margin {
  margin-bottom: 0;
}
.cms-logo-row__item-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cms-logo-row--full-width .cms-logo-row__item-container, .cms-logo-row--align-left .cms-logo-row__item-container {
  justify-content: flex-start;
}
.cms-logo-row .cms-logo-row__item-container--centered {
  justify-content: center;
}
.cms-logo-row__item {
  padding: 10px 20px;
  flex: 0 1 0px;
  min-width: 130px;
}
.cms-logo-row--full-width .cms-logo-row__item {
  flex: 1 1 0px;
  flex-basis: 50%;
  max-width: 50%;
}
.cms-logo-row--full-width .cms-logo-row__item-container--with-1-items .cms-logo-row__item {
  flex-basis: 100%;
  max-width: 100%;
}
@media (min-width: 601px) {
  .cms-logo-row--full-width .cms-logo-row__item {
    flex-basis: 33%;
    max-width: 33%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-2-items .cms-logo-row__item {
    flex-basis: 50%;
    max-width: 50%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-1-items .cms-logo-row__item {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 841px) {
  .cms-logo-row__item {
    padding: 15px 30px;
  }
  .cms-logo-row--full-width .cms-logo-row__item {
    flex-basis: 25%;
    max-width: 25%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-3-items .cms-logo-row__item {
    flex-basis: 33%;
    max-width: 33%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-2-items .cms-logo-row__item {
    flex-basis: 50%;
    max-width: 50%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-1-items .cms-logo-row__item {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1081px) {
  .cms-logo-row--full-width .cms-logo-row__item {
    flex-basis: 20%;
    max-width: 20%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-4-items .cms-logo-row__item {
    flex-basis: 25%;
    max-width: 25%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-3-items .cms-logo-row__item {
    flex-basis: 33%;
    max-width: 33%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-2-items .cms-logo-row__item {
    flex-basis: 50%;
    max-width: 50%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-1-items .cms-logo-row__item {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1441px) {
  .cms-logo-row--full-width .cms-logo-row__item {
    flex-basis: 14.28%;
    max-width: 14.28%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-6-items .cms-logo-row__item {
    flex-basis: 16.66%;
    max-width: 16.66%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-5-items .cms-logo-row__item {
    flex-basis: 20%;
    max-width: 20%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-4-items .cms-logo-row__item {
    flex-basis: 25%;
    max-width: 25%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-3-items .cms-logo-row__item {
    flex-basis: 33%;
    max-width: 33%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-2-items .cms-logo-row__item {
    flex-basis: 50%;
    max-width: 50%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-1-items .cms-logo-row__item {
    flex-basis: 100%;
    max-width: 100%;
  }
}
@media (min-width: 1921px) {
  .cms-logo-row--full-width .cms-logo-row__item {
    flex-basis: 12.5%;
    max-width: 12.5%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-7-items .cms-logo-row__item {
    flex-basis: 14.28%;
    max-width: 14.28%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-6-items .cms-logo-row__item {
    flex-basis: 16.66%;
    max-width: 16.66%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-5-items .cms-logo-row__item {
    flex-basis: 20%;
    max-width: 20%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-4-items .cms-logo-row__item {
    flex-basis: 25%;
    max-width: 25%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-3-items .cms-logo-row__item {
    flex-basis: 33%;
    max-width: 33%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-2-items .cms-logo-row__item {
    flex-basis: 50%;
    max-width: 50%;
  }
  .cms-logo-row--full-width .cms-logo-row__item-container--with-1-items .cms-logo-row__item {
    flex-basis: 100%;
    max-width: 100%;
  }
}
.cms-logo-row__image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
}
@media (min-width: 601px) {
  .cms-logo-row__image {
    height: 100px;
  }
}
@media (min-width: 1081px) {
  .cms-logo-row__image {
    height: 125px;
  }
}
.cms-logo-row__image img {
  width: 85px;
}
@media (min-width: 601px) {
  .cms-logo-row__image img {
    width: 100px;
  }
}
@media (min-width: 1081px) {
  .cms-logo-row__image img {
    width: 125px;
  }
}
.cms-logo-row__icon {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cms-logo-row__icon--black {
  color: #222222;
}
a.cms-logo-row__link:hover .cms-logo-row__icon--black {
  color: #A0A0A0;
}
.cms-logo-row__icon--grey {
  color: #C5C5C5;
}
a.cms-logo-row__link:hover .cms-logo-row__icon--grey {
  color: #A0A0A0;
}
.cms-logo-row__icon--purple {
  color: #990AE3;
}
a.cms-logo-row__link:hover .cms-logo-row__icon--purple {
  color: #CC00FF;
}
.cms-logo-row__icon--white {
  color: #ffffff;
}
a.cms-logo-row__link:hover .cms-logo-row__icon--white {
  color: #CCCCCC;
}
a.cms-logo-row__link--with-default-hover-colour:hover .cms-logo-row__icon {
  color: #CC00FF;
}
.cms-logo-row__icon .icon {
  min-width: 64px;
  min-height: 64px;
  margin-bottom: 4px;
}
@media (min-width: 601px) {
  .cms-logo-row__icon .icon {
    margin-bottom: 8px;
  }
}
.cms-logo-row__text {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}
.cms-logo-row__text--black {
  color: #222222;
}
a.cms-logo-row__link:hover .cms-logo-row__text--black {
  color: #A0A0A0;
}
.cms-logo-row__text--white {
  color: #ffffff;
}
a.cms-logo-row__link:hover .cms-logo-row__text--white {
  color: #CCCCCC;
}
a.cms-logo-row__link--with-default-hover-colour:hover .cms-logo-row__text {
  color: #CC00FF;
}
@media (min-width: 601px) {
  .cms-logo-row__text {
    font-size: 16px;
  }
}
.cms-logo-row__secondary-text {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  margin-top: 2px;
}
.cms-logo-row__secondary-text--black {
  color: #222222;
}
a.cms-logo-row__link:hover .cms-logo-row__secondary-text--black {
  color: #A0A0A0;
}
.cms-logo-row__secondary-text--white {
  color: #ffffff;
}
a.cms-logo-row__link:hover .cms-logo-row__secondary-text--white {
  color: #CCCCCC;
}
a.cms-logo-row__link--with-default-hover-colour:hover .cms-logo-row__secondary-text {
  color: #CC00FF;
}
@media (min-width: 601px) {
  .cms-logo-row__secondary-text {
    font-size: 16px;
  }
}
.cms-logo-row__link {
  color: #222222;
}

.cms-logout {
  color: #990AE3;
}
.cms-logout__page-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 500px;
}

.magnolia-card {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  background-color: #FFF;
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 140px;
  width: 100%;
  overflow: hidden;
  padding: 8px 16px;
  position: relative;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s ease 0s, height 0.2s ease 0s;
}
@media (min-width: 601px) {
  .magnolia-card--hover:hover {
    transform: scale(1.03);
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.12);
    z-index: 3;
  }
}
.magnolia-card--gradient-purple {
  background: #990AE3;
  background: linear-gradient(180deg, #990AE3 0%, #7625A7 100%);
}
.magnolia-card--text-white {
  color: #ffffff;
}
@media (min-width: 481px) {
  .magnolia-card {
    min-height: 224px;
    padding: 16px 24px;
  }
}
@media (min-width: 601px) {
  .magnolia-card {
    min-width: 240px;
    min-height: 500px;
    padding: 40px 32px 16px 32px;
    flex-direction: column;
    border-radius: 16px;
  }
  .magnolia-card--horizontal {
    flex-direction: row;
    min-height: 256px;
  }
}
@media (min-width: 841px) {
  .magnolia-card {
    min-height: 530px;
    padding: 40px 32px 16px 32px;
  }
  .magnolia-card--horizontal {
    flex-direction: row;
    min-height: 372px;
  }
}
@media (min-width: 1081px) {
  .magnolia-card {
    min-height: 530px;
    height: 530px;
    padding: 40px 32px 16px 32px;
  }
}
@media (min-width: 1441px) {
  .magnolia-card {
    min-height: 530px;
  }
}
.magnolia-card--card-large {
  min-height: 432px;
  padding: 24px 16px 16px 16px;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 481px) {
  .magnolia-card--card-large {
    min-height: 530px;
    padding: 40px 24px 16px 24px;
  }
}
@media (min-width: 601px) {
  .magnolia-card--card-large {
    min-height: 584px;
    padding: 56px 40px 16px 40px;
  }
}
@media (min-width: 841px) {
  .magnolia-card--card-large {
    min-height: 530px;
    border-radius: 16px;
    padding: 40px 32px 16px 32px;
  }
}
@media (min-width: 1081px) {
  .magnolia-card--card-large {
    min-height: 584px;
    padding: 40px 32px 16px 32px;
  }
}
@media (min-width: 1441px) {
  .magnolia-card--card-large {
    min-height: 584px;
    padding: 56px 48px 32px 48px;
  }
}
.magnolia-card__link-overlay {
  left: 0;
  top: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
}
.magnolia-card__ribbon {
  position: absolute;
  top: 8px;
  right: 16px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
  padding: 2px 4px;
  border-radius: 4px;
  color: #ffffff;
  z-index: 2;
}
@media (min-width: 481px) {
  .magnolia-card__ribbon {
    top: 16px;
  }
}
@media (min-width: 601px) {
  .magnolia-card__ribbon {
    font-size: 12px;
    line-height: 18px;
    padding: 2px 8px;
  }
}
.magnolia-card__ribbon--red {
  background-color: #E4175C;
}
.magnolia-card__ribbon--orange {
  background-color: #FF9B00;
}
.magnolia-card__ribbon--blue {
  background-color: #0099ff;
}
.magnolia-card__ribbon--transparent {
  background-color: rgba(112, 112, 112, 0.5);
}
.magnolia-card__ribbon--purple {
  background-color: #990AE3;
}
.magnolia-card__description-content {
  z-index: 2;
  flex: 0 0 50%;
  margin-bottom: -30px;
}
@media (min-width: 601px) {
  .magnolia-card__description-content {
    flex: 0 0 auto;
  }
}
.magnolia-card--card-large .magnolia-card__description-content {
  flex: 0 1 auto;
}
.magnolia-card__description-content--horizontal {
  flex: 0 0 65%;
}
@media (min-width: 481px) {
  .magnolia-card__description-content--horizontal {
    flex: 0 0 50%;
  }
}
.magnolia-card__description {
  color: #C5C5C5;
  display: flex;
  font-size: 11px;
  font-weight: bold;
}
@media (min-width: 481px) {
  .magnolia-card__description {
    font-size: 14px;
  }
}
@media (min-width: 1441px) {
  .magnolia-card__description {
    font-size: 16px;
  }
}
.magnolia-card--card-large .magnolia-card__description {
  font-size: 14px;
  line-height: 24px;
}
@media (min-width: 601px) {
  .magnolia-card--card-large .magnolia-card__description {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (min-width: 841px) {
  .magnolia-card--card-large .magnolia-card__description {
    font-size: 14px;
    line-height: 26px;
  }
}
@media (min-width: 1441px) {
  .magnolia-card--card-large .magnolia-card__description {
    font-size: 18px;
    line-height: 28px;
  }
}
.magnolia-card__title {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  margin: 4px 0 0;
}
@media (min-width: 481px) {
  .magnolia-card__title {
    font-size: 16px;
    line-height: 24px;
  }
}
@media (min-width: 601px) {
  .magnolia-card__title {
    font-size: 20px;
    line-height: 28px;
  }
}
@media (min-width: 1441px) {
  .magnolia-card__title {
    font-size: 24px;
    line-height: 32px;
  }
}
.magnolia-card--card-large .magnolia-card__title {
  font-size: 16px;
  line-height: 24px;
}
@media (min-width: 481px) {
  .magnolia-card--card-large .magnolia-card__title {
    font-size: 20px;
    line-height: 28px;
  }
}
@media (min-width: 601px) {
  .magnolia-card--card-large .magnolia-card__title {
    font-size: 40px;
    line-height: 48px;
  }
}
@media (min-width: 841px) {
  .magnolia-card--card-large .magnolia-card__title {
    font-size: 20px;
    line-height: 28px;
  }
}
@media (min-width: 1081px) {
  .magnolia-card--card-large .magnolia-card__title {
    font-size: 40px;
    line-height: 48px;
  }
}
.magnolia-card__product-number {
  font-size: 14px;
  line-height: 20px;
  color: #C5C5C5;
  margin-top: 4px;
}
.magnolia-card__image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  max-width: 100%;
}
.magnolia-card__image-container--horizontal {
  flex: 0 0 35%;
}
@media (min-width: 481px) {
  .magnolia-card__image-container--horizontal {
    flex: 0 0 50%;
  }
}
.magnolia-card__image-container--corner {
  position: absolute;
  right: 16px;
  width: 40%;
  margin-bottom: 0;
  justify-content: unset;
}
@media (min-width: 481px) {
  .magnolia-card__image-container--corner {
    right: 40px;
  }
}
@media (min-width: 601px) {
  .magnolia-card__image-container--corner {
    right: auto;
    top: 45%;
    left: 35%;
    width: 75%;
    margin-bottom: 25px;
  }
  .magnolia-card__image-container--corner.magnolia-card__image-container--horizontal {
    width: 30%;
    bottom: -70px;
    left: auto;
    right: 50px;
    margin-bottom: 0;
  }
}
@media (min-width: 841px) {
  .magnolia-card__image-container--corner {
    top: 30%;
  }
}
.magnolia-card--card-large .magnolia-card__image-container--corner {
  bottom: auto;
  top: 25%;
  width: 65%;
  right: 0;
  margin-bottom: 0;
}
@media (min-width: 601px) {
  .magnolia-card--card-large .magnolia-card__image-container--corner {
    bottom: auto;
    top: 35%;
  }
}
.magnolia-card__image {
  width: 96px;
  max-width: 100%;
  max-height: 100%;
  background-size: cover;
  background-position: center;
  align-self: center;
}
@media (min-width: 481px) {
  .magnolia-card__image {
    width: 160px;
  }
}
@media (min-width: 601px) {
  .magnolia-card__image {
    width: 190px;
  }
}
@media (min-width: 841px) {
  .magnolia-card__image {
    width: 290px;
  }
}
.magnolia-card--card-large .magnolia-card__image {
  margin-bottom: unset;
  flex: 0 1 auto;
  width: 238px;
}
@media (min-width: 481px) {
  .magnolia-card--card-large .magnolia-card__image {
    width: 290px;
  }
}
@media (min-width: 601px) {
  .magnolia-card--card-large .magnolia-card__image {
    width: 290px;
  }
}
@media (min-width: 841px) {
  .magnolia-card--card-large .magnolia-card__image {
    width: 290px;
  }
}
@media (min-width: 1081px) {
  .magnolia-card--card-large .magnolia-card__image {
    width: 290px;
  }
}
@media (min-width: 1441px) {
  .magnolia-card--card-large .magnolia-card__image {
    width: 290px;
  }
}
.magnolia-card__image--corner {
  margin: 0;
  width: auto;
  max-width: 180px;
  max-height: 180px;
}
@media (min-width: 481px) {
  .magnolia-card__image--corner {
    max-width: 240px;
    max-height: 240px;
  }
}
@media (min-width: 601px) {
  .magnolia-card__image--corner {
    max-width: 300px;
    max-height: 300px;
  }
}
@media (min-width: 841px) {
  .magnolia-card__image--corner {
    max-width: 380px;
    max-height: 380px;
  }
}
@media (min-width: 1081px) {
  .magnolia-card__image--corner {
    max-width: 420px;
    max-height: 420px;
  }
}
.magnolia-card__image--corner.magnolia-card__image--horizontal {
  left: auto;
  right: 0;
  max-height: 100%;
  width: auto;
}
.magnolia-card--card-large .magnolia-card__image--corner {
  width: auto;
  max-width: 420px;
  max-height: 420px;
}
@media (min-width: 481px) {
  .magnolia-card--card-large .magnolia-card__image--corner {
    max-width: 638px;
    max-height: 638px;
  }
}
@media (min-width: 841px) {
  .magnolia-card--card-large .magnolia-card__image--corner {
    max-width: 420px;
    max-height: 420px;
  }
}
@media (min-width: 1081px) {
  .magnolia-card--card-large .magnolia-card__image--corner {
    max-width: 480px;
    max-height: 480px;
  }
}
@media (min-width: 1441px) {
  .magnolia-card--card-large .magnolia-card__image--corner {
    max-width: 638px;
    max-height: 638px;
  }
}
.magnolia-card__footer-content {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  position: absolute;
  z-index: 2;
  left: 16px;
  right: 0;
  bottom: 8px;
}
@media (min-width: 481px) {
  .magnolia-card__footer-content {
    bottom: 16px;
    left: 24px;
  }
}
@media (min-width: 601px) {
  .magnolia-card__footer-content {
    left: 40px;
  }
}
@media (min-width: 841px) {
  .magnolia-card__footer-content {
    left: 32px;
  }
}
.magnolia-card--card-large .magnolia-card__footer-content {
  bottom: 16px;
}
@media (min-width: 1441px) {
  .magnolia-card--card-large .magnolia-card__footer-content {
    left: 48px;
    bottom: 32px;
  }
}
.magnolia-card__price {
  font-size: 20px;
  line-height: 20px;
}
@media (min-width: 601px) {
  .magnolia-card__price {
    font-size: 32px;
    line-height: 23px;
  }
}
.magnolia-card__price-unit {
  font-size: 12px;
  line-height: 18px;
  margin-left: -4px;
}
@media (min-width: 601px) {
  .magnolia-card__price-unit {
    font-size: 24px;
    line-height: 24px;
  }
}
.magnolia-card__price-crossed {
  font-size: 12px;
  line-height: 18px;
  text-decoration: line-through;
  color: #C5C5C5;
  margin-left: 6px;
}
@media (min-width: 601px) {
  .magnolia-card__price-crossed {
    font-size: 24px;
    line-height: 24px;
  }
}
.magnolia-card__first-footer-description {
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 26px;
}
.magnolia-card__second-footer-description {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #C5C5C5;
  font-size: 12px;
  line-height: 18px;
}

.marketing-banner-50-50 {
  overflow: hidden;
  position: relative;
}
.marketing-banner-50-50--border {
  border-color: #990AE3;
  border-style: solid;
  border-width: 1px 0 1px 0;
}
.marketing-banner-50-50__column {
  float: left;
  padding-left: 0;
  padding-right: 0;
}
.marketing-banner-50-50__column--border {
  border-color: #990AE3;
  border-style: solid;
  border-width: 0 0 1px 0;
}
@media (min-width: 841px) {
  .marketing-banner-50-50__column--border {
    border-width: 0 1px 0 0;
  }
}
.marketing-banner-50-50__column--corePurple {
  background-color: #990AE3;
}
.marketing-banner-50-50__column--white {
  background-color: #ffffff;
}
.marketing-banner-50-50__column--lightGrey {
  background-color: #F4F4F9;
}
.marketing-banner-50-50__column--black {
  background-color: #121214;
}
.marketing-banner-50-50__column--deepPurple {
  background-color: #29003e;
}
.marketing-banner-50-50__column--beige {
  background-color: #ECDED4;
}
.marketing-banner-50-50__column--lightPurple {
  background-color: #F4E0FF;
}
.marketing-banner-50-50__column--lightBeige {
  background-color: #F8EEE8;
}
.marketing-banner-50-50__content {
  height: 312px;
  position: relative;
}
@media (min-width: 841px) {
  .marketing-banner-50-50__content {
    height: 400px;
  }
}
.marketing-banner-50-50__image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.marketing-banner-50-50__background {
  position: relative;
  height: 100%;
  background-position: 100%;
  background-size: auto 100%;
  background-repeat: no-repeat;
}
.marketing-banner-50-50__background--stretch {
  background-size: cover;
}
@media (min-width: 841px) {
  .marketing-banner-50-50__background--mobile {
    display: none;
    background: none !important;
  }
}
@media (max-width: 840px) {
  .marketing-banner-50-50__background--desktop {
    display: none;
    background: none !important;
  }
}
.marketing-banner-50-50__overlay {
  background: rgba(0, 0, 0, 0.15);
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.marketing-banner-50-50__text-area-container {
  height: 100%;
  word-spacing: normal;
  letter-spacing: normal;
}
.marketing-banner-50-50__text-area {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  font-size: 14px;
  line-height: 18px;
}
.marketing-banner-50-50__text-area h3 {
  margin-top: 0;
}
.marketing-banner-50-50__text-area--white {
  color: #ffffff;
}
.marketing-banner-50-50__text-area--black {
  color: #121214;
}
.marketing-banner-50-50__text-area--grey {
  color: #F4F4F9;
}
.marketing-banner-50-50__text-area--deepPurple {
  color: #29003e;
}
.marketing-banner-50-50__text-area--deepBeige {
  color: #5C524E;
}
.marketing-banner-50-50__text-area--beige {
  color: #ECDED4;
}
.marketing-banner-50-50__text-area--lightPurple {
  color: #F4E0FF;
}
.marketing-banner-50-50__text-area--lightBeige {
  color: #F8EEE8;
}
.marketing-banner-50-50__heading {
  color: inherit;
  margin-bottom: 0;
}
.marketing-banner-50-50__text {
  margin: 0.6em 0;
}
.marketing-banner-50-50__button a {
  margin-left: 0;
}
.marketing-banner-50-50__button .button {
  width: auto;
}

.marketing-restriction__description p:last-child {
  margin-bottom: 0;
}
.marketing-restriction__form-container {
  position: relative;
  min-height: 250px;
}
.marketing-restriction__form {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}
.marketing-restriction__form--visible {
  opacity: 1;
  visibility: visible;
}
.marketing-restriction__form .notification {
  margin-top: 0;
}
.marketing-restriction__checkbox-group {
  margin: 1.5em 0 0.75em;
}
.marketing-restriction__submit {
  margin: 0;
}
.marketing-restriction__back {
  display: none;
  margin: 32px 0;
}

.marketing-unit {
  background-size: cover;
  padding: 62px 0;
  color: #ffffff;
}
.marketing-unit--text-under-image {
  padding: 0;
}
.marketing-unit--mobile {
  display: block;
}
@media (min-width: 601px) {
  .marketing-unit--mobile {
    display: none;
  }
}
.marketing-unit--desktop {
  display: none;
}
@media (min-width: 601px) {
  .marketing-unit--desktop {
    display: block;
  }
}
.marketing-unit__mobile-text-content {
  padding: 32px 0;
}
.marketing-unit__mobile-background {
  background-size: cover;
}
.marketing-unit__heading {
  color: inherit;
  max-width: 852px;
  margin: 0;
  flex-shrink: 0;
}
.marketing-unit__description {
  margin: 1em 0;
}
.marketing-unit--color-purple {
  color: #990AE3;
}
.marketing-unit--color-black {
  color: #222222;
}
.marketing-unit--color-deepPurple {
  color: #29003e;
}
.marketing-unit--color-lightPurple {
  color: #F4E0FF;
}
.marketing-unit--color-lightBeige {
  color: #F8EEE8;
}
.marketing-unit--color-deepBeige {
  color: #5C524E;
}
.marketing-unit--clipping-top-left {
  background-position: 100% 100%;
}
.marketing-unit--clipping-top-center {
  background-position: 50% 100%;
}
.marketing-unit--clipping-top-right {
  background-position: 0 100%;
}
.marketing-unit--clipping-middle-right {
  background-position: 0 50%;
}
.marketing-unit--clipping-bottom-right {
  background-position: 0 0;
}
.marketing-unit--clipping-bottom-center {
  background-position: 50% 0;
}
.marketing-unit--clipping-bottom-left {
  background-position: 100% 0;
}
.marketing-unit--clipping-middle-left {
  background-position: 100% 50%;
}
.marketing-unit--clipping-center {
  background-position: 50% 50%;
}
.marketing-unit--clipping-stretch {
  background-size: 100% 100%;
}
.marketing-unit__basic-components *:first-child {
  margin-left: 0;
  margin-right: 0;
}
.marketing-unit__basic-components .button {
  margin-left: 0;
  margin-right: 0;
  width: auto;
}
.marketing-unit--background-white {
  background-color: #ffffff;
}
.marketing-unit--background-transparent {
  background-color: transparent;
}
.marketing-unit--background-lightGrey {
  background-color: #F2F2F2;
}
.marketing-unit--background-corePurple {
  background-color: #990AE3;
}
.marketing-unit--background-deepPurple {
  background-color: #29003e;
}
.marketing-unit--background-lightPurple {
  background-color: #F4E0FF;
}
.marketing-unit--background-lightBeige {
  background-color: #F8EEE8;
}
.marketing-unit--background-deepBeige {
  background-color: #5C524E;
}
.marketing-unit--background-black {
  background-color: #000000;
}

.media-container-hero {
  position: relative;
  background-color: #F2F2F2;
  z-index: 1;
}
@media (min-width: 601px) {
  .media-container-hero {
    background-color: transparent;
  }
}
.media-container-hero__header {
  width: 100%;
}
@media (min-width: 601px) {
  .media-container-hero--size-from-content {
    height: 344px;
  }
}
@media (min-width: 841px) {
  .media-container-hero--size-from-content {
    height: 360px;
  }
}
@media (min-width: 1081px) {
  .media-container-hero--size-from-content {
    height: 392px;
  }
}
@media (min-width: 1441px) {
  .media-container-hero--size-from-content {
    height: 480px;
  }
}
@media (min-width: 601px) {
  .media-container-hero--color-white {
    color: #ffffff;
  }
}
@media (min-width: 601px) {
  .media-container-hero--color-black {
    color: #222222;
  }
}
@media (min-width: 601px) {
  .media-container-hero--color-purple {
    color: #990AE3;
  }
}
@media (min-width: 601px) {
  .media-container-hero--color-deepPurple {
    color: #29003e;
  }
}
@media (min-width: 601px) {
  .media-container-hero--color-deepBeige {
    color: #5C524E;
  }
}
@media (min-width: 601px) {
  .media-container-hero--color-lightPurple {
    color: #F4E0FF;
  }
}
@media (min-width: 601px) {
  .media-container-hero--color-lightBeige {
    color: #F8EEE8;
  }
}
.media-container-hero__grid {
  flex-direction: column-reverse;
  width: 100%;
  height: 100%;
}
@media (min-width: 601px) {
  .media-container-hero__grid {
    flex-direction: row;
  }
}
.media-container-hero--size-from-content .media-container-hero__grid {
  height: 100%;
}
.media-container-hero__heading {
  margin: 25px 0 18px;
}
@media (max-width: 600px) {
  .media-container-hero__heading {
    margin-bottom: 10px;
  }
}
@media (min-width: 601px) {
  .media-container-hero__heading {
    margin-top: 0;
  }
}
@media (min-width: 1921px) {
  .media-container-hero__heading {
    margin-bottom: 33px;
  }
}
.media-container-hero__subheading {
  margin: 0 0 8px;
}
.media-container-hero__description {
  margin: 0px;
  max-width: 100%;
}
@media (min-width: 601px) {
  .media-container-hero__heading--color-white, .media-container-hero__subheading--color-white, .media-container-hero__description--color-white {
    color: #ffffff;
  }
}
@media (min-width: 601px) {
  .media-container-hero__heading--color-black, .media-container-hero__subheading--color-black, .media-container-hero__description--color-black {
    color: #222222;
  }
}
@media (min-width: 601px) {
  .media-container-hero__heading--color-purple, .media-container-hero__subheading--color-purple, .media-container-hero__description--color-purple {
    color: #990AE3;
  }
}
@media (min-width: 601px) {
  .media-container-hero__heading--color-deepPurple, .media-container-hero__subheading--color-deepPurple, .media-container-hero__description--color-deepPurple {
    color: #29003e;
  }
}
@media (min-width: 601px) {
  .media-container-hero__heading--color-deepBeige, .media-container-hero__subheading--color-deepBeige, .media-container-hero__description--color-deepBeige {
    color: #5C524E;
  }
}
@media (min-width: 601px) {
  .media-container-hero__heading--color-lightPurple, .media-container-hero__subheading--color-lightPurple, .media-container-hero__description--color-lightPurple {
    color: #F4E0FF;
  }
}
@media (min-width: 601px) {
  .media-container-hero__heading--color-lightBeige, .media-container-hero__subheading--color-lightBeige, .media-container-hero__description--color-lightBeige {
    color: #F8EEE8;
  }
}
.media-container-hero__basic-components {
  margin: 12px 0 0;
}
@media (min-width: 841px) {
  .media-container-hero__basic-components {
    margin-top: 16px;
  }
}
@media (min-width: 1921px) {
  .media-container-hero__basic-components {
    margin-top: 26px;
  }
}
.media-container-hero__basic-components .button {
  margin-left: 0;
}
.media-container-hero__basic-components .price-component {
  margin-top: 10px;
  margin-bottom: 20px;
}
.media-container-hero__background {
  width: 100%;
  position: absolute;
  height: 224px;
  overflow: hidden;
}
@media (min-width: 601px) {
  .media-container-hero__background {
    height: auto;
    max-height: 100%;
    max-width: 100vw;
  }
}
.media-container-hero__background .media-container {
  min-width: 100%;
  position: absolute;
}
@media (min-width: 601px) {
  .media-container-hero__background .media-container {
    position: relative;
  }
}
.media-container-hero__background--align-top-right {
  margin-left: 100%;
  transform: translate(-100%, 0);
}
.media-container-hero__background--align-top-right .media-container {
  right: 0;
  top: 0;
}
@media (min-width: 601px) {
  .media-container-hero--size-from-content .media-container-hero__background--align-top-right {
    top: 0;
    right: 0;
    transform: none;
    margin: 0;
  }
  .media-container-hero__background--align-top-right .media-container {
    transform: none;
  }
}
.media-container-hero__background--align-top-center {
  margin-left: 50%;
  transform: translate(-50%, 0);
}
.media-container-hero__background--align-top-center .media-container {
  left: 50%;
  top: 0;
  transform: translate(-50%, 0);
}
@media (min-width: 601px) {
  .media-container-hero--size-from-content .media-container-hero__background--align-top-center {
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0;
  }
}
.media-container-hero__background--align-middle-left .media-container {
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
@media (min-width: 601px) {
  .media-container-hero--size-from-content .media-container-hero__background--align-middle-left {
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    margin: 0;
  }
  .media-container-hero__background--align-middle-left .media-container {
    transform: none;
  }
}
.media-container-hero__background--align-middle-right {
  margin-left: 100%;
  transform: translate(-100%, 0);
}
.media-container-hero__background--align-middle-right .media-container {
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
@media (min-width: 601px) {
  .media-container-hero--size-from-content .media-container-hero__background--align-middle-right {
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
    margin: 0;
  }
  .media-container-hero__background--align-middle-right .media-container {
    transform: none;
  }
}
.media-container-hero__background--align-middle-center {
  margin-left: 50%;
  transform: translate(-50%, 0);
}
.media-container-hero__background--align-middle-center .media-container {
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}
@media (min-width: 601px) {
  .media-container-hero--size-from-content .media-container-hero__background--align-middle-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
  .media-container-hero__background--align-middle-center .media-container {
    transform: none;
  }
}
.media-container-hero__background--align-bottom-left .media-container {
  top: 100%;
  transform: translate(0, -100%);
}
@media (min-width: 601px) {
  .media-container-hero--size-from-content .media-container-hero__background--align-bottom-left {
    bottom: 0;
    left: 0;
  }
  .media-container-hero__background--align-bottom-left .media-container {
    transform: none;
  }
}
.media-container-hero__background--align-bottom-right {
  margin-left: 100%;
  transform: translate(-100%, 0);
}
.media-container-hero__background--align-bottom-right .media-container {
  right: 0;
  bottom: 0;
}
@media (min-width: 601px) {
  .media-container-hero--size-from-content .media-container-hero__background--align-bottom-right {
    bottom: 0;
    right: 0;
    margin: 0;
    transform: none;
  }
}
.media-container-hero__background--align-bottom-center {
  margin-left: 50%;
  transform: translate(-50%, 0);
}
.media-container-hero__background--align-bottom-center .media-container {
  bottom: 0;
}
@media (min-width: 601px) {
  .media-container-hero--size-from-content .media-container-hero__background--align-bottom-center {
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    margin: 0;
  }
}
.media-container-hero__content {
  justify-content: center;
  align-self: center;
  align-items: center;
  text-align: center;
  margin-top: 0;
}
.media-container-hero__content--align-center {
  margin-top: 224px;
}
@media (min-width: 601px) {
  .media-container-hero__content--align-center {
    margin: 0;
  }
}
@media (min-width: 601px) {
  .media-container-hero__content--align-left {
    margin: 0;
    align-items: flex-start;
    text-align: left;
  }
}
.media-container-hero__floating-element {
  height: 224px;
}
@media (min-width: 601px) {
  .media-container-hero__floating-element {
    height: auto;
    max-height: 100%;
  }
}
.media-container-hero__container {
  width: 100%;
  height: 100%;
  top: 0;
  padding: 0 0 32px;
  text-align: center;
  overflow: hidden;
}
.media-container-hero__container .splash {
  margin: 0;
  position: absolute;
  right: 8%;
  top: 20px;
}
@media (min-width: 601px) {
  .media-container-hero__container .splash {
    right: 0;
    top: 48px;
  }
}
.media-container-hero__container .image-element__image {
  max-height: 200px;
  max-width: 100%;
  width: auto;
  height: auto;
}
@media (min-width: 601px) {
  .media-container-hero__container .image-element__image {
    max-height: none;
  }
}
@media (min-width: 601px) {
  .media-container-hero__container {
    padding: 0;
  }
}
.media-container-hero--breakout-background .media-container-hero__container {
  overflow: visible;
}
.media-container-hero--size-from-content .media-container-hero__container {
  position: relative;
}
@media (min-width: 601px) {
  .media-container-hero--size-from-background .media-container-hero__container {
    padding: 0;
    position: absolute;
  }
}
@media (min-width: 601px) {
  .media-container-hero--size-from-background .media-container-hero__background {
    position: relative;
  }
}
.media-container-hero--size-from-content .media-container-hero__background {
  position: absolute;
}

.media-container {
  display: flex;
}
.media-container--video {
  display: block;
  min-height: 1px;
}
.media-container:only-child {
  min-height: 100%;
}
.media-container--center {
  justify-content: center;
}
.media-container--right {
  justify-content: flex-end;
}
.media-container--vertical-center {
  align-items: center;
}
.media-container--vertical-stretch {
  align-items: stretch;
}
.media-container--vertical-bottom {
  align-items: flex-end;
}
.media-container--background-white {
  background-color: #ffffff;
}
.media-container--background-grey {
  background-color: #F2F2F2;
}
.media-container--background-deepPurple {
  background-color: #29003e;
}
.media-container--background-deepBeige {
  background-color: #5C524E;
}
.media-container--background-lightPurple {
  background-color: #F4E0FF;
}
.media-container--background-lightBeige {
  background-color: #F8EEE8;
}
.media-container__image {
  max-width: 100%;
  display: block;
}
.media-container__image--fit {
  width: 100%;
  max-width: none !important;
}
.media-container__picture--fit {
  width: 100%;
}
.media-container__icon--small {
  font-size: 24px;
}
.media-container__icon--medium {
  font-size: 48px;
}
.media-container__icon--large {
  font-size: 72px;
}
.media-container__icon--75percent {
  height: 75%;
}
.media-container__icon--75percent > .icon {
  width: auto;
  height: 100%;
}
.media-container__icon--50percent {
  height: 50%;
}
.media-container__icon--50percent > .icon {
  width: auto;
  height: 100%;
}
.media-container__icon--100percent {
  height: 100%;
}
.media-container__icon--100percent > .icon {
  width: auto;
  height: 100%;
}
.media-container__icon--black {
  color: #000000;
}
.media-container__icon--white {
  color: #ffffff;
}
.media-container__icon--blue {
  color: #0099ff;
}
.media-container__icon--green {
  color: #00CC66;
}
.media-container__icon--red {
  color: #E4175C;
}
.media-container__icon--orange {
  color: #FF9B00;
}
.media-container__icon--purple {
  color: #990AE3;
}
.media-container__video {
  position: relative;
}
.media-container__video--asset {
  width: 100%;
  position: relative;
}
.media-container__video--asset video {
  width: 100%;
  max-width: 100%;
  display: block;
}
.media-container__video--youtube {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
}
.media-container__video--youtube iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.media-container__video--image-in-mobile {
  padding-bottom: 0;
}
.media-container__video-image-substitute {
  width: 100%;
  display: block;
}
.media-container__video-thumbnail-play-button-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media-container__video-thumbnail-play-button {
  padding: 10px;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}
.media-container__video-thumbnail-play-button:hover {
  cursor: pointer;
}
.media-container__video-thumbnail-play-button .icon {
  line-height: 6vw;
  width: 100%;
  height: 100%;
  color: #ffffff;
}
.media-container__video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  text-align: center;
  opacity: 0;
}
.media-container__video-overlay--visible {
  transition: opacity 3s ease-in;
  opacity: 1;
}
.media-container__video-overlay--white {
  color: #ffffff;
}
.media-container__video-overlay--black {
  color: #000000;
}
.media-container__video-overlay--purple {
  color: #990AE3;
}
.media-container__video-overlay--small {
  font-size: 2vw;
  line-height: 2vw;
}
.media-container__video-overlay--medium {
  font-size: 4vw;
  line-height: 4vw;
}
.media-container__video-overlay--large {
  font-size: 6vw;
  line-height: 6vw;
}
.media-container__video-overlay--slow {
  transition: opacity 4s ease-in;
}
.media-container__video-overlay--average {
  transition: opacity 2s ease-in;
}
.media-container__video-overlay--fast {
  transition: opacity 0.5s ease-in;
}

.modal-content {
  display: none;
  background: white;
  z-index: 2001;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 2px 0;
}
.modal-content .grid .columnrow {
  margin: 0;
}
.modal-content__grid {
  padding: 0;
}
.modal-content__heading {
  border-bottom: 1px solid #F2F2F2;
  color: black;
  letter-spacing: -0.2px;
  line-height: 1;
  margin: 0;
  padding: 24px 36px;
}
.modal-content__close {
  border-bottom: 1px solid #F2F2F2;
  color: #A0A0A0 !important;
  padding: 24px 36px;
  font-size: 1.5em;
}
.modal-content__content {
  padding: 32px 36px;
}
.modal-content__components .grid.columnrow {
  margin: 0;
}
.modal-content-display {
  display: block;
  position: absolute;
  top: 10%;
}
@media (min-width: 841px) {
  .modal-content-display {
    left: 20%;
    right: 20%;
    top: 20%;
  }
}
.modal-content__overlay {
  background-color: rgba(0, 0, 0, 0.15);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s;
  z-index: -1;
  pointer-events: none;
}
.modal-content__overlay-show {
  opacity: 1;
  z-index: 2000;
  pointer-events: auto;
}

#navigation-wrapper {
  position: absolute;
  left: 0;
  right: 0;
}

#header-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1010;
}

.main-container {
  padding-top: calc(72px + 1px);
}
.main-container--has-sub-navigation {
  padding-top: calc(72px + 47px + 1px);
}
@media (min-width: 841px) {
  .main-container {
    padding-top: calc(110px + 1px);
  }
  .main-container--has-sub-navigation {
    padding-top: calc(110px + 47px + 1px);
  }
}

#addsearch-results.cms-navigation-2019-compatible#addsearch-results.addsearch-d, #addsearch-results.cms-navigation-2019-compatible#addsearch-results.addsearch-t, #addsearch-results.cms-navigation-2019-compatible#addsearch-results.addsearch-m2 {
  position: fixed !important;
  width: 100% !important;
  left: 0 !important;
  margin-left: 0 !important;
  border: none !important;
  margin-top: 14px !important;
}
@media (min-width: 841px) {
  #addsearch-results.cms-navigation-2019-compatible#addsearch-results.addsearch-d, #addsearch-results.cms-navigation-2019-compatible#addsearch-results.addsearch-t, #addsearch-results.cms-navigation-2019-compatible#addsearch-results.addsearch-m2 {
    width: 494px !important;
    right: 0px !important;
    left: auto !important;
  }
}
@media (min-width: 1081px) {
  #addsearch-results.cms-navigation-2019-compatible#addsearch-results.addsearch-d, #addsearch-results.cms-navigation-2019-compatible#addsearch-results.addsearch-t, #addsearch-results.cms-navigation-2019-compatible#addsearch-results.addsearch-m2 {
    right: 65px !important;
  }
}
@media (min-width: 1441px) {
  #addsearch-results.cms-navigation-2019-compatible#addsearch-results.addsearch-d, #addsearch-results.cms-navigation-2019-compatible#addsearch-results.addsearch-t, #addsearch-results.cms-navigation-2019-compatible#addsearch-results.addsearch-m2 {
    right: 76px !important;
  }
}
@media (min-width: 1921px) {
  #addsearch-results.cms-navigation-2019-compatible#addsearch-results.addsearch-d, #addsearch-results.cms-navigation-2019-compatible#addsearch-results.addsearch-t, #addsearch-results.cms-navigation-2019-compatible#addsearch-results.addsearch-m2 {
    right: calc((100vw - 1920px) / 2 + 68px) !important;
  }
}
#addsearch-results.cms-navigation-2019-compatible #addsearch-results-right {
  display: none !important;
}
#addsearch-results.cms-navigation-2019-compatible #addsearch-results-mid {
  width: auto !important;
  background: none;
}
#addsearch-results.cms-navigation-2019-compatible #addsearch-results-bottom {
  display: none;
}
#addsearch-results.cms-navigation-2019-compatible #addsearch-fuzzy {
  width: auto !important;
}
#addsearch-results.cms-navigation-2019-compatible .addsearch-result-item-sub {
  border: none !important;
  border-bottom: solid 1px #F2F2F2 !important;
  background-size: 68px auto !important;
  background-position: 24px 50% !important;
  width: auto !important;
  min-height: 128px !important;
}
#addsearch-results.cms-navigation-2019-compatible .addsearch-result-item-sub div {
  padding: 0 0 0 116px !important;
  min-height: 128px !important;
  width: auto !important;
}
#addsearch-results.cms-navigation-2019-compatible .addsearch-result-item-sub em {
  color: #707070 !important;
  font-weight: normal !important;
}
#addsearch-results.cms-navigation-2019-compatible .addsearch-result-item-sub h2 {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  text-transform: none !important;
}
#addsearch-results.cms-navigation-2019-compatible .addsearch-result-item-sub h2 a {
  color: #222222 !important;
  padding: 16px 0 8px 0 !important;
}
#addsearch-results.cms-navigation-2019-compatible .addsearch-result-item-sub p {
  color: #707070 !important;
}
#addsearch-results.cms-navigation-2019-compatible .addsearch-result-item-sub .addsearch_category {
  margin-bottom: 16px !important;
}
#addsearch-results.cms-navigation-2019-compatible .addsearch-result-item-container {
  background: none !important;
}
#addsearch-results.cms-navigation-2019-compatible #addsearch-top-close {
  display: none !important;
}

.cms-navigation-2019 {
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
  z-index: 1;
  background-color: #ffffff;
}
.cms-navigation-2019__skip-container {
  position: absolute;
  display: flex;
  align-items: center;
  top: -100px;
  height: 40px;
  width: 100%;
  background-color: #fff;
  transition: top 0.6s ease-in-out;
  z-index: 9999;
}
@media (max-width: 840px) {
  .cms-navigation-2019__skip-container {
    height: 72px;
  }
}
.cms-navigation-2019__skip-container:focus-within {
  top: 0px;
}
.cms-navigation-2019__skip-container.hidden {
  display: none;
}
.cms-navigation-2019__skip-link, .cms-navigation-2019__skip-chat {
  position: relative;
  margin-left: 110px;
  text-decoration: none;
  color: #222;
  padding: 6px 8px;
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}
@media (max-width: 1440px) {
  .cms-navigation-2019__skip-link, .cms-navigation-2019__skip-chat {
    margin-left: 100px;
  }
}
@media (max-width: 1080px) {
  .cms-navigation-2019__skip-link, .cms-navigation-2019__skip-chat {
    margin-left: 40px;
  }
}
.cms-navigation-2019__skip-chat {
  margin-left: 5px;
}
.cms-navigation-2019__skip-chat.skip-link-absent {
  margin-left: 110px;
}
@media (max-width: 1440px) {
  .cms-navigation-2019__skip-chat.skip-link-absent {
    margin-left: 100px;
  }
}
@media (max-width: 1080px) {
  .cms-navigation-2019__skip-chat.skip-link-absent {
    margin-left: 40px;
  }
}
.cms-navigation-2019__top-bar {
  overflow: hidden;
  background-color: #ffffff;
  max-height: 0;
  transition: max-height 0s;
}
@media (min-width: 841px) {
  .cms-navigation-2019__top-bar {
    height: 38px;
    max-height: 38px;
  }
}
.cms-navigation-2019__left-overlay-container {
  flex-grow: 1;
  background-color: transparent;
}
.cms-navigation-2019__top-bar-container {
  display: none;
}
@media (min-width: 841px) {
  .cms-navigation-2019__top-bar-container {
    display: flex;
    height: 38px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}
.cms-navigation-2019__market-segment-container {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  height: auto;
  padding: 0 24px;
  border-bottom: solid 1px #E0E0E0;
  background-color: #ffffff;
}
@media (min-width: 841px) {
  .cms-navigation-2019__market-segment-container {
    border-bottom: none;
    padding: 0;
    height: auto;
    align-items: initial;
  }
  .cms-navigation-2019__market-segment-container--mobile {
    display: none;
  }
}
.cms-navigation-2019__market-segment {
  color: #707070;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 16px;
  line-height: 42px;
  border-bottom: solid 3px transparent;
}
.cms-navigation-2019__market-segment--active {
  cursor: default;
  color: #990AE3;
  border-bottom: solid 3px #990AE3;
}
@media (min-width: 841px) {
  .cms-navigation-2019__market-segment {
    font-size: 11px;
    line-height: 23px;
    border-bottom: solid 2px transparent;
  }
  .cms-navigation-2019__market-segment--active {
    border-bottom: solid 2px #990AE3;
  }
  .cms-navigation-2019__market-segment:hover {
    color: #222222;
  }
}
.cms-navigation-2019__menu-and-language-container {
  overflow-y: auto;
  max-height: calc(100vh - (72px + 52px));
  max-height: calc(var(--vh, 1vh) * 100 - (72px + 52px));
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu-and-language-container {
    overflow: visible;
    max-height: none;
    white-space: nowrap;
  }
}
.cms-navigation-2019__language-container {
  display: flex;
  align-items: center;
  height: 70px;
  padding-left: 12px;
  background-color: #F2F2F2;
}
@media (min-width: 841px) {
  .cms-navigation-2019__language-container {
    padding-left: 0;
    margin-right: -8px;
    height: auto;
    background-color: #ffffff;
  }
  .cms-navigation-2019__language-container--mobile {
    display: none;
  }
}
.cms-navigation-2019__language {
  color: #707070;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 500;
  padding: 0 12px;
}
.cms-navigation-2019__language:hover {
  color: #222222;
}
@media (min-width: 841px) {
  .cms-navigation-2019__language {
    padding: 0 8px;
    font-size: 11px;
  }
}
.cms-navigation-2019__main {
  background-color: #ffffff;
  height: 72px;
  position: static;
}
.cms-navigation-2019__main-container {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: static;
  display: flex;
  height: 72px;
}
.cms-navigation-2019__main-left {
  display: flex;
  align-items: center;
}
.cms-navigation-2019__menu-container {
  position: absolute;
  max-height: 0;
  overflow: hidden;
  top: calc(72px);
  left: 0;
  width: 100%;
  transition: max-height 0.1s;
  z-index: 1;
}
.cms-navigation-2019__menu-container--visible {
  z-index: -1;
  max-height: 1000px;
  transition: max-height 0.2s;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu-container--visible {
    z-index: 0;
  }
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu-container {
    transition: margin 0.1s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
    position: static;
    overflow: visible;
    max-height: none;
    margin-left: 24px;
    top: calc(72px + 1px);
    opacity: 1;
  }
}
.cms-navigation-2019__menu-container--slide-hidden {
  transition: margin 0.2s, opacity 0.2s;
  overflow: hidden;
  opacity: 0;
  margin-left: 20px;
}
.cms-navigation-2019__menu {
  display: flex;
  margin: 0;
  padding-left: 0;
}
.cms-navigation-2019__menu ul, .cms-navigation-2019__menu li {
  list-style-type: none;
}
@media (max-width: 840px) {
  .cms-navigation-2019__menu {
    display: contents;
    width: 100%;
  }
}
.cms-navigation-2019__menu-item > .cms-navigation-2019__menu {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  max-height: 0;
  transition: max-height 0.2s, transform 0s ease-out 0.2s;
  transform: scale(0);
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu-item > .cms-navigation-2019__menu {
    overflow: visible;
    overflow-x: clip;
    transform-origin: 0 0;
    transition: transform 0.2s;
    transform: scaleY(0);
  }
  .cms-navigation-2019__menu-item > .cms-navigation-2019__menu--level-3 {
    transform: scaleX(0);
  }
}
.cms-navigation-2019__menu-item--selected > .cms-navigation-2019__menu {
  max-height: 1000px;
  transform: scale(1);
  transition: max-height 0.2s, transform 0s ease-out 0s;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu-item--selected > .cms-navigation-2019__menu {
    overflow: visible;
    transition: transform 0.2s;
    transform: scaleY(1);
    max-height: none;
  }
  .cms-navigation-2019__menu-item--selected > .cms-navigation-2019__menu--level-3 {
    transform: scaleX(1);
  }
}
.cms-navigation-2019__menu .cms-navigation-2019__menu-item--disabled .icon {
  position: relative;
  font-size: 20px;
  top: 4px;
  margin-left: 6px;
  color: #000000;
}
.cms-navigation-2019__menu--level-1 {
  flex-direction: column;
  position: relative;
  background-color: #F2F2F2;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu--level-1 {
    flex-direction: row;
    flex-wrap: nowrap;
    height: 40px;
    line-height: 35px;
    margin-top: 20px;
    background-color: #ffffff;
  }
}
.cms-navigation-2019__menu--level-2 {
  position: relative;
  background-color: #ffffff;
  max-width: 840px;
  display: block;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu--level-2 {
    z-index: 1;
    position: absolute;
    margin-top: 46px;
    background-color: #F2F2F2;
    left: -12px;
  }
}
.cms-navigation-2019__menu--level-3 {
  position: relative;
  background-color: #ffffff;
  width: inherit;
  max-width: none;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu--level-3 {
    position: absolute;
    top: 0px;
    left: 230px;
    background-color: #F2F2F2;
  }
}
.cms-navigation-2019__menu-item {
  position: relative;
  transition: background-color 0.1s ease-out 0.05s;
  cursor: pointer;
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: space-between;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu-item {
    transition: background-color 0s;
  }
}
@media (max-width: 840px) {
  .cms-navigation-2019__menu-item {
    display: block;
    background-color: #f2f2f2;
    padding-left: 24px;
  }
  .cms-navigation-2019__menu-item .cms-navigation-2019__menu-dropdown-icon {
    position: absolute;
    margin-top: 18px;
    right: 2em;
    transform: translateY(-50%);
  }
  .cms-navigation-2019__menu-item__menu-item-text {
    padding-right: 30px;
  }
}
.cms-navigation-2019__menu-item--last {
  margin-bottom: 16px;
}
.cms-navigation-2019__menu-item--last .cms-navigation-2019__menu-item--last {
  margin-bottom: 0;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu-item--last {
    margin-bottom: 0;
  }
}
.cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item {
  position: relative;
  color: #222222;
  line-height: 70px;
  border-bottom: solid 1px #E0E0E0;
  overflow: hidden;
}
.cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item--selected {
  background-color: #ffffff;
  transition: background-color 0s;
  overflow: visible;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item {
    padding: 0 12px;
    height: 40px;
    line-height: 35px;
    border-bottom: none;
    overflow: visible;
  }
}
.cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item {
  line-height: 20px;
  background-color: #ffffff;
  padding: 2px 24px 2px 2px;
  white-space: normal;
  width: 100%;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item {
    padding: 0;
    width: 230px;
    line-height: 20px;
    border-bottom: solid 1px #F2F2F2;
  }
  .cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item--selected {
    background-color: #F9F9F9;
  }
  .cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item:hover {
    background-color: #F2F2F2;
  }
}
.cms-navigation-2019__menu--level-3 > .cms-navigation-2019__menu-item {
  line-height: 20px;
  background-color: #ffffff;
  padding: 2px 24px 2px 24px;
  white-space: normal;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu--level-3 > .cms-navigation-2019__menu-item {
    padding: 0;
    width: 230px;
    line-height: 20px;
    border-bottom: solid 1px #F2F2F2;
    background-color: #F9F9F9;
  }
  .cms-navigation-2019__menu--level-3 > .cms-navigation-2019__menu-item:hover {
    background-color: #F2F2F2;
  }
}
.cms-navigation-2019 .cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-dropdown-icon {
  margin-left: 10px;
}
@media (min-width: 841px) {
  .cms-navigation-2019 .cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-dropdown-icon {
    position: static;
    margin-left: 10px;
    transform: none;
    top: auto;
    right: auto;
  }
}
.cms-navigation-2019 .cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-dropdown-icon {
  margin-left: 10px;
  top: 0;
}
@media (min-width: 841px) {
  .cms-navigation-2019 .cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-dropdown-icon {
    position: static;
    margin-left: auto;
    transform: none;
    top: auto;
    right: auto;
  }
}
.cms-navigation-2019__menu-security-icon {
  margin-right: 5px;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu-security-icon {
    margin-right: 0;
  }
}
.cms-navigation-2019__menu-item-text {
  color: #222222;
  user-select: none;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
  width: 100%;
  text-align: left;
}
@media (max-width: 840px) {
  .cms-navigation-2019__menu-item-text {
    display: inline-block;
    padding-right: 0;
    width: auto;
  }
}
.cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-item-text {
  font-size: 18px;
  font-weight: 500;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-item-text {
    padding: 0;
    font-size: 16px;
    font-weight: normal;
    /* 
     * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer 
     */
  }
}
@media (min-width: 841px) and (hover: hover) and (pointer: fine) {
  .cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-item-text:hover {
    color: #990AE3;
  }
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item--selected > .cms-navigation-2019__menu-item-text {
    color: #990AE3;
  }
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item--active > .cms-navigation-2019__menu-item-text {
    border-bottom: solid 2px #990AE3;
  }
}
.cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-item-text {
  font-size: 14px;
  font-weight: 500;
  border-top: solid 1px #F2F2F2;
  padding: 14px 0 14px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-item-text {
    font-size: 16px;
    font-weight: normal;
    border-top: none;
    padding: 17px 18px 17px 24px;
  }
  .cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-item-text:hover {
    color: #222222;
  }
}
.cms-navigation-2019__menu--level-3 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-item-text {
  font-size: 14px;
  font-weight: normal;
  padding: 10px 0 10px 22px;
  width: 100%;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu--level-3 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-item-text {
    font-size: 16px;
    font-weight: normal;
    border-top: none;
    padding: 17px 18px 17px 24px;
  }
  .cms-navigation-2019__menu--level-3 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-item-text:hover {
    color: #222222;
  }
}
.cms-navigation-2019__menu-dropdown-icon {
  transition: transform 0.2s;
  font-size: 10px;
  margin-left: auto;
}
@media (max-width: 840px) {
  .cms-navigation-2019__menu-dropdown-icon {
    font-size: 15px;
  }
}
.cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-dropdown-icon {
  transform: rotate(0deg);
  border-radius: 50%;
  line-height: 0px;
  font-size: 16px;
  background-color: #F2F2F2;
  margin-right: -9px;
  padding: 9px;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-dropdown-icon {
    font-size: 10px;
    background-color: transparent;
    padding: 0px;
    margin-right: 0;
    margin-left: 8px;
  }
}
.cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item--selected > .cms-navigation-2019__menu-item-text + .cms-navigation-2019__menu-dropdown-icon {
  transform: rotate(-180deg);
}
.cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-item-text + .cms-navigation-2019__menu-dropdown-icon {
  transform: rotate(0deg);
  border-radius: 50%;
  line-height: 0px;
  font-size: 10px;
  background-color: #ffffff;
  margin-right: 12px;
  padding: 4px;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-item-text + .cms-navigation-2019__menu-dropdown-icon {
    transition: transform 0s;
    margin-right: 0;
    transform: rotate(-90deg);
    background-color: transparent;
    margin-right: 20px;
  }
}
.cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item--selected > .cms-navigation-2019__menu-item-text + .cms-navigation-2019__menu-dropdown-icon {
  transform: rotate(-180deg);
  background-color: #F2F2F2;
}
@media (min-width: 841px) {
  .cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item--selected > .cms-navigation-2019__menu-item-text + .cms-navigation-2019__menu-dropdown-icon {
    transform: rotate(-90deg);
    background-color: transparent;
  }
}
.cms-navigation-2019__logo {
  color: #990AE3;
  line-height: 0;
  margin-bottom: 6px;
}
.cms-navigation-2019__logo .icon {
  height: 40px;
  width: 105px;
}
.cms-navigation-2019__icon-menu {
  display: flex;
  align-items: center;
  line-height: 0;
}
.cms-navigation-2019__icon-menu .icon {
  color: #222222;
}
.cms-navigation-2019__icon-menu-item {
  position: relative;
  cursor: pointer;
  margin-left: 16px;
}
.cms-navigation-2019__icon-menu-item .icon {
  height: 28px;
  width: 28px;
}
.cms-navigation-2019__icon-menu-item:hover .icon--search, .cms-navigation-2019__icon-menu-item:hover .icon--cart, .cms-navigation-2019__icon-menu-item:hover .icon--end-user, .cms-navigation-2019__icon-menu-item:hover .cms-navigation-2019__login-text {
  color: #990AE3;
}
.cms-navigation-2019__icon-menu-item--menu {
  display: block;
}
@media (min-width: 841px) {
  .cms-navigation-2019__icon-menu-item--menu {
    display: none;
  }
}
.cms-navigation-2019__search-input-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-width: 0;
  border-radius: 0;
  width: 48px;
  background-color: #ffffff;
  margin-right: -8px;
  z-index: 0;
}
@media (min-width: 841px) {
  .cms-navigation-2019__search-input-wrapper {
    transition: width 0.2s, min-width 0.2s;
  }
}
.cms-navigation-2019__search-input-wrapper--active {
  position: fixed;
  top: 0;
  left: 0;
  border-radius: 0;
  min-width: 310px;
  width: 100%;
  height: 72px;
  padding: 0 13px 0 15px;
  z-index: 1;
}
@media (min-width: 841px) {
  .cms-navigation-2019__search-input-wrapper--active {
    position: relative;
    display: block;
    border-radius: 999px;
    padding: 0 40px 0 46px;
    width: 310px;
    height: auto;
  }
}
.cms-navigation-2019__search-icon {
  cursor: pointer;
  padding: 0;
  background-color: #ffffff;
  z-index: 1;
  top: 8px;
  left: 9px;
  position: absolute;
  border: none;
}
.cms-navigation-2019__search-icon .icon {
  height: 28px;
  width: 28px;
}
.cms-navigation-2019__search-icon:focus {
  z-index: 1;
}
.cms-navigation-2019__search-input-wrapper--active .cms-navigation-2019__search-icon {
  position: relative;
  top: 0;
  left: 0;
}
@media (min-width: 841px) {
  .cms-navigation-2019__search-input-wrapper--active .cms-navigation-2019__search-icon {
    position: absolute;
    top: 8px;
    left: 8px;
  }
}
.cms-navigation-2019__search-input-wrapper--active .cms-navigation-2019__search-icon .icon--search {
  color: #222222;
}
.cms-navigation-2019__search-close-icon {
  cursor: pointer;
  padding: 5px;
  top: 0;
  right: 0;
  position: absolute;
  background-color: white;
}
.cms-navigation-2019__search-close-icon .icon {
  height: 28px;
  width: 28px;
}
@media (min-width: 841px) {
  .cms-navigation-2019__search-close-icon {
    padding: 9px;
  }
}
.cms-navigation-2019__search-input-wrapper--active .cms-navigation-2019__search-close-icon {
  position: relative;
  top: 0;
  right: 0;
}
@media (min-width: 841px) {
  .cms-navigation-2019__search-input-wrapper--active .cms-navigation-2019__search-close-icon {
    position: absolute;
  }
}
.cms-navigation-2019__search-input {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  width: 100%;
  background-color: transparent;
  border: none;
  font-size: 16px;
  height: 45px;
  padding: 8px;
  outline: none;
}
.cms-navigation-2019__search-overlay, .cms-navigation-2019__general-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.2s, height 0s ease-out 0.2s;
}
@media (min-width: 841px) {
  .cms-navigation-2019__search-overlay, .cms-navigation-2019__general-overlay {
    transition: opacity 0.2s, height 0s ease-out 0.2s;
  }
}
.cms-navigation-2019__search-overlay--visible, .cms-navigation-2019__general-overlay--visible {
  opacity: 1;
  height: 100%;
  transition: opacity 0.2s, height 0s;
  z-index: 1;
}
.cms-navigation-2019__search-overlay {
  z-index: 1;
}
.cms-navigation-2019__login-status--logged-out {
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 2px;
}
.cms-navigation-2019__login-status--logged-in {
  border: none;
  background-color: transparent;
  padding: 2px;
}
.cms-navigation-2019__login-text {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 16px;
  color: #222222;
  margin-left: 6px;
  display: none;
}
@media only screen and (min-width: 940px) {
  .cms-navigation-2019__login-text {
    display: inline-block;
  }
}
.cms-navigation-2019__login-user-initials {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #ffffff;
  background-color: #990AE3;
  font-weight: bold;
  font-size: 11px;
  width: 26px;
  height: 26px;
}
.cms-navigation-2019__login-close-icon {
  border: none;
  background-color: transparent;
  padding: 0;
}
.cms-navigation-2019__cart-amount {
  position: absolute;
  top: -4px;
  left: 13px;
  width: 19px;
  height: 19px;
  font-weight: bold;
  display: none;
  line-height: 18px;
  display: none;
  justify-content: center;
}
.cms-navigation-2019__cart-amount > .icon {
  position: absolute;
  width: 19px;
  height: 19px;
  fill: #990AE3;
  left: 0;
  top: 0;
}
.cms-navigation-2019__cart-amount-number {
  font-size: 11px;
  color: #ffffff;
  z-index: 1;
}
.cms-navigation-2019__hamburger {
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 0.2s ease-in-out;
  height: 26px;
  width: 26px;
  border: none;
  background-color: transparent;
  padding: 0;
}
.cms-navigation-2019__hamburger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 22px;
  background: #222222;
  border-radius: 999px;
  opacity: 1;
  left: 2px;
  transform: rotate(0deg);
  transition: 0.2s ease-in-out;
}
.cms-navigation-2019__hamburger span:nth-child(1) {
  top: 6px;
}
.cms-navigation-2019__hamburger span:nth-child(2), .cms-navigation-2019__hamburger span:nth-child(3) {
  top: 12px;
}
.cms-navigation-2019__hamburger span:nth-child(4) {
  top: 18px;
}
.cms-navigation-2019__hamburger--active span {
  top: 13px;
}
.cms-navigation-2019__hamburger--active span:nth-child(1) {
  width: 0;
  left: 50%;
}
.cms-navigation-2019__hamburger--active span:nth-child(2) {
  width: 22px;
  transform: rotate(45deg);
  background-color: #222222;
}
.cms-navigation-2019__hamburger--active span:nth-child(3) {
  width: 22px;
  transform: rotate(-45deg);
  background-color: #222222;
}
.cms-navigation-2019__hamburger--active span:nth-child(4) {
  width: 0;
  left: 50%;
}
.cms-navigation-2019__contents {
  max-height: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  width: 100%;
  position: relative;
  transition: max-height 0.2s;
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
}
@media (min-width: 841px) {
  .cms-navigation-2019__contents {
    transition: max-height 0.2s;
  }
}
.cms-navigation-2019__contents--login.cms-navigation-2019__contents--visible {
  background-color: transparent;
  overflow: auto;
  z-index: -1;
  max-height: calc(100vh - (72px));
  max-height: calc((var(--vh, 1vh) * 100) - 72px);
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
@media (min-width: 841px) {
  .cms-navigation-2019__contents--login.cms-navigation-2019__contents--visible {
    max-height: calc(100vh - 110px);
    max-height: calc((var(--vh, 1vh) * 100) - 110px);
  }
  .cms-navigation-2019__top-bar--hidden + .cms-navigation-2019__main .cms-navigation-2019__contents--login.cms-navigation-2019__contents--visible {
    max-height: calc(100vh - 72px);
    max-height: calc((var(--vh, 1vh) * 100) - 72px);
  }
}
.cms-navigation-2019__contents--login.cms-navigation-2019__contents--visible::-webkit-scrollbar {
  display: none;
}
.cms-navigation-2019__user-pebble {
  width: 41px;
  height: 43px;
  margin-right: 9px;
}
.cms-navigation-2019__logged-in-container {
  display: flex;
  background-color: transparent;
  max-width: 1920px;
}
@media (min-width: 841px) {
  .cms-navigation-2019__logged-in-container {
    padding: 0 36px;
    margin: 0 auto;
  }
}
@media (min-width: 1081px) {
  .cms-navigation-2019__logged-in-container {
    padding: 0 96px;
  }
}
@media (min-width: 1441px) {
  .cms-navigation-2019__logged-in-container {
    padding: 0 108px;
  }
}
.cms-navigation-2019__login-main-area {
  position: relative;
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
  padding: 0;
}
@media (min-width: 841px) {
  .cms-navigation-2019__login-main-area {
    padding-top: 0;
  }
}
.cms-navigation-2019__login-bottom-area {
  border-top: 1px solid #E0E0E0;
  background-color: #F2F2F2;
}
.cms-navigation-2019__login-bottom-link {
  display: flex;
  font-size: 16px;
  color: #222222;
  font-size: 14px;
  padding: 22px 27px 18px;
}
.cms-navigation-2019__login-bottom-link:hover {
  color: #222222;
}
@media (min-width: 841px) {
  .cms-navigation-2019__login-bottom-link {
    padding: 16px 0 8px;
  }
  .cms-navigation-2019__login-bottom-link:first-of-type() {
    padding-top: 30px;
  }
  .cms-navigation-2019__login-bottom-link:last-of-type() {
    padding-bottom: 30px;
  }
}
.cms-navigation-2019__login-bottom-link span {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  line-height: 1;
}
.cms-navigation-2019__login-bottom-link svg {
  font-size: 24px;
  margin-right: 8px;
}
@media (min-width: 841px) {
  .cms-navigation-2019__login-bottom-link-container {
    max-width: 532px;
    margin: 0 auto;
  }
}
.cms-navigation-2019__login-bottom-link-container--logged-in {
  min-width: 260px;
  max-width: 260px;
  margin-left: auto;
  display: grid;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .cms-navigation-2019__login-bottom-link-container--logged-in {
    display: block;
  }
}
.cms-navigation-2019__login-bottom-link-container--logged-in .cms-navigation-2019__login-bottom-link {
  color: #707070;
  border-top: 1px solid #E0E0E0;
  background-color: #F2F2F2;
  padding: 15px 24px;
}
.cms-navigation-2019__login-bottom-link-container--logged-in .cms-navigation-2019__login-bottom-link:hover, .cms-navigation-2019__login-bottom-link-container--logged-in .cms-navigation-2019__login-bottom-link:active {
  background-color: #E0E0E0;
}
.cms-navigation-2019__login-bottom-icon {
  color: #000000;
  font-size: 24px;
  margin-right: 14px;
}
@media (min-width: 841px) {
  .cms-navigation-2019__login-bottom-icon {
    font-size: 40px;
    margin-right: 20px;
  }
}
.cms-navigation-2019__login-bottom-title {
  color: #000000;
  margin-left: 0;
}
@media (min-width: 841px) {
  .cms-navigation-2019__login-bottom-title {
    font-size: 18px;
    margin: 0;
  }
}
.cms-navigation-2019__login-bottom-description {
  font-size: 14px;
  color: #707070;
}
@media (max-width: 840px) {
  .cms-navigation-2019__login-bottom-description {
    display: none;
  }
}
.cms-navigation-2019 .sub-nav {
  z-index: 0;
}

.cms-common__grid-padding {
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 5%;
}
@media (min-width: 481px) {
  .cms-common__grid-padding {
    padding: 0 34px;
  }
}
@media (min-width: 601px) {
  .cms-common__grid-padding {
    padding: 0 36px;
  }
}
@media (min-width: 1081px) {
  .cms-common__grid-padding {
    padding: 0 96px;
  }
}
@media (min-width: 1441px) {
  .cms-common__grid-padding {
    padding: 0 108px;
  }
}

.navigation-card__container {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 16px 16px 0 rgba(58, 58, 58, 0.1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 144px;
  justify-content: space-between;
  padding: 16px;
}
@media (min-width: 481px) {
  .navigation-card__container {
    height: 296px;
    padding: 24px;
  }
}
.navigation-card__image {
  align-items: left;
  height: 48px;
  width: 48px;
}
@media (min-width: 481px) {
  .navigation-card__image {
    height: 64px;
    width: 64px;
  }
}
.navigation-card__title {
  color: #990AE3;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
}
@media (min-width: 481px) {
  .navigation-card__title {
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 8px;
  }
}
.navigation-card__text {
  color: #000000;
  display: none;
  font-size: 12px;
  line-height: 20px;
}
@media (min-width: 481px) {
  .navigation-card__text {
    display: block;
  }
}

@media (min-width: 601px) {
  .cms-new-magnolia-card--horizontal.cms-new-magnolia-card--corner {
    min-height: 380px;
  }
}
.cms-new-magnolia-card--horizontal.cms-new-magnolia-card--corner .cms-new-magnolia-card__footer-container {
  min-width: unset;
  width: unset;
}

.cms-new-magnolia-card {
  box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.12);
  padding: 10px 10px 10px 10px;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  position: relative;
  transition: transform 0.2s ease 0s;
  -webkit-text-size-adjust: 100%;
}
@media (min-width: 481px) {
  .cms-new-magnolia-card {
    padding: 16px 16px 16px 16px;
  }
}
@media (min-width: 601px) {
  .cms-new-magnolia-card {
    padding: 16px 32px 16px 32px;
  }
}
.cms-new-magnolia-card--no-subcomponent-margin > * {
  margin: 0 !important;
}
.cms-new-magnolia-card--no-subcomponent-padding > * {
  padding: 0 !important;
}
.cms-new-magnolia-card--no-subcomponent-border > * {
  border: none !important;
}
.cms-new-magnolia-card--no-subcomponent-shadow > * {
  box-shadow: none !important;
}
.cms-new-magnolia-card--overflow-hidden {
  overflow: hidden;
}
.cms-new-magnolia-card__link-overlay {
  left: 0;
  top: 0;
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  cursor: pointer;
}
.cms-new-magnolia-card--text-black {
  color: #3A3A3A;
}
.cms-new-magnolia-card--text-white {
  color: #ffffff;
}
.cms-new-magnolia-card--text-deepPurple {
  color: #29003e;
}
.cms-new-magnolia-card--text-lightPurple {
  color: #F4E0FF;
}
.cms-new-magnolia-card--text-deepBeige {
  color: #5C524E;
}
.cms-new-magnolia-card--text-lightBeige {
  color: #F8EEE8;
}
.cms-new-magnolia-card--text-beige {
  color: #ECDED4;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card--with-link:hover {
    transform: scale(1.03);
    box-shadow: 0px 12px 24px 0px rgba(0, 0, 0, 0.12);
    z-index: 3;
  }
}
.cms-new-magnolia-card--background-white {
  background-color: #ffffff;
}
.cms-new-magnolia-card--background-grey {
  background-color: #F2F2F2;
  box-shadow: 0px 12px 24px 10px rgba(0, 0, 0, 0.12);
}
.cms-new-magnolia-card--background-purple-gradient {
  background: linear-gradient(180deg, #990AE3 0%, #7625a7 100%);
}
.cms-new-magnolia-card--background-black-gradient {
  background: linear-gradient(180deg, #505256 0%, #1B1D20 100%);
}
.cms-new-magnolia-card--background-deepPurple {
  background-color: #29003e;
}
.cms-new-magnolia-card--background-lightPurple {
  background-color: #F4E0FF;
}
.cms-new-magnolia-card--background-deepBeige {
  background-color: #5C524E;
}
.cms-new-magnolia-card--background-lightBeige {
  background-color: #F8EEE8;
}
.cms-new-magnolia-card--background-beige {
  background-color: #ECDED4;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card {
    border-radius: 16px;
  }
}
.cms-new-magnolia-card__tag-container {
  height: 22px;
  display: flex;
  justify-content: flex-end;
  margin-right: 0;
  margin-bottom: 5px;
}
@media (min-width: 481px) {
  .cms-new-magnolia-card__tag-container {
    margin-right: -5px;
    margin-bottom: 6px;
  }
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__tag-container {
    margin-right: -16px;
    margin-bottom: 7px;
  }
}
.cms-new-magnolia-card__tag {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 22px;
  padding: 0 6px;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  justify-content: center;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__tag {
    font-size: 12px;
    line-height: 23px;
    padding: 0 8px;
  }
}
.cms-new-magnolia-card__tag--red {
  background-color: #E4175C;
}
.cms-new-magnolia-card__tag--orange {
  background-color: #FF9B00;
}
.cms-new-magnolia-card__tag--blue {
  background-color: #0099ff;
}
.cms-new-magnolia-card__tag--transparent {
  background-color: rgba(112, 112, 112, 0.5);
}
.cms-new-magnolia-card__tag--purple {
  background-color: #990AE3;
}
.cms-new-magnolia-card__content-container {
  display: flex;
  flex: 1 0 auto;
  min-height: 1px;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__content-container {
    flex-direction: column;
  }
  .cms-new-magnolia-card--horizontal .cms-new-magnolia-card__content-container {
    flex-direction: row;
  }
}
.cms-new-magnolia-card--large .cms-new-magnolia-card__content-container {
  flex-direction: column;
}
.cms-new-magnolia-card__content-container > .cms-new-magnolia-card__image-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  width: 100%;
  min-height: 1px;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__content-container > .cms-new-magnolia-card__image-container {
    display: none;
  }
  .cms-new-magnolia-card--horizontal .cms-new-magnolia-card__content-container > .cms-new-magnolia-card__image-container {
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
}
@media (max-width: 840px) {
  .cms-new-magnolia-card--corner .cms-new-magnolia-card__content-container > .cms-new-magnolia-card__image-container {
    margin-top: auto;
  }
}
.cms-new-magnolia-card--horizontal.cms-new-magnolia-card--corner .cms-new-magnolia-card__content-container > .cms-new-magnolia-card__image-container {
  margin-top: auto;
}
.cms-new-magnolia-card--large .cms-new-magnolia-card__content-container > .cms-new-magnolia-card__image-container {
  display: none;
}
.cms-new-magnolia-card .cms-new-magnolia-card__image--huippudiili-mobile {
  width: 140px;
  height: 140px;
}
.cms-new-magnolia-card__content-special {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 1px;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__content-special {
    flex: 1 0 auto;
  }
  .cms-new-magnolia-card--horizontal .cms-new-magnolia-card__content-special {
    flex: 0 0 50%;
  }
}
.cms-new-magnolia-card--large .cms-new-magnolia-card__content-special {
  flex: 1 0 auto;
}
.cms-new-magnolia-card__content-special .cms-new-magnolia-card__image {
  width: 200px;
  height: 200px;
}
.cms-new-magnolia-card__content-special .cms-new-magnolia-card__image-container {
  margin-bottom: 24px;
}
.cms-new-magnolia-card__content-special > .cms-new-magnolia-card__image-container {
  display: none;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__content-special > .cms-new-magnolia-card__image-container {
    display: block;
  }
  .cms-new-magnolia-card--horizontal .cms-new-magnolia-card__content-special > .cms-new-magnolia-card__image-container {
    display: none;
  }
}
.cms-new-magnolia-card--large .cms-new-magnolia-card__content-special > .cms-new-magnolia-card__image-container {
  display: flex;
  align-self: center;
  margin-top: auto;
}
.cms-new-magnolia-card__content-special > .cms-new-magnolia-card__huippudiili-component {
  display: none;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__content-special > .cms-new-magnolia-card__huippudiili-component {
    display: block;
  }
  .cms-new-magnolia-card--horizontal .cms-new-magnolia-card__content-special > .cms-new-magnolia-card__huippudiili-component {
    display: none;
  }
}
.cms-new-magnolia-card__content {
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 1px;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__content {
    flex: 1 0 auto;
  }
  .cms-new-magnolia-card--horizontal .cms-new-magnolia-card__content {
    flex: 0 0 50%;
  }
}
.cms-new-magnolia-card--large .cms-new-magnolia-card__content {
  flex: 1 0 auto;
}
.cms-new-magnolia-card__content > .cms-new-magnolia-card__image-container {
  display: none;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__content > .cms-new-magnolia-card__image-container {
    display: flex;
    align-self: center;
    margin-top: auto;
    width: 100%;
    min-height: 1px;
  }
  .cms-new-magnolia-card--horizontal .cms-new-magnolia-card__content > .cms-new-magnolia-card__image-container {
    display: none;
  }
}
.cms-new-magnolia-card--large .cms-new-magnolia-card__content > .cms-new-magnolia-card__image-container {
  display: flex;
  align-self: center;
  margin-top: auto;
}
.cms-new-magnolia-card__text-container {
  z-index: 1;
}
.cms-new-magnolia-card__description {
  font-size: 11px;
  line-height: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  color: rgba(160, 160, 160, 0.85);
}
@media (min-width: 481px) {
  .cms-new-magnolia-card__description {
    font-size: 14px;
  }
}
@media (min-width: 1441px) {
  .cms-new-magnolia-card__description {
    font-size: 16px;
  }
}
.cms-new-magnolia-card--large .cms-new-magnolia-card__description {
  font-size: 14px;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card--large .cms-new-magnolia-card__description {
    font-size: 16px;
  }
}
@media (min-width: 1441px) {
  .cms-new-magnolia-card--large .cms-new-magnolia-card__description {
    font-size: 18px;
  }
}
.cms-new-magnolia-card__title {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-bottom: 12px;
  margin-top: 0;
  color: inherit;
}
@media (min-width: 481px) {
  .cms-new-magnolia-card__title {
    font-size: 16px;
    line-height: 20px;
  }
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__title {
    font-size: 20px;
    line-height: 28px;
  }
}
@media (min-width: 1441px) {
  .cms-new-magnolia-card__title {
    font-size: 24px;
    line-height: 32px;
  }
}
.cms-new-magnolia-card--large .cms-new-magnolia-card__title {
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 14px;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card--large .cms-new-magnolia-card__title {
    font-size: 27px;
    line-height: 33px;
  }
}
@media (min-width: 841px) {
  .cms-new-magnolia-card--large .cms-new-magnolia-card__title {
    font-size: 29px;
    line-height: 35px;
  }
}
@media (min-width: 1081px) {
  .cms-new-magnolia-card--large .cms-new-magnolia-card__title {
    font-size: 37px;
    line-height: 42px;
  }
}
.cms-new-magnolia-card__text {
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 12px;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__text {
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
  }
}
@media (min-width: 841px) {
  .cms-new-magnolia-card__text {
    font-size: 15px;
    line-height: 24px;
  }
  .cms-new-magnolia-card--large .cms-new-magnolia-card__text {
    font-weight: 500;
  }
}
.cms-new-magnolia-card__image-container {
  max-width: 290px;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__image-container {
    max-width: 420px;
    margin: 0 auto;
  }
}
.cms-new-magnolia-card--corner .cms-new-magnolia-card__image-container {
  height: 180px;
}
@media (min-width: 841px) {
  .cms-new-magnolia-card--horizontal.cms-new-magnolia-card--corner .cms-new-magnolia-card__image-container {
    height: 260px;
  }
}
.cms-new-magnolia-card__image {
  margin-bottom: 0;
  max-width: 100%;
  align-self: center;
  flex-shrink: 0;
  margin: 0 auto;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__image {
    margin-bottom: 12px;
  }
  .cms-new-magnolia-card--horizontal .cms-new-magnolia-card__image {
    margin-bottom: 0;
  }
  .cms-new-magnolia-card--corner .cms-new-magnolia-card__image {
    margin-bottom: 0;
  }
}
.cms-new-magnolia-card--corner .cms-new-magnolia-card__image {
  transform: translateX(33%) translateY(20%) scale(1.2);
}
@media (min-width: 601px) {
  .cms-new-magnolia-card--corner .cms-new-magnolia-card__image {
    transform: translateX(33%) translateY(40%) scale(1.2);
  }
}
.cms-new-magnolia-card--corner.cms-new-magnolia-card--large .cms-new-magnolia-card__image {
  transform: translateX(33%) translateY(33%) scale(1.2);
}
@media (min-width: 601px) {
  .cms-new-magnolia-card--corner.cms-new-magnolia-card--large .cms-new-magnolia-card__image {
    transform: translateX(33%) translateY(40%) scale(1.2);
  }
}
.cms-new-magnolia-card--horizontal.cms-new-magnolia-card--corner .cms-new-magnolia-card__image {
  transform: translateX(33%) translateY(25%) scale(1.2);
}
@media (min-width: 601px) {
  .cms-new-magnolia-card--horizontal.cms-new-magnolia-card--corner .cms-new-magnolia-card__image {
    transform: translateX(35%) scale(1.2);
  }
}
@media (min-width: 841px) {
  .cms-new-magnolia-card--horizontal.cms-new-magnolia-card--corner .cms-new-magnolia-card__image {
    transform: translateX(35%) translateY(30%) scale(1.2);
  }
}
.cms-new-magnolia-card--horizontal.cms-new-magnolia-card--corner.cms-new-magnolia-card--large .cms-new-magnolia-card__image {
  transform: translateX(33%) translateY(33%) scale(1.2);
}
@media (min-width: 601px) {
  .cms-new-magnolia-card--horizontal.cms-new-magnolia-card--corner.cms-new-magnolia-card--large .cms-new-magnolia-card__image {
    transform: translateX(33%) translateY(40%) scale(1.2);
  }
}
.cms-new-magnolia-card--large .cms-new-magnolia-card__image {
  margin-bottom: 12px;
}
.cms-new-magnolia-card__footer-container {
  z-index: 1;
}
.cms-new-magnolia-card--corner .cms-new-magnolia-card__footer-container {
  width: 50%;
  min-width: 80px;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card--corner .cms-new-magnolia-card__footer-container {
    width: 40%;
    min-width: 122px;
  }
}
.cms-new-magnolia-card__price {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 20px;
  line-height: 20px;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__price {
    font-size: 32px;
    line-height: 23px;
  }
}
.cms-new-magnolia-card__price-unit {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin-left: -4px;
  margin-right: 6px;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__price-unit {
    font-size: 24px;
    line-height: 24px;
  }
}
.cms-new-magnolia-card__price-crossed {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-decoration: line-through;
  color: #C5C5C5;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__price-crossed {
    font-size: 24px;
    line-height: 24px;
  }
}
.cms-new-magnolia-card__first-footer-description {
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__first-footer-description {
    font-size: 14px;
    line-height: 26px;
  }
}
.cms-new-magnolia-card__second-footer-description {
  color: #C5C5C5;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card__second-footer-description {
    font-size: 14px;
    line-height: 20px;
  }
}
.cms-new-magnolia-card .cms-huippudiili-number-offer__form-container-dark .text-input__field {
  border-color: #ffffff;
  color: #ffffff;
}
.cms-new-magnolia-card .cms-huippudiili-number-offer__form-container-dark .text-input__field.has-value:focus ~ .text-input__label, .cms-new-magnolia-card .cms-huippudiili-number-offer__form-container-dark .text-input__field.has-value.focus ~ .text-input__label {
  color: #ffffff;
}
.cms-new-magnolia-card .cms-huippudiili-number-offer__form-container-dark .text-input__field:focus ~ .text-input__label, .cms-new-magnolia-card .cms-huippudiili-number-offer__form-container-dark .text-input__field.focus ~ .text-input__label {
  color: #ffffff;
}
.cms-new-magnolia-card .cms-huippudiili-number-offer__form-container-dark .text-input__field ~ .text-input__label {
  color: rgba(255, 255, 255, 0.66);
}
.cms-new-magnolia-card .cms-huippudiili-number-offer__form-container-dark .cms-text-input__error-text {
  color: #ffffff;
}
.cms-new-magnolia-card .cms-huippudiili-number-offer__form-container-dark .icon--error-filled {
  color: #E4175C;
}
.cms-new-magnolia-card .cms-huippudiili-number-offer__input-container {
  margin-bottom: 15px;
}
.cms-new-magnolia-card .cms-huippudiili-number-offer__input-container .text-input {
  margin-bottom: 15px;
}
.cms-new-magnolia-card .cms-huippudiili-number-offer__form-container {
  padding-top: 0px;
  padding-bottom: 0px;
}
@media (min-width: 601px) {
  .cms-new-magnolia-card .cms-huippudiili-number-offer__form-container {
    margin-bottom: 10px;
  }
}
.cms-new-magnolia-card .cms-huippudiili-number-offer {
  margin-bottom: 0px;
}

.cms-plan-card {
  box-sizing: border-box;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  transition: transform ease 0.3s, box-shadow ease 0.3s;
  cursor: pointer;
  position: relative;
  text-align: left;
}
.cms-plan-card__badge {
  position: absolute;
  top: 0;
  font-weight: 500;
  font-size: 14px;
  border-radius: 2px;
  line-height: 1;
  padding: 4px 8px;
}
@media (max-width: 480px) {
  .cms-plan-card__badge {
    left: 16px;
  }
}
@media (min-width: 480px) {
  .cms-plan-card__badge {
    left: 48px;
  }
}
.cms-plan-card__badge--specialOffer {
  background-color: rgb(237, 200, 255);
  color: rgb(41, 0, 62);
}
.cms-plan-card__badge--attention {
  background-color: rgb(255, 195, 102);
  color: rgb(102, 62, 0);
}
.cms-plan-card__badge--information {
  background-color: rgb(166, 219, 255);
  color: rgb(0, 61, 102);
}
.cms-plan-card__badge--notAvailable {
  background-color: rgb(220, 220, 225);
  color: rgb(48, 48, 50);
}
.cms-plan-card__splash-container {
  z-index: 1;
  width: 100%;
  max-width: 408px;
  position: relative;
}
.cms-plan-card__splash {
  position: absolute;
  right: 0;
  display: flex;
  justify-content: center;
  align-content: center;
  top: -16px;
}
@media (min-width: 480px) {
  .cms-plan-card__splash {
    top: -26px;
    right: -26px;
  }
}
.cms-plan-card__splash .icon {
  height: 100%;
  width: 100%;
}
.cms-plan-card__splash--small {
  width: 48px;
  height: 48px;
}
.cms-plan-card__splash--medium {
  width: 64px;
  height: 64px;
}
.cms-plan-card__splash--large {
  width: 80px;
  height: 80px;
}
.cms-plan-card__splash--color-orange {
  color: #ffffff;
}
.cms-plan-card__splash--color-orange .icon {
  fill: #FF9B00;
}
.cms-plan-card__splash--color-purple {
  color: #ffffff;
}
.cms-plan-card__splash--color-purple .icon {
  fill: #990AE3;
}
.cms-plan-card__splash--color-black {
  color: #ffffff;
}
.cms-plan-card__splash--color-black .icon {
  fill: #222222;
}
.cms-plan-card__splash--color-white {
  color: #222222;
}
.cms-plan-card__splash--color-white .icon {
  fill: #ffffff;
}
.cms-plan-card__splash--color-red {
  color: #ffffff;
}
.cms-plan-card__splash--color-red .icon {
  fill: #E4175C;
}
.cms-plan-card__splash--color-green {
  color: #ffffff;
}
.cms-plan-card__splash--color-green .icon {
  fill: #00CC66;
}
.cms-plan-card__splash--color-blue {
  color: #ffffff;
}
.cms-plan-card__splash--color-blue .icon {
  fill: #0099ff;
}
.cms-plan-card__splash-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  line-height: 14px;
  font-weight: bold;
  text-align: center;
}
.cms-plan-card:hover {
  transform: scale(1.03);
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 4px;
}
@media (max-width: 480px) {
  .cms-plan-card {
    height: auto;
    padding-top: 32px;
    padding-right: 16px;
    padding-bottom: 24px;
    padding-left: 16px;
  }
}
@media (min-width: 480px) {
  .cms-plan-card {
    min-height: 624px;
    overflow: hidden;
    padding-top: 48px;
    padding-right: 48px;
    padding-bottom: 40px;
    padding-left: 48px;
    flex-grow: 1;
  }
}
.cms-plan-card--with-image {
  padding-top: 40px;
}
@media (max-width: 480px) {
  .cms-plan-card__container {
    padding-bottom: 24px;
  }
}
@media (min-width: 481px) {
  .cms-plan-card__container {
    padding-bottom: 40px;
  }
}
.cms-plan-card__header {
  max-width: 408px;
}
@media (max-width: 480px) {
  .cms-plan-card__header {
    min-height: 76px;
  }
}
@media (min-width: 481px) {
  .cms-plan-card__header {
    min-height: 100px;
  }
}
.cms-plan-card--with-image .cms-plan-card__header {
  min-height: 144px;
}
.cms-plan-card__image {
  height: 64px;
  text-align: center;
}
.cms-plan-card__image img {
  max-width: 100%;
  max-height: 100%;
}
.cms-plan-card__image + .cms-plan-card__name {
  margin-top: 16px;
}
.cms-plan-card__name {
  font-size: 24px;
  line-height: 24px;
  margin: 0;
  text-align: center;
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #222222;
}
.cms-plan-card--with-image .cms-plan-card__name {
  line-height: 32px;
}
.cms-plan-card__name + .cms-plan-card__description {
  margin-top: 4px;
}
.cms-plan-card__description {
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  color: #3A3A3A;
}
@media (max-width: 480px) {
  .cms-plan-card__description {
    line-height: 16px;
  }
}
@media (min-width: 481px) {
  .cms-plan-card__description {
    line-height: 24px;
  }
}
.cms-plan-card__body {
  flex-grow: 1;
  width: 100%;
  max-width: 408px;
}
.cms-plan-card__smallprint {
  padding-top: 4px;
  min-height: 48px;
  font-weight: 500;
  color: #3A3A3A;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.cms-plan-card__smallprint * {
  font-size: 12px;
  line-height: 20px;
  margin: 0;
}
.cms-plan-card__button {
  height: 56px;
  margin-top: 24px;
  text-align: center;
}
.cms-plan-card__footer {
  max-width: 408px;
  margin-top: 21px;
}
.cms-plan-card__pebble {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 60px;
  height: 60px;
}
.cms-plan-card__pebble-icon {
  font-size: 60px;
  color: #0099ff;
}
.cms-plan-card__pebble-text {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #ffffff;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-55%);
}

.cms-plan-attribute {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 480px) {
  .cms-plan-attribute {
    height: 24px;
  }
}
@media (min-width: 481px) {
  .cms-plan-attribute {
    height: 32px;
  }
}
.cms-plan-attribute__icon {
  text-align: center;
}
@media (max-width: 480px) {
  .cms-plan-attribute__icon {
    width: 24px;
    height: 24px;
    font-size: 24px;
  }
}
@media (min-width: 481px) {
  .cms-plan-attribute__icon {
    width: 32px;
    height: 32px;
    font-size: 32px;
  }
}
.cms-plan-attribute__icon img {
  max-width: 100%;
  max-height: 100%;
}
.cms-plan-attribute__icon + .cms-plan-attribute__name {
  margin-left: 8px;
}
.cms-plan-attribute__name, .cms-plan-attribute__unit {
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
@media (max-width: 480px) {
  .cms-plan-attribute__name, .cms-plan-attribute__unit {
    font-size: 10px;
    line-height: 12px;
    height: 24px;
  }
}
@media (min-width: 481px) {
  .cms-plan-attribute__name, .cms-plan-attribute__unit {
    font-size: 12px;
    line-height: 14px;
    height: 28px;
  }
}
.cms-plan-attribute__unit {
  align-items: flex-end;
}
.cms-plan-attribute__spacer {
  flex-grow: 1;
  box-sizing: border-box;
  height: 24px;
  border-bottom: 2px dashed #A0A0A0;
}
.cms-plan-attribute__name + .cms-plan-attribute__spacer, .cms-plan-attribute__spacer + .cms-plan-attribute__quantity, .cms-plan-attribute__spacer + .cms-plan-attribute__unlimited {
  margin-left: 8px;
}
.cms-plan-attribute__quantity {
  color: #3A3A3A;
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
}
@media (max-width: 480px) {
  .cms-plan-attribute__quantity {
    font-size: 24px;
    line-height: 24px;
  }
}
@media (min-width: 481px) {
  .cms-plan-attribute__quantity {
    font-size: 32px;
    line-height: 32px;
  }
}
.cms-plan-attribute__quantity + .cms-plan-attribute__unit {
  margin-left: 4px;
}
.cms-plan-attribute__unlimited {
  color: #3A3A3A;
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
}
@media (max-width: 480px) {
  .cms-plan-attribute__unlimited {
    font-size: 18px;
    line-height: 18px;
    padding-top: 6px;
  }
}
@media (min-width: 481px) {
  .cms-plan-attribute__unlimited {
    font-size: 24px;
    line-height: 26px;
    padding-top: 6px;
  }
}
.cms-plan-attribute__segment {
  display: block;
  white-space: nowrap;
}
.cms-plan-attribute + .cms-plan-attribute {
  margin-top: 16px;
}

.cms-plan-price {
  color: #3A3A3A;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
@media (max-width: 480px) {
  .cms-plan-price {
    margin-top: 16px;
  }
}
.cms-plan-price--justify-left {
  justify-content: flex-start;
}
.cms-plan-price__prefix {
  font-weight: bold;
  margin-right: 6px;
}
@media (max-width: 480px) {
  .cms-plan-price__prefix {
    font-size: 12px;
    line-height: 14px;
    padding-bottom: 2px;
  }
}
@media (min-width: 481px) {
  .cms-plan-price__prefix {
    font-size: 14px;
    line-height: 24px;
    padding-bottom: 2px;
  }
}
.cms-plan-price__old {
  font-weight: bold;
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  text-decoration: line-through;
  color: #A0A0A0;
  margin-right: 4px;
}
@media (max-width: 480px) {
  .cms-plan-price__old {
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 2px;
  }
}
@media (min-width: 481px) {
  .cms-plan-price__old {
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 2px;
  }
}
.cms-plan-price__quantity {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
}
@media (max-width: 480px) {
  .cms-plan-price__quantity {
    font-size: 32px;
    line-height: 32px;
  }
}
@media (min-width: 481px) {
  .cms-plan-price__quantity {
    font-size: 48px;
    line-height: 48px;
  }
}
.cms-plan-price__quantity + .cms-plan-price__unit {
  margin-left: 4px;
}
.cms-plan-price__unit {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
}
@media (max-width: 480px) {
  .cms-plan-price__unit {
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 2px;
  }
}
@media (min-width: 481px) {
  .cms-plan-price__unit {
    font-size: 24px;
    line-height: 26px;
    padding-bottom: 4px;
  }
}

.cms-plan-promo {
  margin: 24px 0 0;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  min-height: 80px;
}
.cms-plan-promo__content {
  width: 85%;
}
.cms-plan-promo__badge-container {
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  padding: 5px;
  border-radius: 3px;
  margin-top: -4px;
}
.cms-plan-promo__badge-container .cms-plan-promo__badge-text {
  margin-left: 5px;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
}
.cms-plan-promo__badge-container--background-black {
  background-color: #000000;
}
.cms-plan-promo__badge-container--background-white {
  background-color: #ffffff;
}
.cms-plan-promo__badge-container--background-deepPurple {
  background-color: #29003e;
}
.cms-plan-promo__badge-container--background-lightPurple {
  background-color: #F4E0FF;
}
.cms-plan-promo__badge-container--background-deepBeige {
  background-color: #5C524E;
}
.cms-plan-promo__badge-container--background-lightBeige {
  background-color: #F8EEE8;
}
.cms-plan-promo__badge-container--background-beige {
  background-color: #ECDED4;
}
.cms-plan-promo__badge-container--text-color-black {
  color: #000000;
}
.cms-plan-promo__badge-container--text-color-white {
  color: #ffffff;
}
.cms-plan-promo__badge-container--text-color-deepPurple {
  color: #29003e;
}
.cms-plan-promo__badge-container--text-color-lightPurple {
  color: #F4E0FF;
}
.cms-plan-promo__badge-container--text-color-deepBeige {
  color: #5C524E;
}
.cms-plan-promo__badge-container--text-color-lightBeige {
  color: #F8EEE8;
}
.cms-plan-promo__badge-container--text-color-beige {
  color: #ECDED4;
}
.cms-plan-promo__title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: rgba(0, 0, 0, 0.8);
}
.cms-plan-promo__title-top-space {
  margin-top: 8px;
}
.cms-plan-promo__description {
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  color: rgba(0, 0, 0, 0.62);
}
.cms-plan-promo__image {
  flex-shrink: 0;
  text-align: center;
  margin-top: -4px;
}
@media (max-width: 480px) {
  .cms-plan-promo__image {
    width: 56px;
    height: 56px;
  }
}
@media (min-width: 481px) {
  .cms-plan-promo__image {
    width: 56px;
    height: 56px;
  }
}
.cms-plan-promo__image img {
  max-width: 100%;
  max-height: 100%;
  width: inherit;
  height: inherit;
}

.cms-notification-card__loader {
  text-align: center;
  padding: 30px;
}
.cms-notification-card__loader--hidden {
  display: none;
}
.cms-notification-card__gridbag-gutter-simulator {
  margin-bottom: 12px;
}
@media (min-width: 601px) {
  .cms-notification-card__gridbag-gutter-simulator {
    margin-bottom: 16px;
  }
}
@media (min-width: 1081px) {
  .cms-notification-card__gridbag-gutter-simulator {
    margin-bottom: 24px;
  }
}
.cms-notification-card__placeholder {
  margin-bottom: 12px;
}
@media (min-width: 601px) {
  .cms-notification-card__placeholder {
    margin-bottom: 16px;
  }
}
@media (min-width: 1081px) {
  .cms-notification-card__placeholder {
    margin-bottom: 24px;
  }
}

.operator-search {
  margin-top: 2em;
  margin-bottom: 5px;
}
.operator-search__icon {
  position: absolute;
  font-size: 1.5em;
  color: #000000;
  left: 0.7em;
  top: 0.7em;
}
.operator-search__searchbox {
  position: relative;
  width: 100%;
}
.operator-search__input {
  padding: 1em 1em 1em 3em;
  width: 100%;
  outline: 0;
  font: inherit;
  border-radius: 8px;
  border: 1px solid #E0E0E0;
  box-shadow: 0 1px 2px 0 #E0E0E0;
  transition: 0.2s border-color;
}
.operator-search__input:focus {
  margin: 0;
  border: 2px solid #990AE3;
}
.operator-search__input::-webkit-input-placeholder {
  color: #707070;
}
.operator-search__input:-moz-placeholder {
  color: #707070;
}
.operator-search__input::-moz-placeholder {
  color: #707070;
}
.operator-search__input:-ms-input-placeholder {
  color: #707070;
}
.operator-search__table {
  width: 100%;
  border-collapse: collapse;
}
.operator-search__row {
  border-bottom: 1px solid #E0E0E0;
}
.operator-search__operator {
  width: 40%;
}
.operator-search__details {
  width: 60%;
}

.cms-opt-out__otp {
  display: none;
}
.cms-opt-out .form-row--text-input {
  margin-bottom: 35px;
}
.cms-opt-out__error {
  display: none;
}
.cms-opt-out__error-text {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
  color: #E4175C;
}
.cms-opt-out__load-animation {
  display: none;
  margin-top: 25px;
}

.order-tracking__background-image {
  min-height: 300px;
  width: 100%;
  background-size: cover;
  background-repeat: repeat-x;
  background-position: 50% 50%;
  position: absolute;
}
.order-tracking__overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: none;
  transition: background 0.3s ease-out;
}
.order-tracking__overlay--active {
  background: rgba(0, 0, 0, 0.15);
}
.order-tracking__form {
  min-height: 300px;
  position: relative;
  color: #ffffff;
  text-align: center;
}
.order-tracking__form--no-image {
  background-color: #990AE3;
  height: 320px;
}
.order-tracking__form--content {
  width: 100%;
  position: absolute;
  left: 0;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
}
.order-tracking__form--content h3 {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1;
}
.order-tracking__heading {
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
}
.order-tracking__description {
  margin: 1.3em auto 1.8em;
  line-height: 1;
}
.order-tracking__input-form {
  position: relative;
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
}
.order-tracking__error {
  text-align: center;
  color: #E4175C;
  padding: 10px;
}
.order-tracking__results {
  text-align: center;
  clear: both;
  padding-bottom: 2em;
  font-size: 14px;
}
.order-tracking__results--product {
  font-weight: 700;
  padding: 4em 0 2px;
}
.order-tracking__results--product-name {
  margin-bottom: 2em;
  margin-left: auto;
  margin-right: auto;
}
.order-tracking__results p {
  font-size: 14px;
  color: #3A3A3A;
}
.order-tracking__details {
  display: block;
  font-weight: normal;
  line-height: 16px;
  color: #707070;
}
.order-tracking__results-table {
  margin: 0 auto;
  color: #3A3A3A;
  font-weight: 700;
  word-wrap: break-word;
  display: table;
  border-collapse: collapse;
}
.order-tracking__results-table td {
  padding: 2em;
  text-align: left;
  vertical-align: top;
}

.overlay-container__title h4 {
  margin: 0;
}
.overlay-container__title--white {
  color: #ffffff;
}
.overlay-container__title--black {
  color: #000000;
}
.overlay-container__title--deepPurple {
  color: #29003e;
}
.overlay-container__title--deepBeige {
  color: #5C524E;
}
.overlay-container__title--beige {
  color: #ECDED4;
}
.overlay-container__title--lightPurple {
  color: #F4E0FF;
}
.overlay-container__title--lightBeige {
  color: #F8EEE8;
}
.overlay-container__smoke {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99999;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: auto;
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.overlay-container__smoke::-webkit-scrollbar {
  width: 0 !important;
}
.overlay-container--sliding .overlay-container__smoke {
  pointer-events: none;
  display: block;
  background-color: transparent;
  transition: background-color 0.2s;
}
.overlay-container--sliding.overlay-container--edit-mode .overlay-container__smoke {
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: auto;
}
@media (min-width: 601px) {
  .overlay-container__smoke {
    padding: 0 30px;
  }
}
.overlay-container--open .overlay-container__smoke {
  display: block;
}
.overlay-container--open.overlay-container--sliding .overlay-container__smoke {
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: auto;
  z-index: 100000;
}
.overlay-container__modal {
  z-index: 2001;
  position: relative;
  padding: 20px 20px;
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 4px;
}
.overlay-container__modal--white {
  background-color: #ffffff;
}
.overlay-container__modal--grey10 {
  background-color: #F9F9F9;
}
.overlay-container__modal--grey20 {
  background-color: #F2F2F2;
}
.overlay-container__modal--grey100 {
  background-color: #3A3A3A;
}
.overlay-container__modal--black {
  background-color: #000000;
}
.overlay-container__modal--deepPurple {
  background-color: #29003e;
}
.overlay-container__modal--deepBeige {
  background-color: #5C524E;
}
.overlay-container__modal--beige {
  background-color: #ECDED4;
}
.overlay-container__modal--lightPurple {
  background-color: #F4E0FF;
}
.overlay-container__modal--lightBeige {
  background-color: #F8EEE8;
}
@media (min-width: 601px) {
  .overlay-container__modal {
    margin: 10% auto;
    border-radius: 8px;
    padding: 24px 24px;
  }
  .overlay-container__modal--small {
    max-width: 400px;
  }
  .overlay-container__modal--medium {
    max-width: 620px;
  }
  .overlay-container__modal--large {
    max-width: 1064px;
  }
}
.overlay-container--sliding .overlay-container__modal {
  position: absolute;
  transition: right 0.7s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
  width: 100%;
  right: calc(-1 * (100% + 41px));
  display: flex;
  flex-direction: row;
  box-shadow: none;
}
@media (min-width: 601px) {
  .overlay-container--sliding .overlay-container__modal {
    border-radius: 8px 0 0 8px;
  }
  .overlay-container--sliding .overlay-container__modal--small {
    right: calc(-1 * (400px + 41px));
  }
  .overlay-container--sliding .overlay-container__modal--medium {
    right: calc(-1 * (620px + 41px));
  }
  .overlay-container--sliding .overlay-container__modal--large {
    right: calc(-1 * (1064px + 41px));
  }
}
.overlay-container--sliding .overlay-container__modal .overlay-container__content {
  width: 100%;
}
.overlay-container--sliding.overlay-container--edit-mode .overlay-container__modal {
  position: relative;
  right: auto;
}
.overlay-container--sliding.overlay-container--open .overlay-container__modal {
  right: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.3) 1px 1px 4px;
}
.overlay-container__modal .image-element {
  min-height: 1px;
}
.overlay-container__sliding-button-container {
  width: 0px;
  margin-top: 20vh;
}
@media (min-width: 600px) {
  .overlay-container__sliding-button-container-custom-5 {
    margin-top: 5px !important;
  }
}
@media (min-width: 600px) {
  .overlay-container__sliding-button-container-custom-10 {
    margin-top: 10px !important;
  }
}
@media (min-width: 600px) {
  .overlay-container__sliding-button-container-custom-15 {
    margin-top: 15px !important;
  }
}
@media (min-width: 600px) {
  .overlay-container__sliding-button-container-custom-20 {
    margin-top: 20px !important;
  }
}
@media (min-width: 600px) {
  .overlay-container__sliding-button-container-custom-25 {
    margin-top: 25px !important;
  }
}
@media (min-width: 600px) {
  .overlay-container__sliding-button-container-custom-30 {
    margin-top: 30px !important;
  }
}
@media (min-width: 600px) {
  .overlay-container__sliding-button-container-custom-35 {
    margin-top: 35px !important;
  }
}
@media (min-width: 600px) {
  .overlay-container__sliding-button-container-custom-40 {
    margin-top: 40px !important;
  }
}
.overlay-container__sliding-button-container-custom-45 {
  margin-top: 20vh !important;
}
@media (min-width: 600px) {
  .overlay-container__sliding-button-container-custom-45 {
    margin-top: 45px !important;
  }
}
@media (min-width: 600px) {
  .overlay-container__sliding-button-container-custom-50 {
    margin-top: 50px !important;
  }
}
.overlay-container__sliding-button {
  white-space: nowrap;
  border-radius: 16px 0 0 16px;
  text-transform: uppercase;
  font-size: 12px;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  transform: translateX(-56px);
  pointer-events: auto !important;
  cursor: pointer;
  display: inline;
  width: 36px;
  box-shadow: 0 6px 24px 0 rgba(58, 58, 58, 0.12);
}
.overlay-container--sliding.overlay-container--open .overlay-container__sliding-button {
  box-shadow: none;
}
@media (min-width: 601px) {
  .overlay-container__sliding-button {
    border-radius: 14px 0 0 14px;
    transform: translateX(-72px);
    width: 48px;
  }
  .overlay-container--sliding.overlay-container--open .overlay-container__sliding-button {
    width: 40px;
    transform: translateX(-64px);
  }
}
.overlay-container__sliding-button--purple5 {
  background-color: #9B009B;
  color: #ffffff;
}
.overlay-container__sliding-button--darkPink {
  background-color: #D22DB9;
  color: #ffffff;
}
.overlay-container__sliding-button--purple {
  background-color: #990AE3;
  color: #ffffff;
}
.overlay-container__sliding-button--black {
  background-color: #000000;
  color: #ffffff;
}
.overlay-container__sliding-button--grey30 {
  background-color: #E0E0E0;
  color: #000000;
}
.overlay-container__sliding-button--grey40 {
  background-color: #C5C5C5;
  color: #000000;
}
.overlay-container__sliding-button--grey50 {
  background-color: #A0A0A0;
  color: #ffffff;
}
.overlay-container__sliding-button--grey70 {
  background-color: #707070;
  color: #ffffff;
}
.overlay-container__sliding-button .overlay-container__slide-open, .overlay-container__sliding-button .overlay-container__slide-close {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  color: inherit;
  padding: 15px;
  padding-right: 8px;
  align-items: center;
  font-weight: 500;
}
.overlay-container__sliding-button .overlay-container__slide-open > svg, .overlay-container__sliding-button .overlay-container__slide-close > svg {
  font-size: 16px;
}
@media (min-width: 601px) {
  .overlay-container__sliding-button .overlay-container__slide-open, .overlay-container__sliding-button .overlay-container__slide-close {
    padding: 15px;
  }
}
.overlay-container__sliding-button .overlay-container__slide-open {
  display: inline-flex;
}
.overlay-container__sliding-button .overlay-container__slide-close {
  display: none;
}
.overlay-container--sliding.overlay-container--open .overlay-container__sliding-button .overlay-container__slide-open {
  display: none;
}
.overlay-container--sliding.overlay-container--open .overlay-container__sliding-button .overlay-container__slide-close {
  display: inline-flex;
  padding: 15px 12px;
}
.overlay-container__slide-button-text {
  display: none;
}
@media (min-width: 601px) {
  .overlay-container__slide-button-text {
    margin-top: 8px;
    line-height: 0;
    display: inline;
  }
}
.overlay-container__content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.overlay-container__content--extra-vertical-padding-none {
  padding: 0;
}
.overlay-container__content--extra-vertical-padding-small {
  padding: 16px 0;
}
.overlay-container__content--extra-vertical-padding-medium {
  padding: 24px 0;
}
.overlay-container__content--extra-vertical-padding-large {
  padding: 32px 0;
}
.overlay-container__close-button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -13px;
  margin-bottom: 5px;
}
.overlay-container__close-button {
  background: none;
  border: none;
  z-index: 2002;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  color: #C5C5C5;
  padding: 13px;
  margin-right: -13px;
}
@media (min-width: 601px) {
  .overlay-container__close-button {
    font-size: 21px;
  }
}
.overlay-container__close-button:hover {
  color: #000000;
}

.person-list {
  margin-top: 45px;
}
.person-list__picture {
  width: 100%;
  height: 24em;
  max-height: 84vw;
  overflow: hidden;
}
@media (min-width: 841px) {
  .person-list__picture {
    height: 24vw;
    max-height: none;
  }
}
.person-list__person {
  margin-bottom: 35px;
}
@media (min-width: 841px) {
  .person-list__person {
    margin-bottom: 55px;
  }
}
.person-list__person:hover .person-list__details {
  color: #990AE3;
  cursor: pointer;
}
.person-list__person:hover .person-list__details h4, .person-list__person:hover .person-list__details .person-list__description {
  color: #990AE3;
}
.person-list__description {
  color: #000000;
}
.person-list__image {
  object-fit: cover;
  display: block;
  width: auto;
  margin: auto;
  border-radius: 50%;
  height: 100%;
}
.person-list__details {
  overflow: hidden;
  padding: 1em;
}
@media (min-width: 601px) {
  .person-list__details {
    margin-left: 25px;
  }
}
.person-list__name {
  font-size: 20px;
  margin-bottom: 6px;
}
.person-list__back-icon {
  vertical-align: middle;
}
.person-list__read-more {
  position: relative;
  top: 5px;
}
.person-list__read-more:hover {
  color: #CC00FF;
}

.person {
  margin-top: 45px;
  margin-bottom: 55px;
  -ms-flex-negative: 0;
}
.person__name {
  margin-top: 30px;
}
.person__picture {
  margin-top: 35px;
  -ms-flex-negative: 0;
}
@media (min-width: 841px) {
  .person__picture {
    width: 70%;
  }
}
.person__image {
  width: 100%;
  object-fit: cover;
  margin: auto;
  border-radius: 50%;
  display: block;
}
.person__ingress {
  font-size: 12px;
  letter-spacing: 1.56px;
  line-height: 16px;
  text-transform: uppercase;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  top: 28px;
}
.person__back-icon {
  vertical-align: middle;
}

.cms-personalized-card-list__loader {
  text-align: center;
  padding: 30px;
}
.cms-personalized-card-list__loader--hidden {
  display: none;
}
.cms-personalized-card-list__gridbag-gutter-simulator {
  margin-bottom: 12px;
}
@media (min-width: 601px) {
  .cms-personalized-card-list__gridbag-gutter-simulator {
    margin-bottom: 16px;
  }
}
@media (min-width: 1081px) {
  .cms-personalized-card-list__gridbag-gutter-simulator {
    margin-bottom: 24px;
  }
}

.prepaid-price-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;
}
@media (max-width: 840px) {
  .prepaid-price-list--column-title {
    align-items: center;
  }
}
.prepaid-price-list__row-title {
  flex-shrink: 0;
  display: flex;
  background-color: #ffffff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 2px;
}
@media (min-width: 601px) {
  .prepaid-price-list__row-title {
    display: none;
  }
}
.prepaid-price-list__price-row {
  flex-shrink: 0;
  display: flex;
  background-color: #ffffff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-right: 10px;
  min-width: 100%;
  margin: 0 0 5px 0;
  padding: 1em 0.5em 1em 1em;
}
.prepaid-price-list__price-row:first-child {
  width: 7em;
  border-radius: 10px;
  position: sticky;
  top: 0;
}
@media (min-width: 601px) {
  .prepaid-price-list__price-row {
    padding: 1em;
    border-radius: 10px;
    align-items: center;
    min-height: 60px;
  }
  .prepaid-price-list__price-row:first-child {
    position: static;
  }
}
.prepaid-price-list__first-column {
  width: 12em;
  display: none;
}
@media (min-width: 601px) {
  .prepaid-price-list__first-column {
    display: block;
    width: 40%;
  }
}
.prepaid-price-list__row-product {
  display: flex;
  align-items: center;
  padding: 0.5em;
}
@media (min-width: 601px) {
  .prepaid-price-list__row-product {
    padding: 0;
  }
}
.prepaid-price-list__row-image {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  min-width: 32px;
  width: 32px;
  height: 32px;
}
.prepaid-price-list__text-column {
  margin: 0em 0em 0em 1em;
}
.prepaid-price-list__price-column {
  width: 50%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 601px) {
  .prepaid-price-list__price-column {
    width: 30%;
  }
}
.prepaid-price-list__price {
  display: flex;
}
.prepaid-price-list__price-value {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 34px;
}
.prepaid-price-list__price-string {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 24px;
}
@media (min-width: 601px) {
  .prepaid-price-list__price-string {
    font-size: 34px;
  }
}
.prepaid-price-list__price-unit {
  line-height: 14px;
  font-size: 12px;
  margin-bottom: -4px;
}
.prepaid-price-list__additional-unit {
  margin-top: 4px;
  font-size: 12px;
}
.prepaid-price-list__smallprint {
  font-size: 12px;
  color: #A0A0A0;
  margin-top: -2px;
}

.price-bubble {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background-color: #FF9B00;
  color: #ffffff;
}
.price-bubble__heading {
  font-size: 12px;
  line-height: 1;
}
.price-bubble__price {
  font-size: 20px;
  line-height: 1;
  font-weight: bold;
  margin: 3px 0 4px;
}

.price-list {
  margin: 2em 0;
  border-top: 1px solid #E0E0E0;
}
.price-list__single {
  display: none;
}
.price-list__single--visible {
  display: block !important;
}
.price-list__single--visible .price-list__content {
  max-height: none !important;
}
.price-list__content {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  transition: padding-top 0.4s, padding-bottom 0.4s;
  overflow: hidden;
}
.price-list__content-table {
  width: 100%;
  border-collapse: collapse;
}
.price-list__content-row--data-header {
  font-weight: 700;
}
.price-list__content-row--small {
  font-size: 14px;
}
.price-list__content-key {
  width: 60%;
}
.price-list__content-value {
  width: 40%;
}
.price-list__extra {
  padding: 0 1.125em;
  margin: 1em 0;
}
.price-list__countries {
  padding: 0 1.125em;
  margin: 1em 0;
}
.price-list__countries p {
  font-size: 10px;
  line-height: 15px;
  padding: 0;
}
.price-list__container {
  display: block;
  position: relative;
}
.price-list__container--hidden {
  display: none !important;
}
.price-list__item--open .price-list__content {
  padding-top: 1em;
  padding-bottom: 1em;
  max-height: none;
  border-bottom: 1px solid #E0E0E0;
}
.price-list__item {
  float: none;
  background: none;
}
.price-list__heading {
  display: block;
  font-weight: 500;
  width: 100%;
  border-bottom: 1px solid #E0E0E0;
  font-size: 18px;
  padding: 1em 2em 1em 0;
  cursor: pointer;
  margin: 0;
  user-select: none;
  position: relative;
}
.price-list__navbar {
  display: none;
}
.price-list__icon {
  position: absolute;
  display: block;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 16px;
  margin-top: -10px;
  transition: transform 0.2s;
}
.price-list__icon--rotate {
  transform: rotate(180deg);
}
.price-list__icon::before {
  font-size: 15px;
  color: #990AE3;
}

.price-component--color-inherit {
  color: inherit;
}
.price-component--color-black {
  color: #000000;
}
.price-component--color-white {
  color: #ffffff;
}
.price-component__prefix-text {
  font-size: 16px;
  line-height: 16px;
  margin-right: 2px;
}
.price-component--bigger .price-component__prefix-text {
  font-size: 14px;
  font-weight: bold;
}
.price-component__old-price {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  text-decoration: line-through;
  font-size: 20px;
  line-height: 20px;
  margin-right: 2px;
  color: #A0A0A0;
}
.price-component--bigger .price-component__old-price {
  font-size: 24px;
  line-height: 24px;
}
.price-component__single-line-unit {
  font-size: 16px;
  line-height: 16px;
  margin-left: -3px;
}
.price-component--bigger .price-component__single-line-unit {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin-left: -7px;
  font-size: 24px;
  line-height: 24px;
}
.price-component__value {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 34px;
}
.price-component--bigger .price-component__value {
  font-size: 48px;
  line-height: 48px;
}
.price-component__unit {
  font-size: 14px;
  margin-left: -4px;
}
.price-component--bigger .price-component__unit {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin-left: -8px;
  font-size: 16px;
  line-height: 16px;
}
.price-component__stacked-unit {
  display: inline-block;
  line-height: 14px;
  font-size: 12px;
  width: 12px;
  margin-left: -3px;
}
.price-component--bigger .price-component__stacked-unit {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin-left: -6px;
  font-size: 16px;
  line-height: 16px;
}

.price {
  height: 1em;
  display: inline-block;
}
.price:not(.price--normal) + .price--normal {
  margin-left: 0.3529411765em;
}
.price--normal {
  color: #C5C5C5;
  font-size: 0.7058823529em;
  position: relative;
}
.price--normal::after {
  content: "";
  position: absolute;
  top: 75%;
  left: 0;
  width: 100%;
  border-bottom: 1px solid #C5C5C5;
}
.price--out-of-stock {
  color: #A0A0A0;
}
.price__amount {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1em;
  letter-spacing: -0.0220588235em;
}
.price__extra-info {
  font-size: 0.4705882353em;
}
.price__unit {
  font-size: 0.4705882353em;
  margin-left: -0.0294117647em;
}
.price__stacked-unit {
  display: inline-block;
  height: 2em;
  line-height: 1em;
  font-size: 0.4117647059em;
  width: 1em;
  text-align: left;
  margin-left: -0.0294117647em;
}
.price--dynamic:not(.price--default) {
  display: none;
}
.price--smallprint {
  font-size: 14px;
  color: #A0A0A0;
  margin-left: 0 !important;
  display: block;
  height: auto;
}

.product-card {
  padding-bottom: 2px;
  box-sizing: border-box;
  position: relative;
  min-height: 200px;
  /* Right border */
  /* Bottom border */
}
.product-card:hover {
  z-index: 1;
}
.product-card:before {
  content: " ";
  background-color: #F9F9F9;
  position: absolute;
  right: 0;
  width: 2px;
  z-index: 1;
}
.product-card:hover:before {
  display: none !important;
}
.product-card:after {
  content: " ";
  background-color: #F9F9F9;
  position: absolute;
  left: 24px;
  right: 24px;
  bottom: 0;
  height: 2px;
  z-index: 2;
}
@media (min-width: 841px) {
  .product-card:after {
    display: none;
  }
}
[class*=grid__col-]:nth-child(4n) > .product-card:before {
  display: none;
}
[class*=grid__col-]:last-child > .product-card:before {
  display: none;
}
[class*=grid__col-]:last-child > .product-card:after {
  display: none;
}
@media (min-width: 481px) and (max-width: 840px) {
  [class*=grid__col-]:nth-last-child(2):not(:nth-child(2n)) > .product-card:after {
    display: none;
  }
  [class*=grid__col-]:nth-child(2n) > .product-card:before {
    display: none;
  }
}
.carousel [class*=grid__col-]:not(:last-child) .product-card:before {
  display: block;
}
.carousel .product-card:after {
  display: none;
}
.product-card:hover .product-card:after {
  display: none;
}
@media (min-width: 601px) {
  .product-card {
    transform: translateX(1px);
    padding-right: 2px;
    height: 100%;
  }
}
@media (max-width: 600px) {
  .product-card {
    width: 100%;
  }
  .product-card:before {
    display: none;
  }
}
@media (min-width: 601px) and (max-width: 1920px) {
  .product-card {
    min-height: 406px;
  }
  .product-card:before {
    top: 24px;
    bottom: 26px;
  }
}
@media (min-width: 1441px) {
  .product-card {
    min-height: 534px;
  }
  .product-card:before {
    top: 32px;
    bottom: 40px;
  }
}
@media (min-width: 601px) and (max-width: 1920px) {
  .product-card--with-button {
    min-height: 482px;
  }
}
@media (min-width: 1441px) {
  .product-card--with-button {
    min-height: 534px;
  }
}
.product-card__container {
  width: calc(100% - 2px);
  position: relative;
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease 0s, height 0.2s ease 0s;
  border-radius: 4px;
  background-color: #ffffff;
}
.product-card--lightGrey .product-card__container {
  background-color: #F2F2F2;
}
.product-card--deepPurple .product-card__container {
  background-color: #29003e;
}
.product-card--deepBeige .product-card__container {
  background-color: #5C524E;
}
.product-card--lightPurple .product-card__container {
  background-color: #F4E0FF;
}
.product-card--lightBeige .product-card__container {
  background-color: #F8EEE8;
}
@media (min-width: 601px) {
  .product-card__container {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}
@media (max-width: 600px) {
  .product-card__container {
    padding: 32px 0;
  }
}
@media (min-width: 481px) and (max-width: 840px) {
  .product-card__container {
    padding: 32px 40px;
  }
}
@media (min-width: 601px) and (max-width: 1920px) {
  .product-card__container {
    padding: 24px 24px 26px;
    border-radius: 2px;
  }
  .product-card--with-button .product-card__container {
    padding: 24px 23px 32px;
  }
}
@media (min-width: 1441px) {
  .product-card__container {
    padding: 32px 24px 40px;
    border-radius: 2px;
  }
}
@media (min-width: 601px) {
  .product-card__container:hover {
    transform: scale(1.03);
    box-shadow: rgba(1, 1, 1, 0.2) 0 1px 4px;
    z-index: 3;
  }
}
.product-card--button-on-hover .product-card__container:hover {
  transition: transform 0.2s ease 0s, height 0.4s ease 0.1s;
}
@media (min-width: 601px) and (max-width: 1920px) {
  .product-card--button-on-hover .product-card__container:hover {
    height: 470px;
  }
}
@media (min-width: 1441px) {
  .product-card--button-on-hover .product-card__container:hover {
    height: 598px;
  }
}
.product-card__brand {
  color: #A0A0A0;
  margin-left: 0;
  margin-right: 0;
}
@media (max-width: 600px) {
  .product-card__brand {
    padding-left: calc(50% + 6px);
  }
}
@media (min-width: 601px) {
  .product-card__brand {
    text-align: center;
  }
}
@media (max-width: 1080px) {
  .product-card__brand {
    margin-top: -6px;
    margin-bottom: 0;
    line-height: 16px;
    font-size: 14px;
    overflow: hidden;
    transform: translateY(3px);
  }
}
@media (max-width: 1080px) and (min-width: 601px) {
  .product-card__brand {
    max-height: 16px;
  }
}
@media (min-width: 841px) and (max-width: 1920px) {
  .product-card__brand {
    margin-top: -6px;
    margin-bottom: 0;
    line-height: 16px;
    font-size: 14px;
    min-height: 16px;
    overflow: hidden;
    transform: translateY(3px);
  }
}
@media (min-width: 841px) and (max-width: 1920px) and (min-width: 601px) {
  .product-card__brand {
    max-height: 16px;
  }
}
@media (min-width: 1441px) {
  .product-card__brand {
    margin-top: -6px;
    margin-bottom: 0;
    line-height: 18px;
    font-size: 16px;
    min-height: 18px;
    overflow: hidden;
    transform: translateY(3px);
  }
}
@media (min-width: 1441px) and (min-width: 601px) {
  .product-card__brand {
    max-height: 18px;
  }
}
.product-card__name {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  letter-spacing: -0.0083333333em;
  min-height: unset !important;
  word-wrap: break-word;
  margin-left: 0;
  margin-right: 0;
}
@media (max-width: 600px) {
  .product-card__name {
    padding-left: calc(50% + 6px);
  }
}
@media (min-width: 601px) {
  .product-card__name {
    text-align: center;
  }
}
@media (max-width: 1080px) {
  .product-card__name {
    margin-top: 8px;
    margin-bottom: 0;
    line-height: 20px;
    font-size: 20px;
    min-height: 20px;
    overflow: hidden;
    transform: translateY(3px);
    margin-bottom: 8px;
  }
}
@media (max-width: 1080px) and (min-width: 601px) {
  .product-card__name {
    max-height: 60px;
  }
}
@media (min-width: 841px) and (max-width: 1920px) {
  .product-card__name {
    margin-top: 10px;
    margin-bottom: 0;
    line-height: 20px;
    font-size: 20px;
    min-height: 40px;
    overflow: hidden;
    transform: translateY(3px);
    margin-bottom: 10px;
  }
}
@media (min-width: 841px) and (max-width: 1920px) and (min-width: 601px) {
  .product-card__name {
    max-height: 40px;
  }
}
@media (min-width: 1441px) {
  .product-card__name {
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 22px;
    font-size: 24px;
    min-height: 44px;
    overflow: hidden;
    transform: translateY(2px);
    margin-bottom: 20px;
  }
}
@media (min-width: 1441px) and (min-width: 601px) {
  .product-card__name {
    max-height: 44px;
  }
}
@media (min-width: 1441px) {
  .product-card__name + .product-card__image {
    margin-top: 20px;
  }
}
.product-card__overlay-link {
  position: absolute;
  display: block;
  overflow: hidden;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-indent: -10000px;
  z-index: 1;
}
.product-card--app .product-card__description-container, .product-card--pseudo .product-card__description-container {
  max-height: 54px;
  height: 54px;
  margin-bottom: 6px;
  overflow: hidden;
}
.product-card__selling-points {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0px;
  margin-bottom: 0;
  line-height: 16px;
  font-size: 14px;
  min-height: 16px;
  overflow: hidden;
  transform: translateY(3px);
}
@media (max-width: 600px) {
  .product-card__selling-points {
    padding-left: calc(50% + 6px);
  }
}
@media (min-width: 601px) {
  .product-card__selling-points {
    text-align: center;
  }
}
@media (min-width: 601px) {
  .product-card__selling-points {
    max-height: 16px;
  }
}
.product-card__descriptor {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0px;
  margin-bottom: 0;
  line-height: 16px;
  font-size: 14px;
  min-height: 16px;
  overflow: hidden;
  transform: translateY(3px);
  color: #A0A0A0;
}
@media (max-width: 600px) {
  .product-card__descriptor {
    padding-left: calc(50% + 6px);
  }
}
@media (min-width: 601px) {
  .product-card__descriptor {
    text-align: center;
  }
}
@media (min-width: 601px) {
  .product-card__descriptor {
    max-height: 16px;
  }
}
.product-card__descriptor p {
  font-size: 14px;
  margin: 0 auto;
  line-height: inherit;
}
.product-card--app .product-card__descriptor, .product-card--pseudo .product-card__descriptor {
  max-height: 54px;
  height: 54px;
  line-height: 18px;
}
.product-card--app .product-card__descriptor {
  color: #000000;
}
.product-card__image {
  margin: 0;
}
@media (max-width: 600px) {
  .product-card__image {
    position: absolute;
    top: 32px;
    left: 5vw;
    bottom: 32px;
    right: calc(50% + 6px);
  }
  .product-card--with-button .product-card__image {
    height: 136px;
    bottom: auto;
  }
}
@media (min-width: 601px) {
  .product-card__image {
    margin-top: 16px;
    position: relative;
    flex-grow: 1;
  }
}
.product-card--app .product-card__image, .product-card--pseudo .product-card__image {
  margin-top: 0;
}
.product-card__image img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  transform: translateX(-50%) translateY(-50%);
}
.product-card--app .product-card__image img, .product-card--pseudo .product-card__image img {
  max-height: 104px;
}
.product-card__tag {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
}
@media (max-width: 600px) {
  .product-card__tag {
    padding-left: calc(50% + 6px);
  }
}
@media (min-width: 601px) {
  .product-card__tag {
    text-align: center;
  }
}
@media (max-width: 840px) {
  .product-card__tag {
    margin-top: 20px;
  }
}
@media (min-width: 601px) {
  .product-card__tag {
    height: 28px;
  }
}
@media (min-width: 601px) and (max-width: 1920px) {
  .product-card__tag {
    margin-top: 24px;
  }
}
@media (min-width: 1441px) {
  .product-card__tag {
    margin-top: 32px;
  }
}
.product-card--with-button .product-card__tag {
  margin-top: 12px;
}
.product-card__price {
  margin-left: 0;
  margin-right: 0;
}
@media (max-width: 600px) {
  .product-card__price {
    padding-left: calc(50% + 6px);
  }
}
@media (min-width: 601px) {
  .product-card__price {
    text-align: center;
  }
}
@media (max-width: 1080px) {
  .product-card__price {
    margin-top: 2px;
    margin-bottom: 0;
    line-height: 27px;
    font-size: 25px;
    min-height: 27px;
    transform: translateY(5px);
  }
}
@media (min-width: 841px) and (max-width: 1920px) {
  .product-card__price {
    margin-top: 6px;
    margin-bottom: 0;
    line-height: 27px;
    font-size: 25px;
    min-height: 27px;
    transform: translateY(5px);
  }
}
@media (min-width: 1441px) {
  .product-card__price {
    margin-top: 6px;
    margin-bottom: 0;
    line-height: 34px;
    font-size: 34px;
    min-height: 34px;
    transform: translateY(5px);
  }
}
.product-card__button {
  margin-top: 20px;
  height: 44px;
  text-align: center;
  transition: height 0.2s ease 0s, margin 0.2s ease 0s, opacity 0s;
}
@media (min-width: 601px) {
  .product-card__button {
    margin-left: -24px;
    margin-right: -24px;
  }
}
.product-card__button .button {
  margin: 0;
  z-index: 2;
}
.product-card--button-on-hover .product-card__button {
  margin-top: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  order: 100;
}
@media (min-width: 601px) {
  .product-card--button-on-hover .product-card__container:hover .product-card__button {
    margin-top: 8px;
    margin-bottom: 12px;
    height: 44px;
    opacity: 1;
    overflow: visible;
    transition: height 0.4s ease 0.1s, margin 0.4s ease 0.1s, opacity 0.3s ease 0.3s;
  }
}
.product-card__store-icons {
  display: none;
}
.product-card__store-icons .product-card__button:not(:first-child) {
  margin-top: 5px;
}
.product-card__smallprint {
  color: #A0A0A0;
  margin-left: 0;
  margin-right: 0;
}
@media (max-width: 600px) {
  .product-card__smallprint {
    padding-left: calc(50% + 6px);
  }
}
@media (min-width: 601px) {
  .product-card__smallprint {
    text-align: center;
  }
}
@media (max-width: 840px) {
  .product-card__smallprint {
    margin-top: 3px;
    margin-bottom: 0;
    line-height: 18px;
    font-size: 12px;
    overflow: hidden;
    transform: translateY(4.5px);
  }
}
@media (max-width: 840px) and (min-width: 601px) {
  .product-card__smallprint {
    max-height: 36px;
  }
}
@media (max-width: 840px) {
  .product-card--with-button .product-card__smallprint {
    padding-left: 0;
    text-align: center;
  }
}
@media (min-width: 601px) and (max-width: 1920px) {
  .product-card__smallprint {
    margin-top: 7px;
    margin-bottom: 0;
    line-height: 18px;
    font-size: 12px;
    min-height: 36px;
    overflow: hidden;
    transform: translateY(4.5px);
  }
}
@media (min-width: 601px) and (max-width: 1920px) and (min-width: 601px) {
  .product-card__smallprint {
    max-height: 36px;
  }
}
@media (min-width: 601px) and (max-width: 1920px) {
  .product-card--with-button .product-card__smallprint {
    margin-top: 13px;
    margin-bottom: 0;
    line-height: 18px;
    font-size: 12px;
    min-height: 36px;
    overflow: hidden;
    transform: translateY(4.5px);
  }
}
@media (min-width: 601px) and (max-width: 1920px) and (min-width: 601px) {
  .product-card--with-button .product-card__smallprint {
    max-height: 36px;
  }
}
@media (min-width: 1441px) {
  .product-card__smallprint {
    margin-top: 12px;
    margin-bottom: 0;
    line-height: 20px;
    font-size: 12px;
    min-height: 40px;
    overflow: hidden;
    transform: translateY(4px);
  }
}
@media (min-width: 1441px) and (min-width: 601px) {
  .product-card__smallprint {
    max-height: 40px;
  }
}
@media (min-width: 1441px) {
  .product-card--with-button .product-card__smallprint {
    margin-top: 13px;
    margin-bottom: 0;
    line-height: 18px;
    font-size: 12px;
    min-height: 36px;
    overflow: hidden;
    transform: translateY(4.5px);
  }
}
@media (min-width: 1441px) and (min-width: 601px) {
  .product-card--with-button .product-card__smallprint {
    max-height: 36px;
  }
}
.product-card--small {
  margin: 0 auto;
  padding-right: 0;
}
.product-card--small .product-card__image {
  position: relative;
  flex-grow: 0;
  height: 136px;
}
@media (max-width: 600px) {
  .product-card--small .product-card__image {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
@media (min-width: 601px) {
  .product-card--small .product-card__image {
    margin-top: 16px;
  }
}
.product-card--small .product-card__image img:first-child {
  flex-grow: 0;
  position: static;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 138px;
  transform: none;
}
.product-card--small .product-card__brand {
  padding: 0;
}
@media (max-width: 1080px) {
  .product-card--small .product-card__brand {
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 20px;
    font-size: 14px;
    min-height: 20px;
    transform: translateY(0);
  }
}
@media (min-width: 841px) and (max-width: 1920px) {
  .product-card--small .product-card__brand {
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 20px;
    font-size: 14px;
    min-height: 20px;
    transform: translateY(0);
  }
}
@media (min-width: 1441px) {
  .product-card--small .product-card__brand {
    margin-top: 20px;
    margin-bottom: 0;
    line-height: 22px;
    font-size: 14px;
    min-height: 22px;
    transform: translateY(0);
  }
}
.product-card--small .product-card__name {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-weight: bold;
  text-transform: none;
  margin-bottom: 20px;
  padding: 0;
  text-align: center;
}
@media (max-width: 1080px) {
  .product-card--small .product-card__name {
    margin-top: -1px;
    margin-bottom: 0;
    line-height: 22px;
    font-size: 16px;
    min-height: 22px;
    transform: translateY(3px);
  }
}
@media (min-width: 841px) and (max-width: 1920px) {
  .product-card--small .product-card__name {
    margin-top: -1px;
    margin-bottom: 0;
    line-height: 22px;
    font-size: 16px;
    min-height: 44px;
    transform: translateY(3px);
  }
}
@media (min-width: 1441px) {
  .product-card--small .product-card__name {
    margin-top: -1px;
    margin-bottom: 0;
    line-height: 22px;
    font-size: 16px;
    min-height: 44px;
    transform: translateY(3px);
  }
}
@media (min-width: 601px) {
  .product-card--small {
    transform: translateX(1px);
    padding-right: 0;
    height: 100%;
    min-height: 246px;
  }
  .product-card--small:before {
    top: 24px;
    bottom: 26px;
  }
}
@media (min-width: 1441px) {
  .product-card--small {
    min-height: 294px;
  }
  .product-card--small:before {
    top: 32px;
    bottom: 40px;
  }
}
.product-card--small .product-card__container {
  align-items: center;
  position: relative;
  height: 100%;
  padding: 30px;
}
@media (min-width: 601px) {
  .product-card--small .product-card__container {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
}
@media (max-width: 600px) {
  .product-card--small .product-card__container {
    padding: 30px 30px;
  }
}
@media (min-width: 481px) and (max-width: 840px) {
  .product-card--small .product-card__container {
    padding: 30px 30px;
  }
}
@media (min-width: 601px) and (max-width: 1920px) {
  .product-card--small .product-card__container {
    padding: 3px 3px;
    border-radius: 2px;
  }
}
@media (min-width: 1441px) {
  .product-card--small .product-card__container {
    padding: 30px 30px;
    border-radius: 2px;
  }
}
.product-card .splash {
  position: absolute;
  right: -30px;
  top: 22px;
}
.product-card__splash-container {
  margin: 0;
  height: 100%;
  left: 50%;
  max-width: 100%;
  position: absolute;
  transform: translateX(-50%);
  width: 136px;
  top: 0;
}
@media (min-width: 1441px) {
  .product-card__splash-container {
    width: 198px;
  }
}
.product-card__splash {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 64px;
  height: 64px;
  right: 0;
  background-color: #000000;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
@media (min-width: 601px) {
  .product-card__splash {
    width: 80px;
    height: 80px;
    right: -20px;
  }
}
.product-card__splash--orange {
  background-color: #FF9B00;
}
.product-card__splash-text {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  padding: 10px;
  font-size: 12px;
  line-height: 13px;
}
@media (min-width: 601px) {
  .product-card__splash-text {
    line-height: 15px;
    font-size: 14px;
  }
}

.tag {
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  padding: 5px 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
}
.tag--campaign {
  color: #E4175C;
  border-color: #E4175C;
}
.tag--info {
  color: #990AE3;
  border-color: #990AE3;
}
.tag--new {
  color: #0099ff;
  border-color: #0099ff;
}
.tag--price-campaign {
  color: #FF9B00;
  border-color: #FF9B00;
}
.tag--out-of-stock {
  color: #A0A0A0;
  border-color: #A0A0A0;
}

.product-selector {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
}
@media (max-width: 600px) {
  .product-selector__container {
    padding: 0px;
  }
}
.product-selector__loader-container {
  margin: 120px 0;
  text-align: center;
}
.product-selector__box-radio-heading {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 24px;
}
.product-selector__box-radio-description {
  font-size: 12.5px;
}
.product-selector__box-radio-input {
  display: none;
}
.product-selector__box-radio-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -2%;
  margin-bottom: -10px;
}
@media (min-width: 1441px) {
  .product-selector__box-radio-container {
    margin-right: -1%;
  }
}
.product-selector__box-radio-label {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  height: 85px;
  background-color: #ffffff;
  border-radius: 3px;
  width: 48%;
  margin-bottom: 10px;
  margin-right: 2%;
  color: #A0A0A0;
  transition: color 0.2s;
}
@media (min-width: 1441px) {
  .product-selector__box-radio-label {
    width: 24%;
    margin-right: 1%;
  }
}
.product-selector__box-radio-label:hover {
  color: #990AE3;
}
.product-selector__box-radio-input:checked + .product-selector__box-radio-label {
  color: #990AE3;
  border: solid #990AE3 4px;
}
.product-selector__list--no-format {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
}
.product-selector__header-bar-heading {
  display: inline;
}
.product-selector__header-bar {
  vertical-align: middle;
  width: 100%;
}
.product-selector__header-bar--margin-large {
  text-align: center;
  margin-top: 45px;
  margin-bottom: 15px;
}
@media (min-width: 601px) {
  .product-selector__header-bar--margin-large {
    text-align: left;
  }
}
.product-selector__header-bar--margin-bottom-only {
  margin-bottom: 16px;
  margin-top: 0px;
}
.product-selector__header-bar .button {
  display: none;
}
@media (min-width: 601px) {
  .product-selector__header-bar .button {
    display: block;
    float: right;
  }
}
.product-selector__category-container {
  background: #F9F9F9;
  padding: 24px;
}
.product-selector__category-container--dark-grey {
  background: #F2F2F2;
}
.product-selector__category-container--border-bottom {
  border-bottom: 1px solid #C5C5C5;
}
.product-selector__category-container--centered {
  text-align: center;
}
.product-selector__category-container--margin-bottom {
  margin-bottom: 8px;
}
@media (min-width: 1081px) {
  .product-selector__category-container--padding-right {
    padding-right: 4px;
  }
}
@media (min-width: 1081px) {
  .product-selector__category-container--padding-left {
    padding-left: 4px;
  }
}
.product-selector__classification-description {
  margin-left: 37px;
  font-size: 14px;
  line-height: 1.3;
  color: #707070;
}
.product-selector__summary-cta-detail--custom-product p {
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 36px;
}
.product-selector__summary-image-container {
  text-align: center;
}
.product-selector__summary-image {
  width: 300px;
  max-width: 100%;
  max-height: 300px;
  margin-bottom: 15px;
  object-fit: scale-down;
}
.product-selector__summary-description-container {
  min-height: 245px;
}
.product-selector__summary-description-heading, .product-selector__summary-description-additional-services-heading {
  color: #707070;
}
.product-selector__summary-description-body, .product-selector__summary-description-additional-services {
  font-size: 10px;
  color: #707070;
}
.product-selector__summary-description-body p,
.product-selector__summary-description-body li,
.product-selector__summary-description-body ul,
.product-selector__summary-description-body ol, .product-selector__summary-description-additional-services p,
.product-selector__summary-description-additional-services li,
.product-selector__summary-description-additional-services ul,
.product-selector__summary-description-additional-services ol {
  font-size: 14px;
  line-height: 1.3;
}
.product-selector__summary-description-body ul > li > ul, .product-selector__summary-description-additional-services ul > li > ul {
  margin-top: 0;
  margin-bottom: 0;
}
.product-selector__summary-heading {
  margin: 0px;
}
.product-selector__summary-heading--small {
  margin: 0px;
  font-size: 18px;
}
.product-selector__summary-cta-container {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: space-between;
  height: 170px;
}
.product-selector__summary-cta-container + .button {
  margin-top: 30px;
  line-height: 3;
}
.product-selector__summary-change-selections {
  text-align: center;
}
.product-selector__summary-change-selections .button {
  margin: 0;
}
.product-selector__form-container {
  margin-bottom: 10px;
  max-width: 600px;
}
.product-selector__form-container-padder {
  padding: 0 6px;
}
@media (min-width: 601px) {
  .product-selector__form-container-padder {
    padding: 0px;
  }
}
.product-selector__form-container .button {
  margin-top: 46px;
  margin-bottom: 26px;
}
.product-selector__form-textarea {
  margin-bottom: 35px;
}
.product-selector__form-disclaimer-checkbox + .product-selector__form-disclaimer-label p {
  margin-bottom: 0px;
  margin-top: 2px;
}
.product-selector__total-one-time-cost {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 46px;
  margin-bottom: 5px;
}
.product-selector__total-monthly-cost {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 24px;
}
.product-selector__total-monthly-cost > .price__stacked-unit {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-weight: normal;
}
.product-selector__info-icon {
  font-size: 18px;
  color: #0099ff;
  cursor: pointer;
}
.product-selector__info-icon > svg {
  pointer-events: auto;
}
@supports (-ms-ime-align: auto) {
  .product-selector__info-icon > svg {
    pointer-events: none;
  }
}
@media (min-width: 601px) {
  .product-selector__info-icon {
    position: relative;
  }
}
.product-selector__info-icon--margin-left {
  margin-left: 8px;
}
.product-selector__speech-bubble {
  font-weight: normal;
  display: none;
  padding: 4px 18px;
  position: fixed;
  top: 50%;
  width: 90%;
  color: #000000;
  background-color: #ffffff;
  box-sizing: border-box;
  transform: translateY(-50%);
  border-radius: 8px;
  user-select: none;
  cursor: default;
  z-index: 2001;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.3);
  -webkit-text-size-adjust: 100%;
}
.product-selector__speech-bubble--open {
  display: block;
}
.product-selector__speech-bubble p {
  font-size: 12px;
}
@media (min-width: 601px) {
  .product-selector__speech-bubble {
    padding: 0px 12px;
    transform: translate(11px, -50%);
    width: 250px;
    position: absolute;
    border: 3px solid #0099ff;
    border-radius: 3px;
    z-index: 150;
  }
  .product-selector__speech-bubble--open {
    display: inline;
  }
  .product-selector__speech-bubble :after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-right-color: #0099ff;
    border-left: 0;
    margin-top: -10px;
    margin-left: -11px;
  }
}
.product-selector__speech-bubble-close-button {
  float: right;
  color: white;
  font-size: 24px;
  line-height: 1px;
  margin-top: -20px;
  background-color: black;
  padding: 4px;
  border-radius: 40px;
  cursor: pointer;
}
@media (min-width: 601px) {
  .product-selector__speech-bubble-close-button {
    display: none;
  }
}
.product-selector__smoke-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2000;
}
@media (max-width: 600px) {
  .product-selector__smoke-overlay--visible {
    display: block;
  }
}
.product-selector .product-selector__additional-services-option {
  display: block;
}
.product-selector .product-selector__internet-toggle-label {
  margin: 0;
}
.product-selector__internet--excluded .product-selector__internet-toggled-content {
  display: none;
}
.product-selector__internet--excluded .product-selector__header-bar {
  margin: 0;
}

.recommendations {
  text-align: center;
  color: #000000;
  margin: 1em 0;
}
@media (min-width: 841px) {
  .recommendations {
    margin-bottom: 4em;
  }
}
.recommendations__title {
  margin-left: auto;
  margin-right: auto;
}
.recommendations__container {
  margin-top: 3em;
}
@media (min-width: 841px) {
  .recommendations__container {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
  }
}

.recommendation {
  margin-bottom: 2em;
  display: inline-block;
  width: 100%;
}
@media (min-width: 841px) {
  .recommendation {
    width: auto;
    margin: 0 1em;
  }
}
@media (min-width: 1081px) {
  .recommendation {
    margin: 0 3em;
  }
}
.recommendation__icon {
  font-size: 42px;
  margin-bottom: 6px;
}
.recommendation__title {
  margin-bottom: 6px;
}

.recruitment-process {
  overflow: hidden;
  position: relative;
}
.recruitment-process__image {
  object-fit: cover;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  overflow: hidden;
}
.recruitment-process__content {
  position: relative;
}
.recruitment-process__subheading--bold {
  font-weight: bold;
}
.recruitment-process__step {
  display: flex;
  position: relative;
  padding-top: 50px;
  padding-bottom: 50px;
}
.recruitment-process__step:not(:last-of-type)::before {
  content: "";
  position: absolute;
  top: 114px;
  left: 32px;
  height: 100px;
  width: 2px;
  background: #E0E0E0;
}
.recruitment-process__stepNumber {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-align: center;
  border-radius: 50%;
  border: 2px solid #E0E0E0;
  width: 64px;
  height: 64px;
  padding: 18px 0 0 1px;
  font-size: 28px;
}
.recruitment-process__stepHeading {
  font-weight: bold;
}
.recruitment-process__stepText {
  max-width: 600px;
  text-align: left;
  position: absolute;
  font-size: 16px;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 10px;
}
@media (max-width: 480px) {
  .recruitment-process__stepText {
    line-height: 18px;
  }
}
@media (min-width: 841px) {
  .recruitment-process__stepText {
    top: 50%;
    transform: translateY(-50%);
  }
}

@media (min-width: 841px) {
  .cms-reset-password {
    max-width: 600px;
  }
}
.cms-reset-password__title {
  max-width: none;
  margin-bottom: 20px;
}
@media (min-width: 841px) {
  .cms-reset-password__title {
    margin-bottom: 29px;
  }
}
.cms-reset-password__description p {
  max-width: none;
}
.cms-reset-password__input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
@media (min-width: 601px) {
  .cms-reset-password__input-container {
    flex-direction: row;
    margin-bottom: 30px;
  }
}
.cms-reset-password__input-container .text-input {
  margin: 0;
  margin-bottom: 15px;
  width: 100%;
}
@media (min-width: 601px) {
  .cms-reset-password__input-container .text-input {
    margin-bottom: 0;
    padding-right: 20px;
  }
}
@media (min-width: 601px) {
  .cms-reset-password__input-container .text-input .text-input__error-text {
    position: absolute;
  }
}
.cms-reset-password__input-container > .button {
  align-self: center;
  margin: 0;
  margin-top: auto;
}
.cms-reset-password__loader {
  display: flex;
  justify-content: center;
}

.responsivetable {
  padding-top: 10px;
}
.responsivetable tr:nth-child(odd) {
  background-color: #ffffff;
}
.responsivetable tr:not(:last-child) {
  border-bottom: 2px solid #F2F2F2;
}
.responsivetable tr:first-child {
  background-color: rgba(255, 255, 255, 0.95);
}
.responsivetable__table {
  display: flex;
  color: #000000;
}
.responsivetable__row {
  display: inline-flex;
}
.responsivetable__content p {
  margin-top: 4px;
}
.responsivetable__header-row .responsivetable__content p {
  margin: 0.15em 0;
}
.responsivetable__header-row .responsivetable__content p:nth-of-type(1) {
  color: #A0A0A0;
}
.responsivetable__header-row .responsivetable__content p:nth-of-type(2) {
  font-size: 14px;
  font-weight: bold;
}
.responsivetable__header-row .responsivetable__content p:nth-of-type(2) a:link {
  color: #000000;
}
.responsivetable__header-row .responsivetable__content p:nth-of-type(2) a:hover, .responsivetable__header-row .responsivetable__content p:nth-of-type(2) a:active {
  color: #CC00FF;
}
@media (min-width: 841px) {
  .responsivetable {
    flex-direction: row;
  }
  .responsivetable__body {
    display: flex;
    flex-direction: column;
  }
  .responsivetable__body tr {
    display: inline-flex;
    padding: 8px 0;
  }
  .responsivetable__body tr:last-child .custom-button .button {
    margin-top: 19px;
  }
  .responsivetable__row-header {
    padding: 0;
    display: flex;
    flex: 1 1 240px;
  }
  .responsivetable__row-header p {
    margin: 0 !important;
  }
  .responsivetable__header-row {
    position: sticky;
    top: 78px;
  }
  .responsivetable__header-row img {
    height: 136px;
    transition: transform ease 0.2s;
    width: 136px;
    padding: 0 0 18px;
  }
  .responsivetable__header-row img:hover {
    transform: scale(1.13235);
  }
  .responsivetable__header-row .responsivetable__content p:nth-of-type(2) {
    font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 21px;
  }
  .responsivetable__content {
    display: flex;
    flex-direction: column;
    flex: 1 1 312px;
    padding: 0 0 0 48px;
  }
  .responsivetable__content img {
    height: 136px;
    width: 136px;
    padding: 0 0 18px;
  }
  .responsivetable__content p {
    margin: 0;
  }
  .responsivetable__content p .custom-button {
    margin: 10px -8px 10px;
  }
  .responsivetable__row {
    width: 100%;
    flex-direction: row;
  }
  .responsivetable__row p {
    margin: 0.5em 0;
  }
  .responsivetable__table {
    flex-direction: column;
    padding: 0 24px;
  }
  .responsivetable__scroll-container {
    border-radius: 2px;
    box-sizing: border-box;
    flex: 1 1 912px;
  }
}
@media (max-width: 840px) {
  .responsivetable {
    flex-direction: column;
  }
  .responsivetable__table {
    min-width: 0 !important;
    overflow-x: scroll;
    padding-left: 133px;
  }
  .responsivetable__header-row {
    background-color: rgba(255, 255, 255, 0.9) !important;
    box-shadow: 2px 2px 4px 0 transparent;
    left: 0;
    position: absolute;
    transition: box-shadow ease 0.2s;
    width: 133px;
  }
  .responsivetable__row-header {
    border-bottom: 2px solid #F2F2F2;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: bold;
    height: 40px;
    line-height: 20px;
    overflow: hidden;
    padding: 10px 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 133px;
  }
  .responsivetable__row-header p {
    margin: 0;
  }
  .responsivetable__row-header strong {
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
  }
  .responsivetable__content {
    height: 154px;
    width: 133px;
    overflow: hidden;
    padding: 10px 16px;
    border-bottom: 2px solid #F2F2F2;
    display: flex;
    flex-direction: column;
  }
  .responsivetable__content img {
    height: 64px;
    width: 64px;
  }
  .responsivetable__content .custom-button {
    display: none;
  }
  .responsivetable__content p {
    margin: 0;
  }
  .responsivetable__scroll-container {
    overflow: hidden;
    position: relative;
  }
  .responsivetable__body {
    display: flex;
    flex-direction: row;
  }
  .responsivetable__body tr {
    flex-direction: column;
    display: inline-flex;
    border-bottom: none !important;
  }
}

.reuse-content {
  padding: 10px;
  color: #00CC66;
}
.reuse-content__description-item {
  display: flex;
}
.reuse-content__description-title {
  font-weight: bold;
}
.reuse-content__description-label {
  width: 100px;
}
.reuse-content__description-value {
  font-family: monospace;
}
.reuse-content--hidden {
  display: none;
}

.revealable-component {
  text-align: center;
}
.revealable-component__anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}
.revealable-component__content {
  text-align: left;
  max-height: 0;
  transition: max-height 0.4s, padding-top 0.4s, padding-bottom 0.4s;
  transition-timing-function: cubic-bezier(0, 1, 0, 1);
  overflow: hidden;
}
.revealable-component__container {
  display: block;
  position: relative;
}
.revealable-component__item--open .revealable-component__content {
  max-height: none;
}
.revealable-component__item--open .revealable-component__content .tablist-accordion__item--open .tablist-accordion__content {
  padding-top: 0;
  margin-top: -5px;
}
.revealable-component__item {
  float: none;
  background: none;
}
.revealable-component__item--open .revealable-component__content {
  max-height: 10000px;
  transition-timing-function: cubic-bezier(1, 0, 1, 0);
}
.revealable-component__heading {
  display: flex;
  width: 100%;
  cursor: pointer;
  user-select: none;
  position: relative;
  justify-content: center;
}
.revealable-component__heading--left {
  justify-content: flex-start;
  text-align: left;
}
.revealable-component__heading--large {
  font-size: 32px;
  font-weight: bold;
  line-height: 32px;
}
.revealable-component__heading--color-purple {
  color: #990AE3;
}
.revealable-component__heading--color-purple:hover {
  color: #CC00FF;
}
.revealable-component__heading--color-grey {
  color: #A0A0A0;
}
.revealable-component__heading--color-grey:hover {
  color: #C5C5C5;
}
.revealable-component__heading--pebble, .revealable-component__heading--heading {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
}
.revealable-component__heading--hidden {
  display: none;
}
.revealable-component__title {
  max-width: 90%;
  display: inline-block;
  margin-right: 10px;
}
.revealable-component__show-less-text {
  display: inline-block;
  margin-right: 10px;
}
.revealable-component__icon {
  font-size: 14px;
  transition: transform 0.2s;
  width: 14px;
  height: 23px;
}
.revealable-component__heading--large .revealable-component__icon {
  top: 0;
  width: auto;
  height: auto;
  font-size: 18px;
}
.revealable-component__heading--large .revealable-component__icon svg {
  vertical-align: middle;
  margin-top: 0;
}
.revealable-component__icon svg {
  margin-top: 5px;
}
.revealable-component__icon--rotate {
  transform: rotate(180deg);
}

.search-form {
  background-color: #F9F9F9;
  padding: 3em 0 1.5em;
}
.search-form__wrapper {
  align-items: center;
}
.search-form__field .text-input__icon {
  top: 1.5em;
}
@media (min-width: 841px) {
  .search-form__field .text-input__icon {
    left: 10px;
  }
}
.search-form__field .text-input__icon--search {
  top: 2em;
}
.search-form__input {
  font-size: 16px;
}
.search-form__input--ac-active {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.search-form__results-container-wrapper {
  align-items: center;
}
.search-form__results-addsearch {
  display: none;
  position: relative !important;
}
.search-form__filters-container {
  background-color: #F9F9F9;
}
.search-form__filters {
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.search-form__filters-list {
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  margin-top: 21px;
  color: #990AE3;
}
@media (min-width: 841px) {
  .search-form__filters-list {
    overflow: hidden;
  }
}
.search-form__filters-action {
  height: 100%;
  position: absolute;
  transition: left 0.2s ease-in, right 0.2s ease-in;
  display: none;
  padding-top: 1px;
  top: 25%;
}
@media (max-width: 600px) {
  .search-form__filters-action {
    top: 35%;
  }
}
.search-form__filters-action--right {
  right: -30px;
}
.search-form__filters-list--scrollRight .search-form__filters-action--right {
  right: 0;
  display: inline-block;
}

.search-form__filters-action--left {
  left: -30px;
}
.search-form__filters-list--scrollLeft .search-form__filters-action--left {
  left: 0;
  display: inline-block;
}

.search-form__filters-icon {
  position: absolute;
  color: #990AE3;
  width: 20px;
  height: 30px;
  background-color: #F9F9F9;
  text-align: center;
  z-index: 1;
  cursor: pointer;
}
.search-form__filters-icon--left {
  left: 0;
  box-shadow: 10px 0 10px #F9F9F9;
}
.search-form__filters-icon--right {
  right: 0;
  box-shadow: -10px 0 10px #F9F9F9;
}
.search-form__filters-icon .icon {
  vertical-align: middle;
}
@media (max-width: 600px) {
  .search-form__filters-icon .icon {
    font-size: 12px;
  }
}

.search--with-image .search-form {
  background-color: transparent;
  padding-bottom: 0.1px;
  transition: margin-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
}
.search--with-image .search-form__field {
  position: relative;
}
.search--with-image .search-form__results-container {
  background: #ffffff;
  margin-top: 72px;
  padding-bottom: 30px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out 0.3s;
}
.search--with-image .search-form__results-container-wrapper {
  align-items: center;
}
.search--with-image .search-form__results-addsearch {
  margin-top: 180px !important;
}
.search--with-image .search-form .text-input__icon {
  left: -2px;
}
.search--with-image .search__container--small .search-form {
  margin-top: 48px;
}

.search--with-image.search--searched .search-form__results-container {
  margin-top: 40px;
  padding-bottom: 40px;
  opacity: 1;
}
.search--with-image.search--searched .search-form__results-addsearch {
  margin-top: 90px !important;
}

.search--with-image {
  position: relative;
}
.search__container {
  min-height: 480px;
  position: relative;
  z-index: 2;
}
.search__container--small {
  min-height: 180px;
}
.search__background {
  position: absolute;
  height: 480px;
  max-height: 480px;
  width: 100%;
  z-index: 1;
  transition: max-height 0.3s ease-in-out;
}
.search__background--small {
  max-height: 180px;
}
.search--searched .search__background {
  max-height: 375px;
}
.search--searched .search__background--small {
  max-height: 180px;
}
.search__background-image {
  background-size: cover;
  width: 100%;
  height: 100%;
  background-position: 0 0;
}
@media (max-width: 840px) {
  .search__background-image--desktop {
    display: none;
  }
}
@media (min-width: 841px) {
  .search__background-image--mobile {
    display: none;
  }
}
.search--with-image .search__header {
  padding: 164px 0 0;
}
.search .heading-component__heading {
  margin-top: 0;
  margin-bottom: 0;
}

.services-list {
  background-color: #F2F2F2;
  flex-wrap: nowrap;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
}
.services-list__title {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 20px;
  padding-top: 16px;
  margin-bottom: -22px;
}
@media (min-width: 841px) {
  .services-list__title {
    font-size: 24px;
    padding-top: 40px;
    margin-bottom: -8px;
  }
}
.services-list > .grid {
  overflow-x: auto;
  overflow-y: hidden;
}
.services-list__arrow {
  position: absolute;
  color: #990AE3;
  background-color: #ffffff;
  transition: opacity 0.3s, background-color 0.3s, color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  cursor: pointer;
  display: none;
  width: 48px;
  height: 48px;
  font-size: 20px;
  opacity: 0.6;
  border-radius: 48px;
  box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.3);
  pointer-events: none;
}
.services-list__arrow:hover {
  opacity: 1;
  background-color: #990AE3;
  color: #ffffff;
}
.services-list__arrow--right {
  right: 8px;
}
@media (min-width: 841px) {
  .services-list__arrow--right {
    right: 48px;
  }
}
.services-list__arrow--left {
  left: 8px;
}
@media (min-width: 841px) {
  .services-list__arrow--left {
    left: 48px;
  }
}
.services-list__scroll--left .services-list__arrow--left {
  display: block;
  display: flex;
  pointer-events: auto;
}
.services-list__scroll--right .services-list__arrow--right {
  display: block;
  display: flex;
  pointer-events: auto;
}
.services-list__subscription {
  flex-shrink: 0;
}
.services-list__subscription-title {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  color: inherit;
  margin-bottom: 6px;
}
.services-list__subscription-detail {
  color: inherit;
  margin-bottom: 4px;
}
.services-list__subscription-type {
  color: inherit;
  margin-bottom: 4px;
}
.services-list__subscription-address {
  line-height: 24px;
}
.services-list__service-texts {
  max-width: 75%;
  overflow: hidden;
  float: left;
}
.services-list__service-link {
  height: 100%;
  transition: transform 0.2s;
  transform: scale(1);
  display: flex;
  justify-content: space-between;
  padding: 24px 24px 48px;
}
.services-list__service-link:link, .services-list__service-link:visited {
  color: #222222;
}
.services-list__service-link-container {
  background-color: #ffffff;
  box-shadow: 0 1px 2px 0 #E0E0E0;
  border-radius: 4px;
  transition: transform 0.2s;
  margin: 40px 0;
  transform: scale(1);
  overflow: hidden;
  height: 210px;
}
@media (max-width: 600px) {
  .services-list__service-link-container {
    margin-left: 3px;
    margin-right: 3px;
  }
}
.services-list__service-link-container:link, .services-list__service-link-container:visited {
  color: #222222;
}
.services-list__service-link-container:hover {
  z-index: 1;
  transform: scale(1.03);
  box-shadow: rgba(0, 0, 0, 0.2) 0 1px 4px;
  color: #990AE3;
}
.services-list__service-icon {
  float: right;
  order: 1;
  font-size: 48px;
  color: #000000;
  margin: 0 0 16px;
}
.services-list__editor-content {
  padding: 20px 40px;
  background-color: #A0A0A0;
  border: 1px solid #000000;
}
.services-list__promotion {
  flex-shrink: 0;
}
@media (min-width: 1081px) {
  .services-list__service-links--1 .services-list__promotion--small {
    display: none;
  }
}
@media (max-width: 1080px) {
  .services-list__service-links--1 .services-list__promotion--medium {
    display: none;
  }
}
@media (min-width: 1441px) {
  .services-list__service-links--1 .services-list__promotion--medium {
    display: none;
  }
}
@media (max-width: 1440px) {
  .services-list__service-links--1 .services-list__promotion--large {
    display: none;
  }
}
@media (min-width: 1441px) {
  .services-list__service-links--2 .services-list__promotion--small {
    display: none;
  }
}
@media (max-width: 1440px) {
  .services-list__service-links--2 .services-list__promotion--medium {
    display: none;
  }
}
.services-list__service-links--2 .services-list__promotion--large {
  display: none;
}
.services-list__service-links--3-or-more .services-list__promotion--small {
  display: block;
}
.services-list__service-links--3-or-more .services-list__promotion--medium {
  display: none;
}
.services-list__service-links--3-or-more .services-list__promotion--large {
  display: none;
}
.services-list__promotion-content {
  height: 210px;
  margin: 40px 0;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
  color: #fff;
  padding: 35px 25px 25px 25px;
  position: relative;
}
.services-list__promotion-heading {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  top: 50px;
  left: 20px;
  font-size: 24px;
  padding-bottom: 7px;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);
}
.services-list__promotion-button {
  position: absolute;
  bottom: 20px;
}

.subscription-teaser {
  background-size: cover;
  padding: 8px 24px;
  position: absolute;
  top: calc(100% - 10px);
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  border-radius: 0 0 4px 4px;
  background-image: url("/.resources/pride-ui-module/webresources/assets/images/subscription-teasers/notification_bg-11_green.jpg");
  /*&--warning {
    background-image: url('/.resources/pride-ui-module/webresources/assets/images/subscription-teasers/notification_bg-25_purple.jpg');
  }

  &--danger {
    background-image: url('/.resources/pride-ui-module/webresources/assets/images/subscription-teasers/notification_bg-25_purple.jpg');
  }*/
}
.subscription-teaser--open {
  top: 0;
  transition: top 0.4s cubic-bezier(0.65, -0.25, 0.39, -0.32);
  cursor: default;
  border-radius: 4px;
  background-size: 100% 100%;
}
.subscription-teaser--closed {
  top: calc(100% - 36px);
  transition: top 0.4s cubic-bezier(0.64, 0.83, 0.6, 1.41);
}
.subscription-teaser__message {
  white-space: nowrap;
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.45, 0.45, 0.45, 0.45);
  color: white;
  font-weight: bold;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.subscription-teaser--open .subscription-teaser__message {
  opacity: 0;
  position: absolute;
}
.subscription-teaser__content {
  display: none;
  height: 100%;
  text-align: center;
  padding: 25px 20px 5px 20px;
}
.subscription-teaser--open .subscription-teaser__content {
  display: flex;
  flex-direction: column;
}
.subscription-teaser__description {
  color: white;
  font-weight: bold;
  font-size: 18px;
}
.subscription-teaser__button {
  margin-top: auto;
}
.subscription-teaser__close-icon {
  color: white;
  cursor: pointer;
  position: absolute;
  right: 4px;
  top: 4px;
  font-size: 32px;
}

.share-follow {
  margin-top: 40px;
}
@media (min-width: 601px) {
  .share-follow {
    margin-top: 44px;
  }
}
.share-follow-large--margin-bottom {
  margin-bottom: 56px;
}
@media (min-width: 601px) {
  .share-follow-large--margin-bottom {
    margin-bottom: 96px;
  }
}
.share-follow__text {
  text-transform: uppercase;
  margin-left: 4px;
}
.share-follow__buttons {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
}
.share-follow__container a {
  padding: 30px 0px 10px;
}
.share-follow__icon {
  display: inline-flex;
  width: 30px;
  height: 30px;
  background-color: #000000;
  color: #ffffff;
  border-radius: 99px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin: 12px 12px 12px 0;
  transition: background-color 0.2s;
}
.share-follow__icon:link, .share-follow__icon:visited, .share-follow__icon:hover, .share-follow__icon:active {
  color: #ffffff;
}
@media (hover: hover) {
  .share-follow__icon:hover {
    background-color: #CC00FF;
  }
}
@media (max-width: 1080px) {
  .share-follow__icon--reduce-margin {
    margin: 20px 8px 0 !important;
  }
}
.share-follow--large .share-follow__icon {
  width: 50px;
  height: 50px;
  font-size: 30px;
  margin: 20px 8px 0px;
}
@media (min-width: 601px) {
  .share-follow--large .share-follow__icon {
    margin: 40px 32px 0px;
  }
}

.cart {
  background-color: #F2F2F2;
  color: #222222;
  padding-top: 16px;
}
.cart__total {
  overflow: hidden;
  margin: 0 24px;
  padding: 16px 0;
  border-bottom: 1px solid #E0E0E0;
}
.cart__total-text {
  float: left;
}
.cart__total-price {
  float: right;
}
.cart__amount {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 24px;
}
.cart__button {
  text-align: center;
  margin: 8px auto 16px;
}
.cart__item {
  background-color: #ffffff;
  padding: 16px 24px;
  border-top: 1px solid #C5C5C5;
  border-bottom: 1px solid #C5C5C5;
  display: flex;
}
.cart__item + .cart__item {
  border-top: 0;
}
.cart__item-picture {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 80px;
  margin-right: 8px;
}
.cart__item-image {
  max-width: 60px;
  max-height: 100%;
  width: auto;
  height: auto;
}
.cart__item-type {
  color: #A0A0A0;
  font-size: 14px;
  line-height: 1.2;
}
.cart__item-name {
  font-size: 18px;
}
.cart__item-price {
  margin-left: auto;
  align-self: center;
}

.social-media-links {
  text-align: center;
  background-color: #F2F2F2;
  padding: 2em 0;
}
.social-media-links--reverse-background-colors {
  background-color: white;
}
.social-media-links--reverse-background-colors .social-media-link__content {
  background-color: #F2F2F2;
}
.social-media-links__heading {
  padding-bottom: 1em;
  margin-left: auto;
  margin-right: auto;
}

.social-media-link {
  padding: 2px;
}
.social-media-link a {
  color: #000000 !important;
}
.social-media-link__content {
  background-color: white;
  padding: 14px 8px 14px 26px;
}
.social-media-link__header {
  margin-bottom: 16px;
}
.social-media-link__icon {
  display: inline-block;
  color: #000000;
  fill: #000000;
  font-size: 26px;
  position: relative;
  top: 6px;
}
.social-media-link__title {
  font-size: 20px;
  display: inline-block;
  margin-left: 4px;
}
.social-media-link__description {
  margin-bottom: 8px;
  font-size: 14px;
}

.splash {
  width: 1em;
  height: 1em;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #ffffff;
  background-color: #000000;
  margin: 10px;
  font-size: 80px;
}
@media (min-width: 601px) {
  .splash {
    font-size: 96px;
  }
}
@media (min-width: 841px) {
  .splash {
    font-size: 128px;
  }
}
.splash--orange {
  background-color: #FF9B00;
}
.splash__top-text {
  line-height: 1;
  font-size: 10px;
}
@media (min-width: 841px) {
  .splash__top-text {
    font-size: 12px;
  }
}
.splash__main-text {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 0.2656em;
  line-height: 1;
  font-weight: bold;
  margin: 3px 0 2px;
}
@media (min-width: 841px) {
  .splash__main-text {
    margin-bottom: 4px;
  }
}
.splash__bottom-text {
  line-height: 1;
  font-size: 10px;
}
@media (min-width: 841px) {
  .splash__bottom-text {
    font-size: 12px;
  }
}
.splash--small {
  font-size: 80px;
}
.splash--small .splash__top-text,
.splash--small .splash__bottom-text {
  font-size: 10px;
}
.splash--image {
  width: auto;
  height: auto;
  align-self: flex-start;
  display: inline-block;
}

.ssl-check {
  background-image: url("../assets/images/components/ssl-check/chrome-test-background.jpg");
  background-size: cover;
  min-height: 474px;
}
.ssl-check p {
  color: white;
}
.ssl-check__header {
  color: #ffffff;
  margin-bottom: 0;
}
.ssl-check__header--hidden {
  display: none;
}
.ssl-check__intro {
  margin-bottom: 8px;
}
.ssl-check__form-part {
  position: relative;
}
.ssl-check__form-part--email {
  display: none;
}
.ssl-check__error {
  display: none;
  color: #E4175C !important;
}
.ssl-check__result {
  display: none;
  margin-top: 24px;
}
.ssl-check__result-not-secure, .ssl-check__result-secure {
  display: none;
}
.ssl-check__result-secure-image {
  background: url(../assets/images/components/ssl-check/chrome_secure_site.png) 0 0 no-repeat;
  height: 75px;
  display: inline-block;
  max-width: 100%;
}
.ssl-check__result-not-secure-image {
  background: url(../assets/images/components/ssl-check/chrome_not_secure_site.png) 0 0 no-repeat;
  height: 75px;
  display: inline-block;
  max-width: 100%;
}
.ssl-check__result-value {
  margin: 43px 169px 0 169px;
}
.ssl-check__result-value--not-secure {
  margin: 43px 195px 0 195px;
}
.ssl-check__contact-link {
  padding: 0 0 1em;
}
.ssl-check__link a {
  height: 22px;
}
.ssl-check__link a:link, .ssl-check__link a:visited {
  color: #ffffff;
}
.ssl-check__link a:hover, .ssl-check__link a:active {
  color: #CCCCCC;
}
.ssl-check__no-thanks {
  display: none;
  font-weight: bold;
}
.ssl-check__download-guide-buttons {
  margin: 1em 0;
}

.cms-store-locator {
  height: 100%;
  background-color: #ffffff;
  /* Telia react component library styles are added to the page after these styles so we need these to be more specific for them to work. */
}
.cms-store-locator--in-list-view {
  background-color: #F2F2F2;
}
.cms-store-locator__loader {
  display: flex;
  justify-content: center;
  padding: 48px;
}
.cms-store-locator__change-view-button-container {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 10px;
}
@media (min-width: 601px) {
  .cms-store-locator__change-view-button-container {
    top: 24px;
    right: 24px;
  }
}
.cms-store-locator .telia-react-element.cms-store-locator__change-view-button {
  background-color: #ffffff;
  padding: 8px 16px;
  border-radius: 4px;
  border: solid 1px;
  border-color: #C5C5C5;
  /* Can't manage this out of the box with the component library functionality. SelectionButtonGroup is also not versatile enough. */
}
.cms-store-locator .telia-react-element.cms-store-locator__change-view-button .telia-react-icon {
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  max-width: 16px;
  max-height: 16px;
}
.cms-store-locator .telia-react-element.cms-store-locator__change-view-button:focus {
  outline: none;
}
.cms-store-locator .telia-react-element.cms-store-locator__change-view-button:focus::before {
  transition: box-shadow 150ms ease;
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 4px;
  border: 2px solid #40b3ff;
}
.cms-store-locator .telia-react-element.cms-store-locator__change-view-button .telia-react-typography,
.cms-store-locator .telia-react-element.cms-store-locator__change-view-button .telia-react-gutter {
  display: none;
}
@media (min-width: 841px) {
  .cms-store-locator .telia-react-element.cms-store-locator__change-view-button .telia-react-typography,
  .cms-store-locator .telia-react-element.cms-store-locator__change-view-button .telia-react-gutter {
    display: block;
  }
}
.cms-store-locator .telia-react-element.cms-store-locator__change-view-button--in-list-view .telia-react-typography,
.cms-store-locator .telia-react-element.cms-store-locator__change-view-button--in-list-view .telia-react-gutter {
  display: none;
}
@media (min-width: 481px) {
  .cms-store-locator .telia-react-element.cms-store-locator__change-view-button--in-list-view .telia-react-typography,
  .cms-store-locator .telia-react-element.cms-store-locator__change-view-button--in-list-view .telia-react-gutter {
    display: block;
  }
}
.cms-store-locator .telia-react-element.cms-store-locator__change-view-button--selected {
  border-color: #990AE3;
}
.cms-store-locator__side-panel {
  overflow-y: auto;
  background-color: #ffffff;
  width: 100%;
}
.cms-store-locator--in-list-view .cms-store-locator__side-panel {
  order: 1;
}
@media (min-width: 841px) {
  .cms-store-locator--in-list-view .cms-store-locator__side-panel {
    order: 0;
  }
}
@media (min-width: 841px) {
  .cms-store-locator__side-panel {
    width: 392px;
    min-width: 392px;
  }
  .cms-store-locator--support-footer .cms-store-locator__side-panel {
    max-height: calc(100vh - 110px);
    min-height: calc(100vh - 110px);
  }
  .cms-store-locator--in-list-view.cms-store-locator--support-footer .cms-store-locator__side-panel {
    max-height: none;
  }
}
.cms-store-locator__side-panel-search .telia-react-input__container {
  box-shadow: none;
}
.cms-store-locator__side-panel-search .telia-react-input__container:hover {
  box-shadow: none;
}
.cms-store-locator__side-panel-search-result-button {
  background-color: transparent;
  text-align: left;
  cursor: pointer;
}
.cms-store-locator__side-panel-search-result-button:focus {
  outline-offset: -4px;
  outline: solid 4px #0099ff;
}
.cms-store-locator__side-panel-store-marker-container {
  position: relative;
  width: 28px;
}
.cms-store-locator__side-panel-store-marker {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 28px;
  width: 28px;
}
.cms-store-locator__info-window {
  max-width: 312px !important;
}
.cms-store-locator__info-window-opening-times {
  flex-grow: 1;
}
.cms-store-locator__info-window-opening-time-title, .cms-store-locator__info-window-opening-time {
  line-height: 18px !important;
}
.cms-store-locator__main-container {
  position: relative;
  display: flex;
  flex-direction: column-reverse;
}
@media (max-width: 1080px) {
  .cms-store-locator__main-container {
    padding: 0;
  }
}
@media (min-width: 841px) {
  .cms-store-locator__main-container {
    height: 100%;
    flex-direction: row;
  }
}
.cms-store-locator__main-container.grid {
  padding: 0;
}
.cms-store-locator__map-view {
  height: 50vh;
}
@media (min-width: 841px) {
  .cms-store-locator__map-view {
    height: 100%;
    max-height: calc(100vh - 110px);
  }
}
.cms-store-locator__map-view-map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.cms-store-locator__list-view-container {
  padding-top: 0 !important;
  overflow-y: auto;
}
@media (min-width: 841px) {
  .cms-store-locator__list-view-container {
    padding-top: 56px !important;
  }
}
@media (min-width: 841px) {
  .cms-store-locator__list-view-wrapper {
    height: 100%;
  }
}

.cms-store-page {
  padding-bottom: 24px;
}
.cms-store-page__loader {
  display: flex;
  justify-content: center;
  padding: 48px;
}
@media (min-width: 841px) {
  .cms-store-page {
    padding-bottom: 0;
  }
}
@media (min-width: 841px) {
  .cms-store-page__container {
    column-count: 2;
    column-gap: 24px;
  }
}
.cms-store-page__card-wrapper {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
  margin-bottom: -24px !important;
  page-break-inside: avoid; /** for firefox */
  break-inside: avoid-column; /** for all other browsers */
}
.cms-store-page__card-content {
  /** To reset user agent styles for html that may come from the data */
  /** TODO: the p margin should probably be removed so paragraphs from the rich text editor are visible */
}
.cms-store-page__card-content p {
  margin: 0;
  max-width: none;
}
.cms-store-page__card-content .button {
  margin-left: 0;
  margin-top: 16px;
}
.cms-store-page__main-card-image {
  /* Hack to make the image 16:9 */
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  /* --- */
  background-repeat: no-repeat;
  background-size: cover;
  /* 35% to take into account pictures with a person whose head is on the top part of the image */
  background-position: 50% 35%;
}

.sub-nav {
  background: white;
  border-top: 1px solid #E0E0E0;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.11);
}
.body--subnavi-border .sub-nav {
  border-bottom: 2px solid #f2f2f2;
}
.sub-nav__container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  padding-top: 0px;
  padding-bottom: 0px;
}
.sub-nav__list {
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  scroll-behavior: smooth;
  overflow-scrolling: touch;
  display: flex;
}
@media (min-width: 841px) {
  .sub-nav__list {
    overflow: hidden;
  }
}
.sub-nav__item {
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  /* this is the first actual sub menu item */
}
@media (min-width: 841px) {
  .sub-nav__item {
    padding-left: 14px;
    padding-right: 14px;
  }
}
.sub-nav__item:nth-child(2) {
  padding-left: 0;
}
.sub-nav__item a:link,
.sub-nav__item a:visited {
  color: #3A3A3A;
}
.sub-nav__item a:hover {
  color: #990AE3;
}
.sub-nav__link {
  display: inline-block;
  padding-top: 16px;
  padding-bottom: 15px;
  margin: 0;
  line-height: 1em;
  font-size: 15px;
}
.sub-nav__link--active {
  padding-bottom: 13px;
  border-bottom: 2px solid #990AE3;
}
.sub-nav__link--active:hover {
  color: #990AE3;
}
.sub-nav__item:nth-child(2n) .subnav__link {
  margin-left: 0;
}

.sub-nav__action {
  height: 100%;
  position: absolute;
  transition: left 0.2s ease-in, right 0.2s ease-in;
  display: none;
  padding-top: 10px;
}
.sub-nav__action--right {
  right: -30px;
}
.sub-nav__list--scrollRight .sub-nav__action--right {
  right: 0;
  display: inline-block;
}

.sub-nav__action--left {
  left: -30px;
}
.sub-nav__list--scrollLeft .sub-nav__action--left {
  left: 0;
  display: inline-block;
}

.sub-nav__icon {
  position: absolute;
  color: #990AE3;
  width: 30px;
  height: 30px;
  background-color: #fff;
  text-align: center;
  cursor: pointer;
}
.sub-nav__icon--left {
  left: 0;
  box-shadow: 10px 0 10px #ffffff;
}
.sub-nav__icon--right {
  right: 0;
  box-shadow: -10px 0 10px #ffffff;
}
.sub-nav__icon .icon {
  font-size: 20px;
  vertical-align: middle;
}

.cms-tablist {
  margin-top: 2em;
}
.cms-tablist__anchor {
  display: block;
  position: relative;
  top: -90px;
  visibility: hidden;
}
.cms-tablist__navbar-container {
  position: relative;
  margin-bottom: 2.5em;
}
.cms-tablist__navbar-container.scrollLeft {
  padding-left: 20px;
}
.cms-tablist__navbar-container.scrollRight {
  padding-right: 20px;
}
.cms-tablist__navbar {
  width: 100%;
  position: relative;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
.cms-tablist__navbar--align-center {
  text-align: center;
}
.grid .cms-tablist__navbar .grid {
  margin: 0;
  width: 100%;
}
.grid .cms-tablist__navbar .grid .grid__col-12 {
  padding: 0;
}
.cms-tablist__navbar-content {
  white-space: nowrap;
  padding: 3px;
}
.cms-tablist__heading {
  display: none;
}
.cms-tablist__heading--center {
  text-align: center;
}
.cms-tablist__heading--left {
  text-align: left;
}
.cms-tablist__radio {
  display: none;
}
.cms-tablist__tab {
  display: none;
}
.cms-tablist__tab-text {
  pointer-events: none;
  display: inline-block;
}
.cms-tablist__radio:checked + .cms-tablist__tab {
  display: block;
}
.cms-tablist__label {
  font-size: 15px;
  padding: 0.5em 0;
  margin-right: 1em;
  color: #000000;
  cursor: pointer;
  border-bottom: 3px solid #C5C5C5;
  display: inline-block;
  pointer-events: all;
  display: inline-block;
  border-width: 0;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}
@media (min-width: 841px) {
  .cms-tablist__label {
    font-size: 18px;
  }
}
.cms-tablist__label--active {
  color: #000000;
  border-color: #990AE3;
  cursor: auto;
}
.cms-tablist__label:hover {
  color: #990AE3;
  border-color: #990AE3;
}
.cms-tablist__label--active {
  border-bottom: 3px solid #990AE3;
}
.cms-tablist__label--active:hover {
  color: inherit;
}
.cms-tablist__container {
  overflow: hidden;
  margin-bottom: 3em;
  position: relative;
}
.cms-tablist--no-footer-margin .cms-tablist__container {
  margin-bottom: 0;
}
.cms-tablist__tab > .cms-tablist__content .columnrow {
  margin-top: 0;
}
.cms-tablist__scroll-button {
  speak: none;
  position: absolute;
  top: 13px;
  transition: opacity 0.5s;
  cursor: pointer;
  display: none;
  color: #990AE3;
}
.cms-tablist__scroll-left-button {
  left: 0px;
}
.scrollLeft .cms-tablist__scroll-left-button {
  display: block;
}
.cms-tablist__scroll-right-button {
  right: 0px;
}
.scrollRight .cms-tablist__scroll-right-button {
  display: block;
}
.cms-tablist--inline .cms-tablist__heading {
  display: block;
  font-size: 28px;
  font-weight: 700;
  padding: 0.25em 0;
  margin: 1em 0;
  color: #990AE3;
}
.cms-tablist--inline .cms-tablist__navbar {
  display: none;
}
.cms-tablist--inline .cms-tablist__tab {
  display: block;
}
.cms-tablist--rocker-tabs .cms-tablist__navbar-content {
  font-size: 0;
}
.cms-tablist--rocker-tabs .cms-tablist__label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  padding: 8px 20px;
  margin: 0;
  box-sizing: border-box;
  border: solid 2px #990AE3;
  border-right: none;
  color: #990AE3;
}
.cms-tablist--rocker-tabs .cms-tablist__label--active {
  background-color: #990AE3;
  color: #ffffff;
}
@media (min-width: 841px) {
  .cms-tablist--rocker-tabs .cms-tablist__label {
    font-size: 14px;
    padding: 12px 44px;
  }
}
.cms-tablist--rocker-tabs .cms-tablist__label:first-child {
  border-radius: 8px 0 0 8px;
}
.cms-tablist--rocker-tabs .cms-tablist__label:last-child {
  border-radius: 0 8px 8px 0;
  border-right: solid 2px #990AE3;
}
.cms-tablist--squircle .cms-tablist__squircle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  width: 74px;
  height: 74px;
}
@media (min-width: 601px) {
  .cms-tablist--squircle .cms-tablist__squircle-container {
    width: 112px;
    height: 112px;
  }
}
.cms-tablist--squircle .cms-tablist__navbar-container {
  margin-bottom: 16px;
}
.cms-tablist--squircle .cms-tablist__navbar {
  white-space: normal;
}
.cms-tablist--squircle .cms-tablist__navbar-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  white-space: normal;
}
.cms-tablist--squircle .cms-tablist__navbar--align-left .cms-tablist__navbar-content {
  justify-content: flex-start;
}
.cms-tablist--squircle .cms-tablist__label {
  margin: 0;
  pointer-events: all;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  border: solid #E0E0E0 2px;
  border-radius: 18px;
  width: 60px;
  height: 60px;
  padding: 0;
  box-sizing: border-box;
  color: #222222;
  font-size: 9px;
  line-height: 9px;
  font-weight: 500;
  transition: all 100ms linear;
}
.cms-tablist--squircle .cms-tablist__label--active {
  width: 70px;
  height: 70px;
  border-color: rgb(255, 0, 205);
  box-shadow: 0 8px 8px -10px #3A3A3A;
}
@media (min-width: 601px) {
  .cms-tablist--squircle .cms-tablist__label {
    font-size: 12px;
    line-height: 12px;
    width: 80px;
    height: 80px;
    border-radius: 12px;
  }
  .cms-tablist--squircle .cms-tablist__label--active {
    width: 100px;
    height: 100px;
    border-color: #990AE3;
    box-shadow: 0 8px 8px -10px #3A3A3A;
  }
}
.cms-tablist--squircle .cms-tablist__tab-icon {
  pointer-events: none;
  font-size: 22px;
  height: 22px;
}
@media (min-width: 601px) {
  .cms-tablist--squircle .cms-tablist__tab-icon {
    font-size: 26px;
    height: 26px;
  }
}
.cms-tablist--squircle .cms-tablist__tab-number {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 22px;
  line-height: 20px;
  color: #222222;
  pointer-events: none;
}
@media (min-width: 601px) {
  .cms-tablist--squircle .cms-tablist__tab-number {
    font-size: 24px;
    line-height: 24px;
  }
}
.cms-tablist--squircle .cms-tablist__tab-text {
  margin-top: 4px;
  width: 100%;
}

.team-selector__overlay {
  background: rgba(0, 0, 0, 0.8);
  background-size: cover;
  bottom: 0;
  display: none;
  left: 0;
  letter-spacing: normal;
  opacity: 1;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 400ms ease-in;
  word-spacing: normal;
  z-index: 2000;
}
@media (min-width: 601px) {
  .team-selector__overlay {
    padding: 0 30px;
  }
}
.team-selector__modal {
  background: #ffffff;
  max-width: 1064px;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 2001;
  height: 100%;
  overflow-y: auto;
}
@media (min-width: 601px) {
  .team-selector__modal {
    border-radius: 8px;
    height: auto;
    overflow: visible;
    margin: 10% auto;
    padding: 20px 60px 60px;
  }
}
.team-selector__header {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  background-color: #F9F9F9;
  border-bottom: 2px solid #E0E0E0;
  height: 62px;
  padding: 0 24px;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 2000;
  align-items: center;
}
@media (min-width: 601px) {
  .team-selector__header {
    display: block;
    position: relative;
    background-color: transparent;
    border: 0;
    height: auto;
    padding: 0;
    z-index: inherit;
  }
}
.team-selector__close {
  background-color: #000000;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  float: right;
  font-size: 26px;
  height: 30px;
  width: 30px;
  justify-content: center;
  align-items: center;
}
@media (min-width: 601px) {
  .team-selector__close {
    font-size: 45px;
    width: 55px;
    height: 55px;
    transform: translateY(-87px);
  }
}
.team-selector__title {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  display: none;
  padding-left: 5px;
}
@media (min-width: 601px) {
  .team-selector__title {
    display: block;
    font-size: 48px;
    margin-bottom: 50px;
    margin-top: 40px;
    padding-left: 8px;
    width: 100%;
    line-height: 56px;
  }
}
@supports (display: grid) {
  .team-selector__title {
    padding-left: 0;
  }
}
.team-selector__mobile-title {
  font-family: TeliaSansHeading, TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  display: block;
  font-size: 20px;
  width: 70%;
}
@media (min-width: 601px) {
  .team-selector__mobile-title {
    display: none;
  }
}
.team-selector__content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 75px 16px 0;
  /* This will match the Edge browser */
  /* Some versions of Edge think they support grid but they actually don't */
}
@supports (display: grid) {
  .team-selector__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(105px, 1fr));
    grid-gap: 10px;
  }
  @media (min-width: 601px) {
    .team-selector__content {
      grid-gap: 15px;
      grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
    }
  }
}
@supports (-ms-ime-align: auto) {
  .team-selector__content {
    display: flex;
  }
}
@media (min-width: 601px) {
  .team-selector__content {
    margin-top: 0px;
    padding: 0;
  }
}
.team-selector__team-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #E0E0E0;
  border-radius: 8px;
  cursor: pointer;
  padding: 10px 20px;
  margin: 5px;
  /* This will match the Edge browser */
  /* Some versions of Edge think they support grid but they actually don't */
}
@media (min-width: 601px) {
  .team-selector__team-container {
    padding: 16px 33px;
    margin: 8px;
  }
}
@supports (display: grid) {
  .team-selector__team-container {
    margin: 0;
  }
}
@supports (-ms-ime-align: auto) {
  .team-selector__team-container {
    margin: 5px;
  }
  @media (min-width: 601px) {
    .team-selector__team-container {
      margin: 8px;
    }
  }
}
.team-selector__team-name {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #990AE3;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  margin-top: 10px;
}
@media (min-width: 601px) {
  .team-selector__team-name {
    font-size: 20px;
  }
}
.team-selector__team-image {
  width: 50px;
  height: 50px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (min-width: 601px) {
  .team-selector__team-image {
    width: 100px;
    height: 100px;
  }
}

.cms-telephone-number-offers {
  margin-bottom: 20px;
}
.cms-telephone-number-offers > .form-container {
  margin: 0;
}
@media (min-width: 841px) {
  .cms-telephone-number-offers > .form-container {
    padding-top: 1.75em;
    padding-bottom: 1.75em;
  }
}
@media (min-width: 841px) {
  .cms-telephone-number-offers {
    margin-bottom: 0;
  }
}
.cms-telephone-number-offers--dark {
  color: #ffffff;
}
.cms-telephone-number-offers--background-transparent {
  background-color: transparent;
}
.cms-telephone-number-offers--background-white {
  background-color: #ffffff;
}
.cms-telephone-number-offers--background-lightGrey {
  background-color: #F2F2F2;
}
.cms-telephone-number-offers--background-darkGrey {
  background-color: #3A3A3A;
}
.cms-telephone-number-offers__anchor {
  position: relative;
  display: block;
  visibility: hidden;
  top: -64px;
}
@media (min-width: 841px) {
  .cms-telephone-number-offers__anchor {
    top: -78px;
  }
}
@media (min-width: 841px) {
  .main-container > .cms-telephone-number-offers__anchor:first-child {
    top: -110px;
  }
}
.cms-telephone-number-offers__title {
  max-width: unset;
  margin-bottom: 20px;
}
@media (min-width: 841px) {
  .cms-telephone-number-offers__title {
    margin-bottom: 29px;
  }
}
.cms-telephone-number-offers--dark .cms-telephone-number-offers__title {
  color: #ffffff;
}
.cms-telephone-number-offers__description p {
  max-width: unset;
}
.cms-telephone-number-offers__form-container {
  margin-bottom: 10px;
}
@media (min-width: 841px) {
  .cms-telephone-number-offers__form-container {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
}
.cms-telephone-number-offers .form-container--display-none {
  display: none;
}
.cms-telephone-number-offers .form-container--display-block {
  display: block;
}
.cms-telephone-number-offers__image-container--mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 841px) {
  .cms-telephone-number-offers__image-container--mobile {
    display: none;
  }
}
.cms-telephone-number-offers__image-container--desktop {
  display: none;
}
@media (min-width: 841px) {
  .cms-telephone-number-offers__image-container--desktop {
    display: block;
  }
}
@media (min-width: 1081px) {
  .cms-telephone-number-offers__image-container--desktop {
    display: block;
  }
}
.cms-telephone-number-offers__image-container--left-side {
  padding-right: 30px;
}
@media (min-width: 1081px) {
  .cms-telephone-number-offers__image-container--left-side {
    padding-right: 40px;
  }
}
.cms-telephone-number-offers__image-container--right-side {
  padding-left: 30px;
}
@media (min-width: 1081px) {
  .cms-telephone-number-offers__image-container--right-side {
    padding-left: 40px;
  }
}
.cms-telephone-number-offers__image {
  max-width: 220px;
}
@media (min-width: 481px) {
  .cms-telephone-number-offers__image {
    max-width: 300px;
  }
}
@media (min-width: 1081px) {
  .cms-telephone-number-offers__image {
    max-width: 380px;
  }
}
.cms-telephone-number-offers__input-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
@media (min-width: 601px) {
  .cms-telephone-number-offers__input-container {
    flex-direction: row;
    margin-bottom: 40px;
  }
}
@media (min-width: 841px) {
  .cms-telephone-number-offers__input-container {
    max-width: 600px;
  }
}
.cms-telephone-number-offers__input-container .text-input {
  margin: 0;
  margin-bottom: 15px;
  width: 100%;
}
@media (min-width: 601px) {
  .cms-telephone-number-offers__input-container .text-input {
    margin-bottom: 0;
    padding-right: 20px;
  }
}
@media (min-width: 601px) {
  .cms-telephone-number-offers__input-container .text-input .text-input__error-text {
    position: absolute;
  }
}
.cms-telephone-number-offers__input-container > .button {
  align-self: center;
  margin: 0;
  margin-top: auto;
}
.cms-telephone-number-offers__captcha > .g-recaptcha {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
@media (min-width: 601px) {
  .cms-telephone-number-offers__captcha > .g-recaptcha {
    justify-content: flex-start;
  }
}
.cms-telephone-number-offers__loader {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}
.cms-telephone-number-offers__loader-top {
  display: flex;
  justify-content: center;
  padding: 50px;
}
.cms-telephone-number-offers__notification-container .notification .grid {
  margin: 0;
  width: 100%;
}
.cms-telephone-number-offers__notification-container .notification .grid > .grid__col-12 {
  padding: 0 10px;
}
.cms-telephone-number-offers__result-area {
  overflow: hidden;
}
.cms-telephone-number-offers__display-none {
  display: none;
}
.cms-telephone-number-offers__display-block {
  display: block;
}

@media (max-width: 1080px) {
  .grecaptcha-badge {
    bottom: 60px !important;
  }
}
.text-card {
  border-radius: 16px;
  background-color: white;
  color: #3A3A3A;
  box-shadow: 0 3px 12px 5px rgba(224, 224, 224, 0.8);
  overflow: hidden;
  transition: transform ease 0.2s;
  flex-grow: 1;
}
.text-card--clickable:hover {
  color: #3A3A3A;
  transform: scale(1.02);
  z-index: 2;
}
.text-card__image-container {
  padding: 25px 25px 0 25px;
  height: 220px;
  background-repeat: no-repeat;
  background-size: cover;
}
.text-card__image-container--no-background {
  height: 150px;
}
.text-card__image-container--background-centered {
  background-position: center;
}
.text-card__image-container--background-contained {
  background-size: contain;
}
@media (min-width: 601px) {
  .text-card__image-container {
    padding: 40px 40px 0 40px;
    height: 290px;
  }
  .text-card__image-container--no-background {
    height: 210px;
  }
}
.text-card__foreground-image {
  width: 100%;
  max-height: 100px;
  max-width: 100px;
}
@media (min-width: 601px) {
  .text-card__foreground-image {
    max-height: 150px;
    max-width: 150px;
  }
}
.text-card__text-container {
  padding: 25px 25px;
}
@media (min-width: 601px) {
  .text-card__text-container {
    padding: 25px 40px;
  }
}
.text-card__title {
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  margin-bottom: 25px;
}
@media (min-width: 601px) {
  .text-card__title {
    font-size: 32px;
    line-height: 36px;
  }
}
.text-card__text {
  text-overflow: ellipsis;
  overflow: hidden;
}
.text-card__text p {
  max-width: none;
  line-height: inherit;
}
.text-card__text p:first-child {
  margin-top: 0;
}
.text-card__text p:last-child {
  margin-bottom: 0;
}
.text-card__text .button {
  margin-left: 0;
}
.text-card__link {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 600;
  margin-top: 15px;
  color: #990AE3;
}
.text-card__link:hover {
  color: #CC00FF;
}
.text-card__link .icon {
  height: 10px;
}

.textbox {
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
}
.textbox .table {
  overflow: auto;
}
.textbox table {
  min-width: 37.5em;
}

.thumbs__container {
  display: none;
  color: #707070;
}
.thumbs__container--selected {
  color: #990AE3;
}
.thumbs--enabled .thumbs__container:not(.thumbs__container--selected) {
  color: #000000;
}
.thumbs--enabled .thumbs__container:hover {
  cursor: pointer;
  color: #990AE3;
}
.thumbs--enabled .thumbs__container:hover .thumbs__icon {
  transform: scale(1.1);
}
.thumbs--enabled .thumbs__container:active {
  color: #CC00FF;
}
.thumbs__icon {
  display: inline-block;
  font-size: 32px;
  margin-right: -2px;
  transition: all 0.2s ease-in-out;
}
.thumbs__down-icon {
  vertical-align: top;
  padding-left: 14px;
}
.thumbs__count {
  font-weight: bold;
  vertical-align: super;
}
.thumbs__loader .animation--loader {
  font-size: 32px;
}

#tv-channel-package-list {
  flex-direction: row;
}

.cms-tv-channel-package-list {
  background-color: #ffffff;
}
.cms-tv-channel-package-list [class*=grid__col-] {
  padding-top: 0px;
  padding-bottom: 0px;
}
.cms-tv-channel-package-list__tag-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -8px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 601px) {
  .cms-tv-channel-package-list__tag-container {
    justify-content: center;
  }
}
.cms-tv-channel-package-list__tag-container-input {
  display: none;
}
.cms-tv-channel-package-list__tag-container-input:checked + .cms-tv-channel-package-list__tag-container-tag {
  border: 1px solid #990AE3;
}
.cms-tv-channel-package-list__tag-container-tag {
  display: none;
  background-color: #ffffff;
  border: 1px solid #E0E0E0;
  padding: 5px 12px 3px;
  margin-right: 8px;
  color: #707070;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25rem;
  border-radius: 4px;
  margin-top: 8px;
  /* 
   * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer 
   */
}
.cms-tv-channel-package-list__tag-container-tag span {
  pointer-events: none;
}
.cms-tv-channel-package-list__tag-container-tag span > svg {
  color: #990AE3;
  transform: translateY(1px);
}
@media (hover: hover) and (pointer: fine) {
  .cms-tv-channel-package-list__tag-container-tag:hover {
    border: 1px solid #990AE3;
    cursor: pointer;
  }
}
.cms-tv-channel-package-list__tag-container-tag--active {
  border: 1px solid #990AE3;
}
.cms-tv-channel-package-list__tag-container-show-more {
  display: none;
  align-self: flex-end;
  background-color: #F2F2F2;
  border: 1px solid #E0E0E0;
  padding: 5px 12px 3px;
  color: #707070;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.25rem;
  border-radius: 4px;
  margin-top: 8px;
}
.cms-tv-channel-package-list__tag-container-show-more:hover {
  border: 1px solid #990AE3;
  cursor: pointer;
}
.cms-tv-channel-package-list-category-title {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  text-transform: none;
  font-weight: bold;
  text-align: center;
  margin: 1px auto 27px;
}
@media (min-width: 601px) {
  .cms-tv-channel-package-list-category-title {
    margin: 38px auto 30px;
  }
}
.cms-tv-channel-package-list__filter-title {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  text-transform: none;
  margin: 31px 0 !important;
  text-align: center !important;
}
@media (min-width: 601px) {
  .cms-tv-channel-package-list__filter-title {
    margin: 40px 0 !important;
  }
}
.cms-tv-channel-package-list .cms-tablist__navbar > .grid > .grid__col-12 {
  margin-bottom: -4px;
}
.cms-tv-channel-package-list .cms-tablist--squircle {
  margin: 0;
}
.cms-tv-channel-package-list .cms-tablist__squircle-container {
  width: 68px;
  height: 68px;
}
@media (min-width: 601px) {
  .cms-tv-channel-package-list .cms-tablist__squircle-container {
    width: 96px;
    height: 96px;
  }
}
.cms-tv-channel-package-list .cms-tablist__label {
  border-radius: 9px;
  background-color: #ffffff;
}
@media (min-width: 601px) {
  .cms-tv-channel-package-list .cms-tablist__label .cms-tablist__tab-icon {
    font-size: 24px;
  }
}
@media (min-width: 601px) {
  .cms-tv-channel-package-list .cms-tablist__label .cms-tablist__tab-text {
    margin-top: 8px;
    width: 80%;
  }
}
@media (min-width: 601px) {
  .cms-tv-channel-package-list .cms-tablist__label {
    width: 88px;
    height: 88px;
  }
}
.cms-tv-channel-package-list .cms-tablist__label--active {
  width: 60px;
  height: 60px;
  border-color: #990AE3;
  box-shadow: none;
}
@media (min-width: 601px) {
  .cms-tv-channel-package-list .cms-tablist__label--active {
    width: 88px;
    height: 88px;
  }
}
.cms-tv-channel-package-list .image-hero__texts--button,
.cms-tv-channel-package-list .image-hero__texts--anchor-button {
  margin: 12px 0 0;
}
@media (min-width: 841px) {
  .cms-tv-channel-package-list .image-hero__texts--button,
  .cms-tv-channel-package-list .image-hero__texts--anchor-button {
    margin-top: 16px;
  }
}
@media (min-width: 1921px) {
  .cms-tv-channel-package-list .image-hero__texts--button,
  .cms-tv-channel-package-list .image-hero__texts--anchor-button {
    margin-top: 26px;
  }
}
.cms-tv-channel-package-list .image-hero__texts--button .button,
.cms-tv-channel-package-list .image-hero__texts--anchor-button .button {
  margin-left: 0;
}
.cms-tv-channel-package-list__text-container {
  display: flex;
  justify-content: center;
}
.cms-tv-channel-package-list__body-text .textbox {
  margin: 0 auto;
}
.cms-tv-channel-package-list__body-text h1,
.cms-tv-channel-package-list__body-text h2,
.cms-tv-channel-package-list__body-text h3,
.cms-tv-channel-package-list__body-text h4,
.cms-tv-channel-package-list__body-text h5,
.cms-tv-channel-package-list__body-text p {
  max-width: none;
}

.cms-tv-guide {
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
  /* This is the content that is injeted into the program details modal */
}
.cms-tv-guide__title {
  text-align: center;
  max-width: none;
  margin-bottom: 16px;
}
.cms-tv-guide__loader {
  text-align: center;
  margin-top: 20px;
}
.cms-tv-guide__channel-package-toggle-button {
  color: #3A3A3A;
  position: relative;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 8px 11px 8px 16px;
  height: 38px;
  line-height: 23px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 841px) {
  .cms-tv-guide__channel-package-toggle-button {
    background-color: transparent;
  }
}
.cms-tv-guide__channel-package-toggle-button-text {
  margin-right: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cms-tv-guide__channel-package-toggle-button-icon {
  font-size: 11px;
  color: #990AE3;
}
.cms-tv-guide__channel-package-toggle-button-icon .icon {
  transition: transform 0.5s;
}
.cms-tv-guide__channel-package-toggle-button--active .icon {
  transform: rotate(180deg);
}
.cms-tv-guide__package-panel {
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.12);
  background-color: #ffffff;
  color: #222222;
  font-size: 12px;
  font-weight: 500;
  line-height: 26px;
  max-height: 0;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  /* This will match the IE browser */
}
@media (min-width: 841px) {
  .cms-tv-guide__package-panel {
    order: 2;
    width: 100%;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cms-tv-guide__package-panel {
    display: flex;
    flex-wrap: wrap;
  }
}
.cms-tv-guide__package-panel--item {
  /* This will match the IE browser */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .cms-tv-guide__package-panel--item {
    min-width: 280px;
  }
}
.cms-tv-guide__package-panel--item label {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  line-height: 16px;
  cursor: pointer;
}
.cms-tv-guide__package-panel--item label .icon {
  height: 5px;
  width: 5px;
  min-width: 5px;
  min-height: 5px;
  color: #990AE3;
  margin-left: 12px;
  margin-right: 8px;
}
.cms-tv-guide__package-panel input {
  display: none;
}
.cms-tv-guide__package-panel input:checked + label {
  background-color: #990AE3;
  color: #ffffff;
}
.cms-tv-guide__package-panel input:checked + label .icon {
  color: #ffffff;
}
.cms-tv-guide__package-panel--visible {
  max-height: 1000px;
  margin: 16px 0;
  padding: 16px 0;
}
.cms-tv-guide__filter-container {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 8px;
}
@media (min-width: 841px) {
  .cms-tv-guide__filter-container {
    margin-bottom: 24px;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
}
.cms-tv-guide__filter-container .cms-tv-guide__dropdown + .select2-container {
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
  color: #3A3A3A;
  border-radius: 4px;
  background-color: #ffffff;
  margin: 0 !important;
}
@media (min-width: 841px) {
  .cms-tv-guide__filter-container .cms-tv-guide__dropdown + .select2-container {
    background-color: transparent;
    width: auto !important;
  }
}
.cms-tv-guide__filter-container .cms-tv-guide__dropdown + .select2-container .ppr-select__container {
  border: none;
  padding: 8px 16px;
}
.cms-tv-guide__filter-container .cms-tv-guide__dropdown + .select2-container .ppr-select__container .select2-selection__rendered {
  height: 20px;
  line-height: unset;
  margin-right: 20px;
}
.cms-tv-guide__filter-container .cms-tv-guide__dropdown + .select2-container .ppr-select__container .select2-selection__arrow {
  top: 8px;
  width: 22px;
  color: #990AE3;
}
.cms-tv-guide__filter {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}
@media (min-width: 841px) {
  .cms-tv-guide__filter {
    margin: 8px;
  }
}
.cms-tv-guide__filter--hidden {
  display: none;
}
.cms-tv-guide__filter label {
  color: #707070;
  min-height: 1em;
  font-size: 12px;
  line-height: 20px;
}
@media (min-width: 841px) {
  .cms-tv-guide__filter label {
    min-height: 20px;
    padding-left: 16px;
  }
}
.cms-tv-guide__carousel-container {
  display: none;
  position: relative;
  overflow: hidden;
}
.cms-tv-guide__content-carousel {
  position: relative;
  max-height: 160px;
}
.cms-tv-guide__content-carousel--md {
  max-height: 300px;
}
.cms-tv-guide__content-carousel--lg {
  max-height: 500px;
}
.cms-tv-guide__content-carousel--xlg {
  max-height: 650px;
}
.cms-tv-guide__content-carousel--xxlg {
  max-height: none;
}
@media (max-width: 1080px) {
  .cms-tv-guide__content-carousel {
    max-height: 300px;
  }
}
.cms-tv-guide__carousel {
  margin: 0;
  padding-bottom: 8px;
}
@media (min-width: 841px) {
  .cms-tv-guide__carousel {
    margin: 0 24px 0 24px;
  }
}
.cms-tv-guide__carousel .cms-tv-guide__content-carousel {
  display: flex;
  flex-direction: row;
}
.cms-tv-guide__carousel::before, .cms-tv-guide__carousel::after {
  content: "";
  z-index: 1;
  position: absolute;
  top: 0;
  height: 100%;
  width: 24px;
  pointer-events: none;
}
@media (min-width: 841px) {
  .cms-tv-guide__carousel::before, .cms-tv-guide__carousel::after {
    width: 80px;
  }
}
.cms-tv-guide__carousel::before {
  background: linear-gradient(to left, rgba(242, 242, 242, 0), #f2f2f2);
  left: 0;
}
.cms-tv-guide__carousel::after {
  background: linear-gradient(to right, rgba(242, 242, 242, 0), #f2f2f2);
  right: 0;
}
.cms-tv-guide__carousel-arrow {
  width: 45px;
  height: 45px;
  position: absolute;
  top: 50%;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 999px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  z-index: 1;
  transform: translateY(-50%);
}
@media (max-width: 1080px) {
  .cms-tv-guide__carousel-arrow {
    display: none !important;
  }
}
.cms-tv-guide__carousel-arrow .icon {
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
}
.cms-tv-guide__carousel-arrow:hover {
  color: #CC00FF;
}
.cms-tv-guide__carousel-arrow.swiper-button-disabled {
  display: none !important;
}
.cms-tv-guide__carousel-arrow--left {
  left: 0;
}
.cms-tv-guide__carousel-arrow--left .icon {
  transform: translate(-60%, -50%);
}
.cms-tv-guide__carousel-arrow--right {
  right: 0;
}
.cms-tv-guide__carousel-arrow--right .icon {
  transform: translate(-40%, -50%);
}
.cms-tv-guide__channel {
  display: flex;
  flex-direction: column;
  margin: 0 8px;
  min-width: 192px;
  max-width: 192px;
  height: 100%;
}
.cms-tv-guide__channel-heading {
  display: flex;
  flex-direction: column;
  min-width: 192px;
  max-width: 192px;
  align-items: center;
}
.cms-tv-guide__channel-logo {
  height: 60px;
  margin-bottom: 8px;
}
.cms-tv-guide__channel-title {
  display: flex;
  align-items: center;
  min-height: 60px;
  font-weight: 500;
  margin-bottom: 8px;
}
.cms-tv-guide__channel-content {
  height: 100%;
  border-radius: 0 0 8px 8px;
  background-color: #F9F9F9;
  box-shadow: 1px 4px 5px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
}
.cms-tv-guide__channel-content--rounded {
  border-radius: 8px;
  padding-top: 0;
  margin-top: 0;
}
.cms-tv-guide__program--not-found:first-of-type {
  text-align: center;
  background-color: #F9F9F9;
  padding-top: 16px;
  margin-bottom: 8px;
}
.cms-tv-guide__program--active {
  width: 100%;
  background-color: #ffffff;
  z-index: 1;
  border-radius: 8px 8px 0 0;
}
@media (min-width: 841px) {
  .cms-tv-guide__time-slot {
    border-bottom: solid 1px #E0E0E0;
    min-height: 40px;
  }
  .cms-tv-guide__time-slot:last-of-type, .cms-tv-guide__time-slot--hidden {
    border-bottom: none;
  }
}
.cms-tv-guide__program-content {
  padding: 4px 8px;
  display: flex;
  align-items: baseline;
}
.cms-tv-guide__program-content--no-content {
  justify-content: center;
  margin-top: 4px;
}
.cms-tv-guide__program-content--no-content .cms-tv-guide__program-time {
  display: none;
}
.cms-tv-guide__program-content--no-content .cms-tv-guide__program-name {
  color: #3A3A3A;
}
.cms-tv-guide__program-time {
  font-size: 11px;
  color: #A0A0A0;
  margin-right: 12px;
}
.cms-tv-guide__program-name, .cms-tv-guide__program--not-found {
  font-size: 13px;
  color: #3A3A3A;
  line-height: 16px;
  min-height: 32px;
  word-break: break-word;
}
.cms-tv-guide__program-progress {
  margin: 4px 8px;
  position: relative;
  height: 4px;
}
.cms-tv-guide__program-progress progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  height: 4px;
  width: 100%;
  border-radius: 999px;
  background-color: #E0E0E0;
  color: #990AE3;
}
.cms-tv-guide__program-progress progress::-webkit-progress-bar {
  background-color: #E0E0E0;
  border-radius: 16px;
}
.cms-tv-guide__program-progress progress::-webkit-progress-value {
  background-color: #990AE3;
  border-radius: 16px;
}
.cms-tv-guide__program-progress progress::-moz-progress-bar {
  background-color: #990AE3;
  border-radius: 16px;
}
.cms-tv-guide__program-details {
  display: none;
}
.cms-tv-guide__program-details-subtitle {
  color: #3A3A3A;
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;
}
.cms-tv-guide__program-details-description {
  margin-top: 24px;
  color: #3A3A3A;
  font-size: 16px;
  line-height: 24px;
}
.cms-tv-guide__program-details-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  text-align: right;
}
.cms-tv-guide__program-details-footer img {
  height: 60px;
}
.cms-tv-guide__show-more {
  margin-top: 16px;
  text-align: center;
}

.cms-twitter-feed {
  /* this is element that the twitter widget generates for a single tweet */
}
.cms-twitter-feed twitter-widget.twitter-tweet {
  min-width: 0 !important;
  width: auto !important;
}
.cms-twitter-feed__loader {
  padding: 20px;
  text-align: center;
}

.cms-ulm-strong-auth-finalize {
  color: #990AE3;
}

.unique-selling-point {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-self: center;
  max-width: 100%;
  /* This will match the Edge browser */
  /* Some versions of Edge think they support grid but they actually don't */
  margin-top: 1.1em;
  margin-bottom: 1.1em;
}
.unique-selling-point--grey .unique-selling-point__link-container, .unique-selling-point--grey .unique-selling-point__text-container {
  color: #707070;
}
.unique-selling-point--black .unique-selling-point__link-container, .unique-selling-point--black .unique-selling-point__text-container {
  color: #000000;
}
@supports (display: grid) {
  @media (min-width: 601px) {
    .unique-selling-point {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(calc(50% - 35px), 1fr));
    }
  }
  @media (min-width: 841px) {
    .unique-selling-point {
      grid-template-columns: repeat(auto-fill, minmax(calc(33% - 35px), 1fr));
    }
  }
}
@supports (-ms-ime-align: auto) {
  .unique-selling-point {
    display: flex;
  }
}
@media (min-width: 601px) {
  .unique-selling-point {
    align-self: auto;
    flex-direction: row;
    margin-top: 2.5em;
    margin-bottom: 2.5em;
  }
}
@media (min-width: 841px) {
  .unique-selling-point {
    margin-top: 3.5em;
    margin-bottom: 3.5em;
  }
}
.unique-selling-point--no-margin {
  margin-top: 0;
  margin-bottom: 0;
}
.unique-selling-point__item {
  margin: 5px 0;
}
@media (min-width: 601px) {
  .unique-selling-point__item {
    flex-basis: calc(50% - 35px);
    margin: 10px 15px;
  }
}
@media (min-width: 841px) {
  .unique-selling-point__item {
    flex-basis: calc(33% - 35px);
  }
}
.unique-selling-point__icon {
  display: flex;
  align-items: center;
}
.unique-selling-point__icon .icon {
  width: 28px;
  height: 28px;
  min-width: 28px;
}
@media (min-width: 601px) {
  .unique-selling-point__icon .icon {
    width: 35px;
    height: 35px;
    min-width: 35px;
  }
}
.unique-selling-point__image-container {
  display: flex;
  align-items: center;
  height: 28px;
}
@media (min-width: 601px) {
  .unique-selling-point__image-container {
    height: 35px;
  }
}
.unique-selling-point__image {
  width: 28px;
  min-width: 28px;
}
@media (min-width: 601px) {
  .unique-selling-point__image {
    width: 35px;
    min-width: 35px;
  }
}
.unique-selling-point__title {
  font-family: TeliaSans, "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  margin-left: 10px;
}
@media (min-width: 841px) {
  .unique-selling-point__title {
    line-height: 18px;
    font-size: 15px;
    margin-left: 15px;
  }
}
.unique-selling-point__text {
  font-size: 12px;
  line-height: 17px;
  margin-left: 10px;
}
@media (min-width: 841px) {
  .unique-selling-point__text {
    line-height: 18px;
    font-size: 13px;
    margin-left: 15px;
  }
}
.unique-selling-point__title + .unique-selling-point__text {
  margin-top: 3px;
}
.unique-selling-point__link-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.unique-selling-point__link-container:hover, .unique-selling-point__link-container:active {
  color: #CC00FF;
}
.unique-selling-point__text-container {
  display: flex;
  align-items: center;
}

.cms-user-properties {
  max-width: 100%;
  overflow: hidden;
  margin: 20px;
}
.cms-user-properties table tr:nth-child(odd) {
  background-color: #E0E0E0;
}

.validate-email .notification__delete {
  display: none;
}

.cms-video-hero {
  position: relative;
  color: #121214;
}
@media (min-width: 601px) {
  .cms-video-hero--color-black {
    color: #121214;
  }
}
@media (min-width: 601px) {
  .cms-video-hero--color-purple {
    color: #29003e;
  }
}
@media (min-width: 601px) {
  .cms-video-hero--color-white {
    color: #ffffff;
  }
}
@media (min-width: 601px) {
  .cms-video-hero--color-deepPurple {
    color: #29003e;
  }
}
@media (min-width: 601px) {
  .cms-video-hero--color-deepBeige {
    color: #5C524E;
  }
}
@media (min-width: 601px) {
  .cms-video-hero--color-lightPurple {
    color: #F4E0FF;
  }
}
@media (min-width: 601px) {
  .cms-video-hero--color-lightBeige {
    color: #F8EEE8;
  }
}
@media (max-width: 600px) {
  .cms-video-hero--mobile-color-black {
    color: #121214;
  }
  .cms-video-hero--mobile-color-purple {
    color: #29003e;
  }
  .cms-video-hero--mobile-color-white {
    color: #ffffff;
  }
  .cms-video-hero--mobile-color-deepPurple {
    color: #29003e;
  }
  .cms-video-hero--mobile-color-deepBeige {
    color: #5C524E;
  }
  .cms-video-hero--mobile-color-lightPurple {
    color: #F4E0FF;
  }
  .cms-video-hero--mobile-color-lightBeige {
    color: #F8EEE8;
  }
}
.cms-video-hero__video-container {
  position: relative;
  overflow: hidden;
  height: 224px;
}
@media (min-width: 601px) {
  .cms-video-hero__video-container {
    height: 344px;
  }
}
@media (min-width: 841px) {
  .cms-video-hero__video-container {
    min-height: 360px;
    height: 80vh;
    max-height: 468px;
  }
}
@media (min-width: 1081px) {
  .cms-video-hero__video-container {
    min-height: 392px;
    height: 80vh;
    max-height: 509.6px;
  }
}
@media (min-width: 1441px) {
  .cms-video-hero__video-container {
    min-height: 480px;
    height: 80vh;
    max-height: 624px;
  }
}
.cms-video-hero__video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
@supports (-ms-ime-align: auto) {
  .cms-video-hero__video {
    height: auto;
  }
}
.cms-video-hero _::selection, .cms-video-hero__video {
  height: auto\0 ;
}
.cms-video-hero__grid {
  height: 100%;
}
.cms-video-hero__container {
  padding: 62px 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 600px) {
  .cms-video-hero__container {
    background-color: #F2F2F2;
  }
  .cms-video-hero__container--mobile-background-lightGrey {
    background-color: #F2F2F2;
  }
  .cms-video-hero__container--mobile-background-purple {
    background-color: #990AE3;
  }
  .cms-video-hero__container--mobile-background-black {
    background-color: #222222;
  }
  .cms-video-hero__container--mobile-background-white {
    background-color: #ffffff;
  }
  .cms-video-hero__container--mobile-background-transparent {
    background-color: transparent;
  }
  .cms-video-hero__container--mobile-background-deepBeige {
    background-color: #5C524E;
  }
  .cms-video-hero__container--mobile-background-beige {
    background-color: #ECDED4;
  }
  .cms-video-hero__container--mobile-background-deepPurple {
    background-color: #29003e;
  }
  .cms-video-hero__container--mobile-background-lightPurple {
    background-color: #F4E0FF;
  }
  .cms-video-hero__container--mobile-background-lightBeige {
    background-color: #F8EEE8;
  }
}
@media (min-width: 601px) {
  .cms-video-hero__container {
    position: absolute;
  }
}
.cms-video-hero__content--align-left {
  text-align: left;
}
.cms-video-hero__content--align-center {
  text-align: center;
}
.cms-video-hero__content--align-right {
  text-align: right;
}
.cms-video-hero__heading {
  margin: 0;
}
.cms-video-hero__description {
  max-width: none;
  margin: 1em 0;
  font-size: 1.125rem;
  line-height: 1.22222;
}
@media (min-width: 601px) {
  .cms-video-hero__description {
    font-size: 1.25rem;
  }
}
@media (min-width: 841px) {
  .cms-video-hero__description {
    font-size: 1.5rem;
  }
}
@media (min-width: 1441px) {
  .cms-video-hero__description {
    font-size: 1.875rem;
  }
}
.cms-video-hero__heading, .cms-video-hero__description {
  color: inherit;
}
@media (min-width: 601px) {
  .cms-video-hero__heading--color-white, .cms-video-hero__description--color-white {
    color: #ffffff;
  }
}
@media (min-width: 601px) {
  .cms-video-hero__heading--color-black, .cms-video-hero__description--color-black {
    color: #121214;
  }
}
@media (min-width: 601px) {
  .cms-video-hero__heading--color-purple, .cms-video-hero__description--color-purple {
    color: #29003e;
  }
}
@media (min-width: 601px) {
  .cms-video-hero__heading--color-deepPurple, .cms-video-hero__description--color-deepPurple {
    color: #29003e;
  }
}
@media (min-width: 601px) {
  .cms-video-hero__heading--color-deepBeige, .cms-video-hero__description--color-deepBeige {
    color: #5C524E;
  }
}
@media (min-width: 601px) {
  .cms-video-hero__heading--color-lightPurple, .cms-video-hero__description--color-lightPurple {
    color: #F4E0FF;
  }
}
@media (min-width: 601px) {
  .cms-video-hero__heading--color-lightBeige, .cms-video-hero__description--color-lightBeige {
    color: #F8EEE8;
  }
}
@media (max-width: 600px) {
  .cms-video-hero__heading--mobile-color-white, .cms-video-hero__description--mobile-color-white {
    color: #ffffff;
  }
  .cms-video-hero__heading--mobile-color-black, .cms-video-hero__description--mobile-color-black {
    color: #121214;
  }
  .cms-video-hero__heading--mobile-color-purple, .cms-video-hero__description--mobile-color-purple {
    color: #29003e;
  }
  .cms-video-hero__heading--mobile-color-deepPurple, .cms-video-hero__description--mobile-color-deepPurple {
    color: #29003e;
  }
  .cms-video-hero__heading--mobile-color-deepBeige, .cms-video-hero__description--mobile-color-deepBeige {
    color: #5C524E;
  }
  .cms-video-hero__heading--mobile-color-lightPurple, .cms-video-hero__description--mobile-color-lightPurple {
    color: #F4E0FF;
  }
  .cms-video-hero__heading--mobile-color-lightBeige, .cms-video-hero__description--mobile-color-lightBeige {
    color: #F8EEE8;
  }
}

.youtube__container {
  margin: 20px auto;
  height: 100%;
  background-color: #000;
}
.youtube__container:hover .youtube__play-button {
  background-image: url("../assets/images/components/youtube/youtube-play.png");
}
.youtube__player {
  display: block;
  width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
  height: 100%;
  cursor: pointer;
}
.youtube__thumb {
  bottom: 0;
  display: block;
  left: 0;
  margin: auto;
  max-width: 100%;
  width: 100%;
  position: absolute;
  right: 0;
  top: 0;
  height: auto;
}
.youtube__play-button {
  height: 40px;
  width: 40px;
  left: 50%;
  top: 50%;
  margin-left: -20px;
  margin-top: -10px;
  position: absolute;
  background: url("../assets/images/components/youtube/youtube-play-dark.png") no-repeat;
  background-size: 40px;
}

.zone-indicator__status--hidden {
  display: none;
}

.eshop-content-area {
  height: 500px;
  background-color: #C5C5C5;
  display: flex;
  align-items: center;
  justify-content: center;
}