.error-and-search {
  padding-bottom: 5em;
  background-color: color(grey10);
  border-top: 1px solid color(grey30);
  border-bottom: 1px solid color(grey30);
  text-align: center;

  &__title {
    color: color(black);
    margin-bottom: 0;
  }

  &__body {
    max-width: 800px;
    margin: 1em auto;
    color: color(grey70);
  }

  &__form {
    position: relative;
    margin: auto;
    width: 90%;

    @include from('sm') {
      width: 80%;
    }

    @include from('lg') {
      width: 700px;
    }
  }

  &__search-field {
    padding: 1em 1em 1em 3em;
    width: 100%;
  }

  &__search-icon {
    position: absolute;
    left: 1em;
    top: 1em;
  }
}
