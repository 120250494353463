.b2b-landline-disconnection-form {

  &__submit {
    margin-top: 32px;
  }

  &__privacy-notice {
    margin-top: 32px;
  }

  &__addMoreLandlineData {
    font-size: 23px;
    cursor: pointer;
    position: relative;
    top: 24px;
    @include from('sm') {
      left: 5px;
    }

    &:hover {
      color: hover(corePurple);
    }
  }

  &__landlineNoTitle {
    position: relative;
    top: 20px;
  }

  &__deleteRow {
    font-size: 23px;
    cursor: pointer;
    @include from('sm') {
      margin-top: 52px;
    }

    &:hover {
      color: hover(corePurple);
    }
  }

  &__seperator {
    display: block;
    border-bottom: 2px solid color(corePurple);
    margin-top: 48px;
    @include from('sm') {
      display: none;
    }
  }

  &__element-group {
    background: color(white);
    border-radius: 4px;
    padding: 0px 18px 47px 13px;
    box-shadow: 1px 2px 2px color(grey30);
  }

  &__disconnectiondate-desc {
    margin-top: -24px;
  }

  .landlineDataGroup {
    @include from('sm') {
      padding-left: 24px;
    }
  }

  .inputTextWrapper {
    @include from('sm') {
      padding-left: 12px;
    }
  }

  .error {
    border-bottom-color: color(red);
  }

  .error + label {
    color: color(red);
  }
}

.b2b-landline-disconnection {
  &__error-message {
    color: color(red);
  }
}
