.person {
  margin-top: 45px;
  margin-bottom: 55px;
  -ms-flex-negative: 0;

  &__name {
    margin-top: 30px;
  }

  &__picture {
    margin-top: 35px;
    -ms-flex-negative: 0;

    @include from('md') {
      width: 70%;
    }
  }

  &__image {
    width: 100%;
    object-fit: cover;
    margin: auto;
    border-radius: 50%;
    display: block;
  }

  &__ingress {
    font-size: 12px;
    letter-spacing: 1.56px;
    line-height: 16px;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    top:28px;
  }

  &__back-icon {
    vertical-align: middle;
  }
}
