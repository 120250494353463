.content-central-article-teaser {
  &__container {
    color: color(white);
    overflow: hidden;
    position: relative;
    transition: transform 0.2s ease 0s;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    a,
    a:hover {
      color: color(white) !important;
    }

    &:hover {
      transform: scale(1.03);
    }
  }

  &__article {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    color: color(grey100);
    display: flex;
    flex-direction: column;
    padding: 20px;
    transition: transform ease 0.2s;
    border-radius: 6px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: color(grey100);
    text-align: left !important;

    &::after {
      display: block;
      position: absolute;
      content: ' ';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 1;
      transition: opacity 0.5s;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 6px;
    }

    &--has-image {
      color: color(white);
    }
  }

  &__overlay {
    opacity: 0;
    pointer-events: none;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0px;
    bottom: 0;
    transition: opacity 0.2s;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }

  &__article:hover &__overlay {
    opacity: 1;
  }

  &__categories {
    font-size: 14px;
  }

  &__description {
    margin-top: auto;
  }

  &__title {
    $fontSize: 16px;
    $maxLines: 3;
    font-size: $fontSize;
    line-height: $fontSize;
    -webkit-line-clamp: $maxLines;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    max-height: $maxLines * $fontSize;
    font-weight: 500;
    overflow: hidden;
    margin-top: 0;
    margin-bottom: 0.4em;

    @include from('xlg') {
      $fontSize: 30px;
      font-size: $fontSize;
      line-height: $fontSize;
      max-height: $maxLines * $fontSize;
    }
  }

  &__ingress,
  &__author,
  &__title,
  &__description,
  &__categories,
  &__article-reading-time {
    z-index: z(forward);
  }

  &__ingress {
    font-weight: 500;
    max-height: em(69px); // Three line heights, for non-webkit browsers
    -webkit-line-clamp: 3;
    text-overflow: ellipsis;
    display: -webkit-box;
    overflow: hidden;
  }

  &__author {
    font-size: 14px;
  }

  &__article-reading-time {
    margin-top: 20px;
    font-size: 14px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__categories,
  &__article-reading-time {
    font-weight: 600;
  }
}
