.cms-customer-support-card {
    min-height: 100px;

    &__accessible-list {
        max-width: none;
        padding-left: 0;
        margin-left: 0px;
    }

    &__accessible-list-item {
        list-style-type: none;
    }
}

.cms-customer-support-card__description {
    /** To reset user agent styles for html that may come from the data */
    /** TODO: the p margin should probably be removed so paragraphs from the rich text editor are visible */
    p {
        margin: 0;
        max-width: 600px;
    }
    .button {
        margin-left: 0;
        margin-top: 16px;
    }
}