.search {

  &--with-image {
    position: relative;
  }

  &__container {
    min-height: 480px;
    position: relative;
    z-index: z(moreForward);

    &--small {
      min-height: 180px;
    }
  }

  &__background {
    position: absolute;
    height: 480px;
    max-height: 480px;
    width: 100%;
    z-index: z(forward);
    transition: max-height .3s ease-in-out;

    &--small {
      max-height: 180px;
    }
  }

  &--searched &__background {
    max-height: 375px;

    &--small {
      max-height: 180px;
    }
  }

  &__background-image {
    background-size: cover;
    width: 100%;
    height: 100%;
    background-position: 0 0;

    &--desktop {
      @include to('sm') {
        display: none;
      }
    }

    &--mobile {
      @include from('md') {
        display: none;
      }
    }
  }

  &--with-image &__header {
    padding: 164px 0 0;
  }

  .heading-component__heading {
    margin-top: 0;
    margin-bottom: 0;
  }
}
