.marketing-banner-50-50 {
  overflow: hidden;
  position: relative;

  &--border {
    border-color: color(corePurple);
    border-style: solid;
    border-width: 1px 0 1px 0;
  }

  &__column {
    float: left;
    padding-left: 0;
    padding-right: 0;

    &--border {
      border-color: color(corePurple);
      border-style: solid;
      border-width: 0 0 1px 0;

      @include from('md') {
        border-width: 0 1px 0 0;
      }
    }

    // Core purple background deprecated, only here for backwards compatibility
    // Brand guidelines say that we shouldn't use this as a background color
    &--corePurple {
      background-color: color(corePurple);
    }

    &--white {
      background-color: color(white);
    }

    &--lightGrey {
      background-color: color(lightGray);
    }
  
    &--black {
      background-color: color(offBlack);
    }
  
    &--deepPurple {
      background-color: color(deepPurple);
    }
  
    &--beige {
      background-color: color(beige);
    }
  
    &--lightPurple {
      background-color: color(lightPurple);
    }
  
    &--lightBeige {
      background-color: color(lightBeige);
    }
  }

  &__content {
    height: 312px;
    position: relative;

    @include from('md') {
      height: 400px;
    }
  }

  &__image {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &__background {
    position: relative;
    height: 100%;
    background-position: 100%;
    background-size: auto 100%;
    background-repeat: no-repeat;

    &--stretch {
      background-size: cover;
    }

    &--mobile {
      @include from('md') {
        display: none;
        background: none !important;
      }
    }

    &--desktop {
      @include to('sm') {
        display: none;
        background: none !important;
      }
    }
  }

  &__overlay {
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }

  &__text-area-container {
    height: 100%;
    word-spacing: normal;
    letter-spacing: normal;
  }

  &__text-area {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: z(heroText);
    font-size: 14px;
    line-height: 18px;

    h3 {
      margin-top: 0;
    }
    
    &--white {
      color: color(white);
    }
    
    &--black {
      color: color(offBlack);
    }

    &--grey {
      color: color(lightGray);
    }

    &--deepPurple {
      color: color(deepPurple);
    }

    &--deepBeige {
      color: color(deepBeige);
    }

    &--beige {
      color: color(beige);
    }

    &--lightPurple {
      color: color(lightPurple);
    }

    &--lightBeige {
      color: color(lightBeige);
    }
  }

  &__heading {
    color: inherit;
    margin-bottom: 0;
  }

  &__text {
    margin: 0.6em 0;
  }

  &__button {
    a {
      margin-left: 0;
    }

    .button {
      width: auto;
    }
  }
}
