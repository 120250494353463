.cms-store-locator {
  height: 100%;
  background-color: color(white);
  
  &--in-list-view {
    background-color: color(grey20);
  }

  &__loader {
    display: flex;
    justify-content: center;
    padding: 48px;
  }

  &__change-view-button-container {
    position: absolute;
    z-index: z(moreForward);
    top: 10px;
    right: 10px;

    @include from('sm') {
      top: 24px;
      right: 24px;
    }
  }

  /* Telia react component library styles are added to the page after these styles so we need these to be more specific for them to work. */
  .telia-react-element.cms-store-locator__change-view-button {
    background-color: color(white);
    padding: 8px 16px;
    border-radius: 4px;
    border: solid 1px;
    border-color: color(grey40);

    & .telia-react-icon {
      min-width: 16px;
      min-height: 16px;
      width: 16px;
      height: 16px;
      max-width: 16px;
      max-height: 16px;
    }

    &:focus {
      outline: none;

      &::before {
        transition: box-shadow 150ms ease;
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 4px;
        border: 2px solid #40b3ff;
      }
    }

    /* Can't manage this out of the box with the component library functionality. SelectionButtonGroup is also not versatile enough. */
    .telia-react-typography,
    .telia-react-gutter {
      display: none;

      @include from('md') {
        display: block;
      }
    }

    &--in-list-view {
      .telia-react-typography,
      .telia-react-gutter {
        display: none;

        @include from('xs') {
          display: block;
        }
      }
    }

    &--selected {
      border-color: color(corePurple);
    }
  }

  &__side-panel {
    overflow-y: auto;

    .cms-store-locator--in-list-view & {
      order: 1;

      @include from('md') {
        order: 0;
      }
    }

    background-color: color(white);
    width: 100%;

    @include from('md') {
      width: 392px;
      min-width: 392px;

      .cms-store-locator--support-footer & {
        max-height: calc(100vh - 110px); //110px is the navigation height (top-bar included)
        min-height: calc(100vh - 110px); 
      }

      .cms-store-locator--in-list-view.cms-store-locator--support-footer & {
        max-height: none;
      }
    }
  }

  &__side-panel-search {
    .telia-react-input__container {
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }
  }

  &__side-panel-search-result-button {
    background-color: transparent;
    text-align: left;
    cursor: pointer;

    &:focus {
      outline-offset: -4px;
      outline: solid 4px color(blue);
    }
  }

  &__side-panel-store-marker-container {
    position: relative;
    width: 28px;
  }

  &__side-panel-store-marker {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 28px;
    width: 28px;
  }

  &__info-window {
    // We add the component library styles after pride styles so our styles with the same specificity will get overridden by the component library syles
    max-width: 312px !important;
  }

  &__info-window-opening-times {
    flex-grow: 1;
  }

  &__info-window-opening-time-title,
  &__info-window-opening-time {
    // We add the component library styles after pride styles so our styles with the same specificity will get overridden by the component library syles
    line-height: 18px !important;
  }

  &__main-container {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    
    @include to('md') {
      padding: 0;
    }
    
    @include from('md') {
      height: 100%;
      flex-direction: row;
    }

    &.grid {
      padding: 0;
    }
  }

  &__map-view {
    height: 50vh;

    @include from('md') {
      height: 100%;
      max-height: calc(100vh - 110px);
    }
  }

  &__map-view-map {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  &__list-view-container {
    padding-top: 0 !important;

    @include from('md') {
      padding-top: 56px !important;
    }

    overflow-y: auto;
  }

  &__list-view-wrapper {
    @include from('md') {
      height: 100%;
    }
  }
}
