$slicer-breakpoints:        0        480px       600px       840px       1080px      1440px      1920px;
//                          └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────┘ └────┬────
$slicer-breakpoint-names:      'xxs'        'xs'        'sm'        'md'        'lg'       'xlg'       'xxlg';

// Grid
// -------------------------
$reflex-columns: 12; // number of columns
$reflex-max-width: 1920px; // max-width of grid

// Breakpoints
// -------------------------
$reflex-xs: 481px;
$reflex-sm: 601px;
$reflex-md: 841px;
$reflex-lg: 1081px;
$reflex-xlg: 1441px;

// Spacing
// -------------------------
$reflex-grid-spacing:       0 16px;
$reflex-cell-spacing:       16px;

$reflex-cell-spacing-sm:    12px;
$reflex-cell-spacing-md:    $reflex-cell-spacing;
$reflex-cell-spacing-lg:    24px;
