$new-product-card-breakpoint: 480px;

.cms-plan-card {
  box-sizing: border-box;
  background: color(white);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  transition: transform ease 0.3s, box-shadow ease 0.3s;
  cursor: pointer;
  position: relative;
  text-align: left;

  &__badge {
    position: absolute;
    top: 0;
    font-weight: 500;
    font-size: 14px;
    border-radius: 2px;
    line-height: 1;
    padding: 4px 8px;

    @media (max-width: $new-product-card-breakpoint) {
      left: 16px;
    }
  
    @media (min-width: $new-product-card-breakpoint) {
      left: 48px;
    }

    &--specialOffer {
      background-color: rgb(237, 200, 255);
      color: rgb(41, 0, 62);
    }

    &--attention {
      background-color: rgb(255, 195, 102);
      color: rgb(102, 62, 0);
    }

    &--information {
      background-color: rgb(166, 219, 255);
      color: rgb(0, 61, 102);
    }

    &--notAvailable {
      background-color: rgb(220, 220, 225);
      color: rgb(48, 48, 50);
    }
  }

  &__splash-container {
    z-index: z(forward);
    width: 100%;
    max-width: 408px;
    position: relative;
  }

  &__splash {
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    top: -16px;

    @media (min-width: $new-product-card-breakpoint) {
      top: -26px;
      right: -26px;
    }

    .icon {
      height: 100%;
      width: 100%;
    }

    &--small {
      width: 48px;
      height: 48px;
    }

    &--medium {
      width: 64px;
      height: 64px;
    }

    &--large {
      width: 80px;
      height: 80px;
    }

    &--color-orange {
      color: color(white);
      .icon {
        fill: color(orange);
      }
    }

    &--color-purple {
      color: color(white);
      .icon {
        fill: color(corePurple);
      }
    }

    &--color-black {
      color: color(white);
      .icon {
        fill: color(grey200);
      }
    }

    &--color-white {
      color: color(grey200);
      .icon {
        fill: color(white);
      }
    }

    &--color-red {
      color: color(white);
      .icon {
        fill: color(red);
      }
    }

    &--color-green {
      color: color(white);
      .icon {
        fill: color(darkGreen);
      }
    }

    &--color-blue {
      color: color(white);
      .icon {
        fill: color(blue);
      }
    }
  }

  &__splash-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    line-height: 14px;
    font-weight: bold;
    text-align: center;
  }

  &:hover {
    transform: scale(1.03);
    box-shadow: rgba(0, 0, 0, 0.2) 0 1px 4px;
  }

  @media (max-width: $new-product-card-breakpoint) {
    height: auto;
    padding-top: 32px;
    padding-right: 16px;
    padding-bottom: 24px;
    padding-left: 16px;
  }

  @media (min-width: $new-product-card-breakpoint) {
    min-height: 624px;
    overflow: hidden;
    padding-top: 48px;
    padding-right: 48px;
    padding-bottom: 40px;
    padding-left: 48px;
    flex-grow: 1;
  }

  &--with-image {
    padding-top: 40px;
  }

  &__container {
    @media (max-width: $new-product-card-breakpoint) {
      padding-bottom: 24px;
    }

    @media (min-width: ($new-product-card-breakpoint + 1px)) {
      padding-bottom: 40px;
    }
  }

  &__header {
    @media (max-width: $new-product-card-breakpoint) {
      min-height: 76px;
    }

    @media (min-width: ($new-product-card-breakpoint + 1px)) {
      min-height: 100px;
    }

    max-width: 408px;
  }

  &--with-image &__header {
    min-height: 144px;
  }

  &__image {
    height: 64px;
    text-align: center;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__image + &__name {
    margin-top: 16px;
  }

  &__name {
    font-size: 24px;
    line-height: 24px;
    margin: 0;
    text-align: center;
    @include teliaSansHeading;
    color: color(grey200);
  }

  &--with-image &__name {
    line-height: 32px;
  }

  &__name + &__description {
    margin-top: 4px;
  }

  &__description {
    font-weight: bold;
    font-size: 14px;
    text-align: center;
    color: color(grey100);

    @media (max-width: $new-product-card-breakpoint) {
      line-height: 16px;
    }

    @media (min-width: ($new-product-card-breakpoint + 1px)) {
      line-height: 24px;
    }
  }

  &__body {
    flex-grow: 1;
    width: 100%;
    max-width: 408px;
  }

  &__smallprint {
    padding-top: 4px;
    min-height: 48px;
    font-weight: 500;
    color: color(grey100);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    & * {
      font-size: 12px;
      line-height: 20px;
      margin: 0;
    }
  }

  &__button {
    height: 56px;
    margin-top: 24px;
    text-align: center;
  }

  &__footer {
    max-width: 408px;
    margin-top: 21px;
  }

  &__pebble {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 60px;
    height: 60px;

    &-icon {
      font-size: 60px;
      color: color(blue);
    }

    &-text {
      @include teliaSansHeading;
      color: color(white);
      font-size: 10px;
      line-height: 12px;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-55%);
    }
  }
}

.cms-plan-attribute {
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: $new-product-card-breakpoint) {
    height: 24px;
  }

  @media (min-width: ($new-product-card-breakpoint + 1px)) {
    height: 32px;
  }

  &__icon {
    text-align: center;

    @media (max-width: $new-product-card-breakpoint) {
      width: 24px;
      height: 24px;
      font-size: 24px;
    }

    @media (min-width: ($new-product-card-breakpoint + 1px)) {
      width: 32px;
      height: 32px;
      font-size: 32px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  &__icon + &__name {
    margin-left: 8px;
  }

  &__name,
  &__unit {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    @media (max-width: $new-product-card-breakpoint) {
      font-size: 10px;
      line-height: 12px;
      height: 24px;
    }

    @media (min-width: ($new-product-card-breakpoint + 1px)) {
      font-size: 12px;
      line-height: 14px;
      height: 28px;
    }
  }

  &__unit {
    align-items: flex-end;
  }

  &__spacer {
    flex-grow: 1;
    box-sizing: border-box;
    height: 24px;
    border-bottom: 2px dashed color(grey50);
  }

  &__name + &__spacer,
  &__spacer + &__quantity,
  &__spacer + &__unlimited {
    margin-left: 8px;
  }

  &__quantity {
    color: color(grey100);
    @include teliaSansHeading;

    @media (max-width: $new-product-card-breakpoint) {
      font-size: 24px;
      line-height: 24px;
    }

    @media (min-width: ($new-product-card-breakpoint + 1px)) {
      font-size: 32px;
      line-height: 32px;
    }
  }

  &__quantity + &__unit {
    margin-left: 4px;
  }

  &__unlimited {
    color: color(grey100);
    @include teliaSansHeading;

    @media (max-width: $new-product-card-breakpoint) {
      font-size: 18px;
      line-height: 18px;
      padding-top: 6px;
    }

    @media (min-width: ($new-product-card-breakpoint + 1px)) {
      font-size: 24px;
      line-height: 26px;
      padding-top: 6px;
    }
  }

  &__segment {
    display: block;
    white-space: nowrap;
  }

  & + & {
    margin-top: 16px;
  }
}

.cms-plan-price {
  color: color(grey100);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;

  @media (max-width: $new-product-card-breakpoint) {
    margin-top: 16px;
  }

  @media (min-width: ($new-product-card-breakpoint + 1px)) {
  }

  &--justify-left {
    justify-content: flex-start;
  }

  &__prefix {
    font-weight: bold;
    margin-right: 6px;

    @media (max-width: $new-product-card-breakpoint) {
      font-size: 12px;
      line-height: 14px;
      padding-bottom: 2px;
    }

    @media (min-width: ($new-product-card-breakpoint + 1px)) {
      font-size: 14px;
      line-height: 24px;
      padding-bottom: 2px;
    }
  }

  &__old {
    font-weight: bold;
    @include teliaSansHeading;
    text-decoration: line-through;
    color: color(grey50);
    margin-right: 4px;

    @media (max-width: $new-product-card-breakpoint) {
      font-size: 18px;
      line-height: 20px;
      padding-bottom: 2px;
    }

    @media (min-width: ($new-product-card-breakpoint + 1px)) {
      font-size: 24px;
      line-height: 28px;
      padding-bottom: 2px;
    }
  }

  &__quantity {
    @include teliaSansHeading;

    @media (max-width: $new-product-card-breakpoint) {
      font-size: 32px;
      line-height: 32px;
    }

    @media (min-width: ($new-product-card-breakpoint + 1px)) {
      font-size: 48px;
      line-height: 48px;
    }
  }

  &__quantity + &__unit {
    margin-left: 4px;
  }

  &__unit {
    @include teliaSansHeading;

    @media (max-width: $new-product-card-breakpoint) {
      font-size: 18px;
      line-height: 22px;
      padding-bottom: 2px;
    }

    @media (min-width: ($new-product-card-breakpoint + 1px)) {
      font-size: 24px;
      line-height: 26px;
      padding-bottom: 4px;
    }
  }
}

.cms-plan-promo {

  margin: 24px 0 0;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  min-height: 80px;

  &__content {
    width: 85%;
  }

  &__badge-container {
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    padding: 5px;
    border-radius: 3px;
    margin-top: -4px;

    .cms-plan-promo__badge-text {
      margin-left: 5px;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
    }

    &--background-black {
      background-color: color(black);
    }
    &--background-white {
      background-color: color(white);
    }
    &--background-deepPurple {
      background-color: color(deepPurple);
    }
    &--background-lightPurple {
      background-color: color(lightPurple);
    }
    &--background-deepBeige {
      background-color: color(deepBeige);
    }
    &--background-lightBeige {
      background-color: color(lightBeige);
    }
    &--background-beige {
      background-color: color(beige);
    }

    &--text-color-black {
      color: color(black);
    }
    &--text-color-white {
      color: color(white);
    }
    &--text-color-deepPurple {
      color: color(deepPurple);
    }
    &--text-color-lightPurple {
      color: color(lightPurple);
    }
    &--text-color-deepBeige {
      color: color(deepBeige);
    }
    &--text-color-lightBeige {
      color: color(lightBeige);
    }
    &--text-color-beige {
      color: color(beige);
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: rgba(0, 0, 0, 0.8);

    &-top-space {
      margin-top: 8px;
    }
  }

  &__description {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    color: rgba(0, 0, 0, 0.62);
  }

  &__image {
    flex-shrink: 0;
    text-align: center;
    margin-top: -4px;

    @media (max-width: $new-product-card-breakpoint) {
      width: 56px;
      height: 56px;
    }

    @media (min-width: ($new-product-card-breakpoint + 1px)) {
      width: 56px;
      height: 56px;
    }

    img {
      max-width: 100%;
      max-height: 100%;
      width: inherit;
      height: inherit;
    }
  }

}
