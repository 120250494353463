$heroHeightExtraSmall: 224px;
$heroHeightSmall: 344px;
$heroHeightMedium: 360px;
$heroHeightLarge: 392px;
$heroHeightExtraLarge: 480px;

@function rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

.cms-video-hero {
  position: relative;
  color: color(offBlack);

  &--color-black {
    @include from('sm') {
      color: color(offBlack);
    }
  }

  &--color-purple {
    @include from('sm') {
      color: color(deepPurple);
    }
  }

  &--color-white {
    @include from('sm') {
      color: color(white);
    }
  }

  &--color-deepPurple {
    @include from('sm') {
      color: color(deepPurple);
    }
  }

  &--color-deepBeige {
    @include from('sm') {
      color: color(deepBeige);
    }
  }

  &--color-lightPurple {
    @include from('sm') {
      color: color(lightPurple);
    }
  }

  &--color-lightBeige {
    @include from('sm') {
      color: color(lightBeige);
    }
  }

  @include to('xs') {
    &--mobile-color-black {
        color: color(offBlack);
    }

    &--mobile-color-purple {
        color: color(deepPurple);
    }

    &--mobile-color-white {
        color: color(white);
    }

    &--mobile-color-deepPurple {
        color: color(deepPurple);
    }

    &--mobile-color-deepBeige {
        color: color(deepBeige);
    }

    &--mobile-color-lightPurple {
        color: color(lightPurple);
    }

    &--mobile-color-lightBeige {
        color: color(lightBeige);
    }
  }

  &__video-container {
    position: relative;
    overflow: hidden;
    height: $heroHeightExtraSmall;

    @include from('sm') {
      height: $heroHeightSmall;
    }

    @include from('md') {
      min-height: $heroHeightMedium;
      height: 80vh;
      max-height: $heroHeightMedium * 1.3;
    }

    @include from('lg') {
      min-height: $heroHeightLarge;
      height: 80vh;
      max-height: $heroHeightLarge * 1.3;
    }

    @include from('xlg') {
      min-height: $heroHeightExtraLarge;
      height: 80vh;
      max-height: $heroHeightExtraLarge * 1.3;
    }
  }

  &__video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    // These adjusted in the javascript
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  // All EdgeHTML
  @supports (-ms-ime-align:auto) {
    &__video {
      height: auto;
    }
  }

  // IE 9-11
  _::selection, &__video {
    height:auto\0;
  }

  &__grid {
    height: 100%;
  }

  &__container {
    padding: 62px 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include to('xs') {
      // On mobile size the background must always be light grey by default unless overridden
      background-color: color(grey20);

      &--mobile-background-lightGrey {
        background-color: color(grey20);
      }

      &--mobile-background-purple {
        background-color: color(corePurple);
      }

      &--mobile-background-black {
        background-color: color(grey200);
      }

      &--mobile-background-white {
        background-color: color(white);
      }

      &--mobile-background-transparent {
        background-color: transparent;
      }

      &--mobile-background-deepBeige {
        background-color: color(deepBeige);
      }

      &--mobile-background-beige {
        background-color: color(beige);
      }

      &--mobile-background-deepPurple {
        background-color: color(deepPurple);
      }

      &--mobile-background-lightPurple {
        background-color: color(lightPurple);
      }

      &--mobile-background-lightBeige {
        background-color: color(lightBeige);
      }
    }

    @include from('sm') {
      position: absolute;
    }
  }

  &__content {
    &--align-left {
      text-align: left;
    }

    &--align-center {
      text-align: center;
    }

    &--align-right {
      text-align: right;
    }
  }

  &__heading {
    margin: 0;
  }

  &__description {
    max-width: none;
    margin: 1em 0;

    font-size: rem(18px);
    line-height: 1.22222;

    @include from('sm') {
      font-size: rem(20px);
    }

    @include from('md') {
      font-size: rem(24px);
    }

    @include from('xlg') {
      font-size: rem(30px);
    }
  }

  &__heading,
  &__description {
    color: inherit;

    &--color-white {
      @include from('sm') {
        color: color(white);
      }
    }

    &--color-black {
      @include from('sm') {
        color: color(offBlack);
      }
    }

    &--color-purple {
      @include from('sm') {
        color: color(deepPurple);
      }
    }

    &--color-deepPurple {
      @include from('sm') {
        color: color(deepPurple);
      }
    }

    &--color-deepBeige {
      @include from('sm') {
        color: color(deepBeige);
      }
    }

    &--color-lightPurple {
      @include from('sm') {
        color: color(lightPurple);
      }
    }

    &--color-lightBeige {
      @include from('sm') {
        color: color(lightBeige);
      }
    }

    @include to('xs') {
      &--mobile-color-white {
        color: color(white);
      }

      &--mobile-color-black {
        color: color(offBlack);
      }

      &--mobile-color-purple {
        color: color(deepPurple);
      }

      &--mobile-color-deepPurple {
        color: color(deepPurple);
      }

      &--mobile-color-deepBeige {
        color: color(deepBeige);
      }

      &--mobile-color-lightPurple {
        color: color(lightPurple);
      }

      &--mobile-color-lightBeige {
        color: color(lightBeige);
      }
    }
  }
}
