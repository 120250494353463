.modal-content {
  display: none;
  background: white;
  z-index: z(modalBox);
  border-radius: 4px;
  box-shadow: rgba(0,0,0,0.1) 0 1px 2px 0;

  & .grid .columnrow {
    margin: 0;
  }

  &__grid {
    padding: 0;
  }

  &__heading {
    border-bottom: 1px solid color(grey20);
    color: black;
    letter-spacing: -0.2px;
    line-height: 1;
    margin: 0;
    padding: 24px 36px;
  }

  &__close {
    border-bottom: 1px solid color(grey20);
    color: color(grey50) !important;
    padding: 24px 36px;
    font-size: 1.5em;
  }

  &__content {
    padding: 32px 36px;
  }

  &__components .grid.columnrow {
    margin: 0;
  }

  &-display {
    display: block;
    position: absolute;
    top: 10%;

    @include from('md') {
      left: 20%;
      right: 20%;
      top: 20%;
    }
  }

  &__overlay {
    background-color: rgba(color(black), 0.15);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s;
    z-index: z(back);
    pointer-events: none;

    &-show {
      opacity: 1;
      z-index: z(modalOverlay);
      pointer-events: auto;
    }
  }
}
