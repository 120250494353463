.image-and-column-split {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__anchor {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
  }

  &--lightGrey {
    // "telia-gray-200" in component library
    background-color: color(grey20);
  }

  &--white {
    // "telia-white" in component library
    background-color: color(white);
  }

  &--corePurple {
    // "telia-purple-500" in component library
    background-color: color(corePurple);
  }

  &--darkPurple {
    // "telia-purple-900" in component library
    background-color: #1f002e;
  }

  &--deepPurple {
    background-color: color(deepPurple);
  }

  &--lightPurple {
    background-color: color(lightPurple);
  }

  &--deepBeige {
    background-color: color(deepBeige);
  }

  &--lightBeige {
    background-color: color(lightBeige);
  }

  &--darkGrey {
    // "telia-black" in component library
    background-color: color(grey200);
  }

  &--black {
    // There is no absolute black in the component library or "official brand background colors" I guess, ¯\_(ツ)_/¯
    background-color: color(black);
  }

  &--height-limit &__picture {
    max-height: 400px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__image {
    width: 100%;
    display: block;
  }

  &__picture {
    width: 100%;

    @include from('md') {
        width: 50%;
    }

    &--image-left {
      @include from('md') {
        order: 1;
      }
    }

    &--image-right {
      @include from('md') {
        order: 2;
      }
    }
  }

  &__content {
    width: 100%;

    @include from('md') {
      width: 50%;
    }

    &--image-left {
      @include from('md') {
        order: 2;
      }

      .grid--half {
        margin-left: 0;
      }
    }

    &--image-right {
      @include from('md') {
        order: 1;
      }

      .grid--half {
        margin-right: 0;
      }
    }

    & .icon-text-list {
      margin: 20px 0;
    }
  }

  &__column {
    text-align: center;

    p {
      margin: 0.6em 0;

      @include to('sm') {
        margin: 1em auto;
      }
    }

    @include from('md') {
      text-align: left;
    }

    h3 {
      margin: 1.5em 0 0;

      @include from('md') {
        margin: 0;
      }
    }

    .button {
      @include to('xs') {
        margin: 2px 0 0;
      }
    }
  }

  .grid__cell {
      min-height: 100%;
  }
}
