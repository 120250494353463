.country-specific-price-list {
  display: none;
  opacity: 0;
  transition: opacity 1s;

  &--visible {
    display: block;
    opacity: 1;
  }

  & .price-list__content {
    max-height: auto !important;
  }
}
