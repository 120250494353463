$cardPadding: 16px;

.cms-channel-package-card {
  text-align: left;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
  background-color: color(white);
  height: 100%;
  padding-bottom: 4px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100%;
  }

  &__image {
    width: 100%;
    min-height: 1px; // this is for IE11. Otherwise it stretches image height!
  }

  &__logo {
    height: 60px;

    &:not(:last-of-type) {
      margin-right: 12px;
    }
  }

  &__tag-container {
    height: 22px;
    display: flex;
    justify-content: flex-start;
    margin: auto $cardPadding 0;
  }

  &__tag {
    padding: 0px 8px;
    box-sizing: border-box;
    border-radius: 4px;
    font-size: 10px;
    letter-spacing: 1px;
    line-height: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 600;
    color: color(white);
    text-transform: uppercase;

    &--blue {
      background-color: color(blue);
    }

    &--red {
      background-color: hover(red);
    }

    &--orange {
      background-color: color(orange);
    }

    &--purple {
      background-color: color(corePurple);
    }

    &--transparent {
      background-color: rgba(color(grey70), 0.5);
    }
  }

  &__lead-container {
    display: flex;
    align-items: center;
    margin: 2px $cardPadding 8px;

    @include from('sm') {
      font-size: 16px;
      margin-top: 2px;
    }
  }

  &__lead-icon {
    align-self: flex-start;
    margin-right: 6px;
    position: relative;

    > svg {
      display: block;
      height: 22px;
      width: 22px;
      pointer-events: none; // To prevent Edge from swallowing click events
    }

    &--blue {
      color: color(blue);
    }

    &--red {
      color: hover(red);
    }

    &--orange {
      color: color(orange);
    }

    &--purple {
      color: color(corePurple);
    }

    &--transparent {
      color: rgba(color(grey70), 0.5);
    }

    &--has-tooltip {
      cursor: pointer;
    }
  }

  &__lead-text {
    line-height: 22px;
    font-weight: 400;
    color: color(grey70);
    font-size: 14px;

    @include from('sm') {
      font-size: 16px;
    }
  }

  &__description {
    line-height: 22px;
    min-height: 40px;
    font-weight: 400;
    color: color(grey70);
    font-size: 14px;
    margin: 8px $cardPadding 18px;

    @include from('sm') {
      font-size: 16px;
    }
  }

  &__name {
    line-height: 32px;
    color: color(grey100);
    font-weight: 600;
    font-size: 18px;
    display: block;
    margin: 16px $cardPadding 0;

    @include from('sm') {
      font-size: 24px;
    }
  }

  &__footer {
    padding: 12px $cardPadding 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
  }

  &__price-container {
    display: flex;
    flex-wrap: wrap;

    .price {
      @include teliaSansHeading;
      color: color(grey100);
      font-size: 28px;
      margin-right: 4px;

      @include from('sm') {
        font-size: 32px;
      }
    }

    .price-unit {
      @include teliaSans;
      color: color(grey100);
      font-size: 20px;
      display: flex;
      align-items: center;
      flex-grow: 1;
      text-transform: none;

      @include from('sm') {
        font-size: 24px;
      }
    }

    .price-description {
      line-height: 14px;
      font-size: 12px;
      color: color(grey70);
      margin: 4px 0 0;
      flex: 1 0 100%;

      @include from('sm') {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .price-prefix {
      font-size: 12px;
      color: color(grey100);
      font-weight: bold;
      display: flex;
      align-items: flex-end;
      padding-right: 3px;
    }
  }

  .price-from {
    line-height: 14px;
    font-size: 12px;
    color: color(grey100);
    font-weight: bold;
    display: flex;
    align-items: flex-end;
  }

  &__button-container {
    padding-top: 14px;

    .button {
      margin: 0;
      padding: 0 14px;
      margin-bottom: 18px;
    }
  }

  &__tooltip {
    width: 175px;
    position: absolute;
    visibility: hidden;
    // TODO: custom color here
    background-color: #642d96;
    font-weight: normal;
    padding: 4px 18px;
    color: color(white);
    border-radius: 8px;
    z-index: 1;
    cursor: default;
    z-index: z(modalBox);
    box-shadow: 3px 3px 6px 0px rgba(color(black), 0.3);
    transform: translate(40px, 50%);
    bottom: 60%;
    left: -50%;
    -webkit-text-size-adjust: 100%; // prevents iphone from magically adjusting font size for whatever reason

    // Because the rich text editor injects stuff like p in the tooltip
    p {
      font-size: 12px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 100%;
      margin-top: -3px;
      border: 5px solid transparent;
      // This is the trick to create the arrow
      border-right-color: #642d96;
    }

    &--open {
      visibility: visible;
    }
  }
}
