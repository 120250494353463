.operator-search {
  margin-top: 2em;
  margin-bottom: 5px;

  &__icon {
    position: absolute;
    font-size: 1.5em;
    color: color(black);
    left: 0.7em;
    top: 0.7em;
  }

  &__searchbox {
    position: relative;
    width: 100%;
  }

  &__input {
    padding: 1em 1em 1em 3em;
    width: 100%;
    outline: 0;
    font: inherit;
    border-radius: 8px;
    border: 1px solid color(grey30);
    box-shadow: 0 1px 2px 0 color(grey30);
    transition: .2s border-color;

    &:focus {
      margin: 0;
      border: 2px solid color(corePurple);
    }

    @include placeholder {
      color: color(grey70);
    }
  }

  &__table {
    width: 100%;
    border-collapse: collapse;
  }

  &__row {
    border-bottom: 1px solid color(grey30);
  }

  &__operator {
    width: 40%;
  }

  &__details {
    width: 60%;
  }
}
