.team-selector {
  &__overlay {
    background: rgba(color(black), .8);
    background-size: cover;
    bottom: 0;
    display: none;
    left: 0;
    letter-spacing: normal;
    opacity: 1;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 400ms ease-in;
    word-spacing: normal;
    z-index: z(modalOverlay);

    @include from('sm') {
      padding: 0 30px;
    }
  }

  &__modal {
    background: color(white);
    max-width: 1064px;
    position: relative;
    top: 0;
    width: 100%;
    z-index: z(modalBox);
    height: 100%;
    overflow-y: auto;

    @include from('sm') {
      border-radius: 8px;
      height: auto;
      overflow: visible;
      margin: 10% auto;
      padding: 20px 60px 60px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    background-color: color(grey10);
    border-bottom: 2px solid color(grey30);
    height: 62px;
    padding: 0 24px;
    top: 0;
    position: fixed;
    width: 100%;
    z-index: z(modalOverlay);
    align-items: center;

    @include from('sm') {
      display: block;
      position: relative;
      background-color: transparent;
      border: 0;
      height: auto;
      padding: 0;
      z-index: inherit;
    }
  }

  &__close {
    background-color: color(black);
    border-radius: 50%;
    color: color(white);
    cursor: pointer;
    display: flex;
    float: right;
    font-size: 26px;
    height: 30px;
    width: 30px;
    justify-content: center;
    align-items: center;

    @include from('sm') {
      font-size: 45px;
      width: 55px;
      height: 55px;
      transform: translateY(-87px);
    }
  }

  &__title {
    @include teliaSansHeading;
    display: none;
    padding-left: 5px;

    @include from('sm') {
      display: block;
      font-size: 48px;
      margin-bottom: 50px;
      margin-top: 40px;
      padding-left: 8px;
      width: 100%;
      line-height: 56px;
    }

    @supports (display: grid) {
      padding-left: 0;
    }
  }

  &__mobile-title {
    @include teliaSansHeading;
    display: block;
    font-size: 20px;
    width: 70%;

    @include from('sm') {
      display: none;
    }
  }

  &__content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 75px 16px 0;

    @supports (display: grid) {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(105px, 1fr));
      grid-gap: 10px;

      @include from('sm') {
        grid-gap: 15px;
        grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
      }
    }

    /* This will match the Edge browser */
    /* Some versions of Edge think they support grid but they actually don't */
    @supports (-ms-ime-align:auto) {
      display: flex;
    }

    @include from('sm') {
      margin-top: 0px;
      padding: 0;
    }
  }

  &__team-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid color(grey30);
    border-radius: 8px;
    cursor: pointer;
    padding: 10px 20px;
    margin: 5px;

    @include from('sm') {
      padding: 16px 33px;
      margin: 8px;
    }

    @supports (display: grid) {
      margin: 0;
    }

    /* This will match the Edge browser */
    /* Some versions of Edge think they support grid but they actually don't */
    @supports (-ms-ime-align:auto) {
      margin: 5px;

      @include from('sm') {
        margin: 8px;
      }
    }
  }

  &__team-name {
    @include teliaSans;
    color: color(corePurple);
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    margin-top: 10px;

    @include from('sm') {
      font-size: 20px;
    }
  }

  &__team-image {
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @include from('sm') {
      width: 100px;
      height: 100px;
    }
  }
}
