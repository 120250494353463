.link-grid {
  margin-bottom: 4em;

  &__header {
    text-align: center;
    margin-bottom: 40px;
  }

  &__heading {
    margin: 0 auto;
    color: color(grey200);
  }

  &__link {
    margin-bottom: 12px;
    padding: 20px 0;
    text-align: center;

    @include from('md') {
      padding: 40px;
    }

    a {
      &:link, &:visited {
        color: color(grey200);
      }
    }

    .link-grid__figure {
      color: color(grey200);
    }

    &:hover {
      background-color: color(grey10);

      a {
        color: color(corePurple);
      }

      .link-grid__figure {
        color: color(corePurple);
      }
    }
  }

  &__icon {
    font-size: 36px;
    width: 48px;
    height: 48px;

    @include from('md') {
      width: 60px;
      height: 60px;
      font-size: 52px;
    }
  }

  &__item-title {
    @include teliaSans;
    text-transform: none;
    margin: 1em auto 0;
    font-size: 16px;
    color: color(grey200);
    display: inline-block;

    @include from('md') {
      @include teliaSansHeading;
      font-size: 24px;
      display: block;
    }

    .link-grid__link:hover & {
      color: color(corePurple);
    }
  }

  .revealable-component & {
    margin-top: 40px;
    margin-bottom: 1em;
    padding: 16px 0;
    background-color: color(grey20);

    &__header {
      display: none;
    }

    &__item {
      @include to('sm') {
        width: 100%;
      }
    }

    &__item-title {
      @include teliaSans;
      text-transform: none;
      font-size: 16px;
    }

    &__figure {
      display: inline-block;
      vertical-align: middle;

      @include to('sm') {
        margin-right: 24px;
      }
    }

    &__link {
      @include to('sm') {
        text-align: left;
        display: block;
        padding: 0;
      }

      @include from('md') {
        display: inline-block;
        padding: 2em 1em;
      }

      a {
        color: color(grey200);
      }

      .icon {
        fill: color(grey200);
        font-size: 36px;
      }
    }

    &__link:hover {
      background-color: color(grey20);

      a {
        color: color(corePurple);
      }

      .icon {
        fill:  color(corePurple);
      }
    }

    &__icon {
      width: 48px;
      height: 48px;
    }
  }
}
