.giftcard {
  margin-bottom: 3em;
  background-color: transparent;
  position: relative;

  &--small {
    background-color: color(white);
    border-radius: 8px;
    margin-bottom: 0;
    padding: 16px;
    box-shadow: shadow('lg');

    @include from('sm') {
      font-size: 0.95em;
      padding: 8px;
    }

    @include from('md') {
      padding: 16px;
      font-size: 1em;
      max-width: 600px;
    }
  }

  &__title {
    .giftcard--small & {
      margin: 16px 16px 0 16px;
    }
  }

  &__additional-text {
    margin: 0 16px;
    max-width: 100%; // Fixes IE flex wrap bug
  }

  &__anchor {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
  }

  &__form {
    visibility: hidden;
  }

  &__first-section {
    display: block;
  }

  &__second-section {
    display: none;
  }

  &__heading-alignment {
    text-align: center;
    max-width: 100%;
  }

  &__container {
    background-color: color(white);
    box-shadow: 2px 3px 20px 7px color(grey30);
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin-top: 36px;
    flex-direction: column;

    @include to('sm') {
      padding-bottom: 10px;
    }

    @include from('lg') {
      flex-direction: row;
      height: 88px;
    }

    .giftcard--small & {
      margin-top: 0;
      padding-bottom: 0;
      flex-direction: column;
      height: auto;
      box-shadow: none;
    }

    .text-input {
      margin-top: 1em;

      @include from('lg') {
        margin-top: 0;
      }

      .giftcard--small & {
        margin-top: 1em;
      }

      &__error-text {
        position: absolute;
        bottom: -10px;
        margin-left: 3.75em;
        
        @include from('lg') {
          width: 100%;
          bottom: -25px;
          margin-left: 2.75em;
        }

        @include from('xlg') {
          margin-left: 3.75em;
        }

        .giftcard--small & {
          position: absolute;
          bottom: -10px;
          margin-left: 3.75em;
        }
      }

    }

    .text-input__field {
      margin-left: 2em;
      padding-right: 22px;
      box-sizing: border-box;
      width: calc(100% - 22px);

      @include from('lg') {
        margin-left: 1.25em;
      }

      @include from('xlg') {
        margin-left: 2em;
      }

      .giftcard--small & {
        margin-left: 2em;
      }

      flex-grow: 1;
      padding-top: em(8px);

      &:disabled {
        border-bottom: 1px solid color(grey40);
      }

      &:focus, &.focus, &.has-value  {
        & ~ .text-input__label {
          top: 14px;
          margin-left: 5em;

          @include from('lg') {
            top: 0;
            margin-left: 4.5em;
          }

          @include from('xlg') {
            margin-left: 6.25em;
          }

          .giftcard--small & {
            top: 14px;
            margin-left: 5em;
          }
        }
      }
    }

    .text-input__field::-ms-clear {
      display: none;
    }

    .text-input__label {
      left: 0;
      margin-left: 3.75em;
      top: 50%;
      transform: translateY(-50%);

      @include from('lg') {
        margin-left: 3.25em;
      }

      @include from('xlg') {
        margin-left: 4.75em;
      }

      .giftcard--small & {
        margin-left: 3.75em;
      }
    }
  }

  &__input-container {
    flex-grow: 1;
    margin-left: 0.75em;
    width: 100%;
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;

    @include from('lg') {
      padding-top: 0;
      padding-bottom: 0;
    }

    .giftcard--small & {
      padding-top: 1em;
      padding-bottom: 1em;
    }

    .ppr-autocomplete__list {
      position: absolute;
      left: 3.75em;
      top: 3em;
      width: auto;
      right: 1em;

      @include from('lg') {
        top: 2em;
        right: 0;
        left: 3.5em;
      }

      @include from('xlg') {
        left: 4.75em;
        right: 1em;
      }

      .giftcard--small & {
        left: 3.75em;
        top: 3em;
        right: 1em;
      }
    }
  }

  &__input--icon-ok {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);

    @include from('lg') {
      right: 0;
    }

    @include from('xlg') {
      right: 16px;
    }

    .giftcard--small & {
      right: 16px;
    }

    & .icon--checkmark {
      fill: color(darkGreen);
      float: right;
      font-size: 18px;
      justify-content: flex-end;
    }
  }

  &__item {
    flex-grow: 1;
    display: flex;
    margin-top: 1em;
    width: 100%;
    flex-wrap: wrap;

    @include to ('md') {
      padding: 0 1em;
    }

    @include from ('lg') {
      padding-left: 22px;
      width: auto;
    }

    @include from ('xlg') {
      padding-left: 32px;
      padding-right: 12px;
    }

    .giftcard--small & {
      padding: 0 1em;
      width: 100%;
    }
  }

  &__giftcardnumber {
    @include from('lg') {
      width: 20%;
    }

    @include from('xlg') {
      width: auto;
    }

    .giftcard--small & {
      width: 100%;
    }
  }

  &__businessid {
    @include from('lg') {
      width: 20%;
    }

    @include from('xlg') {
      width: auto;
    }

    .giftcard--small & {
      width: 100%;
    }
  }

  &__receiptnumber {
    @include from('lg') {
      width: 20%;
    }

    @include from('xlg') {
      width: auto;
    }

    .giftcard--small & {
      width: 100%;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    font-size: 1.5em;
    transform: translateY(-50%);
  }

  &__return {
    background-color: rgba(color(darkGreen), 0);
    color: color(darkGreen);
    border-color: color(darkGreen);

    &:link,
    &:visited {
      color: color(darkGreen);
    }

    &:hover,
    &.button--hover {
      background-color: color(darkGreen);
      color: color(white);
      border-color: color(darkGreen);
    }

    &:disabled,
    &.button--disabled {
      color: color(grey40);
      border-color: color(grey40) !important;
      background-color: transparent;
    }
  }

  &__validate {
    flex-grow: 0;
    align-self: center;
    text-align: center;
    &-button {
      padding: 28px 0 16px;

      @include from('lg') {
        align-self: flex-end;
        padding: 2px 18px 0 0;
      }

      .giftcard--small & {
        padding: 16px 0 0 0;
      }
    }
  }

  &__error {
    color: color(red);
    max-width: 400px;
    margin-top: 1em;
    padding-left: 1em;
    padding-right: 1em;

    @include from ('md') {
      padding-left: 24px;
      padding-right: 12px;
      max-width: 600px;
    }

    .giftcard--small & {
      padding-left: 1em;
      padding-right: 1em;
      max-width: 400px;
    }
  }

  &__load-animation {
    display: block;
    width: 100%;
    margin-top: 20px;
    text-align: center;

    :before, :after {
      background-color: color(grey10);
    }

    @include from ('md') {
      position: relative;
      top: 0.4em;
    }

    .giftcard--small & {
      position: static;
      top: 0;
    }
  }

  & .component-loader__wrapper {
    border-radius: 8px;
  }
}