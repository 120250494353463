.recruitment-process {
  overflow: hidden;
  position: relative;

  &__image {
    object-fit: cover;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
  }

  &__content {
    position: relative;
  }

  &__subheading {

    &--bold {
      font-weight: bold;
    }
  }

  &__step {
    display: flex;
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;

    &:not(:last-of-type)::before {
      content: '';
      position: absolute;
      top: 114px;
      left: 32px;
      height: 100px;
      width: 2px;
      background: color(grey30);
    }
  }

  &__stepNumber {
    font-family: TeliaSansHeading, TeliaSans, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    text-align: center;
    border-radius: 50%;
    border: 2px solid color(grey30);
    width: 64px;
    height: 64px;
    padding: 18px 0 0 1px;
    font-size: 28px;
  }

  &__stepHeading {
    font-weight: bold;
  }

  &__stepText {
    max-width: 600px;
    text-align: left;
    position: absolute;
    font-size: 16px;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 10px;

    @include to('xxs') {
      line-height: 18px;
    }

    @include from('md') {
      top: 50%;
      transform: translateY(-50%);
    }
  }

}
