#tv-channel-package-list {
  flex-direction: row;
}

.cms-tv-channel-package-list {
  background-color: color(white);

  [class*="grid__col-"] {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  &__tag-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: -8px;
    margin-left: auto;
    margin-right: auto;

    @include from('sm') {
      justify-content: center;
    }

    &-input {
      display: none;
    }

    &-input:checked + .cms-tv-channel-package-list__tag-container-tag {
      border: 1px solid color(corePurple);
    }

    &-tag {
      display: none;
      background-color: color(white);
      border: 1px solid color(grey30);
      padding: 5px 12px 3px;
      margin-right: 8px;
      color: color(grey70);
      font-size: 12px;
      font-weight: 500;
      line-height: 1.25rem;
      border-radius: 4px;
      margin-top: 8px;

      span {
        pointer-events: none;
      }

      span > svg {
        color: color('corePurple');
        transform: translateY(1px);
      }

      // to prevent sticky hover on mobile devices
      @include hover-supported() {
        border: 1px solid color(corePurple);
        cursor: pointer;
      }

      &--active {
        border: 1px solid color(corePurple);
      }
    }

    &-show-more {
      display: none;
      align-self: flex-end;
      background-color: color(grey20);
      border: 1px solid color(grey30);
      padding: 5px 12px 3px;
      color: color(grey70);
      font-size: 12px;
      font-weight: 500;
      line-height: 1.25rem;
      border-radius: 4px;
      margin-top: 8px;

      &:hover {
        border: 1px solid color(corePurple);
        cursor: pointer;
      }
    }
  }

  &-category-title {
    @include teliaSans;
    text-transform: none;
    font-weight: bold;
    text-align: center;
    margin: 1px auto 27px;

    @include from('sm') {
      margin: 38px auto 30px;
    }
  }

  &__filter-title {
    @include teliaSans;
    text-transform: none;
    margin: 31px 0 !important;
    text-align: center !important;

    @include from('sm') {
      margin: 40px 0 !important;
    }
  }

  .cms-tablist__navbar > .grid > .grid__col-12 {
    margin-bottom: -4px;
  }

  .cms-tablist--squircle {
    margin: 0;
  }

  .cms-tablist__squircle-container {
    width: 68px;
    height: 68px;

    @include from('sm') {
      width: 96px;
      height: 96px;
    }
  }

  .cms-tablist__label {
    border-radius: 9px;
    background-color: color(white);

    .cms-tablist__tab-icon {
      @include from('sm') {
        font-size: 24px;
      }
    }

    .cms-tablist__tab-text {
      @include from('sm') {
        margin-top: 8px;
        width: 80%;
      }
    }

    @include from('sm') {
      width: 88px;
      height: 88px;
    }

    &--active {
      width: 60px;
      height: 60px;
      border-color: color(corePurple);
      box-shadow: none;

      @include from('sm') {
        width: 88px;
        height: 88px;
      }
    }
  }

  .image-hero__texts--button,
  .image-hero__texts--anchor-button {
    margin: 12px 0 0;

    @include from('md') {
      margin-top: 16px;
    }

    @include from('xxlg') {
      margin-top: 26px;
    }

    .button {
      margin-left: 0;
    }
  }

  &__text-container {
    display: flex;
    justify-content: center;
  }

  &__body-text {
    .textbox {
      margin: 0 auto;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      max-width: none;
    }
  }
}
