.cms-contact-cards {
  &__gutter {
    height: 12px;
  }

  &__card {
    margin-bottom: 23px;
    padding: 43px 32px;
    border-radius: 8px;
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.12);
    min-height: 306px;
    height: 100%;

    &--white {
      background-color: color(white);
    }

    &--lightGrey {
      background-color: color(grey20);
    }

    &--transparent {
      background-color: transparent;
    }

    .content-container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    &-title {
      color: color(grey100);
      margin-top: 0;
      // SM Title from React styleguide
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25em;

      @include from('md') {
        font-size: 20px;
      }

      @include from('lg') {
        font-size: 24px;
      }
    }

    &-content {
      p {
        margin: 0;
        line-height: 24px;
      }

      a {
        font-weight: bold;
        line-height: 28px;
      }

      &--bottom {
        border-top: 1px solid #d0d0d0;
        margin-top: auto;
        padding-top: 6px;
      }
    }
  }
}
