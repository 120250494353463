.cms-logo-row {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;

  @include from('md') {
    margin-top: 60px;
    margin-bottom: 60px;
  }

  &--align-left {
    justify-content: flex-start;
  }

  &--no-top-margin {
    margin-top: 0;
  }

  &--no-bottom-margin {
    margin-bottom: 0;
  }

  &__item-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .cms-logo-row--full-width &, .cms-logo-row--align-left & {
      justify-content: flex-start;
    }

    .cms-logo-row &--centered { // To make the specificity big enough
      justify-content: center;
    }
  }

  &__item {
    padding: 10px 20px;
    flex: 0 1 0px;
    min-width: 130px;

    .cms-logo-row--full-width & {
      flex: 1 1 0px;
      flex-basis: 50%;
      max-width: 50%;
    }
  
    .cms-logo-row--full-width .cms-logo-row__item-container--with-1-items & {
      flex-basis: 100%;
      max-width: 100%;
    }

    @include from('sm') {
      .cms-logo-row--full-width & {
        flex-basis: 33%;
        max-width: 33%;
      }
  
      .cms-logo-row--full-width .cms-logo-row__item-container--with-2-items & {
        flex-basis: 50%;
        max-width: 50%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-1-items & {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    @include from('md') {
      padding: 15px 30px;

      .cms-logo-row--full-width & {
        flex-basis: 25%;
        max-width: 25%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-3-items & {
        flex-basis: 33%;
        max-width: 33%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-2-items & {
        flex-basis: 50%;
        max-width: 50%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-1-items & {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    @include from('lg') {
      .cms-logo-row--full-width & {
        flex-basis: 20%;
        max-width: 20%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-4-items & {
        flex-basis: 25%;
        max-width: 25%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-3-items & {
        flex-basis: 33%;
        max-width: 33%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-2-items & {
        flex-basis: 50%;
        max-width: 50%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-1-items & {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    @include from('xlg') {
      .cms-logo-row--full-width & {
        flex-basis: 14.28%;
        max-width: 14.28%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-6-items & {
        flex-basis: 16.66%;
        max-width: 16.66%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-5-items & {
        flex-basis: 20%;
        max-width: 20%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-4-items & {
        flex-basis: 25%;
        max-width: 25%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-3-items & {
        flex-basis: 33%;
        max-width: 33%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-2-items & {
        flex-basis: 50%;
        max-width: 50%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-1-items & {
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    @include from('xxlg') {
      .cms-logo-row--full-width & {
        flex-basis: 12.5%;
        max-width: 12.5%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-7-items & {
        flex-basis: 14.28%;
        max-width: 14.28%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-6-items & {
        flex-basis: 16.66%;
        max-width: 16.66%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-5-items & {
        flex-basis: 20%;
        max-width: 20%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-4-items & {
        flex-basis: 25%;
        max-width: 25%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-3-items & {
        flex-basis: 33%;
        max-width: 33%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-2-items & {
        flex-basis: 50%;
        max-width: 50%;
      }

      .cms-logo-row--full-width .cms-logo-row__item-container--with-1-items & {
        flex-basis: 100%;
        max-width: 100%;
      }
    }
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85px;

    @include from('sm') {
      height: 100px;
    }

    @include from('lg') {
      height: 125px;
    }

    img {
      width: 85px;

      @include from('sm') {
        width: 100px;
      }

      @include from('lg') {
        width: 125px;
      }
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;

    &--black {
      color: color(grey200);

      a.cms-logo-row__link:hover & {
        color: hover(grey200);
      }
    }

    &--grey {
      color: color(grey40);

      a.cms-logo-row__link:hover & {
        color: hover(grey40);
      }
    }

    &--purple {
      color: color(corePurple);

      a.cms-logo-row__link:hover & {
        color: hover(corePurple);
      }
    }

    &--white {
      color: color(white);

      a.cms-logo-row__link:hover & {
        color: hover(white);
      }
    }

    a.cms-logo-row__link--with-default-hover-colour:hover & {
      color: hover(corePurple);
    }

    .icon {
      min-width: 64px;
      min-height: 64px;
      margin-bottom: 4px;

      @include from('sm') {
        margin-bottom: 8px;
      }
    }
  }

  &__text {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: 700;

    &--black {
      color: color(grey200);

      a.cms-logo-row__link:hover & {
        color: hover(grey200);
      }
    }

    &--white {
      color: color(white);

      a.cms-logo-row__link:hover & {
        color: hover(white);
      }
    }

    a.cms-logo-row__link--with-default-hover-colour:hover & {
      color: hover(corePurple);
    }

    @include from('sm') {
      font-size: 16px;
    }
  }

  &__secondary-text {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin-top: 2px; // Expecting regular text to exist if secondary text exists.

    &--black {
      color: color(grey200);

      a.cms-logo-row__link:hover & {
        color: hover(grey200);
      }
    }

    &--white {
      color: color(white);

      a.cms-logo-row__link:hover & {
        color: hover(white);
      }
    }

    a.cms-logo-row__link--with-default-hover-colour:hover & {
      color: hover(corePurple);
    }

    @include from('sm') {
      font-size: 16px;
    }
  }

  &__link {
    color: color(grey200);
  }
}
