$heroHeightSmall: 344px;
$heroHeightMedium: 360px;
$heroHeightLarge: 392px;
$heroHeightExtraLarge: 440px;

@function rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

.cms-fifty-fifty-hero {
  display: flex;
  flex-direction: column;
  height: calc(2 * #{$heroHeightSmall});

  &--limit-width {
    max-width: 1920px;
    margin: 0 auto;

    @include from('xlg') {
      padding: 0 108px;
    }
  }

  @include from('md') {
    flex-direction: row;
    height: $heroHeightSmall;
  }

  @include from('lg') {
    height: $heroHeightMedium;
  }

  @include from('xlg') {
    height: $heroHeightLarge;
  }

  @include from('xxlg') {
    height: $heroHeightExtraLarge;
  }

  &__left, &__right {
    flex: 0 1 auto;
    height: $heroHeightSmall;
    overflow: hidden;
    
    @include from('md') {
      flex: 1 0 50%;
    }

    @include from('lg') {
      height: $heroHeightMedium;
    }

    @include from('xlg') {
      height: $heroHeightLarge;
    }

    @include from('xxlg') {
      height: $heroHeightExtraLarge;
    }
  }

  .cms-video-hero {
    // To make the video container height relative to this
    height: 100%;

    &--color-white {
      color: color(white);
    }

    &--color-black {
      color: color(offBlack);
    }

    &--color-purple {
      color: color(deepPurple);
    }

    &--color-deepPurple {
      color: color(deepPurple);
    }

    &--color-deepBeige {
      color: color(deepBeige);
    }

    &--color-lightPurple {
      color: color(lightPurple);
    }

    &--color-lightBeige {
      color: color(lightBeige);
    }

    &__heading, &__description {
      // keep colors the same in mobile
      &--color-white {
        color: color(white);
      }
  
      &--color-black {
        color: color(offBlack);
      }
  
      &--color-purple {
        color: color(deepPurple);
      }

      &--color-deepPurple {
        color: color(deepPurple);
      }

      &--color-deepBeige {
        color: color(deepBeige);
      }

      &--color-lightPurple {
        color: color(lightPurple);
      }

      &--color-lightBeige {
        color: color(lightBeige);
      }
    }

    &__video-container {
      // To prevent the video from overlapping the fifty fifty hero area
      min-height: 100%;
      height: 100%;
      max-height: 100%;
    }

    &__container {
      // To force text container on top of the video in mobile too
      position: absolute;
      background-color: transparent;
    }

    &__content {
      // just always align left
      text-align: left;
    }

    &__grid {
      // Make text have 100% space even in md and larger because in 50 50 hero the container is narrower
      & .grid__col-md-6 {
        width: 100%;
      }

      @include from('sm') {
        padding: 0 36px;
      }

      @include from('md') {
        // at this point there are two heros side by side and we want smaller padding
        padding: 0 18px;
      }

      @include from('lg') {
        padding: 0 36px;
      }
    }
  }

  .image-hero {
    height: 100%;

    // have the overlay in mobile sizes too
    &__fade-overlay {
      &--black {
        background: linear-gradient(90deg, rgba(0,0,0,0.4) 30%, rgba(0,0,0,0) 70%);
      }

      &--white {
        background: linear-gradient(90deg, rgba(255,255,255,0.4) 30%, rgba(0,0,0,0) 70%);
      }
    }

    // override image breakout styles (they're not supported in fifty-fifty hero)
    &--image-breakout {
      margin-bottom: 0;
      padding-bottom: 0;
      height: 100%;

      .image-hero__overlay-image {
        .image-element {
          margin-bottom: 0;
          height: auto;
        }
      }
    }

    &__background-wrapper {
      height: 100%;

      .image-hero__background {
        // remove mobile size bottom border from the background area
        border-bottom: none;
        height: 100%;
      }
    }

    .image-element {
      &__picture, &__image {
        // prevent extra margin under the image
        display: block;
        // remove some weird margin
        margin-bottom: 0;
      }
    }

    &__grid {
      // override mobile size vertical ordering
      flex-direction: row;
      align-items: center;
      // override mobile text alignment
      text-align: left;

      @include from('sm') {
        padding: 0 36px;
      }

      @include from('md') {
        // at this point there are two heros side by side and we want smaller padding
        padding: 0 18px;
      }

      @include from('lg') {
        padding: 0 36px;
      }
    }

    &__texts, &__overlay-image {
      flex: 1 1 0px;
      height: auto;
      // This prevents the image from getting too small if the texts contain something that doesn't want to shrink
      width: 50%;
    }

    &__container {
      // override bottom padding here that is there for an unknown reason
      padding-bottom: 0;

      // override background color on mobile sizes that would normally be light grey on a regular image hero
      &--background-lightGrey {
        background-color: color(grey20);
      }

      &--background-purple {
        background-color: color(corePurple);
      }

      &--background-black {
        background-color: color(grey200);
      }

      &--background-white {
        background-color: color(white);
      }

      &--background-transparent {
        background-color: transparent;
      }

      &--background-deepBeige {
        background-color: color(deepBeige);
      }

      &--background-deepPurple {
        background-color: color(deepPurple);
      }

      &--background-lightPurple {
        background-color: color(lightPurple);
      }

      &--background-lightBeige {
        background-color: color(lightBeige);
      }
    }

    // prevent top margin in mobile
    &__heading {
      margin-top: 0 !important;
    }

    // All this just to keep colors in mobile the same as desktop
    &--color-white {
      color: color(white);
    }

    &--color-black {
      color: color(offBlack);
    }

    &--color-purple {
      color: color(deepPurple);
    }

    &--color-deepPurple {
      color: color(deepPurple);
    }

    &--color-deepBeige {
      color: color(deepBeige);
    }

    &--color-lightPurple {
      color: color(lightPurple);
    }

    &--color-lightBeige {
      color: color(lightBeige);
    }

    &__heading,
    &__subheading,
    &__description {
      color: inherit;

      &--color-white {
        color: color(white);
      }

      &--color-black {
        color: color(offBlack);
      }

      &--color-purple {
        color: color(deepPurple);
      }

      &--color-deepPurple {
        color: color(deepPurple);
      }

      &--color-deepBeige {
        color: color(deepBeige);
      }

      &--color-lightPurple {
        color: color(lightPurple);
      }

      &--color-lightBeige {
        color: color(lightBeige);
      }
    }
  }

  // Actual widths of these heros in various breakpoings
  // xxs:   0 - 480
  //  xs: 480 - 600
  //  sm: 600 - 840
  //  md: 420 - 540 (half) (~xs)
  //  lg: 540 - 720 (half) (~sm)
  // xlg: 720 - 960 (half) (~md)

  // fonts. we just adjust them so that the font size is one breakpoint smaller than normal beginning from md
  // values from typography.scss
  // heading values based on the h2, so image hero should use that (it's configurable there)
  .image-hero__heading, .cms-video-hero__heading {
    font-size: rem(24px);

    @include from('md') {
      font-size: rem(24px);
    }

    @include from('lg') {
      font-size: rem(24px);
    }

    @include from('xlg') {
      font-size: rem(28px);
    }

    @include from('xxlg') {
      font-size: rem(34px);
    }
  }

  // h4 from typography
  .image-hero__subheading { //video-hero does not have a sub-heading
    font-size: rem(20px);

    @include from('md') {
      font-size: rem(20px);
    }

    @include from('lg') {
      font-size: rem(20px);
    }

    @include from('xlg') {
      font-size: rem(22px);
    }
  }

  // h5 from typography.
  .image-hero__description, .cms-video-hero__description {
    font-size: rem(16px);

    // repeat these to make sure we override whatever video hero breakpoints have
    @include from('xs') {
      font-size: rem(16px);
    }

    @include from('sm') {
      font-size: rem(16px);
    }

    @include from('md') {
      font-size: rem(16px);
    }

    @include from('lg') {
      font-size: rem(16px);
    }

    @include from('xlg') {
      font-size: rem(20px);
    }
  }

  .cms-huippudiili-number-offer__form-container-dark {
    & .text-input__field {
      border-color: color(white);
      color: color(white);
      &.has-value {
        &:focus, &.focus {
          & ~ .text-input__label {
            color: color(white);
          }
        }
      }
      &:focus, &.focus {
       & ~ .text-input__label {
         color: color(white);
       }
      }
      & ~ .text-input__label {
        color: rgba(255, 255, 255, 0.66);
      }
    }
    & .cms-text-input__error-text {
      color: color(white);
    }
    & .icon--error-filled {
      color: color(red);
    }
  }

}
