.grid-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @supports (display: grid) {
    display: grid;
    grid-gap: 13px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));

    &--mobile-column-small {
      grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
    }
    
    &--mobile-column-medium {
      grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
    }
    
    &--mobile-column-large {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    @include from('xs') {
      &--column-small {
        grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
      }
  
      &--column-medium {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      }
  
      &--column-large {
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      }
    }
  }
}

/* IE 11 fallback */
_:-ms-fullscreen, :root .grid-container {
  margin: -6.5px;
}

_:-ms-fullscreen, :root .grid-container > * {
  margin: 6.5px;
}

_:-ms-fullscreen, :root .grid-container--mobile-column-small > * {
  width: 130px;  
}

_:-ms-fullscreen, :root .grid-container--mobile-column-medium > * {
  width: 165px;  
}

_:-ms-fullscreen, :root .grid-container--mobile-column-large > * {
  width: 300px;
}

@include from('xs') {
  _:-ms-fullscreen, :root .grid-container--column-small > * {
    width: 130px;
  }

  _:-ms-fullscreen, :root .grid-container--column-medium > * {
    width: 200px;
  }
  
  _:-ms-fullscreen, :root .grid-container--column-large > * {
    width: 300px;
  }
}

