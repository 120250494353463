.cms-customer-support-page {
  // override from columnrow.scss
  &.grid .columnrow {
    margin: 0;
  }

  &__main-cards {
    position: relative;

    @include from('md') {
      margin-top: -180px;
    }
  }

  &__personalized-cards,
  &__disturbance-cards,
  &__category-cards {
    padding-bottom: 24px;
  }

  &__personalized-cards {
    margin-top: -140px;

    @include from('md') {
      margin-top: 0px;
      min-height: calc(180px + 24px);
    }

    &--category {
      margin-top: 0;

      @include from('md') {
        min-height: 0;
      }

      & .cms-logged-out-card,
      .cms-error-card {
        margin-top: 24px;

        @include from('md') {
          margin-top: 36px;
        }
      }
    }
  }

  &__disturbance-cards-title,
  &__category-cards-title {
    color: color(black);

    &--overlaps-hero {
      @include from('md') {
        color: color(white);
      }
    }
  }

  &__disturbance-cards-title,
  &__category-cards-title {
    font-size: 18px;
    font-weight: bold;
    margin: 20px 0;

    @include from('md') {
      font-size: 24px;
      margin: 24px 0;
    }

    @include from('lg') {
      font-size: 32px;
    }
  }
  &__category-description {
    padding-top: 0;
    padding-bottom: 24px;
    > *:first-child {
      margin-top: 3px;
    }
  }

  &__category-description * {
    @include to('sm') {
      font-size: 16px;
    }
  }
  &__contact-cards-title h2 {
    font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: bold;
    margin: 20px 0;
    text-transform: none;

    @include from('md') {
      font-size: 24px;
      margin: 24px 0;
    }

    @include from('lg') {
      font-size: 32px;
    }
  }
}
