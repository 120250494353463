.cms-flat-product-card-container {
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */

  &__title {
    color: color(grey200);
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 500;

    @include from('md') {
      font-size: 24px;
      margin-bottom: 24px;
    }
  }
  
  &__description {
    color: color(grey100);
    font-size: 14px;
    margin-bottom: 20px;
    
    @include from('md') {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
}
