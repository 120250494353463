.cms-aku-availability-search {
  margin-bottom: 3em;
  background-color: transparent;
  position: relative;

  &--small {
    background-color: color(white);
    border-radius: 8px;
    margin-bottom: 0;
    padding: 16px;
    box-shadow: shadow('lg');

    @include from('sm') {
      font-size: 0.95em;
      padding: 8px;
    }

    @include from('md') {
      padding: 16px;
      font-size: 1em;
      max-width: 600px;
    }
  }

  &__title {
    .cms-aku-availability-search--small & {
      margin: 16px 16px 0 16px;
    }
  }

  &__additional-text {
    padding-left: 1em;
    padding-right: 1em;
    max-width: 100%; // Fixes IE flex wrap bug

    @include from ('md') {
      padding-left: 24px;
      padding-right: 12px;
    }

    .cms-aku-availability-search--small & {
      padding-left: 1em;
      padding-right: 1em;
    }
  }

  &__anchor {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
  }

  &__form {
    visibility: hidden;
  }

  &__container {
    background-color: color(white);
    box-shadow: 2px 3px 20px 7px color(grey30);
    border-radius: 8px;
    display: flex;
    align-items: center;
    margin-top: 36px;
    flex-direction: column;

    @include to('sm') {
      padding-bottom: 10px;
    }

    @include from('lg') {
      flex-direction: row;
      height: 88px;
    }

    .cms-aku-availability-search--small & {
      margin-top: 0;
      padding-bottom: 0;
      flex-direction: column;
      height: auto;
      box-shadow: none;
    }

    .text-input {
      margin-top: 1em;

      @include from('lg') {
        margin-top: 0;
      }

      .cms-aku-availability-search--small & {
        margin-top: 1.5em;
      }

      &__error-text {
        position: absolute;
        bottom: -10px;
        margin-left: 3.75em;

        @include from('lg') {
          width: 100%;
          bottom: -25px;
          margin-left: 2.75em;
        }

        @include from('xlg') {
          margin-left: 3.75em;
        }

        .cms-aku-availability-search--small & {
          position: static;
          margin-left: 3.75em;
          line-height: 16px;
        }
      }
    }

    .text-input__field {
      margin-left: 2em;
      padding-right: 22px;
      box-sizing: border-box;
      width: calc(100% - 22px);

      @include from('lg') {
        margin-left: 1.25em;
      }

      @include from('xlg') {
        margin-left: 2em;
      }

      .cms-aku-availability-search--small & {
        margin-left: 2em;
      }

      flex-grow: 1;
      padding-top: em(8px);

      &:disabled {
        border-bottom: 1px solid color(grey40);
      }

      &:focus, &.focus, &.has-value  {
        & ~ .text-input__label {
          top: 14px;
          margin-left: 5em;

          @include from('lg') {
            top: 0;
            margin-left: 4.5em;
          }

          @include from('xlg') {
            margin-left: 6.25em;
          }

          .cms-aku-availability-search--small & {
            top: 14px;
            margin-left: 5em;
          }
        }
      }
    }

    .text-input__field::-ms-clear {
      display: none;
    }

    .text-input__label {
      left: 0;
      margin-left: 3.75em;
      top: 50%;
      transform: translateY(-50%);

      @include from('lg') {
        margin-left: 3.25em;
      }

      @include from('xlg') {
        margin-left: 4.75em;
      }

      .cms-aku-availability-search--small & {
        margin-left: 3.75em;
      }
    }
  }

  &__input-container {
    flex-grow: 1;
    margin-left: 0.75em;
    width: 100%;
    display: flex;
    padding-top: 1em;
    padding-bottom: 1em;

    @include from('lg') {
      padding-top: 0;
      padding-bottom: 0;
    }

    .cms-aku-availability-search--small & {
      padding-top: 1em;
      padding-bottom: 0;
    }

    .ppr-autocomplete__list {
      position: absolute;
      left: 3.75em;
      top: 3em;
      width: auto;
      right: 1em;

      @include from('lg') {
        top: 2em;
        right: 0;
        left: 3.5em;
      }

      @include from('xlg') {
        left: 4.75em;
        right: 1em;
      }

      .cms-aku-availability-search--small & {
        left: 3.75em;
        top: 3em;
        right: 1em;
      }
    }
  }

  &__street-input-icon-ok, &__street-input-icon-loading, &__postalcode-input-icon-ok {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);

    @include from('lg') {
      right: 0;
    }

    @include from('xlg') {
      right: 16px;
    }

    .cms-aku-availability-search--small & {
      right: 16px;
    }

    .icon--checkmark {
      fill: color(darkGreen);
      float: right;
      font-size: 18px;
      justify-content: flex-end;
    }

    .animation--loader {
      font-size: 20px;
    }
  }

  &__item {
    flex-grow: 1;
    display: flex;
    margin-top: 1em;
    width: 100%;
    flex-wrap: wrap;

    @include to ('md') {
      padding: 0 1em;
    }

    @include from ('lg') {
      padding-left: 22px;
      width: auto;
    }

    @include from ('xlg') {
      padding-left: 32px;
      padding-right: 12px;
    }

    .cms-aku-availability-search--small & {
      padding: 0 1em;
      width: 100%;
    }
  }

  &__postalcode {
    @include from('lg') {
      width: 20%;
    }

    @include from('xlg') {
      width: auto;
    }

    .cms-aku-availability-search--small & {
      width: 100%;
    }
  }

  &__postalcode-input {
    // Hide the spinner controls in WebKit browsers (Chrome, Safari)
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    // Hide the spinner controls in Firefox
    -moz-appearance: textfield;
  }

  &__apartment {
    @include from('lg') {
      width: 20%;
    }

    @include from('xlg') {
      width: auto;
    }

    .cms-aku-availability-search--small & {
      width: 100%;
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    font-size: 1.5em;
    transform: translateY(-50%);
  }

  &__submit {
    background-color: color(corePurple);
    &:disabled {
      background-color: color(grey50);
      border-color: transparent;
    }
    &:hover {
      background-color: hover(corePurple);
    }
  }

  &__validate {
    flex-grow: 0;
    align-self: center;

    &-button {
      padding: 28px 0 16px;

      @include from('lg') {
        align-self: flex-end;
        padding: 2px 18px 0 0;
      }

      .cms-aku-availability-search--small & {
        padding: 16px 0 0 0;
      }
    }
  }

  &__error {
    color: color(red);
    max-width: 400px;
    margin-top: 1em;
    padding-left: 1em;
    padding-right: 1em;

    @include from ('md') {
      padding-left: 24px;
      padding-right: 12px;
      max-width: 600px;
    }

    .cms-aku-availability-search--small & {
      padding-left: 1em;
      padding-right: 1em;
      max-width: 400px;
    }
  }

  &__load-animation {
    display: block;
    width: 100%;
    margin-top: 20px;
    text-align: center;

    :before, :after {
      background-color: color(grey10);
    }

    @include from ('md') {
      position: relative;
      top: 0.4em;
    }

    .cms-aku-availability-search--small & {
      position: static;
      top: 0;
    }
  }

  & .component-loader__wrapper {
    border-radius: 8px;
  }

  &__akuresults {
    font-size: 16px;
    line-height: 24px;
    margin: 10px 20px 10px 30px;
    padding-top: 10px;
    padding-bottom: 10px;

    .cms-aku-availability-search--small & {
      margin-left: 16px;
    }
  }

  &__result {
    display: none;
  }

  &__result-operator-details {
    display: none;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

}
