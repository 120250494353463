.splash {
  width: 1em;
  height: 1em;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: color(white);
  background-color: color(black);
  margin: 10px;
  font-size: 80px;

  @include from('sm') {
    font-size: 96px;
  }

  @include from('md') {
    font-size: 128px;
  }

  &--orange {
    background-color: color(orange);
  }

  &__top-text {
    line-height: 1;
    font-size: 10px;

    @include from('md') {
      font-size: 12px;
    }
  }

  &__main-text {
    @include teliaSansHeading;
    font-size: 0.2656em;
    line-height: 1;
    font-weight: bold;
    margin: 3px 0 2px;

    @include from('md') {
      margin-bottom: 4px;
    }
  }

  &__bottom-text {
    line-height: 1;
    font-size: 10px;

    @include from('md') {
      font-size: 12px;
    }
  }

  &--small {
    font-size: 80px;

    .splash__top-text,
    .splash__bottom-text {
      font-size: 10px;
    }
  }

  &--image {
    width: auto;
    height: auto;
    align-self: flex-start;
    display: inline-block;
  }
}
