.country-search {

  &__content {
    height: 424px;
    overflow: hidden;
    padding: 1em;
    background-size: cover;
  }

  &__container {
    padding-bottom: 2em + em(16px);
    vertical-align: middle;
  }

  &__specific {
    position: absolute;
    width: 100%;
    height: 424px;
    margin: -1em;
    background-size: cover;
    opacity: 0;
    transition: opacity 0.8s;

    &--visible {
      opacity: 1;
    }
  }

  &__welcome {
    color: #ffffff;
    text-align: center;
  }

  &__heading {
    position: relative;
    z-index: z(heroText);
    text-align: center;
    margin: 118px auto 0;

    &--white {
      color: color(white);
    }

    &--black {
      color: color(black);
    }
  }

  &__subheading {
    position: relative;
    margin: 0.9em 0 1.5em;
    z-index: z(heroText);
    font-size: 18px;
    text-align: center;
    max-width: 100%;

    &--white {
      color: color(white);
    }

    &--black {
      color: color(black);
    }
  }

  &__selector {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    position: absolute;
    left: 0;
    right: 0;

    .ppr-autocomplete__list {
      margin-top: -3px;
      font-size: 18px;
    }

    .ppr-autocomplete__item {
      padding-left: 3em;
    }
  }

  &__input {
    width: 100%;
    padding: 1.1em 1em 1.1em 3em;
    outline: 0;
    border: 1px solid color(grey30);
    font-size: 18px;
    -moz-box-sizing: border-box;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 color(grey30);

    @include placeholder {
      color: color(grey70);
    }
  }

  &__icon {
    position: absolute;
    font-size: 1.5em;
    color: color(black);
    left: 0.7em;
    top: 1.5em;
  }

  &__clear {
    position: absolute;
    right: 0.7em;
    top: 1.5em;
    font-size: 1.5em;
    color: #a0a0a0;
    background-color: #ffffff;
    cursor: pointer;
    display: none;
  }
}
