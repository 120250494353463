.revealable-component {
  text-align: center;

  &__anchor {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
  }
  
  &__content {
    text-align: left;
    max-height: 0;
    transition: max-height 0.4s, padding-top 0.4s, padding-bottom 0.4s;
    // Transition timing function to collapse content
    transition-timing-function: cubic-bezier(0, 1, 0, 1);
    overflow: hidden;
  }

  &__container {
    display: block;
    position: relative;
  }

  &__item--open .revealable-component__content {
    max-height: none;

    .tablist-accordion__item--open .tablist-accordion__content {
      padding-top: 0;
      margin-top: -5px;
    }
  }
  &__item {
    float: none;
    background: none;

    &--open .revealable-component__content {
      max-height: 10000px;
      // Transition timing function to expand content
      transition-timing-function: cubic-bezier(1, 0, 1, 0);
    }
  }

  &__heading {
    display: flex;
    width: 100%;
    cursor: pointer;
    user-select: none;
    position: relative;
    justify-content: center;

    &--left {
      justify-content: flex-start;
      text-align: left;
    }

    &--large {
      font-size: 32px;
      font-weight: bold;
      line-height: 32px;
    }

    &--color-purple {
      color: color(corePurple);

      &:hover {
        color: hover(corePurple);
      }
    }

    &--color-grey {
      color: color(grey50);

      &:hover {
        color: hover(grey50);
      }
    }

    // Pebble left here for backwards compatibility, it is no longer selectable from the dialog.
    &--pebble, &--heading {
      @include teliaSansHeading;
    }

    &--hidden {
      display: none;
    }
  }

  &__title {
    max-width: 90%;
    display: inline-block;
    margin-right: 10px;
  }

  &__show-less-text {
    display: inline-block;
    margin-right: 10px;
  }

  &__icon {
    .revealable-component__heading--large & {
      svg {
        vertical-align: middle;
        margin-top: 0;
      }
      top: 0;
      width: auto;
      height: auto;
      font-size: 18px;
    }

    font-size: 14px;
    transition: transform 0.2s;
    width: 14px;
    height: $lineHeight;

    svg {
      margin-top: 5px;
    }

    &--rotate {
      transform: rotate(180deg);
    }
  }
}
