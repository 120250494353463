.big-hero {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
  color: color(grey100);

  @include from('sm') {
    padding: 105px 0 75px;
  }

  &__background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &--mobile {
      @include from('sm') {
        display: none;
        background: none !important; // Don't download image on wrong breakpoint
      }
    }

    &--desktop {
      @include to('xs') {
        display: none;
        background: none !important; // Don't download image on wrong breakpoint
      }
    }
  }

  &__grid {
    &--image-on-left {
      flex-direction: row-reverse;
    }
  }

  &__text-container {
    display: flex;
    z-index: z(forward);
    align-items: normal;
    text-align: left;

    @include to('xs') {
      .big-hero--align-mobile-center & {
        align-items: center;
        text-align: center;
      }
    }

    @include from('sm') {
      align-items: flex-start;
      text-align: left;
    }

    & > * {
      /* IE11 Fix, a block level element should occupy 100%, IE needs to be told this */
      width: 100%;
    }
  }

  &__component-area-component {
    margin-bottom: 20px;

    @include from('xs') {
      margin-bottom: 30px;
    }

    & > * {
      margin: 0;
    }
  }

  &__component-area {
    &--bottom {
      .big-hero__component-area-component:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__back-link-container {
    display: flex;
    align-items: center;
  }

  &__back-link {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: color(grey70);
    font-weight: bold;

    &--grey50 {
      color: color(grey50);
    }

    .icon {
      margin-right: 6px;
      font-size: 0.8em;
    }

    @include from('xs') {
      font-size: 18px;
      margin-left: -22px;

      .icon {
        margin-right: 10px;
      }
    }
  }

  &__breadcrumbs-container {
    margin: 25px 0 -25px;
    z-index: z(forward);
  }

  &__breadcrumb-link {
    font-size: 14px;
    font-weight: bold;
    margin-right: 3px;

    @include from('xs') {
      font-size: 18px;
    }

    &--grey {
      color: color(grey50);
    }

    &--white {
      color: color(white);
      opacity: 0.64;
    }
  }

  &__title {
    margin: 25px 0;

    @include from('xs') {
      margin: 40px 0;
    }

    &--wide {
      @include from('sm') {
        width: 150%;
      }
    }

    &--purple {
      color: color(deepPurple);
    }

    &--white {
      color: color(white);
    }

    &--black {
      color: color(offBlack);
    }

    &--deepPurple {
      color: color(deepPurple);
    }

    &--lightPurple {
      color: color(lightPurple);
    }

    &--lightBeige {
      color: color(lightBeige);
    }

    &--deepBeige {
      color: color(deepBeige);
    }
  }

  &__description {
    margin-top: 0;

    /* IE11 Fix, a block level element should occupy 100%, IE needs to be told this */
    width: 100%;

    &--black {
      color: color(offBlack);
    }

    &--white {
      color: color(white);
    }

    &--deepPurple {
      color: color(deepPurple);
    }

    &--lightPurple {
      color: color(lightPurple);
    }

    &--lightBeige {
      color: color(lightBeige);
    }

    &--deepBeige {
      color: color(deepBeige);
    }

  }

  &__feature-image {
    display: none;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    @include from('sm') {
      display: block;
    }

    &--mobile {
      display: block;
      height: auto;
      width: 70%;
      margin-bottom: 20px;
      align-self: center;

      /* IE 11 image in flex fix. Without this, the image would stretch vertically */
      min-height: 1px;

      @include from('sm') {
        display: none;
      }
    }
  }

  &__feature-components {
    display: flex;
    align-items: center;

    & > * {
      /* IE11 Fix, a block level element should occupy 100%, IE needs to be told this */
      width: 100%;
    }
  }
}
