.cms-list-sub-pages {
  a {
    width: 100%;
  }

  &__item {
    list-style-type: none;
    margin-top: 1.1rem;
  }

  &__edit {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;

    .icon {
      color: black;
      font-size: 1rem;
    }
  }
}
