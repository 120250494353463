.responsivetable {
  padding-top: 10px;
  tr {
    &:nth-child(odd) {
      background-color: color(white);
    }

    &:not(:last-child) {
      border-bottom: 2px solid color(grey20);
    }

    &:first-child {
      background-color: rgba(color(white), 0.95);
    }
  }

  &__table {
    display: flex;
    color: color(black);
  }

  &__row {
    display: inline-flex;
  }

  &__content {
    p {
      margin-top: 4px;
    }
  }

  &__header-row {
    .responsivetable__content {
      p {
      margin: 0.15em 0;
      &:nth-of-type(1) {
        color: color(grey50);
      }
      &:nth-of-type(2) {
        font-size: 14px;
        font-weight: bold;
        a {
          &:link {
            color: color(black);
          }
          &:hover , &:active {
            color: hover(corePurple);
          }
        }
      }
    }
    }
  }

  @include from('md') {
    flex-direction: row;
    &__body {
      display: flex;
      flex-direction: column;
      tr {
        display: inline-flex;
        padding: 8px 0;

        &:last-child {
          .custom-button {
            .button {
              margin-top: 19px;
            }
          }
        }
      }
    }

    &__row-header {
      padding: 0;
      display: flex;
      flex: 1 1 240px;

      p {
        margin: 0 !important;
      }
    }

    &__header-row {
      position: sticky;
      top: 78px;

      img {
        height: 136px;
        transition: transform ease 0.2s;
        width: 136px;
        padding: 0 0 18px;
        &:hover {
          transform: scale(1.13235);
        }
      }
      .responsivetable__content {
        p {
          &:nth-of-type(2) {
            font-family: TeliaSansHeading, TeliaSans, 'Helvetica Neue', Helvetica, Arial, sans-serif;
            font-size: 21px;
          }
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1 1 312px;
      padding: 0 0 0 48px;
        img {
          height: 136px;
          width: 136px;
          padding: 0 0 18px;
        }
        p {
          margin: 0;
          .custom-button {
              margin: 10px -8px 10px;
          }
        }


    }

    &__row {
      width: 100%;
      flex-direction: row;
      p {
        margin: 0.5em 0;
      }
    }

    &__table {
      flex-direction: column;
      padding: 0 24px;
    }

    &__scroll-container {
      border-radius: 2px;
      box-sizing: border-box;
      flex: 1 1 912px;
    }
  }

  @include to('sm') {
    flex-direction: column;
    &__table {
      min-width: 0 !important;
      overflow-x: scroll;
      padding-left: 133px;
    }

    &__header-row {
      background-color: rgba(255,255,255,0.9) !important;
      box-shadow: 2px 2px 4px 0 transparent;
      left: 0;
      position: absolute;
      transition: box-shadow ease 0.2s;
      width: 133px;
    }

    &__row-header {
      border-bottom: 2px solid color(grey20);
      box-sizing: border-box;
      font-size: 14px;
      font-weight: bold;
      height: 40px;
      line-height: 20px;
      overflow: hidden;
      padding: 10px 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 133px;
        p {
          margin: 0;
        }
        strong {
          width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
        }
    }

    &__content {
      height: 154px;
      width: 133px;
      overflow: hidden;
      padding: 10px 16px;
      border-bottom: 2px solid color(grey20);
      display: flex;
      flex-direction: column;

        img {
          height: 64px;
          width: 64px;
        }

        .custom-button {
          display: none;
        }

        p {
          margin: 0;
        }
    }

    &__scroll-container {
      overflow: hidden;
      position: relative;
    }

    &__body {
      display: flex;
      flex-direction: row;
        tr {
          flex-direction: column;
          display: inline-flex;
          border-bottom: none !important;
        }
    }
  }
}
