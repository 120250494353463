.heading-component {

  &__anchor {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
  }

  &__heading {
    padding-top: 0;
    padding-bottom: 0;

    &--align-left {
      text-align: left;
    }

    &--align-center {
      text-align: center;

      @include from('lg') {
        margin-left: auto;
        margin-right: auto;
      }
    }

    &--align-right {
      text-align: right;

      @include from('lg') {
        margin-left: auto;
      }
    }

    &--color-black {
      color: color(grey200);
    }

    &--color-white {
      color: color(white);
    }

    &--color-purple {
      color: color(corePurple);
    }

    &--color-deepPurple {
        color: color(deepPurple);
    }

    &--color-deepBeige {
        color: color(deepBeige);
    }

    &--color-lightPurple {
        color: color(lightPurple);
    }

    &--color-lightBeige {
        color: color(lightBeige);
    }

    &--font-weight-normal {
      font-weight: normal;
    }
  }

  & + .textbox {
    margin-top: 0;

    > p:first-child {
      margin-top: 0;
    }
  }

  &--top-spacing-none &__heading {
    margin-top: 0;
  }

  &--bottom-spacing-none &__heading {
    margin-bottom: 0;
  }
}

h2.heading-component__heading {
  margin-top: 1.786em;
  margin-bottom: 1em;
}

h3.heading-component__heading {
  margin-top: 1.786em;
  margin-bottom: 1em;
}
