.services-list {
  background-color: color(grey20);
  flex-wrap: nowrap;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;

  &__title {
    @include teliaSansHeading;
    font-size: 20px;
    padding-top: 16px;
    margin-bottom: -22px;

    @include from('md') {
      font-size: 24px;
      padding-top: 40px;
      margin-bottom: -8px;
    }
  }

  & > .grid {
    overflow-x: auto;
    overflow-y: hidden;
  }

  &__arrow {
    position: absolute;
    color: color(corePurple);
    background-color: color(white);
    transition: opacity 0.3s, background-color 0.3s, color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: z(scrollArrow);
    cursor: pointer;
    display: none;
    width: 48px;
    height: 48px;
    font-size: 20px;
    opacity: 0.6;
    border-radius: 48px;
    box-shadow: 3px 3px 6px 0px rgba(color(black), 0.3);
    pointer-events: none;

    &:hover {
      opacity: 1;
      background-color: color(corePurple);
      color: color(white);
    }

    &--right {
      right: 8px;
      @include from('md') {
        right: 48px;
      }
    }

    &--left {
      left: 8px;
      @include from('md') {
        left: 48px;
      }
    }
  }

  &__scroll--left &__arrow--left {
    display: block; // For IE
    display: flex;
    pointer-events: auto;
  }

  &__scroll--right &__arrow--right {
    display: block; // For IE
    display: flex;
    pointer-events: auto;
  }

  &__subscription {
    flex-shrink: 0;
  }

  &__subscription-title {
    @include teliaSansHeading;
    font-size: 24px;
    line-height: 24px;
    color: inherit;
    margin-bottom: 6px;
  }

  &__subscription-detail {
    color: inherit;
    margin-bottom: 4px;
  }

  &__subscription-type {
    color: inherit;
    margin-bottom: 4px;
  }

  &__subscription-address {
    line-height: 24px;
  }

  &__service-texts {
    max-width: 75%;
    overflow: hidden;
    float: left;
  }

  &__service-link {
    height: 100%;
    transition: transform 0.2s;
    transform: scale(1);
    display: flex;
    justify-content: space-between;
    padding: 24px 24px 48px;

    &:link, &:visited {
      color: color(grey200);
    }
  }

  &__service-link-container {
    background-color: color(white);
    box-shadow: 0 1px 2px 0 color(grey30);
    border-radius: 4px;
    transition: transform 0.2s;
    margin: 40px 0;
    transform: scale(1);
    overflow: hidden;
    height: 210px;

    @include to('xs') {
      margin-left: 3px;
      margin-right: 3px;
    }

    &:link, &:visited {
      color: color(grey200);
    }

    &:hover {
      z-index: z(forward);
      transform: scale(1.03);
      box-shadow: rgba(color(black), 0.2) 0 1px 4px;
      color: color(corePurple);
    }
  }

  &__service-icon {
    float: right;
    order: 1;
    font-size: 48px;
    color: color(black);
    margin: 0 0 16px;
  }

  &__editor-content {
    padding: 20px 40px;
    background-color: color(grey50);
    border: 1px solid color(black);
  }

  &__promotion {
    flex-shrink: 0;
  }

  &__service-links--1 {
    .services-list__promotion--small {
      @include from('lg') {
        display: none;
      }
    }
    .services-list__promotion--medium {
      @include to('md') {
        display: none;
      }
      @include from('xlg') {
        display: none;
      }
    }
    .services-list__promotion--large {
      @include to('lg') {
        display: none;
      }
    }
  }

  &__service-links--2 {
    .services-list__promotion--small {
      @include from('xlg') {
        display: none;
      }
    }
    .services-list__promotion--medium {
      @include to('lg') {
        display: none;
      }
    }
    .services-list__promotion--large {
      display: none;
    }
  }

  &__service-links--3-or-more {
    .services-list__promotion--small {
      display: block;
    }
    .services-list__promotion--medium {
      display: none;
    }
    .services-list__promotion--large {
      display: none;
    }
  }

  &__promotion-content {
    height: 210px;
    margin: 40px 0;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
    color: #fff;
    padding: 35px 25px 25px 25px;
    position: relative;
  }

  &__promotion-heading {
    @include teliaSansHeading;
    top: 50px;
    left: 20px;
    font-size: 24px;
    padding-bottom: 7px;
    text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.6);
  }

  &__promotion-button {
    position: absolute;
    bottom: 20px;
  }
}

.subscription-teaser {
  background-size: cover;
  padding: 8px 24px;
  position: absolute;
  top: calc(100% - 10px);
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  border-radius: 0 0 4px 4px;

  // TODO: background images are probably not final
  background-image: url('/.resources/pride-ui-module/webresources/assets/images/subscription-teasers/notification_bg-11_green.jpg');

  /*&--warning {
    background-image: url('/.resources/pride-ui-module/webresources/assets/images/subscription-teasers/notification_bg-25_purple.jpg');
  }

  &--danger {
    background-image: url('/.resources/pride-ui-module/webresources/assets/images/subscription-teasers/notification_bg-25_purple.jpg');
  }*/

  &--open {
    top: 0;
    transition: top 0.4s cubic-bezier(.65,-0.25,.39,-0.32);
    cursor: default;
    border-radius: 4px;
    background-size: 100% 100%;
  }

  &--closed {
    top: calc(100% - 36px);
    transition: top 0.4s cubic-bezier(.64,.83,.6,1.41);
  }

  &__message {
    white-space: nowrap;
    opacity: 1;
    transition: opacity 0.4s cubic-bezier(.45,.45,.45,.45);
    color: white;
    font-weight: bold;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;

    .subscription-teaser--open & {
      opacity: 0;
      position: absolute;
    }
  }

  &__content {
    display: none;
    height: 100%;
    text-align: center;
    padding: 25px 20px 5px 20px;

    .subscription-teaser--open & {
      display: flex;
      flex-direction: column;
    }
  }

  &__description {
    color: white;
    font-weight: bold;
    font-size: 18px;
  }

  &__button {
    margin-top: auto;
  }

  &__close-icon {
    color: white;
    cursor: pointer;
    position: absolute;
    right: 4px;
    top: 4px;
    font-size: 32px;
  }
}
