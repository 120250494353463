.disturbance-notification {
  margin-top: 40px;

  h2 {
    margin-top: 0.2em;
  }

  &__last-modified {
    color: color(grey50);
    font-size: 12px;
  }

  &__container {
    padding-top: 40px;
  }

  &__back {
    &-link {
      margin-top: 20px;
    }

    &-icon {
      vertical-align: middle;
    }
  }
}
