.disturbance-map {
  margin: 2em 1em;

  &__container {
    display: flex;
    flex-direction: column;

    @include from('md') {
      flex-direction: row;
    }
  }

  &__map {
    order: 2;
    height: 540px;
    width: 100%;

    @include from('md') {
      order: 1;
      height: 690px;
      width: 100%;
      max-width: 1024px;
    }
  }

  &__toolbar {
    order: 1;
    padding: 2em 0 0 0;

    @include from('md') {
      order: 2;
      padding: 2em;
    }

    &-title {
      font-size: 16px;
      font-weight: bold;
    }
  }

  &__color-and-description {
    margin-bottom: 12px;

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }

  &__color {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border-radius: 50%;

    &--active {
      background-color: color(red);
    }

    &--change {
      background-color: color(orange);
    }

    &--fixed {
      background-color: color(darkGreen);
    }
  }

  &__area {
    margin-top: 2em;

    &-name {
      margin-bottom: 10px;
    }
  }

  .area-selection {
    margin: 1em 0;

    &__change {
      position: relative;
      cursor: pointer;

      @include from('md') {
        cursor: auto;
      }

      &-icon {
        position: absolute;
        right: 8px;
        top: 4px;
        font-size: 11px;
        height: 20px;
        width: 11px;
        transition: transform 0.2s;

        @include from('md') {
          display: none;
        }
      }
    }

    &__container {
      width: 100%;
      background-color: color(white);
      position: absolute;
      display: none;
      z-index: 100;

      @include from('md') {
        position: static;
        display: block;
      }

      &:after {
        @include to('sm') {
          content: "";
          border: 1px solid transparent;
          width: 100vw;
          position: relative;
          display: block;
          left: calc(-50vw + 50%);
          box-shadow: 0 4px 8px 0px rgba(color(black), 0.25);
        }
      }
    }

    select {
      margin-bottom: 1em;
    }

    &--open {
      .area-selection__container {
        display: block;
      }

      .area-selection__change-icon {
        transform: rotate(180deg);
      }
    }
  }

  &__street-input-container {
    position: relative;
    width: 100%;
    min-width: 280px;
  }

  &__reset-button {
    margin: 2em 0;

    .icon {
      display: none;
    }
  }

  &__faults-container {
    margin-top: 1em;
  }

  &__faults {
    margin-top: 1em;
    display: none;

    @include from('md') {
      display: block;
    }
  }

  .faults-mobile {
    margin-top: 1em;
    display: block;

    @include from('md') {
      display: none;
    }

    &__fault {
      padding: 8px 12px 12px 12px;

      &:nth-child(odd) {
        background-color: color(grey10);
      }
    }

    &__title {
      font-weight: bold;
      margin: 4px 0 4px 0;
    }
  }

  &__zoom {
    cursor: pointer;

    &:hover {
      color: color(corePurple);
    }

    svg {
      position: relative;
      top: 2px;
      display: inline-block;
      margin-right: 4px;
    }
  }

  &__popup {
    display: none;
    box-shadow: 0 0 8px 0 rgba(color(black), 0.25);
    width: 220px;
    background-color: color(white);
    border-radius: 6px;
    position: relative;

    @include from('xs') {
      width: 280px;
    }

    @include from('sm') {
      width: 320px;
    }

    @include from('lg') {
      width: 450px;
    }

    &-container {
      padding: 1em;
      font-size: 11px;
    }

    &-title {
      display: block;
      font-weight: bold;
      width: 130px;

      @include from('md') {
        display: inline-block;
      }
    }

    &-close {
      cursor: pointer;
      font-size: 16px;
      position: absolute;
      top: 0;
      right: 2px;
      padding: 4px;
    }

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      margin-left: 0px;
      bottom: -15px;
      left: 47%;
      box-sizing: border-box;
      border: 8px solid color(black);
      border-color: transparent transparent color(white) color(white);
      transform-origin: 0 0;
      transform: rotate(-45deg);
      box-shadow: -2px 2px 5px 0 rgba(color(black), 0.12);
    }
  }

  // Override default OpenLayers styles for zoom controls
  .ol-control button {
    background-color: color(black);

    &:hover {
      background-color: color(corePurple);
    }
  }
}