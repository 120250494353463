.logged-in-box {

  &__background {
    background-size: cover;
    padding: 62px 0;
    color: color(white);

    &--color-purple {
      color: color(corePurple);
    }

    &--color-black {
      color: color(black);
    }
  }

  &__greeting {
    @include teliaSansHeading;
    display: inline-block;
    align-self: flex-start;
    color: color(deepPurple);
    font-size: 24px;
    background-color: color(white);
    padding: 1em;
    margin: 1em 0;
    border-radius: 8px;
    border-bottom-left-radius: 0;

  }

  &__description {
    margin: 1em 0;
  }
}

.link-card {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  transition: transform 0.2s;
  cursor: pointer;

  @include to('md') {
    margin-bottom: 16px;
  }

  &:hover {
    transform: scale(1.03);
  }

  &__top-bar {
    padding: 0 40px;
    font-size: 40px;
    display: flex;
    align-items: center;
    height: 90px;
    background-color: color(white);
    color: color(black);
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  &__icon-container {
    position: relative;
  }

  &__number {
    position: absolute;
    top: -2px;
    right: -10px;
    background-color: color(blue);
    color: color(white);
    border-radius: 50%;
    text-align: center;
    width: 16px;
    height: 16px;
    font-size: 10px;
    line-height: 16px;
    font-weight: bold;

    @include from('md') {
      width: 22px;
      height: 22px;
      font-size: 12px;
      line-height: 22px;
      top: -8px;
      right: -16px;
    }
  }

  &__title {
    margin-left: 20px;
    font-size: 20px;
  }

  &__description {
    flex-grow: 1;
    padding: 16px;
    font-size: 14px;
    background-color: color(grey20);
    color: color(grey70);
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }

}
