.cms-opt-out {
  &__otp {
    display: none;
  }

  .form-row--text-input {
    margin-bottom: 35px;
  }

  &__error {
    display: none;

    &-text {
      margin-top: 20px;
      margin-bottom: 20px;
      margin-top: 20px;
      color: color(red);
    }
  }

  &__load-animation {
    display: none;
    margin-top: 25px;
  }
}
