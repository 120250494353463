.content-central-quicklinks {
  margin: 0 0 40px 0;

  @include from('md') {
    margin: 80px 0 110px 0;
  }

  &__header {
    text-align: center;
    padding-bottom: 40px;

    @include from('md') {
      display: none;
    }
  }

  &__heading {
    color: color(black);
    margin-left: auto;
    margin-right: auto;
  }

  &__list {
    font-size: 0;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    margin-bottom: 12px;
    width: 100%;
    font-size: 16px;
    text-align: left;

    @include from('md') {
      width: 50%;
      margin-bottom: 20px;

      &:nth-child(odd) {
        padding-right: 6px;
      }

      &:nth-child(even) {
        padding-left: 6px;
      }
    }

    a {
      width: 100%;

      &:link, &:visited {
        color: color(grey70);
      }

      &:hover {
        background-color: color(grey10);

        &:link, &:visited {
          color: color(corePurple);
        }
      }
    }
  }

  &__link {
    display: block;
    padding: 12px 16px 12px 24px;
    border: 1px solid color(grey20);
    position: relative;
    font-weight: bold;

    &:hover {
      color: color(corePurple);
      background-color: color(grey10);
    }
  }

  &__link-label {
    font-weight: bold;
    padding-right: 1.5em;
  }

  &__link-icon {
    position: absolute;
    font-size: 12px;
    width: 16px;
    height: 16px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 16px;

    @include from('md') {
      right: 16px;
    }
  }
}
