.person-list {
  margin-top: 45px;

  &__picture {
    width: 100%;
    height: 24em;
    max-height: 84vw;
    overflow: hidden;

    @include from('md') {
      height: 24vw;
      max-height: none;
    }
   
  }

  &__person {
    margin-bottom: 35px;

    @include from('md') {
      margin-bottom: 55px;
    }

    &:hover .person-list__details {
      color: color(corePurple);
      cursor: pointer;
      h4, .person-list__description {
        color: color(corePurple);
      }
    }
  }

  &__description {
    color: color(black);
  }

  &__image {
    object-fit: cover;
    display: block;
    width: auto;
    margin: auto;
    border-radius: 50%;
    height: 100%;
  }

  &__details {
    overflow: hidden;
    padding: 1em;

    @include from('sm') {
      margin-left: 25px;
    }
    
  }

  &__name {
    font-size: 20px;
    margin-bottom: 6px;
  }

  &__back-icon {
    vertical-align: middle;
  }

  &__read-more {
    position: relative;
    top: 5px;

    &:hover {
      color: hover(corePurple);
    }
  }
}