.reuse-content {
  padding: 10px;
  color: color(darkGreen);

  &__description-item {
    display: flex;
  }

  &__description-title {
    font-weight: bold;
  }
  
  &__description-label {
    width: 100px;
  }

  &__description-value {
    font-family: monospace;
  }

  &--hidden {
    display: none;
  }
}
