.thumbs {

  &__container {
    display: none;
    color: color(grey70);

    &--selected {
      color: color(corePurple);
    }

    .thumbs--enabled &:not(&--selected) {
      color: color(black);
    }

    .thumbs--enabled &:hover {
      cursor: pointer;
      color: color(corePurple);

      .thumbs__icon {
        transform: scale(1.1);
      }
    }

    .thumbs--enabled &:active {
      color: hover(corePurple);
    }
  }

  &__icon {
    display: inline-block;
    font-size: 32px;
    margin-right: -2px;
    transition: all .2s ease-in-out;
  }

  &__down-icon {
    vertical-align: top;
    padding-left: 14px;
  }

  &__count {
    font-weight: bold;
    vertical-align: super;
  }

  &__loader .animation--loader {
    font-size: 32px;
  }
}