@import '../../../config.scss';
.cms-aureg-password {
  @include from('md') {
    max-width: 600px;
  }

  &__title {
    // Override global h2 stylings.
    max-width: none;
    margin-bottom: 20px;

    @include from('md') {
      margin-bottom: 29px;
    }
  }

  &__description {
    // Override global p style
    p {
      max-width: none;
    }
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    @include from('sm') {
      flex-direction: row;
      margin-bottom: 30px;
    }

    // Override external text-input stylings
    & .text-input {
      margin: 0;
      margin-bottom: 15px;
      width: 100%;

      @include from('sm') {
        margin-bottom: 0;
        padding-right: 20px;
      }

      & .text-input__error-text {
        @include from('sm') {
          position: absolute;
        }
      }
    }

    // Override external button stylings
    & > .button {
      align-self: center;
      margin: 0;
      margin-top: auto;
    }
  }

  &__loader {
    display: flex;
    justify-content: center;
  }
}
