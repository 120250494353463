// This some helper container hacked outside the actual component..
.cms-customer-support-search-hero__container {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &--transparent {
    background-color: transparent;
  }

  // Purple background deprecated, only here for backwards compatibility
  // Brand guidelines say that we shouldn't use this as a background color
  &--purple {
    background: radial-gradient(circle, #9848e1 0%, #7218c4 100%);
  }

  &--white {
    background-color: color(white);
  }

  &--black {
    background-color: color(offBlack);
  }

  &--grey {
    background-color: color(lightGray);
  }

  &--deepPurple {
    background-color: color(deepPurple);
  }

  // Deep beige background deprecated, only here for backwards compatibility
  // Brand guidelines say that we shouldn't use this as a background color
  &--deepBeige {
    background-color: color(deepBeige);
  }

  &--beige {
    background-color: color(beige);
  }

  &--lightPurple {
    background-color: color(lightPurple);
  }

  &--lightBeige {
    background-color: color(lightBeige);
  }
}

.cms-customer-support-search-hero {
  width: 100%;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 75px;

  &--has-personalized-cards {
    padding-bottom: 180px;
  }

  @include from('md') {
    padding-top: 80px;
    padding-bottom: 240px;

    &--is-low-hero {
      padding-bottom: 75px;
    }
  }

  &__overlay {
    &--active {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(34, 34, 34, 0.2);
      z-index: z(forward);
    }
  }

  &__container {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &__title {
    display: block;
    text-align: center;
    margin: 0;
    margin-bottom: 16px;
    font-size: 28px;
    padding-top: 0px;
    padding-bottom: 0px;

    // Purple text deprecated, only here for backwards compatibility
    // Brand guidelines say that we shouldn't use this as text color
    &--purple {
      color: color(corePurple);
    }

    &--white {
      color: color(white);
    }

    &--black {
      color: color(offBlack);
    }

    &--grey {
      color: color(lightGray);
    }

    &--deepPurple {
      color: color(deepPurple);
    }

    &--deepBeige {
      color: color(deepBeige);
    }

    &--beige {
      color: color(beige);
    }

    &--lightPurple {
      color: color(lightPurple);
    }

    &--lightBeige {
      color: color(lightBeige);
    }

    @include from('sm') {
      font-size: 32px;
    }

    @include from('md') {
      margin-bottom: 28px;
      font-size: 40px;
    }

    @include from('lg') {
      font-size: 48px;
    }
  }

  &__search-input-container {
    z-index: z(forward);
    position: relative;
  }

  &__results-container {
    z-index: 1;
    position: absolute;
    top: 56px;
    width: 100%;

    #autocomplete-container {
      display: none;

      .suggestions {
        padding: 0;

        li.addsearch-suggestion {
          list-style-type: none;
          display: flex;
          align-items: center;
          color: rgba(0, 0, 0, 0.62);
          cursor: pointer;
          font-weight: 600;
        }

        .icon {
          color: rgba(0, 0, 0, 0.62);
          font-size: 21px;
          margin-right: 22px;
        }
      }
    }

    #results-container {
      display: none;

      .addsearch-searchresults {
        &--no-results {
          &:focus {
            border: 1px solid #5897fb;
          }
          p {
            margin: 0;
          }
        }

        .category-title {
          color: color(grey200);
          font-size: 18px;
          font-weight: bold;
          margin-top: 25px;
          margin-bottom: 14px;
        }

        .hit {
          display: flex;
          justify-content: space-between;
          align-items: center;

          @include to('md') {
            margin-bottom: 8px;
          }

          .category {
            max-width: 40%;
            text-align: end;

            @include to('md') {
              display: none;
            }
          }
        }

        &__result-title {
          color: color(grey200);
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 1.5;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;

          @include to('lg') {
            display: flex;
          }

          @include from('lg') {
            max-width: 50%;
          }

          .icon {
            color: color(corePurple);
            font-size: 16px;
            margin-right: 16px;
          }

          span {
            line-height: 1.1;
            margin-bottom: 8px;
          }
        }
      }
    }

    &--visible {
      border-radius: 16px;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12);
      background-color: color(white);
      padding: 12px 24px;

      #autocomplete-container,
      #results-container {
        display: block;
      }
    }
  }

  #searchfield-container {
    z-index: 1;

    .cms-customer-support-search-hero__search-container {
      width: 100%;
      border-radius: 32px;
      box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.12);
      background-color: color(white);
      margin-bottom: 10px;
      display: flex;
      padding: 8px 12px;

      @include from('md') {
        padding: 10px 25px;
      }

      form,
      input {
        flex: 1;
      }

      input {
        width: 100%;
        height: 100%;
        border: none;
        font-size: 16px;
        outline: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        caret-color: color(corePurple);
        // To prevent safari from adding black borders around the search input (because the input type is search)
        -webkit-appearance: none;
      }

      .cms-customer-support-search-hero__search-icon {
        margin-right: 16px;
        font-size: 25px;

        @include from('md') {
          margin-right: 21px;
        }
      }
    }
  }

  &__suggestions {
    display: block;
    color: color(white);
    text-align: center;

    // Purple text deprecated, only here for backwards compatibility
    // Brand guidelines say that we shouldn't use this as text color
    &--purple {
      color: color(corePurple);

      a {
        color: color(corePurple);
      }
    }

    &--white {
      color: color(white);

      a {
        color: color(white);
      }
    }

    &--black {
      color: color(offBlack);

      a {
        color: color(offBlack);
      }
    }

    &--grey {
      color: color(lightGray);

      a {
        color: color(lightGray);
      }
    }

    &--deepPurple {
      color: color(deepPurple);

      a {
        color: color(deepPurple);
      }
    }

    &--deepBeige {
      color: color(deepBeige);

      a {
        color: color(deepBeige);
      }
    }

    &--beige {
      color: color(beige);

      a {
        color: color(beige);
      }
    }

    &--lightPurple {
      color: color(lightPurple);

      a {
        color: color(lightPurple);
      }
    }

    &--lightBeige {
      color: color(lightBeige);

      a {
        color: color(lightBeige);
      }
    }

    span {
      text-transform: uppercase;
      margin-right: 8px;
      font-weight: bold;
    }

    a {
      text-decoration: underline;
    }
  }
}
