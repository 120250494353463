
.input {
  border: none;
  box-shadow: none;
  transition: background-color 0.1s ease-in, border-color 0.1s ease-in;
  border-radius: 0;
  padding: 20px;
  background-color: transparent;

  @include placeholder {
    color: #fff;
  }
}

.error-message {
  color: color(red);
  display: block;
  text-align: left;
  font-size: 12px;
  line-height: 12px;
  .icon {
    margin-right: 6px;
  }
}

/////// New input styles from styleguide /////////////////////

.text-input {
  position: relative;
  margin-bottom: -5px;
  margin-top: 20px;

  form & {
    padding-top: 0;
    padding-bottom: 0;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &--no-width-adjust &__field {
    max-width: 100%;
    min-width: 100%;
  }

  &__label {
    color: color(grey70);
    font-weight: normal;
    position: absolute;
    top: 26px;
    z-index: z(forward);
    transition: all 0.2s;
    pointer-events: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    animation-delay: 2s;
    animation-name: formFieldFlash;
    animation-duration: 0.7s;
    animation-timing-function: ease-in-out;

    .text-input--has-icon & {
      padding-left: 34px;
    }
  }

  &__field {
    font-family: inherit;
    font-size: 1em;
    border: 0;
    border-bottom: 1px solid color(grey40);
    position: relative;
    display: block;
    width: 100%;
    padding: em(28px) 0 em(5px) 0;
    line-height: 1.25em;
    appearance: none;
    border-radius: 0;
    transition: color 0.2s, border-color 0.2s;
    background-color: transparent;

    &--centered {
      text-align: center;
    }

    .text-input--has-icon & {
      padding-left: 34px;
    }

    .form-container--dark & {
      color: color(white);
    }

    &.has-value {
      border-color: color(grey40);

      & ~ .text-input__label {
        top: 6px;
        font-size: 0.75em;
        color: color(grey50);
        animation-name: none;
      }
    }

    &:focus, &.focus {
      border-color: color(corePurple);
      margin-bottom: 0;
      border-bottom-width: 2px;
      padding-bottom: em(4px);
      outline: none;

      & ~ .text-input__label {
        top: 6px;
        font-size: 0.75em;
        color: color(corePurple);

        .form-container--dark & {
          color: color(grey50);
        }
      }
    }

    &:disabled {
      border-color: color(grey40);
      border-bottom-style: dotted;
      color: color(grey50);
      -webkit-text-fill-color: color(grey50);

      & + .text-input__label {
        animation-name: none;
        color: color(grey40);
      }
    }

    &:read-only {
      border-bottom-color: transparent;
      @include teliaSans;
    }

    &.has-error {
      border-color: color(red);

      & + .text-input__label {
        color: color(red);
      }
    }
  }

  &--independent &__field {
    padding: 0 0 .3125em;
  }

  &--login {
    margin-top: 0;

    .text-input__field {
      padding: 1em 0;
      border: 0;
    }
  }

  &--search {
    width: 100%;
    color: color(black);
    background-color: color(white);
    font-size: 16px;
    padding-left: 60px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);

    @include from('md') {
      font-size: $fontSize;
    }

    &:focus {
      outline: 0;
    }

    @include placeholder {
      color: color(grey70);
    }
  }

  &__icon {
    color: color(black);
    position: absolute;
    top: 50%;
    left: 0;
    font-size: 20px;
    line-height: normal;
    transform: translateY(-50%);
    padding: 15px 20px;
    margin-top: 2px;

    &--search {
      font-size: 24px;
      cursor: pointer;
    }

    &--clear {
      font-size: 24px;
      display: none;
      cursor: pointer;
    }

    &--error {
      font-size: 24px;
      display: none;
      color: color(red);
    }

    &--loader {
      color: color(corePurple);
      padding-top: 14px;
      display: none;
      margin-top: 0;

      .animation--loader {
        margin: 3px 0 0;
        display: inline-block;
        line-height: 1px;
        font-size: 26px;
      }
    }

    .text-input--has-icon & {
      font-size: 21px;
      padding: 0;
      bottom: 1px;
      top: auto;
      transform: none;
    }
  }

  &__error-text {
    color: color(red);
    display: block;
    text-align: left;
    font-size: 12px;
    margin-top: 5px;
  }

  &__info-text {
    color: color(grey50);
    display: block;
    text-align: left;
    font-size: 12px;
    margin-top: 5px;

    .icon {
      fill: color(blue);
    }
  }

  .ppr-autocomplete {
    &__list {
      top: -2px;
      border-bottom: 2px solid rgba(0,0,0,0.15);
      border-top: 2px solid color(corePurple);
    }
  }
}

.file-input {
  &__input {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
  }

  &__field {
    max-height: 0;
    overflow: hidden;
    transition: max-height .5s ease-in, padding .5s ease-in;
    position: relative;

    &--visible {
      max-height: 150px;
      padding: 18px 0 0;
    }
  }

  &__button {
    margin: 24px 1em 6px;
  }

  &__label {
    margin: 0.5em 0;
  }

  &__info-text {
    color: color(grey50);
    display: block;
    text-align: left;
    font-size: 12px;
    margin-top: 5px;

    .icon {
      fill: color(blue);
    }
  }
}

.radio {
  display: none;
  $circleSize: 28px;
  $blipSize: 16px;

  & + label {
    margin: 0 10px 10px 0;
    padding: 0 0 0 35px;
    line-height: $circleSize;
    cursor: pointer;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    user-select: none;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: $circleSize;
      height: $circleSize;
      border: 2px solid color(grey40);
      background-color: color(white);
      border-radius: 999px;
    }

    &::after {
      content: '';
      position: absolute;
      left: $circleSize / 2 - $blipSize / 2;
      top: $circleSize / 2 - $blipSize / 2;
      width: $blipSize;
      height: $blipSize;
      border-radius: 999px;
      background-color: color(corePurple);
      display: none;
    }
  }

  &:checked + label {
    &::after {
      display: block;
    }
  }

  &:disabled + label {
    pointer-events: none;
    color: color(grey40);
    &::before {
      border-color: color(grey40);
      background-color: color(grey20);
    }

    &::after {
      background-color: color(grey40);
    }
  }

  &.invalid + label {
    color: color(red);
    &::before {
      border-color: color(red);
    }
    &::after {
      background-color: color(red);
    }
  }

}

.checkbox {
  display: none;
  $boxSize: 28px;
  $checkSize: 16px;

  & + label {
    margin: 0 10px 10px 0;
    padding: 0 0 0 35px;
    line-height: $boxSize;
    cursor: pointer;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    user-select: none;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: $boxSize;
      height: $boxSize;
      border: 2px solid color(grey40);
      background-color: color(white);
      border-radius: 2px;
    }

    &::after {
      content: '';
      position: absolute;
      left: 6px;
      top: 14px;
      width: 3px;
      height: 3px;
      border-radius: 2px;
      border-bottom: 3px solid color(corePurple);
      border-left: 3px solid color(corePurple);
      transform: rotate(-45deg);
      transform-origin: top left;
      opacity: 0;
      transition: height 0s, width 0s;
    }
  }

  &:checked + label {
    &::after {
      opacity: 1;
      width: $checkSize;
      height: $checkSize / 2;
      transition: height 0.2s, width 0.2s 0.2s;
    }
  }

  &:disabled + label {
    pointer-events: none;
    color: color(grey40);
    &::before {
      border-color: color(grey40);
      background-color: color(grey20);
    }

    &::after {
      border-color: color(grey40);
    }
  }

  &.invalid + label {
    color: color(red);
    &::before {
      border-color: color(red);
    }
    &::after {
      border-color: color(red);
    }
  }

}

/* Focus styles for the custom checkbox - Accessibility changes */
label.custom-checkbox:focus {
  outline: 2px solid #990AE3;
  background-color: #DDE;
}

label.custom-checkbox:hover {
  background-color: #DDE;
}

label.accessible-radio:focus {
  outline: 2px solid #990AE3;
  background-color: #DDE;
}

label.accessible-radio:hover {
  background-color: #DDE;
}


.toggle {
  display: none;
  $sliderWidth: 60px;
  $sliderHeight: 32px;
  $knobSize: 28px;

  & + label {
    margin: 0 10px 10px 0;
    padding: 0 0 0 $sliderWidth + 8px;
    line-height: $sliderHeight;
    cursor: pointer;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    user-select: none;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: $sliderWidth;
      height: $sliderHeight;
      border: 2px solid color(grey40);
      background-color: color(grey40);
      border-radius: 999px;
      transition: border-color 0.3s, background-color 0.3s;
    }

    &::after {
      content: '';
      position: absolute;
      left: 2px;
      top: 2px;
      width: $knobSize;
      height: $knobSize;
      border-radius: 999px;
      background-color: color(white);
      transition: left 0.3s;
    }
  }

  &:checked + label {
    &::before {
      background-color: color(corePurple);
      border-color: color(corePurple);
    }
    &::after {
      left: $sliderWidth - $knobSize - 2px;
    }
  }

  &:disabled + label {
    pointer-events: none;
    color: color(grey40);
    &::before {
      border-color: color(grey40);
      background-color: color(grey40);
    }
  }

  &.invalid + label {
    color: color(red);
    &::before {
      background-color: color(red);
      border-color: color(red);
    }
  }
}

textarea {
  min-height: 75px;
}

.select {
  border-radius: 0;
  -webkit-appearance: button;
  -moz-appearance: button;
  border: none;
}

.form-group {
  margin: 1.5em 0;

  &__legend {
    font-weight: bold;
    margin: 1em 0;
    color: color(grey100);
  }
}
