.job-listing {

  &__anchor {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
  }

  &__heading {
    &--black {
      color: color(black);
    }
  }

  &__select-container {
    display: none;
  }

  &__select-box {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__select-label {
    color: color(grey50);
  }

  &__vacancy {
    padding: 16px 5%;
    width: 100vw;
    margin-left: -5.5%;

    @include from('md') {
      padding: 0;
      margin: 0;
      width: auto;
    }
  }

  &__table {
    display: none;
  }

  &__table-container {
    @include to('md') {
      padding: 0;
    }
  }

  &__column-table-headings {
    display: none;
    @include from('md') {
      display: block;
    }
  }

  &__column-heading {
    font-weight: bold;
  }

  &__vacancy-data {
    display: block;
    padding: 8px 0;
    @include from('md') {
      font-size: 13px;
    }
  }

  &__columns {
    display: flex;
  }

  &__title-column {
    padding: 8px 0;
    max-width: 50%;
    color: color(grey100);

    @include from('md') {
      display: none;
    }
  }

  &__data-column {
    max-width: 50%;
    color: color(black);

    .job-listing__vacancy-link:hover & {
      color: hover(black);
    }
  }

  &__vacancy:nth-child(odd) {
    background-color: color(grey10);
  }

  &__icon {
    position: absolute;
    top: 10px;
    right: 20px;

    @include to('sm') {
      display: none;
    }
  }

  &__button-container {
    display: none;
    padding-top: 30px;

    .button {
      margin-left: 0;
    }
  }

  &__loader{
    @include to('md') {
      margin-top: 26px;
    }
  }

}
