/* Values from: https://react.styleguide.telia.fi/?path=/story/atoms-shadows--overview */

$shadows: (
  zero:  none,
  sm:    0 2px 8px 0 rgba(58, 58, 58, 0.12),
  md:    0 6px 24px 0 rgba(58, 58, 58, 0.12),
  lg:    0 8px 32px 0 rgba(58, 58, 58, 0.18)
);

@function shadow($label) {
  $shadow: map-get($shadows, $label);

  @if $shadow {
    @return $shadow;
  }

  @warn 'Invalid shadow "#{$label}"; add your own to shadows.scss or choose one from shadows.scss';
  @return null;
}
