.editor-note {
  background-color: #FFFF00;
  word-spacing: normal;
  letter-spacing: normal;
  border-bottom: solid 2px #aaaa00;

  &__content {
    padding: 10px 2em;

    @include from ('lg') {
      padding: 10px $reflex-cell-spacing;
    }
  }
}
