.content-central-article-grid {
  &__anchor {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
  }

  margin-bottom: 2em;

  &__teaser-container {
    margin-bottom: 20px;

    @include from('sm') {
      margin-bottom: 16px;
    }

    @include from('lg') {
      margin-bottom: 20px;
    }
  }

  &__available-articles-info {
    position: absolute;
    top: 15px;
    font-size: 14px;
    color: color(grey40);
  }

  &__load-more-button-container {
    clear: left;
    text-align: center;
    padding-top: 3em;
  }

  &__load-more-spinner {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 24px;

    &--hidden {
      display: none;
    }
  }
}
