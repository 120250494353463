.counter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  &--align-mobile-center {
    align-items: center;
  }

  @include from('sm') {
    align-items: flex-start;

    &--align-desktop-center {
      align-items: center;
    }
  }

  &__heading {
    @include teliaSansHeading;
    width: 100%;
  }

  &__title {
    &--center {
      text-align: center;
    }

    &--left {
      text-align: left;
    }

    &--purple {
      color: color(corePurple);
    }

    &--white {
      color: color(white);
    }

    &--black {
      color: color(black);
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__flappers {
    display: flex;
    margin: -6px;

    @include from('md') {
      margin: -10px;
    }

    &--hidden {
      display: none;
    }

    .counter--smaller-size & {
      margin: -6px;
    }
  }

  &__flapper {
    @include teliaSansHeading;
    color: color(grey20);
    display: block;
    position: relative;
    perspective: 250px;
    width: 54px;
    height: 54px;
    font-size: 32px;
    margin: 6px;
    min-width: 54px;
    min-height: 54px;
    transform: translateZ(0);

    @include from('md') {
      width: 80px;
      height: 80px;
      min-width: 80px;
      min-height: 80px;
      font-size: 48px;
      margin: 10px;
    }

    .counter--smaller-size & {
      width: 50px;
      height: 50px;
      min-width: 50px;
      min-height: 50px;
      font-size: 30px;
      margin: 8px;
    }
  }

  &__flapper-unit {
    @include teliaSans;
    position: absolute;
    color: color(grey20);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px;
    line-height: 18px;

    @include from('md') {
      font-size: 13px;
      line-height: 23px;
    }

    .counter--smaller-size & {
      font-size: 10px;
      line-height: 18px;
    }
  }

  &__flapper-rod {
    z-index: z(back);
    display: block;
    width: 110%;
    height: 21%;
    border-radius: 4px;
    background-color: #8802A3;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__flapper-clipper {
    &--dynamic {
      position: absolute;
    }

    &--top {
      display: block;
      height: 50%;
      width: 100%;
      overflow: hidden;
      border-radius: 8px 8px 0 0;

      &.counter__flapper-clipper--dynamic {
        top: 0;
        transition: none;
        transform-origin: bottom;

        .counter__flapper--flap & {
          transition: transform 350ms ease-in;
          transform: rotateX(-90deg);
        }
      }
    }

    &--bottom {
      display: block;
      height: 50%;
      width: 100%;
      overflow: hidden;
      border-radius: 0 0 8px 8px;

      &.counter__flapper-clipper--dynamic {
        top: 50%;
        transition: none;
        transform-origin: top;
        transform: rotateX(89.9999deg);

        .counter__flapper--flap & {
          transition: all 150ms linear;
          transition-delay: 350ms;
          transform: rotateX(0deg);
        }
      }
    }
  }

  &__flapper-number {
    &--top {
      display: block;
      background-color: #AF5AFF;
      height: 200%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--bottom {
      display: block;
      background-color: #AB36E8;
      height: 200%;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(-50%);
    }
  }

  &__flapper-number-value {
    transform: translateY(-3px);

    &--without-unit {
      transform: translateY(0);
    }
  }

  &__ended-text {
    @include teliaSansHeading;
    margin-top: 4px;
    font-size: 18px;
    text-align: center;

    @include from('md') {
      margin-top: 10px;
      font-size: 22px;
    }

    .counter--smaller-size & {
      margin-top: 4px;
      font-size: 18px;
    }

    &--purple {
      color: color(corePurple);
    }

    &--white {
      color: color(white);
    }

    &--black {
      color: color(black);
    }

    &--hidden {
      display: none;
    }
  }
}
