.media-container {
  display: flex;

  &--video {
    //IE 11 won't display videos properly when in a flexbox
    display: block;

    //IE 11 flex bug fix https://github.com/philipwalton/flexbugs/issues/75
    min-height: 1px;
  }

  &:only-child {
    min-height: 100%;
  }

  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: flex-end;
  }

  &--vertical-center {
    align-items: center;
  }

  &--vertical-stretch {
    align-items: stretch;
  }

  &--vertical-bottom {
    align-items: flex-end;
  }

  &--background-white {
    background-color: color(white);
  }

  &--background-grey {
    background-color: color(grey20);
  }

  &--background-deepPurple {
    background-color: color(deepPurple);
  }


  &--background-deepBeige {
    background-color: color(deepBeige);
  }


  &--background-lightPurple {
    background-color: color(lightPurple);
  }


  &--background-lightBeige {
    background-color: color(lightBeige);
  }

  &__image {
    max-width: 100%;
    display: block;

    &--fit {
      width: 100%;
      max-width: none !important; // Need important to override inline style
    }
  }

  &__picture {
    &--fit {
      width: 100%;
    }
  }

  &__icon {
    &--small {
      font-size: 24px;
    }

    &--medium {
      font-size: 48px;
    }

    &--large {
      font-size: 72px;
    }

    &--75percent {
      height: 75%;

      >.icon {
        width: auto;
        height: 100%;
      }
    }

    &--50percent {
      height: 50%;

      >.icon {
        width: auto;
        height: 100%;
      }
    }

    &--100percent {
      height: 100%;

      >.icon {
        width: auto;
        height: 100%;
      }
    }

    &--black {
      color: color(black);
    }

    &--white {
      color: color(white);
    }

    &--blue {
      color: color(blue);
    }

    &--green {
      color: color(darkGreen);
    }

    &--red {
      color: color(red);
    }

    &--orange {
      color: color(orange);
    }

    &--purple {
      color: color(corePurple);
    }
  }

  &__video {
    position: relative;

    &--asset {
      width: 100%;
      position: relative;

      video {
        width: 100%;
        max-width: 100%;
        display: block;
      }
    }

    &--youtube {
      position: relative;
      width: 100%;
      padding-bottom: 56.25%; // aspect ratio 16:9

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    &--image-in-mobile {
      padding-bottom: 0;
    }
  }

  &__video-image-substitute {
    width: 100%;
    display: block;
  }

  &__video-thumbnail-play-button-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__video-thumbnail-play-button {
    padding: 10px;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    z-index: z(forward);
    background-color: rgba(color(black), 0.5);

    &:hover {
      cursor: pointer;
    }

    .icon {
      line-height: 6vw;
      width: 100%;
      height: 100%;
      color: color(white);
    }
  }

  &__video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    @include teliaSansHeading;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    text-align: center;
    opacity: 0;

    &--visible {
      transition: opacity 3s ease-in;
      opacity: 1;
    }

    &--white {
      color: color(white);
    }

    &--black {
      color: color(black);
    }

    &--purple {
      color: color(corePurple);
    }

    &--small {
      font-size: 2vw;
      line-height: 2vw;
    }

    &--medium {
      font-size: 4vw;
      line-height: 4vw;
    }

    &--large {
      font-size: 6vw;
      line-height: 6vw;
    }

    &--slow {
      transition: opacity 4s ease-in;
    }

    &--average {
      transition: opacity 2s ease-in;
    }

    &--fast {
      transition: opacity 0.5s ease-in;
    }
  }
}
