.bulletin-item {
  overflow: hidden;

  @include from ('md') {
    padding: 10px 0 0 0;
  }  

  &__image-container {
    @include from ('md') {
      display: block;
      width: 100%;
      position: relative;
      height: 0;
      padding: 56.25% 0 0 0;
      overflow: hidden;
      margin-bottom: 0.5em;
    }
  }

  &__image {
    width: 100%;

    @include from ('md') {
      position: absolute;
      display: block;
      max-width: 100%;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }    

  &__type {
    text-transform: uppercase;
    font-size: 11px;
    font-weight: normal;
    color: color(grey50);
  }

  &__link-text {
    font-weight: bold;
    color: color(corePurple);
    font-size: 19px;
    line-height: 24px;
    margin-top: 5px;
    margin-bottom: 1.8em;
    display: block;

    &:hover {
      color: hover(corePurple);
      svg {
        fill: hover(corePurple);
      }
    }
  }  

  &__chevron {
    svg {
      fill: color(corePurple);
      opacity: 0.7;
      width: 18px;
      height: 19px;
      vertical-align: text-bottom;
      margin-left: -7px;
    }
  }  
}