$gutter: 2px;

// Timings
$hover-begins: 0s;
$hover-ends: 0.2s;
$growth-begins: 0.1s;
$growth-ends: 0.5s;
$button-appearance-begins: 0.3s;
$button-appearance-ends: 0.6s;
$restore-begins: 0s;
$restore-ends: 0.2s;

$hover-transition: #{$hover-ends - $hover-begins} ease $hover-begins;
$growth-transition: #{$growth-ends - $growth-begins} ease $growth-begins;
$button-appearance-transition: #{$button-appearance-ends - $button-appearance-begins} ease $button-appearance-begins;
$restore-transition: #{$restore-ends - $restore-begins} ease $restore-begins;

@mixin horizontal-spacing {
  margin-left: 0;
  margin-right: 0;
  @include to('xs') {
    padding-left: calc(50% + 6px);
  }
  @include from('sm') {
    text-align: center;
  }
}

@mixin vertical-spacing($margin-top, $line-height, $line-spacing, $font-size, $min-number-of-lines:0, $max-number-of-lines:0) {
  margin-top: $margin-top - $line-spacing;
  margin-bottom: 0;
  line-height: $line-height + $line-spacing;
  font-size: $font-size;
  @if ($min-number-of-lines > 0) {
    min-height: ($line-height + $line-spacing) * $min-number-of-lines;
  }
  @if ($max-number-of-lines > 0) {
    overflow: hidden;

    @include from('sm') {
      max-height: ($line-height + $line-spacing) * $max-number-of-lines;
    }
  }
  transform: translateY(#{$line-spacing * 0.5});
}

.product-card {
  padding-bottom: $gutter;
  box-sizing: border-box;
  position: relative;
  min-height: 200px;

  &:hover {
    z-index: z(forward);
  }

  /* Right border */
  &:before {
    content: " ";
    background-color: color(grey10);
    position: absolute;
    right: 0;
    width: $gutter;
    z-index: z(forward);
  }

  &:hover:before {
    display: none !important;
  }

  /* Bottom border */
  &:after {
    content: " ";
    background-color: color(grey10);
    position: absolute;
    left: 24px;
    right: 24px;
    bottom: 0;
    height: $gutter;
    z-index: z(moreForward);

    @include from('md') {
      display: none;
    }
  }

  [class*="grid__col-"]:nth-child(4n) > &:before {
    display: none;
  }

  [class*="grid__col-"]:last-child > &:before {
    display: none;
  }

  [class*="grid__col-"]:last-child > &:after {
    display: none;
  }

  @include between('xs', 'sm') {
    [class*="grid__col-"]:nth-last-child(2):not(:nth-child(2n)) > &:after {
      display: none;
    }

    [class*="grid__col-"]:nth-child(2n) > &:before {
      display: none;
    }
  }

  .carousel [class*="grid__col-"]:not(:last-child) &:before {
    display: block;
  }

  .carousel &:after {
    display: none;
  }

  &:hover &:after {
    display: none;
  }

  @include from('sm') {
    transform: translateX(1px);
    padding-right: $gutter;
    height: 100%;
  }

  @include to('xs') {
    width: 100%;
    &:before {
      display: none;
    }
  }

  @include between('sm', 'xlg') {
    min-height: 404px + $gutter;
    &:before {
      top: 24px;
      bottom: 26px;
    }
  }

  @include from('xlg') {
    min-height: 532px + $gutter;
    &:before {
      top: 32px;
      bottom: 40px;
    }
  }

  &--with-button {
    @include between('sm', 'xlg') {
      min-height: 480px + $gutter;
    }
    @include from('xlg') {
      min-height: 532px + $gutter
    }
  }

  &__container {
    width: calc(100% - 2px);
    position: relative;
    display: flex;
    flex-direction: column;
    transition: transform $restore-transition, height $restore-transition;
    border-radius: 4px;
    background-color: color(white);

    .product-card--lightGrey & {
      background-color: color(grey20);
    }

    .product-card--deepPurple & {
      background-color: color(deepPurple);
    }

    .product-card--deepBeige & {
      background-color: color(deepBeige);
    }

    .product-card--lightPurple & {
      background-color: color(lightPurple);
    }

    .product-card--lightBeige & {
      background-color: color(lightBeige);
    }

    @include from('sm') {
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    @include to('xs') {
      padding: 32px 0;
    }

    @include between('xs', 'sm') {
      padding: 32px 40px;
    }

    @include between('sm', 'xlg') {
      padding: 24px 24px 26px;
      .product-card--with-button & {
        padding: 24px 23px 32px;
      }
      border-radius: 2px;
    }

    @include from('xlg') {
      padding: 32px 24px 40px;
      border-radius: 2px;
    }

    &:hover {
      @include from('sm') {
        transform: scale(1.03);
        box-shadow: rgba(1, 1, 1, 0.2) 0 1px 4px;
        z-index: z(productCardHover);
      }
    }

    .product-card--button-on-hover &:hover {
      transition: transform $hover-transition, height $growth-transition;
      @include between('sm', 'xlg') {
        height: 404px + $gutter + 64px;
      }
      @include from('xlg') {
        height: 532px + $gutter + 64px;
      }
    }
  }

  &__brand {
    color: color(grey50);
    @include horizontal-spacing;

    @include to('md') {
      @include vertical-spacing(0, 10px, 6px, 14px, 0, 1);
    }

    @include between('md', 'xlg') {
      @include vertical-spacing(0, 10px, 6px, 14px, 1, 1);
    }

    @include from('xlg') {
      @include vertical-spacing(0, 12px, 6px, 16px, 1, 1);
    }
  }

  &__name {
    @include teliaSansHeading;
    letter-spacing: (-0.2px / 24px) * 1em;
    min-height: unset !important;
    word-wrap: break-word;
    @include horizontal-spacing;

    @include to('md') {
      @include vertical-spacing(14px, 14px, 6px, 20px, 1, 3);
      margin-bottom: 8px;
    }

    @include between('md', 'xlg') {
      @include vertical-spacing(16px, 14px, 6px, 20px, 2, 2);
      margin-bottom: 10px;
    }

    @include from('xlg') {
      @include vertical-spacing(24px, 18px, 4px, 24px, 2, 2);
      margin-bottom: 20px;
    }
  }

  &__name + &__image {
    @include from('xlg') {
      margin-top: 20px;
    }
  }

  &__overlay-link {
    position: absolute;
    display: block;
    overflow: hidden;
    left:0;
    top: 0;
    width: 100%;
    height: 100%;
    text-indent: -10000px;
    z-index: z(forward);
  }

  &__description-container {
    .product-card--app &, .product-card--pseudo & {
      max-height: 54px;
      height: 54px;
      margin-bottom: 6px;
      overflow: hidden;
    }
  }

  &__selling-points {
    @include horizontal-spacing;
    @include vertical-spacing(6px, 10px, 6px, 14px, 1, 1);
  }

  &__descriptor {
    @include horizontal-spacing;
    @include vertical-spacing(6px, 10px, 6px, 14px, 1, 1);
    color: color(grey50);

    p {
      font-size: 14px;
      margin: 0 auto;
      line-height: inherit;
    }

    .product-card--app &, .product-card--pseudo & {
      max-height: 54px;
      height: 54px;
      line-height: 18px;
    }

    .product-card--app & {
      color: color(black);
    }
  }

  &__image {
    margin: 0;

    @include to('xs') {
      position: absolute;
      top: 32px;
      left: 5vw;
      bottom: 32px;
      right: calc(50% + 6px);
      .product-card--with-button & {
        height: 136px;
        bottom: auto;
      }
    }

    @include from('sm') {
      margin-top: 16px;
      position: relative;
      flex-grow: 1;
    }

    .product-card--app &, .product-card--pseudo & {
      margin-top: 0;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      transform: translateX(-50%) translateY(-50%);

      .product-card--app &, .product-card--pseudo & {
        max-height: 104px;
      }
    }
  }

  &__tag {
    @include horizontal-spacing;
    margin-bottom: 0;
    @include to('sm') {
      margin-top: 20px;
    }

    @include from('sm') {
      height: 28px;
    }

    @include between('sm', 'xlg') {
      margin-top: 24px;
    }

    @include from('xlg') {
      margin-top: 32px;
    }

    .product-card--with-button & {
      margin-top: 12px;
    }
  }

  &__price {
    @include horizontal-spacing;

    @include to('md') {
      @include vertical-spacing(12px, 17px, 10px, 25px, 1);
    }

    @include between('md', 'xlg') {
      @include vertical-spacing(16px, 17px, 10px, 25px, 1);
    }

    @include from('xlg') {
      @include vertical-spacing(16px, 24px, 10px, 34px, 1);
    }
  }

  &__button {
    margin-top: 20px;
    height: 44px;
    text-align: center;
    transition: height $restore-transition, margin $restore-transition, opacity 0s;

    @include from('sm') {
      margin-left: -24px;
      margin-right: -24px;
    }

    .button {
      margin: 0;
      z-index: z(moreForward);
    }

    .product-card--button-on-hover & {
      margin-top: 0;
      height: 0;
      overflow: hidden;
      opacity: 0;
      order: 100;
    }

    @include from('sm') {
      .product-card--button-on-hover .product-card__container:hover & {
        margin-top: 8px;
        margin-bottom: 12px;
        height: 44px;
        opacity: 1;
        overflow: visible;
        transition: height $growth-transition, margin $growth-transition, opacity $button-appearance-transition;
      }
    }
  }

  &__store-icons {
    display: none;
  }

  &__store-icons &__button:not(:first-child) {
    margin-top: 5px;
  }

  &__smallprint {
    color: color(grey50);
    @include horizontal-spacing;

    @include to('sm') {
      @include vertical-spacing(12px, 9px, 9px, 12px, 0, 2);

      .product-card--with-button & {
        padding-left: 0;
        text-align: center;
      }
    }

    @include between('sm', 'xlg') {
      @include vertical-spacing(16px, 9px, 9px, 12px, 2, 2);
      .product-card--with-button & {
        @include vertical-spacing(22px, 9px, 9px, 12px, 2, 2);
      }
    }

    @include from('xlg') {
      @include vertical-spacing(20px, 12px, 8px, 12px, 2, 2);
      .product-card--with-button & {
        @include vertical-spacing(22px, 9px, 9px, 12px, 2, 2);
      }
    }
  }

  &--small {
    margin: 0 auto;
    padding-right: 0;

    .product-card__image {
      position: relative;
      flex-grow: 0;
      height: 136px;

      @include to('xs') {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      @include from('sm') {
        margin-top: 16px;
      }

      img:first-child {
        flex-grow: 0;
        position: static;
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 138px;
        transform: none;
      }
    }

    .product-card__brand {
      padding: 0;

      @include to('md') {
        @include vertical-spacing(20px, 20px, 0, 14px, 1, 0);
      }

      @include between('md', 'xlg') {
        @include vertical-spacing(20px, 20px, 0, 14px, 1, 0);
      }

      @include from('xlg') {
        @include vertical-spacing(20px, 22px, 0, 14px, 1, 0);
      }
    }

    .product-card__name {
      @include teliaSans;
      font-weight: bold;
      text-transform: none;
      margin-bottom: 20px;
      padding: 0;
      text-align: center;

      @include to('md') {
        @include vertical-spacing(5px, 16px, 6px, 16px, 1, 0);
      }

      @include between('md', 'xlg') {
        @include vertical-spacing(5px, 16px, 6px, 16px, 2, 0);
      }

      @include from('xlg') {
        @include vertical-spacing(5px, 16px, 6px, 16px, 2, 0);
      }
    }

    @include from('sm') {
      transform: translateX(1px);
      padding-right: 0;
      height: 100%;
      min-height: 246px;
      &:before {
        top: 24px;
        bottom: 26px;
      }
    }

    @include from('xlg') {
      min-height: 294px;
      &:before {
        top: 32px;
        bottom: 40px;
      }
    }

    .product-card__container {
      align-items: center;
      position: relative;
      height: 100%;
      padding: 30px;

      @include from('sm') {
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }

      @include to('xs') {
        padding: 30px 30px;
      }

      @include between('xs', 'sm') {
        padding: 30px 30px;
      }

      @include between('sm', 'xlg') {
        padding: 3px 3px;
        border-radius: 2px;
      }

      @include from('xlg') {
        padding: 30px 30px;
        border-radius: 2px;
      }
    }
  }

  .splash {
    position: absolute;
    right: -30px;
    top: 22px;
  }

  &__splash-container {
    margin: 0;
    height: 100%;
    left: 50%;
    max-width: 100%;
    position: absolute;
    transform: translateX(-50%);
    width: 136px;
    top: 0;

    @include from('xlg') {
      width: 198px;
    }
  }

  &__splash {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 64px;
    height: 64px;
    right: 0;
    background-color: color(black);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;

    @include from('sm') {
      width: 80px;
      height: 80px;
      right: -20px;
    }

    &--orange {
      background-color: color(orange);
    }
  }

  &__splash-text {
    @include teliaSansHeading;
    text-align: center;
    color: color(white);
    padding: 10px;
    font-size: 12px;
    line-height: 13px;

    @include from('sm') {
      line-height: 15px;
      font-size: 14px;
    }
  }
}
