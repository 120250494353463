.product-selector {
  @include teliaSans;

  &__container {
    @include to('xs') {
      padding: 0px;
    }
  }

  &__loader-container {
    margin: 120px 0;
    text-align: center;
  }

  &__box-radio {
    &-heading {
      @include teliaSansHeading;
      font-size: 24px;
    }

    &-description {
      font-size: 12.5px;
    }

    &-input {
      display: none;
    }

    &-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-right: -2%;
      margin-bottom: -10px;

      @include from('xlg') {
        margin-right: -1%;
      }
    }

    &-label {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      user-select: none;
      cursor: pointer;
      height: 85px;
      background-color: color(white);
      border-radius: 3px;
      width: 48%;
      margin-bottom: 10px;
      margin-right: 2%;
      color: color(grey50);
      transition: color 0.2s;

      @include from('xlg') {
        width: 24%;
        margin-right: 1%;
      }

      &:hover {
        color: color(corePurple);
      }
    }

    &-input:checked + &-label {
      color: color(corePurple);
      border: solid color(corePurple) 4px;
    }
  }

  &__list--no-format {
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
  }

  &__header-bar-heading {
    display: inline;
  }

  &__header-bar {
    vertical-align: middle;
    width: 100%;

    &--margin {
      &-large {
        text-align: center;
        margin-top: 45px;
        margin-bottom: 15px;

        @include from('sm') {
          text-align: left;
        }
      }

      &-bottom-only {
        margin-bottom: 16px;
        margin-top: 0px;
      }
    }

    .button {
      display: none;

      @include from('sm') {
        display: block;
        float: right;
      }
    }
  }

  &__category-container {
    background: color(grey10);
    padding: 24px;

    &--dark-grey {
      background: color(grey20);
    }

    &--border-bottom {
      border-bottom: 1px solid color(grey40);
    }

    &--centered {
      text-align: center;
    }

    &--margin-bottom {
      margin-bottom: 8px;
    }

    &--padding-right {
      @include from('lg') {
        padding-right: 4px;
      }
    }

    &--padding-left {
      @include from('lg') {
        padding-left: 4px;
      }
    }
  }

  &__classification-description {
    margin-left: 37px;
    font-size: 14px;
    line-height: 1.3;
    color: color(grey70);
  }

  &__summary-cta-detail {
    &--custom-product {
      p {
        font-size: 18px;
        line-height: 1.5;
        margin-bottom: 36px;
      }
    }
  }

  &__summary-image-container {
    text-align: center;
  }

  &__summary-image {
    width: 300px;
    max-width: 100%;
    max-height: 300px;
    margin-bottom: 15px;
    object-fit: scale-down;
  }

  &__summary-description {
    &-container {
      min-height: 245px;
    }

    &-heading,
    &-additional-services-heading {
      color: color(grey70);
    }

    &-body,
    &-additional-services {
      font-size: 10px;
      color: color(grey70);

      p,
      li,
      ul,
      ol {
        font-size: 14px;
        line-height: 1.3;
      }

      ul > li > ul {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  &__summary-heading {
    margin: 0px;

    &--small {
      margin: 0px;
      font-size: 18px;
    }
  }

  &__summary-cta-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    height: 170px;
  }

  &__summary-cta-container + .button {
    margin-top: 30px;
    line-height: 3;
  }

  &__summary-change-selections {
    text-align: center;

    .button {
      margin: 0;
    }
  }

  &__form-container {
    margin-bottom: 10px;
    max-width: 600px;

    &-padder {
      padding: 0 6px;

      @include from('sm') {
        padding: 0px;
      }
    }

    .button {
      margin-top: 46px;
      margin-bottom: 26px;
    }
  }

  &__form-textarea {
    margin-bottom: 35px;
  }

  &__form-disclaimer-checkbox + &__form-disclaimer-label p {
    margin-bottom: 0px;
    margin-top: 2px;
  }

  &__total-one-time-cost {
    @include teliaSansHeading;
    font-size: 46px;
    margin-bottom: 5px;
  }

  &__total-monthly-cost {
    @include teliaSansHeading;
    font-size: 24px;

    > .price__stacked-unit {
      @include teliaSans;
      font-weight: normal;
    }
  }

  &__info-icon {
    & > svg {
      pointer-events: auto;
    }

    @supports (-ms-ime-align: auto) {
      & > svg {
        pointer-events: none; // Fix Edge bug where svg absorbs click events
      }
    }

    font-size: 18px;
    color: color(blue);
    cursor: pointer;

    @include from('sm') {
      position: relative;
    }

    &--margin-left {
      margin-left: 8px;
    }
  }

  &__speech-bubble {
    font-weight: normal;
    display: none;
    padding: 4px 18px;
    position: fixed;
    top: 50%;
    width: 90%;
    color: color(black);
    background-color: color(white);
    box-sizing: border-box;
    transform: translateY(-50%);
    border-radius: 8px;
    user-select: none;
    cursor: default;
    z-index: z(modalBox);
    box-shadow: 3px 3px 6px 0px rgba(color(black), 0.3);
    -webkit-text-size-adjust: 100%; // prevents iphone from magically adjusting font size for whatever reason

    &--open {
      display: block;
    }

    p {
      font-size: 12px;
    }

    @include from('sm') {
      padding: 0px 12px;
      transform: translate(11px, -50%);
      width: 250px;
      position: absolute;
      border: 3px solid color(blue);
      border-radius: 3px;
      z-index: z(tooltip);

      &--open {
        display: inline;
      }

      :after {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        border-right-color: color(blue);
        border-left: 0;
        margin-top: -10px;
        margin-left: -11px;
      }
    }
  }

  &__speech-bubble-close-button {
    float: right;
    color: white;
    font-size: 24px;
    line-height: 1px;
    margin-top: -20px;
    background-color: black;
    padding: 4px;
    border-radius: 40px;
    cursor: pointer;

    @include from('sm') {
      display: none;
    }
  }

  &__smoke-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(color(black), 0.5);
    z-index: z(modalOverlay);

    @include to('xs') {
      &--visible {
        display: block;
      }
    }
  }

  & &__additional-services-option {
    // Additional '&' is required to increase specificity over default checkbox label styles
    display: block;
  }

  & &__internet-toggle-label {
    // Additional '&' is required to increase specificity over default checkbox label styles
    margin: 0;
  }

  &__internet--excluded &__internet-toggled-content {
    display: none;
  }

  &__internet--excluded &__header-bar {
    margin: 0;
  }
}
