.disturbance-notifications {

  &__description {
    margin: 44px 0 44px 0;
    font-size: 20px;
  }

  .disturbance-notification {
    padding: 1em 32px 1em 16px;
    display: block;
    margin: 8px 0;
    border: 1px solid color(grey20);
    color: color(grey70);
    font-weight: bold;
    position: relative;
    text-align: left;

    &:hover {
      color: color(corePurple);
    }

    &__icon {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      right: 16px;
      font-size: 12px;
    }
  }
}
