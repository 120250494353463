.price-list {
  margin: 2em 0;
  border-top: 1px solid color(grey30);

  &__single {
    display: none;

    &--visible {
      display: block !important;

      .price-list__content {
        max-height: none !important;
      }
    }
  }

  &__content {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    transition: padding-top 0.4s, padding-bottom 0.4s;
    overflow: hidden;

    &-table {
      width: 100%;
      border-collapse: collapse;
    }

    &-row {
      &--data-header {
        font-weight: 700;
      }

      &--small {
        font-size: 14px;
      }
    }

    &-key {
      width: 60%;
    }

    &-value {
      width: 40%;
    }
  }

  &__extra {
    padding: 0 1.125em;
    margin: 1em 0;
  }

  &__countries {
    padding: 0 1.125em;
    margin: 1em 0;

    p {
      font-size: 10px;
      line-height: 15px;
      padding: 0;
    }
  }

  &__container {
    display: block;
    position: relative;

    &--hidden {
      display: none !important;
    }
  }

  &__item--open .price-list__content {
    padding-top: 1em;
    padding-bottom: 1em;
    max-height: none;
    border-bottom: 1px solid color(grey30);
  }

  &__item {
    float: none;
    background: none;
  }

  &__heading {
    display: block;
    font-weight: 500;
    width: 100%;
    border-bottom: 1px solid color(grey30);
    font-size: 18px;
    padding: 1em 2em 1em 0;
    cursor: pointer;
    margin: 0;
    user-select: none;
    position: relative;
  }

  &__navbar {
    display: none;
  }

  &__icon {
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    top: 50%;
    right: 16px;
    margin-top: -10px;
    transition: transform 0.2s;

    &--rotate{
      transform: rotate(180deg);
    }

    &::before {
      font-size: 15px;
      color: color(corePurple);
    }
  }
}
