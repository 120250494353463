.ppr-autocomplete {
  &__list {
    width: 100%;
    background-color: color(white);
    padding: 0;
    transition: max-height 0.2s;
    overflow: hidden;
    position: relative;
    color: color(black);
    max-height: 0;
    z-index: z(dropdown);
    display: none;
    border: 1px solid;
    border-color: rgba(0, 0, 0, 0.15);
    border-radius: 0 0 8px 8px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);

    li:hover {
      color: color(corePurple);
    }

    &--open {
      display: block;
      max-height: 100%;
      overflow: auto;
    }
  }

  &__item {
    padding: 0.4em 1em;
    cursor: pointer;

    &:nth-child(1) {
      padding-top: 1em;
    }

    &:nth-last-child(1) {
      padding-bottom: 0.8em;
    }

    .match {
      font-weight: bold;
    }

    &--active, &:hover {
      color: color(corePurple);
    }
  }
}
