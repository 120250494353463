.helppi-confirmation-form {
  display: none;
  height: 100%;
  overflow: hidden;
  width: 100%;

  &__title {
    @include teliaSansHeading;
    font-size: 24px;
    margin-left: 19px;
    margin-top: 17px;
  }

  &__text {
    font-size: 16px;
    margin-bottom: 44px;
    margin-left: 19px;
    margin-top: 44px;

    @include from ('xs') {
      font-size: 20px;
    }
  }

  &__success {
    display: none;
    height: 100%;

    &.active {
      display: block;
    }
  }

  &__buttons-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 10%;
    padding-top: 30px;
    text-align: center;
    width: 100%;
  }

  &__button-primary {
    font-size: 14px;
    font-weight: bold;
    width: 189px;

    span {
      margin: 0 auto;
    }
  }
}
