.sub-nav {
  background: white;

  border-top: 1px solid color(grey30);
  box-shadow: 0 1px 0 0 rgba(color(black), 0.11);

  .body--subnavi-border & {
    border-bottom: 2px solid #f2f2f2;
  }

  &__container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  &__list {
    overflow-x: hidden;
    overflow-y: hidden;
    white-space: nowrap;
    scroll-behavior: smooth;
    overflow-scrolling: touch;
    display: flex;

    @include from('md') {
      overflow: hidden;
    }
  }

  &__item {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;

    @include from('md') {
      padding-left: 14px;
      padding-right: 14px;
    }

    /* this is the first actual sub menu item */
    &:nth-child(2) {
      padding-left: 0;
    }

    a:link,
    a:visited {
      color: color(grey100);
    }

    a:hover {
      color: color(corePurple);
    }
  }

  &__link {
    display: inline-block;
    padding-top: 16px;
    padding-bottom: 15px;
    margin: 0;
    line-height: 1em;
    font-size: 15px;

    &--active {
      padding-bottom: 13px;
      border-bottom: 2px solid color(corePurple);

      &:hover {
        color: color(corePurple);
      }
    }

    @at-root {
      .sub-nav__item:nth-child(2n) .subnav__link {
        margin-left: 0;
      }
    }
  }

  &__action {
    height: 100%;
    position: absolute;
    transition: left 0.2s ease-in, right 0.2s ease-in;
    display: none;
    padding-top: 10px;

    &--right {
      right: -30px;

      @at-root {
        .sub-nav__list--scrollRight .sub-nav__action--right {
          right: 0;
          display: inline-block;
        }
      }
    }

    &--left {
      left: -30px;

      @at-root {
        .sub-nav__list--scrollLeft .sub-nav__action--left {
          left: 0;
          display: inline-block;
        }
      }
    }
  }

  &__icon {
    position: absolute;
    color: color(corePurple);
    width: 30px;
    height: 30px;
    background-color: #fff;
    text-align: center;
    cursor: pointer;

    &--left {
      left: 0;
      box-shadow: 10px 0 10px color(white);
    }

    &--right {
      right: 0;
      box-shadow: -10px 0 10px color(white);
    }

    .icon {
      font-size: 20px;
      vertical-align: middle;
    }
  }
}
