$heroHeight: 480px;
$heroHeightLarge: 392px;
$heroHeightMedium: 360px;
$heroHeightSmall: 344px;

$breakoutSize: 48px;
$breakoutSizeSmall: 0px;

$totalSize: $heroHeight + $breakoutSize;
$totalSizeLarge: $heroHeightLarge + $breakoutSize;
$totalSizeMedium: $heroHeightMedium + $breakoutSize;
$totalSizeSmall: $heroHeightSmall + $breakoutSizeSmall;

.image-hero {
  position: relative;

  @include from('sm') {
    height: $heroHeightSmall;
  }

  @include from('md') {
    height: $heroHeightMedium;
  }

  @include from('lg') {
    height: $heroHeightLarge;
  }

  @include from('xlg') {
    height: $heroHeight;
  }

  &--image-breakout {
    @include from('sm') {
      padding-bottom: $breakoutSizeSmall;
      height: $totalSizeSmall;
    }

    @include from('md') {
      margin-bottom: -$breakoutSize;
      padding-bottom: $breakoutSize;
      height: $totalSizeMedium;
    }

    @include from('lg') {
      padding-bottom: $breakoutSize;
      height: $totalSizeLarge;
    }

    @include from('xlg') {
      padding-bottom: $breakoutSize;
      height: $totalSize;
    }
  }

  &--large {
    @include from('md') {
      min-height: $heroHeightMedium;
      height: 80vh;
      max-height: $heroHeightMedium * 1.3;
    }

    @include from('lg') {
      min-height: $heroHeightLarge;
      height: 80vh;
      max-height: $heroHeightLarge * 1.3;
    }

    @include from('xlg') {
      min-height: $heroHeight;
      height: 80vh;
      max-height: $heroHeight * 1.3;
    }

  }

  /* base hero text color */
  /* desktop text colors */
  &--color-white {
    @include from('sm') {
      color: color(white);
    }
  }

  &--color-black {
    @include from('sm') {
      color: color(offBlack);
    }
  }

  &--color-purple {
    @include from('sm') {
      color: color(deepPurple);
    }
  }

  &--color-deepPurple {
    @include from('sm') {
      color: color(deepPurple);
    }
  }

  &--color-deepBeige {
    @include from('sm') {
      color: color(deepBeige);
    }
  }

  &--color-beige {
    @include from('sm') {
      color: color(beige);
    }
  }

  &--color-lightPurple {
    @include from('sm') {
      color: color(lightPurple);
    }
  }

  &--color-lightBeige {
    @include from('sm') {
      color: color(lightBeige);
    }
  }

  // text color for the mobile view
  &-mobile {
    @include to('xs') {
      &--color-white {
        color: color(white);
      }

      &--color-black {
        color: color(offBlack);
      }

      &--color-purple {
        color: color(deepPurple);
      }

      &--color-deepPurple {
        color: color(deepPurple);
      }

      &--color-deepBeige {
        color: color(deepBeige);
      }

      &--color-beige {
        color: color(beige);
      }

      &--color-lightPurple {
        color: color(lightPurple);
      }

      &--color-lightBeige {
        color: color(lightBeige);
      }
    }
  }

  &__fade-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include from('sm') {
      &--black {
        background: linear-gradient(90deg, rgba(0,0,0,0.4) 30%, rgba(0,0,0,0) 70%);
      }

      &--white {
        background: linear-gradient(90deg, rgba(255,255,255,0.4) 30%, rgba(0,0,0,0) 70%);
      }
    }
  }

  /* text part colors when in desktop size */
  &__heading,
  &__subheading,
  &__description {
    /* normally inherit base hero text color */
    @include from('sm') {
      color: inherit;
    }

    /* custom colors spesified */
    &--color-white {
      @include from('sm') {
        color: color(white);
      }
    }

    &--color-black {
      @include from('sm') {
        color: color(offBlack);
      }
    }

    &--color-purple {
      @include from('sm') {
        color: color(deepPurple);
      }
    }

    &--color-deepPurple {
      @include from('sm') {
        color: color(deepPurple);
      }
    }

    &--color-lightPurple {
      @include from('sm') {
        color: color(lightPurple);
      }
    }

    &--color-deepBeige {
      @include from('sm') {
        color: color(deepBeige);
      }
    }

    &--color-beige {
      @include from('sm') {
        color: color(beige);
      }
    }

    &--color-lightBeige {
      @include from('sm') {
        color: color(lightBeige);
      }
    }
  }

  /* text part colors when in mobile view */
  &__mobile-heading,
  &__mobile-subheading,
  &__mobile-description {
    /* normally inherit base hero text color */
    @include to('xs') {
      color: inherit;
    }

    /* custom colors spesified */
    &--color-white {
      @include to('xs') {
        color: color(white);
      }
    }

    &--color-black {
      @include to('xs') {
        color: color(offBlack);
      }
    }

    &--color-purple {
      @include to('xs') {
        color: color(deepPurple);
      }
    }

    &--color-deepPurple {
      @include to('xs') {
        color: color(deepPurple);
      }
    }

    &--color-lightPurple {
      @include to('xs') {
        color: color(lightPurple);
      }
    }

    &--color-deepBeige {
      @include to('xs') {
        color: color(deepBeige);
      }
    }

    &--color-beige {
      @include to('xs') {
        color: color(beige);
      }
    }

    &--color-lightBeige {
      @include to('xs') {
        color: color(lightBeige);
      }
    }

  }

  &__heading {
    margin: 25px 0 18px;

    @include to('xs') {
      margin-bottom: 10px;
    }

    @include from('sm') {
      margin-top: 0;
    }

    @include from('xxlg') {
      margin-bottom: 33px;
    }
  }

  &__subheading {
    margin: 0 0 8px;
  }

  &__description {
    max-width: none;
    margin: 0;
  }

  &__basic-components {
    margin: 12px 0 0;

    @include from('md') {
      margin-top: 16px;
    }

    @include from('xxlg') {
      margin-top: 26px;
    }

    .button {
      margin-left: 0;
    }

    .price-component {
      margin-top: 10px;
      margin-bottom: 20px;
    }

    &--no-margin {
      margin-top: 0px;
    }
  }

  &__container {
    height: 100%;
    padding: 0 0 32px;
    position: relative;

    @include from('sm') {
      padding: 0;
    }

    &--background-lightGrey {
      background-color: color(grey20);
    }

    &--background-purple {
      background-color: color(corePurple);
    }

    &--background-black {
      background-color: color(grey200);
    }

    &--background-white {
      background-color: color(white);
    }

    &--background-transparent {
      background-color: transparent;
    }

    &--background-deepBeige {
      background-color: color(deepBeige);
    }

    &--background-beige {
      background-color: color(beige);
    }

    &--background-deepPurple {
      background-color: color(deepPurple);
    }

    &--background-lightPurple {
      background-color: color(lightPurple);
    }

    &--background-lightBeige {
      background-color: color(lightBeige);
    }

    @include to('xs') {
      // On mobile size the background must always be light grey by default unless overridden
      background-color: color(grey20);

      &--mobile-background-lightGrey {
        background-color: color(grey20);
      }

      &--mobile-background-purple {
        background-color: color(corePurple);
      }

      &--mobile-background-black {
        background-color: color(grey200);
      }

      &--mobile-background-white {
        background-color: color(white);
      }

      &--mobile-background-transparent {
        background-color: transparent;
      }

      &--mobile-background-deepBeige {
        background-color: color(deepBeige);
      }

      &--mobile-background-beige {
        background-color: color(beige);
      }

      &--mobile-background-deepPurple {
        background-color: color(deepPurple);
      }

      &--mobile-background-lightPurple {
        background-color: color(lightPurple);
      }

      &--mobile-background-lightBeige {
        background-color: color(lightBeige);
      }

    }
  }

  &__background-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;

    @include to('xs') {
      height: 224px;
    }
  }

  &__background {
    height: 100%;
    background-size: cover;

    &--clipping-top-left {
      background-position: 100% 100%;
      transform-origin: 100% 100%;
    }

    &--clipping-top-center {
      background-position: 50% 100%;
      transform-origin: 50% 100%;
    }

    &--clipping-top-right {
      background-position: 0 100%;
      transform-origin: 0 100%;
    }

    &--clipping-middle-right {
      background-position: 0 50%;
      transform-origin: 0 50%;
    }

    &--clipping-bottom-right {
      background-position: 0 0;
      transform-origin: 0 0;
    }

    &--clipping-bottom-center {
      background-position: 50% 0;
      transform-origin: 50% 0;
    }

    &--clipping-bottom-left {
      background-position: 100% 0;
      transform-origin: 100% 0;
    }

    &--clipping-middle-left {
      background-position: 100% 50%;
      transform-origin: 100% 50%;
    }

    &--clipping-center {
      background-position: 50% 50%;
      transform-origin: 50% 50%;
    }

    &--clipping-stretch {
      background-size: 100% 100%;
      transform-origin: 50% 50%;
    }

    @include to('xs') {
      height: 224px;
      border-bottom: none;
    }

    &--mobile {
      background-position: 50% 50%;

      @include from('sm') {
        display: none;
        background: none !important; // Don't download image on wrong breakpoint
      }
    }

    &--desktop {
      @include to('xs') {
        display: none;
        background: none !important; // Don't download image on wrong breakpoint
      }
    }

    .image-hero--has-motion & {
      transition: opacity 0.5s, transform 2.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
      opacity: 1;
    }

    .image-hero--motion & {
      transform: scale(1.03);
      opacity: 1;
    }
  }

  &__overlay-image {
    height: 224px;

    @include from('sm') {
      height: auto;
      max-height: 100%;
    }

    .splash {
      position: absolute;
      margin: 0;
      right: 8%;
      top: 20px;
      z-index: z(forward);

      @include from('sm') {
        right: 0;
        top: 48px;
      }
    }
  }

  &__grid {
    height: 100%;

    @include to('xs') {
      flex-direction: column-reverse;
      align-items: center;
    }

    .image-hero--has-motion & {
      transition: opacity 2s;
      opacity: 1;
    }

    .image-hero--motion & {
      opacity: 1;
    }
  }

  &__texts {
    align-self: center;
  }

  .image-element {
    @at-root {
      .image-hero--image-breakout .image-element {
        @include from('sm') {
          height: calc(100% + #{$breakoutSizeSmall});
          margin-bottom: -$breakoutSizeSmall;
        }

        @include from('md') {
          height: calc(100% + #{$breakoutSize});
          margin-bottom: -$breakoutSize;
        }
      }
    }

    &__picture {
      width: auto;
      z-index: z(forward);
      pointer-events: none;

      @include at('sm') {
        margin-bottom: 40px;
      }

      @include from('sm') {
        position: relative;
        display: inline-block;
      }
    }

    &__image {
      max-height: 200px;
      max-width: 100%;
      width: auto;
      height: auto;

      @include from('sm') {
        max-height: $heroHeightSmall - 32px;
      }

      @include from('md') {
        max-height: $heroHeightMedium - $breakoutSize;
      }

      @include from('lg') {
        max-height: $heroHeightLarge - $breakoutSize;
      }

      @include from('xlg') {
        max-height: $heroHeight - $breakoutSize;
      }

      @at-root {
        .image-hero--image-breakout .image-element__image {
          @include from('md') {
            max-height: $totalSizeMedium;
          }

          @include from('lg') {
            max-height: $totalSizeLarge;
          }

          @include from('xlg') {
            max-height: $totalSize;
          }
        }
      }
    }
  }

  .media-container {
    @at-root {
      .image-hero--image-breakout .media-container {
        @include from('sm') {
          height: calc(100% + #{$breakoutSizeSmall});
          margin-bottom: -$breakoutSizeSmall;
        }

        @include from('md') {
          height: calc(100% + #{$breakoutSize});
          margin-bottom: -$breakoutSize;
        }
      }
    }

    &__image {
      max-height: 200px;
      max-width: 100% !important; //Media container uses the image macro that inlines an absolute max-width -value. This overrides that.
      width: auto;
      height: auto;

      @include from('sm') {
        max-height: $heroHeightSmall - 32px;
      }

      @include from('md') {
        max-height: $heroHeightMedium - $breakoutSize;
      }

      @include from('lg') {
        max-height: $heroHeightLarge - $breakoutSize;
      }

      @include from('xlg') {
        max-height: $heroHeight - $breakoutSize;
      }

      @at-root {
        .image-hero--image-breakout .media-container__image {
          @include from('md') {
            max-height: $totalSizeMedium;
          }

          @include from('lg') {
            max-height: $totalSizeLarge;
          }

          @include from('xlg') {
            max-height: $totalSize;
          }
        }
      }
    }
  }

  .cms-huippudiili-number-offer__form-container-dark {
    @include from('sm') {
      & .text-input__field {
        border-color: color(white);
        color: color(white);
        &.has-value {
          &:focus, &.focus {
            & ~ .text-input__label {
              color: color(white);
            }
          }
        }
        &:focus, &.focus {
          & ~ .text-input__label {
            color: color(white);
          }
        }
        & ~ .text-input__label {
          color: rgba(255, 255, 255, 0.66);
        }
      }
      & .cms-text-input__error-text {
        color: color(white);
      }
      & .icon--error-filled {
        color: color(red);
      }
    }
  }

}
