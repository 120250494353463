.helppi-area-title {
  color: color(corePurple);
}

.helppi-category-link {
  @include to('sm') {
    margin: 0 auto;
  }
}

.helppi-calendar-component {
  background-color: color(grey20);
  cursor: pointer;
  height: 288px;
  margin-bottom: 24px;
  margin-right: 24px;
  padding-left: 16px;
  padding-right: 15px;
  width: 288px;

  &-image {
    width: 100%;

    svg {
      fill: color(black);
      height: 48px;
      margin-bottom: 28px;
      margin-left: 108px;
      margin-top: 35px;
      width: 48px;
    }
  }

  &-title {
    @include teliaSansHeading;
    color: color(black);
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 10px;
    text-align: center;
  }

  &-subtitle {
    color: color(grey70);
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 29px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
  }
}

.helppi-overlay-container {
  @include teliaSans;
  background: rgba(color(black), .8);
  background-size: cover;
  bottom: 0;
  display: none;
  left: 0;
  letter-spacing: normal;
  opacity: 0;
  overflow: auto;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 400ms ease-in;
  word-spacing: normal;
  z-index: z(helppiModal);

  &:target {
    opacity: 1;
    pointer-events: auto;
  }

  .helppi-modal {
    background: color(grey10);
    border-radius: 8px;
    height: 789px;
    margin: 10% auto;
    max-width: 840px;
    padding: 17px 15px 20px;
    position: relative;
    width: 100%;

    @include at ('sm') {
      width: 90%;
    }

    @include at ('md') {
      width: 80%;
    }

    @include from ('lg') {
      width: 840px;
    }

    &-close {
      background-color: color(black);
      border-radius: 50%;
      color: color(white);
      cursor: pointer;
      display: block;
      font-size: 40px;
      height: 40px;
      margin-left: 83.75%;
      margin-top: -40px;
      width: 40px;

      @include from ('md') {
        display: none;
      }
    }

    .callback-calendar__time-selector {
      margin-bottom: 50px;
      margin-left: 10%;
      width: 80%;
      z-index: inherit;

      @include from ('xs') {
        margin-left: 25%;
        width: 50%;
      }
    }
  }
}

@import 'helppi-service-type-header';
@import 'helppi-service-date-header';
@import 'helppi-service-info';
@import 'helppi-date-selection';
@import 'helppi-multi-service-selection';
@import 'helppi-service-form';
@import 'helppi-confirmation-form';
