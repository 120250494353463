.helppi-service-type-header {
  background: color(blue);
  border-radius: 8px;
  color: color(white);
  height: 96px;
  letter-spacing: normal;
  margin-bottom: 6px;
  padding: 10px;
  width: 100%;
  word-spacing: normal;

  @include from ('xs') {
    padding: 24px;
  }

  &__info {
    @include teliaSans;
    float: left;
    font-size: 14px;
    padding: 12px 5px;
    width: 75%;

    @include from ('xs') {
      font-size: 20px;
      padding: 0;
      width: 80%;
    }

    &-subtitle {
      font-weight: bold;
    }

    &-logo {
      display: none;
      float: left;
      font-size: 48px;
      height: 48px;
      margin-right: 24px;
      width: 48px;

      @include from ('xs') {
        display: block;
      }
    }
  }

  &__change {
    cursor: pointer;
    float: right;
    font-size: 12px;
    padding-left: 0;
    padding-top: 25px;

    @include from ('xs') {
      padding-left: 10px;
      padding-top: 10px;
    }
  }
}
