.cms-store-page {
  padding-bottom: 24px;

  &__loader {
    display: flex;
    justify-content: center;
    padding: 48px;
  }

  @include from('md') {
    padding-bottom: 0;
  }

  &__container {
    @include from('md') {
      column-count: 2;
      column-gap: 24px;
    }
  }

  &__card-wrapper {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    margin-bottom: -24px !important;
    page-break-inside: avoid; /** for firefox */
    break-inside: avoid-column; /** for all other browsers */
  }

  &__card-content {
    /** To reset user agent styles for html that may come from the data */
    /** TODO: the p margin should probably be removed so paragraphs from the rich text editor are visible */
    p {
      margin: 0;
      max-width: none;
    }

    .button {
      margin-left: 0;
      margin-top: 16px;
    }
  }

  &__main-card-image {
    /* Hack to make the image 16:9 */
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    /* --- */

    background-repeat: no-repeat;
    background-size: cover;
    /* 35% to take into account pictures with a person whose head is on the top part of the image */
    background-position: 50% 35%;
  }

}
