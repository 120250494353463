@import '../../../config.scss';
.appstore {
  bottom: 0;
  left: 0;
  text-align: center;
  width: 100%;
  position: absolute;

  &__container {
    @include from('md') {
      height: 72px;
      display: inline-block;
      background-color: #000000;
    }

    a {
      height: 60px;
      background-color: #000000;
      width: 150px;
      position: relative;
      display: block;
      margin-bottom: 2px;
      float: left;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background-color: #333333;
      }

      @include from('sm') {
        width: 175px;
      }

      @include from('md') {
        text-align: center;
        width: 250px;
        float: left;
        height: 100%;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        max-width: 128px;
      }
    }
  }
}
