.contact-info {
  text-align: center;

  &__wrapper {
    padding: 10px !important;
  }

  &__content {
    background-color: color(grey20);
    padding: 36px 8px 0 8px;
    border-radius: 4px;
  }

  &__bottom-content {
    margin-top: auto;
  }

  &__icon {
    font-size: 36px;
  }

  &__heading {
    font-size: 20px;
    margin: 16px auto 22px;
  }

  &__description {
    color: color(grey70);
    margin-bottom: 20px;
  }

  &__phone {
    @include teliaSansHeading;
    color: color(deepPurple);
    font-size: 24px;
    line-height: 24px;
  }

  &__link {
    font-weight: bold;
    line-height: 22px;
  }

  &__link-icon {
    color: color(corePurple);
    font-size: 10px;
  }

  &__bottom-text {
    margin-top: 8px;
    color: color(grey50);
    min-height: 54px;
  }
}

.contact-info-list {
  margin: 48px auto 16px;
}
