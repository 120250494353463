ul {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 20px;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 20px;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

ol {
  display: block;
  list-style-type: decimal;
  -webkit-margin-before: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 20px;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 20px;
}

ul ul, ol ul {
  list-style-type: circle;
}

ol ul, ul ol, ul ul, ol ol {
  -webkit-margin-before: 0px;
  -webkit-margin-after: 0px;
}

* {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  background: transparent;
}

p {
  margin: 1em 0;
}

input:-webkit-autofill,
.input:-webkit-autofill,
#identifier:-webkit-autofill,
#password:-webkit-autofill {
  background-color: #FFF9EC !important;
}

table {
  width: 100%;
  table-layout: fixed;
  color: color(grey100);
  border-width: 0;
  border-spacing: 0;

  tr {
    &:nth-child(odd) {
      background-color: color(grey10);
    }
  }

  td {
    border-width: 0;
    padding: 0.75em 1.125em;
  }

  thead {
    border-bottom: 1px solid color(grey30);

    tr {
      background-color: color(white) !important;
    }

    th {
      border-width: 0;
      padding: 0.75em 1.125em;
      text-align: left;
    }
  }

  &.inverse {
    tr {
      &:nth-child(odd) {
        background-color: color(white);
      }
      &:nth-child(even) {
        background-color: color(grey10);
      }
    }

    thead {
      tr {
        background-color: color(grey10) !important;
      }
    }
  }
}

.grid {
  max-width: $maxWidth;
  margin: 0 auto;
  padding: 0 5%;

  @include from('xs') {
    padding: 0 34px;
  }

  @include from('sm') {
    padding: 0 36px;
  }

  @include from('lg') {
    padding: 0 96px;
  }

  @include from('xlg') {
    padding: 0 108px;
  }

  & &:not(.grid--with-padding) {
    padding: 0;

    @include from('xs') {
      margin: 0 -6px;
      width: calc(100% + 12px);
    }

    @include from('sm') {
      margin: 0 -8px;
      width: calc(100% + 16px);
    }

    @include from('lg') {
      margin: 0 -12px;
      width: calc(100% + 24px);
    }
  }

  &--half {
    max-width: $maxWidth / 2;
  }
}

[class*="grid__col-"] {
  @include gutter-padding();
}

.grid__col--bleed {
  padding-left: 0;
  padding-right: 0;
}

.grid__col-xxs-none {
  width: auto;
}

.grid__col-xs-none {
  @include from('xs') {
    width: auto;
  }
}

.grid__col-sm-none {
  @include from('sm') {
    width: auto;
  }
}

.grid__col-md-none {
  @include from('md') {
    width: auto;
  }
}

.grid__col-lg-none {
  @include from('lg') {
    width: auto;
  }
}

.grid__col-xlg-none {
  @include from('xlg') {
    width: auto;
  }
}


.hidden {
  display: none !important;
}

.box {
  background-color: color(white);
  margin-bottom: 1em;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);

  &--rounded {
    border-radius: 5px;
  }
}

.main-container--full-height {
  height: 100%;
}

.cms-gutter {
  height: 8px;

  &--sm {
    height: 8px;
  }

  &--md {
    height: 16px;
  }

  &--lg {
    height: 24px;
  }
}

/* Try to remove browser native button styles */
.cms-clear-button-styles {
  padding: 0;
  border: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  color: inherit;
  line-height: 0;
  text-align: left;
}

