.liiga-game-feed {

  &__mobile {
    .slick-dots {
      visibility: hidden;
    }
  }

  &__title {
    justify-content: flex-start;
  }

  &__game-card {
    position: relative;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  &__live-flag {
    position: absolute;
    top: 20px;
    left: 20px;
    background-color: color(red);
    color: color(white);
    padding-left: 10px;
    padding-right: 10px;
  }

  &__price {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: color(black);
    color: color(white);
    padding-left: 10px;
    padding-right: 10px;
  }

  &__game-image {
    width: 100%;
    background-size: cover;
    background-position: center;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    height: 191px;

    @include from ('md') {
      height: 128px;
    }
    @include from ('xlg') {
      height: 223px;
    }
  }

  &__game-info {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    margin-top: 1em;
  }

  &__team-logo {
    max-height: 2em;
  }

  &__card-title {
    font-size: 1.2em;
    margin: 0;
  }

  &__card-text {
    margin-bottom: 0;
  }
}
