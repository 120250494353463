@mixin pebble() {
  font-family: Pebble, TeliaSans, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
}

@mixin teliaSansHeading() {
  font-family: TeliaSansHeading, TeliaSans, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: normal;
}

@mixin teliaSansFamily() {
  font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

@mixin teliaSans() {
  @include teliaSansFamily;
  font-weight: normal;
}

@mixin teliaSansMedium() {
  @include teliaSansFamily;
  font-weight: 500;
}

@mixin teliaSansBold() {
  @include teliaSansFamily;
  font-weight: 600;
}

@mixin retina() {
  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (   min--moz-device-pixel-ratio: 2), // Bug on older FF
  only screen and (   -moz-min-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (        min-device-pixel-ratio: 2),
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx) {
    @content;
  }
}

@mixin placeholder() {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

// Responsive padding that matches grid edge gutters
@mixin gutter-padding() {
  padding-left: 0;
  padding-right: 0;

  @include from('xs') {
    padding-left: 6px;
    padding-right: 6px;
  }

  @include from('sm') {
    padding-left: 8px;
    padding-right: 8px;
  }

  @include from('lg') {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@mixin tablink() {
  font-size: 15px;
  padding: 0.5em 0;
  margin-right: 1em;
  color: color(black);
  cursor: pointer;
  border-bottom: 3px solid color(grey40);
  display: inline-block;

  @include from('md') {
    font-size: 18px;
  }

  &--active {
    color: color(black);
    border-color: color(corePurple);
    cursor: auto;
  }

  &:hover {
    color: color(corePurple);
    border-color: color(corePurple);
  }
}

@mixin blog-author-face() {
  &-img {
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    margin: auto;
    object-fit: cover;
  }
  &-picture {
    width: 5em;
    height: 5em;
    border-radius: 50%;
    margin: 1em 0 1.2em;
    overflow: hidden;
    display: block;
    position: relative;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 12%,
      rgba(246, 246, 246, 1) 50%,
      rgba(222, 231, 230, 1) 100%
    );
  }
}

@mixin button-hover-gradient() {
  background-color: color(corePurple);

  &:hover {
    background-image: linear-gradient(
      135deg,
      hover(corePurple) 0%,
      hover(corePurple) 66%,
      hover(corePurple) 69%,
      color(corePurple) 86%,
      color(corePurple) 100%
    );
    background-size: 600% 600%;
    animation: primary-button-keyframe ease-out 1.7s;
    animation-fill-mode: forwards;
  }

  @keyframes primary-button-keyframe {
    0% {
      background-position: 100% 96%;
    }
    100% {
      background-position: 0% 5%;
    }
  }
}

@mixin hover-supported {
  /* 
   * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer 
   */
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      @content;
    }
  }
}
