.floater {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-content: center;
  z-index: z(notification);
  background-color: color(corePurple);
  border-radius: 15px;
  width: 291px;
  min-height: 141px;
  padding: 15px;
  right: 24px;
  text-align: center;
  color: color(white);
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
  
  @include from('sm') {
    left: auto;
    bottom: 37px;
    transform: none;
  }

  &--edit-mode {
    position: static;
  }

  &--shifted {
    bottom: 75px;
  }

  &__title {
    @include teliaSansHeading;
    font-size: 22px;
    margin-bottom: 10px;
  }

  &__close-button-container {
    position: relative;
  }
  
  &__close-button {
    z-index: z(modalInput);
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 19px;
    height: 19px;
    cursor: pointer;
    font-size: 15px;
    border-radius: 20px;
    background-color: color(white);
    color: color(corePurple);
    right: -10px;
    top: -10px;
  }

  &__link {
    @include teliaSans;
    margin-top: 4px;
    font-size: 13px;
    text-align: center;
    font-weight: 500;
    color: color(white);

    &:link, &:hover, &:active, &:visited {
      color: color(white);
    }
    
    .icon {
      font-size: 0.5em;
    }

    &--purple {
      color: color(corePurple);
    }

    &--white {
      color: color(white);
    }

    &--black {
      color: color(black);
    }
  }
}
