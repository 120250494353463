.login-suggestion {
  background-color: color(grey20);

  .image-hero + & {
    border-top: 1px solid color(grey30);
  }

  &__small-text {
    display: inline-block;
  }

  &__content {
    padding: 8px 16px;

    @include to('sm') {
      text-align: center;
    }

    &--white {
      background-color: color(white);
    }

    &--lightGrey {
      background-color: color(grey20);
    }

    &--transparent {
      background-color: transparent;
    }
  }

  &__button {
    a {
      margin-left: 10px;
      margin-right: 10px;

      @include from('md') {
        margin-left: 22px;
      }
    }
  }
}
