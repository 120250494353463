.cart {
  background-color: color(grey20);
  color: color(grey200);
  padding-top: 16px;

  &__total {
    overflow: hidden;
    margin: 0 24px;
    padding: 16px 0;
    border-bottom: 1px solid color(grey30);
  }

  &__total-text {
    float: left;
  }

  &__total-price {
    float: right;
  }

  &__amount {
    @include teliaSansHeading;
    font-size: 24px;
  }

  &__button {
    text-align: center;
    margin: 8px auto 16px;
  }

  &__item {
    background-color: color(white);
    padding: 16px 24px;
    border-top: 1px solid color(grey40);
    border-bottom: 1px solid color(grey40);
    display: flex;

    & + & {
      border-top: 0;
    }
  }

  &__item-picture {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 80px;
    margin-right: 8px;
  }

  &__item-image {
    max-width: 60px;
    max-height: 100%;
    width: auto;
    height: auto;
  }

  &__item-type {
    color: color(grey50);
    font-size: 14px;
    line-height: 1.2;
  }

  &__item-name {
    font-size: 18px;
  }

  &__item-price {
    margin-left: auto;
    align-self: center;
  }
}
