.liiga-product-card {
  color: color(grey100);
  background-color: color(grey20);
  border-radius: 8px;
  height: 100%;
  overflow: hidden;
  max-width: 500px;
  margin-bottom: 24px;

  /* Fix a bug where liiga product cards are not visible on Safari 10.x. */
  /* Safari 10.1 */
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (not (stroke-color: transparent)) {
      & {
        height: auto !important;
      }
    }
  }

  /* Safari 10.0 (not 10.1) */
  _::-webkit-:host:not(:root:root),
  & {
    height: auto !important;
  }

  &__background-image {
    position: relative;
    width: 100%;
    height: 347px;
    background-position: center;
    background-size: cover;
  }

  &__foreground-image {
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    bottom: -36px;

    img {
      max-width: 185px;
    }
  }

  &__content {
    position: relative;
    padding: 15px 36px 10px;
  }

  &__change-team-link {
    display: inline-block;
    position: absolute;
    top: 15px;
    color: color(grey70);

    .icon {
      pointer-events: all;
      vertical-align: middle;
      height: 24px;
      width: 24px;
    }

    &:hover {
      color: hover(corePurple);
      cursor: pointer;
    }

    span {
      vertical-align: middle;
    }
  }

  &__title {
    color: color(grey100);
    margin-bottom: 16px;
    font-size: 30px;
  }

  &__description {
    line-height: 24px;
    margin-bottom: 24px;
    font-size: 14px;

    @include from('xs') {
      font-size: 16px;
    }
  }

  &__price-container {
    @include teliaSansHeading;
    margin-bottom: 8px;
    font-size: 20px;

    @include from('xs') {
      font-size: 24px;
    }
  }

  &__price-value {
    font-size: 2em;
  }

  &__price-unit {
    font-size: 1.6em;
  }

  &__price-info {
    font-size: 0.7em;
    line-height: 1;
    margin-bottom: 14px;
  }

  &__disclaimer {
    font-size: 0.7em;
    line-height: 1;
    margin: 14px 0 20px;
  }

  &__button {
    margin-bottom: 6px;

    .button {
      margin-left: 0;
    }
  }

  &__secondary-button-container {
    font-size: 18px;
    color: color(corePurple);
    margin-bottom: 6px;
  }

  &__secondary-button {
    font-weight: normal;

    &:after {
      margin-left: -2px;
    }

    &:hover {
      cursor: pointer;
      color: hover(corePurple);
    }
  }
}
