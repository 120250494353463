.cms-twitter-feed {
  /* this is element that the twitter widget generates for a single tweet */
  twitter-widget.twitter-tweet {
    min-width: 0 !important;
    width: auto !important;
  }

  &__loader {
    padding: 20px;
    text-align: center;
  }
}
