.livesearch {
  padding: 0 5%;

  @include from('xs') {
    padding: 0 34px;
  }

  @include from('sm') {
    padding: 0 36px;
  }

  @include from('md') {
    padding: 0;
    width: 100%;
  }

  &__container {
    &--closed {
      display: none;
    }

    &--open {
      display: block;
    }
  }

  &__form {
    @include from('md') {
      padding-top: 23px;
      padding-bottom: 22px;
    }
  }

  &__input-group {
    position: relative;
  }

  &__input {
    border: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
    padding: 15px 20px 10px 53px;

    @include from('md') {
      padding: 21px 20px 20px 43px;
    }

    @include from('lg') {
      padding: 21px 20px 20px 53px;
    }

    @include placeholder() {
      @include teliaSans;
      font-size: 16px;
      color: color(grey70);

      @include from('md') {
        font-size: 18px;
      }
    }

    &--selected {
      @include from('md') {
        border-bottom: 2px solid color(corePurple);
      }
    }
  }

  &__input-icon {
    color: color(corePurple);
    top: 50%;
    padding: 0;
    margin-top: 4px;
    
    @include from('md') {
      margin: 0;
      top: 45%;
    }
  }

  &__close {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    color: color(grey40);
    transition: color 0.2s;

    @include from('md') {
      display: block;
      right: 0;
    }

    &:hover {
      cursor: pointer;
      color: color(black);
    }
  }

  &__clearinput {
    display: none;
    position: absolute;
    right: 37px;
    top: 39px;
    font-size: 13px;
    color: color(corePurple);
    transition: color 0.2s;

    @include from('md') {
      display: block;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
