@import '../config.scss';
.button {
  font-family: inherit;
  font-size: 12px;
  letter-spacing: 0.8px;
  line-height: 18px;
  font-weight: bold;
  white-space: nowrap;
  padding: 0 2em;
  height: 40px;
  margin: 6px;
  border: 2px solid transparent;
  color: color(white);
  background-color: color(corePurple);
  border-radius: 999px;
  text-transform: uppercase;
  transition: all 0.2s;
  cursor: pointer;
  position: relative;
  display: inline-flex;
  align-items: center;
  max-width: 100%;

  &:focus {
    outline: solid 3px  #40b3ff;
    outline-offset: 2px;

    &:not(:focus-visible) {
      outline: none;
    }
  }

  [class*='grid__col-'] > & {
    align-self: flex-start;
  }

  .grid--justify-center > [class*='grid__col-'] > & {
    align-self: center;
  }

  .grid--justify-end > [class*='grid__col-'] > & {
    align-self: flex-end;
  }

  @at-root {
    button.button {
      display: inline-block;
    }
  }

  @include retina() {
    box-shadow: none;
  }

  // This is needed if using the button as <button class="button">
  &__wrapper {
    display: flex;
    align-items: center;
  }

  @include from('md') {
    height: 44px;
    font-size: 14px;
    padding: 0 2em;
  }

  &:link,
  &:visited {
    color: color(white);
  }

  &:hover,
  &--hover {
    color: color(white);
    background-color: hover(corePurple);
  }

  &--purchase {
    background-color: color(darkGreen);
    &:hover,
    &.button--hover {
      background-color: hover(darkGreen);
    }
  }

  &:disabled,
  &--disabled {
    background-color: color(grey40);
    cursor: default;
    pointer-events: none;
  }

  &--purchase-secondary {
    background-color: rgba(color(darkGreen), 0);
    color: color(darkGreen);
    border-color: color(darkGreen);

    &:link,
    &:visited {
      color: color(darkGreen);
    }

    &:hover,
    &.button--hover {
      background-color: color(darkGreen);
      color: color(white);
      border-color: color(darkGreen);
    }

    &:disabled,
    &.button--disabled {
      color: color(grey40);
      border-color: color(grey40) !important;
      background-color: transparent;
    }
  }

  &--secondary {
    background-color: rgba(color(corePurple), 0);
    color: color(corePurple);
    border-color: color(corePurple);

    &:link,
    &:visited {
      color: color(corePurple);
    }

    &:hover,
    &.button--hover {
      background-color: color(corePurple);
      color: color(white);
      border-color: color(corePurple);
    }

    &:disabled,
    &.button--disabled {
      color: color(grey40);
      border-color: color(grey40) !important;
      background-color: transparent;
    }
  }

  &--text {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--link {
    background-color: transparent;
    color: color(corePurple);
    border: 0 transparent;
    text-transform: none;
    letter-spacing: 0;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    font-size: 14px;

    @include from('md') {
      font-size: 16px;

      .icon {
        margin-top: 1px;
      }
    }

    &:link,
    &:visited {
      color: color(corePurple);
    }

    &:hover,
    &.button--hover {
      background-color: transparent;
      color: hover(corePurple);
      border-color: transparent;
    }

    &.button--disabled {
      color: color(grey40);
      border-color: transparent;
      background-color: transparent;
    }
  }

  &--app-store,
  &--google-play,
  &--windows-store {
    background-repeat: no-repeat;
    background-position: center;
    min-width: 158px;
    background-color: color(black);
    border-color: color(black);
    overflow: hidden;
    background-size: 100px auto;
    height: 40px;

    @include from('md') {
      height: 44px;
    }

    &:hover {
      background-color: color(black);
      border-color: color(black);
    }

    .button--text,
    .icon {
      display: none;
    }
  }

  &--app-store {
    background-image: url('/.resources/pride-ui-module/webresources/assets/images/appstore/desktop_AppStore@2x.png');
  }

  &--google-play {
    background-image: url('/.resources/pride-ui-module/webresources/assets/images/appstore/desktop_googlePlay@2x.png');
  }

  &--windows-store {
    background-image: url('/.resources/pride-ui-module/webresources/assets/images/appstore/desktop_WindowsStore@2x.png');
  }

  @mixin dark-background-styles {
    background-color: color(white);
    color: color(corePurple);

    &:link,
    &:visited {
      color: color(corePurple);
    }

    &:hover,
    &.button--hover {
      background-color: hover(corePurple);
      color: color(white);
    }

    &:disabled,
    &.button--disabled {
      color: color(grey50);
      background-color: color(grey20);
    }

    &.button--purchase {
      color: color(white);
      background-color: color(darkGreen);
      &:hover,
      &.button--hover {
        background-color: hover(darkGreen);
      }
      &:disabled,
      &.button--disabled {
        color: color(grey50);
        background-color: color(grey20);
      }
    }

    &.button--secondary {
      background-color: transparent;
      color: color(white);
      border-color: color(white);
      &:hover,
      &.button--hover {
        border-color: color(white);
        background-color: color(white);
        color: color(corePurple);
      }
      &:disabled,
      &.button--disabled {
        color: color(grey40);
        background-color: transparent;
      }
    }
  }

  &--dark {
    @include from('sm') {
      @include dark-background-styles;
    }

    &:not(.button--not-dark-in-mobile) {
      @include dark-background-styles;
    }
  }

  .icon {
    font-size: 10px;
    margin-left: 5px;
  }

  &--icon-before .icon {
    font-size: 1.143em;
    margin-left: 0;
    margin-right: 9px;
    order: -1;
  }

  &--small {
    height: 30px;
    padding: 6px 1em;
    font-size: 12px;
  }

  &--pebble {
    @include teliaSansHeading;
    font-weight: normal;
  }

  &--no-margin {
    margin: 0;
  }

  &--large {
    font-size: 12px;
    padding: 0 24px;
    height: 40px;

    @include from('sm') {
      font-size: 14px;
      padding: 0 24px;
      height: 48px;
    }
  }

  &--xlarge {
    font-size: 12px;
    padding: 0 24px;
    height: 40px;

    @include from('sm') {
      font-size: 16px;
      padding: 0 40px;
      height: 56px;
    }
  }

  &--primary.button--hover-gradient:not(:disabled):not(.button--disabled) {
    @include button-hover-gradient();
  }
}
