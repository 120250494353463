.helppi-date-selection {
  display: none;
  height: 100%;
  width: 100%;

  &__date-container {
    margin-top: 58px;

    &-title {
      @include teliaSansHeading;
      font-size: 24px;
      font-weight: normal;
      margin-bottom: 48px;
      text-align: center;
    }

    &-week-info {
      background-color: color(grey20);
      height: 50px;
      margin-bottom: 22px;
      padding: 14px 10px;

      @include from ('lg') {
        padding-bottom: 14px;
        padding-left: 37px;
        padding-right: 39px;
        padding-top: 14px;
      }
    }

    &--previous {
      color: color(grey100);
      cursor: pointer;
      float: left;
      font-size: 14px;
      font-weight: bold;
      text-align: left;
      text-transform: uppercase;
      width: 10%;

      span {
        display: none;
      }

      @include from ('lg') {
        width: 25%;
        span {
          display: block;
          float: right;
          margin-right: 30px;
        }
      }
    }

    &--month-name {
      color: color(grey50);
      float: left;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      width: 80%;

      @include from ('lg') {
        width: 50%;
      }
    }

    &--next {
      color: color(grey100);
      cursor: pointer;
      float: left;
      font-size: 14px;
      font-weight: bold;
      text-align: right;
      text-transform: uppercase;
      width: 10%;

      span {
        display: none;
        float: left;
        margin-left: 30px;
      }

      @include from ('lg') {
        width: 25%;

        span {
          display: block;
        }
      }
    }

  }

  &__date-chosen {
    background: color(blue);
    border-radius: 4px;
    color: color(white);
    display: none;
    font-size: 12px;
    height: 82px;
    margin-left: 5%;
    max-width: 392px;
    padding-bottom: 19px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 19px;
    text-transform: capitalize;
    width: 90%;

    @include from ('xs') {
      font-size: 16px;
      margin: 40px auto 0;
      padding-bottom: 19px;
      padding-left: 50px;
      padding-right: 39px;
      padding-top: 19px;
    }

    &.active {
      display: flex;
    }

    &-old-reservation {
      margin-right: 10px;
      width: 45%;

      @include from ('xs') {
        float: left;
        margin-right: 29px;
      }
    }

    &-change-icon {
      margin-top: 14px;
      width: 10%;

      @include from ('xs') {
        float: left;
        margin-top: 14px;
      }
    }

    &-new-reservation {
      width: 45%;

      @include from ('xs') {
        float: right;
      }
    }
  }

  .callback-calendar__date-container {
    justify-content: center;

    .helppi-date-holder-single {
      padding-right: 5px;

      @include from ('xs') {
        padding-right: 22px;
      }

      .callback-calendar__date-num {
        height: 33px;
        width: 33px;
      }
    }
  }

  &__buttons-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    text-align: center;
    width: 100%;
  }

  &__button-primary ,
  &__button-secondary {
    font-size: 14px;
    font-weight: bold;
    padding: 14px 20px;
    width: 125px;

    span {
      margin: 0 auto;
    }
  }
}
