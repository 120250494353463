.cms-image-and-text {
  position: relative;

  &--align-center {
    text-align: center;
  }

  &--align-right {
    text-align: right;
  }

  &--align-left {
    text-align: left;
    min-height: 1px;
  }

  &__content {
    .cms-image-and-text--show-on-scroll & {
      position: relative;
      transition: opacity 1.4s, top 1.4s;
      opacity: 0;
      top: 25px;

      &--visible {
        opacity: 1;
        top: 0;
      }
    }

    .cms-image-and-text--image-left.cms-image-and-text--show-on-scroll & {
      @include from('md') {
        transition: opacity 1.4s, left 1.4s;
        left: 25px;

        &--visible {
          opacity: 1;
          left: 0;
        }
      }
    }

    .cms-image-and-text--image-right.cms-image-and-text--show-on-scroll & {
      @include from('md') {
        transition: opacity 1.4s, left 1.4s;
        left: -25px;

        &--visible {
          opacity: 1;
          left: 0;
        }
      }
    }
  }

  &__image, &__icon {
    .cms-image-and-text--show-on-scroll & {
      transition: opacity 1.2s;
      opacity: 0;

      &--visible {
        opacity: 1;
      }
    }
  }

  &--horizontal {
    .cms-image-and-text {
      &__outer-link {
        @include from('md') {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex-wrap: nowrap;
        }
      }

      &__content {
        @include from('md') {
          flex: 0 0 50%;
          max-width: 50%;
          padding-left: $reflex-cell-spacing;
          text-align: left;
        }

        @include from('md') {
          .cms-image-and-text__text {
            margin: 0 auto 0 0;

            p {
              margin-left: 0;
              margin-right: auto;
            }
          }
        }
      }

      &__image {
        @include from('md') {
          flex: 0 0 50%;
          max-width: 50%;
          padding-right: $reflex-cell-spacing;
          text-align: center;
        }
      }
    }
  }

  &--image-right &__image {
    @include from('md') {
      order: 1;
      padding-right: 0;
      padding-left: $reflex-cell-spacing;
      text-align: center;
    }
  }

  &--image-right &__content {
    @include from('md') {
      text-align: left;
      padding-left: 0;
      padding-right: $reflex-cell-spacing;
    }

    @include from('md') {
      .cms-image-and-text__text {
        margin: 0 auto 0 0;

        p {
          margin-right: auto;
          margin-left: 0;
        }
      }
    }
  }

  &__inner-image {
    width: 100%;
    max-width: 100%;
  }

  &--reverse {
    background-color: color(grey10);
    border-radius: 4px;
    overflow: hidden;

    .body--grey &,
    .columnrow--lightGrey & {
      background-color: color(white);
    }
  }

  &--reverse &__content {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }

  &--no-margins {
    margin-bottom: em(24px);

    .cms-image-and-text__title {
      margin-top: em(27px);
    }

    .cms-image-and-text__text {
      padding-bottom: 0;
    }
  }

  &--small-image-title {
    .cms-image-and-text__image {
      width: 25%;
      height: 25%;
      margin: auto;
    }

    .cms-image-and-text__title {
      font-size: 16px;
    }
  }

  &__container {
    position: relative;
  }

  &__link-overlay {
    left: 0;
    top: 0;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: z(cardLinkOverlay);
  }

  &__image {
    width: 100%;
    display: block;
    border-radius: 4px;
  }

  &--reverse &__image {
    border-radius: 0;
  }

  &__icon {
    display: block;
    font-size: 56px;
    margin-top: 30px;

    @include from('sm') {
      margin-top: 60px;
    }
  }

  &__title {
    margin-top: 1.5em;
    margin-bottom: 0;

    .cms-image-and-text--align-center & {
      margin-left: auto;
      margin-right: auto;
    }

    &--bold {
      font-weight: bold;
    }

    &--white {
      color: color(white);
    }

    &--purple {
      color: color(corePurple);
    }

    &--deepPurple {
      color: color(deepPurple);
    }

    &--lightPurple {
      color: color(lightPurple);
    }

    &--deepBeige {
      color: color(deepBeige);
    }

    &--lightBeige {
      color: color(lightBeige);
    }
  }

  &__icon + &__content {
    margin-top: 15px;

    .cms-image-and-text__title {
      margin-top: 0;
    }

    @include to('xs') {
      .cms-image-and-text__text {
        padding-bottom: 1em;
      }
    }
  }

  &__text {
    padding-bottom: 2.5em;
    max-width: 85%;

    a {
      position: relative;
    }

    @include at('xxs') {
      max-width: 100%;
    }

    .button {
      margin: 8px 0;
      position: relative;
      z-index: 6;
    }

    .cms-image-and-text--align-center & {
      margin: 0 auto;

      p {
        margin-left: auto;
        margin-right: auto;
      }
    }

    .cms-image-and-text--align-right & {
      float: right;
    }

    &--white {
      color: color(white);
    }

    &--lightPurple {
      color: color(lightPurple);
    }
  }

  .columnrow__column--borders &__content {
    padding: 0 2em;
  }

  .columnrow__column--borders &__text {
    padding-bottom: 1.2em;
  }

  &__labels-container {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  &__label-container {
    min-height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    padding: 5px;
    border-radius: 3px;
    margin-top: 4px;

    &--no-icon {
      .cms-image-and-text__label-container-text {
        margin: 0px !important;
      }
    }

    .cms-image-and-text__label-container-text {
      margin-left: 5px;
      font-weight: 500;
      font-size: 14px;
      line-height: 14px;
      text-align: center;
    }

    &--background-black {
      background-color: color(black);
    }
    &--background-white {
      background-color: color(white);
    }
    &--background-purple {
      background-color: color(corePurple);
    }
    &--background-deepPurple {
      background-color: color(deepPurple);
    }
    &--background-lightPurple {
      background-color: color(lightPurple);
    }
    &--background-deepBeige {
      background-color: color(deepBeige);
    }
    &--background-lightBeige {
      background-color: color(lightBeige);
    }
    &--background-beige {
      background-color: color(beige);
    }

    &--text-color-black {
      color: color(black);
    }
    &--text-color-white {
      color: color(white);
    }
    &--text-color-purple {
      color: color(corePurple);
    }
    &--text-color-deepPurple {
      color: color(deepPurple);
    }
    &--text-color-lightPurple {
      color: color(lightPurple);
    }
    &--text-color-deepBeige {
      color: color(deepBeige);
    }
    &--text-color-lightBeige {
      color: color(lightBeige);
    }
    &--text-color-beige {
      color: color(beige);
    }
  }
}
