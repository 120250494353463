.callback-calendar {
  margin-bottom: 16px;

  &__issue-types-title {
    font-weight: bold;
    margin-top: 12px;
    font-size: 20px;
  }

  &__issue-types-options {
    display: flex;
    flex-direction: column;
  }

  & &__issue-types-options &__issue-types-option {
    margin-top: 12px;
  }

  &__info-text {
    @include teliaSans;
    background-color: color(grey30);
    padding: 20px;
    border-radius: 10px;
    word-wrap: break-word;

    &--helppi {
      background-color: color(corePurple);
      border-radius: 60px;
      color: color(white);
      padding: 24px;
      overflow: hidden;
    }
  }

  &__info-banner {
    width: 100%;
    border-radius: 10px;
    margin-top: 10px;
  }

  &__date-selector-week {
    @include teliaSans;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: color(grey20);
    color: color(grey50);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
  }

  &__date-selector {
    margin-top: 16px;
  }

  &__date-selector-week-changer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    color: color(grey100);
    cursor: pointer;
  }

  &__date-selector-dates {
    margin: 20px -6px;
    display: flex;
    justify-content: space-between;
  }

  &__date-selector-date-container {
    display: flex;
    flex-direction: column;
  }

  &__date-selector-date-number {
    @include teliaSans;
    cursor: pointer;
    margin: 0 6px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
    border: solid 1px color(grey30);
    width: 44px;
    height: 44px;
  }

  &__date-selector-date-name {
    @include teliaSans;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-size: 14px;
    color: color(grey50);
    font-weight: bold;
  }

  &__date-selector-date {
    &--selected {
      .callback-calendar__date-selector-date-number {
        color: color(white);
        background-color: color(corePurple);
        border-color: color(corePurple);
        font-weight: bold;
      }

      .callback-calendar__date-selector-date-name {
        color: color(corePurple);
      }
    }
  }

  &__reservation-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: color(blue);
    border-radius: 4px;
    padding: 16px;
  }

  &__reservation-details {
    color: color(white);
  }

  &__reservation-date {
    font-weight: bold;
  }

  &__reservation-edit-link {
    display: flex;
    align-items: baseline;
    font-weight: bold;
    font-size: 12px;
    color: color(white);

    &:hover {
      color: color(white) !important;
    }
  }

  &__reservation-edit-link-text {
    font-size: 12px;
    margin-left: 6px;
  }
}
