.content-article {
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
  background-color: color(grey20);

  &--customerSupport2020 {
    background-color: #f2f2f2;

    & .cms-personalized-card-list {
      padding-bottom: 24px;
    }

    .content-article__header-wrapper {
      @include from('md') {
        padding-left: 22px;
      }
    }

    .content-article__helppi-banner {
      border-radius: 16px;
      box-shadow: 0 6px 24px 0 #3a3a3a1a;
      background-color: color(white);
      padding: 32px;
      margin-bottom: 32px;
    }

    .content-article__helppi-banner-title {
      font-size: 24px;
      margin-bottom: 20px;
      font-weight: bold;
    }

    .content-article__helppi-banner-text {
      font-size: 16px;
      margin-bottom: 20px;
    }

    .content-article__helppi-banner-phone-number {
      @include teliaSansHeading;
      font-size: 32px;
      margin-bottom: 6px;
      line-height: 32px;
    }

    .content-article__helppi-banner-pricing {
      font-size: 16px;
    }

    .content-article__helppi-banner-link {
      font-size: 16px;
      margin-top: 20px;

      .icon {
        height: 10px;
      }
    }

    .content-article__heading {
      // LG Headline from React styleguide
      h1.h2 {
        @include teliaSansHeading;
        font-size: 24px;

        @include from('md') {
          font-size: 32px;
        }

        @include from('lg') {
          font-size: 40px;
        }
      }
    }

    // LG Ingress from React styleguide
    .content-article__ingress {
      @include teliaSans;
      font-size: 14px;

      @include from('md') {
        font-size: 16px;
      }
    }

    .cms-customer-support-page__overlay {
      &--active {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(34, 34, 34, 0.2);
        z-index: z(forward);
      }
    }

    .content-article__article-content,
    .content-article__notification-content {
      transition: box-shadow 0.1s border-radius 0.1s;
      box-shadow: 0 6px 24px 0 #3a3a3a1a;
      border-radius: 16px;
      box-sizing: border-box;
      background-color: color(white);
      overflow: hidden;
      padding: 32px;
      margin-bottom: 16px;

      h1,
      h2,
      h3,
      h4,
      h5 {
        font-size: 20px;
        margin-top: 32px;
        margin-bottom: 24px;
      }

      h5 {
        font-weight: bold;
      }

      .hasTable {
          overflow: auto;
      }
    }

    .content-article__backlink-container {
      @include to('lg') {
        margin-top: 12px;
      }
    }

    .content-article__back-link {
      margin-top: 36px;
      display: flex;
      align-items: center;
      line-height: 0;

      @include to('md') {
        margin-top: 16px;
      }
    }

    .content-article__back-icon {
      display: block;
      margin-right: 6px;
    }

    .content-article__ingress {
      font-size: 22px;
      line-height: 30px;
    }

    .content-article__related-articles {
      @include from('md') {
        padding: 0 0 0 19px;
      }

      svg {
        color: color(corePurple);
        font-size: 10px;
      }

      &-title {
        color: color(grey100);
        // XXS Title from React styleguide
        line-height: 1.25em;
        font-weight: 600;
        font-size: 14px;

        @include from('lg') {
          font-size: 16px;
        }
      }

      &-item {
        display: flex;
        align-items: baseline;
        // MD BodyText from React styleguide
        font-size: 14px;
        line-height: 1.5em;
        font-size: 14px;

        @include from('md') {
          font-size: 16px;
        }

        p {
          margin: 0 0 0 8px;
          color: color(black);
        }
      }
    }

    .content-article__gutter {
      height: 8px;
      margin-top: 8px;
    }

    .content-article__article {
      background-color: #f2f2f2;
      padding: 0;
    }

    .content-article__similar-container {
      display: flex;
      margin: 32px 0 24px 0;

      // MD BodyText from React styleguide
      font-size: 14px;
      line-height: 1.5em;
      font-size: 14px;

      a {
        font-weight: 500;
      }

      @include to('sm') {
        flex-direction: column;

        a {
          margin-top: 8px;
        }
      }

      @include from('md') {
        font-size: 16px;

        a {
          margin-left: 8px;
        }
      }
    }

    .content-article__article-wrapper {
      @include from('md') {
        padding-right: 16px;
      }
    }

    .content-article__right-side-wrapper {
      padding-left: 8px;
      flex-direction: column;
    }

    .content-article__accordion {
      position: relative;
      padding: 1px 32px 0;
      margin-bottom: 16px;
      transition: box-shadow 0.1s, border-radius 0.1s, background-color 0.1s, padding 0.4s;

      &-heading {
        width:100%;
        background-color: transparent;
        border: none;
        padding: 8px 0;
        border-bottom: 1px solid #cdcdcd;
        color: color(grey200);
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        // XS Title from React styleguide
        font-weight: 600;
        line-height: 1.25em;
        font-size: 14px;
        text-align: left;

        @include from('md') {
          font-size: 16px;
        }

        @include from('lg') {
          font-size: 20px;
        }

        svg {
          color: color(corePurple);
        }

        .icon {
          transition: transform 0.4s;
          margin-right: 3px;
        }

        .icon.rotated {
          transform: rotate(180deg);
        }
      }

      &-description {
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        color: color(grey100);
        height: 0;
        overflow: hidden;
        transition: height 0.4s;
        // MD BodyText from React styleguide
        font-size: 14px;
        line-height: 1.5em;
        font-size: 14px;

        @include from('md') {
          font-size: 16px;
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
          font-size: 20px;
          margin-top: 32px;
          margin-bottom: 24px;
        }

        h5 {
          font-weight: bold;
        }
      }

      &--opened {
        box-shadow: 0 3px 8px 0 #3a3a3a1a;
        border-radius: 16px;
        box-sizing: border-box;
        background-color: color(white);
        overflow: hidden;
        padding: 16px 32px;

        .content-article__accordion-description {
          height: auto;

          ol li {
            list-style-position: inside;
          }

          .hasTable {
            overflow: auto;
          }
        }
      }
    }

    .content-article__accordion-anchor {
      position: absolute;
    }

    .content-article__error {
      margin: 20px 0;
    }

    .content-article__extra-component-wrapper {
      margin-top: 16px;
    }
  }

  &--default {
    @media (max-width: 481px) {
      padding-left: 3%;
      padding-right: 3%;
    }
  }

  &__heading {
    h1.h2 {
      @include teliaSansHeading();
      margin-top: 35px;
    }
  }

  &__main {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
  }

  &__article,
  &__right-side-author {
    background-color: color(white);
    border-radius: 6px;
    padding: 30px;
  }

  &__right-side-author {
    margin-bottom: 30px;
    margin-top: 12px;

    @include from('md') {
      margin-top: 0;
    }
  }

  &__right-side-wrapper {
    display: flex;
    flex-direction: column-reverse;

    @include from('md') {
      position: sticky;
      // top: 120px;
      margin-bottom: auto;
    }
  }

  &__back {
    &-link {
      margin-top: 20px;
    }

    &-icon {
      vertical-align: middle;
    }
  }

  &__hero {
    &-image {
      width: 100%;
      margin-bottom: 20px;
      border-radius: 6px;
    }

    &-img {
      width: 100%;
      position: relative;
      border-radius: 6px;
    }

    &-picture {
      &--mobile {
        @include from('sm') {
          display: none;
        }
      }

      &--desktop {
        display: none;

        @include from('sm') {
          display: block;
        }
      }
    }
  }

  &__article {
    img {
      height: auto !important;
      max-width: 100%;
    }

    .youtube-embed-wrapper {
      display: flex;
    }

    iframe {
      max-width: 100%;
    }
  }

  &__discussiontopics {
    &-table-container {
      padding: 14px;
    }

    &-data-list {
      border-bottom: 1px solid color(grey30);
      margin: 10px;

      &:first-child {
        border-bottom: 0px;
      }
    }

    &-heading {
      font-weight: bold;
      margin-bottom: 23px;
      margin-top: 11px;
      font-size: 18px;
      margin-left: 47px;

      @include from('md') {
        margin-left: 0;
      }

      @include from('xlg') {
        right: 25px;
      }
    }

    &-post-time {
      color: color(grey70);
      @include from('md') {
        align-self: end;
      }
    }

    &-data-column {
      color: color(black);
      margin-bottom: 13px;
      font-size: 15px;

      .content-article__discussiontopics-link:hover & {
        color: color(corePurple);
      }
    }

    &-icon {
      font-size: 34px;
      top: 34px;
      color: color(blue);

      @include from('md') {
        top: 0;
      }
    }
  }

  figcaption {
    text-transform: none;
    font-size: 16px;
    color: color(grey50);
    letter-spacing: 0;
    line-height: 24px;
    font-weight: normal;
    margin: 8px 2px 10px;
  }

  figure:after {
    content: '';
    height: 1px;
    display: block;
    width: 100%;
    box-shadow: 0 1px 0 0 color(grey20);
    margin-bottom: 1px;
  }

  &__author {
    max-width: 300px;
    @include blog-author-face();

    &-name {
      font-weight: bold;
    }

    &-description {
      color: color(grey50);
      line-height: 1.3;
    }
  }

  &__categories {
    max-width: 350px;
    margin-top: 26px;

    &-heading {
      text-transform: uppercase;
    }

    & &-link {
      font-size: 14px;
      background-color: color(grey20);
      border-radius: 100px;
      color: color(black);
      padding: 8px 18px;
      margin: 3px 0;
      display: inline-block;

      &:hover {
        background-color: color(grey30);
      }
    }
  }

  &__date {
    color: color(grey50);
    padding: 3em 0 0;
  }

  &__call-to-action {
    margin: 1em 0;

    &-container {
      margin: 0 !important;
    }

    &-content {
      padding: 2em;
    }

    h3,
    h4 {
      margin: 0 0 8px 0;
    }

    a {
      margin-top: 16px;
      margin-left: 0;
    }

    img {
      object-fit: cover;
      max-height: 312px;
    }
  }

  &__related-articles {
    // margin-top: auto;

    &-heading {
      color: color(grey50);
      font-size: 20px;
      margin-bottom: 12px;
    }

    &-list {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;

      .content-central-article-teaser__container {
        margin-bottom: 20px;

        @include from('md') {
          margin-bottom: 10px;
        }
      }
    }
  }

  &__related-article {
    margin: 0 0 12px;
    line-height: 24px;
  }

  &__next-prev-bg-container {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;

    @include from('md') {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__next-prev-bg {
    width: 100%;
    height: 100%;
    background-color: color(grey10);
    border: 2px solid color(white);
    border-radius: 4px;

    &--hover {
      background-color: color(grey20);
    }

    &--right {
      left: 50%;
    }

    &--full-width {
      width: 100%;
    }
  }

  &__next-prev-container {
    justify-content: space-between;
  }

  &__next-prev {
    margin-top: 2em;
    display: flex;
    flex-direction: column;
    position: relative;

    @include from('md') {
      flex-direction: row;
    }

    &-item {
      padding-top: 50px;
      cursor: pointer;

      &:first-child .content-article__next-prev-icon {
        margin-right: 8px;
      }

      &:last-child .content-article__next-prev-icon {
        margin-left: 8px;
      }
    }

    & &-content {
      padding: 0;
    }

    &-link {
      display: flex;
      font-size: 12px;
      color: color(black);
    }

    &-icon {
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: color(corePurple);
      color: color(white);
      width: 22px;
      height: 22px;
      border-radius: 99px;
    }

    &-title {
      color: color(black);
      margin: 14px 0;
    }

    &-ingress {
      color: color(grey50);
      padding-bottom: 50px;
    }
  }

  &__error {
    margin-top: 20px;
  }

  &__bottom-link &__back {
    margin-bottom: 30px;
  }

  &__ingress {
    color: color(black);
  }

  &--support {
    padding-bottom: 58px;

    .content-article__main.grid {
      justify-content: flex-start;
    }
  }

  &__highlight-box {
    margin-bottom: 2em;
    font-size: 18px;

    &__content {
      background-color: color(grey20);
      padding: 1em 2em;
    }
  }

  .call-to-action {
    padding: 20px 0;

    &--website,
    &--email {
      .call-to-action {
        &__icon {
          background-image: url('/.resources/pride-ui-module/webresources/assets/images/call-to-action/link.png');
        }
      }
    }

    &--call,
    &--sms {
      .call-to-action {
        &__icon {
          background-image: url('/.resources/pride-ui-module/webresources/assets/images/call-to-action/phone.png');
        }
      }
    }

    &__icon {
      display: inline-block;
      vertical-align: middle;
      width: 42px;
      height: 42px;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: color(black);
      background-size: 20px 20px;
      border-radius: 50%;
      text-align: center;
      font-size: 22px;
    }

    &__content {
      display: inline-block;
      vertical-align: middle;
      padding-left: 12px;
    }

    &__label {
      color: color(grey50);
      margin-bottom: 4px;
    }

    &__link {
      display: inline-block;
      font-weight: bold;
      color: color(corePurple);
    }

    &__price {
      font-size: 12px;
      margin-top: -3px;
      position: absolute;
    }
  }

  &__products-container {
    margin-top: 40px;
  }

  &__thumbs {
    margin: 0 !important;

    &-container {
      display: none;
      color: color(grey70);
      border-radius: 4px;
      border: 1px solid color(white);
      width: 184px;
      background: color(grey10);

      @include to('sm') {
        width: 155px;
      }

      .content-article__thumbs--enabled &:hover {
        cursor: pointer;
        color: hover(corePurple);
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
      }
    }

    &-down-container {
      margin-left: 20px;

      @include to('sm') {
        margin: 20px 0;
      }
    }

    &-icon {
      display: inline-block;
      font-size: 44px;
      width: 45px;
      height: 41px;
      margin: 25px 70px 60px;

      @include to('sm') {
        margin: 25px 55px 60px;
      }
    }

    &-label {
      width: 39px;
      height: 27px;
      line-height: 26px;
      font-size: 18px;
    }

    &-down-label {
      margin-left: 10px;
    }
  }

  &__feedback {
    &-comments {
      display: none;
      margin-bottom: 20px;

      &-submit {
        margin-top: 20px;
        align-self: center;
      }
    }

    &-title {
      display: none;
      align-self: center;

      h3 {
        margin-bottom: 45px;
      }
    }

    &-consent {
      display: none;
    }
  }

  &__reading-time {
    &-container {
      padding: 5px;
      margin: -26px 0 10px -5px;
    }

    &-icon {
      width: 15px;
      height: 15px;
      display: inline-block;
      vertical-align: middle;

      .icon {
        font-size: 15px;
      }
    }

    &-text {
      display: inline-block;
      vertical-align: middle;
      padding-top: 4px;
    }
  }

  .content-central-article-teaser {
    &__title {
      font-size: 18px;
      margin-bottom: 6px;
      font-weight: 600;
    }

    &__ingress {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 6px;
    }

    &__author {
      font-size: 12px;
    }
  }
}
