.youtube {
  &__container {
    margin: 20px auto;
    height: 100%;
    background-color: #000;

    &:hover {
      .youtube__play-button {
        background-image: url('../assets/images/components/youtube/youtube-play.png');
      }
    }
  }

  &__player {
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
    overflow: hidden;
    position: relative;
    height: 100%;
    cursor: pointer;
  }

  &__thumb {
    bottom: 0;
    display: block;
    left: 0;
    margin: auto;
    max-width: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
    height: auto;
  }

  &__play-button {
    height: 40px;
    width: 40px;
    left: 50%;
    top: 50%;
    margin-left: -20px;
    margin-top: -10px;
    position: absolute;
    background: url('../assets/images/components/youtube/youtube-play-dark.png') no-repeat;
    background-size: 40px;
  }

}

