$cms-navigation-main-height: 72px;
$cms-navigation-top-bar-height: 38px;
$cms-navigation-total-height: 110px;
$cms-sub-navigation-height: 47px;
$cms-segment-container-mobile-height: 52px;

#navigation-wrapper {
  position: absolute;
  left: 0;
  right: 0;
}

#header-wrapper {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: z(navigation);
}

.main-container {
  padding-top: calc(#{$cms-navigation-main-height} + 1px);

  &--has-sub-navigation {
    padding-top: calc(#{$cms-navigation-main-height} + #{$cms-sub-navigation-height} + 1px);
  }

  @include from('md') {
    padding-top: calc(#{$cms-navigation-total-height} + 1px);

    &--has-sub-navigation {
      padding-top: calc(#{$cms-navigation-total-height} + #{$cms-sub-navigation-height} + 1px);
    }
  }
}

#addsearch-results.cms-navigation-2019-compatible {
  // d for desktop, t for tablet, m for mobile
  &#addsearch-results.addsearch-d,
  &#addsearch-results.addsearch-t,
  &#addsearch-results.addsearch-m2 {
    position: fixed !important;
    width: 100% !important;
    left: 0 !important;
    margin-left: 0 !important;
    border: none !important;
    margin-top: 14px !important;

    @include from('md') {
      width: 494px !important;
      right: 0px !important;
      left: auto !important;
    }

    @include from('lg') {
      right: 65px !important;
    }

    @include from('xlg') {
      right: 76px !important;
    }

    @include from('xxlg') {
      right: calc((100vw - 1920px) / 2 + 68px) !important;
    }
  }

  // This contains the search suggestions
  #addsearch-results-right {
    display: none !important;
  }

  // This contains the search results
  #addsearch-results-mid {
    width: auto !important;
    background: none; // Overddides some very weird backgound image that is disguised as a border
  }

  #addsearch-results-bottom {
    display: none;
  }

  // Visible when not enough search results
  #addsearch-fuzzy {
    width: auto !important;
  }

  .addsearch-result-item-sub {
    border: none !important;
    border-bottom: solid 1px color(grey20) !important;
    background-size: 68px auto !important;
    background-position: 24px 50% !important;
    width: auto !important;
    min-height: 128px !important;

    div {
      padding: 0 0 0 116px !important;
      min-height: 128px !important;
      width: auto !important;
    }

    & em {
      color: color(grey70) !important;
      font-weight: normal !important;
    }

    & h2 {
      @include teliaSans;
      text-transform: none !important;

      a {
        color: color(grey200) !important;
        padding: 16px 0 8px 0 !important;
      }
    }

    p {
      color: color(grey70) !important;
    }

    .addsearch_category {
      margin-bottom: 16px !important;
    }
  }

  .addsearch-result-item-container {
    background: none !important;
  }

  #addsearch-top-close {
    display: none !important;
  }
}

.cms-navigation-2019 {
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
  z-index: z(forward);
  background-color: color(white);
  &__skip-container {
    position: absolute;
    display: flex; 
    align-items: center;
    top: -100px;
    height: 40px;
    width: 100%; 
    background-color: #fff;
    transition: top 0.6s ease-in-out;
    z-index: 9999; 

    @include to ('sm') {
      height: 72px;
    }

    &:focus-within {
      top: 0px;
    }

    &.hidden {
      display: none;
    }
  }

  &__skip-link, &__skip-chat {
      position: relative;
      margin-left: 110px;
      text-decoration: none;
      color: #222;
      padding: 6px 8px;
      @include teliaSansBold;
      @include to ('lg') {
        margin-left: 100px;
      }
      @include to ('md') {
        margin-left: 40px;
      }
  }

  &__skip-chat {
      margin-left:5px;
  }

  &__skip-chat.skip-link-absent {
      margin-left: 110px;
      @include to ('lg') {
        margin-left: 100px;
      }
      @include to ('md') {
        margin-left: 40px;
      }
  }

  &__top-bar {
    overflow: hidden;
    background-color: color(white);
    max-height: 0;
    transition: max-height 0s;

    @include from('md') {
      height: $cms-navigation-top-bar-height;
      max-height: $cms-navigation-top-bar-height;
    }
  }
  &__left-overlay-container {
    flex-grow: 1;
    background-color: transparent;
  }

  &__top-bar-container {
    display: none;
    @include from('md') {
    display: flex;
    height: $cms-navigation-top-bar-height;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    }
  }

  &__market-segment-container {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    height: auto;
    padding: 0 24px;
    border-bottom: solid 1px color(grey30);
    background-color: color(white);

    @include from('md') {
      border-bottom: none;
      padding: 0;
      height: auto;
      align-items: initial;

      &--mobile {
        display: none;
      }
    }
  }

  &__market-segment {
    color: color(grey70);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 500;
    margin-right: 16px;
    line-height: 42px;
    border-bottom: solid 3px transparent;

    &--active {
      cursor: default;
      color: color(corePurple);
      border-bottom: solid 3px color(corePurple);
    }

    @include from('md') {
      font-size: 11px;
      line-height: 23px;
      border-bottom: solid 2px transparent;

      &--active {
        border-bottom: solid 2px color(corePurple);
      }

      &:hover {
        color: color(grey200);
      }
    }
  }

  &__menu-and-language-container {
    overflow-y: auto;
    max-height: calc(100vh - (#{$cms-navigation-main-height} + #{$cms-segment-container-mobile-height}));
    max-height: calc((var(--vh, 1vh) * 100) - (#{$cms-navigation-main-height} + #{$cms-segment-container-mobile-height}));

    @include from('md') {
      overflow: visible;
      max-height: none;
      white-space: nowrap;
    }
  }

  &__language-container {
    display: flex;
    align-items: center;
    height: 70px;
    padding-left: 12px;
    background-color: color(grey20);

    @include from('md') {
      padding-left: 0;
      margin-right: -8px;
      height: auto;
      background-color: color(white);

      &--mobile {
        display: none;
      }
    }
  }

  &__language {
    color: color(grey70);
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 500;
    padding: 0 12px;

    &:hover {
      color: color(grey200);
    }

    @include from('md') {
      padding: 0 8px;
      font-size: 11px;
    }
  }

  &__main {
    background-color: color(white);
    height: $cms-navigation-main-height;
    position: static;
  }

  &__main-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: static;
    display: flex;
    height: $cms-navigation-main-height;
  }

  &__main-left {
    display: flex;
    align-items: center;
  }

  &__menu-container {
    position: absolute;
    max-height: 0;
    overflow: hidden;
    top: calc(#{$cms-navigation-main-height});
    left: 0;
    width: 100%;
    transition: max-height 0.1s;
    z-index: z(forward);

    &--visible {
      z-index: z(back);
      max-height: 1000px;
      transition: max-height 0.2s;

      @include from('md') {
        z-index: 0;
      }
    }

    @include from('md') {
      transition: margin 0.1s ease-out 0.2s, opacity 0.2s ease-out 0.2s;
      position: static;
      overflow: visible;
      max-height: none;
      margin-left: 24px;
      top: calc(#{$cms-navigation-main-height} + 1px);
      opacity: 1;
    }

    &--slide-hidden {
      transition: margin 0.2s, opacity 0.2s;
      overflow: hidden;
      opacity: 0;
      margin-left: 20px;
    }
  }

  &__menu {
    display: flex;
    margin: 0;
    padding-left: 0;
    
    

    ul,li {
      list-style-type: none;
    }

    @include to('sm'){
      display: contents;
      width: 100%;
    }
    
    .cms-navigation-2019__menu-item > & {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      max-height: 0;
      transition: max-height 0.2s, transform 0s ease-out 0.2s;
      transform: scale(0);

      @include from('md') {
        overflow: visible;
        overflow-x: clip;
        transform-origin: 0 0;
        transition: transform 0.2s;
        transform: scaleY(0);

        &--level-3 {
          transform: scaleX(0);
        }
      }
    }

    .cms-navigation-2019__menu-item--selected > & {
      max-height: 1000px;
      transform: scale(1);
      transition: max-height 0.2s, transform 0s ease-out 0s;

      @include from('md') {
        overflow: visible;
        transition: transform 0.2s;
        transform: scaleY(1);
        max-height: none;

        &--level-3 {
          transform: scaleX(1);
        }
      }
    }

    .cms-navigation-2019__menu-item--disabled {
      .icon {
        position: relative;
        font-size: 20px;
        top: 4px;
        margin-left: 6px;
        color: color(black);
      }
    }

    // Level 1 menu
    &--level-1 {
      flex-direction: column;
      position: relative;
      background-color: color(grey20);

      @include from('md') {
        flex-direction: row;
        flex-wrap: nowrap;
        height: 40px;
        line-height: 35px;
        margin-top: 20px;
        background-color: color(white);
      }
    }

    // Level 2 menu
    &--level-2 {
      position: relative;
      background-color: color(white);
      max-width: 840px;
      display: block;

      

      @include from('md') {
        z-index: z(forward);
        position: absolute;
        margin-top: 46px;
        background-color: color(grey20);
        left: -12px;
      }
    }

    // Level 3 menu
    &--level-3 {
      position: relative;
      background-color: color(white);
      width: inherit;
      max-width: none;
      

      @include from('md') {
        position: absolute;
        top: 0px;
        left: 230px;
        background-color: color(grey20);
      }
    }
  }
  &__menu-item {
    position: relative;
    transition: background-color 0.1s ease-out 0.05s;
    cursor: pointer;
    display: flex;
    align-items: baseline;
    width: 100%;
    justify-content: space-between;
   

    @include from('md') {
      transition: background-color 0s;
    }
    @include to('sm'){
      display: block;
      background-color: #f2f2f2;
      padding-left: 24px;
      
    
      .cms-navigation-2019__menu-dropdown-icon {
        position: absolute;
        margin-top: 18px;
        right: 2em; 
        transform: translateY(-50%);
        
      }

      &__menu-item-text {
        padding-right: 30px; 
      }
    }
    &--last {
      margin-bottom: 16px;

      .cms-navigation-2019__menu-item--last {
        margin-bottom: 0;
      }

      @include from('md') {
        margin-bottom: 0;
      }
    }

    // Level 1 menu item
    .cms-navigation-2019__menu--level-1 > & {
      position: relative;
      color: color(grey200);
      line-height: 70px;
      border-bottom: solid 1px color(grey30);
      overflow: hidden;

      &--selected {
        background-color: color(white);
        transition: background-color 0s;
        overflow: visible;
      }

      @include from('md') {
        padding: 0 12px;
        height: 40px;
        line-height: 35px;
        border-bottom: none;
        overflow: visible;
      }
    }

    // Level 2 menu item
    .cms-navigation-2019__menu--level-2 > & {
      line-height: 20px;
      background-color: color(white);
      padding: 2px 24px 2px 2px;
      white-space: normal;
      width: 100%;
      @include from('md') {
        padding: 0;
        width: 230px;
        line-height: 20px;
        border-bottom: solid 1px color(grey20);

        &--selected {
          background-color: color(grey10);
        }

        &:hover {
          background-color: color(grey20);
        }
      }
    }

    // Level 3 menu item
    .cms-navigation-2019__menu--level-3 > & {
      line-height: 20px;
      background-color: color(white);
      padding: 2px 24px 2px 24px;
      white-space: normal;

      @include from('md') {
        padding: 0;
        width: 230px;
        line-height: 20px;
        border-bottom: solid 1px color(grey20);
        background-color: color(grey10);

        &:hover {
          background-color: color(grey20);
        }
      }
    }
  }

  // Set margin-left for first-level menu item chevron to 10px
  .cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-dropdown-icon {
    margin-left: 10px;
    @include from('md') {
      position: static;
      margin-left: 10px; 
      transform: none;
      top: auto;
      right: auto;
    }
  }
  // Set margin-left for second-level menu item chevron to auto
  .cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-dropdown-icon {
    margin-left: 10px;
    top: 0;
    @include from('md') {
      position: static;
      margin-left: auto; 
      transform: none;
      top: auto;
      right: auto;
    }
  }
  &__menu-security-icon {
    margin-right: 5px;
    @include from('md') {
      margin-right: 0; 
    }
  }

  &__menu-item-text {
    color: color(grey200);
    user-select: none;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    font-size: inherit; 
    font-family: inherit; 
    cursor: pointer; 
    width: 100%;
    text-align: left;

    @include to('sm'){
      display: inline-block;
      padding-right: 0;
      width: auto;
    }

    // Level 1 text
    .cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item > & {
      font-size: 18px;
      font-weight: 500;

      @include from('md') {
        padding: 0;
        font-size: 16px;
        font-weight: normal;

        @include hover-supported {
          color: color(corePurple);
        }
      }
    }

    .cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item--selected > & {
      @include from('md') {
        color: color(corePurple);
      }
    }

    .cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item--active > & {
      @include from('md') {
        border-bottom: solid 2px color(corePurple);
      }
    }

    // Level 2 text
    .cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item > & {
      font-size: 14px;
      font-weight: 500;
      border-top: solid 1px color(grey20);
      padding: 14px 0 14px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      

      @include from('md') {
        font-size: 16px;
        font-weight: normal;
        border-top: none;
        padding: 17px 18px 17px 24px;
        

        &:hover {
          color: color(grey200);
        }
      }
    }

    // Level 3 text
    .cms-navigation-2019__menu--level-3 > .cms-navigation-2019__menu-item > & {
      font-size: 14px;
      font-weight: normal;
      padding: 10px 0 10px 22px;
      width: 100%;

      @include from('md') {
        font-size: 16px;
        font-weight: normal;
        border-top: none;
        padding: 17px 18px 17px 24px;

        &:hover {
          color: color(grey200);
        }
      }
    }
  }
&__menu-dropdown-icon {
    transition: transform 0.2s;
    font-size: 10px;
    margin-left: auto;
    @include to('sm'){
      font-size: 15px;
    }

    // Level 1 icon
    .cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item > & {
      transform: rotate(0deg);
      border-radius: 50%;
      line-height: 0px;
      font-size: 16px;
      background-color: color(grey20);
      margin-right: -9px;
      padding: 9px;

      @include from('md') {
        font-size: 10px;
        background-color: transparent;
        padding: 0px;
        margin-right: 0;
        margin-left: 8px;
      }
    }

    // Level 1 icon selected
    .cms-navigation-2019__menu--level-1 > .cms-navigation-2019__menu-item--selected > .cms-navigation-2019__menu-item-text + & {
      transform: rotate(-180deg);
    }

    // Level 2 icon
    .cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item > .cms-navigation-2019__menu-item-text + & {
      transform: rotate(0deg);
      border-radius: 50%;
      line-height: 0px;
      font-size: 10px;
      background-color: color(white);
      margin-right: 12px;
      padding: 4px;

      @include from('md') {
        transition: transform 0s;
        margin-right: 0;
        transform: rotate(-90deg);
        background-color: transparent;
        margin-right: 20px;
      }
    }

    // Level 2 icon selected
    .cms-navigation-2019__menu--level-2 > .cms-navigation-2019__menu-item--selected > .cms-navigation-2019__menu-item-text + & {
      transform: rotate(-180deg);
      background-color: color(grey20);

      @include from('md') {
        transform: rotate(-90deg);
        background-color: transparent;
      }
    }

    // There is never a level 3 icon
  }

  &__logo {
    color: color(corePurple);
    line-height: 0;
    margin-bottom: 6px;

    .icon {
      height: 40px;
      width: 105px;
    }
  }

  &__icon-menu {
    display: flex;
    align-items: center;
    line-height: 0;

    .icon {
      color: color(grey200);
    }
  }

  &__icon-menu-item {
    position: relative;
    cursor: pointer;
    margin-left: 16px;

    .icon {
      height: 28px;
      width: 28px;
    }
    &:hover .icon--search,
    &:hover .icon--cart,
    &:hover .icon--end-user,
    &:hover .cms-navigation-2019__login-text {
      color: color(corePurple);
    }

    &--menu {
      display: block;

      @include from('md') {
        display: none;
      }
    }
  }

  &__search-input-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    min-width: 0;
    border-radius: 0;
    width: 48px;
    background-color: color(white);
    margin-right: -8px;
    z-index: 0;

    @include from('md') {
      transition: width 0.2s, min-width 0.2s;
    }

    &--active {
      position: fixed;
      top: 0;
      left: 0;
      border-radius: 0;
      min-width: 310px;
      width: 100%;
      height: $cms-navigation-main-height;
      padding: 0 13px 0 15px;
      z-index: z(forward);

      @include from('md') {
        position: relative;
        display: block;
        border-radius: 999px;
        padding: 0 40px 0 46px;
        width: 310px;
        height: auto;
      }
    }
  }

  &__search-icon {
    cursor: pointer;
    padding:0;
    background-color: color(white);
    z-index: z(forward);
    top: 8px;
    left: 9px;
    position: absolute;
    border:none;
    .icon {
      height: 28px;
      width: 28px;
    }
    &:focus {
      z-index: z(forward);
    }


    .cms-navigation-2019__search-input-wrapper--active & {
      position: relative;
      top: 0;
      left: 0;
      @include from('md') {
        position: absolute;
        top: 8px;
        left: 8px;
      }
      .icon--search {
        color: color(grey200);
      }
    }
  }

  &__search-close-icon {
    cursor: pointer;
    padding: 5px;
    top: 0;
    right: 0;
    position: absolute;
    background-color: white;

    .icon {
      height: 28px;
      width: 28px;
    }

    @include from('md') {
      padding: 9px;
    }

    .cms-navigation-2019__search-input-wrapper--active & {
      position: relative;
      top: 0;
      right: 0;

      @include from('md') {
        position: absolute;
      }
    }
  }

  &__search-input {
    @include teliaSans;
    width: 100%;
    background-color: transparent;
    border: none;
    font-size: 16px;
    height: 45px;
    padding: 8px;
    outline: none;
  }

  &__search-overlay,
  &__general-overlay {
    background-color: rgba(color(black), 0.25);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    opacity: 0;
    z-index: z(back);
    transition: opacity 0.2s, height 0s ease-out 0.2s;

    @include from('md') {
      transition: opacity 0.2s, height 0s ease-out 0.2s;
    }

    &--visible {
      opacity: 1;
      height: 100%;
      transition: opacity 0.2s, height 0s;
      z-index: z(forward);
    }
  }

  &__search-overlay {
    z-index: z(forward);
  }
  &__login-status {
    &--logged-out {
      display: flex;
      align-items: center;
      border: none;
      background-color: transparent;
      padding: 2px;
    }
    &--logged-in {
      border: none;
      background-color: transparent;
      padding: 2px;
    }
  }
  &__login-text {
    @include teliaSans;
    font-size: 16px;
    color: color(grey200);
    margin-left: 6px;
    display: none;
    @media only screen and (min-width: 940px) {
      display: inline-block;
    }
  }
  &__login-user-initials {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: color(white);
    background-color: color(corePurple);
    font-weight: bold;
    font-size: 11px;
    width: 26px;
    height: 26px;
  }
  &__login-close-icon{
    border: none;
    background-color: transparent;
    padding: 0;
  }
  &__cart-amount {
    position: absolute;
    top: -4px;
    left: 13px;
    width: 19px;
    height: 19px;
    font-weight: bold;
    display: none;
    line-height: 18px;
    // This will be set to flex in javascript when the amount is > 0
    display: none;
    justify-content: center;

    > .icon {
      position: absolute;
      width: 19px;
      height: 19px;
      fill: color(corePurple);
      left: 0;
      top: 0;
    }
  }

  &__cart-amount-number {
    font-size: 11px;
    color: color(white);
    z-index: 1;
  }

  &__hamburger {
    cursor: pointer;
    transform: rotate(0deg);
    transition: transform 0.2s ease-in-out;
    height: 26px;
    width: 26px;
    border: none;
    background-color: transparent;
    padding: 0;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 22px;
      background: color(grey200);
      border-radius: 999px;
      opacity: 1;
      left: 2px;
      transform: rotate(0deg);
      transition: 0.2s ease-in-out;

      &:nth-child(1) {
        top: 6px;
      }

      &:nth-child(2),
      &:nth-child(3) {
        top: 12px;
      }

      &:nth-child(4) {
        top: 18px;
      }
    }
  }

  &__hamburger--active {

    span {
      top: 13px;

      &:nth-child(1) {
        width: 0;
        left: 50%;
      }

      &:nth-child(2) {
        width: 22px;
        transform: rotate(45deg);
        background-color: color(grey200);
      }

      &:nth-child(3) {
        width: 22px;
        transform: rotate(-45deg);
        background-color: color(grey200);
      }

      &:nth-child(4) {
        width: 0;
        left: 50%;
      }
    }
  }

  // Login content
  &__contents {
    max-height: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    width: 100%;
    position: relative;
    transition: max-height 0.2s;
    -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */

    @include from('md') {
      transition: max-height 0.2s;
    }

    &--login.cms-navigation-2019__contents--visible {
      background-color: transparent;
      overflow: auto;
      z-index: z(back);
      max-height: calc(100vh - (#{$cms-navigation-main-height}));
      max-height: calc((var(--vh, 1vh) * 100) - #{$cms-navigation-main-height});

      @include from('md') {
        max-height: calc(100vh - #{$cms-navigation-total-height});
        max-height: calc((var(--vh, 1vh) * 100) - #{$cms-navigation-total-height});

        .cms-navigation-2019__top-bar--hidden + .cms-navigation-2019__main & {
          max-height: calc(100vh - #{$cms-navigation-main-height});
          max-height: calc((var(--vh, 1vh) * 100) - #{$cms-navigation-main-height});
        }
      }

      // hide scrollbars
      overflow: -moz-scrollbars-none;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__user-pebble {
    width: 41px;
    height: 43px;
    margin-right: 9px;
  }

  &__logged-in-container {
    display: flex;
    background-color: transparent;
    max-width: 1920px;

    @include from('md') {
      padding: 0 36px;
      margin: 0 auto;
    }

    @include from('lg') {
      padding: 0 96px;
    }

    @include from('xlg') {
      padding: 0 108px;
    }
  }

  &__login-main-area {
    position: relative;
    -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
    padding: 0;

    @include from('md') {
      padding-top: 0;
    }
  }

  &__login-bottom-area {
    border-top: 1px solid color(grey30);
    background-color: color(grey20);
  }

  &__login-bottom-link {
    display: flex;
    font-size: 16px;
    color: color(grey200);
    font-size: 14px;
    padding: 22px 27px 18px;

    &:hover {
      color: color(grey200);
    }

    @include from('md') {
      padding: 16px 0 8px;

      &:first-of-type() {
        padding-top: 30px;
      }

      &:last-of-type() {
        padding-bottom: 30px;
      }
    }

    span {
      pointer-events: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      line-height: 1;
    }

    svg {
      font-size: 24px;
      margin-right: 8px;
    }

    &-container {
      @include from('md') {
        max-width: 532px;
        margin: 0 auto;
      }

      &--logged-in {
        min-width: 260px;
        max-width: 260px;
        margin-left: auto;
        display: grid;

        // fix for IE11 as it does not support grid properly
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          display: block;
        }

        .cms-navigation-2019__login-bottom-link {
          color: color(grey70);
          border-top: 1px solid color(grey30);
          background-color: color(grey20);
          padding: 15px 24px;

          &:hover,
          &:active {
            background-color: color(grey30);
          }
        }
      }
    }
  }

  &__login-bottom-icon {
    color: color(black);
    font-size: 24px;
    margin-right: 14px;

    @include from('md') {
      font-size: 40px;
      margin-right: 20px;
    }
  }

  &__login-bottom-title {
    color: color(black);
    margin-left: 0;

    @include from('md') {
      font-size: 18px;
      margin: 0;
    }
  }

  &__login-bottom-description {
    font-size: 14px;
    color: color(grey70);

    @include to('sm') {
      display: none;
    }
  }

  .sub-nav {
    z-index: 0;
  }
}

// This moved here from common.scss because we don't want to export common.scss in the navigation css bundle in config.js for external systems
// and currently we're not actually using this common css elsewhere. ¯\_(ツ)_/¯
.cms-common {
  // For simulating page level grid paddings in cases where we do not want or cannot use grid for some reason
  &__grid-padding {
    margin: 0 auto;
    max-width: 1920px;
    padding: 0 5%;

    @include from('xs') {
      padding: 0 34px;
    }

    @include from('sm') {
      padding: 0 36px;
    }

    @include from('lg') {
      padding: 0 96px;
    }

    @include from('xlg') {
      padding: 0 108px;
    }
  }
}
