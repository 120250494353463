.cms-facebook-feed {

  .fb-page {
    visibility: hidden;
  }
  
  &__loader {
    padding: 20px;
    text-align: center;
  }
}
