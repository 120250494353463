$heroHeight: 480px;
$heroHeightLarge: 392px;
$heroHeightMedium: 360px;
$heroHeightSmall: 344px;
$backgroundMobileHeight: 224px;

.media-container-hero {
  position: relative;
  background-color: color(grey20);
  z-index: z(forward);

  @include from('sm') {
    background-color: transparent;
  }

  &__header {
    width: 100%;
  }

  &--size-from-content {
    @include from('sm') {
      height: $heroHeightSmall;
    }

    @include from('md') {
      height: $heroHeightMedium;
    }

    @include from('lg') {
      height: $heroHeightLarge;
    }

    @include from('xlg') {
      height: $heroHeight;
    }
  }

  &--color-white {
    @include from('sm') {
      color: color(white);
    }
  }

  &--color-black {
    @include from('sm') {
      color: color(grey200);
    }
  }

  &--color-purple {
    @include from('sm') {
      color: color(corePurple);
    }
  }

  &--color-deepPurple {
    @include from('sm') {
      color: color(deepPurple);
    }
  }

  &--color-deepBeige {
    @include from('sm') {
      color: color(deepBeige);
    }
  }

  &--color-lightPurple {
    @include from('sm') {
      color: color(lightPurple);
    }
  }

  &--color-lightBeige {
    @include from('sm') {
      color: color(lightBeige);
    }
  }


  &__grid {
    flex-direction: column-reverse;
    width: 100%;
    height: 100%;

    @include from('sm') {
      flex-direction: row;
    }
  }

  &--size-from-content &__grid {
    height: 100%;
  }

  &__heading {
    margin: 25px 0 18px;

    @include to('xs') {
      margin-bottom: 10px;
    }

    @include from('sm') {
      margin-top: 0;
    }

    @include from('xxlg') {
      margin-bottom: 33px;
    }
  }

  &__subheading {
    margin: 0 0 8px;
  }

  &__description {
    margin: 0px;
    max-width: 100%;
  }

  &__heading,
  &__subheading,
  &__description {
    &--color-white {
      @include from('sm') {
        color: color(white);
      }
    }

    &--color-black {
      @include from('sm') {
        color: color(grey200);
      }
    }

    &--color-purple {
      @include from('sm') {
        color: color(corePurple);
      }
    }

    &--color-deepPurple {
      @include from('sm') {
        color: color(deepPurple);
      }
    }
  
    &--color-deepBeige {
      @include from('sm') {
        color: color(deepBeige);
      }
    }
  
    &--color-lightPurple {
      @include from('sm') {
        color: color(lightPurple);
      }
    }
  
    &--color-lightBeige {
      @include from('sm') {
        color: color(lightBeige);
      }
    }


  }

  &__basic-components {
    margin: 12px 0 0;

    @include from('md') {
      margin-top: 16px;
    }

    @include from('xxlg') {
      margin-top: 26px;
    }

    .button {
      margin-left: 0;
    }

    .price-component {
      margin-top: 10px;
      margin-bottom: 20px;
    }
  }

  &__background {
    width: 100%;
    position: absolute;
    height: $backgroundMobileHeight;
    overflow: hidden;

    @include from('sm') {
      height: auto;
      max-height: 100%;
      max-width: 100vw;
    }

    .media-container {
      min-width: 100%;
      position: absolute;

      @include from('sm') {
        position: relative;
      }
    }

    &--align-top-right {
      margin-left: 100%;
      transform: translate(-100%, 0);

      .media-container {
        right: 0;
        top: 0;
      }

      @include from('sm') {
        .media-container-hero--size-from-content & {
          top: 0;
          right: 0;
          transform: none;
          margin: 0;
        }

        .media-container {
          transform: none;
        }
      }
    }

    &--align-top-center {
      margin-left: 50%;
      transform: translate(-50%, 0);

      .media-container {
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
      }

      @include from('sm') {
        .media-container-hero--size-from-content & {
          top: 0;
          left: 50%;
          transform: translate(-50%, 0);
          margin: 0;
        }
      }
    }

    &--align-middle-left {
      .media-container {
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }

      @include from('sm') {
        .media-container-hero--size-from-content & {
          top: 50%;
          left: 0;
          transform: translate(0, -50%);
          margin: 0;
        }

        .media-container {
          transform: none;
        }
      }
    }

    &--align-middle-right {
      margin-left: 100%;
      transform: translate(-100%, 0);

      .media-container {
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }

      @include from('sm') {
        .media-container-hero--size-from-content & {
          top: 50%;
          right: 0;
          transform: translate(0, -50%);
          margin: 0;
        }

        .media-container {
          transform: none;
        }
      }
    }

    &--align-middle-center {
      margin-left: 50%;
      transform: translate(-50%, 0);

      .media-container {
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }

      @include from('sm') {
        .media-container-hero--size-from-content & {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin: 0;
        }

        .media-container {
          transform: none;
        }
      }
    }

    &--align-bottom-left {
      .media-container {
        top: 100%;
        transform: translate(0, -100%);
      }

      @include from('sm') {
        .media-container-hero--size-from-content & {
          bottom: 0;
          left: 0;
        }

        .media-container {
          transform: none;
        }
      }
    }

    &--align-bottom-right {
      margin-left: 100%;
      transform: translate(-100%, 0);

      .media-container {
        right: 0;
        bottom: 0;
      }

      @include from('sm') {
        .media-container-hero--size-from-content & {
          bottom: 0;
          right: 0;
          margin: 0;
          transform: none;
        }
      }
    }

    &--align-bottom-center {
      margin-left: 50%;
      transform: translate(-50%, 0);

      .media-container {
        bottom: 0;
      }

      @include from('sm') {
        .media-container-hero--size-from-content & {
          bottom: 0;
          left: 50%;
          transform: translate(-50%, 0);
          margin: 0;
        }
      }
    }
  }

  &__content {
    justify-content: center;
    align-self: center;
    align-items: center;
    text-align: center;
    margin-top: 0;

    &--align {
      &-center {
        margin-top: $backgroundMobileHeight;

        @include from('sm') {
          margin: 0;
        }
      }

      &-left {
        @include from('sm') {
          margin: 0;
          align-items: flex-start;
          text-align: left;
        }
      }
    }
  }

  &__floating-element {
    height: $backgroundMobileHeight;
    @include from('sm') {
      height: auto;
      max-height: 100%;
    }
  }

  &__container {
    width: 100%;
    height: 100%;
    top: 0;
    padding: 0 0 32px;
    text-align: center;
    overflow: hidden;

    .splash {
      margin: 0;
      position: absolute;
      right: 8%;
      top: 20px;

      @include from('sm') {
        right: 0;
        top: 48px;
      }
    }

    .image-element__image {
      max-height: 200px;
      max-width: 100%;
      width: auto;
      height: auto;

      @include from('sm') {
        max-height: none;
      }
    }

    @include from('sm') {
      padding: 0;
    }
  }

  &--breakout-background &__container {
    overflow: visible;
  }

  &--size-from-content &__container {
    position: relative;
  }

  &--size-from-background &__container {
    @include from('sm') {
      padding: 0;
      position: absolute;
    }
  }

  &--size-from-background &__background {
    @include from('sm') {
      position: relative;
    }
  }

  &--size-from-content &__background {
    position: absolute;
  }
}
