.icon-panel {
  background-color: color(grey20);
  padding-top: 40px;
  padding-bottom: 52px;

  @include from('xs') {
    padding-left: 6px;
    padding-right: 6px;
  }

  @include from('sm') {
    padding-left: 8px;
    padding-right: 8px;
  }

  @include from('lg') {
    padding-left: 12px;
    padding-right: 12px;
  }

  &__card {
    outline: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: color(white);
    padding: 1em;
    margin: 2px;

    &:link, &:visited, &:hover, &:active {
      color: color(grey200);
    }
  }

  &__icon {
    font-size: 24px;
    margin-right: 0.5em;
  }

}
