.epressi-article {
  &__title {
    color: color(corePurple);
    max-width: 100%;
   }

  &__introduction {
    font-weight: bold;
  }

  &__images {
    margin: -8px;
  }

  &__image-container {
    display: inline-block;
    max-width: 1000px;
    margin: 8px;
  }

  &__image {
    max-width: 100%;
  }

  p {
    max-width: 100%;
  }
}
