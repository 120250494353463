.image-element {
  &:only-child {
    min-height: 100%;
  }

  &--left {
    text-align: left;
  }
  
  &--center {
    text-align: center;

    img {
      margin: 0 auto;
    }
  }

  &--right {
    text-align: right;
  }

  &__picture {
    max-width: 100%;

    &--fit {
      width: 100%;
    }
  }

  &__image {
    max-width: 100%;
    display: inline-block;

    &--fit {
      width: 100%;
    }
  }
}
