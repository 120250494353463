// Timings
$hover-begins: 0s;
$hover-ends: 0.2s;
$growth-begins: 0.1s;
$growth-ends: 0.5s;
$button-appearance-begins: 0.3s;
$button-appearance-ends: 0.6s;
$restore-begins: 0s;
$restore-ends: 0.2s;

$hover-transition: #{$hover-ends - $hover-begins} ease $hover-begins;
$growth-transition: #{$growth-ends - $growth-begins} ease $growth-begins;
$button-appearance-transition: #{$button-appearance-ends - $button-appearance-begins} ease $button-appearance-begins;
$restore-transition: #{$restore-ends - $restore-begins} ease $restore-begins;

.magnolia-card {
  @include teliaSans;
  background-color: #FFF;
  box-shadow: 0px 12px 24px 0px rgba(0,0,0,0.12);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 140px;
  width: 100%;
  overflow: hidden;
  padding: 8px 16px;
  position: relative;
  background-size: cover;
  background-position: center;
  transition: transform $restore-transition, height $restore-transition;

  &--hover {
    &:hover {
      @include from('sm') {
        transform: scale(1.03);
        box-shadow: 0px 12px 24px 0px rgba(0,0,0,0.12);
        z-index: z(productCardHover);
      }
    }
  }

  &--gradient-purple {
    background: color(corePurple);
    background: linear-gradient(180deg, color(corePurple) 0%, #7625A7 100%);
  }

  &--text-white {
    color: color(white);
  }

  @include from('xs') {
    min-height: 224px;
    padding: 16px 24px;
  }
  
  @include from('sm') {
    min-width: 240px;
    min-height: 500px;
    padding: 40px 32px 16px 32px;
    flex-direction: column;
    border-radius: 16px;

    &--horizontal {
      flex-direction: row;
      min-height: 256px;
    }
  }
  
  @include from('md') {
    min-height: 530px;
    padding: 40px 32px 16px 32px;

    &--horizontal {
      flex-direction: row;
      min-height: 372px;
    }
  }
  
  @include from('lg') {
    min-height: 530px;
    height: 530px;
    padding: 40px 32px 16px 32px;
  }

  @include from('xlg') {
    min-height: 530px;
  }

  &--card-large {
    min-height: 432px;
    padding: 24px 16px 16px 16px;
    flex-direction: column;
    width: 100%;

    @include from('xs') {
      min-height: 530px;
      padding: 40px 24px 16px 24px;
    }

    @include from('sm') {
      min-height: 584px;
      padding: 56px 40px 16px 40px;
    }

    @include from('md') {
      min-height: 530px;
      border-radius: 16px;
      padding: 40px 32px 16px 32px;
    }
    
    @include from('lg') {
      min-height: 584px;
      padding: 40px 32px 16px 32px;
    }

    @include from('xlg') {
      min-height: 584px;
      padding: 56px 48px 32px 48px;
    }
  }

  &__link-overlay {
    left: 0;
    top: 0;
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: z(cardLinkOverlay);
  }

  &__ribbon {
    position: absolute;
    top: 8px;
    right: 16px;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 12px;
    padding: 2px 4px;
    border-radius: 4px;
    color: color(white);
    z-index: z(moreForward);

    @include from('xs') {
      top: 16px;
    }
    
    @include from('sm') {
      font-size: 12px;
      line-height: 18px;
      padding: 2px 8px;
    }

    &--red {
      background-color: color(red);
    }
    
    &--orange {
      background-color: color(orange);
    }

    &--blue {
      background-color: color(blue);
    }

    &--transparent {
      background-color: rgba(color(grey70), 0.5);
    }

    &--purple {
      background-color: color(corePurple);
    }
  }
  
  &__description-content {
    z-index: z(moreForward);
    flex: 0 0 50%;
    margin-bottom: -30px;
    
    @include from('sm') {
      flex: 0 0 auto;
    }
    
    .magnolia-card--card-large & {
      flex: 0 1 auto;
    }

    &--horizontal {
      flex: 0 0 65%;

      @include from('xs') {
        flex: 0 0 50%;
      }
    }
  }

  &__description {
    color: color(grey40);
    display: flex;
    font-size: 11px;
    font-weight: bold;
    
    @include from('xs') {
      font-size: 14px;
    }

    @include from('xlg') {
      font-size: 16px;
    }

    .magnolia-card--card-large & {
      font-size: 14px;
      line-height: 24px;

      @include from('sm') {
        font-size: 18px;
        line-height: 28px;
      }

      @include from('md') {
        font-size: 14px;
        line-height: 26px;
      }

      @include from('xlg') {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }

  &__title {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin: 4px 0 0;

    @include from('xs') {
      font-size: 16px;
      line-height: 24px;
    }

    @include from('sm') {
      font-size: 20px;
      line-height: 28px;
    }

    @include from('xlg') {
      font-size: 24px;
      line-height: 32px;
    }

    .magnolia-card--card-large & {
      font-size: 16px;
      line-height: 24px;

      @include from('xs') {
        font-size: 20px;
        line-height: 28px;
      }

      @include from('sm') {
        font-size: 40px;
        line-height: 48px;
      }

      @include from('md') {
        font-size: 20px;
        line-height: 28px;
      }

      @include from('lg') {
        font-size: 40px;
        line-height: 48px;
      }
    }
  }

  &__product-number {
    font-size: 14px;
    line-height: 20px;
    color: color(grey40);
    margin-top: 4px;
  }

  &__image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;
    max-width: 100%;

    &--horizontal {
      flex: 0 0 35%;

      @include from('xs') {
        flex: 0 0 50%;
      }
    }

    &--corner {
      position: absolute;
      right: 16px;
      width: 40%;
      margin-bottom: 0;
      justify-content: unset;

      @include from('xs') {
        right: 40px;
      }

      @include from('sm') {
        right: auto;
        top: 45%;
        left: 35%;
        width: 75%;
        margin-bottom: 25px;
      
        &.magnolia-card__image-container--horizontal {
          width: 30%;
          bottom: -70px;
          left: auto;
          right: 50px;
          margin-bottom: 0;
        }
      }

      @include from('md') {
        top: 30%;
      }

      .magnolia-card--card-large & {
        bottom: auto;
        top: 25%;
        width: 65%;
        right: 0;
        margin-bottom: 0;

        @include from('sm') {
          bottom: auto;
          top: 35%;
        }
      }
    }
  }

  &__image {
    width: 96px;
    max-width: 100%;
    max-height: 100%;
    background-size: cover;
    background-position: center;
    align-self: center;

    @include from('xs') {
      width: 160px;
    }

    @include from('sm') {
      width: 190px;
    }

    @include from('md') {
      width: 290px;
    }

    .magnolia-card--card-large & {
      margin-bottom: unset;
      flex: 0 1 auto;
      width: 238px;

      @include from('xs') {
        width: 290px;
      }

      @include from('sm') {
        width: 290px;
      }

      @include from('md') {
        width: 290px;
      }

      @include from('lg') {
        width: 290px;
      }

      @include from('xlg') {
        width: 290px;
      }
    }

    &--corner {
      margin: 0;
      width: auto;
      max-width: 180px;
      max-height: 180px;

      @include from('xs') {
        max-width: 240px;
        max-height: 240px;
      }

      @include from('sm') {
        max-width: 300px;
        max-height: 300px;
      }

      @include from('md') {
        max-width: 380px;
        max-height: 380px;
      }

      @include from('lg') {
        max-width: 420px;
        max-height: 420px;
      }

      &.magnolia-card__image--horizontal {
        left: auto;
        right: 0;
        max-height: 100%;
        width: auto;
      }

      .magnolia-card--card-large & {
        width: auto;
        max-width: 420px;
        max-height: 420px;

        @include from('xs') {
          max-width: 638px;
          max-height: 638px;
        }

        @include from('md') {
          max-width: 420px;
          max-height: 420px;
        }

        @include from('lg') {
          max-width: 480px;
          max-height: 480px;
        }

        @include from('xlg') {
          max-width: 638px;
          max-height: 638px;
        }
      }
    }
  }

  &__footer-content {
    @include teliaSansHeading;
    position: absolute;
    z-index: z(moreForward);
    left: 16px;
    right: 0;
    bottom: 8px;

    @include from('xs') {
      bottom: 16px;
      left: 24px;
    }

    @include from('sm') {
      left: 40px;
    }

    @include from('md') {
      left: 32px;
    }

    .magnolia-card--card-large & {
      bottom: 16px;

      @include from('xlg') {
        left: 48px;
        bottom: 32px;
      }
    }
  }

  &__price {
    font-size: 20px;
    line-height: 20px;
    
    @include from('sm') {
      font-size: 32px;
      line-height: 23px;
    }
  }

  &__price-unit {
    font-size: 12px;
    line-height: 18px;
    margin-left: -4px;

    @include from('sm') {
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__price-crossed {
    font-size: 12px;
    line-height: 18px;
    text-decoration: line-through;
    color: color(grey40);
    margin-left: 6px;

    @include from('sm') {
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__first-footer-description {
    display: block;
    font-size: 14px;
    font-weight: bold;
    line-height: 26px;
  }

  &__second-footer-description {
    @include teliaSans;
    color: color(grey40);
    font-size: 12px;
    line-height: 18px;
  }
}
