.overlay-container {
  $small: 400px;
  $medium: 620px;
  $large: 1064px;

  &__title {
    h4 {
      margin: 0;
    }

    &--white {
      color: color(white);
    }

    &--black {
      color: color(black);
    }

    &--deepPurple {
      color: color(deepPurple);
    }

    &--deepBeige {
      color: color(deepBeige);
    }

    &--beige {
      color: color(beige);
    }

    &--lightPurple {
      color: color(lightPurple);
    }

    &--lightBeige {
      color: color(lightBeige);
    }
  }

  &__smoke {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: z(sameAsChat);
    display: none;
    background-color: rgba(color(black), 0.5);
    height: 100%;
    -webkit-overflow-scrolling: touch;

    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar { width: 0 !important }
    overflow: -moz-scrollbars-none;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .overlay-container--sliding & {
      pointer-events: none;
      display: block;
      background-color: transparent;
      transition: background-color 0.2s;
    }

    .overlay-container--sliding.overlay-container--edit-mode & {
      background-color: rgba(color(black), 0.5);
      pointer-events: auto;
     }

    @include from('sm') {
      padding: 0 30px;
    }
  }

  &--open &__smoke {
    display: block;
  }
  
  &--open.overlay-container--sliding &__smoke {
    background-color: rgba(color(black), 0.5);
    pointer-events: auto;
    z-index: z(moreThanChat);
  }

  &__modal {
    z-index: z(modalBox);
    position: relative;
    padding: 20px 20px;
    box-shadow: rgba(color(black), 0.3) 1px 1px 4px;

    &--white {
      background-color: color(white);
    }

    &--grey10 {
      background-color: color(grey10);
    }

    &--grey20 {
      background-color: color(grey20);
    }

    &--grey100 {
      background-color: color(grey100);
    }

    &--black {
      background-color: color(black);
    }

    &--deepPurple {
      background-color: color(deepPurple);
    }

    // Deep beige background deprecated, only here for backwards compatibility
    // Brand guidelines say that we shouldn't use this as a background color
    &--deepBeige {
      background-color: color(deepBeige);
    }

    &--beige {
      background-color: color(beige);
    }

    &--lightPurple {
      background-color: color(lightPurple);
    }

    &--lightBeige {
      background-color: color(lightBeige);
    }

    @include from('sm') {
      margin: 10% auto;
      border-radius: 8px;
      padding: 24px 24px;

      &--small {
        max-width: $small;
      }

      &--medium {
        max-width: $medium;
      }

      &--large {
        max-width: $large;
      }
    }

    .overlay-container--sliding & {
      position: absolute;
      transition: right 0.7s;
      transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
      width: 100%;
      right: calc(-1 * (100% + 41px));
      display: flex;
      flex-direction: row;
      box-shadow: none;

      @include from('sm') {
        border-radius: 8px 0 0 8px;
  
        &--small {
          right: calc(-1 * (#{$small} + 41px));
        }
  
        &--medium {
          right: calc(-1 * (#{$medium} + 41px));
        }
  
        &--large {
          right: calc(-1 * (#{$large} + 41px));
        }
      }

      & .overlay-container__content {
        width: 100%;
      }
    }

    .overlay-container--sliding.overlay-container--edit-mode & {
      position: relative;
      right: auto;
    }

    .overlay-container--sliding.overlay-container--open & {
      right: 0 !important;
      box-shadow: rgba(color(black), 0.3) 1px 1px 4px;
      //TODO: slide button getting hidden when browser width close to modal
    }

    .image-element {
      //fix IE11 bug where modal does not collapse correctly
      min-height: 1px;
    }
  }

  &__sliding-button-container {
    width: 0px; // Prevent sliding button from using space after transforming
    margin-top: 20vh; // TODO: if modal is short, long button text can make the modal stretch.
  }

  &__sliding-button-container-custom {

    &-5{
      @media (min-width:600px) {
        margin-top: 5px !important
      }
    }

    &-10{
      @media (min-width:600px) {
        margin-top: 10px !important
      }
    }

    &-15{
      @media (min-width:600px) {
        margin-top: 15px !important
      }
    }

    &-20{
      @media (min-width:600px) {
        margin-top: 20px !important
      }
    }

    &-25{
      @media (min-width:600px) {
        margin-top: 25px !important
      }
    }

    &-30{
      @media (min-width:600px) {
        margin-top: 30px !important
      }
    }

    &-35{
      @media (min-width:600px) {
        margin-top: 35px !important
      }
    }

    &-40{
      @media (min-width:600px) {
        margin-top: 40px !important
      }
    }

    &-45{
      margin-top: 20vh !important;
      @media (min-width:600px) {
        margin-top: 45px !important
      }
    }

    &-50{
      @media (min-width:600px) {
        margin-top: 50px !important
      }
    }

  }

  &__sliding-button {
    white-space: nowrap;
    border-radius: 16px 0 0 16px;
    text-transform: uppercase;
    font-size: 12px;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: translateX(-56px);
    pointer-events: auto !important;
    cursor: pointer;
    display: inline; // Button will have 100% height without this on IE
    width: 36px; // This has effect even when inline because the writing mode is vertical
    box-shadow: shadow('md');

    .overlay-container--sliding.overlay-container--open & {
      box-shadow: none;
    }

    @include from('sm') {
      border-radius: 14px 0 0 14px;
      transform: translateX(-72px);
      width: 48px;

      .overlay-container--sliding.overlay-container--open & {
        width: 40px;
        transform: translateX(-64px);
      }
    }

    &--purple5 {
      background-color: #9B009B;
      color: color(white);
    }

    &--darkPink {
      background-color: #D22DB9;
      color: color(white);
    }

    &--purple {
      background-color: color(corePurple);
      color: color(white);
    }

    &--black {
      background-color: color(black);
      color: color(white);
    }

    &--grey30 {
      background-color: color(grey30);
      color: color(black);
    }

    &--grey40 {
      background-color: color(grey40);
      color: color(black);
    }

    &--grey50 {
      background-color: color(grey50);
      color: color(white);
    }

    &--grey70 {
      background-color: color(grey70);
      color: color(white);
    }

    .overlay-container__slide-open, .overlay-container__slide-close {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      // outline: inherit;
      color: inherit;
      padding: 15px;
      padding-right: 8px;
      align-items: center;
      font-weight: 500;

      > svg {
        font-size: 16px;
      }

      @include from('sm') {
        padding: 15px;
      }
    }

    .overlay-container__slide-open {
      display: inline-flex;
    }

    .overlay-container__slide-close {
      display: none;
    }

    .overlay-container--sliding.overlay-container--open & {
      .overlay-container__slide-open {
        display: none;
      }

      .overlay-container__slide-close {
        display: inline-flex;
        padding: 15px 12px;
      }
    }
  }

  &__slide-button-text {
    display: none;

    @include from('sm') {
      margin-top: 8px;
      line-height: 0;
      display: inline;
    }
  }

  &__content-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__content {
    &--extra-vertical-padding {
      &-none {
        padding: 0;
      }

      &-small {
        padding: 16px 0;
      }

      &-medium {
        padding: 24px 0;
      }

      &-large {
        padding: 32px 0;
      }
    }
  }

  &__close-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -13px;
    margin-bottom: 5px;
  }

  &__close-button {
    background: none;
    border: none;
    
    z-index: z(modalInput);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    color: color(grey40);
    padding: 13px;
    margin-right: -13px;

    @include from('sm') {
      font-size: 21px;
    }

    &:hover {
      color: color(black);
    }
  }
}
