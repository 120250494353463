.unique-selling-point {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-self: center;
  max-width: 100%;

  &--grey {
    .unique-selling-point__link-container, .unique-selling-point__text-container {
      color: color(grey70);
    }
  }

  &--black {
    .unique-selling-point__link-container, .unique-selling-point__text-container {
      color: color(black);
    }
  }

  @supports (display: grid) {
    @include from('sm') {
      display: grid;
      $twoInARow: calc(50% - 35px);
      grid-template-columns: repeat(auto-fill, minmax($twoInARow, 1fr));
    }

    @include from('md') {
      $threeInARow: calc(33% - 35px);
      grid-template-columns: repeat(auto-fill, minmax($threeInARow, 1fr));
    }
  }

  /* This will match the Edge browser */
  /* Some versions of Edge think they support grid but they actually don't */
  @supports (-ms-ime-align:auto) {
    display: flex;
  }

  margin-top: 1.1em;
  margin-bottom: 1.1em;

  @include from('sm') {
    align-self: auto;
    flex-direction: row;
    margin-top: 2.5em;
    margin-bottom: 2.5em;
  }

  @include from('md') {
    margin-top: 3.5em;
    margin-bottom: 3.5em;
  }

  &--no-margin {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  &__item {
    margin: 5px 0;

    @include from('sm') {
      $twoInARow: calc(50% - 35px);
      flex-basis: $twoInARow;
      margin: 10px 15px;
    }

    @include from('md') {
      $threeInARow: calc(33% - 35px);
      flex-basis: $threeInARow;
    }
  }
  
  &__icon {
    display: flex;
    align-items: center;

    .icon {
      width: 28px;
      height: 28px;
      min-width: 28px;

      @include from('sm') {
        width: 35px;
        height: 35px;
        min-width: 35px;
      }
    }
  }

  &__image-container {
    display: flex;
    align-items: center;
    height: 28px;

    @include from('sm') {
      height: 35px;
    }
  }

  &__image {
    width: 28px;
    min-width: 28px;

    @include from('sm') {
      width: 35px;
      min-width: 35px;
    }
  }

  &__title {
    @include teliaSans;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    margin: 0;
    margin-left: 10px;

    @include from('md') {
      line-height: 18px;
      font-size: 15px;
      margin-left: 15px;
    }
  }

  &__text {
    font-size: 12px;
    line-height: 17px;
    margin-left: 10px;

    @include from('md') {
      line-height: 18px;
      font-size: 13px;
      margin-left: 15px;
    }

    .unique-selling-point__title + & {
      margin-top: 3px;
    }
  }

  &__link-container {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover, &:active {
      color: hover(corePurple);
    }
  }

  &__text-container {
    display: flex;
    align-items: center;
  }
}
