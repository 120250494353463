.ssl-check {
  background-image: url('../assets/images/components/ssl-check/chrome-test-background.jpg');
  background-size: cover;
  min-height: 474px;

  p {
    color: white;
  }

  &__header {
    color: color(white);
    margin-bottom: 0;

    &--hidden {
      display: none;
    }
  }

  &__intro {
    margin-bottom: 8px;
  }

  &__form-part {
    position: relative;

    &--email {
      display: none;
    }
  }

  &__error {
    display: none;
    color: color(red) !important;
  }

  &__result {
    display: none;
    margin-top: 24px;

     &-not-secure, &-secure {
       display: none;
     }
  }

  &__result-secure-image {
    background: url(../assets/images/components/ssl-check/chrome_secure_site.png) 0 0 no-repeat;
    height: 75px;
    display: inline-block;
    max-width: 100%;
  }

  &__result-not-secure-image {
    background: url(../assets/images/components/ssl-check/chrome_not_secure_site.png) 0 0 no-repeat;
    height: 75px;
    display: inline-block;
    max-width: 100%;
  }

  &__result-value {
    margin: 43px 169px 0 169px;

    &--not-secure {
      margin: 43px 195px 0 195px;
    }
  }

  &__contact-link {
    padding: 0 0 1em;
  }

  &__link a {
    height: 22px;

    &:link, &:visited {
      color: color(white);
    }

    &:hover, &:active {
      color: hover(white);
    }
  }

  &__no-thanks {
    display: none;
    font-weight: bold;
  }

  &__download-guide-buttons {
    margin: 1em 0;
  }
}
