.accordionlist {
  margin-bottom: 1em;

  &__content {
    max-height: 0;
    padding: 0 40px;
    transition: max-height 0.4s, padding-top 0.4s, padding-bottom 0.4s, opacity 0.4s ease;
    overflow: hidden;
    visibility: hidden;  // Initially hidden
    opacity: 0;          // Initially invisible
    pointer-events: none; // Not interactable

    &.edit-mode {
      max-height: none;
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }
  }
  

  &__content-wrapper > * {
    overflow: auto;
  }

  &__content > &__content-wrapper > .columnrow {
    margin-top: 0px;
    overflow: visible;
  }

  &__item {
    float: none;
    border-radius: 16px;
    box-shadow: 0 6px 24px 0px rgba(color(black), 0.12);
    margin: 16px 0;
    min-height: 88px;

    &--lightGrey {
      background-color: color(grey20);
    }

    &--white {
      background-color: color(white);
    }
  }

  &__heading {
    position: relative;
    font-size: 18px;
    line-height: 28px;
    padding: 30px 64px 24px 40px;
    cursor: pointer;
    font-weight: 500;
    width: 100%;
    overflow-wrap: break-word;
    background-color: transparent; 
    border: none;
    text-align: left;

    &--center {
      text-align: center;
    }

    &--purple {
      color: color(corePurple);
    }

    &--grey {
      color: color(grey100);
    }

    &--black {
      color: color(black);
    }
  }

  &__icon {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    bottom: 0;
    right: 48px;
    margin: auto 0;
    color: color(corePurple);
    transition: transform 0.2s;
  }

  &__item--open {
    .accordionlist__icon {
      transform: rotate(180deg);
    }
  }

  &-inline {
    .accordionlist__content {
      max-height: initial;
    }
  }
}
