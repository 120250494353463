.notification {
  @include teliaSans;
  font-weight: bold;
  font-size: 16px;
  color: color(white);
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  z-index: z(notification);
  max-height: 180px;
  transition: max-height 0.6s ease-in-out 0.3s;
  background-color: color(blue);

  &--deleted {
    max-height: 0;
  }

  &__container {
    display: flex;
    flex-direction: row;
    min-height: 40px;
    width: 100%;
    padding-top: 0px;
    padding-bottom: 0px;

    .columnrow & {
      padding: 0 2em;
    }

    .notification--textbox-border & {
      padding: 0 1em;

      @include from('md') {
        padding: 0 2em;
      }
    }
  }

  &__text-content a {
    z-index: z(notification);
  }

  p {
    font-size: 14px;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    max-width: none;
    flex-grow: 1;
    justify-content: space-between;

    a {
      margin-right: -12px;
    }
  }

  &__text {
    display: inline-block; // IE 9
    display: flex;
    margin: 8px 0 8px 16px;
    font-size: 14px;
    line-height: 20px;
    align-self: center;
    align-items: baseline;
    justify-content: space-between;
    flex-grow: 1;
    flex-wrap: nowrap;

    .no-flexbox & {
      width: 90%;
      text-align: left;
    }

    &--textbox-style {
      max-width: none;
      line-height: 24px;

      .notification__text-content {
        padding-right: 0;
      }
    }

    a {
      display: inline-block;
      color: inherit;
      margin-top: 0;
      padding-top: 0;
      flex: 0 0 auto;
      &:hover{
        text-decoration: underline;
      }
    }

    &-content {
      display: inline-block;
      margin-right: 16px;
      max-width: none;
      flex-basis: 100%;

      &--padding-none {
        padding-right: 0;

        p {
          max-width: none;
        }
      }
    }
  }

  &__icon {
    display: inline-block; // IE9
    display: flex;
    align-items: flex-start;
    align-self: flex-start;
    padding: 10px 0;
    font-size: 20px;

    .no-flexbox & {
      max-width: 2.5%;
    }
    //
  }

  .icon {
    display: inline-block;
    vertical-align: text-bottom;

    &--chevron-right {
      font-size: 10px;
      vertical-align: baseline;
    }

    &--icon-checkmark {
      fill: white;
    }

    &--close {
      font-size: 14px;
    }
  }

  &__link {
    display: inline-block;

    &:link,
    &:visited,
    &:active,
    &:hover {
      color: inherit;
    }

    &--grey {
      color: color(grey70) !important;

      &:link,
      &:visited {
        color: color(grey70);
      }

      &:active,
      &:hover {
        color: hover(grey70);
      }
    }
    &--white {
      color: color(white) !important;

      &:link,
      &:visited {
        color: color(white);
      }

      &:active,
      &:hover {
        color: hover(white);
      }
    }
    &--hidden {
      visibility: hidden;
    }
  }

  &__delete {
    display: flex;
    flex-shrink: 0;
    cursor: pointer;
    align-self: flex-start;
    margin-left: auto;
    padding: 8px 0;
    line-height: 20px;
    > button {
      // Styles for <button> elements
      background: none;
      border: none;
      padding:2px 3px; 
      font: inherit;
      cursor: pointer;
      outline: none; 
      border-radius: 3px;
      margin-bottom: 3px;


      // Style the focus outline
      &:hover, &:focus {
        box-shadow: 0 0 0 2px color(white);
      }

      // Exclude SVG icon container from color change
      & svg {
        fill: white; // Get original color back for SVG icon
      }

    }
    // IE 9
    float: right;

    @include from('md') {
      margin-left: 24px;
    }

    @include from('xlg') {
      margin-left: 64px;
    }

    .no-flexbox & {
      max-width: 2.5%;
      text-align: right;
      vertical-align: middle;
    }

    &--textbox-margin {
      align-self: flex-start;
      line-height: 1;
    }
  }

  &--grey {
    color: color(grey70);
  }

  &--blue {
    background-color: color(blue);
  }

  &--blue:hover {
    background-color: hover(blue);
  }

  &--green {
    background-color: color(darkGreen);
  }

  &--green:hover {
    background-color: hover(darkGreen);
  }

  &--red {
    background-color: color(red);
  }

  &--red:hover {
    background-color: hover(red);
  }

  &--purple {
    background-color: color(corePurple);
  }

  &--purple:hover {
    background-color: hover(corePurple);
  }

  &--grey {
    background-color: color(grey30);
  }

  &--grey:hover {
    background-color: hover(grey20);
  }

  &--textbox,
  &--textbox-border {
    color: color(grey200);
    margin: 20px 0;
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    .icon {
      fill: color(blue);

      &--chevron-right {
        fill: color(corePurple);
      }

      &--close {
        fill: color(grey200);
      }
    }

    .notification {
      &__icon {
        display: inline-block;
        align-self: flex-start;
        line-height: 1;
        padding: 12px 0;
        font-size: 24px;
      }

      &__text {
        margin-top: 11px;
        margin-bottom: 11px;
      }

      &__link {
        &:link,
        &:visited {
          color: color(corePurple);
        }

        &:active,
        &:hover {
          color: hover(corePurple);
        }
      }
    }
  }

  &--textbox-border {
    border: solid 4px;
    border-color: color(blue);
    transition: max-height 0.3s;

    &.notification--deleted {
      border-bottom-width: 0;
      border-top-width: 0;
      margin: 0;
    }
  }

  &--textbox-border.notification--blue {
    border-color: color(blue);
  }

  &--textbox-border.notification--green {
    border-color: color(darkGreen);
  }

  &--textbox-border.notification--red {
    border-color: color(red);
  }

  &--textbox-border.notification--purple {
    border-color: color(corePurple);
  }

  &--textbox-border.notification--grey {
    border-color: color(grey30);
  }

  &--textbox.notification--red {
    .notification__icon .icon {
      fill: color(red);
    }
  }

  &--textbox.notification--green {
    .notification__icon .icon {
      fill: color(darkGreen);
    }
  }

  &--textbox.notification--purple {
    .notification__icon .icon {
      fill: color(corePurple);
    }
  }

  &--textbox.notification--grey {
    .notification__icon .icon {
      fill: color(grey30);
    }
  }

  &--hide-close &__delete {
    display: none;
  }
}

