@import '../config.scss';

html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong,
var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, article, aside,
canvas, details, figcaption, figure, footer, header, hgroup, menu, nav, section,
summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  @include teliaSans;
  font-size: $fontSize;
  line-height: $lineHeight;
  font-weight: normal;
  letter-spacing: normal;
  background: color(white);

  &.body--grey {
    background-color: color(grey20);
  }

  &.body--deepPurple {
    background-color: color(deepPurple);
  }

  &.body--lightPurple {
    background-color: color(lightPurple);
  }

  &.body--deepBeige {
    background-color: color(deepBeige);
  }

  &.body--lightBeige {
    background-color: color(lightBeige);
  }

}

body, html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.no-scroll, html.no-scroll {
  overflow: hidden;
  position: fixed;
  height: 100%;
}
