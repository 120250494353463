.content-central-category-filters {

  &__description {
    @include teliaSans;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 16px;
    margin-bottom: 10px;
    text-transform: uppercase;
    margin-top: 2em;
  }

  &__container {
    background: color(grey20);
    margin-top: 16px;
  }

  &__categories {
    display: flex;
    flex-flow: row wrap;
    padding: 40px 0;

    @include to('sm') {
      padding: 16px 0;
    }
  }

  &__category {
    margin: 4px 6px 4px 0;
    padding: 6px 16px 6px 11px;
    text-align: center;
    border-radius: 22px;
    background-color: color(white);
    font-size: 14px;

    @include to('sm') {
      height: 30px;
      line-height: 30px;
      padding: 0 15px;
    }

    &:link, &:visited {
      color: color(black);
    }

    &:hover, &--selected {
      background-color: color(corePurple);

      &:link, &:visited, .content-central-category-filters__article-count {
        color: color(white);
      }
    }
  }

  &__article-count {
    color: color(grey50);
  }

  &__content {
    margin-top: 55px;
  }

  &__empty-padding {
    padding: 0px;
  }

  .image-hero__texts--button,
  .image-hero__texts--anchor-button {
    margin: 12px 0 0;

    @include from('md') {
      margin-top: 16px;
    }

    @include from('xxlg') {
      margin-top: 26px;
    }

    .button {
      margin-left: 0;
    }
  }
}
