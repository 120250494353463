$darkThemeColor: color(white);

.liiga-hero {
  position: relative;
  padding-top: 25px;
  padding-bottom: 40px;
  color: color(grey100);

  @include from('sm') {
    padding: 75px 0;
  }

  &--dark {
    color: $darkThemeColor;
  }

  &__background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    &--mobile {
      @include from('sm') {
        display: none;
        background: none !important; // Don't download image on wrong breakpoint
      }
    }

    &--desktop {
      @include to('xs') {
        display: none;
        background: none !important; // Don't download image on wrong breakpoint
      }
    }
  }

  &__pre-title {
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;

    @include from('sm') {
      margin-bottom: 8px;
    }
  }

  &__title-container {
    font-size: 1.1em;

    @include from('sm') {
      margin-bottom: 21px;
    }
  }

  &__title {
    .liiga-hero--dark & {
      color: $darkThemeColor;
    }
  }

  &__price {
    line-height: 48px;
    margin-bottom: 16px;
    font-size: 24px;

    @include from('sm') {
      margin-bottom: 28px;
      font-size: 32px;
    }


    &-prefix {
      font-weight: bold;
      font-size: 1em;
    }

    &-value {
      @include teliaSansHeading;
      font-size: 1.5em;
    }

    &-unit {
      @include teliaSansHeading;
      font-size: 1em;
    }
  }

  &__button {
    margin-bottom: 10px;
    align-self: center;

    @include from('sm') {
      align-self: flex-start;
    }

    .button {
      margin-left: 0;
    }
  }

  &__description {
    line-height: 28px;
    max-width: 294px;
  }

  &__feature-image {
    display: none;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

    @include from('sm') {
      display: block;
    }

    &--mobile {
      display: block;
      height: auto;
      width: 70%;
      margin-bottom: 16px;
      align-self: center;
      min-height: 1px; /* IE 11 image in flex fix. Without this, the image would stretch vertically */

      @include from('sm') {
        display: none;
      }
    }
  }
}
