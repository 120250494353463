.prepaid-price-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 70vh;
  overflow-y: auto;

  &--column-title {
    @include to('sm') {
      align-items: center;
    }
  }

  &__row-title {
    flex-shrink: 0;
    display: flex;
    background-color: color(white);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    margin-bottom: 2px;
    @include from('sm') {
      display: none;
    }
  }

  &__price-row {
    flex-shrink: 0;
    display: flex;
    background-color: color(white);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-right: 10px;
    min-width: 100%;
    margin: 0 0 5px 0;
    padding: 1em 0.5em 1em 1em;
    &:first-child {
      width: 7em;
      border-radius: 10px;    
      position: sticky;
      top: 0;
    }
    @include from('sm') {
      padding: 1em;
      border-radius: 10px;
      align-items: center;
      min-height: 60px;
      &:first-child {
        position: static;
      }
    }
  }
  
  &__first-column {
    width: 12em;
    display: none;
    @include from('sm') {
      display: block;
      width: 40%;
    }
  }
  
  &__row-product {
    display: flex;
    align-items: center;
    padding: 0.5em;
    @include from('sm') {
      padding: 0;
    }
  }

  &__row-image {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    min-width: 32px;
    width: 32px;
    height: 32px;
  }

  &__text-column {
    margin: 0em 0em 0em 1em;
  }
  
  &__price-column {
    width: 50%;
    display: flex;
    flex-direction: column;

    @include from('sm') {
      width: 30%;
    }
  }

  &__price {
    display: flex;
  }

  &__price-value {
    @include teliaSansHeading; 
    font-size: 34px;
  }

  &__price-string {
    @include teliaSansHeading;
    font-size: 24px;

    @include from('sm') {
      font-size: 34px;
    }
  }

  &__price-unit {
    line-height: 14px;
    font-size: 12px;
    margin-bottom: -4px;
  }

  &__additional-unit {
    margin-top: 4px;
    font-size: 12px;
  }
  
  &__smallprint {
    font-size: 12px;
    color: color(grey50);
    margin-top: -2px;
  }
}
