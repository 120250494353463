.columnrow {
  &__anchor {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
  }

  > .media-container {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
  }

  &--justify-center {
    [class*="grid__col-"] {
      text-align: center;
    }

    p, ol, ul {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &:last-child:not(&--no-footer-margin) {
    padding-bottom: 60px;

    .grid & {
      // Disable for nested grids
      padding-bottom: 0;
    }
  }

  &--justify-end {
    text-align: right;
  }

  &--rounded {
    > .grid > [class*="grid__col-"] {
      border-radius: 16px;
    }
  }

  &--clip-column-background {
    > .grid > [class*="grid__col-"] {
      background-clip: content-box;
    }
  }

  &--separate-vertically {
    .grid {
      > [class*="grid__col-"] {
        margin-bottom: 12px;
    
        @include from('sm') {
          margin-bottom: 16px;
        }
      
        @include from('lg') {
          margin-bottom: 24px;
        }
      }
    }
  }

  &--white {
    background-color: color(white);
  }

  &--lightGrey {
    background-color: color(grey20);
  }

  &--darkGrey {
    background-color: color(grey100);
  }

  &--black {
    background-color: color(black);
  }

  &--deepPurple {
    background-color: color(deepPurple);
  }

  &--lightPurple {
    background-color: color(lightPurple);
  }

  &--deepBeige {
    background-color: color(deepBeige);
  }

  &--lightBeige {
    background-color: color(lightBeige);
  }

  &--morePadding {
    padding-top: 4em;
    padding-bottom: 4em;
  }

  &--media-container-as-bg {
    position: relative;
    overflow: hidden;
  }

  &__column {
    &--white {
      > .grid > [class*="grid__col-"] {
        background-color: color(white);
      }
    }

    &--lightGrey {
      > .grid > [class*="grid__col-"] {
        background-color: color(grey20);
      }
    }

    &--deepPurple {
      > .grid > [class*="grid__col-"] {
        background-color: color(deepPurple);
      }
    }

    &--lightPurple {
      > .grid > [class*="grid__col-"] {
        background-color: color(lightPurple);
      }
    }

    &--deepBeige {
      > .grid > [class*="grid__col-"] {
        background-color: color(deepBeige);
      }
    }

    &--lightBeige {
      > .grid > [class*="grid__col-"] {
        background-color: color(lightBeige);
      }
    }

    &--whiteBorder {
      > .grid > [class*="grid__col-"] {
        border: 10px solid color(white);
      }
    }

    &--lightGreyBorder {
      > .grid > [class*="grid__col-"] {
        border: 10px solid color(grey20);
      }
    }

    &--deepPurpleBorder {
      > .grid > [class*="grid__col-"] {
        border: 10px solid color(deepPurple);
      }
    }

    &--lightPurpleBorder {
      > .grid > [class*="grid__col-"] {
        border: 10px solid color(lightPurple);
      }
    }

    &--deepBeigeBorder {
      > .grid > [class*="grid__col-"] {
        border: 10px solid color(deepBeige);
      }
    }

    &--lightBeigeBorder {
      > .grid > [class*="grid__col-"] {
        border: 10px solid color(lightBeige);
      }
    }
  }

  .grid & {
    margin: 60px 0;
  }

  &__no-margins {
    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }

  &-padding,
  &__column-padding {
    &--top-small {
      padding-top: 16px;
    }
    &--top-medium {
      padding-top: 32px;
    }
    &--top-large {
      padding-top: 48px;
    }

    &--bottom-small {
      padding-bottom: 16px;
    }
    &--bottom-medium {
      padding-bottom: 32px;
    }
    &--bottom-large {
      padding-bottom: 48px;
    }

    &--horizontal-small {
      padding-left: 16px;
      padding-right: 16px;
    }
    &--horizontal-medium {
      padding-left: 32px;
      padding-right: 32px;
    }
    &--horizontal-large {
      padding-left: 48px;
      padding-right: 48px;
    }
  }
}
