.cms-flat-product-card {
  position: relative;
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
  border-radius: 8px;
  background-color: color(white);
  box-shadow: 0 2px 8px 0 rgba(color(grey50), 0.5);
  padding: 14px 20px;
  margin-bottom: 16px;

  &--mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 118px;
    
    @include from('md') {
      display: none;
    }

    &:nth-last-child(2) {
      margin-bottom: 0;
    }
  }
  
  &--desktop {
    display: none;
    
    @include from('md') {
      min-height: 90px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__mobile-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 90px;
  }

  &__mobile-image-and-arrow-container {
    display: flex;
    align-items: center;
  }

  &__desktop-content-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__title-and-tag-container {
    display: flex;
    align-items: flex-start;
  }

  &__title {
    font-size: 14px;
    color: color(grey200);
    font-weight: 500;
    line-height: 20px;

    @include from('md') {
      font-size: 20px;
    }
  }

  &__tag {
    padding: 3px 4px;
    border-radius: 4px;
    font-size: 9px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 10px;
    color: color(white);
    text-transform: uppercase;
    white-space: nowrap;

    &--red {
      background-color: color(red);
    }

    &--orange {
      background-color: color(orange);
    }

    &--blue {
      background-color: color(blue);
    }

    &--transparent {
      background-color: rgba(color(grey70), 0.5);
    }

    &--purple {
      background-color: color(corePurple);
    }

    @include from('md') {
      padding: 4px 5px;
      margin-left: 16px;
      margin-top: 1px;
      font-size: 10px;
    }

    .cms-flat-product-card--mobile & {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
  
  &__description {
    font-size: 12px;
    color: color(grey50);
    font-weight: 500;
    line-height: 16px;
    margin-top: 2px;
    
    @include from('md') {
      line-height: 16px;
      margin-top: 4px;
    }

    &--hidden-in-mobile {
      display: none;

      @include from('sm') {
        display: block;
      }
    }
  }

  &__price-and-description-container {
    margin-top: 18px;

    @include from('md') {
      padding-left: 20px;
      margin-top: 0;
      width: 1%;
      min-width: 200px;
    }
  }

  &__price {
    @include teliaSansHeading;
    color: color(grey200);

    &-prefix {
      font-size: 12px;

      @include from('md') {
        font-size: 16px;
      }
    }

    &-value {
      font-size: 20px;

      @include from('md') {
        font-size: 24px;
      }
    }

    &-unit {
      font-size: 12px;

      @include from('md') {
        font-size: 16px;
      }
    }
  }

  &__price-description {
    color: color(grey50);
    font-size: 11px;
    line-height: 12px;
    font-weight: 500;
    margin-top: 2px;

    @include from('md') {
      font-size: 12px;
      line-height: 14px;
    }
  }

  &__image-and-icon-container {
    display: flex;
    align-items: center;
    padding-left: 10px;
    line-height: 0;

    @include from('md') {
      padding-left: 0;
      margin-right: 22px;
    }
  }

  &__image {
    img {
      width: 48px;
    }
  }

  &__icon {
    .icon {
      color: color(black);
      width: 48px;
      height: 48px;
    }
  }

  &__arrow {
    margin-left: 16px;
    line-height: 0;

    .icon {
      color: color(corePurple);
      height: 26px;
    }
  }
}
