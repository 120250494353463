.helppi-service-form {
  display: none;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;

  &__title {
    @include teliaSansHeading;
    font-size: 24px;
    text-align: center;
  }

  &__load-animation {
    align-items: center;
    background-color: rgba(color(white), .8);
    bottom: 0;
    display: flex;
    justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    transition: opacity .3s;
    z-index: z(animationOverHelppiModal);

    &--active {
      opacity: 1;
      pointer-events: auto;
    }
  }

  &__fail {
    display: none;

    &.active {
      display: block;
      padding: 10px;
      width: 100%;
    }
  }

  &__buttons-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
  }

  &__confirm-button-primary ,
  &__cancel-reserve-button-secondary ,
  &__cancel-button-secondary {
    display: none;
    font-size: 14px;
    font-weight: bold;
    padding: 10px 20px;
    width: 230px;
    word-spacing: normal;

    &.active {
      display: block;
    }
  }
}
