.animation--dots {
  display: inline-block;
  font-size: 10px;
  line-height: 18px;
  vertical-align: middle;

  svg {
    line-height: 1px;
    animation-name: blink;
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    /**
     * This makes sure that the starting style (opacity: .2)
     * of the animation is applied before the animation starts.
     * Otherwise we would see a short flash or would have
     * to set the default styling of the dots to the same
     * as the animation. Same applies for the ending styles.
     */
    animation-fill-mode: both;

    &:nth-child(2) {
      animation-delay: .2s;
    }
    &:nth-child(3) {
      animation-delay: .4s;
    }
  }
}

// New default animation with spinning pebble ring
.animation--loader {
  display: inline-block;
  font-size: 48px;
  position: relative;
  width: 1em;
  height: 1em;
  color: color(corePurple);

  span {
    font-size: 0.2em;
    transform-origin: 50% 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: calc(50% - 0.5em);
    line-height: 1px;

    &:nth-child(1) {
      transform: rotate(0deg);
      animation-delay: -1000ms;
    }
    &:nth-child(2) {
      transform: rotate(45deg);
      animation-delay: -875ms;
    }
    &:nth-child(3) {
      transform: rotate(90deg);
      animation-delay: -750ms;
    }
    &:nth-child(4) {
      transform: rotate(135deg);
      animation-delay: -625ms;
    }
    &:nth-child(5) {
      transform: rotate(180deg);
      animation-delay: -500ms;
    }
    &:nth-child(6) {
      transform: rotate(225deg);
      animation-delay: -375ms;
    }
    &:nth-child(7) {
      transform: rotate(270deg);
      animation-delay: -250ms;
    }
    &:nth-child(8) {
      transform: rotate(315deg);
      animation-delay: -125ms;
    }

    .icon {
      animation-delay: inherit;
      animation-name: pebbleSpinner;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
    }
  }
}

.animation--white {
  color: color(white);
}

@keyframes pebbleSpinner {
  0% {
    opacity: 0.2;
    transform: scale(0.4);
  }

  10% {
    opacity: 1;
    transform: scale(1.1);
  }

  20% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.2;
    transform: scale(0.4);
  }
}

@keyframes formFieldFlash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blink {
    /**
     * At the start of the animation the dot
     * has an opacity of .2
     */
    0% {
      opacity: .2;
    }
    /**
     * At 20% the dot is fully visible and
     * then fades out slowly
     */
    20% {
      opacity: 1;
    }
    /**
     * Until it reaches an opacity of .2 and
     * the animation can start again
     */
    100% {
      opacity: .2;
    }
}
