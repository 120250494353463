.linklist {
  color: color(black);
  padding: 1em 0;

  &--purple {
    color: color(corePurple);
  }

  &__list {
    border-top: 1px solid color(grey30);
    width: 100%;
  }

  &__label {
    border-top: 1px solid color(grey30);

    @include to('md') {
      padding-bottom: 1em;
    }
  }

  &__item {
    display: block;
    padding: 20px 32px 20px 0;
    border-bottom: 1px solid color(grey30);
    transition: color 0.2s;

    &:link, &:visited {
      color: color(black); 
    }

    &:hover {
      color: color(corePurple);
    }
    
    .linklist--purple & {
      &:link, &:visited {
        color: color(corePurple); 
      }

      &:hover {
        color: hover(corePurple);
      }
    }
  }

  &__icon {
    color: color(grey40);
    font-size: 14px;
    position: absolute;
    right: 20px;
    margin-top: 3px;
  }
}
