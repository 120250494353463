.text-card {
  border-radius: 16px;
  background-color: white;
  color: color(grey100);
  box-shadow: 0 3px 12px 5px rgba(color(grey30), 0.8);
  overflow: hidden;
  transition: transform ease 0.2s;
  flex-grow: 1;

  &--clickable {
    &:hover {
      color: color(grey100);
      transform: scale(1.02);
      z-index: z(moreForward);
    }
  }

  &__image-container {
    padding: 25px 25px 0 25px;
    height: 220px;
    background-repeat: no-repeat;
    background-size: cover;

    &--no-background {
      height: 150px;
    }

    &--background-centered {
      background-position: center;
    }

    &--background-contained {
      background-size: contain;
    }

    @include from('sm') {
      padding: 40px 40px 0 40px;
      height: 290px;

      &--no-background {
        height: 210px;
      }
    }
  }

  &__foreground-image {
    width: 100%;
    max-height: 100px;
    max-width: 100px;

    @include from('sm') {
      max-height: 150px;
      max-width: 150px;
    }
  }

  &__text-container {
    padding: 25px 25px;

    @include from('sm') {
      padding: 25px 40px;
    }
  }

  &__title {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 25px;

    @include from('sm') {
      font-size: 32px;
      line-height: 36px;
    }
  }

  &__text {
    text-overflow: ellipsis;
    overflow: hidden;

    // Override evil global element specific styles
    p {
      max-width: none;
      line-height: inherit;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    // Override button margin that causes the button to be croped it's wide enough
    .button {
      margin-left: 0;
    }
  }

  &__link {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 600;
    margin-top: 15px;
    color: color(corePurple);

    &:hover {
      color: hover(corePurple);
    }

    .icon {
      height: 10px;
    }
  }
}
