$base-size: 34px;

.price {
  height: 1em;
  display: inline-block;

  &:not(&--normal) + &--normal {
    margin-left: (12px / $base-size) * 1em;
  }

  &--normal {
    color: color(grey40);
    font-size: (24px / $base-size) * 1em;
    position:relative;
    &::after {
      content: "";
      position: absolute;
      top: 75%;
      left: 0;
      width: 100%;
      border-bottom: 1px solid color(grey40);
    }
  }

  &--out-of-stock {
    color: color(grey50);
  }

  &__amount {
    font-family: TeliaSansHeading, TeliaSans, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    line-height: 1em;
    letter-spacing: (-0.75px / $base-size) * 1em;
  }

  &__extra-info {
    font-size: (16px / $base-size) * 1em;
  }

  &__unit {
    font-size: (16px / $base-size) * 1em;
    margin-left: (-1px / $base-size) * 1em;
  }

  &__stacked-unit {
    display: inline-block;
    height: 2em;
    line-height: 1em;
    font-size: (14px / $base-size) * 1em;
    width: 1em;
    text-align: left;
    margin-left: (-1px / $base-size) * 1em;
  }

  &--dynamic:not(&--default) {
    display: none;
  }

  &--smallprint {
    font-size: 14px;
    color: color(grey50);
    margin-left: 0 !important;
    display: block;
    height: auto;
  }
}
