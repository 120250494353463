.cms-login-2022 {
  &__mfa-sms-number {
    line-height: 1;
    font-size: 18px;
  }

  &__phone-number-options {
    display: flex;
    flex-direction: column;
    border: 1px solid color(grey40);
    border-radius: 4px;
    overflow: hidden;
  }

  &__phone-number-option {
    background-color: color(grey10);
    border-bottom: 1px solid color(grey40);
    padding: 30px 20px;

    &:last-child {
      border-bottom: none;
    }

    label {
      margin: 0 !important;
      font-weight: bold !important;

      &::before {
        box-sizing: border-box;
      }
    }
  }

  &__resend-sms {
    p {
      line-height: 1;
      color: color(grey70);
    }
  }

  &__mfa-error {
    display: none;
    color: color(red);
    width: 100%;
    text-align: right;
    margin: 0;

    &--visible {
      display: block;
    }
  }

  &__mfa-sms-error {
    display: none;
    color: color(red);
    width: 100%;
    margin: 0;

    &--visible {
      display: block;
    }
  }

  &__otp-input-fields {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;

    @include from('sm') {
      gap: 8px;
    }
  }

  &__otp-help-text {
    margin-bottom: 16px;
  }

  &__otp-input {
    @include teliaSans;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    padding: 3px;
    text-align: center;
    height: 50px;
    width: 36px;

    @include from('sm') {
      padding: 4px;
      height: 56px;
      width: 40px;
    }

    &:focus {
      outline: solid 3px #40b3ff;
      outline-offset: 2px;

      &:not(:focus-visible) {
        outline: none;
      }
    }
  }

  &__otp-resent-info {
    font-weight: bold;
    display: none;
    font-size: 14px;

    &--visible {
      display: block;
    }

    @include from('md') {
      font-size: 16px;
    }
  }

  &__resend-otp {
    display: none;
    font-size: 14px;

    &--visible {
      display: inline;
    }

    @include from('md') {
      font-size: 16px;
    }
  }

  &__mfa-otp-loader {
    display: none;

    .animation--loader {
      font-size: 36px;
    }

    &--visible {
      display: block;
    }
  }

  &__page-loader {
    width: 100%;
    min-height: 100vh;
    margin: 30vh auto;
    text-align: center;

    img {
      height: 20vh;
    }
  }

  .flex {
    display: flex;
    gap: 0 0.5rem;
  }

  &__form-link {
    display: flex;
    margin-bottom: 12px;
  }

  &__form-link > span {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 12px;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5;
    color: #990ae3;
    pointer-events: none;
  }

  &__form-link > svg {
    font-size: 24px;
    margin-right: 8px;
    color: #222222;
  }

  &__display-none {
    display: none;
  }

  &__display-block {
    display: block;
  }

  &__page {
    padding: 2rem 1rem;
  }

  &__top-form-link {
    margin-top: 2rem;
  }

  &__form-button {
    margin-top: 1.75rem;

    .button {
      margin: 0;
      display: inline;
    }
  }

  &__error {
    overflow: hidden;
    max-height: 0;
    margin: 0;
    transition: max-height 0.2s ease-in, margin 0.2s ease-in;
    padding-left: 12px;

    &--credentials,
    &--b2btupas {
      @include from('md') {
        padding-left: 4px;
      }
    }

    &--visible {
      max-height: unset;
      margin: 2rem 0 1rem;
    }

    .notification {
      @include from('md') {
        max-width: 100%;
        margin: 0 auto;

        .grid {
          margin: 0 !important;
          padding: 0 !important;

          > .grid__col-12 {
            margin: 0 !important;
            padding: 0 !important;
          }
        }
      }

      p {
        display: inline-block;

        a {
          margin: 0;
          color: color(corePurple);
        }
      }
    }
  }

  .notification__text {
    a {
      color: color(corePurple);
    }
  }

  &__page-title,
  &__box-title,
  &__form-title {
    font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  &__back-button {
    height: 3.5rem;
  }

  &__page-content {
    display: flex;
    flex-direction: column;

    @include from('lg') {
      flex-direction: row;
      justify-content: center;
    }
  }

  &__back-button-box {
    @include from('md') {
      padding: 0 24px;
    }

    @include from('lg') {
      max-width: 1870px;
      margin: 0 auto;
      padding: 0 84px;
    }
  }

  &__back-button {
    margin: 0;
  }

  &__page-box-title,
  &__checkbox-box {
    @include from('md') {
      justify-content: center;
    }
  }

  &__checkbox-label-text {
    margin-left: 0.5rem;
  }

  &__options {
    justify-content: center;
    flex-direction: column;

    @include from('md') {
      flex-direction: row;
      align-items: normal;
    }
  }

  &__box:first-child {
    border-radius: 4px 4px 0 0;

    @include from('md') {
      border-radius: 4px;
    }
  }

  &__box:last-child {
    border-radius: 0 0 4px 4px;

    @include from('md') {
      border-radius: 4px;
    }
  }

  &__box {
    border: 1px solid #b0b0b5;
    background-color: #ffffff;
    margin-bottom: -1px;
    color: #222222;
    flex-direction: row;

    @include from('md') {
      border-radius: 4px;
      flex-direction: column;
      flex-basis: 33.33%;
      max-width: 345px;
    }

    @include from('lg') {
      padding: 1.5rem;
    }
  }

  &__text-box {
    padding: 1.5rem;
    width: 100%;

    @include from('md') {
      text-align: center;
    }
  }

  &__box-title {
    margin: 0.5rem 0 1rem;
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;

    @include from('md') {
      margin: 1.5rem 0;
      font-size: 20px;
    }
  }

  &__button-box {
    text-align: center;
    width: 3.6rem;
    background-color: #990ae3;

    @include from('md') {
      background-color: inherit;
      width: 100%;
      padding-bottom: 2rem;
    }
  }

  &__button-box > .button {
    width: 100%;
    height: 100%;
    border-radius: 0;
    margin: 0;
    padding: 0;

    @include from('md') {
      width: auto;
      height: 40px;
      border-radius: 999px;
      margin: 6px;
      padding: 0 2rem;
    }

    .button-text {
      display: none;

      @include from('md') {
        display: inline;
        pointer-events: none;
      }
    }
  }

  &__button-box > .button::after {
    // renders greater than icon
    content: url("data:image/svg+xml;utf8,<svg width='14' height='22' viewBox='0 0 14 22' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M13.5834 11C13.5836 11.3071 13.4532 11.5998 13.2248 11.8051L2.39143 21.5551C1.94679 21.9553 1.26188 21.9193 0.861641 21.4747C0.461406 21.03 0.497404 20.3451 0.942046 19.9449L10.8809 11L0.942046 2.05508C0.654414 1.79617 0.526755 1.40348 0.607159 1.02493C0.687562 0.64638 0.963813 0.339479 1.33185 0.219835C1.69989 0.100191 2.10379 0.185981 2.39143 0.444888L13.2248 10.1949C13.4532 10.4002 13.5836 10.6929 13.5834 11Z' fill='white'/></svg>");

    @include from('md') {
      content: '';
    }
  }

  &__short-text {
    margin: 0.5rem 0;
  }

  &__link {
    color: #222222;
    text-decoration: underline;
  }

  &__page-title {
    margin: 1.75rem 0;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 500;

    @include from('md') {
      margin: 3rem auto;
      font-size: 30px;
      font-weight: 700;
    }
  }

  &__page-title::after {
    content: ':';

    @include from('md') {
      content: '';
    }
  }

  &__checkbox-box {
    margin: 20px auto;
    padding: 1rem;
    background-color: color(lightPurple);
    color: #222222;
    border-radius: 4px;

    @include from('lg') {
      max-width: 1050px;
    }
  }

  margin: auto;
  color: color(corePurple);
  background-color: color(lightPurple);
}

#cms-login-2022__menu-page {
  .cms-login-2022__back-button {
    background-color: #ffffff;
    color: #990ae3;
  }

  background-color: #f4f4f9;
  background-image: url('/.resources/pride-ui-module/webresources/assets/images/onelogin/bg-sm.png');
  background-repeat: no-repeat;
  background-position: right top;

  @include from('md') {
    background-image: none; // Prevents overlapping images
    background-image: url('/.resources/pride-ui-module/webresources/assets/images/onelogin/bg-lg.png');
  }
}

#cms-login-2022__b2c-owner-page,
#cms-login-2022__b2c-user-page,
#cms-login-2022__b2b-admin-page {
  .cms-login-2022__form-box {
    @include from('md') {
      width: 580px;
      margin: 1rem auto;
      padding: 3rem;
      background-color: #ffffff;
      border: 1px solid #b0b0b5;
      border-radius: 4px;
    }

    color: #222222;
  }

  .cms-login-2022__form-title {
    margin-top: 3rem;
    margin-bottom: 2.75rem;
    font-size: 1.5rem;
    font-weight: 700;

    @include from('lg') {
      font-size: 2rem;
      margin-top: 3.5rem;
      margin-bottom: 2rem;
    }
  }

  .cms-login-2022__form-text {
    color: #222222;
  }

  .cms-login-2022__form-label {
    display: inline-block;
    margin: 16px 0 8px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.67;
    letter-spacing: normal;
    color: color(grey200);
  }

  .cms-login-2022__input {
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    padding: 1rem 10px;
  }

  .cms-login-2022__login-button {
    margin: 1.75rem 0 0;
    width: 100%;

    @include from('md') {
      width: auto;
    }
  }

  .cms-login-2022__notification-box {
    .notification__icon {
      display: flex;
      -ms-flex-align: start;
      align-items: flex-start;
      -ms-flex-item-align: start;
      align-self: flex-start;
      padding: 10px 0;
      font-size: 20px;
      margin: 0.8rem 0 0 1rem;

      @include from('md') {
        margin-top: 1rem;
      }
    }

    .notification__text {
      display: flex;
      font-size: 14px;
      line-height: 20px;
      -ms-flex-item-align: center;
      align-self: center;
      -ms-flex-align: baseline;
      align-items: baseline;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      color: #222222;
      margin: 0.5rem;
      padding-right: 1rem;
    }

    border-radius: 4px;
  }

  .cms-login-2022__notification-box {
    .notification__icon {
      color: #33adff;
    }

    background-color: #ccebff;
    border-left: 4px solid #33adff;
    margin-top: 2.5rem;
  }

  .cms-login-2022__tupas-notification-box {
    .tupas-notification__icon {
      display: flex;
      -ms-flex-align: start;
      align-items: flex-start;
      -ms-flex-item-align: start;
      align-self: flex-start;
      padding: 10px 0;
      font-size: 20px;
      margin: 0 0 0 1rem;

      @include from('md') {
        margin-top: 0;
      }
    }

    .tupas-notification__text {
      display: flex;
      font-size: 14px;
      line-height: 20px;
      -ms-flex-item-align: center;
      align-self: center;
      -ms-flex-align: baseline;
      align-items: baseline;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-positive: 1;
      flex-grow: 1;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      color: #222222;
      margin: 0.5rem;
      padding-right: 1rem;
    }

    border-radius: 4px;
  }

  .cms-login-2022__tupas-notification-box {
    .tupas-notification__icon {
      color: #33adff;
    }

    background-color: #ccebff;
    border-left: 4px solid #33adff;
    margin: 0 1.4rem;
  }

  .cms-login-2022__error--credentials,
  .cms-login-2022__error--b2btupas {
    .notification__icon {
      color: #be0040;
    }

    background-color: #fad1de;
    border-left: 4px solid #af1d54;
  }

  .cms-login-2022__back-button {
    background-color: #f4f4f9;
    color: #990ae3;

    @include from('md') {
      background-color: #ffffff;
      color: #990ae3;
    }
  }

  background-color: #ffffff;

  @include from('md') {
    background-color: #f4f4f9;
  }
}

#cms-login-2022__b2c-user-page {
  .cms-login-2022__form-box {
    @include from('lg') {
      position: relative;
      margin: 1rem auto;
      padding: 3rem 4rem 4rem;
      width: 650px;
    }
  }

  background-repeat: no-repeat;
  background-position: right top;

  @include from('md') {
    background-image: url('/.resources/pride-ui-module/webresources/assets/images/onelogin/bg-lg.png');
  }
}

#cms-login-2022__b2c-owner-page,
#cms-login-2022__b2b-admin-page {
  .cms-login-2022__form-title {
    @include from('md') {
      margin-top: -0.75rem;
      margin-bottom: 2.2rem;
      font-size: 2rem;
    }

    @include from('lg') {
      margin-top: 3rem;
    }
  }

  .cms-login-2022__login-button {
    width: 100%;
  }

  .cms-login-2022__back-button {
    background-color: #f4f4f9;
    color: #990ae3;
  }

  @include from('md') {
    background-color: #ffffff;
  }

  .cms-login-2022__form-box {
    @include from('md') {
      border: none;
    }

    @include from('lg') {
      margin: 0 0 1rem 1rem;
      padding: 0rem 5rem 3rem 0rem;
      width: 500px;
    }
  }

  .cms-login-2022__cards {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 550px;
    margin: 1rem auto 0;

    &-wrapper {
      margin: 2.5rem -1rem -3.5rem;
      padding: 0 0 3.5rem;
      background-color: #f4f4f9;

      @include from('lg') {
        margin: 5rem -1rem 2.5rem;
        height: min-content;
        border-radius: 4px;
      }
    }

    &-box {
      max-width: 580px;
      margin: auto;
      color: #222222;
    }

    &-text {
      margin-top: 1rem;
      padding: 1rem 1rem 0 1rem;

      @include from('md') {
        padding: 1rem 1rem 0 2.2rem;
      }

      @include from('lg') {
        margin-top: 0.5rem;
      }
    }

    &-link {
      color: #222222;
      text-decoration: underline;
    }
  }

  .cms-login-2022__card {
    display: flex;
    flex-direction: column;
    width: 170px;
    height: 110px;
    border: 1px solid #8c8c90;
    justify-content: flex-end;
    align-items: center;
    margin: -1px 0 0 -1px; // hides double-width borders
    background-color: #fff;
    cursor: pointer;

    img {
      max-width: 135px;
      max-height: 40px;
    }

    &-text {
      height: 40px;
      margin-top: 0.5rem;
      color: #222222;
      font-size: 14px;
    }
  }

  .cms-login-2022__card:last-child {
    border: none;
    background-color: transparent;
    height: 0;
  }
}

#cms-login-2022__b2c-owner-page {
  background-color: #f4f4f9;
  background-repeat: no-repeat;
  background-position: right top;

  @include from('md') {
    background-image: url('/.resources/pride-ui-module/webresources/assets/images/onelogin/bg-lg.png');
  }
  .cms-login-2022__back-button {
    background-color: #fff;
    color: #990ae3;
  }

  .cms-login-2022__cards {
    &-wrapper {
      border: 1px solid #b0b0b5;
      background-color: #fff;
      margin: 20px auto;
      border-radius: 4px;
    }
    &-text {
      margin-top: 1rem;
      padding: 0.5rem 1rem 0 1rem;

      @include from('md') {
        padding: 0.5rem 1rem 0 1.4rem;
      }
    }
  }
}

/* Try to remove browser native button styles */
.cms-clear-button-styles {
  padding: 0;
  border: 0;
  margin: 0;
  /* stylelint-disable property-no-vendor-prefix */
  -webkit-appearance: none;
  -moz-appearance: none;
  /* stylelint-enable */
  background-color: transparent;
  color: inherit;
  line-height: 0;
  text-align: left;
}

.cms-modal {
  display: none;
  color: color(grey200);

  &--open {
    display: block;
  }

  &__wrapper {
    position: fixed;
    z-index: 9999;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 24px;
    background: rgba(0, 0, 0, 0.23);
    cursor: pointer;
    inset: 0;
  }

  &__window {
    background-color: color(white);
    border-radius: 16px;
    position: relative;
    display: flex;
    width: 100%;
    max-width: 848px;
    max-height: 100%;
    flex-direction: column;
    padding: 32px;
    padding-bottom: 24px;
    box-shadow: 0 8px 24px rgba(88, 88, 88, 0.18);
    cursor: default;

    @include from('md') {
      max-width: 848px;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;

    h3 {
      margin: 0;
    }
  }

  &__close {
    cursor: pointer;
    width: 20px;
    height: 20px;

    > .icon {
      width: 20px;
      height: 20px;
    }

    &:hover {
      color: color(grey200);
    }

    &:focus {
      outline: solid 3px #40b3ff;
      outline-offset: 2px;

      &:not(:focus-visible) {
        outline: none;
      }
    }
  }

  &__content {
    margin-bottom: 32px;

    > p {
      max-width: none;
    }

    > ul {
      margin: 0;

      > li {
        padding-top: 16px;
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    align-items: center;
  }

  &__cancel {
    padding: 0 2em;
  }
}
