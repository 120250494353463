.marketing-restriction {

  &__description {

    p:last-child {
      margin-bottom: 0;
    }
  }

  &__form-container {
    position: relative;
    min-height: 250px;
  }

  &__form {
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease-in-out, visibility .3s ease-in-out;

    &--visible {
      opacity: 1;
      visibility: visible;
    }

    .notification {
      margin-top: 0;
    }
  }

  &__checkbox-group {
    margin: 1.5em 0 .75em;
  }

  &__submit {
    margin: 0;
  }

  &__back {
    display: none;
    margin: 32px 0;
  }
}
