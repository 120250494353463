@function rem($size) {
  $remSize: $size / 16px;
  @return #{$remSize}rem;
}

h1,
.h1 {
  @include teliaSansHeading;
  font-size: rem(24px);
  line-height: 1;
  letter-spacing: 0;
  color: color(grey200);
  margin-top: 25px;
  margin-bottom: 11px;

  @include from('xs') {
    font-size: rem(28px);
    margin-bottom: 16px;
  }

  @include from('md') {
    font-size: rem(40px);
    margin-bottom: 18px;
  }

  @include from('lg') {
    font-size: rem(48px);
    margin-bottom: 21px;
  }

  @include from('xlg') {
    font-size: rem(64px);
    margin-bottom: 18px;
  }
}

h2,
.h2 {
  @include teliaSansBold;
  font-size: rem(24px);
  line-height: 1;
  letter-spacing: 0;
  color: color(grey200);
  margin-top: 27px + 16px;
  margin-bottom: 29px;

  @include from('xs') {
    margin-top: 43px + 16px;
  }

  @include from('sm') {
    margin-top: 43px + 16px;
  }

  @include from('md') {
    font-size: rem(28px);
    margin-top: 34px + 24px;
  }

  @include from('lg') {
    font-size: rem(34px);
  }

  @include from('xlg') {
    font-size: rem(42px);
  }

  @include from('xxlg') {
    font-size: rem(44px);
  }
}

h3,
.h3 {
  @include teliaSansBold;
  font-size: rem(22px);
  line-height: 1;
  color: color(grey200);
  margin-top: 36px;

  @include from('xs') {
    margin-bottom: 26px;
  }

  @include from('md') {
    font-size: rem(24px);
  }

  @include from('lg') {
    font-size: rem(28px);
  }

  @include from('xlg') {
    font-size: rem(34px);
  }
}

h4,
.h4 {
  @include teliaSansBold;
  font-size: rem(20px);
  line-height: 1;
  letter-spacing: -0.08px;
  color: color(grey200);
  margin: 1em 0;

  @include from('md') {
    font-size: rem(22px);
  }

  @include from('lg') {
    font-size: rem(24px);
  }
}

h5,
.h5 {
  @include teliaSansBold;
  font-size: rem(16px);
  line-height: 1.5;
  letter-spacing: normal;
  margin: 1em 0;

  @include from('md') {
    font-size: rem(20px);
    line-height: 1.3;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  @include from('lg') {
    max-width: 60vw;

    .grid--justify-center & {
      margin-left: auto;
      margin-right: auto;
    }

    .grid--justify-end & {
      margin-left: auto;
    }
  }

  @include from('xlg') {
    max-width: 62.5vw;
  }

  @include from('xxlg') {
    max-width: 1260px;
  }

  a:link,
  a:visited {
    color: inherit;
  }

  a:hover,
  a:active {
    color: hover(grey200);
  }
}

.heading-xl {
  font-size: 28px;

  @include from('sm') {
    font-size: rem(36px);
  }

  @include from('md') {
    font-size: rem(56px);
  }

  @include from('lg') {
    font-size: rem(64px);
  }

  @include from('xlg') {
    font-size: rem(88px);
  }

  @include from('xxlg') {
    font-size: rem(130px);
  }
}

.heading-small {
  font-size: 12px;
  letter-spacing: 1.55px;
  line-height: 16px;
}

p,
ol,
ul {
  font-size: 14px;
  line-height: 1.5;
  max-width: 600px;

  @include from('md') {
    font-size: 16px;
  }
}

.callout {
  font-size: rem(18px);
  line-height: 1.22222;

  @include from('sm') {
    font-size: rem(20px);
  }

  @include from('md') {
    font-size: rem(24px);
  }

  @include from('xlg') {
    font-size: rem(30px);
  }

  &--purple {
    color: color(corePurple);
  }
}

.purplecircle {
  ol {
    list-style-type: none;
    position: relative;

    /*This will match the IE browser */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      left: -20px;
    }
  }

  ol > li {
    counter-increment: step-counter;
    margin-bottom: 22px;
    margin-left: 16px;

    /*This will match the IE browser */
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-left: 35px;
    }

    @supports (-moz-appearance: none) {
      // adds padding to firefox
      padding-left: 16px;
    }

    @supports (-ms-ime-align: auto) {
      // adds padding to edge
      padding-left: 16px;
    }
  }

  ol > li:before {
    content: counter(step-counter);
    position: absolute;
    left: 0;
    width: 1.4em;
    height: 1.4em;
    color: color(white);
    background-color: color(corePurple);
    border-radius: 50%;
    text-align: center;
    margin-right: 10px;
    line-height: 22px;

    @include from('md') {
      line-height: 24px;
    }
  }
}

.paragraph-large {
  font-size: rem(18px);
  line-height: 1.5;
  max-width: 600px;

  @include from('md') {
    font-size: rem(20px);
  }
}

.paragraph-medium {
  font-size: 16px;
  line-height: 1.5;
  max-width: 600px;

  @include from('md') {
    font-size: rem(18px);
  }
}

.summary {
  font-size: 12px;
  line-height: 18px;
}

.caption {
  font-size: 14px;
  line-height: 20px;
  color: color(corePurple);
  letter-spacing: 0.8px;
  text-transform: uppercase;
  font-weight: bold;
}

.step-label {
  @include teliaSansHeading;
  color: color(grey200);
  background-color: color(grey20);
  display: inline-flex;
  width: 39px;
  height: 39px;
  font-size: 24px;
  line-height: 1;
  align-items: center;
  justify-content: center;
  border-radius: 39px;
  overflow: hidden;
}

a {
  color: color(corePurple);

  &:hover,
  &:active {
    color: hover(corePurple);
  }
}

.link--with-arrow,
a.arrow {
  font-weight: bold;

  &:after {
    content: '';
    width: 10px;
    height: 16px;
    background-image: url('#{$assetBasePath}/images/links/arrow-right.png');
    background-size: 12px;
    background-repeat: no-repeat;
    top: 6px;
    position: relative;
    display: inline-block;
    margin-left: 2px;
  }

  &:hover:after {
    background-image: url('#{$assetBasePath}/images/links/arrow-right-hover.png');
  }
}

.white-link--with-arrow {
  @extend .link--with-arrow;

  &:link,
  &:hover,
  &:active,
  &:visited {
    color: color(white);
  }

  &:after {
    background-image: url('#{$assetBasePath}/images/links/white-arrow-right.png');
  }
  &:hover:after {
    background-image: url('#{$assetBasePath}/images/links/white-arrow-right.png');
  }
}

.modal-buttonlink {
  &:after {
    content: '';
    width: 10px;
    height: 17px;
    background-image: url('#{$assetBasePath}/images/links/white-arrow-right.png');
    background-size: 12px;
    background-repeat: no-repeat;
    top: 3px;
    position: relative;
    display: inline-block;
    margin-left: 5px;
  }

  &:hover:after {
    background-image: url('#{$assetBasePath}/images/links/white-arrow-right.png');
  }
}

.modal-buttonlink--purple {
  @extend .modal-buttonlink;

  &:after {
    background-image: url('#{$assetBasePath}/images/links/arrow-right.png');
  }
}

.cms-blockquote {
  border-left: solid 2px color(corePurple);
  padding-left: 12px;
  padding-right: 20%;
  margin: 1.4em 0;
  margin-left: 17px;
  font-size: 16px;

  @include from('md') {
    padding-left: 15px;
    margin: 1.4em 0;
    margin-left: 24px;
    font-size: 18px;
    line-height: 1.7em;
  }
}

@at-root {
  @font-face {
    font-family: Pebble;
    src: url('#{$assetBasePath}/fonts/pebble/Pebble_W_Rg.eot');
    src: url('#{$assetBasePath}/fonts/pebble/Pebble_W_Rg.eot?#iefix') format('embedded-opentype'),
      url('#{$assetBasePath}/fonts/pebble/Pebble_W_Rg.woff2') format('woff2'),
      url('#{$assetBasePath}/fonts/pebble/Pebble_W_Rg.woff') format('woff'),
      url('#{$assetBasePath}/fonts/pebble/Pebble_W_Rg.ttf') format('truetype');
  }

  @font-face {
    font-family: TeliaSansHeading;
    src: url('#{$assetBasePath}/fonts/teliasans/TeliaSansHeading-Heading.otf') format('opentype'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSansHeading-Heading.ttf') format('truetype'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSansHeading-Heading.woff') format('woff'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSansHeading-Heading.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: TeliaSans;
    src: url('#{$assetBasePath}/fonts/teliasans/TeliaSans-Regular.otf') format('opentype'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-Regular.ttf') format('truetype'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-Regular.woff') format('woff'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: TeliaSans;
    src: url('#{$assetBasePath}/fonts/teliasans/TeliaSans-Medium.otf') format('opentype'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-Medium.ttf') format('truetype'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-Medium.woff') format('woff'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: TeliaSans;
    src: url('#{$assetBasePath}/fonts/teliasans/TeliaSans-Bold.otf') format('opentype'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-Bold.ttf') format('truetype'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-Bold.woff') format('woff'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-Bold.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: TeliaSans;
    src: url('#{$assetBasePath}/fonts/teliasans/TeliaSans-RegularItalic.otf') format('opentype'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-RegularItalic.ttf') format('truetype'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-RegularItalic.woff') format('woff'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-RegularItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: TeliaSans;
    src: url('#{$assetBasePath}/fonts/teliasans/TeliaSans-MediumItalic.otf') format('opentype'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-MediumItalic.ttf') format('truetype'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-MediumItalic.woff') format('woff'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
  }

  @font-face {
    font-family: TeliaSans;
    src: url('#{$assetBasePath}/fonts/teliasans/TeliaSans-BoldItalic.otf') format('opentype'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-BoldItalic.ttf') format('truetype'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-BoldItalic.woff') format('woff'),
      url('#{$assetBasePath}/fonts/teliasans/TeliaSans-BoldItalic.woff2') format('woff2');
    font-weight: 600;
    font-style: italic;
  }
}

// TRCL: To force the component library to use TeliaSans.
// Apparently we're not going to get the TeliaSans font with the component library so here we override the component library specific font class.
.telia-react-typography__helvetica {
  @include teliaSansFamily;
}
