.cms-instagram-feed {
  /* instagram embed script creates some styles that we want to override here */
  iframe.instagram-media {
    margin: 0 !important;
    min-width: 100% !important;
    /* iPhone would not shrink the iframe to fit the container without this */
    width: 1px;
  }

  &__loader {
    padding: 20px;
    text-align: center;
  }
}
