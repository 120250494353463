.content-central-breadcrumb {
  padding: 7px 0;

  &__items {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0.3px;
    max-width: none;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  &--light {
    color: color(grey100);
    background-color: color(white);
    border-top: 1px solid color(grey30);
    border-bottom: 1px solid color(grey30);
  }

  &--dark {
    color: color(lightPurple);
    background-color: color(deepPurple);
  }

  &--beige {
    color: color(deepBeige);
    background-color: color(lightBeige);
  }

  &--transparent-light {
    color: color(grey100);
    background-color: transparent;
    border-top: 1px solid color(grey30);
    border-bottom: 1px solid color(grey30);
  }

  &--transparent-dark {
    color: color(lightPurple);
    background-color: transparent;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 44px;
    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
    flex: 0 0 auto;

    &--current {
      flex: 0 1 auto;
      font-weight: 500;
      overflow: hidden;
    }

    &--desktop {
      display: none;

      @include from('sm') {
        display: flex;
      }
    }

    > * {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__link {
    display: flex;
    position: relative;
    height: 100%;
    align-items: center;

    &::after {
      position: absolute;
      bottom: 12px;
      width: 100%;
      content: "";
    }

    .content-central-breadcrumb--light &,
    .content-central-breadcrumb--transparent-light & {
      color: color(grey100);

      &::after {
        border-bottom: 1px solid color(grey100);
      }

      &:hover {
        color: color(corePurple);

        &::after {
          border-bottom: 2px solid color(corePurple);
        }
      }
    }

    .content-central-breadcrumb--dark &,
    .content-central-breadcrumb--transparent-dark & {
      color: color(lightPurple);

      &::after {
        border-bottom: 1px solid color(lightPurple);
      }

      &:hover {
        color: #E4B6FB;

        &::after {
          border-bottom: 2px solid #E4B6FB;
        }
      }
    }

    .content-central-breadcrumb--beige & {
      color: color(deepBeige);

      &::after {
        border-bottom: 1px solid color(deepBeige);
      }

      &:hover {
        color: color(deepBeige);

        &::after {
          border-bottom: 2px solid color(deepBeige);
        }
      }
    }

    &:focus {
      outline: solid 3px color(focusOutline);
      outline-offset: -1px;

      &:not(:focus-visible) {
        outline: none;
      }
    }
  }

  &__item-delimiter {
    margin-left: 6px;
    margin-right: 6px;
  }
}
