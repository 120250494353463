.cms-campaign {
    background-color: color('grey20');
    padding: 0 108px !important;
    
    &__container {
        padding-left: 12px !important;
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }

    &__wrapper {
        padding: 12px;
    }

    &__mobileapp {
        height: 184px;
        background-color: color(orange);
        color: color(white);
        display: inline-block;    
        padding: 0 20px;
        position: relative;
        
        &__width-320 {
            width: 320px;
        }
    
        &__width-375 {
            width: 375px;
        }
    
        &__width-414 {
            width: 414px;
        }
    
        &__width-360 {
            width: 360px;
        }
    
        &__width-400 {
            width: 400px;
        }
    
        &__width-480 {
            width: 480px;
        }
    
        &__background-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            border-radius: 5px;
            background-size: 100% !important;
            background-repeat: no-repeat;
        }
    
        &__block {
            height: 88px;
        }
    
        &__content {
            height: 96px;
            position: relative;
    
            &__body {
                position: absolute;
                bottom: 12px;
            }
        }
    
        &__caption {
            height: 18px;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.1px;
            text-transform: uppercase;
            font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
        }
    
        &__title {
            min-height: 22px;
            max-height: 44px;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: -0.4px;
            font-family: TeliaSans, 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
            text-overflow: ellipsis !important;
            overflow: hidden;
        }
    }

    &__os {
        height: 210px;
        background-color: color(orange);
        color: color(white);
        display: inline-block;
        border-radius: 6px;
        padding: 0 20px;
        position: relative;    
   
        &__small {
            width: 392px;
        }

        &__big {
            width: 807px;
        }

        &__background-image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
            border-radius: 5px;
            background-position: 100%;
        }

        &__header {
            margin-bottom: 15px;
            color: color(white);
            position: relative;
        }

        &__description {
            position: relative;
        }

        &__button-container {
            position: absolute;
            bottom: 10px;
        }
        
        &__telia* {
            margin:0; 
            padding:0;
        }

        &__button {            
            font-size: 14px;
            letter-spacing: 0.8px;
            line-height: 18px;
            font-weight: bold;
            white-space: nowrap;
            padding: 0 2em;
            height: 40px;
            margin: 6px;
            border: 2px solid transparent;
            color: color(white);
            background-color: color(corePurple);
            border-color: color(corePurple);
            border-radius: 999px;
            text-transform: uppercase;
            -webkit-transition: all 0.2s;
            transition: all 0.2s;
            cursor: pointer;
            position: relative;
            display: -webkit-inline-box;
            display: -ms-inline-flexbox;
            display: inline-flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            max-width: 100%;
            text-decoration: none;

            &.cms-campaign__os__button--dark-background {
                color: color(corePurple);
                border-color: color(white);
                background-color: color(white);
            }

            &__text {
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &__icon {
                fill: currentColor;
                margin-left: 5px;
                font-size: 9px;
                margin-top: 1px;

                &--large {
                    font-size: 14px;
                }

                svg {
                    width: 1em;
                    height: 1em;
                }
            }

            &:hover {
                background-color: hover(corePurple);
                border-color: hover(corePurple);
                color: color(white);

                .cms-campaign__os__button--dark-background {
                    background-color: hover(corePurple);
                    border-color: hover(corePurple);
                    color: color(white);
                }
            }

            &:link,
            &:visited {
                color: color(white);
            }

            &--secondary {
                background-color: rgba(153, 10, 227, 0);
                color: color(corePurple);
                border-color: color(corePurple);

                &.cms-campaign__os__button--dark-background {
                    color: color(white);
                    border-color: color(white);
                    background-color: transparent;
                }

                &:hover {
                    background-color: color(corePurple);
                    border-color: color(corePurple);
                    color: color(white);

                    &.cms-campaign__os__button--dark-background {
                        background-color: hover(corePurple);
                        border-color: hover(corePurple);
                        color: color(white);
                    }
                }
    
                &:link,
                &:visited {
                    color: color(corePurple);
                }
            }

            &--purchase {
                background-color: color(darkGreen);
                border-color: color(darkGreen);

                &.cms-campaign__os__button--dark-background {
                    color: color(white);
                    border-color: color(darkGreen);
                    background-color: color(darkGreen);
                }

                &:hover {
                    background-color: hover(darkGreen);
                    border-color: hover(darkGreen);
                    color: color(white);

                    &.cms-campaign__os__button--dark-background {
                        background-color: hover(darkGreen);
                        border-color: hover(darkGreen);
                        color: color(white);
                    }
                }
            }

            &--link {
                background-color: transparent;
                color: color(corePurple);
                border: 0 transparent;
                text-transform: none;
                letter-spacing: 0;
                padding: 0;
                margin-left: 0;
                margin-right: 0;
                font-size: 14px;
                -webkit-transition: 0ms;
                transition: 0ms;

                &.cms-campaign__os__button--dark-background {
                    background-color: transparent;
                    color: color(white);
                }

                &:hover {
                    background-color: transparent;
                    color: hover(corePurple);
                    border-color: transparent;

                    &.cms-campaign__os__button--dark-background {
                        background-color: transparent;
                        color: color(corePurple);
                    }
                }

                &:link,
                &:visited {
                    color: color(corePurple);
                }

                .cms-campaign__os__button__icon {
                    margin-top: 3px;
                }
            }
            
            
        }
    }


}

