.price-bubble {
  width: 80px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 40px;

  background-color: color(orange);
  color: color(white);

  &__heading {
    font-size: 12px;
    line-height: 1;
  }

  &__price {
    font-size: 20px;
    line-height: 1;
    font-weight: bold;
    margin: 3px 0 4px;
  }
}
