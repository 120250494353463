.order-tracking {

  &__background-image {
    min-height: 300px;
    width: 100%;
    background-size: cover;
    background-repeat: repeat-x;
    background-position: 50% 50%;
    position: absolute;
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: none;
    transition: background .3s ease-out;

    &--active {
      background: rgba(0, 0, 0, 0.15);
    }
  }

  &__form {
    min-height: 300px;
    position: relative;
    color: color(white);
    text-align: center;

    &--no-image {
      background-color: color(corePurple);
      height: 320px;
    }

    &--content {
      width: 100%;
      position: absolute;
      left: 0;
      margin: auto;
      top: 50%;
      transform: translateY(-50%);

      h3 {
        margin-top: 0;
        margin-bottom: 0;
        line-height: 1;
      }
    }
  }

  &__heading {
    color: color(white);
    margin-left: auto;
    margin-right: auto;
  }

  &__description {
    margin: 1.3em auto 1.8em;
    line-height: 1;
  }

  &__input-form {
    position: relative;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }

  &__error {
    text-align: center;
    color: color(red);
    padding: 10px;
  }

  &__results {
    text-align: center;
    clear: both;
    padding-bottom: 2em;
    font-size: 14px;

    &--product {
      font-weight: 700;
      padding: 4em 0 2px;
    }

    &--product-name {
      margin-bottom: 2em;
      margin-left: auto;
      margin-right: auto;
    }

    p {
      font-size: 14px;
      color: color(grey100);
    }
  }

  &__details {
    display: block;
    font-weight: normal;
    line-height: 16px;
    color: color(grey70);
  }

  &__results-table {
    margin: 0 auto;
    color: color(grey100);
    font-weight: 700;
    word-wrap: break-word;
    display: table;
    border-collapse: collapse;

    td {
      padding: 2em;
      text-align: left;
      vertical-align: top;
    }
  }
}
