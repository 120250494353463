.job-listing-yaw {
  max-width: 1440px;

  &__anchor {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
  }

  &__heading {
    &--black {
      color: color(black);
    }
  }

  &__select-container {
    display: none;
  }

  &__pagination-container {
    text-align: center;
    padding-top: 20px;
    display: none;
  }
  &__pagination-list {
    display: inline-block;
    li {
      cursor: pointer;
    }
  }
  &__pagination-numbered.current {
    font-weight: bolder;
    color: #9706e2;
  }
  &__pagination-numbered-container,
  &__pagination-numbered-beginning,
  &__pagination-numbered,
  &__pagination-numbered-end {
    padding: 0;
  }
  &__pagination-later,
  &__pagination-earlier {
    height: 20px;
  }
  &__pagination-last:after,
  &__pagination-first:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0' y='0' viewBox='0 0 16 14'><path d='M1.6,14c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1L5.2,7L0.5,2.5c-0.6-0.6-0.6-1.5,0-2.1s1.6-0.6,2.2,0l5.9,5.5c0.6,0.6,0.6,1.5,0,2.1l-5.9,5.5C2.3,13.9,2,14,1.6,14z'/><path d='M8.6,14c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1L12.2,7L7.4,2.5C6.8,1.9,6.8,1,7.4,0.4s1.6-0.6,2.2,0l5.9,5.5c0.6,0.6,0.6,1.5,0,2.1l-5.9,5.5C9.3,13.9,8.9,14,8.6,14z'/></svg>");
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: 15px;
    width: 16px;
  }

  &__pagination-next:after,
  &__pagination-previous:after {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0' y='0' viewBox='0 0 9 14'><path d='M1.6,14c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1L5.2,7L0.5,2.5c-0.6-0.6-0.6-1.5,0-2.1s1.6-0.6,2.2,0l5.9,5.5c0.6,0.6,0.6,1.5,0,2.1l-5.9,5.5C2.4,13.9,2,14,1.6,14z'/></svg>");
    background-repeat: no-repeat;
    content: '';
    display: block;
    height: 15px;
    width: 10px;
  }
  &__pagination-previous:after,
  &__pagination-first:after {
    transform: rotate(180deg);
  }
  &__pagination-list {
    list-style-type: none;
    overflow: hidden;
    li {
      width: 30px;
      float: left;
    }
  }
  &__search-label {
    display: block;
    margin: 0 0 8px;
    font-size: 14px;
    font-weight: bolder;
  }
  &__search-input {
    border: none; // reset default borders
    width: calc(100% - 40px);
    height: 24px;
    vertical-align: top;
  }
  &__search-box {
    border-bottom: 1px solid #B0B0B5;
  }
  &__search-icon-box {
    display: inline-block;
    padding: 0 12px 4px 0;
  }
  &__search-icon {
    width: 24px;
    height: 24px;
  }
  &__select-box {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__select-label {
    color: color(grey50);
  }

  &__vacancy {
    padding: 16px 5%;
    width: 100vw;
    margin-left: -5.5%;

    @include from('md') {
      padding: 0;
      margin: 0;
      width: auto;
    }
  }

  &__table {
    display: none;
  }

  &__table-container {
    @include to('md') {
      padding: 0;
    }
  }

  &__column-table-headings {
    display: none;
    @include from('md') {
      display: block;
    }
  }

  &__column-heading {
    font-weight: bold;
    cursor: pointer;
  }

  &__vacancy-data {
    display: block;
    padding: 8px 0;
    @include from('md') {
      font-size: 13px;
    }
  }

  &__columns {
    display: flex;
  }

  &__title-column {
    padding: 8px 0;
    max-width: 50%;
    color: color(grey100);

    @include from('md') {
      display: none;
    }
  }

  &__data-column {
    max-width: 50%;
    color: color(black);

    .job-listing-yaw__vacancy-link:hover & {
      color: hover(black);
    }
  }

  &__vacancy:nth-child(odd) {
    background-color: color(grey10);
  }

  &__icon {
    position: absolute;
    top: 10px;
    right: 20px;

    @include to('sm') {
      display: none;
    }
  }

  &__button-container {
    display: none;
    padding-top: 30px;

    .button {
      margin-left: 0;
    }
  }

  &__loader{
    @include to('md') {
      margin-top: 26px;
    }
  }

}
