$loginMaxWidth: 532px;

.cms-login {
  width: 100%;
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */

  &--logged-out {
    background-color: color(white);

    .cms-login__bottom-area {
      background-color: color(grey10);
    }
  }

  &__logged-in-container {
    display: flex;
    background-color: transparent;
    max-width: 1920px;
    margin-top: -1px;

    @include from('md') {
      padding: 0 36px;
      margin: -1px auto 0;
    }

    @include from('lg') {
      padding: 0 96px;
    }

    @include from('xlg') {
      padding: 0 108px;
    }
  }

  &__left-overlay-container {
    flex-grow: 1;
    background-color: transparent;
  }

  &__services-list {
    position: relative;
    background-color: color(white);
    margin-left: auto;
    min-width: 260px;
    max-width: 260px;
    display: flex;
    flex-direction: column;

    .cms-login__screen-username {
      font-weight: bold;
      letter-spacing: normal;
      color: #2b2b2b;
      font-size: 16px;
      padding: 18px 24px 0;
      margin-bottom: 18px;
    }

    .cms-login__loggedInUrls {
      &--itemLinks {
        display: flex;
        flex-direction: column;
        position: relative;
        pointer-events: all;
      }
      &--itemText {
        color: #303030;
        position: relative;
        pointer-events: all;
        line-height: 1.5;
        padding: 15px 24px;

        &::before {
          content: '';
          width: 100%;
          border-top: solid 1px color(grey30);
          position: absolute;
          left: 0;
          top: 0;
        }
        &:hover {
          color: color(corePurple);
        }
      }
    }
  }

  .cms-login__services-list h3 {
    @include teliaSans;
    padding: 6px 0;
    text-transform: none;
    margin: 0;
    font-size: 12px;
    font-weight: normal;
    color: color(corePurple);
    padding: 6px 24px 15px;
    display: flex;

    a {
      display: flex;
    }

    .icon {
      margin-left: 4px;
      font-size: 11px;
    }
  }

  &__logout-button {
    display: flex;
    padding: 15px 24px;
    color: #303030;
    position: relative;
    pointer-events: all;
    line-height: 1;

    span {
      pointer-events: none;
      font-size: 14px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    svg {
      font-size: 24px;
      margin-right: 8px;
    }

    &:hover {
      color: #303030;
      background-color: color(grey20);
    }

    &::before {
      content: '';
      width: 100%;
      border-top: solid 1px color(grey30);
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  &__heading {
    @include teliaSans;
    line-height: 1;
    font-size: 24px;
    line-height: 26px;
    padding: 24px 24px 32px;
    font-weight: 700;

    @include from('md') {
      max-width: $loginMaxWidth;
      padding: 41px 0 36px;
      margin: 0 auto;
      font-size: 32px;
      line-height: 36px;
    }
  }

  &__tabs {
    @include to('md') {
      justify-content: space-between;
    }

    &-container {
      @include to('md') {
        padding: 0 24px;
      }

      @include from('md') {
        max-width: $loginMaxWidth;
        margin: 0 auto;
      }
    }
  }

  &__tab-radio {
    display: none;
  }

  &__tab {
    display: inline-block;
    color: color(grey70);
    letter-spacing: 1px !important;
    font-size: 16px !important;
    font-weight: 700;
    margin-right: 16px;
    padding-bottom: 9px;
    line-height: normal;
    border-bottom: 3px solid transparent;

    @include to('md') {
      max-width: 45%;
      vertical-align: baseline;
    }

    @include from('md') {
      border-bottom: 3px solid transparent;

      &--active {
        border-bottom: 3px solid color(corePurple);
      }

      &:hover {
        color: color(grey200);
      }
    }

    &--b2b-portal {
      order: 0;
    }

    &--credentials {
      order: 1;
    }

    &--tupas {
      order: 2;
    }
  }

  &__form {
    @include to('md') {
      padding: 0 24px 20px;
    }

    @include from('md') {
      max-width: $loginMaxWidth;
      margin: 0 auto;
    }

    &-row {
      @include from('md') {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .text-input {
    -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
    height: 48px;
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    margin: 0;

    > input {
      border: none;
      padding: 12px 16px;
      font-size: 16px;
      height: 100%;
    }

    &:focus-within {
      border-bottom: 1.5px solid color(corePurple);
    }
  }

  &__tab-radio--credentials:checked ~ &__tabs-container &__tab--credentials,
  &__tab-radio--tupas:checked ~ &__tabs-container &__tab--tupas,
  &__tab-radio--b2b-portal:checked ~ &__tabs-container &__tab--b2b-portal {
    color: color(black);
    border-color: color(corePurple);
    cursor: auto;
  }

  &__panel {
    -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
    position: relative;
    padding-top: 0;
    max-height: 0;
    overflow: hidden;
    order: 4;

    @include to('sm') {
      width: 100%;
      transition: padding 0.2s, max-height 0.3s;
    }

    &__info {
      @include to('md') {
        padding: 0 24px;
      }

      @include from('md') {
        padding-top: 20px;
        line-height: 24px;
        max-width: $loginMaxWidth;
        margin: 0 auto;

        &--empty {
          padding-bottom: 4px;
        }
      }
    }
  }

  &__tab-radio--credentials:checked ~ &__panel--credentials,
  &__tab-radio--tupas:checked ~ &__panel--tupas,
  &__tab-radio--b2b-portal:checked ~ &__panel--b2b-portal {
    border-top: 1px solid color(grey30);
    max-height: 800px;
  }

  @at-root {
    .cms-login .cms-login__error {
      overflow: hidden;
      max-height: 0;
      margin: 0;
      transition: max-height 0.2s ease-in, margin 0.2s ease-in;
      padding-left: 12px;

      &--credentials {
        @include from('md') {
          padding-left: 4px;
        }
      }

      &--visible {
        max-height: 150px;
        margin: 2px 0 20px;
      }

      .notification {
        @include from('md') {
          max-width: $loginMaxWidth;
          margin: 0 auto;

          .grid {
            margin: 0 !important;
            padding: 0 !important;

            > .grid__col-12 {
              margin: 0 !important;
              padding: 0 !important;
            }
          }
        }
      }
    }
  }

  &__error-details {
    font-weight: normal;
    display: block;
  }

  &__form-button {
    padding: 32px 0;

    &--padding {
      @include to('md') {
        padding: 32px 24px;
      }
    }

    @include from('md') {
      padding: 40px 0;
    }

    .button {
      margin: 0;
      display: inline;
    }
  }

  &__link-title {
    font-size: 14px;
  }

  &__link-container {
    &-spacer {
      @include to('md') {
        margin-bottom: 0px;
      }

      @include from('md') {
        margin-bottom: 40px;
      }
    }

    &__link {
      display: flex;
      font-size: 14px;
      margin-bottom: 16px;
      font-weight: 500;
      color: color(grey10);

      span {
        pointer-events: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 14px;
        line-height: 1;
        color: color(corePurple);
      }

      svg {
        font-size: 24px;
        margin-right: 8px;
        color: color(grey200) !important;
      }
    }
  }

  &__notification-tupas-container {
    margin: 0 24px 20px;

    @include from('md') {
      max-width: $loginMaxWidth;
      margin: 0 auto;
    }
  }

  &__notification-container {
    display: flex;
    flex-direction: row;
    min-height: 40px;
    max-width: $loginMaxWidth;
    background: color(blue100);
    box-shadow: 0px 2px 8px rgba(58, 58, 58, 0.12);
    border-radius: 4px;
    margin-bottom: 32px;
    padding: 12px;
    border-left: 4px solid color(blue600);

    @include from('md') {
      margin-bottom: 40px;
    }

    .notification__text p {
      margin: 0;
    }

    .notification__text a {
      color: color(corePurple);
    }

    .notification__icon {
      color: color(blue600);
      width: 14px;
      height: 14px;
    }

    .notification__delete {
      padding: 14px 0 8px 16px;
    }
  }

  .cms-login__form-label-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 23px 0 5px;

    &:first-of-type {
      padding: 11px 0 5px;
    }

    > label {
      position: relative;
      top: 0;
      font-size: 16px;
      line-height: 1.67;
      letter-spacing: normal;
      color: color(grey200);
      font-weight: 400;
    }
  }

  &__bottom-link {
    max-width: $loginMaxWidth;
    flex-direction: row;
    flex-wrap: nowrap;
    margin: 0 auto;
    display: flex;
    color: #23231f;
    padding: 17px 0 20px;

    span {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-size: 14px;
      line-height: 1;
    }

    svg {
      font-size: 16px;
      margin-right: 8px;
    }
  }

  &__tooltip {
    position: relative;
    display: inline-block;
    width: 20px;

    &-info-icon {
      position: absolute;
      top: 0;
      left: 0;

      & > svg {
        pointer-events: auto;
      }

      @supports (-ms-ime-align: auto) {
        & > svg {
          pointer-events: none; // Fix Edge bug where svg absorbs click events
        }
      }

      font-size: 18px;
      color: #642d96;
      cursor: pointer;
    }

    &-content {
      position: absolute;
      visibility: hidden;
      background-color: #642d96;
      font-weight: normal;
      padding: 4px 18px;
      color: color(white);
      border-radius: 8px;
      z-index: 1;
      cursor: default;
      z-index: z(modalBox);
      box-shadow: 3px 3px 6px 0px rgba(color(black), 0.3);
      -webkit-text-size-adjust: 100%; // prevents iphone from magically adjusting font size for whatever reason

      p {
        font-size: 12px;
      }

      @include to('md') {
        transform: translate(-100%, 50%);
        bottom: 60%;
        left: -50%;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 100%;
          margin-top: -5px;
          border: 5px solid transparent;
          border-left-color: #642d96;
        }
      }

      @include from('md') {
        transform: translate(-50%, 0);
        bottom: 150%;
        left: 50%;

        &:after {
          content: '';
          position: absolute;
          top: 105%;
          left: 50%;
          margin-left: -5px;
          border: 5px solid transparent;
          border-top-color: #642d96;
        }
      }

      @include from('lg') {
        &:after {
          top: 100%;
        }
      }
    }

    &--open {
      .cms-login__tooltip-content {
        visibility: visible;
      }
    }
  }

  &__repeated-tooltip {
    p {
      // To remove rich text editor p margins
      margin: 0;
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 23px;
    }
  }
}
