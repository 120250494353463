.cms-notification-card {
  &__loader {
    text-align: center;
    padding: 30px;

    &--hidden {
      display: none;
    }
  }

  &__gridbag-gutter-simulator {
    margin-bottom: 12px;

    @include from('sm') {
      margin-bottom: 16px;
    }

    @include from('lg') {
      margin-bottom: 24px;
    }
  }

  &__placeholder {
    margin-bottom: 12px;

    @include from('sm') {
      margin-bottom: 16px;
    }

    @include from('lg') {
      margin-bottom: 24px;
    }
  }
}