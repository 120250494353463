.device-rebate {
  &__anchor {
    display: block;
    position: relative;
    top: -90px;
    visibility: hidden;
  }

  &__form-content {
    padding: 30px 0;
    max-width: 600px;
  }

  &__heading-text {
    color: color(corePurple);
    margin: 0.5em 0;
    max-width: none;

    &--white {
      color: color(white);
    }

    &--centered {
      text-align: center;
    }
  }

  &__additionalText {
    margin: 0.5em 0 1em;
    max-width: none;
    margin: 0 auto;
    p {
      color: white;
    }
  }

  &__results {
    position: relative;
  }

  &__background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    display: none;
    height: 100%;

    .device-rebate__results--carousel &,
    .device-rebate__results--single & {
      display: block;
    }

    .device-rebate__results--list & {
      display: block;
      max-height: 467px;
    }
  }

  &__background-image {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;

    &--desktop {
      display: none;

      @include from('md') {
        display: block;
      }
    }

    &--mobile {
      @include from('md') {
        display: none;
      }
    }
  }

  &__result-background {
    position: absolute;
    top: 267px;
    left: 0;
    right: 0;
    z-index: z(forward);
    height: 200px;
    background: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(255, 255, 255, 1));
    display: none;

    .device-rebate__results--list & {
      display: block;
    }
  }

  &__result-view {
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;
    max-height: 0;
    transition: max-height 0.4s;
    transition-timing-function: cubic-bezier(0, 1, 0, 1);
    overflow: hidden;

    &--visible {
      max-height: 10000px;
      transition-timing-function: cubic-bezier(1, 0, 1, 0);
    }
  }

  &__result-container {
    background-position: 0 0;
    background-size: cover;
    background-repeat: no-repeat;

    .device-rebate__results--list & {
      background-size: initial;
    }
  }

  &__result-view-content {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__promotion-callout {
    margin: 0 0 5px;
    color: color(white);
  }

  &__promotion-price {
    font-size: 40px;
    color: color(white);
    margin: 0px;
  }

  &__result-list {
    margin: 1.5em 0 0;
    padding-top: 0;
    padding-bottom: 16px;
    background-color: transparent;
    z-index: z(moreForward);

    .product-card {
      padding: 0;
      width: 100%;
    }

    &--carousel {
      .grid {
        opacity: 0;
        transition: opacity 0.3s;

        &.slick-initialized {
          opacity: 1;
        }
      }
    }

    &--list {
      .device-rebate__result-item {
        border-radius: 0;
      }

      > div:first-child .device-rebate__result-item {
        border-radius: 4px 4px 0 0;
      }

      > div:last-child .device-rebate__result-item {
        border-radius: 0 0 4px 4px;
      }

      .grid {
        background-color: color(white);
        border-radius: 2px;

        > div {
          .product-card {
            &:before,
            &:after {
              display: block;
            }

            &__container {
              border-radius: 2px;
            }
          }

          &:first-child {
            .product-card {
              &__container {
                border-radius: 4px 4px 0 0;
              }
            }
          }

          &:last-child {
            .product-card {
              &__container {
                border-radius: 0 0 4px 4px;
              }
            }
          }
        }
      }
    }
  }

  &__result-item {
    background-color: color(white);
    height: 244px;
    width: 188px;
    border-radius: 4px;
    margin: 0 auto;
  }

  &__back {
    background-color: color(grey20);
    text-align: center;
    padding: 20px 0;
  }

  &__back-icon {
    font-size: 12px;
  }

  &__back-link {
    font-weight: bold;
  }

  &__button {
    text-align: center;
    margin-bottom: 1em;
  }

  &__status {
    text-align: center;
    font-size: 14px;
    margin-bottom: 8px;
    color: color(white);
  }

  &__show-all {
    &:link,
    &:visited {
      color: color(white);
      font-weight: bold;
    }
  }

  .notification {
    margin-top: 0;
  }

  .slick-track {
    padding: 0;
    border-radius: 4px;
    display: flex;
    align-items: stretch;
    background-color: color(white);
  }

  .slick-dots {
    @include to('xxs') {
      justify-content: center;
    }
  }

  .product-card__container {
    width: 100%;
  }

  .slick-slide {
    height: auto;
    width: auto;
    padding: 0;
  }

  .carousel {
    overflow-x: visible;

    &__arrow {
      top: calc(50% - 21px);
      &--left {
        @include from('xlg') {
          left: calc((100% - 100vw) / 4);
        }
      }

      &--right {
        @include from('xlg') {
          right: calc((100% - 100vw) / 4);
        }
      }
    }
  }

  &__select-container {
    padding-bottom: 20px;
  }

  &__input-field {
    margin-bottom: 5px !important;
  }

  &__imei {
    position: relative;
    bottom: 18px;
  }

  &__navbar-container {
    .cms-tablist__navbar {
      overflow: visible;
    }
  }

  &__captcha > .g-recaptcha {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    @include from('sm') {
      justify-content: flex-start;
    }
  }
}

// Move google captcha badge above chat button
@include to('md') {
  .grecaptcha-badge {
    bottom: 60px !important;
  }
}
