.share-follow {
  margin-top: 40px;

  @include from('sm') {
    margin-top: 44px;
  }

  &-large--margin-bottom {
    margin-bottom: 56px;
    @include from('sm') {
      margin-bottom: 96px;
    }
  }

  &__text {
    text-transform: uppercase;
    margin-left: 4px;
  }

  &__buttons {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  &__container {
    a {
      padding: 30px 0px 10px;
    }
  }

  &__icon {
    display: inline-flex;
    width: 30px;
    height: 30px;
    background-color: color(black);
    color: color(white);
    border-radius: 99px;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    margin: 12px 12px 12px 0;
    transition: background-color 0.2s;

    &:link, &:visited, &:hover, &:active {
      color: color(white);
    }

    @media (hover: hover) {
      &:hover {
        background-color: hover(corePurple);
      }
    }

    &--reduce-margin {
      @include to('md') {
        margin: 20px 8px 0 !important;
      }
    }

    .share-follow--large & {
      width: 50px;
      height: 50px;
      font-size: 30px;
      margin: 20px 8px 0px;
      @include from('sm') {
        margin: 40px 32px 0px;
      }
    }
  }

}
