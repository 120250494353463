.tag {
  display: inline-block;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.05em;
  padding: 5px 0;
  border-top: 1px solid;
  border-bottom: 1px solid;

  &--campaign {
    color: color(red);
    border-color: color(red);
  }

  &--info {
    color: color(corePurple);
    border-color: color(corePurple);
  }

  &--new {
    color: color(blue);
    border-color: color(blue);
  }

  &--price-campaign {
    color: color(orange);
    border-color: color(orange);
  }

  &--out-of-stock {
    color: color(grey50);
    border-color: color(grey50);
  }
}
