.cms-logout {
  color: color(corePurple);

  &__page-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
  }
}
