.epressi-article-list {
  &__item {
    -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
    display: flex;
    padding: 10px;

    &:hover {
      background-color: color(grey10);
    }
  }

  &__loader {
    padding: 20px;
    text-align: center;
  }

  &__thumbnail-container {
    min-width: 80px;
  }

  &__thumbnail {
    max-width: 80px;
  }

  &__item-text-container {
    margin-left: 15px;
  }

  &__item-title-container {
    margin: 0;
  }

  &__item-title {
    color: color(grey100);
    font-weight: bold;
    font-size: 0.9em;
  }

  &__item-date {
    color: color(grey70);
    font-size: 12px;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    &--hidden {
      display: none;
    }

    &-items {
      list-style-type: none;
    }

    &-link {
      padding: 6px 12px;
      display: block;
    }

    &-item {
      margin-left: 8px;
      background-color: color(grey20);
      text-align: center;

      &--active {
        pointer-events: none;
        background-color: color(corePurple);

        .epressi-article-list__pagination-link {
          color: color(white);
        }
      }

      &--disabled {
        background-color: transparent;
        color: color(corePurple);
        padding: 6px;
      }
    }
  }
}
