.social-media-links {
  text-align: center;
  background-color: color(grey20);
  padding: 2em 0;

  &--reverse-background-colors {
    background-color: white;

    .social-media-link__content {
      background-color: color(grey20);
    }
  }

  &__heading {
    padding-bottom: 1em;
    margin-left: auto;
    margin-right: auto;
  }
}

.social-media-link {
  padding: 2px;

  a {
    color: color(black) !important;
  }

  &__content {
    background-color: white;
    padding: 14px 8px 14px 26px;
  }

  &__header {
    margin-bottom: 16px;
  }

  &__icon {
    display: inline-block;
    color: color(black);
    fill: color(black);
    font-size: 26px;
    position: relative;
    top: 6px;
  }

  &__title {
    font-size: 20px;
    display: inline-block;
    margin-left: 4px;
  }

  &__description {
    margin-bottom: 8px;
    font-size: 14px;
  }
}
