@mixin big-x-item-block() {
  &__link {
    padding: 1em 0;
  }

  &__icon, &__text {
    display: block;
    text-align: center;
  }

  &__icon {
    padding-bottom: 10px;
  }
}

.big-x-item {
  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    border-bottom: 1px solid color(grey30);

    @include from('sm') {
      width: 90%;
    }

    @include from('md') {
      display: none;
    }
  }

  &--horizontal-mobile:before {
    width: 90%;
  }

  @include from('sm') {
    &:nth-last-child(-n+2):before {
      display: none;
    }
  }

  &:last-child:before {
    display: none;
  }

  &--horizontal-mobile {
    &:nth-last-child(-n+2):before {
      display: none;
    }

    @include to('xs') {
      padding-left: 6px;
      padding-right: 6px;
    }
    
    @include to('sm') {
      &:after {
        content: "";
        position: absolute;
        right: 4.5%;
        top: 50%;
        height: 80%;
        width: 1px;
        border-right: 1px solid color(grey30);
        transform: translateY(-50%);
      }
  
      &:nth-child(even):after {
        display: none;
      }
    }
  }

  &:nth-child(even):before {
    width: 100%;
  }

  @include at('sm') {
    &:after {
      content: "";
      position: absolute;
      right: 4.5%;
      top: 50%;
      height: 80%;
      width: 1px;
      border-right: 1px solid color(grey30);
      transform: translateY(-50%);
    }

    &:nth-child(even):after {
      display: none;
    }
  }

  &__link {
    font-size: 18px;
    line-height: 26px;
    padding: 1em 0;

    .big-x-item--horizontal-mobile & {
      font-size: 16px;
      line-height: 24px;
    }

    @include from('md') {
      padding: 2.5em 0;
    }

    &:link, &:visited {
      color: color(black);
    }

    &:hover, &:active {
      color: color(corePurple);
    }

    @include at('md') {
      font-size: 16px;
    }
  }

  &__icon {
    font-size: 30px;
    margin-right: 5px;

    .big-x-item--horizontal-mobile & {
      font-size: 28px;
    }
  }

  &__text {
    vertical-align: super;
  }

  .big-x__list--has-many & {
    @include from('md') {
      @include big-x-item-block();

      &__text {
        margin-left: -100%;
        margin-right: -100%;
        text-align: center;
        display: block;
      }
    }
  }

  .big-x__list--has-few & {
    @include between('md', 'lg') {
      @include big-x-item-block();
    }
  }
}

