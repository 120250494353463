.bulletin {

  &__header {
    width: 70%;
  }

  &__content {

    p {
      font-size: em(18px);
      line-height: em(24px);
      color: color(grey100);

        &:first-child {
        margin-top: 0;
        }
      }
  }      

  &__image {
    width: 100%;
  }

}