.marketing-unit {
  background-size: cover;
  padding: 62px 0;
  color: color(white);

  &--text-under-image {
    padding: 0;
  }

  &--mobile {
    display: block;

    @include from('sm') {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include from('sm') {
      display: block;
    }
  }

  &__mobile-text-content {
    padding: 32px 0;
  }

  &__mobile-background {
    background-size: cover;
  }

  &__heading {
    color: inherit;
    max-width: 852px;
    margin: 0;
    flex-shrink: 0;
  }

  &__description {
    margin: 1em 0;
  }

  &--color-purple {
    color: color(corePurple);
  }

  &--color-black {
    color: color(grey200);
  }

  &--color-deepPurple {
    color: color(deepPurple);
  }

  &--color-lightPurple {
    color: color(lightPurple);
  }

  &--color-lightBeige {
    color: color(lightBeige);
  }

  &--color-deepBeige {
    color: color(deepBeige);
  }

  &--clipping-top-left {
    background-position: 100% 100%;
  }

  &--clipping-top-center {
    background-position: 50% 100%;
  }

  &--clipping-top-right {
    background-position: 0 100%;
  }

  &--clipping-middle-right {
    background-position: 0 50%;
  }

  &--clipping-bottom-right {
    background-position: 0 0;
  }

  &--clipping-bottom-center {
    background-position: 50% 0;
  }

  &--clipping-bottom-left {
    background-position: 100% 0;
  }

  &--clipping-middle-left {
    background-position: 100% 50%;
  }

  &--clipping-center {
    background-position: 50% 50%;
  }

  &--clipping-stretch {
    background-size: 100% 100%;
  }

  &__basic-components {
    *:first-child {
      margin-left: 0;
      margin-right: 0;
    }

    .button {
      margin-left: 0;
      margin-right: 0;
      width: auto;
    }
  }

  &--background {
    &-white {
      background-color: color(white);
    }

    &-transparent {
      background-color: transparent;
    }

    &-lightGrey {
      background-color: color(grey20);
    }

    &-corePurple {
      background-color: color(corePurple);
    }

    &-deepPurple {
      background-color: color(deepPurple);
    }

    &-lightPurple {
      background-color: color(lightPurple);
    }

    &-lightBeige {
      background-color: color(lightBeige);
    }

    &-deepBeige {
      background-color: color(deepBeige);
    }

    &-black {
      background-color: color(black);
    }
  }
}
