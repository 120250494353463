.form {

  &-languages {
    padding-top: 1.75em;

    &-current {
      font-weight: bold;
    }

    &-item {
      float: left;
    }
  }

  &-buttons {
    padding-top: 1.75em;
    padding-bottom: 1.75em;
  }

  &-container {
    margin-bottom: 1.75em;
  }

  &__layer {
    padding-top: 0.75em;

    .notification {
      padding-top: 0.75em;
      z-index: 0;
    }
  }

  &-element-text {
    padding-top: 1.75em;
  }

  &-element-help {
    display: none;
    background-color: #ffffff;
    z-index: 9999;
    border: 4px solid #0099FF;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.20);
    border-radius: 4px;
    padding: 8px;
    position: absolute;
    top: 50%;
    right: 5%;
    left: 25%;
  }

  &-element-img img {
    width: 100%;
  }

  &-element-tiplist {
    &--numbered {

      & > div {
        color: color(corePurple);
        font-weight: bold;
      }
      & ul {
        list-style-type: none;

        &  li {
           counter-increment: item;

           & span {
              display: inline-table;
              max-width: 92%;
           }
        }
        & li:before {
           font-size: 24px;
           margin-right: 10px;
           content: counter(item);
           color: #000;
           text-align: center;
           display: inline-block;
         }
      }
    }
    &-help {
      display: none;
      background-color: #ffffff;
      z-index: 9999;
      border: 4px solid #0099FF;
      box-shadow: 0 0 4px 0 rgba(0,0,0,0.20);
      border-radius: 4px;
      padding: 8px;
      position: absolute;
      right: 5%;
      left: 25%;
    }

    &-icon {
      color: #0099FF;
      pointer-events: auto;
    }

    &-icon:hover + .form-element-tiplist-help {
      display: block;
    }
}

  &-element-icon {
    color: #0099FF;
    top: 50%;
    right: 2%;
    position: absolute;
    pointer-events: auto;
  }

  &-element-icon:hover + .form-element-help {
    display: block;
  }

}

.form-loader {

  .error-message {
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .error {
    border-bottom-color: color(red);
  }

  .error + label {
      color: color(red);
  }

  &__captcha > .g-recaptcha {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    
    @include from('sm') {
      justify-content: flex-start;
    }
  }

  &__loader {
    display: block;
    width: 100%;
    margin-top: 20px;

    @include from ('md') {
      display: inline;
      margin-left: 10px;
      position: relative;
      top: 0.4em;
    }
  }
}
