// @note: this is just converted css from default select2
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;

  .select2-selection--single {
    .select2-selection__clear {
      position: relative;
    }
  }

  .select2-selection--multiple {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    min-height: 32px;
    user-select: none;

    .select2-selection__rendered {
      display: inline-block;
      overflow: hidden;
      padding-left: 8px;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .select2-search--inline {
    float: left;

    .select2-search__field {
      box-sizing: border-box;
      border: none;
      font-size: 100%;
      margin-top: 5px;
      padding: 0;
    }
  }
}

.select2-results {
  display: block;
}

.select2-container--open {
  z-index: 1;

  .select2-dropdown {
    left: 0;
  }

  .select2-dropdown--above {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .select2-dropdown--below {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.select2-search--dropdown {
  .select2-search__field {
    padding: 4px;
    width: 100%;
    box-sizing: border-box;
  }

}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: z(dropdownOverlay);
  background-color: color(white);
  filter: alpha(opacity = 0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: (-1px) !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.select2-container--default {
  .select2-selection--single {
    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
    }

    .select2-selection__placeholder {
      color: #999;
    }
  }

  &.select2-container--disabled {
    .select2-selection--single {
      background-color: #eee;
      cursor: default;

      .select2-selection__clear {
        display: none;
      }
    }

    .select2-selection--multiple {
      background-color: #eee;
      cursor: default;
    }

    .select2-selection__choice__remove {
      display: none;
    }
  }

  &.select2-container--open {
    &.select2-container--above {
      .select2-selection--single {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      .select2-selection--multiple {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &.select2-container--below {
      .select2-selection--single {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .select2-selection--multiple {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .select2-selection--single .select2-selection__arrow b {
      border-color: transparent transparent #888 transparent;
      border-width: 0 4px 5px 4px;
    }
  }

  .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;

    .select2-selection__rendered {
      box-sizing: border-box;
      list-style: none;
      margin: 0;
      padding: 0 5px;
      width: 100%;
    }

    .select2-selection__placeholder {
      color: #999;
      margin-top: 5px;
      float: left;
    }

    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-top: 5px;
      margin-right: 10px;
    }

    .select2-selection__choice {
      background-color: #e4e4e4;
      border: 1px solid #aaa;
      border-radius: 4px;
      cursor: default;
      float: left;
      margin-right: 5px;
      margin-top: 5px;
      padding: 0 5px;
    }

    .select2-selection__choice__remove {
      color: #999;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: 2px;

      &:hover {
        color: #333;
      }
    }
  }

  .select2-results__option[role='group'] {
    padding: 0;
  }

  .select2-results__option[aria-disabled='true'] {
    color: #999;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }

  &.select2-container--focus .select2-selection--multiple {
    border: solid black 1px;
    outline: 0;
  }

  .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
  }

  .select2-search--inline .select2-search__field {
    background: transparent;
    border: none;
    outline: 0;
    box-shadow: none;
  }

  .select2-results__option .select2-results__option {
    padding-left: 1em;

    .select2-results__group {
      padding-left: 0;
    }

    .select2-results__option {
      margin-left: -1em;
      padding-left: 2em;

      .select2-results__option {
        margin-left: -2em;
        padding-left: 3em;

        .select2-results__option {
          margin-left: -3em;
          padding-left: 4em;

          .select2-results__option {
            margin-left: -4em;
            padding-left: 5em;

            .select2-results__option {
              margin-left: -5em;
              padding-left: 6em;
            }
          }
        }
      }
    }
  }
}

.select2-container--default[dir='rtl'] {
  .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      left: 1px;
      right: auto;
    }
  }

  .select2-selection--multiple {
    .select2-selection__choice {
      float: right;
      margin-left: 5px;
      margin-right: auto;
    }

    .select2-selection__placeholder {
      float: right;
    }

    .select2-search--inline {
      float: right;
    }

    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }
}

.select2-container--classic {
  .select2-selection--single {
    background-color: #f7f7f7;
    border: 1px solid #aaa;
    border-radius: 4px;
    outline: 0;
    background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);

    &:focus {
      border: 1px solid #5897fb;
    }

    .select2-selection__rendered {
      color: #444;
      line-height: 28px;
    }

    .select2-selection__clear {
      cursor: pointer;
      float: right;
      font-weight: bold;
      margin-right: 10px;
    }

    .select2-selection__placeholder {
      color: #999;
    }

    .select2-selection__arrow {
      background-color: #ddd;
      border: none;
      border-left: 1px solid #aaa;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      height: 26px;
      position: absolute;
      top: 1px;
      right: 1px;
      width: 20px;
      background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
      background-repeat: repeat-x;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFCCCCCC', GradientType=0);

      b {
        border-color: #888 transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0 4px;
        height: 0;
        left: 50%;
        margin-left: -4px;
        margin-top: -2px;
        position: absolute;
        top: 50%;
        width: 0;
      }
    }
  }

  &.select2-container--open {
    .select2-selection--single {
      border: 1px solid #5897fb;

      .select2-selection__arrow {
        background: transparent;
        border: none;

        b {
          border-color: transparent transparent #888 transparent;
          border-width: 0 4px 5px 4px;
        }
      }
    }

    &.select2-container--above {
      .select2-selection--single {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        background-image: linear-gradient(to bottom, white 0, #eeeeee 50%);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFFFFFFF', endColorstr='#FFEEEEEE', GradientType=0);
      }

      .select2-selection--multiple {
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    &.select2-container--below {
      .select2-selection--single {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
        background-repeat: repeat-x;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#FFEEEEEE', endColorstr='#FFFFFFFF', GradientType=0);
      }

      .select2-selection--multiple {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    .select2-selection--multiple {
      border: 1px solid #5897fb;
    }

    .select2-dropdown {
      border-color: #5897fb;
    }
  }

  .select2-selection--multiple {
    background-color: white;
    border: 1px solid #aaa;
    border-radius: 4px;
    cursor: text;
    outline: 0;

    &:focus {
      border: 1px solid #5897fb;
    }

    .select2-selection__rendered {
      list-style: none;
      margin: 0;
      padding: 0 5px;
    }

    .select2-selection__clear {
      display: none;
    }

    .select2-selection__choice {
      background-color: #e4e4e4;
      border: 1px solid #aaa;
      border-radius: 4px;
      cursor: default;
      float: left;
      margin-right: 5px;
      margin-top: 5px;
      padding: 0 5px;
    }

    .select2-selection__choice__remove {
      color: #888;
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      margin-right: 2px;

      &:hover {
        color: #555;
      }
    }
  }

  .select2-dropdown {
    background-color: white;
    border: 1px solid transparent;
  }

  .select2-dropdown--above {
    border-bottom: none;
  }

  .select2-dropdown--below {
    border-top: none;
  }

  .select2-results__option[role='group'] {
    padding: 0;
  }

  .select2-results__option[aria-disabled='true'] {
    color: grey;
  }

  .select2-results__group {
    cursor: default;
    display: block;
    padding: 6px;
  }

  .select2-search--dropdown .select2-search__field {
    border: 1px solid #aaa;
    outline: 0;
  }

  .select2-search--inline .select2-search__field {
    outline: 0;
    box-shadow: none;
  }

  .select2-results > .select2-results__options {
    -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
    max-height: 200px;
    overflow-y: auto;
    border: 2px solid color(corePurple);
  }
}

.select2-container--classic[dir='rtl'] {
  .select2-selection--single {
    .select2-selection__clear {
      float: left;
    }

    .select2-selection__arrow {
      border: none;
      border-right: 1px solid #aaa;
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      left: 1px;
      right: auto;
    }
  }

  .select2-selection--multiple {
    .select2-selection__choice {
      float: right;
      margin-left: 5px;
      margin-right: auto;
    }

    .select2-selection__choice__remove {
      margin-left: 2px;
      margin-right: auto;
    }
  }
}

.select2-container[dir='rtl'] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

// Custom styles starts from here
.ppr-select {
  width: 100%;

  padding: 10px;
  height: 46px;

  background-color: transparent;
  border: 0;
  border-bottom: 1px solid color(grey50);

  &__label {
    font-size: 12px;
    line-height: 1;
    color: color(grey50);
    margin: -22px 0 20px;

    .select2 & {
      margin: 0;
      position: absolute;
      top: -5px;
    }
  }

  & ~ .select2-container {
    margin-top: 15px;

    .icon {
      transition: transform 0.5s, color 0.5s;
    }
  }

  & ~ .select2-container--open .icon {
    transform: rotate(180deg);
    color: color(corePurple);
  }

  &.error ~ .select2-container &__label {
    color: color(red);
  }

  &.error ~ .select2-container &__container {
    border-bottom: 2px solid color(red);

    .icon {
      color: color(red);
    }
  }

  & ~ .select2-container--disabled {
    pointer-events: none;

    .ppr-select__container {
      border-bottom: 1px dotted color(grey40);
    }

    .select2-selection {
      background-color: transparent;

      .select2-selection__rendered {
        color: color(grey40);
      }
    }
  }

  &__footer {
    font-size: 12px;
    line-height: 1;
    margin: 7px 0;

    &--error {
      color: color(red);
    }
  }

  &__container {
    cursor: pointer;
    display: block;
    user-select: none;

    background-color: transparent;
    border: 0;
    border-bottom: 1px solid color(grey40);

    padding: 10px 0 4px;

    &.select2-selection--single {
      .select2-selection__rendered {
        height: 24px;
        line-height: 24px;
        display: block;
        padding: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        color: #444;
      }

      .select2-selection__arrow {
        height: 20px;
        width: 20px;
        position: absolute;
        top: 12px;
        color: color(grey40);
        font-size: 11px;

        right: 0;

        b {
          display: none;
        }
      }
    }
  }

  &__dropdown {
    overflow: hidden;
    box-sizing: border-box;
    display: block;
    position: absolute;
    top: -2px;
    width: 100%;
    z-index: z(dropdown);
    background-color: color(white);
    transition: max-height 2s;

    .select2-container--visible & {
      max-height: 200px;

      .select2-results {
        margin-top: 0;
        transition: margin 0.2s ease-out;
      }
    }

    .select2-search {
      display: block;
      padding: 4px;

      &--hide {
        display: none;
      }

      &__field {
        font-size: 16px;

        // Hide the autofill-icon on Safari
        &::-webkit-contacts-auto-fill-button {
          display: none !important;
          visibility: hidden;
          pointer-events: none;
          position: absolute;
          right: 0;
        }
      }
    }

    .select2-results {
      display: block;
      position: relative;
      // margin-top: -200px; // Disabled until we can have animations
      margin-top: 0;
      transition: margin 0.2s ease-out;

      > .select2-results__options {
        max-height: 200px;
        overflow-y: auto;
        overflow-x: hidden;
        max-width: 100%;
      }

      &__options {
        border: 2px solid color(corePurple);
        border-radius: 2px;
        list-style: none;
        margin: 0;
        padding: 0;
      }

      &__option {
        -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */
        padding: 10px 6px;
        user-select: none;
        cursor: pointer;
        color: color(corePurple);

        &[aria-selected='true'] {
          background-color: color(corePurple);
          color: color(white);
        }

        &--highlighted {
          background-color: color(corePurple);
          color: color(white);

          &[aria-selected='true'] {
            background-color: color(corePurple);
            color: color(white);
          }
        }
      }
    }
  }
}
