// This component relies somewhat on external .form-container styles (form container required to style dark theme inputs)
.cms-huippudiili-number-offer {
  & > .form-container {
   margin: 0;

   @include from('md') {
     padding-top: 1.75em;
     padding-bottom: 1.75em;
   }

 }

 margin-bottom: 20px;

 @include from('md') {
   margin-bottom: 0;
 }

 &__form-content {
    width: 100%;
 }

 &__form-container {
   margin-bottom: 10px;
   padding-top: 0;
   padding-bottom: 0;

   @include from('md') {
     display: flex;
     align-items: center;
     flex-direction: row;
   }
 }

 &__input-container {
   display: flex;
   flex-direction: column;
   margin-bottom: 10px;

   @include from('sm') {
     flex-direction: row;
   }

   @include from('md') {
     max-width: 600px;
   }

   .cms-text-input__error-text {
      color: color(red);
      display: block;
      text-align: left;
      font-size: 12px;
      margin-top: 5px;
   }

   // Override external text-input stylings
   & .text-input {
     margin: 0;
     margin-bottom: 15px;
     width: 100%;
     text-align: left;
     @include from('sm') {
       margin-bottom: 0;
       padding-right: 20px;
     }

     & .cms-text-input__error-text {
       @include from('sm') {
         position: absolute;
       }
     }
   }

   // Override external button stylings
   & > .button {
     align-self: center;
     margin: 0;
     margin-top: auto;
   }

 }

 &__captcha > .g-recaptcha {
   display: flex;
   justify-content: center;
   margin-bottom: 10px;

   @include from('sm') {
     justify-content: flex-start;
   }
 }

 &__loader {
   margin-top: 10px;
   display: flex;
   justify-content: center;
 }

 &__display-none {
  display: none !important;
 }

 &__visibility-hidden {
  visibility: hidden !important;
 }

}

// Move google captcha badge above chat button
@include to('md') {
 .grecaptcha-badge {
   bottom: 60px !important;
 }
}