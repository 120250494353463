.textbox {
  -webkit-text-size-adjust: 100%; /* prevents iphone from magically adjusting font size for whatever reason */

  .table {
    overflow: auto;
  }

  table {
    min-width: em(600px);
  }
}
